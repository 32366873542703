import { OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { CourseMapper } from "app/app.mappers";

const initialState = OrderedMap();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_COURSES:
    case ACTIONS.CLEAR_ROLE_COURSES: {
      return initialState;
    }

    case ACTIONS.GET_COURSES:
    case ACTIONS.GET_AVAILABLE_COURSES_FOR_ROLE:
    case ACTIONS.GET_AVAILABLE_PREREQUISITE_COURSES:
    case ACTIONS.GET_PREREQUISITE_COURSES: {
      if (error) return state;

      return state.merge(
        payload.map(course => [
          course.courseId.toString(),
          CourseMapper.from(course),
        ]),
      );
    }

    case ACTIONS.GET_ROLE_COURSES_BY_ROLE: {
      if (error) return state;

      return OrderedMap(
        payload.courses.map(course => [
          course.courseId.toString(),
          CourseMapper.from(course),
        ]),
      );
    }

    case ACTIONS.GET_AVAILABLE_COURSES_FOR_COLLECTION: {
      if (error) return state;

      return OrderedMap(
        payload.map(course => [
          course.courseId.toString(),
          CourseMapper.from(course),
        ]),
      );
    }

    case ACTIONS.CREATE_COURSE:
    case ACTIONS.UPDATE_COURSE:
    case ACTIONS.GET_COURSE:
    case ACTIONS.ADD_PREREQUISITE_COURSE: {
      if (error) return state;

      return state.set(payload.courseId.toString(), CourseMapper.from(payload));
    }

    case ACTIONS.DELETE_COURSE: {
      if (error) return state;

      return state.delete(action.courseId.toString());
    }

    case ACTIONS.DELETE_COURSES: {
      if (error) return state;

      return state.deleteAll(
        action.courseIds.map(courseId => courseId.toString()),
      );
    }

    case ACTIONS.REMOVE_PREREQUISITE_COURSE: {
      if (error) return state;

      return state.delete(action.prerequisiteCourseId.toString());
    }

    default:
      return state;
  }
};
