import React from "react";
import { compose } from "redux";
import { withStyles } from "@material-ui/styles";
import { Grid, Divider, Typography } from "@material-ui/core";

import CompetencyStatusIcon, {
  statusToTooltipText,
} from "app/components/CompetencyStatusIcon";

import { invokeIfFunction } from "utils/app.util";

const EntityRelationCompetencyStatus = ({
  classes,
  renderLeftEntity,
  competencyStatus,
  renderRightEntity,
  getCompetencyStatusText,
}) => (
  <Grid item container alignItems="flex-start">
    {renderLeftEntity && (
      <Grid item xs>
        {invokeIfFunction(renderLeftEntity)}
      </Grid>
    )}
    <Grid
      item
      xs
      container
      alignItems="center"
      className={classes.competencyStatusIconContainer}
    >
      <Grid container component={Divider} className={classes.divider} />
      <CompetencyStatusIcon
        status={competencyStatus}
        IconProps={{
          classes: {
            root: classes.competencyStatusIcon,
          },
        }}
      />
      <Typography variant="caption" className={classes.competencyStatusText}>
        {invokeIfFunction(getCompetencyStatusText, competencyStatus)}
      </Typography>
    </Grid>
    {renderRightEntity && (
      <Grid item xs className={classes.container}>
        <Grid
          component={Divider}
          orientation="vertical"
          className={classes.verticalDivider}
        />
        {invokeIfFunction(renderRightEntity)}
      </Grid>
    )}
  </Grid>
);

const styles = theme => ({
  divider: {
    height: 2,
    background: theme.palette.grey[400],
  },
  avatar: {
    background: theme.palette.primary.main,
  },
  competencyStatusIcon: {
    top: "50%",
    left: "50%",
    position: "absolute",
    width: theme.spacing(5),
    height: theme.spacing(5),
    transform: "translate(-50%, -50%)",
  },
  competencyStatusIconContainer: {
    position: "relative",
    minHeight: theme.spacing(10),
  },
  text: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  verticalDivider: {
    width: 2,
    zIndex: -1,
    left: "50%",
    position: "absolute",
    transform: "translateX(-50%)",
    background: theme.palette.grey[400],
  },
  container: {
    position: "relative",
  },
  competencyStatusText: {
    bottom: 0,
    left: "50%",
    position: "absolute",
    transform: "translateX(-50%)",
  },
});

EntityRelationCompetencyStatus.defaultProps = {
  getCompetencyStatusText: statusToTooltipText,
};

export default compose(withStyles(styles))(EntityRelationCompetencyStatus);
