import { compose } from "redux";
import { connect } from "react-redux";
import { PureComponent } from "react";
import { getSkillFromState } from "features/competence.selectors";

class SkillBreadcrumb extends PureComponent {
  render() {
    const { skill } = this.props;

    return skill ? skill.get("name") : "...";
  }
}

export default compose(
  connect((state, { match }) => ({
    skill: getSkillFromState(state, match.params.skillId),
  })),
)(SkillBreadcrumb);
