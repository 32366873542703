import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { generatePath } from "react-router";
import React, { PureComponent } from "react";

import {
  CREATE_EMPLOYEE_SKILL_MODAL_ID,
  ADD_EMPLOYEE_SKILL_EVIDENCE_MODAL_ID,
} from "features/competence.constants";

import {
  createEmployeeSkillOnEnter,
  employeeSkillsBasePageOnLoad,
  createEmployeeSkillFormOnSubmit,
  deleteEmployeeSkillAskConfirmation,
  initializeAddEmployeeSkillEvidenceForm,
  addEmployeeSkillEvidenceAndFetchEmployeeRoleSkills,
} from "features/competence.actions";

import CrudBasePage from "app/components/CrudBasePage";
import withToggleModal from "app/components/withToggleModal";
import { ACTIONS, EMPTY_LIST, ICONS } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import EmployeeRoleSkillList from "features/components/EmployeeRoleSkillList";

import CreateEmployeeSkillModal from "features/components/CreateEmployeeSkillModal";
import { getEmployeeRoleSkillsForEmployeeFromState } from "features/competence.selectors";
import AddEmployeeSkillEvidenceModal from "features/components/AddEmployeeSkillEvidenceModal";

class EmployeeSkillsBasePage extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad, employeeId } = this.props;

    dispatchOnLoad(employeeId);
  }

  toggleCreateModal = () =>
    this.props.dispatchToggleModal({
      modalId: CREATE_EMPLOYEE_SKILL_MODAL_ID,
    });

  toggleAddEvidenceModal = () =>
    this.props.dispatchToggleModal({
      modalId: ADD_EMPLOYEE_SKILL_EVIDENCE_MODAL_ID,
    });

  initializeAddEmployeeSkillEvidence = item => {
    const { dispatchInitializeAddEmployeeSkillEvidence } = this.props;

    dispatchInitializeAddEmployeeSkillEvidence(
      item,
      this.toggleAddEvidenceModal,
    );
  };

  render() {
    const {
      path,
      Icon,
      title,
      employeeId,
      getRoleSkillsDataState,
      employeeRoleSkillsByRole,
      dispatchDeleteEmployeeSkill,
      dispatchCreateEmployeeSkillOnEnter,
      dispatchCreateEmployeeSkillFormOnSubmit,
      showAddButton,
      dispatchAddEmployeeSkillEvidenceAndFetchEmployeeRoleSkills,
    } = this.props;

    return (
      <CrudBasePage
        Icon={Icon}
        title={title}
        displaySearchField
        displayAddButton={showAddButton}
        addButtonOnClick={this.toggleCreateModal}
        dataState={getRoleSkillsDataState}
      >
        <Grid container>
          {employeeRoleSkillsByRole.map((employeeRoleSkillsForRole, index) => {
            return (
              <EmployeeRoleSkillList
                Icon={ICONS.SKILL}
                key={index}
                title={employeeRoleSkillsForRole.get("roleName")}
                roleId={employeeRoleSkillsForRole.get("roleId")}
                employeeRoleSkills={employeeRoleSkillsForRole.get(
                  "employeeRoleSkills",
                )}
                createSortableListRowProps={item => ({
                  component: Link,
                  to: `${generatePath(path, { employeeId })}/${item.get(
                    "skillId",
                  )}`,
                })}
                initializeAddEmployeeSkillEvidence={
                  this.initializeAddEmployeeSkillEvidence
                }
                removeEmployeeSkill={dispatchDeleteEmployeeSkill}
              />
            );
          })}
          <CreateEmployeeSkillModal
            employeeId={employeeId}
            onEnter={dispatchCreateEmployeeSkillOnEnter}
            onSubmit={dispatchCreateEmployeeSkillFormOnSubmit(employeeId)}
          />
          <AddEmployeeSkillEvidenceModal
            employeeId={employeeId}
            addEmployeeSkillEvidenceOnSubmit={
              dispatchAddEmployeeSkillEvidenceAndFetchEmployeeRoleSkills
            }
          />
        </Grid>
      </CrudBasePage>
    );
  }
}

EmployeeSkillsBasePage.defaultProps = {
  employeeRoleSkillsByRole: EMPTY_LIST,
  showAddButton: true,
};

export default compose(
  connect(
    (state, { employeeId }) => ({
      employeeRoleSkillsByRole: getEmployeeRoleSkillsForEmployeeFromState(
        state,
        employeeId,
      ),
      getRoleSkillsDataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_EMPLOYEE_ROLE_SKILLS_GROUPED_BY_ROLE,
      ),
    }),
    {
      dispatchOnLoad: employeeSkillsBasePageOnLoad,
      dispatchCreateEmployeeSkillOnEnter: createEmployeeSkillOnEnter,
      dispatchDeleteEmployeeSkill: deleteEmployeeSkillAskConfirmation,
      dispatchCreateEmployeeSkillFormOnSubmit: createEmployeeSkillFormOnSubmit,
      dispatchInitializeAddEmployeeSkillEvidence: initializeAddEmployeeSkillEvidenceForm,
      dispatchAddEmployeeSkillEvidenceAndFetchEmployeeRoleSkills: addEmployeeSkillEvidenceAndFetchEmployeeRoleSkills,
    },
  ),
  withToggleModal,
)(EmployeeSkillsBasePage);
