import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import {
  getGapReportDepartmentsFromState,
  getSpecificationGapReportSpecificationsFromState,
  getSpecificationGapReportSpecificationCoursesBySpecificationFromState,
  getSpecificationGapReportSpecificationSkillsBySpecificationFromState,
} from "features/competence.selectors";

import { GAP_REPORT } from "app/app.constants";
import GapReport from "features/components/GapReport/GapReport";
import SpecificationGapReportHeader from "features/specification/components/SpecificationGapReportHeader";
import SpecificationGapReportEmployeeRow from "features/specification/components/SpecificationGapReportEmployeeRow";

class SpecificationGapReport extends PureComponent {
  renderHeader = () => {
    const {
      specifications,
      specificationCourses,
      specificationSkills,
    } = this.props;

    return (
      <SpecificationGapReportHeader
        specifications={specifications}
        specificationCourses={specificationCourses}
        specificationSkills={specificationSkills}
      />
    );
  };

  renderEmployee = ({ employee, index }) => {
    const {
      root,
      specifications,
      specificationCourses,
      specificationSkills,
    } = this.props;

    return (
      <SpecificationGapReportEmployeeRow
        root={root}
        index={index}
        specifications={specifications}
        specificationCourses={specificationCourses}
        specificationSkills={specificationSkills}
        key={employee.get("employeeId")}
        employeeId={employee.get("employeeId")}
      />
    );
  };

  render() {
    const { root, title, employeesByDepartment, departments } = this.props;

    return (
      <GapReport
        root={root}
        title={title}
        renderHeader={this.renderHeader}
        departments={departments}
        employeesByDepartment={employeesByDepartment}
        renderEmployee={({ employee, index }) =>
          this.renderEmployee({ employee, index })
        }
        gapReportType={GAP_REPORT.SPECIFICATION_GAP_REPORT}
      />
    );
  }
}

export default compose(
  connect(state => ({
    departments: getGapReportDepartmentsFromState(state),
    specifications: getSpecificationGapReportSpecificationsFromState(state),
    specificationCourses: getSpecificationGapReportSpecificationCoursesBySpecificationFromState(
      state,
    ),
    specificationSkills: getSpecificationGapReportSpecificationSkillsBySpecificationFromState(
      state,
    ),
  })),
)(SpecificationGapReport);
