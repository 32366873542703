import { compose } from "redux";
import { connect } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo } from "react";

import {
  getMyTeamFileEvidencesNeedingApproval,
  previewOrSaveEmployeeSkillFileEvidence,
  updateEmployeeSkillFileEvidenceApproval,
  removeEmployeeSkillFileEvidenceApproval,
} from "features/competence.actions";

import SortableTable from "app/components/Table/SortableTable";
import DownloadFileColumn from "app/components/DownloadFileColumn";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import { EMPTY_MAP, APPROVAL_STATUS, ACTIONS } from "app/app.constants";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";
import ApproveButton from "features/hr/approval/components/ApproveButton";
import { getSkillFileEvidencesApprovalFromState } from "features/competence.selectors";

const EmployeeSkillFileEvidenceApproval = ({
  filter,
  fileEvidencesNeedingApproval = EMPTY_MAP,
  dispatchGetEmployeeSkillFileEvidencesNeedingApproval,
  dispatchUpdateEmployeeSkillFileEvidenceApproval,
  dispatchPreviewOrSaveEmployeeSkillFileEvidence,
  dispatchRemoveEmployeeSkillFileEvidenceApproval,
}) => {
  const loadEmployeeSkillEvidences = useCallback(() => {
    dispatchGetEmployeeSkillFileEvidencesNeedingApproval();
  }, [dispatchGetEmployeeSkillFileEvidencesNeedingApproval]);

  useEffect(() => {
    loadEmployeeSkillEvidences();
  }, [loadEmployeeSkillEvidences]);

  const columns = useMemo(
    () => [
      {
        xs: true,
        component: Grid,
        title: "Evidence",
        id: "evidence",
        Header: "Evidence",
        getSortProperty: item => item.get("name"),
        Cell: ({ row }) => {
          if (row.depth === 0) return row.original.get("name");

          return (
            <DownloadFileColumn
              file={row.original}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();

                const skillEvdiences = fileEvidencesNeedingApproval
                  .filter(
                    x =>
                      x.get("skillEvidenceId").toString() ===
                      row.original.skillEvidenceId.toString(),
                  )
                  .first();

                dispatchPreviewOrSaveEmployeeSkillFileEvidence(
                  skillEvdiences.get("employeeId"),
                  skillEvdiences.get("skillId"),
                  row.original,
                );
              }}
            />
          );
        },
      },
      {
        xs: 3,
        component: Grid,
        title: "Skill",
        id: "Skill",
        Header: "Skill",
        getSortProperty: item => item.get("skillName"),
        Cell: ({ row }) => {
          if (row.depth === 0)
            return (
              <Grid>
                <Typography noWrap variant="body2">
                  {row.original.get("skillName")}
                </Typography>
                <Typography noWrap component="p" variant="caption">
                  {row.original.get("employeeDisplayName")}
                </Typography>
              </Grid>
            );

          return "";
        },
      },
      {
        xs: true,
        title: "Skill Type",
        id: "SkillType",
        Header: "Skill Type",
        getSortProperty: item => item.get("skillTypeName"),
        Cell: ({ row }) => {
          if (row.depth === 0) return row.original.get("skillTypeName");
          return "";
        },
      },
      {
        xs: true,
        title: "Approved",
        id: "Approved",
        Header: "Approved",
        Cell: ({ row }) => {
          if (row.depth === 0) {
            var approved = row.original.get("approvalDate");
            var approvedOther = row.original.get("approvalOtherDate");
            var apprText = approved ? (approvedOther ? "2" : "1") : "0";
            return apprText + "/2";
          }
          return "";
        },
      },
      {
        title: "",
        xs: true,
        component: Grid,
        id: "status",
        Header: "",
        Cell: ({ row }) => {
          if (row.depth === 0) {
            return (
              <ApproveButton
                item={row.original}
                id={row.original.get("skillEvidenceId")}
                checkBy="status"
                approveAction={
                  ACTIONS.UPDATE_EMPLOYEE_SKILL_FILE_EVIDENCE_APPROVAL
                }
                rejectAction={
                  ACTIONS.UPDATE_EMPLOYEE_SKILL_FILE_EVIDENCE_APPROVAL
                }
                undoAction={
                  ACTIONS.REMOVE_EMPLOYEE_SKILL_FILE_EVIDENCE_APPROVAL
                }
                onApprove={() =>
                  dispatchUpdateEmployeeSkillFileEvidenceApproval(
                    row.original.get("employeeId"),
                    row.original.get("skillId"),
                    row.original.set(
                      "approvalStatus",
                      APPROVAL_STATUS.APPROVED,
                    ),
                  )
                }
                onReject={() =>
                  dispatchUpdateEmployeeSkillFileEvidenceApproval(
                    row.original.get("employeeId"),
                    row.original.get("skillId"),
                    row.original.set(
                      "approvalStatus",
                      APPROVAL_STATUS.REJECTED,
                    ),
                  )
                }
                onUndo={() =>
                  dispatchRemoveEmployeeSkillFileEvidenceApproval(
                    row.original.get("employeeId"),
                    row.original.get("skillId"),
                    row.original.get("skillEvidenceId"),
                  )
                }
              />
            );
          }
          return "";
        },
      },
      {
        xs: true,
        title: "",
        id: "compStatus",
        component: Grid,
        container: true,
        justify: "flex-end",
        getSortProperty: item => item.get("competencyStatus"),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return (
            <CompetencyStatusChip
              status={row.original.get("competencyStatus")}
            />
          );
        },
        filter: {
          label: "Status",
          defaultText: "All",
          getFilterText: filter => statusToTooltipText(filter),
        },
      },
    ],
    [
      fileEvidencesNeedingApproval,
      dispatchUpdateEmployeeSkillFileEvidenceApproval,
      dispatchRemoveEmployeeSkillFileEvidenceApproval,
      dispatchPreviewOrSaveEmployeeSkillFileEvidence,
    ],
  );

  return (
    <SortableTable
      stickyHeader
      disableSortBy
      columns={columns}
      title="Evidences"
      keyName="skillEvidenceId"
      useGlobalFilter={false}
      subListPropertyName="files"
      subListKey="employeeSkillEvidenceFileId"
      items={fileEvidencesNeedingApproval}
      noItemsMessage="No skill evidence added yet..."
    />
  );
};

export default compose(
  connect(
    state => ({
      fileEvidencesNeedingApproval: getSkillFileEvidencesApprovalFromState(
        state,
      ),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_MY_TEAM_FILE_EVIDENCES_NEEDING_APPROVAL,
      ),
    }),
    {
      dispatchGetEmployeeSkillFileEvidencesNeedingApproval: getMyTeamFileEvidencesNeedingApproval,
      dispatchPreviewOrSaveEmployeeSkillFileEvidence: previewOrSaveEmployeeSkillFileEvidence,
      dispatchUpdateEmployeeSkillFileEvidenceApproval: updateEmployeeSkillFileEvidenceApproval,
      dispatchRemoveEmployeeSkillFileEvidenceApproval: removeEmployeeSkillFileEvidenceApproval,
    },
  ),
)(EmployeeSkillFileEvidenceApproval);
