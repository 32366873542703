import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { BasePageTitle } from "altus-ui-components";

import { ACTIONS, GAP_REPORT } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import GapReportEmployeesHeaderOptions from "features/components/GapReport/GapReportEmployeesHeaderOptions";

class GapReportEmployeesHeader extends PureComponent {
  render() {
    const { classes, title, gapReportType } = this.props;
    return (
      <Grid
        container
        direction="column"
        justify="space-between"
        className={classes.root}
      >
        <Grid container className={classes.row}>
          <BasePageTitle title={title} />
          <Grid container className={classes.row} />
          {gapReportType === GAP_REPORT.ROLE_GAP_REPORT && (
            <GapReportEmployeesHeaderOptions />
          )}
        </Grid>
        <Grid container className={classes.row}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2} component={Typography} noWrap variant="caption">
              <Typography noWrap variant="subtitle2">
                #
              </Typography>
            </Grid>
            <Grid item xs={5} component={Typography} noWrap variant="caption">
              <Typography noWrap variant="subtitle2">
                Name
              </Typography>
            </Grid>
            <Grid item xs={5} noWrap variant="caption" component={Typography}>
              <Typography noWrap variant="subtitle2">
                Position
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    left: 0,
    zIndex: 4,
    position: "sticky",
    padding: `0px ${theme.spacing(2)}px`,
    height: theme.gapReport.header.height,
    borderBottom: theme.gapReport.defaultBorder,
    background: theme.palette.background.default,
    backgroundColor: theme.palette.background.default,
    minWidth: theme.gapReport.employeesColumn.width,
    maxWidth: theme.gapReport.employeesColumn.width,
  },
  row: {
    height: theme.gapReport.cellSize,
  },
});

export default compose(
  connect(state => ({
    dataState: getSummarizedDataStatesFromState(
      state,
      ACTIONS.UPDATE_GAP_REPORT_OPTIONS,
    ),
  })),
  withStyles(styles),
)(GapReportEmployeesHeader);
