import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Switch, Route } from "react-router-dom";

import { RouteContainer, NoContentBasePage } from "altus-ui-components";

import routePaths from "app/routePaths";
import { appOnLoad } from "app/app.actions";
import { routesWithoutSidebar } from "app/routes";
import AccessControl from "app/components/AccessControl";
import LoginContainer from "features/login/LoginContainer";
import CompetenceContainer from "app/components/CompetenceContainer";

class ApplicationContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad } = this.props;
    dispatchOnLoad();
  }

  render() {
    return (
      <Switch>
        <Route exact path={routePaths.login} component={LoginContainer} />
        {routesWithoutSidebar.map(route => (
          <Route
            key={route.path}
            path={route.path}
            exact={route.exact}
            render={() => (
              <AccessControl
                accessLevel={route.accessLevel}
                noAccessContent={
                  <NoContentBasePage
                    header="No access"
                    description="You do not have access to this page.."
                  />
                }
              >
                {/* Since these routes are not filtered away by
                 * CompetenceContainer, we need to put the content
                 * inside a AccessControl
                 */}
                <RouteContainer routes={[route]} />
              </AccessControl>
            )}
          />
        ))}
        <Route component={CompetenceContainer} />
      </Switch>
    );
  }
}

export default compose(
  connect(null, {
    dispatchOnLoad: appOnLoad,
  }),
)(ApplicationContainer);
