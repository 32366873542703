import { Map, Set } from "immutable";

import { ACTIONS } from "app/app.constants";
import fileMappers from "app/mappers/file.mappers";
import { EmployeeEducationMapper } from "app/app.mappers";
import { EDUCATION_FORM } from "features/competence.constants";

const initialState = Map();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_EMPLOYEE_EDUCATION_ITEMS: {
      if (error) return state;

      return Map(
        payload.map(employeeEducation => [
          employeeEducation.educationId.toString(),
          EmployeeEducationMapper.from(employeeEducation),
        ]),
      );
    }

    case ACTIONS.UPLOAD_EDUCATION_FILE: {
      if (error) return state;

      return state.mergeIn(
        [action.educationId.toString(), EDUCATION_FORM.FILES],
        Set([payload]).map(fileMappers.File.from),
      );
    }

    case ACTIONS.GET_EMPLOYEE_EDUCATION_ITEM:
    case ACTIONS.UPDATE_EMPLOYEE_EDUCATION_ITEM:
    case ACTIONS.CREATE_EMPLOYEE_EDUCATION_ITEM:
    case ACTIONS.CREATE_AND_APPROVE_EMPLOYEE_EDUCATION_ITEM:
    case ACTIONS.APPROVE_EMPLOYEE_EDUCATION_ITEM:
    case ACTIONS.DISAPPROVE_EMPLOYEE_EDUCATION_ITEM: {
      if (error) return state;

      return state.set(
        payload.educationId.toString(),
        EmployeeEducationMapper.from(payload),
      );
    }

    case ACTIONS.DELETE_EMPLOYEE_EDUCATION_ITEM: {
      if (error) return state;

      return state.delete(action.educationId.toString());
    }

    default:
      return state;
  }
};
