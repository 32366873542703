import { compose } from "redux";
import React, { PureComponent } from "react";
import { withStyles, SvgIcon, withTheme } from "@material-ui/core";

import {
  statusToIcon,
  statusToTooltipText,
} from "app/components/CompetencyStatusIcon";

class GapReportCell extends PureComponent {
  onClick = () => {
    const { to } = this.props;

    window.open(to, "_blank");
  };

  render() {
    const {
      x,
      y,
      theme,
      status,
      classes,
      Icon = statusToIcon(status),
      fill = theme.palette.background.default,
    } = this.props;

    const { cellSize } = theme.gapReport;

    const xPos = x * cellSize;
    const yPos = y * cellSize;

    return (
      <g className={classes.root} onClick={this.onClick}>
        {Icon && <title>{statusToTooltipText(status)}</title>}
        <rect
          y={yPos}
          x={xPos}
          fill={fill}
          width={cellSize}
          height={cellSize}
          className={classes.rect}
          stroke={theme.palette.grey[300]}
        />
        {Icon && (
          <SvgIcon
            x={xPos}
            y={yPos}
            width={cellSize}
            color="secondary"
            height={cellSize}
            viewBox="-12 -12 48 48"
          >
            <Icon />
          </SvgIcon>
        )}
      </g>
    );
  }
}

const styles = theme => ({
  root: {
    cursor: "pointer",
    "&:hover $rect": {
      fill: theme.palette.action.hover,
    },
  },
  rect: {},
});

export default compose(withTheme, withStyles(styles))(GapReportCell);
