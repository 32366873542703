import { compose } from "redux";
import isNaN from "lodash/isNaN";
import React, { PureComponent } from "react";
import { grey } from "@material-ui/core/colors";

import {
  Grid,
  Typography,
  withStyles,
  LinearProgress,
} from "@material-ui/core";

class ProgressBar extends PureComponent {
  render() {
    const {
      title,
      value,
      classes,
      TypographyProps,
      LinearProgressProps,
    } = this.props;

    const adjustedValue = isNaN(value) ? 0 : value;

    return (
      <Grid container justifyContent="center" className={classes.root}>
        <Typography
          variant="caption"
          className={classes.title}
          {...TypographyProps}
        >
          {title}
        </Typography>
        <LinearProgress
          color="primary"
          variant="determinate"
          value={adjustedValue}
          classes={{
            root: classes.linearProgressRoot,
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary,
          }}
          {...LinearProgressProps}
        />
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    position: "relative",
  },
  title: {
    zIndex: 2,
    top: "50%",
    left: "50%",
    position: "absolute",
    color: theme.palette.common.white,
    transform: "translate(-50%, -50%)",
    lineHeight: `${theme.spacing(2)}px`,
    fontWeight: theme.typography.fontWeightMedium,
  },
  linearProgressRoot: {
    width: "100%",
    height: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  barColorPrimary: {
    backgroundColor: theme.palette.primary.main,
  },
  colorPrimary: {
    backgroundColor: grey[400],
  },
});

ProgressBar.defaultProps = {
  value: 0,
};

export default compose(withStyles(styles))(ProgressBar);
