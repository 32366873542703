import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import {
  ROLE_FORM,
  ROLE_DETAILS_MODAL_ID,
} from "features/competence.constants";

import { getFormValuesFromState } from "app/app.selectors";
import SimpleFormModal from "app/components/SimpleFormModal";
import RoleDetails from "features/components/RoleDetails";

const RoleDetailsModal = ({ role }) => {
  return (
    <SimpleFormModal
      displayCancelBtn={false}
      displaySubmitBtn={false}
      component={RoleDetails}
      modalId={ROLE_DETAILS_MODAL_ID}
      title={role ? role.get("name") : ""}
      FormProps={{
        role,
      }}
      form={{
        form: ROLE_FORM.ID,
      }}
    />
  );
};

export default compose(
  connect(state => ({
    role: getFormValuesFromState(state, ROLE_FORM.ID),
  })),
  React.memo,
)(RoleDetailsModal);
