import { OrderedMap } from "immutable";

import { ACTIONS, EMPTY_MAP } from "app/app.constants";
import { ExperienceRecordMapper } from "app/app.mappers";

const initialState = EMPTY_MAP;

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_EXPERIENCE_RECORDS:
      return initialState;

    case ACTIONS.GET_EMPLOYEE_EXPERIENCE_RECORDS:
    case ACTIONS.GET_EMPLOYEE_EXPERIENCE_RECORDS_FOR_REPORT:
    case ACTIONS.GET_EXPERIENCE_RECORDS_BY_COMPETENCY_STATUS:
    case ACTIONS.GET_EXPERIENCE_RECORDS: {
      if (error) return state;

      return OrderedMap(
        payload.map(experience => [
          experience.experienceRecordId.toString(),
          ExperienceRecordMapper.from(experience),
        ]),
      );
    }

    case ACTIONS.UPDATE_EXPERIENCE_RECORD:
    case ACTIONS.SUBMIT_EXPERIENCE_RECORD:
    case ACTIONS.GET_EXPERIENCE_RECORD: {
      if (error) return state;

      return state.set(
        payload.experienceRecordId.toString(),
        ExperienceRecordMapper.from(payload),
      );
    }

    case ACTIONS.CREATE_EXPERIENCE_RECORDS: {
      if (error) return state;

      return state.merge(
        payload.map(experience => [
          experience.experienceRecordId.toString(),
          ExperienceRecordMapper.from(experience),
        ]),
      );
    }

    case ACTIONS.DELETE_EXPERIENCE_RECORD: {
      if (error) return state;

      return state.delete(action.experienceId.toString());
    }

    case ACTIONS.DELETE_EXPERIENCE_RECORDS: {
      if (error) return state;

      return state.deleteAll(
        action.experienceIds.map(experienceId => experienceId.toString()),
      );
    }

    default:
      return state;
  }
};
