import { compose } from "redux";
import React, { useCallback } from "react";
import { withStyles } from "@material-ui/core";

import ClearIcon from "@material-ui/icons/Clear";
import { TextField, IconButton } from "@material-ui/core";

const MultiSelectTextField = ({
  value,
  onClick,
  onClear,
  classes,
  ...rest
}) => {
  const clearClick = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      onClear();
    },
    [onClear],
  );

  return (
    <TextField
      fullWidth
      margin="none"
      variant="filled"
      onClick={onClick}
      placeholder={`${value.size} selected`}
      classes={{
        root: classes.textFieldRoot,
      }}
      InputProps={{
        endAdornment: value.size > 0 && (
          <IconButton size="small" onClick={clearClick}>
            <ClearIcon />
          </IconButton>
        ),
      }}
      {...rest}
    />
  );
};

const styles = theme => ({
  textFieldRoot: {
    "& .MuiFilledInput-root": {
      background: theme.palette.common.white,
      "&:hover": {
        background: theme.palette.grey[100],
      },
    },
  },
});

export default compose(withStyles(styles))(MultiSelectTextField);
