import { compose } from "redux";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";
import Switch from "@material-ui/core/Switch";
import { green } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import { alpha, styled } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import {
  getRoleFromState,
  getEmployeeFromState,
  getEmployeeRoleFromState,
} from "features/competence.selectors";

import {
  toggleRoleEmployeeCoursesOverride,
  toggleRoleEmployeeSkillsOverride,
} from "features/competence.actions";

import {
  RoleMapper,
  EmployeeMapper,
  EmployeeRoleMapper,
} from "app/app.mappers";

import { getInitials } from "utils/app.util";
import { ICONS, ACTIONS } from "app/app.constants";
import { BasePage } from "altus-ui-components";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import EntityCompetencyStatus from "features/components/EntityRelationCompetencyStatus/EntityCompetencyStatus";
import EntityRelationCompetencyStatus from "features/components/EntityRelationCompetencyStatus/EntityRelationCompetencyStatus";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: green[600],
    "&:hover": {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: green[600],
  },
}));

class EmployeeRoleStatusContainer extends PureComponent {
  renderEmployee = () => {
    const { employee } = this.props;

    return (
      <EntityCompetencyStatus
        topText={employee.get("displayName")}
        bottomText={employee.get("departmentName")}
        Icon={getInitials(employee.get("displayName"))}
      />
    );
  };

  renderRole = () => {
    const { role } = this.props;

    return (
      <EntityCompetencyStatus
        Icon={<ICONS.ROLE />}
        topText={role.get("name")}
        bottomText={role.get("description")}
      />
    );
  };

  onChangeOverrideCourses = event => {
    const {
      dispatchToggleOverrideEmployeeRoleCourses,
      employeeId,
      employeeRole,
    } = this.props;

    dispatchToggleOverrideEmployeeRoleCourses(
      employeeId,
      employeeRole.get("roleId"),
    );
  };

  onChangeOverrideSkills = event => {
    const {
      dispatchToggleOverrideEmployeeRoleSkills,
      employeeId,
      employeeRole,
    } = this.props;

    dispatchToggleOverrideEmployeeRoleSkills(
      employeeId,
      employeeRole.get("roleId"),
    );
  };

  render() {
    const { employeeRole, dataState, breadcrumb } = this.props;

    return (
      <BasePage title={breadcrumb} dataState={dataState}>
        <Grid container spacing={2}>
          <Grid item container xs={12} lg={10}>
            <EntityRelationCompetencyStatus
              renderRightEntity={this.renderRole}
              renderLeftEntity={this.renderEmployee}
              competencyStatus={employeeRole.get("competencyStatus")}
            />
          </Grid>
          <Grid item container xs={12} lg={10}>
            <FormGroup>
              <FormControlLabel
                label="Override Courses"
                control={
                  <GreenSwitch
                    checked={employeeRole.get("overrideCourses", false)}
                    onChange={this.onChangeOverrideCourses}
                  />
                }
              />

              <FormControlLabel
                label="Override Skills"
                control={
                  <GreenSwitch
                    checked={employeeRole.get("overrideSkills", false)}
                    onChange={this.onChangeOverrideSkills}
                  />
                }
              />
            </FormGroup>
          </Grid>
        </Grid>
      </BasePage>
    );
  }
}

EmployeeRoleStatusContainer.defaultProps = {
  role: RoleMapper.initial,
  employee: EmployeeMapper.initial,
  employeeRole: EmployeeRoleMapper.initial,
};

export default compose(
  connect(
    (state, { roleId, employeeId }) => ({
      role: getRoleFromState(state, roleId),
      employee: getEmployeeFromState(state, employeeId),
      employeeRole: getEmployeeRoleFromState(state, employeeId, roleId),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_ROLE,
        ACTIONS.GET_EMPLOYEE,
        ACTIONS.GET_EMPLOYEE_ROLES,
      ),
    }),
    {
      dispatchToggleOverrideEmployeeRoleCourses: toggleRoleEmployeeCoursesOverride,
      dispatchToggleOverrideEmployeeRoleSkills: toggleRoleEmployeeSkillsOverride,
    },
  ),
)(EmployeeRoleStatusContainer);
