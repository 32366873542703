import { compose } from "redux";
import classNames from "classnames";
import { connect } from "react-redux";
import React from "react";
import GetApp from "@material-ui/icons/GetApp";
import { withStyles } from "@material-ui/styles";
import { green } from "@material-ui/core/colors";
import Attachment from "@material-ui/icons/Attachment";

import {
  Grid,
  Divider,
  Paper,
  Avatar,
  Button,
  Tooltip,
  IconButton,
  Typography,
} from "@material-ui/core";

import { ICONS } from "app/app.constants";
import { previewOrSaveEmployeeSkillFileEvidence } from "features/competence.actions";

const EmployeeSkillFileUpload = ({
  value,
  classes,
  skillId,
  disabled,
  rootProps,
  removeFile,
  employeeId,
  isDragActive,
  openFileDialog,
  dispatchPreviewOrSaveEmployeeSkillFileEvidence,
}) => {
  const isAnyFileSelected = value.size > 0;
  const selectedFiles = isAnyFileSelected ? value.toList() : [];

  return (
    <Grid
      {...rootProps}
      container
      component={Paper}
      alignItems="center"
      spacing={4}
      className={classNames(classes.root, {
        [classes.rootIsDragActive]: isDragActive,
      })}
    >
      {isAnyFileSelected && (
        <Grid item container className={classes.selectedFilesContainer}>
          {selectedFiles.map((file, index) => (
            <Grid
              container
              justify="space-between"
              alignItems="center"
              key={index}
            >
              <Grid container item xs spacing={2} alignItems="center">
                <Grid item>
                  <Avatar
                    classes={{
                      colorDefault: classes.avatarColorDefault,
                    }}
                  >
                    <Attachment></Attachment>
                  </Avatar>
                </Grid>
                <Grid item>
                  <Typography className={classes.fileName}>
                    {file.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <Tooltip title="Delete">
                      <Grid item>
                        <IconButton
                          disabled={disabled}
                          onClick={() => removeFile(file)}
                        >
                          <ICONS.DELETE />
                        </IconButton>
                      </Grid>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title="Download">
                      <IconButton
                        onClick={() =>
                          dispatchPreviewOrSaveEmployeeSkillFileEvidence(
                            employeeId,
                            skillId,
                            file,
                          )
                        }
                      >
                        <GetApp></GetApp>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
      {isAnyFileSelected && (
        <Grid item container>
          <Divider className={classes.divider}></Divider>
        </Grid>
      )}
      <Grid item container justify="center" spacing={1} alignItems="center">
        <Grid item>
          <Button
            variant="outlined"
            onClick={openFileDialog}
            className={classes.fileName}
          >
            Browse
          </Button>
        </Grid>
        <Grid item>
          <Typography className={classes.fileName}>
            {"or Drag & Drop Evidence Files here"}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  root: {
    outline: "none",
    padding: theme.spacing(3),
    background: theme.palette.grey[200],
    margin: 0,
  },
  rootIsDragActive: {
    background: green[100],
  },
  avatarColorDefault: {
    background: theme.palette.primary.light,
  },
  fileName: {
    color: theme.palette.text.secondary,
  },
  selectedFilesContainer: {
    overflow: "auto",
    maxHeight: "210px",
  },
  divider: {
    width: "100%",
  },
});

export default compose(
  connect(null, {
    dispatchPreviewOrSaveEmployeeSkillFileEvidence: previewOrSaveEmployeeSkillFileEvidence,
  }),
  withStyles(styles),
)(EmployeeSkillFileUpload);
