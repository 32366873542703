import { Map, OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { DepartmentMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_MY_TEAM_DEPARTMENTS:
    case ACTIONS.GET_DEPARTMENTS: {
      if (error) return state;

      return OrderedMap(
        payload.map(department => [
          department.departmentId.toString(),
          DepartmentMapper.from(department),
        ]),
      );
    }

    default:
      return state;
  }
};
