import { compose } from "redux";
import { Link } from "react-router-dom";
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Tooltip } from "@material-ui/core";

import routePaths from "app/routePaths";
import { EMPTY_SET, ICONS } from "app/app.constants";
import GapReportHeaderCell from "features/components/GapReport/GapReportHeaderCell";
import GapReportRequirementHeaderCell from "features/components/GapReport/GapReportRequirementHeaderCell";

class SpecificationGapReportHeader extends PureComponent {
  render() {
    const {
      classes,
      specifications,
      specificationCourses,
      specificationSkills,
    } = this.props;

    return (
      <Grid item container wrap="nowrap" className={classes.root}>
        {specifications.map(specification => {
          const specificationId = specification
            .get("specificationId")
            .toString();

          return (
            <React.Fragment key={specificationId}>
              <Tooltip title={specification.get("name")}>
                <GapReportHeaderCell
                  target="_blank"
                  component={Link}
                  to={`${
                    routePaths.hrPortal
                  }/specifications/${specification.get("specificationId")}`}
                >
                  <Typography
                    noWrap
                    variant="subtitle2"
                    className={classes.headerText}
                  >
                    {specification.get("name")}
                  </Typography>
                </GapReportHeaderCell>
              </Tooltip>

              {specificationCourses
                .get(specificationId, EMPTY_SET)
                .map(specificationCourse => (
                  <GapReportRequirementHeaderCell
                    Icon={ICONS.COURSE}
                    name={specificationCourse.get("courseName")}
                    key={specificationCourse.get("specificationCourseId")}
                    title={`${specificationCourse.get(
                      "courseName",
                    )} (${specificationCourse.get("courseTypeName")})`}
                    to={`${
                      routePaths.hrPortal
                    }/courses/${specificationCourse.get("courseId")}`}
                  />
                ))}

              {specificationSkills
                .get(specificationId, EMPTY_SET)
                .map(specificationSkill => (
                  <GapReportRequirementHeaderCell
                    Icon={ICONS.SKILL}
                    name={specificationSkill.get("skillName")}
                    key={specificationSkill.get("specificationNameId")}
                    title={`${specificationSkill.get(
                      "skillName",
                    )} (${specificationSkill.get("skillTypeName")})`}
                    to={`${routePaths.hrPortal}/skills/${specificationSkill.get(
                      "skillId",
                    )}`}
                  />
                ))}
            </React.Fragment>
          );
        })}
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    top: 0,
    zIndex: 3,
    position: "sticky",
    height: theme.gapReport.header.height,
    borderTop: theme.gapReport.defaultBorder,
    borderRight: theme.gapReport.defaultBorder,
    backgroundColor: theme.palette.background.default,
  },
  headerText: {
    transform: "rotate(-90deg)",
    minWidth: theme.gapReport.header.height - theme.spacing(2),
    maxWidth: theme.gapReport.header.height - theme.spacing(2),
  },
});

export default compose(withStyles(styles))(SpecificationGapReportHeader);
