import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { ACTIONS } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import { clearSkillGapReport } from "features/competence.actions";
import SkillGapReport from "features/components/SkillGapReport/SkillGapReport";
import GapReportBasePage from "features/components/GapReport/GapReportBasePage";
import RoleDetailsModal from "features/components/RoleGapReport/RoleDetailsModal";
import SkillDetailsModal from "features/components/SkillGapReport/SkillDetailsModal";
import SkillGapReportFilter from "features/components/SkillGapReport/SkillGapReportFilter";
import { getGapReportEmployeesByDepartmentFromState } from "features/competence.selectors";

class SkillGapReportBasePage extends PureComponent {
  componentWillUnmount() {
    const { dispatchClearSkillGapReport } = this.props;
    dispatchClearSkillGapReport();
  }

  renderFilter = () => {
    const {
      availableFilters,
      getSkillGapReport,
      downloadSkillGapReport,
      dispatchClearSkillGapReport,
    } = this.props;

    return (
      <SkillGapReportFilter
        getGapReport={getSkillGapReport}
        availableFilters={availableFilters}
        clearGapReport={dispatchClearSkillGapReport}
        downloadSkillGapReport={downloadSkillGapReport}
      />
    );
  };

  render() {
    const {
      root,
      title,
      dataState,
      getSkillGapReport,
      employeesByDepartment,
    } = this.props;

    return (
      <GapReportBasePage
        title={title}
        dataState={dataState}
        getGapReport={getSkillGapReport}
        renderFilter={this.renderFilter}
        employeesByDepartment={employeesByDepartment}
      >
        {!!employeesByDepartment.size && (
          <SkillGapReport
            root={root}
            title={title}
            employeesByDepartment={employeesByDepartment}
          />
        )}
        <SkillDetailsModal />
        <RoleDetailsModal />
      </GapReportBasePage>
    );
  }
}

export default compose(
  connect(
    state => ({
      employeesByDepartment: getGapReportEmployeesByDepartmentFromState(state),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_SKILL_GAP_REPORT,
        ACTIONS.DOWNLOAD_SKILL_GAP_REPORT,
        ACTIONS.GET_DEPARTMENTS,
        ACTIONS.GET_EMPLOYEES,
      ),
    }),
    {
      dispatchClearSkillGapReport: clearSkillGapReport,
    },
  ),
)(SkillGapReportBasePage);
