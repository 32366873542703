import React, { useCallback, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Helmet } from "altus-ui-components";

import {
  getTrainingHistoryReport,
  clearTrainingHistoryReport,
  trainingHistoryReportOnLoad,
  trainingHistoryReportOnUnload,
  downloadAndSaveTrainingHistoryReport,
} from "features/competence.actions";

import { getTrainingReportEmployeesFromState } from "features/competence.selectors";

import { ACTIONS, APP_ROOT, EMPTY_MAP } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import GapReportBasePage from "features/components/GapReport/GapReportBasePage";
import TrainingHistoryReportFilter from "features/reports/trainingHistory/TrainingHistoryReportFilter"; // FILTERS,
import TrainingHistoryReportTableContainer from "features/reports/trainingHistory/TrainingHistoryReportTableContainer";

const TrainingHistoryReport = ({
  root = APP_ROOT.HR,
  dataState,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchGetReport,
  employeesByDepartment = EMPTY_MAP,
  dispatchClearReport,
  dispatchDownloadGapReport,
}) => {
  useEffect(() => {
    dispatchOnLoad();
    return () => dispatchOnUnload();
  }, [dispatchOnLoad, dispatchOnUnload]);

  const renderFilter = useCallback(
    () => (
      <TrainingHistoryReportFilter
        getGapReport={dispatchGetReport}
        clearGapReport={dispatchClearReport}
        downloadGapReport={dispatchDownloadGapReport}
      />
    ),
    [dispatchGetReport, dispatchClearReport, dispatchDownloadGapReport],
  );

  return (
    <>
      <Helmet titleTemplate="%s">
        <title>{breadcrumb}</title>
      </Helmet>
      <GapReportBasePage
        title={breadcrumb}
        dataState={dataState}
        renderFilter={renderFilter}
        employeesByDepartment={employeesByDepartment}
      >
        {!!employeesByDepartment.size && (
          <TrainingHistoryReportTableContainer
            root={root}
            title={breadcrumb}
            employees={employeesByDepartment}
          />
        )}
      </GapReportBasePage>
    </>
  );
};

export default compose(
  connect(
    state => ({
      employeesByDepartment: getTrainingReportEmployeesFromState(state),

      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_TRAINING_HISTORY_REPORT,
        ACTIONS.DOWNLOAD_TRAINING_HISTORY_REPORT,
      ),
    }),
    {
      dispatchOnLoad: trainingHistoryReportOnLoad,
      dispatchGetReport: getTrainingHistoryReport,
      dispatchOnUnload: trainingHistoryReportOnUnload,
      dispatchClearReport: clearTrainingHistoryReport,
      dispatchDownloadGapReport: downloadAndSaveTrainingHistoryReport,
    },
  ),
  React.memo,
)(TrainingHistoryReport);
