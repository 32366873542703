import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Helmet } from "altus-ui-components";

import {
  getCourseGapReport,
  hrCourseGapReportOnLoad,
  downloadAndSaveCourseGapReport,
} from "features/competence.actions";

import { APP_ROOT } from "app/app.constants";
import CourseGapReportBasePage from "features/components/CourseGapReport/CourseGapReportBasePage";

class HRCourseGapReportContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad } = this.props;

    dispatchOnLoad();
  }

  render() {
    const {
      breadcrumb,
      dispatchGetEmployees,
      dispatchGetDepartments,
      dispatchGetCourseGapReport,
      dispatchDownloadCourseGapReport,
    } = this.props;

    return (
      <>
        <Helmet titleTemplate="%s">
          <title>{breadcrumb}</title>
        </Helmet>
        <CourseGapReportBasePage
          root={APP_ROOT.HR}
          title={breadcrumb}
          getEmployees={dispatchGetEmployees}
          getDepartments={dispatchGetDepartments}
          getCourseGapReport={dispatchGetCourseGapReport}
          downloadCourseGapReport={dispatchDownloadCourseGapReport}
        />
      </>
    );
  }
}

export default compose(
  connect(null, {
    dispatchOnLoad: hrCourseGapReportOnLoad,
    dispatchGetCourseGapReport: getCourseGapReport,
    dispatchDownloadCourseGapReport: downloadAndSaveCourseGapReport,
  }),
)(HRCourseGapReportContainer);
