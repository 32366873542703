import Agent from "infrastructure/agent";

/**
 * Course
 */
const getCourses = filter => Agent.get(`/course`, filter);
const getMyTeamCourses = () => Agent.get(`/course/myteam`);

const getCourse = courseId => Agent.get(`/course/${courseId}`);

const createCourse = course => Agent.post(`/course`, course);

const updateCourse = (courseId, course) =>
  Agent.put(`/course/${courseId}`, course);

const deleteCourse = courseId => Agent.delete(`/course/${courseId}`);

const deleteCourses = courseIds => Agent.post(`/course/remove`, courseIds);

const getCollectionItemsOverriddenByCourse = courseId =>
  Agent.get(`/course/${courseId}/overrides`);

/**
 * Prerequisite Course
 */
const getAvailablePrerequisiteCourses = () =>
  Agent.get(`/course/prerequisite/available`);

const getPrerequisiteCourses = courseId =>
  Agent.get(`/course/${courseId}/prerequisite`);

const addPrerequisiteCourse = (courseId, prerequisiteCourseId) =>
  Agent.post(`/course/${courseId}/prerequisite/${prerequisiteCourseId}`);

const removePrerequisiteCourse = (courseId, prerequisiteCourseId) =>
  Agent.delete(`/course/${courseId}/prerequisite/${prerequisiteCourseId}`);

export default {
  getCourse,
  getCourses,
  getMyTeamCourses,
  createCourse,
  updateCourse,
  deleteCourse,
  deleteCourses,

  getAvailablePrerequisiteCourses,
  getPrerequisiteCourses,
  addPrerequisiteCourse,
  removePrerequisiteCourse,
  getCollectionItemsOverriddenByCourse,
};
