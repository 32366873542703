import { compose } from "redux";
import { connect } from "react-redux";
import { PureComponent } from "react";
import { getSpecificationFromState } from "features/competence.selectors";

class SpecificationBreadcrumb extends PureComponent {
  render() {
    const { specification } = this.props;

    return specification ? specification.get("name") : "...";
  }
}

export default compose(
  connect((state, { match }) => ({
    specification: getSpecificationFromState(
      state,
      match.params.specificationId,
    ),
  })),
)(SpecificationBreadcrumb);
