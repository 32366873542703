import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { TILE_CHIP_TYPES, OverviewContainer } from "altus-ui-components";

import { getSettingsStats } from "features/competence.actions";
import { getSettingsStatsFromState } from "features/competence.selectors";

class SettingsOverviewContainer extends PureComponent {
  componentDidMount() {
    const { dispatchGetSettingsStats } = this.props;
    dispatchGetSettingsStats();
  }

  render() {
    const {
      parentRoutes,
      match,
      parentBreadcrumb,
      settingsStats,
      Icon,
    } = this.props;

    const routesWithLabel = parentRoutes.map(route => ({
      ...route,
      chipType: route.key ? TILE_CHIP_TYPES.VISIBLE : TILE_CHIP_TYPES.HIDDEN,
      chipLabel: settingsStats.get(route.key),
    }));

    return (
      <OverviewContainer
        Icon={Icon}
        match={match}
        parentRoutes={routesWithLabel}
        parentBreadcrumb={parentBreadcrumb}
      />
    );
  }
}

export default compose(
  connect(
    state => ({
      settingsStats: getSettingsStatsFromState(state),
    }),
    {
      dispatchGetSettingsStats: getSettingsStats,
    },
  ),
)(SettingsOverviewContainer);
