import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { RouteContainer, NavTabsContainer } from "altus-ui-components";

import {
  getCourse,
  getEmployeeCourse,
  getPrerequisiteCourses,
  getPrerequisiteEmployeeCourses,
} from "features/competence.actions";

import { ROUTE_KEYS } from "app/routes";
import { getCourseFromState } from "features/competence.selectors";
import EmployeeCourseDetailsHeader from "features/employee/employeeCourse/EmployeeCourseDetailsHeader";

class EmployeeCourseContainer extends PureComponent {
  componentDidMount() {
    const {
      courseId,
      employeeId,
      dispatchGetCourse,
      dispatchGetEmployeeCourse,
      dispatchGetPrerequisiteCourses,
      dispatchGetPrerequisiteEmployeeCourses,
    } = this.props;

    dispatchGetCourse(courseId);
    dispatchGetPrerequisiteCourses(courseId);
    dispatchGetEmployeeCourse(employeeId, courseId);
    dispatchGetPrerequisiteEmployeeCourses(employeeId, courseId);
  }

  filterRoutes = route => {
    const { course } = this.props;

    if (!course) return false;

    switch (route.key) {
      case ROUTE_KEYS.COLLECTIONS:
        return course.get("isInCollection");
      case ROUTE_KEYS.EMPLOYEE_COURSE_ATTENDANCE:
        return !course.get("isCustom");
      default:
        return true;
    }
  };

  render() {
    const { routes, courseId, employeeId } = this.props;

    return (
      <>
        <EmployeeCourseDetailsHeader
          courseId={courseId}
          employeeId={employeeId}
        />
        <NavTabsContainer routes={routes.filter(this.filterRoutes)} />
        <RouteContainer
          routes={routes}
          RouteProps={{
            employeeId,
          }}
        />
      </>
    );
  }
}

export default compose(
  connect(
    (state, { courseId }) => ({
      course: getCourseFromState(state, courseId),
    }),
    {
      dispatchGetCourse: getCourse,
      dispatchGetEmployeeCourse: getEmployeeCourse,
      dispatchGetPrerequisiteCourses: getPrerequisiteCourses,
      dispatchGetPrerequisiteEmployeeCourses: getPrerequisiteEmployeeCourses,
    },
  ),
)(EmployeeCourseContainer);
