import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { RouteContainer, NavTabsContainer } from "altus-ui-components";

import {
  getRole,
  getEmployeeRoles,
  getStatsForEmployeeRole,
} from "features/competence.actions";

import { ROUTE_KEYS } from "app/routes";
import { EMPTY_MAP } from "app/app.constants";
import { setRouteCount } from "utils/route.util";
import { getEmployeeRoleStatsFromState } from "features/competence.selectors";
import EmployeeRoleProgressBar from "features/components/EmployeeRoleProgressBar";
import EmployeeRoleDetailsHeader from "features/employeeRole/EmployeeRoleDetailsHeader";

class EmployeeRoleContainer extends PureComponent {
  componentDidMount() {
    const {
      roleId,
      employeeId,
      dispatchGetRole,
      dispatchGetEmployeeRoles,
      dispatchGetRoleStatsForEmployeeAndRole,
    } = this.props;

    dispatchGetRole(roleId);
    dispatchGetEmployeeRoles(employeeId);
    dispatchGetRoleStatsForEmployeeAndRole(employeeId, roleId);
  }

  getRouteCount = key => {
    const { employeeRoleStats } = this.props;

    switch (key) {
      case ROUTE_KEYS.ROLE_SKILLS:
        return employeeRoleStats.get("requiredSkillsCount");
      case ROUTE_KEYS.ROLE_COURSES:
        return employeeRoleStats.get("requiredCoursesCount");
      default:
        return null;
    }
  };

  render() {
    const { roleId, routes, employeeId } = this.props;

    return (
      <>
        <EmployeeRoleDetailsHeader employeeId={employeeId} roleId={roleId}>
          <EmployeeRoleProgressBar employeeId={employeeId} roleId={roleId} />
        </EmployeeRoleDetailsHeader>
        <NavTabsContainer
          routes={routes.map(route =>
            setRouteCount(route, this.getRouteCount(route.key)),
          )}
        />
        <RouteContainer
          routes={routes}
          RouteProps={{
            employeeId,
          }}
        />
      </>
    );
  }
}

EmployeeRoleContainer.defaultProps = {
  employeeRoleStats: EMPTY_MAP,
};
export default compose(
  connect(
    (state, { roleId, employeeId }) => ({
      employeeRoleStats: getEmployeeRoleStatsFromState(
        state,
        employeeId,
        roleId,
      ),
    }),
    {
      dispatchGetRole: getRole,
      dispatchGetEmployeeRoles: getEmployeeRoles,
      dispatchGetRoleStatsForEmployeeAndRole: getStatsForEmployeeRole,
    },
  ),
)(EmployeeRoleContainer);
