import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { getCurrentEmployeeFromState } from "app/app.selectors";
import EmployeeWorkHistoryBasePage from "features/components/EmployeeWorkHistoryBasePage";

class MyPageWorkHistoryContainer extends PureComponent {
  render() {
    const { currentEmployee, breadcrumb, Icon } = this.props;

    return (
      <EmployeeWorkHistoryBasePage
        title={breadcrumb}
        employeeId={currentEmployee.get("employeeId")}
        Icon={Icon}
      />
    );
  }
}

export default compose(
  connect(state => ({
    currentEmployee: getCurrentEmployeeFromState(state),
  })),
)(MyPageWorkHistoryContainer);
