import { OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { SkillTypeMapper } from "app/app.mappers";

const initialState = OrderedMap();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_SKILL_TYPES: {
      if (error) return state;

      return OrderedMap(
        payload.map(skillType => [
          skillType.skillTypeId.toString(),
          SkillTypeMapper.from(skillType),
        ]),
      );
    }

    case ACTIONS.GET_SKILL_TYPE:
    case ACTIONS.UPDATE_SKILL_TYPE:
    case ACTIONS.CREATE_SKILL_TYPE: {
      if (error) return state;

      return state.set(
        payload.skillTypeId.toString(),
        SkillTypeMapper.from(payload),
      );
    }

    case ACTIONS.DELETE_SKILL_TYPE: {
      if (error) return state;

      return state.delete(action.skillTypeId.toString());
    }

    default:
      return state;
  }
};
