import { compose } from "redux";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";
import { reduxForm } from "redux-form/immutable";

import { BasePage } from "altus-ui-components";

import {
  collectionDetailsOnLoad,
  collectionDetailsOnChange,
} from "features/competence.actions";

import { ACTIONS } from "app/app.constants";
import { COLLECTION_FORM } from "features/competence.constants";
import { getActionDataStateFromState } from "app/app.selectors";
import CollectionForm from "features/hr/collections/components/CollectionForm";

class CollectionDetailsContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad, collectionId } = this.props;

    dispatchOnLoad(collectionId);
  }

  render() {
    const { breadcrumb, dataState } = this.props;

    return (
      <BasePage title={breadcrumb} dataState={dataState}>
        <Grid container item md={8} xs={12}>
          <CollectionForm />
        </Grid>
      </BasePage>
    );
  }
}

export default compose(
  connect(
    state => ({
      dataState: getActionDataStateFromState(state, ACTIONS.GET_COLLECTION),
    }),
    {
      dispatchOnLoad: collectionDetailsOnLoad,
    },
  ),
  reduxForm({
    form: COLLECTION_FORM.ID,
    onChange: debounce(collectionDetailsOnChange, 1000),
  }),
)(CollectionDetailsContainer);
