import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import {
  getRoleGapReportRolesFromState,
  getGapReportDepartmentsFromState,
  getRoleGapReportRoleCoursesByRoleFromState,
  getRoleGapReportRoleSkillsByRoleFromState,
} from "features/competence.selectors";

import { GAP_REPORT } from "app/app.constants";
import GapReport from "features/components/GapReport/GapReport";
import RoleGapReportHeader from "features/components/RoleGapReport/RoleGapReportHeader";
import RoleGapReportEmployeeRow from "features/components/RoleGapReport/RoleGapReportEmployeeRow";
import { getFeatureFlagStatusFromState } from "app/app.selectors";
import { EMPTY_LIST, FEATURE_FLAG } from "app/app.constants";

class RoleGapReport extends PureComponent {
  renderHeader = () => {
    const { roles, roleCourses, roleSkills } = this.props;

    return (
      <RoleGapReportHeader
        roles={roles}
        roleCourses={roleCourses}
        roleSkills={roleSkills}
      />
    );
  };

  renderEmployee = ({ employee, index }) => {
    const { root, roles, roleCourses, roleSkills } = this.props;

    return (
      <RoleGapReportEmployeeRow
        root={root}
        index={index}
        roles={roles}
        roleCourses={roleCourses}
        roleSkills={roleSkills}
        key={employee.get("employeeId")}
        employeeId={employee.get("employeeId")}
      />
    );
  };

  render() {
    const { root, title, employeesByDepartment, departments } = this.props;

    return (
      <GapReport
        root={root}
        title={title}
        renderHeader={this.renderHeader}
        departments={departments}
        employeesByDepartment={employeesByDepartment}
        renderEmployee={({ employee, index }) =>
          this.renderEmployee({ employee, index })
        }
        gapReportType={GAP_REPORT.ROLE_GAP_REPORT}
      />
    );
  }
}

export default compose(
  connect(state => {
    const isSkillsFeatureFlagEnabled = getFeatureFlagStatusFromState(
      state,
      FEATURE_FLAG.SKILLS,
    );

    return {
      roles: getRoleGapReportRolesFromState(state),
      departments: getGapReportDepartmentsFromState(state),
      roleCourses: getRoleGapReportRoleCoursesByRoleFromState(state),
      roleSkills: isSkillsFeatureFlagEnabled
        ? getRoleGapReportRoleSkillsByRoleFromState(state)
        : EMPTY_LIST,
    };
  }),
)(RoleGapReport);
