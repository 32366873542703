import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Helmet } from "altus-ui-components";

import {
  hrSkillGapReportOnLoad,
  getEmployeeSkillGapReport,
  downloadAndSaveSkillGapReport,
} from "features/competence.actions";

import { APP_ROOT } from "app/app.constants";
import SkillGapReportBasePage from "features/components/SkillGapReport/SkillGapReportBasePage";

class HRSkillGapReportContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad } = this.props;

    dispatchOnLoad();
  }

  render() {
    const {
      breadcrumb,
      dispatchGetSkillGapReport,
      dispatchDownloadSkillGapReport,
    } = this.props;

    return (
      <>
        <Helmet titleTemplate="%s">
          <title>{breadcrumb}</title>
        </Helmet>
        <SkillGapReportBasePage
          root={APP_ROOT.HR}
          title={breadcrumb}
          getSkillGapReport={dispatchGetSkillGapReport}
          downloadSkillGapReport={dispatchDownloadSkillGapReport}
        />
      </>
    );
  }
}

export default compose(
  connect(null, {
    dispatchOnLoad: hrSkillGapReportOnLoad,
    dispatchGetSkillGapReport: getEmployeeSkillGapReport,
    dispatchDownloadSkillGapReport: downloadAndSaveSkillGapReport,
  }),
)(HRSkillGapReportContainer);
