import { compose } from "redux";
import debounce from "lodash/debounce";
import React, { useMemo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { Table, IncrementDecrementButton } from "altus-ui-components";

import { EMPTY_MAP } from "app/app.constants";

const ExperienceRecordCategory = ({
  classes,
  readOnly,
  category = EMPTY_MAP,
  recordItems = EMPTY_MAP,
  onUpdateExperienceRecordItem,
}) => {
  const columns = useMemo(
    () => [
      ...category
        .get("counters")
        .toJS()
        .map(counter => ({
          xs: 2,
          id: "CounterId_" + counter.experienceCategoryCounterId,
          Header: counter.name,
          Cell: ({ row }) => {
            const counterValue =
              recordItems
                .filter(
                  ri =>
                    ri.get("experienceItemId").toString() ===
                      row.original.get("experienceItemId").toString() &&
                    ri.get("experienceCategoryCounterId").toString() ===
                      counter.experienceCategoryCounterId.toString(),
                )
                .first()
                ?.get("count") ?? 0;

            return (
              <IncrementDecrementButton
                minValue={0}
                value={counterValue}
                ButtonGroupProps={{
                  disabled: readOnly,
                  classes: { root: classes.incrementDecrementButton },
                }}
                onChange={debounce((_, newCount) => {
                  onUpdateExperienceRecordItem({
                    experienceItemId: row.original
                      .get("experienceItemId")
                      .toString(),
                    experienceCategoryCounterId:
                      counter.experienceCategoryCounterId,
                    count: newCount,
                  });
                }, 1000)}
              />
            );
          },
        })),
      {
        xs: true,
        id: categoryItem => categoryItem.get("experienceItemId"),
        Header: category.get("itemsTitle"),
        accessor: categoryItem => categoryItem.get("name"),
      },
    ],
    [category, recordItems, onUpdateExperienceRecordItem, classes, readOnly],
  );

  return (
    <Grid container item>
      <Typography variant="h6" className={classes.title}>
        {category.get("name")}
      </Typography>
      <Table
        stickyHeader
        columns={columns}
        useGlobalFilter={false}
        noItemsMessage="No records"
        items={category.get("items")}
      />
    </Grid>
  );
};

const styles = theme => ({
  title: {
    display: "flex",
  },
  incrementDecrementButton: {
    "& > button": {
      padding: "2px 0px",
    },
  },
});

export default compose(
  React.memo,
  withStyles(styles),
)(ExperienceRecordCategory);
