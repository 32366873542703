import Agent from "infrastructure/agent";

const getPendingExperienceRecordsForValidator = employeeId =>
  Agent.get(`/experience/validator/${employeeId}/records/pending`);

const approveExperienceRecord = (employeeId, experienceRecordId) =>
  Agent.post(
    `/experience/validator/${employeeId}/records/${experienceRecordId}/approve`,
  );

const rejectExperienceRecord = (employeeId, experienceRecordId) =>
  Agent.post(
    `/experience/validator/${employeeId}/records/${experienceRecordId}/reject`,
  );

const undoApprovalExperienceRecord = (employeeId, experienceRecordId) =>
  Agent.post(
    `/experience/validator/${employeeId}/records/${experienceRecordId}/undo-approval`,
  );

const changeExperienceRecordValidator = (
  employeeId,
  experienceRecordId,
  validatorId,
) =>
  Agent.post(
    `/experience/validator/${employeeId}/records/${experienceRecordId}/changeValidator/${validatorId}`,
  );

export default {
  rejectExperienceRecord,
  approveExperienceRecord,
  undoApprovalExperienceRecord,
  changeExperienceRecordValidator,
  getPendingExperienceRecordsForValidator,
};
