import { compose } from "redux";
import React, { Component } from "react";
import { withStyles } from "@material-ui/core";

import { Autocomplete } from "altus-ui-components";

class GapReportAutocompleteFilter extends Component {
  render() {
    const {
      label,
      value,
      classes,
      options,
      placeholder,
      displayOptionsCount,
      ...rest
    } = this.props;

    return (
      <Autocomplete
        multiple
        limitTags={1}
        options={options}
        value={value ? value.toArray().filter(Boolean) : value}
        TextFieldProps={{
          label: displayOptionsCount ? `${label} (${options.size})` : label,
          margin: "none",
          fullWidth: true,
          variant: "filled",
          placeholder: "Search..",
          classes: {
            root: classes.textFieldRoot,
          },
        }}
        {...rest}
      />
    );
  }
}

const styles = theme => ({
  textFieldRoot: {
    "& .MuiFilledInput-root": {
      background: theme.palette.common.white,
      "&:hover": {
        background: theme.palette.grey[100],
      },
    },
  },
});

GapReportAutocompleteFilter.defaultProps = {
  displayOptionsCount: true,
};

export default compose(withStyles(styles))(GapReportAutocompleteFilter);
