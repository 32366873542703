import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Typography, Grid } from "@material-ui/core";

import { BasePage } from "altus-ui-components";

import { clearCollections } from "features/competence.actions";
import { getCollectionsFromState } from "features/competence.selectors";
import CollectionConditionMatrix from "features/components/CollectionConditionMatrix/CollectionConditionMatrix";

class CourseCollectionContainer extends PureComponent {
  componentWillUnmount() {
    const { dispatchClearCollections } = this.props;

    dispatchClearCollections();
  }

  render() {
    const {
      title,
      dataState,
      collections,
      noResultsMessage,
      getOptionHighlighted,
    } = this.props;

    return (
      <BasePage title={title} dataState={dataState}>
        {!collections.size && (
          <Grid item xs container justify="center" alignItems="center">
            <Grid item>
              <Typography variant="caption">{noResultsMessage}</Typography>
            </Grid>
          </Grid>
        )}
        {collections.map(collection => (
          <CollectionConditionMatrix
            disabled
            getOptionHighlighted={getOptionHighlighted}
            key={collection.get("competencyCollectionId")}
            collectionId={collection.get("competencyCollectionId")}
          />
        ))}
      </BasePage>
    );
  }
}

export default compose(
  connect(
    state => ({
      collections: getCollectionsFromState(state),
    }),
    {
      dispatchClearCollections: clearCollections,
    },
  ),
)(CourseCollectionContainer);
