import { Map, OrderedMap, List } from "immutable";

import { ACTIONS } from "app/app.constants";

import {
  RoleMapper,
  RoleCourseMapper,
  EmployeeRoleMapper,
  EmployeeSkillMapper,
  RoleSkillMapper,
} from "app/app.mappers";

import employeeCourseMappers from "app/mappers/employeeCourse.mappers";

const initialState = Map({
  role: Map(),
  roleCourse: Map(),
  employeeCourse: Map(),
  employeeSkill: Map(),
});

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_ROLE_GAP_REPORT: {
      return initialState;
    }

    case ACTIONS.GET_ROLE_GAP_REPORT: {
      if (error) return state;

      return state
        .set(
          "role",
          OrderedMap(
            payload.roles.map(role => [
              role.roleId.toString(),
              RoleMapper.from(role),
            ]),
          ),
        )
        .set(
          "roleCourse",
          List(payload.roleCourses)
            .map(RoleCourseMapper.from)
            .groupBy(roleCourse => roleCourse.get("roleId").toString()),
        )
        .set(
          "roleSkill",
          List(payload.roleSkills)
            .map(RoleSkillMapper.from)
            .groupBy(roleSkill => roleSkill.get("roleId").toString()),
        )
        .set(
          "employeeRole",
          List(payload.roleEmployees)
            .map(EmployeeRoleMapper.from)
            .groupBy(employeeRole => employeeRole.get("employeeId").toString())
            .map(employeeRolesByEmployeeId =>
              Map(
                employeeRolesByEmployeeId.map(employeeRole => [
                  employeeRole.get("roleId").toString(),
                  employeeRole,
                ]),
              ),
            ),
        )
        .set(
          "employeeCourse",
          List(payload.employeeCourses)
            .map(employeeCourseMappers.EmployeeCourse.from)
            .groupBy(employeeCourse =>
              employeeCourse.get("employeeId").toString(),
            )
            .map(employeeCoursesByEmployeeId =>
              Map(
                employeeCoursesByEmployeeId.map(employeeCourse => [
                  employeeCourse.get("courseId").toString(),
                  employeeCourse,
                ]),
              ),
            ),
        )
        .set(
          "employeeSkill",
          List(payload.employeeSkills)
            .map(EmployeeSkillMapper.from)
            .groupBy(employeeSkill =>
              employeeSkill.get("employeeId").toString(),
            )
            .map(employeeSkillsByEmployeeId =>
              Map(
                employeeSkillsByEmployeeId.map(employeeSkill => [
                  employeeSkill.get("skillId").toString(),
                  employeeSkill,
                ]),
              ),
            ),
        );
    }

    default:
      return state;
  }
};
