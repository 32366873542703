import { compose } from "redux";
import { connect } from "react-redux";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Description from "@material-ui/icons/Description";
import { Grid, IconButton, Tooltip, withStyles } from "@material-ui/core";

import {
  initializeGenerateEmployeesCVs,
  generateAndDownloadEmployeesCVs,
} from "features/competence.actions";

import { getEmployeeStatsByEmployeeIdFromState } from "features/competence.selectors";

import CrudBasePage from "app/components/CrudBasePage";
import EmployeeAvatar from "app/components/EmployeeAvatar";
import GenerateCVModal from "features/components/GenerateCVModal";
import { getFeatureFlagStatusFromState } from "app/app.selectors";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";
import { GENERATE_EMPLOYEES_CVS_MODAL_ID } from "features/competence.constants";

import {
  SORT_DIRECTION,
  ICONS,
  EMPTY_ARRAY,
  FEATURE_FLAG,
} from "app/app.constants";

const renderIcon = employee => (
  <EmployeeAvatar
    employeeDisplayName={employee.get("displayName")}
    employeeHiredTo={employee.get("hiredTo")}
  />
);

const EmployeesBasePage = ({
  path,
  title,
  classes,
  dataState,
  employees,
  onRefresh,
  listActions: parentListActions = [],
  employeeStatsByEmployeeId,
  isPdfExportFeatureFlagEnabled,
  dispatchInitializeGenerateEmployeesCVs,
  dispatchGenerateAndDownloadEmployeesCVs,
}) => {
  const columns = useMemo(
    () => [
      {
        xs: 1,
        title: "#",
        getSortProperty: item => item.get("employeeNumber"),
      },
      {
        xs: true,
        title: "First Name",
        getSortProperty: item => item.get("firstName"),
      },
      {
        xs: true,
        title: "Last Name",
        getSortProperty: item => item.get("lastName"),
      },
      {
        xs: true,
        filter: {
          defaultText: "All positions",
        },
        title: "Position",
        getSortProperty: item => item.get("position"),
      },
      {
        xs: true,
        filter: {
          defaultText: "All departments",
        },
        title: "Department",
        getSortProperty: item => item.get("departmentName"),
      },
      {
        xs: 1,
        component: Grid,
        container: true,
        justify: "center",
        title: (
          <Tooltip title="Role Count">
            <ICONS.ROLE />
          </Tooltip>
        ),
        getSortProperty: item =>
          employeeStatsByEmployeeId.getIn([
            item.get("employeeId").toString(),
            "rolesCount",
          ]),
      },
      {
        xs: 1,
        component: Grid,
        container: true,
        justify: "center",
        title: (
          <Tooltip title="Skill Count">
            <ICONS.SKILL />
          </Tooltip>
        ),
        getSortProperty: item =>
          employeeStatsByEmployeeId.getIn([
            item.get("employeeId").toString(),
            "skillsCount",
          ]),
      },
      {
        xs: 1,
        component: Grid,
        container: true,
        justify: "center",
        title: (
          <Tooltip title="Course Count">
            <ICONS.COURSE />
          </Tooltip>
        ),
        getSortProperty: item =>
          employeeStatsByEmployeeId.getIn([
            item.get("employeeId").toString(),
            "coursesCount",
          ]),
      },
      {
        xs: true,
        title: "",
        component: Grid,
        container: true,
        justify: "flex-end",
        getSortProperty: item =>
          employeeStatsByEmployeeId.getIn([
            item.get("employeeId").toString(),
            "competencyStatus",
          ]),
        getValue: item => (
          <CompetencyStatusChip
            status={employeeStatsByEmployeeId.getIn([
              item.get("employeeId").toString(),
              "competencyStatus",
            ])}
          />
        ),
        filter: {
          label: "Status",
          defaultText: "All",
          getFilterText: filter => statusToTooltipText(filter),
        },
      },
    ],
    [employeeStatsByEmployeeId],
  );

  const listActions = useMemo(
    () => [
      {
        renderAction: ({ selectedItems }) => (
          <IconButton
            classes={{
              root: classes.iconButtonRoot,
            }}
            disabled={!selectedItems.size}
            onClick={() =>
              dispatchInitializeGenerateEmployeesCVs(selectedItems)
            }
          >
            <Description />
          </IconButton>
        ),
        getTitle: selectedItems => `Generate CV (${selectedItems.size})`,
      },
    ],
    [classes, dispatchInitializeGenerateEmployeesCVs],
  );

  return (
    <CrudBasePage
      Icon={ICONS.TEAM}
      title={title}
      items={employees}
      displaySearchField
      dataState={dataState}
      SortableListProps={{
        actions: EMPTY_ARRAY,
        columns,
        onRefresh,
        dataState,
        renderIcon,
        selectable: true,
        displayNumberOfItems: true,
        listActions: [...listActions, ...parentListActions],
        getKey: item => item.get("employeeId"),
        defaultSortDirection: SORT_DIRECTION.ASC,
        defaultSortColumn: columns.findIndex(
          item => item.title === "Last Name",
        ),
        createSortableListRowProps: item => ({
          component: Link,
          to: `${path}/${item.get("employeeId")}`,
        }),
      }}
    >
      <GenerateCVModal
        modalId={GENERATE_EMPLOYEES_CVS_MODAL_ID}
        onSubmit={dispatchGenerateAndDownloadEmployeesCVs}
        isPdfExportFeatureFlagEnabled={isPdfExportFeatureFlagEnabled}
      />
    </CrudBasePage>
  );
};

const styles = theme => ({
  iconButtonRoot: {
    padding: theme.spacing(1) + 1,
  },
});

export default compose(
  connect(
    state => ({
      employeeStatsByEmployeeId: getEmployeeStatsByEmployeeIdFromState(state),
      isPdfExportFeatureFlagEnabled: getFeatureFlagStatusFromState(
        state,
        FEATURE_FLAG.ExportPDF,
      ),
    }),
    {
      dispatchGenerateAndDownloadEmployeesCVs: generateAndDownloadEmployeesCVs,
      dispatchInitializeGenerateEmployeesCVs: initializeGenerateEmployeesCVs,
    },
  ),
  React.memo,
  withStyles(styles),
)(EmployeesBasePage);
