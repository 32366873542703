import React, { useCallback } from "react";

import { useToggle } from "altus-hooks";
import { MultiSelectModal } from "altus-ui-components";

import { EMPTY_LIST } from "app/app.constants";
import MultiSelectTextField from "app/components/MultiSelectTextField";

const GapReportModalItemSelector = ({
  label,
  value,
  options,
  getOptionLabel,
  onSelectionChange,
  displayOptionsCount = true,
}) => {
  const [isOpen, toggleModal] = useToggle(false);

  const onItemsSelect = useCallback(
    selectedItems => {
      onSelectionChange(selectedItems);
      toggleModal();
    },
    [toggleModal, onSelectionChange],
  );

  const onItemsClear = useCallback(() => onSelectionChange(EMPTY_LIST), [
    onSelectionChange,
  ]);

  return (
    <>
      <MultiSelectTextField
        value={value}
        onClick={toggleModal}
        onClear={onItemsClear}
        label={displayOptionsCount ? `${label} (${options.size})` : label}
      />
      <MultiSelectModal
        key={value}
        displayChips
        open={isOpen}
        items={options}
        onClose={toggleModal}
        getName={getOptionLabel}
        onSubmit={onItemsSelect}
        title={`Select ${label}`}
        initialSelectedItems={value}
      />
    </>
  );
};

export default GapReportModalItemSelector;
