import { compose } from "redux";
import { Map } from "immutable";
import { connect } from "react-redux";
import React, { useCallback, useEffect, useMemo } from "react";
import { Tooltip, IconButton, Box, Grid, withStyles } from "@material-ui/core";

import { TableRowActionsCell } from "altus-ui-components";

import {
  previewOrSaveEmployeeSkillFileEvidence,
  addSkillEvidenceAndFetchEmployeeeSkillEvidences,
  initializeAddEmployeeSkillEvidenceForm,
  deleteEmployeeSkillEvidenceAskConfirmation,
  employeeSkillFileEvidencesContainerOnLoad,
} from "features/competence.actions";

import {
  getEmployeeSkillEvidencesFromState,
  getSkillFromState,
} from "features/competence.selectors";

import {
  getCurrentUserFromState,
  getSummarizedDataStatesFromState,
} from "app/app.selectors";

import { isAdminOrHR } from "utils/app.util";
import { formatDate } from "utils/format.util";
import { ACTIONS, ICONS, Format } from "app/app.constants";
import withToggleModal from "app/components/withToggleModal";
import SortableTable from "app/components/Table/SortableTable";
import DownloadFileColumn from "app/components/DownloadFileColumn";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";
import { ADD_EMPLOYEE_SKILL_EVIDENCE_MODAL_ID } from "features/competence.constants";
import AddEmployeeSkillEvidenceModal from "features/components/AddEmployeeSkillEvidenceModal";

const EmployeeSkillFileEvidencesContainer = ({
  skill,
  skillId,
  employeeId,
  currentUser,
  dispatchOnLoad,
  dispatchToggleModal,
  employeeSkillEvidences,
  dispatchAddEmployeeSkillEvidenceOnSubmit,
  dispatchInitializeAddEmployeeSkillEvidence,
  dispatchPreviewOrSaveEmployeeSkillEvidenceFile,
  dispatchDeleteEmployeeSkillEvidenceAskConfirmation,
}) => {
  const loadEmployeeSkillEvidences = useCallback(() => {
    dispatchOnLoad(employeeId, skillId);
  }, [employeeId, skillId, dispatchOnLoad]);

  useEffect(() => {
    loadEmployeeSkillEvidences(employeeId, skillId);
  }, [employeeId, skillId, loadEmployeeSkillEvidences]);

  const columns = useMemo(
    () => [
      {
        xs: 6,
        component: Grid,
        id: "name",
        title: "Evidence",
        Header: "Evidence",
        getSortProperty: item => item.get("name"),
        Cell: ({ row }) => {
          if (row.depth === 0) return row.original.get("name");
          return (
            <DownloadFileColumn
              file={row.original}
              onClick={() =>
                dispatchPreviewOrSaveEmployeeSkillEvidenceFile(
                  employeeId,
                  skillId,
                  row.original,
                )
              }
            />
          );
        },
      },
      {
        xs: true,
        id: "registrationDate",
        title: "Registration",
        Header: "Registration",
        getSortProperty: item =>
          formatDate(item.get("registrationDate"), Format.date),
        Cell: ({ row }) => {
          if (row.depth === 0)
            return formatDate(
              row.original.get("registrationDate"),
              Format.date,
            );
          return "";
        },
      },
      {
        xs: true,
        id: "expirationDate",
        title: "Expires",
        Header: "Expires",
        getSortProperty: item =>
          formatDate(item.get("expirationDate"), Format.date),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return formatDate(row.original.get("expirationDate"), Format.date);
        },
      },
      {
        xs: true,
        title: "",
        id: "competencyStatus",
        Header: "Status",
        component: Grid,
        container: true,
        justify: "flex-end",
        getSortProperty: item => item.get("competencyStatus"),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return (
            <CompetencyStatusChip
              status={row.original.get("competencyStatus")}
            />
          );
        },
        filter: {
          label: "Status",
          defaultText: "All",
          getFilterText: filter => statusToTooltipText(filter),
        },
      },
      {
        id: "actions",
        Header: <TableRowActionsCell minItems={2} />,
        Footer: <TableRowActionsCell minItems={2} />,
        Cell: ({ row }) => {
          var canDelete = true;
          if (row.depth !== 0) return "";

          canDelete =
            !row.original.get("isApproved") || isAdminOrHR(currentUser);
          return (
            <TableRowActionsCell minItems={2}>
              <Tooltip
                title={
                  !canDelete
                    ? "Only HR can delete approved skill evidence"
                    : "Delete skill evidence attendance"
                }
              >
                <Box>
                  <IconButton
                    disabled={!canDelete}
                    onClick={() =>
                      dispatchDeleteEmployeeSkillEvidenceAskConfirmation(
                        row.original,
                      )
                    }
                  >
                    <ICONS.DELETE fontSize="small" />
                  </IconButton>
                </Box>
              </Tooltip>
            </TableRowActionsCell>
          );
        },
      },
    ],
    [
      skillId,
      employeeId,
      currentUser,
      dispatchPreviewOrSaveEmployeeSkillEvidenceFile,
      dispatchDeleteEmployeeSkillEvidenceAskConfirmation,
    ],
  );

  const initializeAndToggleAddEmployeeSkillEvidence = () => {
    dispatchInitializeAddEmployeeSkillEvidence(
      Map({
        employeeId: employeeId,
        skillId: skill.get("skillId"),
        skillName: skill.get("name"),
      }),
      toggleAddEvidenceModal,
    );
  };

  const toggleAddEvidenceModal = () =>
    dispatchToggleModal({
      modalId: ADD_EMPLOYEE_SKILL_EVIDENCE_MODAL_ID,
    });
  return (
    <>
      <SortableTable
        stickyHeader
        disableSortBy
        displayAddButton
        columns={columns}
        title="Evidences"
        addButtonOnClick={initializeAndToggleAddEmployeeSkillEvidence}
        keyName="skillEvidenceId"
        useGlobalFilter={false}
        subListPropertyName="files"
        subListKey="employeeSkillEvidenceFileId"
        items={employeeSkillEvidences}
        noItemsMessage="No skill evidence added yet..."
      />
      <AddEmployeeSkillEvidenceModal
        employeeId={employeeId}
        addEmployeeSkillEvidenceOnSubmit={
          dispatchAddEmployeeSkillEvidenceOnSubmit
        }
      />
    </>
  );
};

const styles = () => ({
  formControlLabelRoot: {
    marginLeft: 0,
  },
});

export default compose(
  connect(
    (state, { employeeId, skillId }) => ({
      currentUser: getCurrentUserFromState(state),

      skill: getSkillFromState(state, skillId),
      employeeSkillEvidences: getEmployeeSkillEvidencesFromState(
        state,
        employeeId,
        skillId,
      ),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_EMPLOYEE_SKILL_EVIDENCES,
        ACTIONS.DELETE_EMPLOYEE_SKILL_FILE_EVIDENCE,
      ),
    }),
    {
      dispatchOnLoad: employeeSkillFileEvidencesContainerOnLoad,
      dispatchDeleteEmployeeSkillEvidence: deleteEmployeeSkillEvidenceAskConfirmation,
      dispatchPreviewOrSaveEmployeeSkillEvidenceFile: previewOrSaveEmployeeSkillFileEvidence,
      dispatchAddEmployeeSkillEvidenceOnSubmit: addSkillEvidenceAndFetchEmployeeeSkillEvidences,
      dispatchInitializeAddEmployeeSkillEvidence: initializeAddEmployeeSkillEvidenceForm,
      dispatchDeleteEmployeeSkillEvidenceAskConfirmation: deleteEmployeeSkillEvidenceAskConfirmation,
    },
  ),
  withStyles(styles),
  withToggleModal,
)(EmployeeSkillFileEvidencesContainer);
