import { compose } from "redux";
import { List } from "immutable";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";

import {
  getSkillsGroupedBySkillIdFromState,
  getGapReportEmployeesByDepartmentFromState,
} from "features/competence.selectors";

import { invokeIfFunction } from "utils/app.util";
import { EMPTY_MAP, EMPTY_LIST } from "app/app.constants";
import { BASE_FILTERS } from "features/components/GapReport/GapReportFilterBase";
import GapReportFilterBase from "features/components/GapReport/GapReportFilterBase";
import GapReportAutocompleteFilter from "features/components/GapReport/GapReportAutocompleteFilter";

const FILTERS = {
  SKILLS: "skills",
};

const initialFilter = EMPTY_MAP.set(BASE_FILTERS.DEPARTMENTS, EMPTY_LIST)
  .set(BASE_FILTERS.STATUS, EMPTY_LIST)
  .set(FILTERS.SKILLS, EMPTY_LIST)
  .set(BASE_FILTERS.EMPLOYEES, EMPTY_LIST);

class SkillGapReportFilter extends PureComponent {
  renderFilter = ({
    filter,
    setFilter,
    renderStatusFilter,
    renderEmployeeFilter,
    renderDepartmentFilter,
  }) => {
    const { skillsBySkillId } = this.props;

    return (
      <>
        {invokeIfFunction(renderDepartmentFilter)}
        {invokeIfFunction(renderEmployeeFilter)}
        <Grid item xs>
          <GapReportAutocompleteFilter
            label="Skills"
            options={skillsBySkillId}
            getOptionLabel={skill => skill.get("name")}
            value={filter
              .get(FILTERS.SKILLS)
              .map(skillId => skillsBySkillId.get(skillId.toString()))}
            onChange={(_, value) =>
              setFilter(
                FILTERS.SKILLS,
                List(value.map(skill => skill.get("skillId"))),
              )
            }
          />
        </Grid>
        {invokeIfFunction(renderStatusFilter)}
      </>
    );
  };

  render() {
    const {
      getGapReport,
      clearGapReport,
      skillsBySkillId,
      employeesByDepartment,
      downloadSkillGapReport,
    } = this.props;

    return (
      <GapReportFilterBase
        getGapReport={getGapReport}
        initialFilter={initialFilter}
        clearGapReport={clearGapReport}
        renderFilter={this.renderFilter}
        skillsBySkillId={skillsBySkillId} // force rerender when this changes
        downloadGapReport={downloadSkillGapReport}
        downloadBtnDisabled={!employeesByDepartment.size}
      />
    );
  }
}

export default compose(
  connect(state => ({
    skillsBySkillId: getSkillsGroupedBySkillIdFromState(state),
    employeesByDepartment: getGapReportEmployeesByDepartmentFromState(state),
  })),
)(SkillGapReportFilter);
