import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import {
  getCourseFromState,
  getRoleCoursesFromState,
} from "features/competence.selectors";

import RoleCourseListContainer from "features/components/RoleCourseList/RoleCourseListContainer";

class RoleRoleCourseList extends PureComponent {
  render() {
    return <RoleCourseListContainer {...this.props} />;
  }
}

export default compose(
  connect(
    (state, { roleId }) => ({
      items: getRoleCoursesFromState(state, roleId).map(roleCourse =>
        roleCourse.set(
          "course",
          getCourseFromState(state, roleCourse.get("courseId")),
        ),
      ),
    }),
    null,
  ),
)(RoleRoleCourseList);
