import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import {
  getCourseFromState,
  getCourseStatsFromState,
} from "features/competence.selectors";

import { CourseMapper } from "app/app.mappers";
import { EMPTY_MAP, ICONS } from "app/app.constants";
import EntityDetailsHeader from "app/components/EntityDetailsHeader";

const CourseDetailsHeader = ({
  children,
  courseStats = EMPTY_MAP,
  course = CourseMapper.initial,
}) => (
  <EntityDetailsHeader
    Icon={<ICONS.COURSE />}
    title={course.get("name")}
    subtitle={course.get("courseTypeName")}
    competencyStatus={courseStats.get("competencyStatus")}
  >
    {children}
  </EntityDetailsHeader>
);

export default compose(
  connect((state, { courseId }) => ({
    course: getCourseFromState(state, courseId),
    courseStats: getCourseStatsFromState(state, courseId),
  })),
)(CourseDetailsHeader);
