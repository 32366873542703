import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import {
  SKILL_FORM,
  SKILL_DETAILS_MODAL_ID,
} from "features/competence.constants";

import { getFormValuesFromState } from "app/app.selectors";
import SimpleFormModal from "app/components/SimpleFormModal";
import SkillDetails from "features/components/SkillDetails";

const SkillDetailsModal = ({ skill }) => {
  return (
    <SimpleFormModal
      submitText="Generate"
      displayCancelBtn={false}
      displaySubmitBtn={false}
      component={SkillDetails}
      modalId={SKILL_DETAILS_MODAL_ID}
      title={skill ? skill.get("name") : ""}
      FormProps={{
        skill,
      }}
      form={{
        form: SKILL_FORM.ID,
      }}
    />
  );
};

export default compose(
  connect(state => ({
    skill: getFormValuesFromState(state, SKILL_FORM.ID),
  })),
  React.memo,
)(SkillDetailsModal);
