import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";
import { Tooltip, IconButton } from "@material-ui/core";

import {
  coursesOnLoad,
  coursesOnUnload,
  createCourseOnSubmit,
  initializeCreateCourse,
  deleteCoursesAskConfirmation,
  deleteCourseAskConfirmation,
  getStatsForCourses,
} from "features/competence.actions";

import {
  getCoursesFromState,
  getCourseStatsByCourseIdFromState,
} from "features/competence.selectors";

import { ACTIONS, ICONS, EMPTY_MAP } from "app/app.constants";
import CrudBasePage from "app/components/CrudBasePage";
import { COURSE_FORM } from "features/competence.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import CourseForm from "features/hr/courses/components/CourseForm";
import CourseELearningLink from "features/hr/courses/components/CourseELearningLink";
import CourseProgressBar from "features/course/CourseProgressBar";

class CoursesContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad, dispatchGetStatsForCourses } = this.props;

    dispatchOnLoad();
    dispatchGetStatsForCourses();
  }

  componentWillUnmount() {
    const { dispatchOnUnload } = this.props;
    dispatchOnUnload();
  }

  columns = [
    {
      xs: 3,
      title: "Course Name",
      getSortProperty: item => item.get("name"),
    },
    {
      xs: true,
      filter: {
        label: "Group",
        defaultText: "All groups",
      },
      title: "Group",
      getSortProperty: item => item.get("courseGroupName"),
    },
    {
      xs: true,
      filter: {
        multiple: true,
        label: "Type",
        defaultText: "All types",
      },
      title: "Type",
      getSortProperty: item => item.get("courseTypeName"),
    },
    {
      xs: true,
      title: "Valid",
      getSortProperty: item => item.get("validityMonthsString"),
    },
    {
      filter: {
        defaultText: "Any",
        label: "Countries",
        getFilterText: filter => (filter === "" ? "Global" : filter),
      },
      xs: true,
      title: "Countries",
      getSortProperty: item =>
        item
          .get("countries")
          .map(c => c.get("twoLetterCode"))
          .join(", "),
    },
    {
      xs: true,
      component: Grid,
      title: "Ext. Link",
      getValue: item => <CourseELearningLink link={item.get("externalUrl")} />,
    },
    {
      xs: 1,
      container: true,
      justify: "center",
      title: (
        <Tooltip title="Role Count">
          <ICONS.ROLE />
        </Tooltip>
      ),
      getSortProperty: item =>
        this.props.courseStatsByCourseId.getIn(
          [item.get("courseId").toString(), "rolesCount"],
          "-",
        ),
    },
    {
      xs: 1,
      container: true,
      justify: "center",
      title: (
        <Tooltip title="Member Count">
          <ICONS.TEAM />
        </Tooltip>
      ),
      getSortProperty: item =>
        this.props.courseStatsByCourseId.getIn(
          [item.get("courseId").toString(), "memberCount"],
          "-",
        ),
    },
    {
      xs: true,
      component: Grid,
      title: "Coverage",
      getValue: item => (
        <CourseProgressBar courseId={item.get("courseId").toString()} />
      ),
    },
  ];

  listActions = [
    {
      renderAction: ({ selectedItems }) => (
        <IconButton
          classes={{
            root: styles.iconButtonRoot,
          }}
          disabled={!selectedItems.size}
          onClick={() => this.props.dispatchDeleteCourses(selectedItems)}
        >
          <ICONS.DELETE />
        </IconButton>
      ),
      getTitle: selectedItems => `Delete (${selectedItems.size})`,
    },
  ];
  actions = [
    {
      getValue: item => (
        <Tooltip title="Delete">
          <IconButton onClick={() => this.props.dispatchDeleteCourse(item)}>
            <ICONS.DELETE fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  render() {
    const {
      courses,
      dataState,
      breadcrumb,
      dispatchOnLoad,
      dispatchCreateCourseOnSubmit,
      dispatchInitializeCreateCourse,
    } = this.props;

    return (
      <CrudBasePage
        title={breadcrumb}
        items={courses}
        displayAddButton
        displaySearchField
        Icon={ICONS.COURSE}
        EditEntityFormComponent={CourseForm}
        CreateEntityFormComponent={CourseForm}
        dataState={dataState}
        SortableListProps={{
          selectable: true,
          columns: this.columns,
          actions: this.actions,
          onRefresh: dispatchOnLoad,
          displayNumberOfItems: true,
          listActions: this.listActions,
          getKey: item => item.get("courseId"),
          createSortableListRowProps: item => ({
            component: Link,
            to: `courses/${item.get("courseId")}`,
          }),
        }}
        CreateModalProps={{
          submitText: "Add",
          title: "Add course",
          onSubmit: dispatchCreateCourseOnSubmit,
          onEnter: dispatchInitializeCreateCourse,
        }}
        createEntityForm={{
          form: COURSE_FORM.ID,
        }}
      />
    );
  }
}

CoursesContainer.defaultProps = {
  courseStatsByCourseId: EMPTY_MAP,
};

const styles = theme => ({
  iconButtonRoot: {
    padding: theme.spacing(1),
  },
});

export default compose(
  connect(
    state => ({
      courses: getCoursesFromState(state),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_COURSES,
        ACTIONS.DELETE_COURSE,
        ACTIONS.DELETE_COURSES,
      ),
      courseStatsByCourseId: getCourseStatsByCourseIdFromState(state),
    }),
    {
      dispatchOnLoad: coursesOnLoad,
      dispatchOnUnload: coursesOnUnload,
      dispatchDeleteCourse: deleteCourseAskConfirmation,
      dispatchDeleteCourses: deleteCoursesAskConfirmation,
      dispatchCreateCourseOnSubmit: createCourseOnSubmit,
      dispatchInitializeCreateCourse: initializeCreateCourse,
      dispatchGetStatsForCourses: getStatsForCourses,
    },
  ),
)(CoursesContainer);
