import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useMemo, useState } from "react";
import { Grid, IconButton, Tooltip, Box } from "@material-ui/core";

import { TableRowActionsCell } from "altus-ui-components";

import {
  getWorkHistory,
  workHistoryOnUnload,
  editWorkHistoryOnSubmit,
  initializeEditWorkHistory,
  deleteWorkHistoryFileAskConfirmation,
  deleteWorkHistoryItemAskConfirmation,
  deleteWorkHistoryItemsAskConfirmation,
  previewOrSaveWorkHistoryFile,
} from "features/competence.actions";

import {
  getCurrentUserFromState,
  getSummarizedDataStatesFromState,
} from "app/app.selectors";

import {
  WORK_HISTORY_FORM,
  ADD_EMPLOYEE_WORK_HISTORY_MODAL_ID,
} from "features/competence.constants";

import { formatDate } from "utils/format.util";
import { createGuid, isAdminOrHR } from "utils/app.util";
import { ICONS, ACTIONS, Format } from "app/app.constants";
import withToggleModal from "app/components/withToggleModal";
import SortableTable from "app/components/Table/SortableTable";
import DownloadFileColumn from "app/components/DownloadFileColumn";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import { getWorkHistoryFromState } from "features/competence.selectors";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";
import WorkHistoryForm from "features/employee/workHistory/components/WorkHistoryForm";
import AddWorkHistoryModal from "features/employee/workHistory/components/AddWorkHistoryModal";

const EmployeeWorkHistoryContainer = ({
  classes,
  employeeId,
  currentUser,
  workHistories,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchToggleModal,
  dispatchEditWorkHistoryOnSubmit,
  dispatchInitializEditWorkHistory,
  dispatchWorkHistoryOnNotAllowed,
  dispatchDeleteWorkHistoryItem,
  dispatchPreviewOrSaveWorkHistoryFile,
  dispatchDeleteWorkHistoryFileAskConfirmation,
}) => {
  useEffect(() => {
    dispatchOnLoad(employeeId);
  }, [employeeId, dispatchOnLoad]);

  useEffect(() => {
    dispatchOnUnload();
  }, [dispatchOnUnload]);

  const columns = useMemo(
    () => [
      {
        xl: true,
        id: "icon",
        Header: <TableRowActionsCell className={classes.root} minItems={2} />,
        Cell: ({ row }) => {
          if (row.depth !== 0) return <TableRowActionsCell minItems={1} />;

          return (
            <TableRowActionsCell>
              <ICONS.HISTORY color="primary" />
            </TableRowActionsCell>
          );
        },
      },
      {
        id: "title",
        title: "Title",
        Header: "Title",
        xs: 2,
        getSortProperty: item => item.get("title"),
        Cell: ({ row }) => {
          if (row.depth === 0) return row.original.get("title");

          return (
            <DownloadFileColumn
              file={row.original}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();

                dispatchPreviewOrSaveWorkHistoryFile(
                  employeeId,
                  row.original.workHistoryId,
                  row.original,
                );
              }}
            />
          );
        },
      },
      {
        id: "Company",
        Header: "Company",
        title: "Company",
        xs: 2,
        filter: {
          defaultText: "All companies",
        },
        getSortProperty: item => item.get("company"),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return row.original.get("company");
        },
      },
      {
        id: "from",
        title: "From",
        Header: "From",
        xs: 1,
        getSortProperty: item => formatDate(item.get("from"), Format.date),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return formatDate(row.original.get("from"), Format.date);
        },
      },
      {
        id: "to",
        title: "To",
        Header: "To",
        xs: 1,
        getSortProperty: item => formatDate(item.get("to"), Format.date),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return formatDate(row.original.get("to"), Format.date);
        },
      },
      {
        id: "category",
        title: "Category",
        Header: "Category",
        xs: 2,
        filter: {
          defaultText: "All categories",
        },
        getSortProperty: item => item.get("parentCompetencyCategoryName"),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return row.original.get("parentCompetencyCategoryName");
        },
      },
      {
        id: "subCategory",
        title: "Sub-category",
        Header: "Sub-category",
        xs: 1,
        filter: {
          defaultText: "All sub-categories",
        },
        getSortProperty: item => item.get("competencyCategoryName"),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return row.original.get("competencyCategoryName");
        },
      },
      {
        xs: 1,
        id: "competencyStatus",
        title: "",
        component: Grid,
        container: true,
        justify: "flex-end",
        getSortProperty: item => item.get("competencyStatus"),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return (
            <CompetencyStatusChip
              status={row.original.get("competencyStatus")}
            />
          );
        },
        filter: {
          label: "Status",
          defaultText: "All",
          getFilterText: filter => statusToTooltipText(filter),
        },
      },
      {
        id: "actions",
        xs: 1,
        Header: <TableRowActionsCell minItems={1} />,
        Footer: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          var canDelete = true;

          if (row.depth === 0)
            canDelete =
              !row.original.get("isApproved") || isAdminOrHR(currentUser);

          return (
            <TableRowActionsCell minItems={1}>
              <Tooltip
                title={
                  row.depth === 0
                    ? !canDelete
                      ? "Only HR can delete approved work history"
                      : "Delete employee work history"
                    : "Delete employee work history file"
                }
              >
                <Box>
                  <IconButton
                    disabled={!canDelete}
                    onClick={event => {
                      event.preventDefault();
                      event.stopPropagation();
                      row.depth === 0
                        ? dispatchDeleteWorkHistoryItem(row.original)
                        : dispatchDeleteWorkHistoryFileAskConfirmation({
                            employeeId,
                            workHistoryId: row.original.workHistoryId,
                            workHistoryFileId: row.original.workHistoryFileId,
                            name: row.original.name,
                          });
                    }}
                  >
                    <ICONS.DELETE fontSize="small" />
                  </IconButton>
                </Box>
              </Tooltip>
            </TableRowActionsCell>
          );
        },
      },
    ],
    [
      employeeId,
      currentUser,
      dispatchDeleteWorkHistoryItem,
      dispatchPreviewOrSaveWorkHistoryFile,
      dispatchDeleteWorkHistoryFileAskConfirmation,
      classes,
    ],
  );

  const toggleCreateModal = () =>
    dispatchToggleModal({
      modalId: ADD_EMPLOYEE_WORK_HISTORY_MODAL_ID,
    });

  const [editModalId, setEditModalId] = useState(0);

  useState(() => {
    setEditModalId(createGuid());
  }, []);

  const toggleEditModal = () => dispatchToggleModal({ modalId: editModalId });

  return (
    <>
      <SortableTable
        stickyHeader
        disableSortBy
        displayAddButton
        columns={columns}
        title="Work History"
        addButtonOnClick={toggleCreateModal}
        keyName="workHistoryId"
        useGlobalFilter={false}
        subListPropertyName="files"
        subListKey="workHistoryFileId"
        items={workHistories}
        noItemsMessage="No work history added yet..."
        editModalId={editModalId}
        toggleEditModal={toggleEditModal}
        onRowClick={item => {
          item.get("isApproved") && !isAdminOrHR(currentUser)
            ? dispatchWorkHistoryOnNotAllowed()
            : dispatchInitializEditWorkHistory(item, toggleEditModal);
        }}
        EditEntityFormComponent={WorkHistoryForm}
        EditModalProps={{
          submitText: "Save",
          title: "Edit Work History",
          formTitleField: WORK_HISTORY_FORM.TITLE,
          onSubmit: dispatchEditWorkHistoryOnSubmit,
          competencyStatusField: WORK_HISTORY_FORM.COMPETENCY_STATUS,
        }}
        editEntityForm={{
          form: WORK_HISTORY_FORM.ID,
        }}
        EditEntityFormProps={{
          employeeId,
        }}
      />
      <AddWorkHistoryModal employeeId={employeeId} />
    </>
  );
};

const workHistoryOnNotAllowed = () => dispatch =>
  dispatch({
    type: ACTIONS.CONFIRMATION_DIALOG_PENDING,
    confirmationDialog: {
      title: "Edit or Delete Work History",
      description: "You cannot edit or delete approved Work History items.",
      confirmButtonText: "Close",
      noCancel: true,
    },
  });

const styles = theme => ({
  root: {
    paddingRight: ` ${theme.spacing(4)}px`,
  },
});

export default compose(
  connect(
    state => ({
      currentUser: getCurrentUserFromState(state),
      workHistories: getWorkHistoryFromState(state),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_WORK_HISTORY,
        ACTIONS.DELETE_WORK_HISTORY,
      ),
    }),
    {
      dispatchOnLoad: getWorkHistory,
      dispatchOnUnload: workHistoryOnUnload,
      dispatchEditWorkHistoryOnSubmit: editWorkHistoryOnSubmit,
      dispatchInitializEditWorkHistory: initializeEditWorkHistory,
      dispatchDeleteWorkHistoryItem: deleteWorkHistoryItemAskConfirmation,
      dispatchDeleteWorkHistory: deleteWorkHistoryItemsAskConfirmation,
      dispatchDeleteWorkHistoryFileAskConfirmation: deleteWorkHistoryFileAskConfirmation,
      dispatchPreviewOrSaveWorkHistoryFile: previewOrSaveWorkHistoryFile,
      dispatchWorkHistoryOnNotAllowed: workHistoryOnNotAllowed,
    },
  ),
  withStyles(styles),
  withToggleModal,
)(EmployeeWorkHistoryContainer);
