import { Map } from "immutable";

import { ACTIONS } from "app/app.constants";
import employeeCourseMappers from "app/mappers/employeeCourse.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_EMPLOYEE_COURSES_BY_ROLE: {
      if (error) return state;

      return state.set(
        action.employeeId.toString(),
        Map(
          payload.map(employeeCourse => [
            employeeCourse.courseId.toString(),
            employeeCourseMappers.EmployeeCourse.from(employeeCourse),
          ]),
        ),
      );
    }

    case ACTIONS.GET_EMPLOYEES_BY_COURSE: {
      if (error) return state;

      return Map(
        payload.map(employeeCourse => [
          employeeCourse.employeeId.toString(),
          employeeCourseMappers.EmployeeCourse.from(employeeCourse),
        ]),
      );
    }

    case ACTIONS.GET_PREREQUISITE_EMPLOYEE_COURSES: {
      if (error) return state;

      return state.mergeIn(
        [action.employeeId.toString()],
        Map(
          payload.map(employeeCourse => [
            employeeCourse.courseId.toString(),
            employeeCourseMappers.EmployeeCourse.from(employeeCourse),
          ]),
        ),
      );
    }

    case ACTIONS.GET_EMPLOYEE_COURSE:
    case ACTIONS.UPDATE_EMPLOYEE_COURSE:
    case ACTIONS.CREATE_EMPLOYEE_COURSE: {
      if (error) return state;

      return state.setIn(
        [payload.employeeId.toString(), payload.courseId.toString()],
        employeeCourseMappers.EmployeeCourse.from(payload),
      );
    }

    case ACTIONS.DELETE_EMPLOYEE_COURSE: {
      if (error) return state;

      return state.deleteIn([
        action.employeeId.toString(),
        action.courseId.toString(),
      ]);
    }

    default:
      return state;
  }
};
