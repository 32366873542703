import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { initializeCreateWorkHistory } from "features/competence.actions";
import {
  WORK_HISTORY_FORM,
  ADD_EMPLOYEE_WORK_HISTORY_MODAL_ID,
} from "features/competence.constants";

import { EMPTY_SET } from "app/app.constants";
import SimpleFormModal from "app/components/SimpleFormModal";
import WorkHistoryForm from "features/employee/workHistory/components/WorkHistoryForm";
import AddWorkHistoryModalActions from "features/employee/workHistory/components/AddWorkHistoryModalActions";

const AddWorkHistoryModal = ({
  employeeId,
  dispatchInitializCreateWorkHistory,
}) => {
  return (
    <SimpleFormModal
      submitText="ADD"
      title="Add Work History"
      displaySubmitBtn={false}
      displayCancelBtn={false}
      onEnter={dispatchInitializCreateWorkHistory(employeeId)}
      component={WorkHistoryForm}
      modalId={ADD_EMPLOYEE_WORK_HISTORY_MODAL_ID}
      ModalActions={AddWorkHistoryModalActions}
      ModalActionsProps={{
        employeeId,
      }}
      FormProps={{
        employeeId,
      }}
      DialogProps={{
        maxWidth: "sm",
        fullWidth: true,
      }}
      form={{
        form: WORK_HISTORY_FORM.ID,
        initialValues: {
          [WORK_HISTORY_FORM.FILES]: EMPTY_SET,
          [WORK_HISTORY_FORM.EMPLOYEE_ID]: employeeId,
        },
      }}
    />
  );
};

export default compose(
  connect(null, {
    dispatchInitializCreateWorkHistory: initializeCreateWorkHistory,
  }),
)(AddWorkHistoryModal);
