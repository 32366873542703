import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";

import {
  getEmployeeFromState,
  getEmployeeStatsFromState,
} from "features/competence.selectors";

import { getInitials } from "utils/app.util";
import { EmployeeMapper } from "app/app.mappers";
import { EMPTY_MAP, FEATURE_FLAG } from "app/app.constants";
import { getFeatureFlagStatusFromState } from "app/app.selectors";
import EntityDetailsHeader from "app/components/EntityDetailsHeader";

const EmployeeDetailsHeader = ({
  path,
  children,
  employeeStats = EMPTY_MAP,
  isExperienceFeatureFlagEnabled,
  employee = EmployeeMapper.initial,
}) => (
  <Switch>
    <Route component={null} path={`${path}/courses/:courseId`} />
    <Route component={null} path={`${path}/roles/:roleId`} />
    <Route component={null} path={`${path}/skills/:skillId`} />
    <Route component={null} path={`${path}/experiences/:experienceRecordId`} />

    <Route
      render={() => (
        <>
          <EntityDetailsHeader
            title={employee.get("displayName")}
            subtitle={employee.get("departmentName")}
            Icon={getInitials(employee.get("displayName"))}
            competencyStatus={employeeStats.get("competencyStatus")}
            experiencePoints={employeeStats.get("experiencePoints")}
            isExperienceFeatureFlagEnabled={isExperienceFeatureFlagEnabled}
          />
          {children}
        </>
      )}
    />
  </Switch>
);

export default compose(
  connect((state, { employeeId }) => ({
    employee: getEmployeeFromState(state, employeeId),
    employeeStats: getEmployeeStatsFromState(state, employeeId),
    isExperienceFeatureFlagEnabled: getFeatureFlagStatusFromState(
      state,
      FEATURE_FLAG.EXPERIENCES,
    ),
  })),
)(EmployeeDetailsHeader);
