import Agent from "infrastructure/agent";

import appService from "services/app.service";
import employeeCourseMappers from "app/mappers/employeeCourse.mappers";

/**
 * Employee Courses
 */
const getEmployeeCourses = employeeId =>
  Agent.get(`/employee/${employeeId}/course`);

const getEmployeeRoleCourseList = employeeId =>
  Agent.get(`/employee/${employeeId}/course/byrole`);

const getEmployeeCourse = (employeeId, courseId) =>
  Agent.get(`/employee/${employeeId}/course/${courseId}`);

const createEmployeeCourse = (employeeId, courseId) =>
  Agent.post(`/employee/${employeeId}/course/${courseId}`);

const getPrerequisiteEmployeeCourses = (employeeId, courseId) =>
  Agent.get(`/employee/${employeeId}/course/${courseId}/prerequisite`);

/**
 * Employee Course Attendances
 */

const getEmployeeCourseAttendances = (employeeId, courseId, filter) =>
  Agent.get(`/employee/${employeeId}/course/${courseId}/attendance/`, filter);

const createEmployeeCourseAttendance = (employeeId, courseId, attendance) =>
  Agent.post(
    `/employee/${employeeId}/course/${courseId}/attendance`,
    employeeCourseMappers.EmployeeCourseAttendance.to.create(attendance),
  );

const createAndApproveEmployeeCourseAttendance = (
  employeeId,
  courseId,
  attendance,
) =>
  Agent.post(
    `/employee/${employeeId}/course/${courseId}/attendance/approve`,
    employeeCourseMappers.EmployeeCourseAttendance.to.create(attendance),
  );

const createEmployeeCourseAttendanceOverride = (
  employeeId,
  courseId,
  attendance,
) =>
  Agent.post(
    `/employee/${employeeId}/course/${courseId}/attendance/override`,
    employeeCourseMappers.EmployeeCourseAttendance.to.createOverride(
      attendance,
    ),
  );

const deleteEmployeeCourse = (employeeId, courseId) =>
  Agent.delete(`/employee/${employeeId}/course/${courseId}`);

const deleteEmployeeCourseAttendance = (employeeId, courseId, attendanceId) =>
  Agent.delete(
    `/employee/${employeeId}/course/${courseId}/attendance/${attendanceId}`,
  );

const deleteEmployeeCourseAttendanceApproval = (
  employeeId,
  courseId,
  attendanceId,
) =>
  Agent.delete(
    `/employee/${employeeId}/course/${courseId}/attendance/${attendanceId}/approval`,
  );

const updateEmployeeCourseAttendanceApproval = (
  employeeId,
  courseId,
  attendanceId,
  employeeCourseAttendance,
) =>
  Agent.put(
    `/employee/${employeeId}/course/${courseId}/attendance/${attendanceId}/approval`,
    employeeCourseMappers.EmployeeCourseAttendance.to.update(
      employeeCourseAttendance,
    ),
  );

const uploadEmployeeCourseAttendanceFile = (
  employeeId,
  courseId,
  attendanceId,
  file,
) =>
  Agent.file.post(
    `/employee/${employeeId}/course/${courseId}/attendance/${attendanceId}/files`,
    file,
    {},
  );

const previewEmployeeCourseAttendanceFile = (
  employeeId,
  courseId,
  attendanceId,
  fileId,
) =>
  appService.previewFile(
    `/employee/${employeeId}/course/${courseId}/attendance/${attendanceId}/files/${fileId}`,
  );

const deleteEmployeeCourseAttendanceFile = (
  employeeId,
  courseId,
  attendanceId,
  fileId,
) =>
  Agent.delete(
    `/employee/${employeeId}/course/${courseId}/attendance/${attendanceId}/files/${fileId}`,
  );

export default {
  getEmployeeCourses,
  getEmployeeRoleCourseList,
  getEmployeeCourse,
  createEmployeeCourse,
  getPrerequisiteEmployeeCourses,
  getEmployeeCourseAttendances,
  createEmployeeCourseAttendance,
  createAndApproveEmployeeCourseAttendance,
  createEmployeeCourseAttendanceOverride,
  deleteEmployeeCourse,
  deleteEmployeeCourseAttendance,
  deleteEmployeeCourseAttendanceApproval,
  updateEmployeeCourseAttendanceApproval,
  uploadEmployeeCourseAttendanceFile,
  previewEmployeeCourseAttendanceFile,
  deleteEmployeeCourseAttendanceFile,
};
