import React from "react";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { DialogTitle, Grid, Button } from "@material-ui/core";

const ModalHeader = ({ classes, toggleModal, title }) => (
  <DialogTitle
    disableTypography
    classes={{
      root: classes.dialogTitleRoot,
    }}
  >
    <Grid container justify="space-between" alignItems="center">
      <Typography color="secondary" variant="subtitle2">
        {title}
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        onClick={toggleModal}
        className={classes.headerButton}
      >
        Close
      </Button>
    </Grid>
  </DialogTitle>
);

const styles = theme => ({
  dialogTitleRoot: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.altus.background.header,
  },
  headerButton: {
    fontWeight: 700,
    textTransform: "none",
    background: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
  },
});

export default compose(React.memo, withStyles(styles))(ModalHeader);
