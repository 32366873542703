import { compose } from "redux";
import { connect } from "react-redux";
import { Field } from "redux-form/immutable";
import React, { PureComponent, createRef } from "react";
import { Grid, MenuItem, Typography } from "@material-ui/core";

import {
  getSkillGroupsFromState,
  getSkillsNotAddedToEmployee,
} from "features/competence.selectors";

import { saveFile } from "utils/app.util";
import { required, notEmpty } from "utils/validation.util";
import TextField from "app/components/form/TextField";
import AutoSuggestField from "app/components/form/AutoSuggestField";
import { EMPLOYEE_SKILL_FORM } from "features/competence.constants";
import SkillDetails from "features/components/SkillDetails";
import FileUploadField from "app/components/form/FileUploadField/FileUploadField";
import EmployeeSkillFileUpload from "features/components/EmployeeSkillFileUpload";
import DatePickerField from "app/components/form/DatePickerField";

class CreateEmployeeSkillForm extends PureComponent {
  constructor(props) {
    super(props);

    this.clearEmployeeSkillRef = createRef();
  }
  render() {
    const {
      change,
      skills,
      activeTab,
      handleSubmit,
      skillGroups,
      selectedSkillId,
      selectedSkillGroupId,
      employeeId,
    } = this.props;

    const filteredSkills = skills.filter(skill =>
      selectedSkillGroupId
        ? skill.get("skillGroupId") === selectedSkillGroupId
        : true,
    );

    const selectedSkill = skills.find(
      skill => skill.get("skillId") === selectedSkillId,
    );

    return (
      <form onSubmit={handleSubmit} id={EMPLOYEE_SKILL_FORM.ID}>
        <Grid container spacing={3}>
          {activeTab === 0 && (
            <>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={3}>
                  <Field
                    select
                    autoFocus
                    label="Skill group"
                    component={TextField}
                    name={EMPLOYEE_SKILL_FORM.SKILL_GROUP}
                    onChange={() => {
                      if (this.clearEmployeeSkillRef.current) {
                        this.clearEmployeeSkillRef.current.click();
                      }

                      change(EMPLOYEE_SKILL_FORM.SKILL_ID, null);
                    }}
                  >
                    <MenuItem value={""}>All</MenuItem>
                    {skillGroups.map(skillGroup => (
                      <MenuItem
                        key={skillGroup.get("skillGroupId")}
                        value={skillGroup.get("skillGroupId")}
                      >
                        {skillGroup.get("name")}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs>
                  <Field
                    required
                    validate={[required]}
                    placeholder="Search..."
                    label="Select Skill"
                    component={AutoSuggestField}
                    clearBtnRef={this.clearEmployeeSkillRef}
                    name={EMPLOYEE_SKILL_FORM.SKILL_ID}
                    noResultsMessage="No skills available..."
                    TextFieldProps={{
                      required: true,
                    }}
                    options={filteredSkills.map(skill => ({
                      label: `${skill.get("name")} (${skill.get(
                        "skillTypeName",
                      )})`,
                      value: skill.get("skillId"),
                    }))}
                  />
                </Grid>
              </Grid>
              <Grid item container>
                <Grid container>
                  <Typography variant="h6">Skill Details</Typography>
                </Grid>
                {!selectedSkillId && (
                  <Grid container>
                    <Grid item>
                      <Typography variant="caption">
                        No skill selected
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <SkillDetails skill={selectedSkill} />
              </Grid>
            </>
          )}
          {activeTab === 1 && (
            <Grid container item direction="column">
              <Grid item>
                <Field
                  validate={[required, notEmpty]}
                  multiple={true}
                  downloadFile={saveFile}
                  component={FileUploadField}
                  name={EMPLOYEE_SKILL_FORM.FILE_EVIDENCES}
                  label="Upload Evidence Files"
                  FormControlProps={{
                    required: true,
                    margin: "normal",
                  }}
                >
                  {({
                    value,
                    rootProps,
                    removeFile,
                    isDragActive,
                    openFileDialog,
                  }) => (
                    <EmployeeSkillFileUpload
                      value={value}
                      rootProps={rootProps}
                      employeeId={employeeId}
                      removeFile={removeFile}
                      skillId={selectedSkillId}
                      isDragActive={isDragActive}
                      openFileDialog={openFileDialog}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    inputVariant="standard"
                    validate={[required]}
                    component={DatePickerField}
                    name={EMPLOYEE_SKILL_FORM.WHEN}
                    DatePickerProps={{
                      required: true,
                      margin: "normal",
                      label: "When",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    );
  }
}

export default compose(
  connect((state, { formValueSelector, employeeId }) => ({
    skillGroups: getSkillGroupsFromState(state),
    skills: getSkillsNotAddedToEmployee(state, employeeId),
    selectedSkillGroupId: formValueSelector(
      state,
      EMPLOYEE_SKILL_FORM.SKILL_GROUP,
    ),
  })),
)(CreateEmployeeSkillForm);
