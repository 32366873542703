import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { ButtonGroup } from "@material-ui/core";

import { LoadingButton } from "altus-ui-components";

import {
  getActionsDataStatesFromState,
  getCurrentUserFromState,
} from "app/app.selectors";

const ApproveButton = ({
  item,
  onApprove,
  onReject,
  onUndo,
  dataStates,
  user,
  checkBy = "date",
}) => {
  const approvalDate = item.get("approvalDate");
  const approvalOtherDate = item.get("approvalOtherDate");
  const approvalCase = approvalDate ? (approvalOtherDate ? 2 : 1) : 0;
  var undoVisible = false;
  if (approvalCase === 0) {
    // first to approve
    undoVisible = false;
  } else if (approvalCase === 1) {
    // first approved, second to approve
    // check is this user already approve
    if (item.get("approvedByUserId") === user.get("userId")) {
      undoVisible = true;
    } else {
      undoVisible = false;
    }
  } else if (approvalCase === 2) {
    // both approved
    undoVisible = true;
  }

  if (undoVisible === false && item.get("approvalStatus") !== 0) {
    undoVisible = true;
  }

  return (
    <ButtonGroup color="primary" size="small" variant="outlined">
      {(checkBy === "date" ? !approvalDate : !undoVisible) && (
        <LoadingButton
          loading={dataStates[0].isLoading()}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();

            onApprove();
          }}
        >
          Approve
        </LoadingButton>
      )}
      {(checkBy === "date" ? !approvalDate : !undoVisible) && (
        <LoadingButton
          loading={dataStates[1].isLoading()}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();

            onReject();
          }}
        >
          Reject
        </LoadingButton>
      )}
      {(checkBy === "date" ? approvalDate : undoVisible) && (
        <LoadingButton
          loading={dataStates[2].isLoading()}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();

            onUndo();
          }}
        >
          Undo
        </LoadingButton>
      )}
    </ButtonGroup>
  );
};

export default compose(
  connect((state, { id, approveAction, rejectAction, undoAction }) => ({
    user: getCurrentUserFromState(state),
    dataStates: getActionsDataStatesFromState(
      state,
      { type: approveAction, id },
      { type: rejectAction, id },
      { type: undoAction, id },
    ),
  })),
)(ApproveButton);
