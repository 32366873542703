import Agent from "infrastructure/agent";
import contentDisposition from "content-disposition";

const getExperienceRecordAvailablePositions = (
  employeeId,
  experienceRecordId,
) =>
  Agent.get(
    `/employee/${employeeId}/experience/record/${experienceRecordId}/available-positions`,
  );

const getExperienceRecordAvailableValidators = (
  employeeId,
  experienceRecordId,
) =>
  Agent.get(
    `/employee/${employeeId}/experience/record/${experienceRecordId}/available-validators`,
  );

const getExperienceDisciplines = () => Agent.get(`/experience/discipline`);

const getExperienceRecords = () => Agent.get(`/experience/record`);

const getExperienceRecordsByCompetencyStatus = competencyStatus =>
  Agent.get(`/experience/record/status/${competencyStatus}`);

const getExperienceRecord = (employeeId, experienceRecordId) =>
  Agent.get(`/employee/${employeeId}/experience/record/${experienceRecordId}`);

const createExperienceRecords = experiences =>
  Agent.post(`/experience/record`, experiences);

const updateExperienceRecord = (
  employeeId,
  experienceRecordId,
  experienceRecord,
) =>
  Agent.put(
    `/employee/${employeeId}/experience/record/${experienceRecordId}`,
    experienceRecord,
  );

const getExperienceRecordItems = (employeeId, experienceRecordId) =>
  Agent.get(
    `/employee/${employeeId}/experience/record/${experienceRecordId}/items`,
  );

const getEmployeeAllExperienceRecordItems = employeeId =>
  Agent.get(`/employee/${employeeId}/experience/record/items/summarized`);

const getEmployeeFilteredExperienceRecordItems = (
  employeeId,
  experienceRecords,
) =>
  Agent.post(
    `/employee/${employeeId}/experience/record/items/summarized/filter`,
    experienceRecords,
  );

const getAllExperienceRecordCategories = experienceRecordId =>
  Agent.get(`/experience/record/${experienceRecordId}/category/all`);

const getEmployeeExperienceRecordCategoriesForReport = (
  employeeId,
  experienceRecords,
) =>
  Agent.get(
    `/employee/${employeeId}/experience/record/category/report`,
    experienceRecords,
  );

const getExperienceRecordCategoriesForExperienceRecords = (
  employeeId,
  experienceRecords,
) =>
  Agent.post(
    `/employee/${employeeId}/experience/record/category/report/filter`,
    experienceRecords,
  );

const getExperienceRecordsReportForEmployee = (
  employeeId,
  selectedCategories,
) =>
  Agent.maybeRefreshToken().then(request =>
    request
      .post(
        `${Agent.config.apiRoot}/employee/${employeeId}/experience/record/report`,
      )
      .send(selectedCategories)
      .responseType("blob")
      .then(({ headers, body: file }) => {
        const { parameters } = contentDisposition.parse(
          headers["content-disposition"],
        );

        return [file, parameters.filename];
      }),
  );

const getExperienceRecordCategories = experienceRecordId =>
  Agent.get(`/experience/record/${experienceRecordId}/category`);

const submitExperienceRecord = (employeeId, experienceRecordId) =>
  Agent.post(
    `/employee/${employeeId}/experience/record/${experienceRecordId}/submit`,
  );

const updateExperienceRecordItem = (
  employeeId,
  experienceRecordId,
  experienceRecordItem,
) =>
  Agent.post(
    `/employee/${employeeId}/experience/record/${experienceRecordId}/items`,
    experienceRecordItem,
  );

const deleteExperienceRecord = experienceRecordId =>
  Agent.delete(`/experience/${experienceRecordId}`);

const deleteExperienceRecords = experienceRecordIds =>
  Agent.post(`/experience/remove`, experienceRecordIds);

export default {
  getExperienceRecord,
  getExperienceRecords,
  getExperienceRecordsByCompetencyStatus,
  updateExperienceRecord,
  submitExperienceRecord,
  deleteExperienceRecord,
  deleteExperienceRecords,
  createExperienceRecords,
  getExperienceDisciplines,
  getExperienceRecordItems,
  updateExperienceRecordItem,
  getExperienceRecordCategories,
  getAllExperienceRecordCategories,
  getExperienceRecordAvailablePositions,
  getExperienceRecordAvailableValidators,
  getEmployeeAllExperienceRecordItems,
  getEmployeeFilteredExperienceRecordItems,
  getEmployeeExperienceRecordCategoriesForReport,
  getExperienceRecordCategoriesForExperienceRecords,
  getExperienceRecordsReportForEmployee,
};
