import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { formValueSelector } from "redux-form/immutable";

import {
  EMPLOYEE_COURSE_FORM,
  CREATE_EMPLOYEE_COURSE_ATTENDANCE_MODAL_ID,
} from "features/competence.constants";

import { EMPTY_SET } from "app/app.constants";
import SimpleFormModal from "app/components/SimpleFormModal";
import EmployeeCourseModalTabs from "features/components/EmployeeCourseModalTabs";
import EmployeeCourseModalActions from "features/components/EmployeeCourseModalActions";
import CreateEmployeeCourseAttendanceForm from "features/myPage/courses/components/CreateEmployeeCourseAttendanceForm";

const CreateEmployeeCourseModal = ({
  classes,
  onEnter,
  onSubmit,
  activeTab,
  employeeId,
  selectedCourseId,
}) => {
  const tabs = Object.values({
    COURSE_DETAILS: {
      title: "Details",
    },
    UPLOAD_CERTIFICATES: {
      title: "Upload",
      disabled: !selectedCourseId,
    },
  });

  return (
    <SimpleFormModal
      Icon={null}
      submitText="Add"
      onEnter={onEnter}
      title="Add course"
      onSubmit={onSubmit}
      displaySubmitBtn={false}
      displayCancelBtn={false}
      Tabs={EmployeeCourseModalTabs}
      component={CreateEmployeeCourseAttendanceForm}
      modalId={CREATE_EMPLOYEE_COURSE_ATTENDANCE_MODAL_ID}
      ModalActions={EmployeeCourseModalActions}
      ModalActionsProps={{
        tabs,
        activeTab,
        selectedCourseId,
      }}
      TabsProps={{
        tabs,
        selectedCourseId,
      }}
      FormProps={{
        activeTab,
        employeeId,
        selectedCourseId,
      }}
      DialogProps={{
        maxWidth: "md",
        fullWidth: true,
        classes: {
          paper: classes.dialogPaper,
        },
      }}
      form={{
        form: EMPLOYEE_COURSE_FORM.ID,
        initialValues: {
          [EMPLOYEE_COURSE_FORM.ACTIVE_TAB]: 0,
          [EMPLOYEE_COURSE_FORM.FILES]: EMPTY_SET,
        },
      }}
    />
  );
};

const formSelector = formValueSelector(EMPLOYEE_COURSE_FORM.ID);

const styles = {
  dialogPaper: {
    height: "100%",
    maxHeight: 750,
  },
};

export default compose(
  connect(state => ({
    activeTab: formSelector(state, EMPLOYEE_COURSE_FORM.ACTIVE_TAB),
    selectedCourseId: formSelector(state, EMPLOYEE_COURSE_FORM.COURSE_ID),
  })),
  withStyles(styles),
)(CreateEmployeeCourseModal);
