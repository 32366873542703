import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";

import { getCurrentEmployeeFromState } from "app/app.selectors";
import MyPageExperienceRecordHeader from "features/myPage/experiences/components/MyPageExperienceRecordHeader";
import EmployeeExperienceRecordsContainer from "features/employee/experienceRecords/EmployeeExperienceRecordsContainer";

const MyPageExperienceRecordsContainer = ({
  currentEmployee,
  breadcrumb,
  path,
}) => {
  return (
    <>
      <Grid container justifyContent="flex-end">
        <MyPageExperienceRecordHeader
          employeeId={currentEmployee.get("employeeId")}
        />
      </Grid>
      <EmployeeExperienceRecordsContainer
        breadcrumb={breadcrumb}
        employeeId={currentEmployee.get("employeeId")}
        path={path}
      />
    </>
  );
};

export default compose(
  connect(state => ({
    currentEmployee: getCurrentEmployeeFromState(state),
  })),
)(MyPageExperienceRecordsContainer);
