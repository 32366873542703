import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { RouteContainer, NavTabsContainer } from "altus-ui-components";

import {
  getRoleWithParents,
  getStatsForMyTeamRole,
} from "features/competence.actions";

import { EMPTY_MAP } from "app/app.constants";
import { getRoleRouteCount, setRouteCount } from "utils/route.util";
import { getRoleStatsFromState } from "features/competence.selectors";
import RoleDetailsHeader from "features/role/components/RoleDetailsHeader";

class RoleContainer extends PureComponent {
  componentDidMount() {
    const {
      roleId,
      dispatchGetRole,
      dispatchGetRoleStatsForMyTeamRole,
    } = this.props;

    dispatchGetRole(roleId);
    dispatchGetRoleStatsForMyTeamRole(roleId);
  }

  render() {
    const { routes, roleId, roleStats } = this.props;

    return (
      <>
        <RoleDetailsHeader roleId={roleId} hideStatus />
        <NavTabsContainer
          routes={routes.map(route =>
            setRouteCount(route, getRoleRouteCount(roleStats, route.key)),
          )}
        />
        <RouteContainer routes={routes} />
      </>
    );
  }
}

RoleContainer.defaultProps = {
  roleStats: EMPTY_MAP,
};

export default compose(
  connect(
    (state, { roleId }) => ({
      roleStats: getRoleStatsFromState(state, roleId),
    }),
    {
      dispatchGetRole: getRoleWithParents,
      dispatchGetRoleStatsForMyTeamRole: getStatsForMyTeamRole,
    },
  ),
)(RoleContainer);
