import { compose } from "redux";
import { connect } from "react-redux";
import { IconButton, Tooltip } from "@material-ui/core";
import React, { useEffect, useMemo, useCallback } from "react";

import { useModal } from "altus-modal";
import { MultiSelectModal } from "altus-ui-components";

import {
  updateSpecificationSkill,
  specificationSkillsOnLoad,
  specificationSkillsOnUnload,
  createSpecificationSkillsOnSubmit,
  getAvailableSkillsForSpecification,
  deleteSpecificationSkillAskConfirmation,
} from "features/competence.actions";

import {
  getSpecificationsFromState,
  getAvailableSkillsForSpecificationFromState,
} from "features/competence.selectors";

import {
  getActionDataStateFromState,
  getSummarizedDataStatesFromState,
} from "app/app.selectors";

import SwitchField from "app/components/SwitchField";
import CrudBasePage from "app/components/CrudBasePage";
import { ACTIONS, ICONS, CRITICALITY } from "app/app.constants";
import { CREATE_SPECIFICATION_SKILL_FORM } from "features/competence.constants";
import SpecificationSkillListContainer from "features/specification/components/SpecificationSkillListContainer";

const getKey = item => item.get("specificationSkillId");
const getSkillName = skill => skill.get("name");

const SpecificationSkillsContainer = ({
  dataState,
  breadcrumb,
  specifications,
  dispatchOnLoad,
  availableSkills,
  specificationId,
  dispatchOnUnload,
  getAvailableSkillsDataState,
  dispatchDeleteSpecificationSkill,
  dispatchUpdateSpecificationSkill,
  dispatchCreateSpecificationSkillOnSubmit,
  dispatchGetAvailableSkillsForSpecification,
}) => {
  const [isOpen, toggleModal] = useModal(CREATE_SPECIFICATION_SKILL_FORM.ID);

  useEffect(() => {
    dispatchOnLoad(specificationId);

    return () => dispatchOnUnload();
  }, [specificationId, dispatchOnUnload, dispatchOnLoad]);

  useEffect(() => {
    if (isOpen) {
      dispatchGetAvailableSkillsForSpecification(specificationId);
    }
  }, [specificationId, isOpen, dispatchGetAvailableSkillsForSpecification]);

  const columns = useMemo(
    () => [
      {
        xs: 4,
        title: "Skill Name",
        getSortProperty: item => item.getIn(["skillName"]),
      },
      {
        xs: true,
        title: "Group",
        getSortProperty: item => item.getIn(["skillGroupName"]),
      },
      {
        xs: true,
        title: "Type",
        getSortProperty: item => item.getIn(["skillTypeName"]),
      },
      {
        xs: true,
        title: "Valid",
        getSortProperty: item => item.getIn(["skillValidityMonthsString"]),
      },
      {
        xs: true,
        justify: "center",
        container: true,
        title: "Criticality",
        getValue: item => (
          <SwitchField
            label="Critical"
            checked={item.get("criticality") === CRITICALITY.CRITICAL}
            onChange={() =>
              dispatchUpdateSpecificationSkill(
                item.get("specificationId"),
                item.get("skillId"),
                item.update("criticality", skillCriticality =>
                  skillCriticality === CRITICALITY.CRITICAL
                    ? CRITICALITY.NOT_CRITICAL
                    : CRITICALITY.CRITICAL,
                ),
              )
            }
          />
        ),
      },
    ],
    [dispatchUpdateSpecificationSkill],
  );

  const actions = useMemo(
    () => [
      {
        getValue: item =>
          item.get("specificationId").toString() ===
            specificationId.toString() && (
            <Tooltip title="Delete">
              <IconButton
                onClick={() => dispatchDeleteSpecificationSkill(item)}
              >
                <ICONS.DELETE />
              </IconButton>
            </Tooltip>
          ),
      },
    ],
    [specificationId, dispatchDeleteSpecificationSkill],
  );

  const addSkillsSubmit = useCallback(
    selectedSkills => {
      dispatchCreateSpecificationSkillOnSubmit(
        specificationId,
        selectedSkills.map(skill => skill.get("skillId")).toArray(),
      ).then(toggleModal);
    },
    [specificationId, toggleModal, dispatchCreateSpecificationSkillOnSubmit],
  );

  return (
    <CrudBasePage
      dataState={dataState}
      title={breadcrumb}
      displayAddButton={true}
      displaySearchField={true}
      addButtonOnClick={toggleModal}
    >
      {specifications.map(specification => {
        const innerSpecificationId = specification.get("specificationId");

        return (
          <SpecificationSkillListContainer
            Icon={ICONS.SKILL}
            key={innerSpecificationId}
            specificationId={innerSpecificationId}
            getKey={getKey}
            actions={actions}
            columns={columns}
            inherited={
              innerSpecificationId.toString() !== specificationId.toString()
            }
          />
        );
      })}
      <MultiSelectModal
        open={isOpen && !getAvailableSkillsDataState.isLoading()}
        displayChips
        onClose={toggleModal}
        getName={getSkillName}
        items={availableSkills}
        title="Add Skills"
        onSubmit={addSkillsSubmit}
      />
    </CrudBasePage>
  );
};

export default compose(
  connect(
    (state, { specificationId }) => ({
      specifications: getSpecificationsFromState(state, specificationId),
      availableSkills: getAvailableSkillsForSpecificationFromState(
        state,
        specificationId,
      ),
      getAvailableSkillsDataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_AVAILABLE_SKILLS_FOR_SPECIFICATION,
      ),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_SPECIFICATION_SKILLS_BY_SPECIFICATION_WITH_PARENTS,
        ACTIONS.GET_AVAILABLE_SKILLS_FOR_SPECIFICATION,
        ACTIONS.UPDATE_SPECIFICATION_SKILL,
      ),
    }),
    {
      dispatchOnLoad: specificationSkillsOnLoad,
      dispatchOnUnload: specificationSkillsOnUnload,
      dispatchUpdateSpecificationSkill: updateSpecificationSkill,
      dispatchDeleteSpecificationSkill: deleteSpecificationSkillAskConfirmation,
      dispatchCreateSpecificationSkillOnSubmit: createSpecificationSkillsOnSubmit,
      dispatchGetAvailableSkillsForSpecification: getAvailableSkillsForSpecification,
    },
  ),
)(SpecificationSkillsContainer);
