import isNil from "lodash/isNil";
import { List } from "immutable";
import { EMPTY_LIST, EMPTY_MAP } from "app/app.constants";
import { createSelector } from "utils/app.util";
import { getCurrentEmployeeSelector } from "app/app.selectors";

const getCoursesSelector = state => state.getIn(["competence", "course"]);

const getEmployeeCoursesSelector = state =>
  state.getIn(["competence", "employeeCourse"]);

export const getAllEmployeeCoursesByState = createSelector(
  getEmployeeCoursesSelector,
  employeeCourses => employeeCourses,
);

const getEmployeeCoursesByEmployeeSelector = (state, employeeId) =>
  state.getIn(["competence", "employeeCourse", employeeId.toString()]);

const getCourseSelector = (state, courseId) =>
  state.getIn(["competence", "course", courseId.toString()]);

const getEmployeeCourse = (state, employeeId, courseId) =>
  state.getIn([
    "competence",
    "employeeCourse",
    employeeId.toString(),
    courseId.toString(),
  ]);

export const getEmployeeCourseFromState = createSelector(
  getEmployeeCourse,
  employeeCourse => employeeCourse,
);

export const getCoursesFromState = createSelector(getCoursesSelector, courses =>
  courses.toList(),
);

export const getCoursesByCourseIdFromState = createSelector(
  getCoursesSelector,
  courses => courses,
);

export const getCourseFromState = createSelector(
  getCourseSelector,
  course => course,
);

export const getAvailablePrerequisiteCoursesFromState = createSelector(
  getCoursesFromState,
  courses =>
    courses
      .filter(
        c => !c.get("isCustom") && c.get("prerequisiteForCourseId") === null,
      )
      .toList(),
);

export const getPrerequisiteCoursesFromState = createSelector(
  getCoursesSelector,
  (state, courseId) => courseId,
  (courses, courseId) =>
    courses
      .filter(c => c.get("prerequisiteForCourseId") === +courseId)
      .toList(),
);

export const getCurrentEmployeeCoursesFromState = createSelector(
  getCurrentEmployeeSelector,
  getEmployeeCoursesSelector,
  (currentEmployee, coursesByEmployee) => {
    const employeeId = currentEmployee.get("employeeId");
    const employeeCourses = coursesByEmployee.get(employeeId.toString());
    return employeeCourses ? employeeCourses.toList() : EMPTY_LIST;
  },
);

export const getEmployeeCoursesFromState = employeeId =>
  createSelector(getEmployeeCoursesSelector, coursesByEmployee => {
    const employeeCourses = coursesByEmployee.get(employeeId.toString());
    return employeeCourses ? employeeCourses.toList() : EMPTY_LIST;
  });

const getCollectionsSelector = state =>
  state.getIn(["competence", "collection"]);

export const getCollectionsFromState = createSelector(
  getCollectionsSelector,
  collections => collections.toList(),
);

const getCollectionSelector = (state, competencyCollectionId) =>
  competencyCollectionId &&
  state.getIn(["competence", "collection", competencyCollectionId.toString()]);

export const getCollectionFromState = createSelector(
  getCollectionSelector,
  collection => collection,
);

const getRolesSelector = state => state.getIn(["competence", "role"]);

export const getAllRolesFromState = createSelector(getRolesSelector, roles =>
  roles.toList(),
);

export const getRolesGroupedByRoleIdFromState = createSelector(
  getRolesSelector,
  roles => roles,
);

export const getRolesFromState = createSelector(
  (state, roleId) => {
    const roles = getRolesSelector(state);
    if (!roleId) {
      return roles;
    }

    // roleId is given, return the matching role and all its parents, in order
    let rv = List();
    let role = roles.get(roleId.toString());
    while (role) {
      rv = rv.push(role);
      const parentId = role.get("parentId") || "";
      role = roles.get(parentId.toString());
    }
    return rv;
  },
  roles => roles.toList(),
);

const getRoleSelector = (state, roleId) =>
  roleId && state.getIn(["competence", "role", roleId.toString()]);

export const getRoleFromState = createSelector(getRoleSelector, role => role);

const getEmployeesSelector = state => state.getIn(["competence", "employee"]);

const getAvailableEmployeeIdsForRoleSelector = (state, roleId) =>
  state?.getIn(["competence", "role", roleId.toString(), "availableEmployees"]);

export const getAvailableEmployeesForRoleFromState = createSelector(
  getAvailableEmployeeIdsForRoleSelector,
  availableEmployees => availableEmployees?.toList(),
);

export const getEmployeesFromState = createSelector(
  getEmployeesSelector,
  employees =>
    employees.sortBy(employee => employee.get("displayName")).toList(),
);

export const getEmployeesByEmployeeNumberFromState = createSelector(
  getEmployeesSelector,
  employees =>
    employees
      .sortBy(employee => employee.get("displayName"))
      .mapKeys((_, employee) => employee.get("employeeNumber")),
);

const getEmployeeSelector = (state, employeeId) =>
  state.getIn(["competence", "employee", employeeId.toString()]);

export const getEmployeeFromState = createSelector(
  getEmployeeSelector,
  employee => employee,
);

const getAvailableRolesForEmployeeSelector = (state, employeeId) =>
  state?.getIn([
    "competence",
    "employee",
    employeeId.toString(),
    "availableRoles",
  ]);

export const getAvailableEmployeeRolesFromState = createSelector(
  getAvailableRolesForEmployeeSelector,
  availableRoles => availableRoles?.toList(),
);

const getMyTeamEmployeeSelector = (state, employeeId) =>
  state.getIn(["competence", "myTeam", employeeId.toString()]);

export const getMyTeamEmployeeFromState = createSelector(
  getMyTeamEmployeeSelector,
  employee => employee,
);

const getMyTeamEmployeesSelector = state =>
  state.getIn(["competence", "myTeam"]);

export const getMyTeamEmployeesFromState = createSelector(
  getMyTeamEmployeesSelector,
  myTeamEmployees => myTeamEmployees.toList(),
);

const getEmployeeRolesSelector = state =>
  state.getIn(["competence", "employeeRole"]);

export const getAllEmployeeRolesFromState = createSelector(
  getEmployeeRolesSelector,
  employeeRoles => employeeRoles,
);

const getEmployeeServicesSelector = state =>
  state.getIn(["competence", "employeeService"]);

export const getEmployeeServicesFromState = employeeId =>
  createSelector(getEmployeeServicesSelector, employeeServices => {
    var eServices = employeeServices?.get(employeeId.toString());
    return eServices
      ? employeeServices?.get(employeeId.toString()).toList()
      : EMPTY_LIST;
  });

export const getEmployeeRolesFromState = employeeId =>
  createSelector(
    getEmployeeRolesSelector,
    getRolesSelector,
    (rolesByEmployee, roles) => {
      if (!rolesByEmployee.size || !roles.size) return undefined;

      const employeeRoles = rolesByEmployee.get(employeeId.toString());

      return employeeRoles
        ? employeeRoles
            .toList()
            .sortBy(employeeRole => employeeRole.getIn(["role", "name"]))
        : undefined;
    },
  );

export const getEmployeeRoleSelector = (state, employeeId, roleId) =>
  state.getIn([
    "competence",
    "employeeRole",
    employeeId.toString(),
    roleId.toString(),
  ]);

export const getEmployeeRoleFromState = createSelector(
  getEmployeeRoleSelector,
  employeeRole => employeeRole,
);

const getRoleCoursesForRoleSelector = (state, roleId) =>
  state.getIn(["competence", "roleCourse", roleId.toString()]);

export const getRoleCoursesFromState = createSelector(
  getRoleCoursesForRoleSelector,
  roleCourses => (roleCourses ? roleCourses.toList() : EMPTY_LIST),
);

const getRoleEmployeesSelector = (state, roleId) =>
  state.getIn(["competence", "roleEmployee", roleId.toString()]);

export const getRoleEmployeesFromState = createSelector(
  getRoleEmployeesSelector,
  roleEmployees => (roleEmployees ? roleEmployees.toList() : EMPTY_LIST),
);

const getRoleEmployeeSelector = (state, roleId, employeeId) =>
  state.getIn([
    "competence",
    "roleEmployee",
    roleId.toString(),
    employeeId.toString(),
  ]);

export const getRoleEmployeeFromState = createSelector(
  getRoleEmployeeSelector,
  roleEmployee => roleEmployee,
);

const getEmployeeEducationSelector = state =>
  state.getIn(["competence", "employeeEducation"]);

export const getEmployeeEducationFromState = createSelector(
  getEmployeeEducationSelector,
  education => (education ? education.toList() : undefined),
);

const getCompetencyCategoriesSelector = state =>
  state.getIn(["competence", "competencyCategory"]);

export const getCompetencyCategoriesFromState = createSelector(
  getCompetencyCategoriesSelector,
  categories => (categories ? categories.toList() : undefined),
);

const getWorkHistorySelector = state =>
  state.getIn(["competence", "workHistory"]);

export const getWorkHistoryFromState = createSelector(
  getWorkHistorySelector,
  workHistory => workHistory.toList(),
);

export const getEmployeeCoursesForEmployeeSelector = (state, employeeId) =>
  state.getIn(["competence", "employeeCourse", employeeId.toString()]);

export const getAvailableCoursesForEmployee = createSelector(
  getEmployeeCoursesForEmployeeSelector,
  getCoursesSelector,
  (employeeCourses, courses) =>
    courses
      .filter(
        course =>
          !course.get("isCustom") &&
          !employeeCourses.some(
            employeeCourse =>
              employeeCourse.get("courseId") === course.get("courseId"),
          ),
      )
      .toList(),
);

const getRoleCoursesSelector = state =>
  state.getIn(["competence", "roleCourse"]);

export const getAllRoleCoursesByRoleIdByState = createSelector(
  getRoleCoursesSelector,
  roleCoursesByRoleId => roleCoursesByRoleId,
);

export const createEmployeeRoleCoursesSelector = (employeeId, roleId) =>
  createSelector(
    state => getRoleCoursesForRoleSelector(state, roleId),
    state => getEmployeeCoursesByEmployeeSelector(state, employeeId),
    (roleCourses, employeeCourses) =>
      roleCourses && employeeCourses
        ? roleCourses
            .map(roleCourse =>
              roleCourse.merge(
                employeeCourses.get(roleCourse.get("courseId").toString()),
              ),
            )
            .filter(Boolean)
            .toList()
        : EMPTY_LIST,
  );

const getEmployeeRolesForEmployeeSelector = (state, employeeId) =>
  state.getIn(["competence", "employeeRole", employeeId.toString()]);

export const getSimpleEmployeeRolesFromState = createSelector(
  getEmployeeRolesForEmployeeSelector,
  employeeRoles => (employeeRoles ? employeeRoles.toList() : undefined),
);

const getCourseGroupsSelector = state =>
  state.getIn(["competence", "courseGroup"]);

export const getCourseGroupsFromState = createSelector(
  getCourseGroupsSelector,
  courseGroups => courseGroups.toList(),
);

const getCourseTypesSelector = state =>
  state.getIn(["competence", "courseType"]);

export const getCourseTypesFromState = createSelector(
  getCourseTypesSelector,
  courseTypes => courseTypes,
);

export const getEmployeeCoursesAttendancesApprovalFromState = createSelector(
  state => state.getIn(["competence", "approval", "employeeCourseAttendance"]),
  employeeCourses => employeeCourses.toList(),
);

export const getEducationsApprovalFromState = createSelector(
  state => state.getIn(["competence", "approval", "education"]),
  educations => educations.toList(),
);

export const getWorkHistoryApprovalFromState = createSelector(
  state => state.getIn(["competence", "approval", "workHistory"]),
  workHistory => workHistory.toList(),
);

export const getSkillFileEvidencesApprovalFromState = createSelector(
  state => state.getIn(["competence", "approval", "skillFileEvidence"]),
  skillFileEvidences => skillFileEvidences?.toList(),
);

export const getHrStatsFromState = createSelector(
  state => state.getIn(["competence", "stats", "hr"]),
  hrStats => hrStats,
);

export const getMyTeamStatsFromState = createSelector(
  state => state.getIn(["competence", "stats", "myTeam"]),
  myTeamStats => myTeamStats,
);

export const getVerifierStatsFromState = createSelector(
  state => state.getIn(["competence", "stats", "verifier"]),
  verifierStats => verifierStats,
);

export const getSettingsStatsFromState = createSelector(
  state => state.getIn(["competence", "stats", "settings"]),
  settingsStats => settingsStats,
);

export const getEmployeeCourseAttendancesFromState = createSelector(
  (state, employeeId, courseId) =>
    state.getIn([
      "competence",
      "employeeCourseAttendance",
      employeeId.toString(),
      courseId.toString(),
    ]),
  employeeCourseAttendances =>
    employeeCourseAttendances ? employeeCourseAttendances.toList() : undefined,
);

export const getDepartmentsFromState = createSelector(
  state => state.getIn(["competence", "department"]),
  departments => departments,
);

export const getManagersFromState = createSelector(
  state => state.getIn(["competence", "manager"]),
  managers => managers,
);

export const getDepartmentFromState = createSelector(
  (state, departmentId) =>
    state.getIn(["competence", "department", departmentId.toString()]),
  department => department,
);

export const getEmployeeRoleGapReportRolesFromState = createSelector(
  state => state.getIn(["competence", "employeeRoleGapReport", "role"]),
  role => role,
);

export const getEmployeeRoleGapReportRoleCoursesFromState = createSelector(
  state => state.getIn(["competence", "employeeRoleGapReport", "roleCourse"]),
  roleCourse => roleCourse,
);

export const getEmployeeRoleGapReportRoleSkillsFromState = createSelector(
  state => state.getIn(["competence", "employeeRoleGapReport", "roleSkill"]),
  roleSkill => roleSkill,
);

export const getEmployeeRoleGapReportCoursesFromState = createSelector(
  state => state.getIn(["competence", "employeeRoleGapReport", "course"]),
  course => course,
);

export const getEmployeeRoleGapReportSkillsFromState = createSelector(
  state => state.getIn(["competence", "employeeRoleGapReport", "skill"]),
  skill => skill,
);

export const getEmployeeRoleGapReportEmployeeSkillsFromState = createSelector(
  state =>
    state.getIn(["competence", "employeeRoleGapReport", "employeeSkill"]),
  employeeSkills => employeeSkills,
);

export const getEmployeeRoleGapReportEmployeeRolesFromState = createSelector(
  state => state.getIn(["competence", "employeeRoleGapReport", "employeeRole"]),
  employeeRole => employeeRole,
);

export const getEmployeeRoleGapReportEmployeeCoursesFromState = createSelector(
  state =>
    state.getIn(["competence", "employeeRoleGapReport", "employeeCourse"]),
  employeeCourses => employeeCourses,
);

export const getRoleGapReportRolesFromState = createSelector(
  state => state.getIn(["competence", "roleGapReport", "role"]),
  roles => roles.toList(),
);

export const getRoleGapReportRoleCoursesByRoleFromState = createSelector(
  state => state.getIn(["competence", "roleGapReport", "roleCourse"]),
  roleCourse => roleCourse,
);

export const getRoleGapReportCoursesRoleFromState = createSelector(
  state => state.getIn(["competence", "roleGapReport", "courseRole"]),
  courseRole => courseRole,
);

export const getRoleGapReportRoleSkillsByRoleFromState = createSelector(
  state => state.getIn(["competence", "roleGapReport", "roleSkill"]),
  roleSkill => roleSkill,
);

export const getRoleGapReportEmployeeRolesFromState = createSelector(
  (state, employeeId) =>
    state.getIn([
      "competence",
      "roleGapReport",
      "employeeRole",
      employeeId.toString(),
    ]),
  employeeRoles => employeeRoles,
);

export const getRoleGapReportEmployeeCoursesFromState = createSelector(
  (state, employeeId) =>
    state.getIn([
      "competence",
      "roleGapReport",
      "employeeCourse",
      employeeId.toString(),
    ]),
  employeeCourses => employeeCourses,
);

export const getRoleGapReportEmployeeSkillsFromState = createSelector(
  (state, employeeId) =>
    state.getIn([
      "competence",
      "roleGapReport",
      "employeeSkill",
      employeeId.toString(),
    ]),
  employeeSkills => employeeSkills,
);

export const getEmployeeCoursesByCourseFromState = createSelector(
  state => state.getIn(["competence", "employeeCourse"]),
  employeeCourses => employeeCourses.toList(),
);

export const getCountriesFromState = createSelector(
  state => state.getIn(["competence", "country"]),
  countries => countries,
);
const getExperienceRecordsSelector = state =>
  state.getIn(["competence", "experienceRecord"]);

export const getExperienceRecordsFromState = createSelector(
  getExperienceRecordsSelector,
  experienceRecords => experienceRecords?.toList(),
);

const getExperienceRecordSelector = (state, experienceRecordId) =>
  state.getIn([
    "competence",
    "experienceRecord",
    experienceRecordId.toString(),
  ]);

export const getExperienceRecordFromState = createSelector(
  getExperienceRecordSelector,
  experienceRecord => experienceRecord,
);

const getExperienceRecordItemsSelector = (state, experienceRecordId) =>
  state.getIn([
    "competence",
    "experienceRecordItem",
    experienceRecordId?.toString(),
  ]);

export const getExperienceRecordItemsFromState = createSelector(
  getExperienceRecordItemsSelector,
  experienceRecordItems => experienceRecordItems,
);

const getEmployeeAllExperienceRecordItemsSelector = (state, employeeId) =>
  state.getIn(["competence", "experienceRecordItem", employeeId?.toString()]);

export const getEmployeeAllExperienceRecordItemsFromState = createSelector(
  getEmployeeAllExperienceRecordItemsSelector,
  experienceRecordItems => experienceRecordItems,
);

const getExperienceRecordCategoriesSelector = state => {
  return state.getIn(["competence", "experienceRecordCategory"]);
};

export const getExperienceCategoriesFromState = createSelector(
  getExperienceRecordCategoriesSelector,
  experienceCategories => experienceCategories.toList(),
);

export const getAvailableExperienceCategoriesFromState = createSelector(
  getExperienceRecordCategoriesSelector,
  experienceCategories => experienceCategories,
);

const getExperienceClientsSelector = state =>
  state.getIn(["competence", "organization"]);

export const getExperienceClientsFromState = createSelector(
  getExperienceClientsSelector,
  experienceClients => experienceClients.toList(),
);

const getExperienceRecordAvailablePositionsSelector = state =>
  state.getIn(["competence", "experienceRecordPosition", "available"]);

export const getExperienceRecordAvailablePositionsFromState = createSelector(
  getExperienceRecordAvailablePositionsSelector,
  experiencePositions => experiencePositions?.toList(),
);

const getExperienceRecordAvailableValidatorsSelector = state =>
  state.getIn(["competence", "experienceRecordValidator", "available"]);

export const getExperienceRecordAvailableValidatorsFromState = createSelector(
  getExperienceRecordAvailableValidatorsSelector,
  employees => employees?.toList(),
);

const getExperienceDisciplinesSelector = state =>
  state.getIn(["competence", "experienceDiscipline"]);

export const getExperienceDisciplinesFromState = createSelector(
  getExperienceDisciplinesSelector,
  experienceDisciplines => experienceDisciplines.toList(),
);

const getExperienceLocationsSelector = state =>
  state.getIn(["competence", "facility"]);

export const getExperienceLocationsFromState = createSelector(
  getExperienceLocationsSelector,
  experienceLocations => experienceLocations.toList(),
);

const getExperienceDepartmentsSelector = state =>
  state.getIn(["competence", "experienceDepartment"]);

export const getExperienceDepartmentsFromState = createSelector(
  getExperienceDepartmentsSelector,
  experienceDepartments => experienceDepartments.toList(),
);

const getExperienceRecordsForValidatorSelector = state =>
  state.getIn(["competence", "approval", "experienceRecord"]);

export const getExperienceRecordsForValidatorFromState = createSelector(
  getExperienceRecordsForValidatorSelector,
  experienceRecords => experienceRecords.toList(),
);

const getSkillGroupsSelector = state =>
  state.getIn(["competence", "skillGroup"]);

export const getSkillGroupsFromState = createSelector(
  getSkillGroupsSelector,
  skillGroups => skillGroups.toList(),
);

const getSkillTypesSelector = state => state.getIn(["competence", "skillType"]);

export const getSkillTypesFromState = createSelector(
  getSkillTypesSelector,
  skillTypes => skillTypes.toList(),
);

const getSkillsSelector = state => state.getIn(["competence", "skill"]);

export const getSkillsFromState = createSelector(getSkillsSelector, skills =>
  skills.toList(),
);

export const getSkillsGroupedBySkillIdFromState = createSelector(
  getSkillsSelector,
  skills => skills,
);

const getSkillSelector = (state, skillId) =>
  state.getIn(["competence", "skill", skillId.toString()]);

export const getSkillFromState = createSelector(
  getSkillSelector,
  skill => skill,
);

const getRoleSkillsForRoleSelector = (state, roleId) =>
  state.getIn(["competence", "roleSkill", roleId.toString()]);

export const getRoleSkillsForRoleFromState = createSelector(
  getRoleSkillsForRoleSelector,
  roleSkills => (roleSkills ? roleSkills.toList() : undefined),
);

const getEmployeeRoleSkillsForEmployeeSelector = (state, employeeId) =>
  state.getIn(["competence", "employeeRoleSkill", employeeId.toString()]);

export const getEmployeeRoleSkillsForEmployeeFromState = createSelector(
  getEmployeeRoleSkillsForEmployeeSelector,
  employeeRoleSkills =>
    employeeRoleSkills ? employeeRoleSkills.toList() : EMPTY_LIST,
);

export const getEmployeeRoleSkillsByRoleSelector = roleId =>
  createSelector(
    getEmployeeRoleSkillsForEmployeeSelector,
    employeeRoleSkillsGroupedByRole => {
      if (!employeeRoleSkillsGroupedByRole) {
        return undefined;
      }

      const employeeRoleSkillForRole = employeeRoleSkillsGroupedByRole.find(
        employeeRoleSkillGroup =>
          employeeRoleSkillGroup.get("roleId") &&
          employeeRoleSkillGroup.get("roleId").toString() === roleId.toString(),
      );

      return !!employeeRoleSkillForRole
        ? employeeRoleSkillForRole.get("employeeRoleSkills").toList()
        : undefined;
    },
  );

const getEmployeeSkillsSelector = state =>
  state.getIn(["competence", "employeeSkill"]);

export const getEmployeeSkillFromState = (employeeId, skillId) =>
  createSelector(getEmployeeSkillsSelector, employeeSkills =>
    employeeSkills.getIn([employeeId.toString(), skillId.toString()]),
  );

const getEmployeeSkillsAddedToEmployeeSelector = (state, employeeId) =>
  state
    .getIn(["competence", "employeeRoleSkill", employeeId.toString()])
    .reduce((skillIdsAddedToEmployee, employeeRoleSkillsByRole) => {
      return skillIdsAddedToEmployee.concat(
        employeeRoleSkillsByRole
          .get("employeeRoleSkills")
          .map(anEmployeeRoleSkill => anEmployeeRoleSkill.get("skillId")),
      );
    }, List());

export const getSkillsNotAddedToEmployee = createSelector(
  getEmployeeSkillsAddedToEmployeeSelector,
  getSkillsSelector,
  (skillIdsAddedToEmployee, skills) =>
    skills
      .filter(skill => !skillIdsAddedToEmployee.includes(skill.get("skillId")))
      .toList(),
);
export const getEmployeeSkillsBySkillFromState = skillId =>
  createSelector(getEmployeeSkillsSelector, employeeSkills =>
    employeeSkills
      .toList()
      .flatten(1)
      .groupBy(employeeSkill => employeeSkill.get("skillId").toString())
      .get(skillId.toString()),
  );

export const getSkillGapReportSkillsFromState = createSelector(
  state => state.getIn(["competence", "skillGapReport", "skill"]),
  skills => skills.toList(),
);

export const getSkillGapReportEmployeeSkillsFromState = createSelector(
  (state, employeeId) =>
    state.getIn([
      "competence",
      "skillGapReport",
      "employeeSkill",
      employeeId.toString(),
    ]),
  employeeSkills => employeeSkills,
);

const getEmployeeSkillFileEvidences = state =>
  state.getIn(["competence", "employeeSkillFileEvidence"]);

export const getEmployeeSkillFileEvidencesFromState = (employeeId, skillId) =>
  createSelector(getEmployeeSkillFileEvidences, evidences =>
    evidences
      .filter(
        anEvidence =>
          anEvidence.get("skillId").toString() === skillId.toString() &&
          anEvidence.get("employeeId").toString() === employeeId.toString(),
      )
      .toList(),
  );

export const getEmployeeSkillEvidencesFromState = createSelector(
  (state, employeeId, skillId) =>
    state.getIn([
      "competence",
      "employeeSkillEvidence",
      employeeId.toString(),
      skillId.toString(),
    ]),
  employeeSkillEvidences =>
    employeeSkillEvidences ? employeeSkillEvidences.toList() : undefined,
);

// const getEmployeeSkillEvidences = (state) =>
//   state.getIn(["competence", "employeeSkillEvidence"]);

// export const getEmployeeSkillEvidencesFromState = (employeeId, skillId) =>
//   createSelector(getEmployeeSkillEvidences, (evidences) =>
//     evidences
//       .filter(
//         (anEvidence) =>
//           anEvidence.get("skillId").toString() === skillId.toString() &&
//           anEvidence.get("employeeId").toString() === employeeId.toString(),
//       )
//       .toList(),
//   );

const getAvailableCoursesIdsForRoleSelector = (state, roleId) =>
  state.getIn(["competence", "role", roleId.toString(), "availableCoursesIds"]);

export const getAvailableCoursesForRoleFromState = createSelector(
  getAvailableCoursesIdsForRoleSelector,
  getCoursesSelector,
  (coursesIdsForRole, allCourses) =>
    coursesIdsForRole
      ?.map(courseId => allCourses.get(courseId.toString()))
      .toList(),
);

const getAvailableSkillsIdsForRoleSelector = (state, roleId) =>
  state.getIn(["competence", "role", roleId.toString(), "availableSkillsIds"]);

export const getAvailableSkillsForRoleFromState = createSelector(
  getAvailableSkillsIdsForRoleSelector,
  getSkillsSelector,
  (skillsIdsForRole, allSkills) =>
    skillsIdsForRole
      ?.map(skillId => allSkills.get(skillId.toString()))
      .toList(),
);

/**
 * Specifications
 */

const getAvailableCoursesForSpecificationSelector = (state, specificationId) =>
  state?.getIn([
    "competence",
    "specification",
    specificationId.toString(),
    "availableCourses",
  ]);

export const getAvailableCoursesForSpecificationFromState = createSelector(
  getAvailableCoursesForSpecificationSelector,
  availableCourses => availableCourses?.toList(),
);

const getAvailableSkillsForSpecificationSelector = (state, specificationId) =>
  state?.getIn([
    "competence",
    "specification",
    specificationId.toString(),
    "availableSkills",
  ]);

export const getAvailableSkillsForSpecificationFromState = createSelector(
  getAvailableSkillsForSpecificationSelector,
  availableSkills => availableSkills?.toList(),
);

const getSpecificationsSelector = state =>
  state.getIn(["competence", "specification"]);

export const getSpecificationsBySpecificationIdFromState = createSelector(
  getSpecificationsSelector,
  specifications => specifications,
);

export const getSpecificationsFromState = createSelector(
  (state, specificationId) => {
    const specifications = getSpecificationsSelector(state);
    if (!specificationId) {
      return specifications;
    }

    // specificationId is given, return the matching specification and all its parents, in order
    let rv = EMPTY_LIST;
    let specification = specifications.get(specificationId.toString());
    while (specification) {
      rv = rv.push(specification);
      const parentId = specification.get("parentId") || "";
      specification = specifications.get(parentId.toString());
    }
    return rv;
  },
  specifications => specifications.toList(),
);

const getSpecificationSelector = (state, specificationId) =>
  specificationId &&
  state.getIn(["competence", "specification", specificationId.toString()]);

export const getSpecificationFromState = createSelector(
  getSpecificationSelector,
  specification => specification,
);

/**
 * SpecificationCourses
 */

const getSpecificationCoursesSelector = (state, specificationId) =>
  specificationId &&
  state.getIn([
    "competence",
    "specificationCourse",
    specificationId.toString(),
  ]);

export const getSpecificationCoursesFromState = createSelector(
  getSpecificationCoursesSelector,
  specificationCourses =>
    !!specificationCourses ? specificationCourses.toList() : EMPTY_LIST,
);

/**
 * SpecificationSkills
 */

const getSpecificationSkillsSelector = (state, specificationId) =>
  specificationId &&
  state.getIn(["competence", "specificationSkill", specificationId.toString()]);

export const getSpecificationSkillsFromState = createSelector(
  getSpecificationSkillsSelector,
  specificationSkills =>
    !!specificationSkills ? specificationSkills.toList() : EMPTY_LIST,
);

/**
 * Specification Gap Report
 */
export const getSpecificationGapReportEmployeeSpecificationStatusesFromState = createSelector(
  (state, employeeId) =>
    state.getIn([
      "competence",
      "specificationGapReport",
      "employeeSpecificationStatus",
      employeeId.toString(),
    ]),
  employeeSpecificationStatuses => employeeSpecificationStatuses,
);

export const getSpecificationGapReportEmployeeCoursesFromState = createSelector(
  (state, employeeId) =>
    state.getIn([
      "competence",
      "specificationGapReport",
      "employeeCourse",
      employeeId.toString(),
    ]),
  employeeCourses => employeeCourses,
);

export const getSpecificationGapReportEmployeeSkillsFromState = createSelector(
  (state, employeeId) =>
    state.getIn([
      "competence",
      "specificationGapReport",
      "employeeSkill",
      employeeId.toString(),
    ]),
  employeeSkills => employeeSkills,
);

export const getSpecificationGapReportSpecificationsFromState = createSelector(
  state =>
    state.getIn(["competence", "specificationGapReport", "specification"]),
  specifications => specifications.toList(),
);

export const getSpecificationGapReportSpecificationCoursesBySpecificationFromState = createSelector(
  state =>
    state.getIn([
      "competence",
      "specificationGapReport",
      "specificationCourse",
    ]),
  specificationCourses => specificationCourses,
);

export const getSpecificationGapReportSpecificationSkillsBySpecificationFromState = createSelector(
  state =>
    state.getIn(["competence", "specificationGapReport", "specificationSkill"]),
  specificationSkills => specificationSkills,
);

/**
 * Services
 */

const getAvailableCoursesForServiceSelector = (state, serviceId) =>
  state?.getIn([
    "competence",
    "service",
    serviceId.toString(),
    "availableCourses",
  ]);

export const getAvailableCoursesForServiceFromState = createSelector(
  getAvailableCoursesForServiceSelector,
  availableCourses => availableCourses?.toList(),
);

const getAvailableSkillsForServiceSelector = (state, serviceId) =>
  state?.getIn([
    "competence",
    "service",
    serviceId.toString(),
    "availableSkills",
  ]);

export const getAvailableSkillsForServiceFromState = createSelector(
  getAvailableSkillsForServiceSelector,
  availableSkills => availableSkills?.toList(),
);

const getServicesSelector = state => state.getIn(["competence", "service"]);

export const getServicesByServiceIdFromState = createSelector(
  getServicesSelector,
  services => services,
);

export const getServicesFromState = createSelector(
  (state, serviceId) => {
    const services = getServicesSelector(state);
    if (!serviceId) {
      return services;
    }

    // serviceId is given, return the matching service and all its parents, in order
    let rv = EMPTY_LIST;
    let service = services.get(serviceId.toString());
    while (service) {
      rv = rv.push(service);
      const parentId = service.get("parentId") || "";
      service = services.get(parentId.toString());
    }
    return rv;
  },
  services => services.toList(),
);

const getServiceSelector = (state, serviceId) =>
  serviceId && state.getIn(["competence", "service", serviceId.toString()]);

export const getServiceFromState = createSelector(
  getServiceSelector,
  service => service,
);

/**
 * ServiceCourses
 */

const getServiceCoursesSelector = (state, serviceId) =>
  serviceId &&
  state.getIn(["competence", "serviceCourse", serviceId.toString()]);

export const getServiceCoursesFromState = createSelector(
  getServiceCoursesSelector,
  serviceCourses => (!!serviceCourses ? serviceCourses.toList() : EMPTY_LIST),
);

/**
 * ServiceSkills
 */

const getServiceSkillsSelector = (state, serviceId) =>
  serviceId &&
  state.getIn(["competence", "serviceSkill", serviceId.toString()]);

export const getServiceSkillsFromState = createSelector(
  getServiceSkillsSelector,
  serviceSkills => (!!serviceSkills ? serviceSkills.toList() : EMPTY_LIST),
);

/**
 * Service Gap Report
 */
export const getServiceGapReportEmployeeServiceStatusesFromState = createSelector(
  (state, employeeId) =>
    state.getIn([
      "competence",
      "serviceGapReport",
      "employeeServiceStatus",
      employeeId.toString(),
    ]),
  employeeServiceStatuses => employeeServiceStatuses,
);

export const getServiceGapReportEmployeeCoursesFromState = createSelector(
  (state, employeeId) =>
    state.getIn([
      "competence",
      "serviceGapReport",
      "employeeCourse",
      employeeId.toString(),
    ]),
  employeeCourses => employeeCourses,
);

export const getServiceGapReportEmployeeSkillsFromState = createSelector(
  (state, employeeId) =>
    state.getIn([
      "competence",
      "serviceGapReport",
      "employeeSkill",
      employeeId.toString(),
    ]),
  employeeSkills => employeeSkills,
);

export const getServiceGapReportServicesFromState = createSelector(
  state => state.getIn(["competence", "serviceGapReport", "service"]),
  services => services.toList(),
);

export const getServiceGapReportServiceCoursesByServiceFromState = createSelector(
  state => state.getIn(["competence", "serviceGapReport", "serviceCourse"]),
  serviceCourses => serviceCourses,
);

export const getServiceGapReportServiceSkillsByServiceFromState = createSelector(
  state => state.getIn(["competence", "serviceGapReport", "serviceSkill"]),
  serviceSkills => serviceSkills,
);

export const getRoleStatsByRoleIdFromState = createSelector(
  state => state.getIn(["competence", "stats", "role"]),
  stats => stats,
);

export const getEmployeeRoleStatsFromState = createSelector(
  (state, employeeId, roleId) =>
    state.getIn([
      "competence",
      "stats",
      "employeeRole",
      employeeId.toString(),
      roleId.toString(),
    ]),
  stats => stats,
);

export const getRoleStatsByEmployeeAndRoleIdFromState = createSelector(
  state => state.getIn(["competence", "stats", "employeeRole"]),
  stats => stats,
);

export const getRoleStatsFromState = createSelector(
  (state, roleId) =>
    state.getIn(["competence", "stats", "role", roleId.toString()]),
  stats => stats,
);

export const getExperienceRecordsStatsByStatusFromState = createSelector(
  state => state.getIn(["competence", "stats", "experienceRecordsFilter"]),
  stats => stats,
);

export const getExperienceRecordsStatsFromState = createSelector(
  state => state.getIn(["competence", "stats", "experienceRecord"]),
  stats => stats,
);

export const getExperienceRecordStatsFromState = createSelector(
  (state, experienceRecordId) =>
    state.getIn([
      "competence",
      "stats",
      "experienceRecord",
      experienceRecordId.toString(),
    ]),
  stats => stats,
);

export const getCourseStatsByCourseIdFromState = createSelector(
  state => state.getIn(["competence", "stats", "course"]),
  stats => stats,
);

export const getCourseStatsFromState = createSelector(
  (state, courseId) =>
    state.getIn(["competence", "stats", "course", courseId.toString()]),
  stats => stats,
);

export const getCollectionStatsByCollectionIdFromState = createSelector(
  state => state.getIn(["competence", "stats", "collection"]),
  stats => stats,
);

export const getCollectionStatsFromState = createSelector(
  (state, competencyCollectionId) =>
    state.getIn([
      "competence",
      "stats",
      "collection",
      competencyCollectionId.toString(),
    ]),
  stats => stats,
);

export const getSkillStatsBySkillIdFromState = createSelector(
  state => state.getIn(["competence", "stats", "skill"]),
  stats => stats,
);

export const getSkillStatsFromState = createSelector(
  (state, skillId) =>
    state.getIn(["competence", "stats", "skill", skillId.toString()]),
  stats => stats,
);

export const getExperienceStatsFromState = createSelector(
  (state, experienceRecordId) =>
    state.getIn([
      "competence",
      "stats",
      "experienceRecord",
      experienceRecordId.toString(),
    ]),
  stats => stats,
);

export const getSpecificationStatsBySpecificationIdFromState = createSelector(
  state => state.getIn(["competence", "stats", "specification"]),
  state => state,
);

export const getSpecificationStatsFromState = createSelector(
  (state, specificationId) =>
    state.getIn([
      "competence",
      "stats",
      "specification",
      specificationId.toString(),
    ]),
  stats => stats,
);

export const getServiceStatsByServiceIdFromState = createSelector(
  state => state.getIn(["competence", "stats", "service"]),
  state => state,
);

export const getServiceStatsFromState = createSelector(
  (state, serviceId) =>
    state.getIn(["competence", "stats", "service", serviceId.toString()]),
  stats => stats,
);

export const getEmployeeStatsFromState = createSelector(
  (state, employeeId) =>
    state.getIn(["competence", "stats", "employee", employeeId.toString()]),
  stats => stats,
);

export const getEmployeeStatsByEmployeeIdFromState = createSelector(
  state => state.getIn(["competence", "stats", "employee"]),
  stats => stats,
);

export const getGapReportEmployeesByDepartmentFromState = createSelector(
  state => state.getIn(["competence", "gapReport", "employee"]),
  employeesByDepartment => employeesByDepartment,
);

export const getGapReportEmployeeByDepartmentFromState = createSelector(
  (state, employeeId) =>
    state.getIn(["competence", "gapReport", "employee", employeeId.toString()]),
  employeesByDepartment => employeesByDepartment,
);

export const getGapReportDepartmentsFromState = createSelector(
  state => state.getIn(["competence", "gapReport", "department"]),
  departments => departments,
);

export const getGapReportAppliedFiltersFromState = createSelector(
  state => state.getIn(["competence", "gapReport", "appliedFilters"]),
  appliedFilters => appliedFilters,
);

export const getGapReportOptionsFromState = createSelector(
  state => state.getIn(["competence", "gapReport", "appliedOptions"]),
  appliedOptions => appliedOptions,
);

export const getCourseGapReportCoursesFromState = createSelector(
  state => state.getIn(["competence", "courseGapReport", "course"]),
  courses => courses.toList(),
);

export const getTrainingReportEmployeesFromState = createSelector(
  state =>
    state.getIn([
      "competence",
      "trainingHistoryReport",
      "employeesAttendances",
    ]),
  employees => employees,
);

export const getCourseExpiryReportFromState = createSelector(
  state => state.getIn(["competence", "courseExpiryReport", "courses"]),
  courses => courses,
);

export const getTrainingAndCompetencyReportEmployeesFromState = createSelector(
  state =>
    state.getIn(["competence", "trainingAndCompetencyReport", "employees"]),
  employees => employees,
);

export const getCourseTrainingReportCoursesFromState = createSelector(
  state =>
    state.getIn(["competence", "courseTrainingHistoryReport", "courses"]),
  courses => courses,
);

export const getOutstandingTrainingReportCoursesFromState = createSelector(
  state =>
    state.getIn(["competence", "outstandingTrainingHistoryReport", "courses"]),
  courses => courses,
);

export const getCourseGapReportEmployeeCoursesFromState = createSelector(
  (state, employeeId) =>
    state.getIn([
      "competence",
      "courseGapReport",
      "employeeCourse",
      employeeId.toString(),
    ]),
  employeeCourses => employeeCourses,
);

const getCollectionItemsSelector = (state, collectionId) =>
  state.getIn([
    "competence",
    "collectionItem",
    "byCollectionId",
    collectionId.toString(),
  ]);

const getAvailableCollectionItemsSelector = state =>
  state.getIn(["competence", "collectionItem", "available"]);

export const getCollectionItemsFromState = createSelector(
  getCollectionItemsSelector,
  collectionItems => collectionItems,
);

export const groupItemsByRowAndColumn = collectionItems => {
  if (!collectionItems) return;

  return collectionItems
    .filter(collectionItem => !isNil(collectionItem.get("row")))
    .filter(collectionItem => !isNil(collectionItem.get("column")))
    .reduce(
      (result, collectionItem) =>
        result.setIn(
          [collectionItem.get("row"), collectionItem.get("column")],
          collectionItem,
        ),
      EMPTY_MAP,
    );
};

export const getCollectionItemsByRowAndColumnFromState = createSelector(
  getCollectionItemsSelector,
  collectionItems => groupItemsByRowAndColumn(collectionItems),
);

export const getCollectionCoursesFromState = createSelector(
  getCollectionItemsSelector,
  collectionItems =>
    collectionItems
      ? collectionItems
          .filter(collectionItem => !isNil(collectionItem.get("courseId")))
          .toList()
      : undefined,
);

export const getCollectionSkillsFromState = createSelector(
  getCollectionItemsSelector,
  collectionItems =>
    collectionItems
      ? collectionItems
          .filter(collectionItem => !isNil(collectionItem.get("skillId")))
          .toList()
      : undefined,
);

export const getAvailableCoursesForCollectionFromState = createSelector(
  getCoursesSelector,
  courses => courses,
);

export const getAvailableSkillsForCollectionFromState = createSelector(
  getSkillsSelector,
  skills => skills,
);

export const getAvailableCollectionItems = createSelector(
  getAvailableCollectionItemsSelector,
  collectionItems => (collectionItems ? collectionItems.toList() : undefined),
);

const getCollectionItemsBySkillSelector = (state, skillId) =>
  state.getIn([
    "competence",
    "collectionItem",
    "bySkillId",
    skillId.toString(),
  ]);

const getCollectionItemsByCourseSelector = (state, courseId) =>
  state.getIn([
    "competence",
    "collectionItem",
    "byCourseId",
    courseId.toString(),
  ]);

export const getCollectionItemsBySkillFromState = createSelector(
  getCollectionItemsBySkillSelector,
  collectionItems => (collectionItems ? collectionItems.toList() : undefined),
);

export const getCollectionItemsByCourseFromState = createSelector(
  getCollectionItemsByCourseSelector,
  collectionItems => (collectionItems ? collectionItems.toList() : undefined),
);
