import { compose } from "redux";
import { List } from "immutable";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import React, { useCallback, useState } from "react";

import {
  getCourseExpiryReportFromState,
  getCoursesByCourseIdFromState,
} from "features/competence.selectors";

import { EMPTY_MAP, EMPTY_LIST, ACTIONS } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import GapReportFilterBase from "features/components/GapReport/GapReportFilterBase";
import DateFromToPeriodSelection from "features/reports/common/DateFromToPeriodSelection";
import GapReportAutocompleteFilter from "features/components/GapReport/GapReportAutocompleteFilter";

const FILTERS = {
  DATE_RANGE: "dateRange",
  COURSES: "courses",
};

const initialFilter = EMPTY_MAP.set(FILTERS.DATE_RANGE, {}).set(
  FILTERS.COURSES,
  EMPTY_LIST,
);

const CourseExpiryReportFilter = ({
  courses,
  getGapReport,
  clearGapReport,
  downloadGapReport,
  employeesByDepartment,
}) => {
  const [count, setCount] = useState(0);
  const renderFilter = useCallback(
    ({ setFilter, filter }) => (
      <>
        <Grid item xs>
          <GapReportAutocompleteFilter
            label="Courses"
            options={courses}
            getOptionLabel={course => course.get("name")}
            value={filter
              .get(FILTERS.COURSES)
              .map(courseId => courses.get(courseId.toString()))}
            onChange={(_, value) =>
              setFilter(
                FILTERS.COURSES,
                List(value.map(course => course.get("courseId"))),
              )
            }
          />
        </Grid>
        <DateFromToPeriodSelection
          clearPeriod={count}
          onChanged={period => setFilter(FILTERS.DATE_RANGE, period)}
        />
      </>
    ),
    [count, courses],
  );

  const clearReport = useCallback(() => {
    setCount(count + 1);
    clearGapReport();
  }, [clearGapReport, count]);

  return (
    <GapReportFilterBase
      getGapReport={getGapReport}
      initialFilter={initialFilter}
      clearGapReport={clearReport}
      renderFilter={renderFilter}
      downloadGapReport={downloadGapReport}
      downloadBtnDisabled={!employeesByDepartment?.size}
    />
  );
};

export default compose(
  connect(state => ({
    courses: getCoursesByCourseIdFromState(state),
    employeesByDepartment: getCourseExpiryReportFromState(state),
    dataState: getSummarizedDataStatesFromState(
      state,
      ACTIONS.GET_COURSE_EXPIRY_REPORT,
    ),
  })),
)(CourseExpiryReportFilter);
