import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { isModalOpen, toggleModal } from "altus-modal";

export default modalId => WrappedComponent => {
  class Component extends PureComponent {
    constructor(props) {
      super(props);

      this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal = () => {
      const { dispatchToggleModal } = this.props;
      dispatchToggleModal({ modalId: modalId });
    };

    render() {
      const { Trigger, open } = this.props;

      return (
        <>
          {Trigger &&
            React.cloneElement(Trigger, {
              onClick: this.toggleModal,
            })}
          {open && (
            <WrappedComponent
              modalId={modalId}
              toggleModal={this.toggleModal}
              {...this.props}
            />
          )}
        </>
      );
    }
  }

  return connect(
    state => ({
      open: isModalOpen(state, modalId),
    }),
    {
      dispatchToggleModal: toggleModal,
    },
  )(Component);
};
