import React, { PureComponent } from "react";

import EmployeeCoursesBasePage from "features/components/EmployeeCoursesBasePage";

class EmployeeCoursesContainer extends PureComponent {
  render() {
    const { employeeId, breadcrumb, path } = this.props;

    return (
      <EmployeeCoursesBasePage
        title={breadcrumb}
        employeeId={employeeId}
        path={path}
      />
    );
  }
}

export default EmployeeCoursesContainer;
