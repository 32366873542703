import { List } from "immutable";

import { EmployeeSkillMapper } from "app/app.mappers";
import { ACTIONS, EMPTY_MAP } from "app/app.constants";

const initialState = EMPTY_MAP;

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_EMPLOYEE_SKILL: {
      if (error) return state;

      return state.setIn(
        [payload.employeeId.toString(), payload.skillId.toString()],
        EmployeeSkillMapper.from(payload),
      );
    }

    case ACTIONS.GET_EMPLOYEE_SKILLS_BY_SKILL: {
      if (error) return state;

      return List(payload).reduce(
        (result, employeeSkill) =>
          result.setIn(
            [
              employeeSkill.employeeId.toString(),
              employeeSkill.skillId.toString(),
            ],
            EmployeeSkillMapper.from(employeeSkill),
          ),
        EMPTY_MAP,
      );
    }

    default:
      return state;
  }
};
