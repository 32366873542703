import React, { PureComponent } from "react";
import { compose } from "redux";
import { withStyles } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";

class Certificate extends PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <SvgIcon classes={classes.root} {...this.props}>
        <path
          d="M18.9,6.1L18.9,6.1c-0.7-0.4-0.9-1.2-0.7-1.8l0-0.1c0.4-1-0.4-2-1.4-1.9l-0.1,0c-0.7,0.1-1.3-0.4-1.5-1.1l0-0.1
		c-0.2-1-1.5-1.4-2.3-0.7l-0.1,0.1c-0.5,0.5-1.3,0.5-1.8,0L11,0.3c-0.8-0.7-2-0.3-2.3,0.7l0,0.1C8.6,1.9,7.9,2.3,7.2,2.3l-0.1,0
		c-1-0.1-1.8,1-1.4,1.9l0,0.1C6,4.9,5.8,5.7,5.2,6L5.1,6.1c-0.9,0.5-0.9,1.9,0,2.4l0.1,0.1C5.8,8.9,6,9.6,5.8,10.3l0,0.1
		c-0.4,1,0.4,2,1.4,1.9l0.1,0c0.7-0.1,1.3,0.4,1.5,1.1l0,0.1c0.2,1,1.5,1.4,2.3,0.7l0.1-0.1c0.5-0.5,1.3-0.5,1.8,0l0.1,0.1
		c0.8,0.7,2,0.3,2.3-0.7l0-0.1c0.2-0.7,0.8-1.1,1.5-1.1l0.1,0c1,0.1,1.8-1,1.4-1.9l0-0.1c-0.3-0.6,0-1.4,0.6-1.7l0.1-0.1
		C19.8,7.9,19.8,6.6,18.9,6.1z M12,11.8c-2.5,0-4.5-2-4.5-4.5c0-2.5,2-4.5,4.5-4.5c2.5,0,4.5,2,4.5,4.5C16.5,9.8,14.5,11.8,12,11.8z
		"
        />
        <path
          d="M15.5,13.8l0,0.1c-0.3,1.1-1.6,1.5-2.4,0.8L13,14.6c-0.2-0.2-0.4-0.2-0.6-0.3l3.5,9.7l1.5-2l2.5,0.6l-3.5-9.7
		C15.9,13,15.6,13.4,15.5,13.8z"
        />
        <path
          d="M10.9,14.7c-0.8,0.7-2.2,0.3-2.4-0.8l0-0.1c-0.1-0.4-0.4-0.8-0.8-1l-3.5,9.7L6.7,22l1.5,2l3.5-9.7
		C11.4,14.4,11.2,14.5,10.9,14.7L10.9,14.7z"
        />
      </SvgIcon>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex",
  },
});

export default compose(withStyles(styles))(Certificate);
