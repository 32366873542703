import { compose } from "redux";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";

import { LoadingOverlay } from "altus-ui-components";
import { ReceivedDataState } from "altus-datastate";

import {
  getUsersFromState,
  getAppRolesFromState,
  getSummarizedDataStatesFromState,
} from "app/app.selectors";

import { ACTIONS, ICONS } from "app/app.constants";
import CrudBasePage from "app/components/CrudBasePage";
import { updateUser, createUserAppRoles } from "app/app.actions";
import AppRolesSelectField from "features/settings/components/AppRolesSelectField";

import {
  userAccessOnLoad,
  userAccessOnUnload,
} from "features/competence.actions";
import EmployeeAvatar from "app/components/EmployeeAvatar";

class UserAccessContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad } = this.props;
    dispatchOnLoad();
  }

  componentWillUnmount() {
    const { dispatchOnUnload } = this.props;
    dispatchOnUnload();
  }

  columns = [
    {
      xs: 4,
      title: "Name",
      getSortProperty: user => user.get("fullName"),
    },
    {
      xs: true,
      component: Grid,
      title: "Access Rights",
      getSortProperty: user => user.get("userType"),
      getValue: user => {
        return (
          <AppRolesSelectField
            value={user.get("appRoles")}
            appRoles={this.props.appRoles}
            onChange={appRoles =>
              this.props.dispatchCreateUserAppRoles(
                user.get("userId"),
                appRoles,
              )
            }
          />
        );
      },
    },
  ];

  renderIcon = item => (
    <EmployeeAvatar employeeDisplayName={item.get("fullName")} />
  );

  render() {
    const { users, breadcrumb, dataState } = this.props;

    return (
      <>
        <CrudBasePage
          Icon={ICONS.USER_ACCESS}
          items={users}
          title={breadcrumb}
          displaySearchField
          dataState={ReceivedDataState}
          SortableListProps={{
            getKey: item => item.get("userId"),
            columns: this.columns,
            renderIcon: this.renderIcon,
          }}
        />
        <LoadingOverlay dataState={dataState} />
      </>
    );
  }
}

export default compose(
  connect(
    state => ({
      users: getUsersFromState(state),
      appRoles: getAppRolesFromState(state),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.CREATE_USER_APP_ROLES,
        ACTIONS.GET_USERS,
      ),
    }),
    {
      dispatchUpdateUser: updateUser,
      dispatchOnLoad: userAccessOnLoad,
      dispatchOnUnload: userAccessOnUnload,
      dispatchCreateUserAppRoles: createUserAppRoles,
    },
  ),
)(UserAccessContainer);
