import React from "react";
import { withStyles } from "@material-ui/styles";
import LinkIcon from "@material-ui/icons/Link";
import { Grid, Typography, Tooltip } from "@material-ui/core";
import ISpringLogo from "assets/ispring_logo.svg";

class CourseELearningLink extends React.Component {
  /**
   * @param {Event} event
   */
  onClick = event => {
    const { link } = this.props;
    event.preventDefault();
    event.stopPropagation();

    window.open(link, "_blank");
  };

  render() {
    const { link, classes } = this.props;
    if (!link) return null;

    const linkHostname = new URL(link).hostname.replace("www.", "");

    const isISpringLink = link.includes("ispring");

    return (
      <Tooltip title={link}>
        <Grid
          container
          alignItems="center"
          spacing={1}
          wrap="nowrap"
          onClick={this.onClick}
        >
          {isISpringLink ? (
            <img
              src={ISpringLogo}
              className={classes.logo}
              alt="iSpring logo"
            />
          ) : (
            <LinkIcon />
          )}
          <Typography noWrap className={classes.link} variant="body2">
            {linkHostname}
          </Typography>
        </Grid>
      </Tooltip>
    );
  }
}

const styles = theme => ({
  link: {
    color: theme.urlLink.color,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  logo: {
    height: theme.spacing(3),
  },
});

export default withStyles(styles)(CourseELearningLink);
