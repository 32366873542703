import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { PureComponent } from "react";
import { IconButton, Tooltip } from "@material-ui/core";

import {
  specificationsOnLoad,
  specificationsOnUnload,
  deleteSpecificationAskConfirmation,
} from "features/competence.actions";

import { ACTIONS, ICONS } from "app/app.constants";
import SpecificationsBasePage from "features/components/SpecificationsBasePage";
import { getActionDataStateFromState } from "app/app.selectors";
import routePaths from "app/routePaths";

class SpecificationsContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad } = this.props;
    dispatchOnLoad();
  }

  componentWillUnmount() {
    const { dispatchOnUnload } = this.props;
    dispatchOnUnload();
  }

  actions = [
    {
      getValue: item => (
        <Tooltip title="Delete">
          <IconButton
            onClick={() => this.props.dispatchDeleteSpecification(item)}
          >
            <ICONS.DELETE fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  createSortableListRowProps = item => ({
    component: Link,
    to: `${routePaths.hrPortal}/specifications/${item.get("specificationId")}`,
  });

  render() {
    const { dataState, breadcrumb } = this.props;

    return (
      <SpecificationsBasePage
        displayAddButton
        title={breadcrumb}
        dataState={dataState}
        actions={this.actions}
        createSortableListRowProps={this.createSortableListRowProps}
      />
    );
  }
}

export default compose(
  connect(
    state => ({
      dataState: getActionDataStateFromState(state, ACTIONS.GET_SPECIFICATIONS),
    }),
    {
      dispatchOnLoad: specificationsOnLoad,
      dispatchOnUnload: specificationsOnUnload,
      dispatchDeleteSpecification: deleteSpecificationAskConfirmation,
    },
  ),
)(SpecificationsContainer);
