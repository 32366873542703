const fileFromDto = ({ originalFileName, ...file }) => ({
  ...file,
  name: originalFileName,
});

export default {
  File: {
    from: fileFromDto,
  },
};
