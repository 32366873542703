import isNil from "lodash/isNil";

import { TILE_CHIP_TYPES } from "altus-ui-components";

import { ROUTE_KEYS } from "app/routes";

export const setRouteCount = (route, count) => {
  const breadcrumb = isNil(count)
    ? route.breadcrumb
    : `${route.breadcrumb} (${count})`;

  return {
    ...route,
    breadcrumb,
  };
};

export const getRoleRouteCount = (roleStats, key) => {
  switch (key) {
    case ROUTE_KEYS.ROLE_EMPLOYEES:
      return roleStats.get("memberCount");
    case ROUTE_KEYS.ROLE_SKILLS:
      return roleStats.get("requiredSkillsCount");
    case ROUTE_KEYS.ROLE_COURSES:
      return roleStats.get("requiredCoursesCount");
    default:
      return null;
  }
};

export const getEmployeeRouteCount = (employeeStats, key) => {
  switch (key) {
    case ROUTE_KEYS.COURSES:
      return employeeStats.get("coursesCount");
    case ROUTE_KEYS.EDUCATION:
      return employeeStats.get("educationCount");
    case ROUTE_KEYS.ROLES:
      return employeeStats.get("rolesCount");
    case ROUTE_KEYS.SERVICES:
      return employeeStats.get("servicesCount");
    case ROUTE_KEYS.WORK_HISTORY:
      return employeeStats.get("workHistoryCount");
    case ROUTE_KEYS.SKILLS:
      return employeeStats.get("skillsCount");
    case ROUTE_KEYS.EMPLOYEE_EXPERIENCE_RECORDS:
      return employeeStats.get("experienceRecordsCount");
    case ROUTE_KEYS.APPROVALS:
      return employeeStats.get("pendingApproval");
    default:
      return undefined;
  }
};

export const getEmployeeChipType = (stats, route) => {
  switch (route.key) {
    case ROUTE_KEYS.APPROVALS:
      return getEmployeeRouteCount(stats, route.key) > 0
        ? TILE_CHIP_TYPES.NOTIFICATION
        : TILE_CHIP_TYPES.VISIBLE;

    default:
      break;
  }

  return route.key ? TILE_CHIP_TYPES.VISIBLE : TILE_CHIP_TYPES.HIDDEN;
};
