import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";

import { getEmployeeSkillsBySkill } from "features/competence.actions";

import routePaths from "app/routePaths";
import { ACTIONS, EMPTY_LIST } from "app/app.constants";
import CrudBasePage from "app/components/CrudBasePage";
import { getActionDataStateFromState } from "app/app.selectors";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";
import { getEmployeeSkillsBySkillFromState } from "features/competence.selectors";
import EmployeeAvatar from "app/components/EmployeeAvatar";

class SkillEmployeesContainer extends PureComponent {
  componentDidMount() {
    const { dispatchGetEmployeeSkillsBySkill, skillId } = this.props;

    dispatchGetEmployeeSkillsBySkill(skillId);
  }

  columns = [
    {
      xs: 1,
      title: "#",
      getSortProperty: item => item.get("employeeNumber"),
    },
    {
      xs: 4,
      title: "Name",
      getSortProperty: item => item.get("employeeDisplayName"),
    },
    {
      xs: true,
      title: "Evidences",
      getSortProperty: item => item.get("fileEvidencesCount"),
    },
    {
      xs: true,
      title: "",
      component: Grid,
      container: true,
      justify: "flex-end",
      getSortProperty: item => item.get("competencyStatus"),
      getValue: item => (
        <CompetencyStatusChip status={item.get("competencyStatus")} />
      ),
      filter: {
        label: "Status",
        defaultText: "All",
        getFilterText: filter => statusToTooltipText(filter),
      },
    },
  ];

  getKey = employeeSkill => employeeSkill.get("employeeId");

  renderIcon = employeeSkill => (
    <EmployeeAvatar
      employeeDisplayName={employeeSkill.get("employeeDisplayName")}
    />
  );

  render() {
    const { breadcrumb, dataState, employeeSkills } = this.props;

    return (
      <CrudBasePage
        Icon={null}
        items={employeeSkills}
        title={breadcrumb}
        dataState={dataState}
        displayAddButton={false}
        SortableListProps={{
          getKey: this.getKey,
          columns: this.columns,
          renderIcon: this.renderIcon,
          createSortableListRowProps: item => ({
            component: Link,
            to: `${routePaths.hrPortal}/employees/${item.get(
              "employeeId",
            )}/skills/${item.get("skillId")}`,
          }),
        }}
      />
    );
  }
}

SkillEmployeesContainer.defaultProps = {
  employeeSkills: EMPTY_LIST,
};

export default compose(
  connect(
    (initialState, { skillId }) => {
      const employeeSkillSelector = getEmployeeSkillsBySkillFromState(skillId);

      return state => ({
        employeeSkills: employeeSkillSelector(state),
        dataState: getActionDataStateFromState(
          state,
          ACTIONS.GET_EMPLOYEE_SKILLS_BY_SKILL,
        ),
      });
    },
    {
      dispatchGetEmployeeSkillsBySkill: getEmployeeSkillsBySkill,
    },
  ),
)(SkillEmployeesContainer);
