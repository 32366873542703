import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Field } from "redux-form/immutable";
import { reduxForm } from "redux-form/immutable";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Avatar, Button } from "@material-ui/core";

import { getInitials } from "utils/app.util";
import AccessControl from "app/components/AccessControl";
import withToggleModal from "app/components/withToggleModal";
import { EMPLOYEE_FORM } from "features/competence.constants";
import { getFeatureFlagStatusFromState } from "app/app.selectors";
import GenerateCVModal from "features/components/GenerateCVModal";
import { APP_ROLES_HR_AND_ADMIN, FEATURE_FLAG } from "app/app.constants";
import ReadOnlyFieldReduxForm from "app/components/form/ReadOnlyFieldReduxForm";
import { generateAndDownloadEmployeeCV } from "features/competence.actions";

class EmployeeDetailsForm extends PureComponent {
  render() {
    const {
      classes,
      children,
      employeeId,
      isPdfExportFeatureFlagEnabled,
      dispatchGenerateAndDownloadEmployeeCV,
    } = this.props;

    return (
      <form>
        <Grid container spacing={4} direction="column">
          <Grid container item xs={12} spacing={2}>
            <Grid container item xs={2} direction="column" spacing={4}>
              <Grid item>
                <Field
                  name={EMPLOYEE_FORM.DISPLAY_NAME}
                  component={({ input }) => (
                    <Avatar
                      classes={{
                        root: classes.avatarRoot,
                      }}
                    >
                      {getInitials(input.value)}
                    </Avatar>
                  )}
                />
              </Grid>
            </Grid>
            <Grid container item xs spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={2}>
                  <Field
                    label="#"
                    component={ReadOnlyFieldReduxForm}
                    name={EMPLOYEE_FORM.EMPLOYEE_NUMBER}
                  />
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Field
                  label="First Name"
                  component={ReadOnlyFieldReduxForm}
                  name={EMPLOYEE_FORM.FIRST_NAME}
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  label="Last Name"
                  component={ReadOnlyFieldReduxForm}
                  name={EMPLOYEE_FORM.LAST_NAME}
                />
              </Grid>
              <Grid item xs={10}>
                <Field
                  label="Position"
                  component={ReadOnlyFieldReduxForm}
                  name={EMPLOYEE_FORM.POSITION}
                />
              </Grid>
              <Grid item xs={10}>
                <Field
                  defaultValue="-"
                  label="E-mail (work)"
                  component={ReadOnlyFieldReduxForm}
                  name={EMPLOYEE_FORM.EMAIL_WORK}
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  label="Phone"
                  defaultValue="-"
                  component={ReadOnlyFieldReduxForm}
                  name={EMPLOYEE_FORM.PHONE}
                />
              </Grid>
              <Grid item xs={5}>
                <Field
                  label="Mobile"
                  defaultValue="-"
                  component={ReadOnlyFieldReduxForm}
                  name={EMPLOYEE_FORM.MOBILE_PHONE}
                />
              </Grid>
              {!!children && (
                <Grid item xs={10}>
                  {children}
                </Grid>
              )}
              <Grid item xs={12}>
                <AccessControl accessLevel={APP_ROLES_HR_AND_ADMIN}>
                  <Grid item xs={2}>
                    <GenerateCVModal
                      onSubmit={dispatchGenerateAndDownloadEmployeeCV(
                        employeeId,
                      )}
                      Trigger={
                        <Button fullWidth color="default" variant="contained">
                          Create CV
                        </Button>
                      }
                      isPdfExportFeatureFlagEnabled={
                        isPdfExportFeatureFlagEnabled
                      }
                    />
                  </Grid>
                </AccessControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const styles = () => ({
  avatarRoot: {
    height: 100,
    width: 100,
    margin: "0 auto",
  },
});

export default compose(
  connect(
    state => ({
      isPdfExportFeatureFlagEnabled: getFeatureFlagStatusFromState(
        state,
        FEATURE_FLAG.ExportPDF,
      ),
    }),
    {
      dispatchGenerateAndDownloadEmployeeCV: generateAndDownloadEmployeeCV,
    },
  ),
  reduxForm({
    form: EMPLOYEE_FORM.ID,
  }),
  withToggleModal,
  withStyles(styles),
)(EmployeeDetailsForm);
