import { compose } from "redux";
import { connect } from "react-redux";
import { isImmutable } from "immutable";
import React, { useCallback, useState, useEffect, useMemo } from "react";

import {
  Tooltip,
  IconButton,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  withStyles,
} from "@material-ui/core";

import { TableRowActionsCell } from "altus-ui-components";

import {
  getEmployeeCourseAttendances,
  previewEmployeeCourseAttendanceFile,
  initializeAddEmployeeCourseAttendanceModal,
  deleteEmployeeCourseAttendanceAskConfirmation,
  deleteEmployeeCourseAttendanceFileAskConfirmation,
} from "features/competence.actions";

import {
  getCurrentUserFromState,
  getSummarizedDataStatesFromState,
} from "app/app.selectors";

import {
  getCourseFromState,
  getEmployeeCourseFromState,
  getEmployeeCourseAttendancesFromState,
} from "features/competence.selectors";

import { isAdminOrHR } from "utils/app.util";
import { formatDate } from "utils/format.util";
import { ACTIONS, ICONS, Format } from "app/app.constants";
import withToggleModal from "app/components/withToggleModal";
import SortableTable from "app/components/Table/SortableTable";
import DownloadFileColumn from "app/components/DownloadFileColumn";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";
import { ADD_EMPLOYEE_COURSE_ATTENDANCE_MODAL_ID } from "features/competence.constants";
import AddEmployeeCourseCertificateModal from "features/components/AddEmployeeCourseCertificateModal";

const EmployeeCourseAttendanceContainer = ({
  course,
  classes,
  courseId,
  employeeId,
  currentUser,
  dispatchToggleModal,
  employeeCourseAttendances,
  dispatchGetEmployeeCourseAttendances,
  dispatchDeleteEmployeeCourseAttendance,
  dispatchPreviewEmployeeCourseAttendanceFile,
  dispatchInitializeAddEmployeeCourseAttendanceModal,
  dispatchDeleteEmployeeCourseAttendanceFileAskConfirmation,
}) => {
  const isCourseRenewable = useMemo(
    () => (course?.get("validityMonths") === null ? false : true),
    [course],
  );

  const [includeHistory, setIncludeHistory] = useState(false);

  const onIncludeHistoryChange = useCallback(
    event => {
      if (isCourseRenewable) {
        setIncludeHistory(event.target.checked);
      } else {
        setIncludeHistory(true);
      }
    },
    [isCourseRenewable],
  );

  const loadEmployeeCourseAttendances = useCallback(() => {
    if (isCourseRenewable) {
      dispatchGetEmployeeCourseAttendances(employeeId, courseId, {
        includeHistory,
      });
    } else {
      dispatchGetEmployeeCourseAttendances(employeeId, courseId);
    }
  }, [
    employeeId,
    courseId,
    includeHistory,
    isCourseRenewable,
    dispatchGetEmployeeCourseAttendances,
  ]);

  useEffect(() => {
    loadEmployeeCourseAttendances(employeeId, courseId);
  }, [employeeId, courseId, loadEmployeeCourseAttendances]);

  const columns = useMemo(
    () => [
      {
        xl: true,
        id: "icon",
        paddingLeft: "16px",
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (row.depth !== 0) return <TableRowActionsCell minItems={1} />;

          return (
            <TableRowActionsCell>
              {row.original.get("isOverridden") ? (
                <ICONS.COURSE_OVERRIDEN color="primary" />
              ) : (
                <ICONS.COURSE_CERTIFICATE color="primary" />
              )}
            </TableRowActionsCell>
          );
        },
      },
      {
        xs: true,
        id: "startDate",
        title: "Start",
        Header: "Start",
        getSortProperty: item => formatDate(item.get("startDate"), Format.date),
        Cell: ({ row }) => {
          if (row.depth === 0)
            return formatDate(row.original.get("startDate"), Format.date);

          return (
            <DownloadFileColumn
              file={row.original}
              onClick={() =>
                dispatchPreviewEmployeeCourseAttendanceFile(
                  employeeId,
                  courseId,
                  row.original.employeeCourseAttendanceId,
                  row.original.employeeCourseAttendanceFileId,
                )
              }
            />
          );
        },
      },
      {
        xs: true,
        id: "endDate",
        title: "Finished",
        Header: "Finished",
        getSortProperty: item => formatDate(item.get("endDate"), Format.date),
        accessor: item =>
          isImmutable(item) ? formatDate(item.get("endDate"), Format.date) : "",
        Cell: ({ value }) => {
          if (!value) {
            return null;
          }

          return value;
        },
      },
      {
        xs: true,
        id: "expirationDate",
        title: "Expires",
        Header: "Expires",
        getSortProperty: item =>
          formatDate(item.get("expirationDate"), Format.date),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return formatDate(row.original.get("expirationDate"), Format.date);
        },
      },
      {
        xs: true,
        title: "",
        id: "competencyStatus",
        Header: "Status",
        component: Grid,
        container: true,
        justify: "flex-end",
        getSortProperty: item => item.get("competencyStatus"),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return (
            <CompetencyStatusChip
              status={row.original.get("competencyStatus")}
            />
          );
        },
        filter: {
          label: "Status",
          defaultText: "All",
          getFilterText: filter => statusToTooltipText(filter),
        },
      },
      {
        id: "actions",
        Header: <TableRowActionsCell minItems={2} />,
        Footer: <TableRowActionsCell minItems={2} />,
        Cell: ({ row }) => {
          var canDelete = true;

          if (row.depth === 0)
            canDelete =
              !row.original.get("isApproved") || isAdminOrHR(currentUser);

          return (
            <TableRowActionsCell minItems={2}>
              <Tooltip
                title={
                  row.depth === 0
                    ? !canDelete
                      ? "Only HR can delete approved attendances"
                      : "Delete course attendance"
                    : "Delete course attendance file"
                }
              >
                <Box>
                  <IconButton
                    disabled={!canDelete}
                    onClick={() =>
                      row.depth === 0
                        ? dispatchDeleteEmployeeCourseAttendance(row.original)
                        : dispatchDeleteEmployeeCourseAttendanceFileAskConfirmation(
                            {
                              employeeId,
                              courseId,
                              attendanceId:
                                row.original.employeeCourseAttendanceId,
                              attendanceFileId:
                                row.original.employeeCourseAttendanceFileId,
                              name: row.original.name,
                            },
                          )
                    }
                  >
                    <ICONS.DELETE fontSize="small" />
                  </IconButton>
                </Box>
              </Tooltip>
            </TableRowActionsCell>
          );
        },
      },
    ],
    [
      courseId,
      employeeId,
      currentUser,
      dispatchDeleteEmployeeCourseAttendance,
      dispatchPreviewEmployeeCourseAttendanceFile,
      dispatchDeleteEmployeeCourseAttendanceFileAskConfirmation,
    ],
  );

  const initializeAndToggleAddEmployeeCourseCertificateModal = () => {
    dispatchInitializeAddEmployeeCourseAttendanceModal(
      employeeId,
      courseId,
      course.get("name"),
      toggleAddEmployeeCourseCertificateModal,
    );
  };

  const toggleAddEmployeeCourseCertificateModal = () =>
    dispatchToggleModal({
      modalId: ADD_EMPLOYEE_COURSE_ATTENDANCE_MODAL_ID,
    });

  const listActions = useMemo(
    () =>
      isCourseRenewable
        ? [
            {
              renderAction: () => (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={includeHistory}
                      onChange={onIncludeHistoryChange}
                    />
                  }
                  label="Show renewable course history"
                  classes={{
                    root: classes.formControlLabelRoot,
                  }}
                />
              ),
              getTitle: () => "Show renewable course history",
            },
          ]
        : [],
    [isCourseRenewable, classes, includeHistory, onIncludeHistoryChange],
  );

  return (
    <>
      <SortableTable
        stickyHeader
        disableSortBy
        displayAddButton
        columns={columns}
        listActions={isCourseRenewable ? listActions : []}
        title="Attendence"
        addButtonOnClick={initializeAndToggleAddEmployeeCourseCertificateModal}
        keyName="attendanceId"
        useGlobalFilter={false}
        subListPropertyName="files"
        items={employeeCourseAttendances}
        noItemsMessage="No course attendences added yet..."
      />
      <AddEmployeeCourseCertificateModal employeeId={employeeId} />
    </>
  );
};

const styles = () => ({
  formControlLabelRoot: {
    marginLeft: 0,
  },
});

export default compose(
  connect(
    (state, { employeeId, courseId }) => ({
      currentUser: getCurrentUserFromState(state),
      employeeCourseAttendances: getEmployeeCourseAttendancesFromState(
        state,
        employeeId,
        courseId,
      ),
      employeeCourse: getEmployeeCourseFromState(state, employeeId, courseId),
      course: getCourseFromState(state, courseId),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_EMPLOYEE_COURSE_ATTENDANCES,
        ACTIONS.DELETE_EMPLOYEE_COURSE_ATTENDANCE,
        ACTIONS.DELETE_EMPLOYEE_COURSE_ATTENDANCE_FILE,
      ),
    }),
    {
      dispatchGetEmployeeCourseAttendances: getEmployeeCourseAttendances,
      dispatchDeleteEmployeeCourseAttendance: deleteEmployeeCourseAttendanceAskConfirmation,
      dispatchPreviewEmployeeCourseAttendanceFile: previewEmployeeCourseAttendanceFile,
      dispatchInitializeAddEmployeeCourseAttendanceModal: initializeAddEmployeeCourseAttendanceModal,
      dispatchDeleteEmployeeCourseAttendanceFileAskConfirmation: deleteEmployeeCourseAttendanceFileAskConfirmation,
    },
  ),
  withStyles(styles),
  withToggleModal,
)(EmployeeCourseAttendanceContainer);
