import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import {
  getRoleFromState,
  getRoleStatsFromState,
} from "features/competence.selectors";

import { RoleMapper } from "app/app.mappers";
import EntityDetailsHeader from "app/components/EntityDetailsHeader";
import { ICONS, COMPETENCY_STATUS, EMPTY_MAP } from "app/app.constants";

const RoleDetailsHeader = ({
  children,
  hideStatus,
  roleStats = EMPTY_MAP,
  role = RoleMapper.initial,
}) => (
  <EntityDetailsHeader
    Icon={<ICONS.ROLE />}
    title={role.get("name")}
    subtitle={role.get("description")}
    competencyStatus={
      hideStatus ? COMPETENCY_STATUS.NONE : roleStats.get("competencyStatus")
    }
  >
    {children}
  </EntityDetailsHeader>
);

export default compose(
  connect((state, { roleId }) => ({
    role: getRoleFromState(state, roleId),
    roleStats: getRoleStatsFromState(state, roleId),
  })),
)(RoleDetailsHeader);
