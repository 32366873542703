import Agent from "infrastructure/agent";

const getWorkHistoryCategories = () => Agent.get("/data/workhistorycategory");

const getEducationCategories = () => Agent.get("/data/educationcategory");

/**
 * Course Group
 */
const getCourseGroups = () => Agent.get(`/data/coursegroup`);

const getCourseGroup = () => Agent.get(`/data/coursegroup`);

const createCourseGroup = courseGroup =>
  Agent.post(`/data/coursegroup`, courseGroup);

const updateCourseGroup = (courseGroupId, courseGroup) =>
  Agent.put(`/data/coursegroup/${courseGroupId}`, courseGroup);

const deleteCourseGroup = courseGroupId =>
  Agent.delete(`/data/coursegroup/${courseGroupId}`);

/**
 * Course Group
 */
const getCourseTypes = () => Agent.get(`/data/coursetype`);

const getCourseType = () => Agent.get(`/data/coursetype`);

const createCourseType = courseType =>
  Agent.post(`/data/coursetype`, courseType);

const updateCourseType = (courseTypeId, courseType) =>
  Agent.put(`/data/coursetype/${courseTypeId}`, courseType);

const deleteCourseType = courseTypeId =>
  Agent.delete(`/data/coursetype/${courseTypeId}`);

/**
 * Department
 */
const getDepartments = () => Agent.get(`/data/department`);

const getMyTeamDepartments = () => Agent.get(`/data/myteam/department`);

/**
 * Manager
 */
const getManagers = () => Agent.get(`/data/manager`);

const getMyTeamManagers = () => Agent.get(`/data/myteam/manager`);

/**
 * Country
 */
const getCountries = () => Agent.get(`/data/country`);

export default {
  getDepartments,
  getCourseGroup,
  getCourseGroups,
  createCourseGroup,
  updateCourseGroup,
  deleteCourseGroup,
  getEducationCategories,
  getWorkHistoryCategories,
  getMyTeamDepartments,
  getManagers,
  getMyTeamManagers,
  getCountries,
  getCourseTypes,
  getCourseType,
  createCourseType,
  updateCourseType,
  deleteCourseType,
};
