import { OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";

import {
  SkillMapper,
  CourseMapper,
  SpecificationMapper,
} from "app/app.mappers";

const initialState = OrderedMap();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_SPECIFICATIONS: {
      return initialState;
    }

    case ACTIONS.GET_SPECIFICATIONS: {
      if (error) return state;

      return OrderedMap(
        payload.map(specification => [
          specification.specificationId.toString(),
          SpecificationMapper.from(specification),
        ]),
      );
    }

    case ACTIONS.GET_SPECIFICATION_WITH_PARENTS: {
      if (error) return state;

      return state.merge(
        OrderedMap(
          payload.map(specification => [
            specification.specificationId.toString(),
            SpecificationMapper.from(specification),
          ]),
        ),
      );
    }

    case ACTIONS.GET_AVAILABLE_COURSES_FOR_SPECIFICATION: {
      if (error) return state;

      return state.setIn(
        [action.specificationId.toString(), "availableCourses"],
        OrderedMap(
          payload.map(course => [
            course.courseId.toString(),
            CourseMapper.from(course),
          ]),
        ),
      );
    }

    case ACTIONS.GET_AVAILABLE_SKILLS_FOR_SPECIFICATION: {
      if (error) return state;

      return state.setIn(
        [action.specificationId.toString(), "availableSkills"],
        OrderedMap(
          payload.map(skill => [
            skill.skillId.toString(),
            SkillMapper.from(skill),
          ]),
        ),
      );
    }

    case ACTIONS.GET_SPECIFICATION:
    case ACTIONS.UPDATE_SPECIFICATION: {
      if (error) return state;

      return state.set(
        payload.specificationId.toString(),
        SpecificationMapper.from(payload),
      );
    }
    default:
      return state;
  }
};
