import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { EMPTY_MAP, ICONS } from "app/app.constants";
import EntityDetailsHeader from "app/components/EntityDetailsHeader";
import { getCollectionFromState } from "features/competence.selectors";

const CollectionDetailsHeader = ({ children, collection }) => {
  return (
    <EntityDetailsHeader
      Icon={<ICONS.COLLECTION />}
      title={collection.get("name")}
      subtitle={collection.get("description")}
    >
      {children}
    </EntityDetailsHeader>
  );
};

CollectionDetailsHeader.defaultProps = {
  collection: EMPTY_MAP,
};

export default compose(
  connect((state, { collectionId }) => ({
    collection: getCollectionFromState(state, collectionId),
  })),
)(CollectionDetailsHeader);
