import { compose } from "redux";
import React, { PureComponent } from "react";

import {
  Grid,
  Button,
  Tooltip,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";

import { SortableList } from "altus-ui-components";

import { ICONS, EMPTY_LIST } from "app/app.constants";

class CollectionItemList extends PureComponent {
  actions = [
    {
      getValue: item => (
        <Tooltip title="Delete">
          <IconButton onClick={() => this.props.onDelete(item)}>
            <ICONS.DELETE fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  listActions = [
    {
      renderAction: () => (
        <Button color="primary" variant="contained" onClick={this.props.onAdd}>
          Add
        </Button>
      ),
      getTitle: () => "",
    },
    {
      renderAction: () => (
        <Grid item>
          <Typography variant="h6" className={this.props.classes.listTitle}>
            {this.props.title}
          </Typography>
        </Grid>
      ),
      getTitle: () => "",
    },
  ];

  render() {
    const { items, getKey, columns, classes, SortableListProps } = this.props;

    return (
      <Grid container className={classes.root}>
        <SortableList
          noheader
          items={items}
          getKey={getKey}
          columns={columns}
          actions={this.actions}
          listActions={this.listActions}
          classes={{
            headerRootSticky: classes.headerRootSticky,
          }}
          {...SortableListProps}
        />
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  listTitle: {
    marginLeft: theme.spacing(2),
  },
  headerRootSticky: {
    borderBottom: theme.gapReport.defaultBorder,
  },
});

CollectionItemList.defaultProps = {
  items: EMPTY_LIST,
};

export default compose(withStyles(styles))(CollectionItemList);
