import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import {
  COURSE_FORM,
  COURSE_DETAILS_MODAL_ID,
} from "features/competence.constants";

import { getFormValuesFromState } from "app/app.selectors";
import SimpleFormModal from "app/components/SimpleFormModal";
import CourseDetails from "features/components/CourseDetails";

const CourseDetailsModal = ({ course }) => {
  return (
    <SimpleFormModal
      submitText="Generate"
      displayCancelBtn={false}
      displaySubmitBtn={false}
      component={CourseDetails}
      modalId={COURSE_DETAILS_MODAL_ID}
      title={course ? course.get("name") : ""}
      FormProps={{
        course,
      }}
      form={{
        form: COURSE_FORM.ID,
      }}
    />
  );
};

export default compose(
  connect(state => ({
    course: getFormValuesFromState(state, COURSE_FORM.ID),
  })),
  React.memo,
)(CourseDetailsModal);
