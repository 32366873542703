import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import Delete from "@material-ui/icons/Delete";
import { Tooltip, IconButton } from "@material-ui/core";

import {
  getSkillGroups,
  editSkillGroupOnSubmit,
  createSkillGroupOnSubmit,
  initializeEditSkillGroup,
  deleteSkillGroupAskConfirmation,
} from "features/competence.actions";

import { ACTIONS, ICONS } from "app/app.constants";
import CrudBasePage from "app/components/CrudBasePage";
import { getActionDataStateFromState } from "app/app.selectors";
import { SKILL_GROUP_FORM } from "features/competence.constants";
import { getSkillGroupsFromState } from "features/competence.selectors";
import SkillGroupForm from "features/settings/components/SkillGroupForm";

class SettingsSkillGroupsContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad } = this.props;
    dispatchOnLoad();
  }

  columns = [
    {
      xs: true,
      title: "Name",
      getSortProperty: item => item.get("name"),
    },
  ];

  actions = [
    {
      getValue: item => (
        <Tooltip title="Delete">
          <IconButton onClick={() => this.props.dispatchDeleteSkillGroup(item)}>
            <Delete fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  render() {
    const {
      dataState,
      breadcrumb,
      skillGroups,
      dispatchEditSkillGroupOnSubmit,
      dispatchCreateSkillGroupOnSubmit,
      dispatchInitializeEditSkillGroup,
    } = this.props;

    return (
      <CrudBasePage
        displayAddButton
        displaySearchField
        Icon={ICONS.SKILL}
        items={skillGroups}
        title={breadcrumb}
        dataState={dataState}
        CreateEntityFormComponent={SkillGroupForm}
        EditEntityFormComponent={SkillGroupForm}
        createEntityForm={{
          form: SKILL_GROUP_FORM.ID,
        }}
        editEntityForm={{
          form: SKILL_GROUP_FORM.ID,
        }}
        CreateModalProps={{
          submitText: "Add",
          title: "Add skill group",
          onSubmit: dispatchCreateSkillGroupOnSubmit,
        }}
        EditModalProps={{
          submitText: "Save",
          title: "Edit skill group",
          formTitleField: SKILL_GROUP_FORM.NAME,
          onSubmit: dispatchEditSkillGroupOnSubmit,
        }}
        SortableListProps={{
          actions: this.actions,
          columns: this.columns,
          getKey: item => item.get("skillGroupId"),
          onRowClick: dispatchInitializeEditSkillGroup,
        }}
      />
    );
  }
}

export default compose(
  connect(
    state => ({
      skillGroups: getSkillGroupsFromState(state),
      dataState: getActionDataStateFromState(state, ACTIONS.GET_SKILL_GROUPS),
    }),
    {
      dispatchOnLoad: getSkillGroups,
      dispatchEditSkillGroupOnSubmit: editSkillGroupOnSubmit,
      dispatchDeleteSkillGroup: deleteSkillGroupAskConfirmation,
      dispatchCreateSkillGroupOnSubmit: createSkillGroupOnSubmit,
      dispatchInitializeEditSkillGroup: initializeEditSkillGroup,
    },
  ),
)(SettingsSkillGroupsContainer);
