import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import {
  AppFailure,
  ErrorBoundary as ErrorBoundaryCore,
} from "altus-ui-components";

import { IN_DEV } from "app/app.constants";
import { setApplicationFailure } from "app/app.actions";

const renderErrorContent = error => (
  <AppFailure errorMessage={IN_DEV ? error.message : undefined} />
);

const ErrorBoundary = ({ dispatchSetApplicationFailure, children }) => (
  <ErrorBoundaryCore
    onError={dispatchSetApplicationFailure}
    renderContent={renderErrorContent}
  >
    {children}
  </ErrorBoundaryCore>
);

export default compose(
  connect(null, {
    dispatchSetApplicationFailure: setApplicationFailure,
  }),
  React.memo,
)(ErrorBoundary);
