import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { getRoleSkillsWithParentsByRole } from "features/competence.actions";
import { getRoleFromState } from "features/competence.selectors";
import RoleSkillList from "features/components/RoleSkillList/RoleSkillList";

class RoleSkillListContainer extends PureComponent {
  render() {
    const { items } = this.props;

    if (!items.size) return null;

    return <RoleSkillList {...this.props} />;
  }
}

export default compose(
  connect(
    (state, { roleId }) => ({
      role: getRoleFromState(state, roleId),
    }),
    {
      dispatchGetRoleSkills: getRoleSkillsWithParentsByRole,
    },
  ),
)(RoleSkillListContainer);
