import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Delete from "@material-ui/icons/Delete";
import React, { useMemo, useEffect } from "react";
import { Tooltip, IconButton } from "@material-ui/core";

import {
  collectionsOnLoad,
  collectionsOnUnload,
  createCollectionOnSubmit,
  deleteCollectionsAskConfirmation,
  getStatsForCollections,
} from "features/competence.actions";

import {
  getCollectionsFromState,
  getCollectionStatsByCollectionIdFromState,
} from "features/competence.selectors";

import CrudBasePage from "app/components/CrudBasePage";
import { COLLECTION_FORM } from "features/competence.constants";
import { ACTIONS, ICONS, EMPTY_MAP, FEATURE_FLAG } from "app/app.constants";
import CollectionForm from "features/hr/collections/components/CollectionForm";

import {
  getSummarizedDataStatesFromState,
  getFeatureFlagStatusFromState,
} from "app/app.selectors";

const CollectionsContainer = ({
  dataState,
  breadcrumb,
  collections,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchDeleteCollection,
  dispatchGetStatsForCollections,
  dispatchCreateCollectionOnSubmit,
  isSkillFeatureFlagEnabled = false,
  collectionStatsByCollectionId = EMPTY_MAP,
}) => {
  useEffect(() => {
    dispatchOnLoad();
    dispatchGetStatsForCollections();

    return () => dispatchOnUnload();
  }, [dispatchOnLoad, dispatchGetStatsForCollections, dispatchOnUnload]);

  const columns = useMemo(
    () => [
      {
        xs: 8,
        title: "Collection Name",
        getSortProperty: item => item.get("name"),
      },
      {
        filter: {
          defaultText: "Any",
          label: "Countries",
          getFilterText: filter => (filter === "" ? "Global" : filter),
        },
        xs: 2,
        title: "Countries",
        getSortProperty: item =>
          item
            .get("countries")
            .map(c => c.get("twoLetterCode"))
            .join(", "),
      },
      {
        xs: 1,
        container: true,
        justify: "center",
        title: (
          <Tooltip title="Course Count">
            <ICONS.COURSE />
          </Tooltip>
        ),
        getSortProperty: item =>
          collectionStatsByCollectionId.getIn(
            [item.get("competencyCollectionId").toString(), "coursesCount"],
            "-",
          ),
      },
      isSkillFeatureFlagEnabled && {
        xs: 1,
        container: true,
        justify: "center",
        title: (
          <Tooltip title="Skill Count">
            <ICONS.SKILL />
          </Tooltip>
        ),
        getSortProperty: item =>
          collectionStatsByCollectionId.getIn(
            [item.get("competencyCollectionId").toString(), "skillsCount"],
            "-",
          ),
      },
    ],
    [isSkillFeatureFlagEnabled, collectionStatsByCollectionId],
  );

  const actions = useMemo(
    () => [
      {
        getValue: item => (
          <Tooltip title="Delete">
            <IconButton onClick={() => dispatchDeleteCollection(item)}>
              <Delete fontSize="small" />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    [dispatchDeleteCollection],
  );
  return (
    <CrudBasePage
      title={breadcrumb}
      items={collections}
      displayAddButton
      displaySearchField
      Icon={ICONS.COLLECTION}
      CreateEntityFormComponent={CollectionForm}
      dataState={dataState}
      SortableListProps={{
        columns: columns,
        actions: actions,
        onRefresh: dispatchOnLoad,
        displayNumberOfItems: true,
        getKey: item => item.get("competencyCollectionId"),
        createSortableListRowProps: item => ({
          component: Link,
          to: `collections/${item.get("competencyCollectionId")}`,
        }),
      }}
      CreateModalProps={{
        submitText: "Add",
        title: "Add collection",
        onSubmit: dispatchCreateCollectionOnSubmit,
      }}
      createEntityForm={{
        form: COLLECTION_FORM.ID,
      }}
    />
  );
};

export default compose(
  connect(
    state => ({
      collections: getCollectionsFromState(state),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_COLLECTIONS,
        ACTIONS.DELETE_COLLECTION,
      ),
      isSkillFeatureFlagEnabled: getFeatureFlagStatusFromState(
        state,
        FEATURE_FLAG.SKILLS,
      ),
      collectionStatsByCollectionId: getCollectionStatsByCollectionIdFromState(
        state,
      ),
    }),
    {
      dispatchOnLoad: collectionsOnLoad,
      dispatchOnUnload: collectionsOnUnload,
      dispatchDeleteCollection: deleteCollectionsAskConfirmation,
      dispatchCreateCollectionOnSubmit: createCollectionOnSubmit,
      dispatchGetStatsForCollections: getStatsForCollections,
    },
  ),
)(CollectionsContainer);
