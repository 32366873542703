import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { getCurrentEmployeeFromState } from "app/app.selectors";
import EmployeeSkillContainer from "features/employee/employeeSkill/EmployeeSkillContainer";

class MyPageEmployeeSkillContainer extends PureComponent {
  render() {
    const { currentEmployeeId, skillId, routes, Icon } = this.props;

    return (
      <EmployeeSkillContainer
        Icon={Icon}
        skillId={skillId}
        employeeId={currentEmployeeId}
        routes={routes}
      />
    );
  }
}

export default compose(
  connect(state => ({
    currentEmployeeId: getCurrentEmployeeFromState(state).get("employeeId"),
  })),
)(MyPageEmployeeSkillContainer);
