import { compose } from "redux";
import { Grid } from "@material-ui/core";
import { Field } from "redux-form/immutable";
import React, { PureComponent } from "react";

import { required } from "utils/validation.util";
import TextField from "app/components/form/TextField";
import { COLLECTION_FORM } from "features/competence.constants";
import CountryMultiselectField from "app/components/form/CountryMultiselectField";

class CollectionForm extends PureComponent {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form id={COLLECTION_FORM.ID} onSubmit={handleSubmit}>
        <Grid container direction="column">
          <Grid container item>
            <Field
              autoFocus
              required
              validate={[required]}
              component={TextField}
              label="Collection Name"
              name={COLLECTION_FORM.NAME}
            />
          </Grid>
          <Grid container item>
            <Field
              multiline
              rows={5}
              label="Description"
              component={TextField}
              name={COLLECTION_FORM.DESCRIPTION}
            />
          </Grid>
          <Grid item container>
            <Field
              name={COLLECTION_FORM.COUNTRIES}
              component={CountryMultiselectField}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default compose()(CollectionForm);
