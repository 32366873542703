import { Map, OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { RoleSkillMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case ACTIONS.CLEAR_ROLE_SKILLS: {
      return initialState;
    }

    case ACTIONS.CREATE_ROLE_SKILLS: {
      if (error) return state;

      return state.set(
        action.roleId.toString(),
        OrderedMap(
          payload.map(roleSkill => [
            roleSkill.roleSkillId.toString(),
            RoleSkillMapper.from(roleSkill),
          ]),
        ),
      );
    }

    case ACTIONS.UPDATE_ROLE_SKILL: {
      if (error) return state;

      return state.setIn(
        [payload.roleId.toString(), payload.roleSkillId.toString()],
        RoleSkillMapper.from(payload),
      );
    }

    case ACTIONS.DELETE_ROLE_SKILL: {
      if (error) return state;

      return state.deleteIn([
        action.roleId.toString(),
        action.roleSkillId.toString(),
      ]);
    }

    case ACTIONS.GET_ROLE_SKILLS_WITH_PARENTS_BY_ROLE: {
      if (error) return state;

      return OrderedMap(
        payload.map(roleSkill => [
          roleSkill.roleSkillId.toString(),
          RoleSkillMapper.from(roleSkill),
        ]),
      ).groupBy(roleSkill => roleSkill.get("roleId").toString());
    }

    default:
      return state;
  }
};
