import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { ICONS } from "app/app.constants";
import { ServiceMapper } from "app/app.mappers";
import { getServiceFromState } from "features/competence.selectors";
import EntityDetailsHeader from "app/components/EntityDetailsHeader";

const ServiceDetailsHeader = ({
  children,
  service = ServiceMapper.initial,
}) => {
  return (
    <EntityDetailsHeader
      Icon={<ICONS.SERVICE />}
      title={service.get("name")}
      subtitle={service.get("description")}
    >
      {children}
    </EntityDetailsHeader>
  );
};

export default compose(
  connect((state, { serviceId }) => ({
    service: getServiceFromState(state, serviceId),
  })),
)(ServiceDetailsHeader);
