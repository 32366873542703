import React from "react";

import {
  ApplicationBase,
  ConfirmationDialog,
  NotificationContainer,
} from "altus-ui-components";

import theme from "layout/themes";
import { APP_NAME } from "app/app.constants";
import ApplicationContainer from "app/components/ApplicationContainer";
import ApplicationEnvironmentTopbar from "app/components/ApplicationEnvironmentTopbar";

const ApplicationRoot = () => (
  <ApplicationBase appName={APP_NAME} theme={theme}>
    <ApplicationEnvironmentTopbar />
    <ApplicationContainer />
    <ConfirmationDialog />
    <NotificationContainer />
  </ApplicationBase>
);

export default ApplicationRoot;
