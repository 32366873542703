import { compose } from "redux";
import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";

import { useMultiselect } from "altus-hooks";

import {
  Grid,
  Button,
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemIcon,
  Tooltip,
} from "@material-ui/core";

import ModalCheckboxSelect from "app/components/Modal/ModalCheckboxSelect";

const EmployeeExperienceRecordReportExportModal = ({
  open,
  handleClose,
  handleGenerate,
  classes,
  availableExperienceRecordCategories,
}) => {
  const {
    selectedItems: selectedCategories,
    isSelected,
    toggleSelect: onToggleSelect,
  } = useMultiselect(availableExperienceRecordCategories);

  const [showDeployments, setShowDeployments] = useState(true);

  var canGenerate = selectedCategories.size || showDeployments;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle classes={{ root: classes.dialogTitle }}>
        <Grid container justifyContent="center">
          <Typography variant="h6" className={classes.title}>
            Export Experience Report
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container item xs spacing={2} justifyContent="center">
          {availableExperienceRecordCategories?.size && (
            <Grid container item justifyContent="flex-start">
              <Grid container>
                <Typography
                  variant="subtitle1"
                  className={classes.selectionTitle}
                >
                  Include:
                </Typography>
              </Grid>
              <Grid item container>
                <ListItem
                  dense
                  button
                  key={"deploymentCb"}
                  onClick={() => setShowDeployments(!showDeployments)}
                >
                  <ListItemIcon>
                    <Checkbox
                      color="primary"
                      edge="start"
                      disableRipple
                      checked={showDeployments}
                    />
                  </ListItemIcon>
                  <ListItemText primary={"Deployments"} />
                </ListItem>
                <ModalCheckboxSelect
                  getKey={category => category.experienceCategoryId}
                  getTitle={category => category.itemsTitle}
                  isSelected={isSelected}
                  availableItems={availableExperienceRecordCategories}
                  onToggleSelect={onToggleSelect}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container item xs justifyContent="flex-end">
          <Button
            onClick={handleClose}
            size="large"
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
          <Grid item>
            <Tooltip
              title={
                !canGenerate
                  ? "At least one option must be selected in order to generate report"
                  : ""
              }
            >
              <Grid>
                <Button
                  onClick={() =>
                    handleGenerate(selectedCategories, showDeployments)
                  }
                  size="large"
                  color="primary"
                  variant="contained"
                  className={classes.dialogActionButton}
                  disabled={!canGenerate}
                >
                  Generate
                </Button>
              </Grid>
            </Tooltip>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const styles = theme => ({
  dialogPaper: {
    height: "100%",
    maxHeight: 580,
    minWidth: 580,
    paddingBottom: theme.spacing(2),
  },
  dialogTitle: { backgroundColor: "black", color: "white" },
  selectionTitle: { fontWeight: theme.typography.fontWeightMedium },
  dialogActionButton: {
    marginLeft: theme.spacing(2),
  },
});

export default compose(withStyles(styles))(
  EmployeeExperienceRecordReportExportModal,
);
