import Agent from "infrastructure/agent";

const getServices = () => Agent.get(`/service`);

const getService = serviceId => Agent.get(`/service/${serviceId}`);

const getServiceWithParents = serviceId =>
  Agent.get(`/service/${serviceId}/withparents`);

const createService = service => Agent.post(`/service`, service);

const updateService = (serviceId, service) =>
  Agent.put(`/service/${serviceId}`, service);

const deleteService = serviceId => Agent.delete(`/service/${serviceId}`);

const getAvailableCoursesForService = serviceId =>
  Agent.get(`/service/${serviceId}/course/availablecourses`);

const getServiceCoursesByServiceWithParents = serviceId =>
  Agent.get(`/service/${serviceId}/course/withparents`);

const createServiceCourses = (serviceId, courseIdList) =>
  Agent.post(`/service/${serviceId}/course/assign-courses`, courseIdList);

const updateServiceCourse = (serviceId, courseId, serviceCourse) =>
  Agent.put(`/service/${serviceId}/course/${courseId}`, serviceCourse);

const deleteServiceCourse = (serviceId, courseId) =>
  Agent.delete(`/service/${serviceId}/course/${courseId}`);

const getAvailableSkillsForService = serviceId =>
  Agent.get(`/service/${serviceId}/skill/availableskills`);

const getServiceSkillsByServiceWithParents = serviceId =>
  Agent.get(`/service/${serviceId}/skill/withparents`);

const createServiceSkills = (serviceId, serviceIdList) =>
  Agent.post(`/service/${serviceId}/skill/assign-skills`, serviceIdList);

const updateServiceSkill = (serviceId, skillId, serviceSkill) =>
  Agent.put(`/service/${serviceId}/skill/${skillId}`, serviceSkill);

const deleteServiceSkill = (serviceId, skillId) =>
  Agent.delete(`/service/${serviceId}/skill/${skillId}`);

export default {
  getServices,
  getService,
  getServiceWithParents,
  createService,
  updateService,
  deleteService,

  getAvailableCoursesForService,
  getServiceCoursesByServiceWithParents,
  createServiceCourses,
  updateServiceCourse,
  deleteServiceCourse,

  getAvailableSkillsForService,
  getServiceSkillsByServiceWithParents,
  updateServiceSkill,
  deleteServiceSkill,
  createServiceSkills,
};
