import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Grid, Typography, withStyles } from "@material-ui/core";

import {
  getCourseFromState,
  getPrerequisiteCoursesFromState,
} from "features/competence.selectors";

import { ACTIONS } from "app/app.constants";
import { CourseMapper } from "app/app.mappers";
import CourseDetails from "features/components/CourseDetails";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import CustomCourseInfo from "features/components/CustomCourseInfo";
import { BasePage } from "altus-ui-components";

class EmployeeCourseDetailsContainer extends PureComponent {
  render() {
    const {
      course,
      classes,
      dataState,
      breadcrumb,
      prerequisiteCourses,
    } = this.props;

    return (
      <BasePage title={breadcrumb} dataState={dataState}>
        <Grid container item spacing={4}>
          <Grid container item xs={12} md={8}>
            <CourseDetails course={course}></CourseDetails>
          </Grid>
          {course.get("isCustom") && (
            <>
              <Grid container item xs={12} md={4}>
                <CustomCourseInfo />
              </Grid>
              <Grid container item xs={12} md={8}>
                <Typography variant="h5">Prerequisites</Typography>
              </Grid>
              {prerequisiteCourses.map(prereq => (
                <Grid item xs={12} md={8} key={prereq.get("courseId")}>
                  <div className={classes.prerequisiteCourse}>
                    <CourseDetails course={prereq} />
                  </div>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </BasePage>
    );
  }
}

EmployeeCourseDetailsContainer.defaultProps = {
  course: CourseMapper.initial,
};

const styles = theme => ({
  prerequisiteCourse: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(4),
    position: "relative",
  },
});

export default compose(
  connect((state, { courseId }) => ({
    dataState: getSummarizedDataStatesFromState(
      state,
      ACTIONS.GET_COURSE,
      ACTIONS.GET_PREREQUISITE_COURSES,
    ),
    course: getCourseFromState(state, courseId),
    prerequisiteCourses: getPrerequisiteCoursesFromState(state, courseId),
  })),
  withStyles(styles),
)(EmployeeCourseDetailsContainer);
