import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";

import {
  getEmployeeCoursesByCourse,
  previewOrSaveEmployeeCourseAttendanceFile,
} from "features/competence.actions";

import routePaths from "app/routePaths";
import { formatDate } from "utils/format.util";
import { ACTIONS, Format } from "app/app.constants";
import CrudBasePage from "app/components/CrudBasePage";
import { getActionDataStateFromState } from "app/app.selectors";
import DownloadFileColumn from "app/components/DownloadFileColumn";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import { resolveEmployeeCourseFile } from "utils/app.util";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";
import { getEmployeeCoursesByCourseFromState } from "features/competence.selectors";
import EmployeeAvatar from "app/components/EmployeeAvatar";

class CourseEmployeesContainer extends PureComponent {
  componentDidMount() {
    const { dispatchGetEmployeesByCourse, courseId } = this.props;

    dispatchGetEmployeesByCourse(courseId);
  }

  columns = [
    {
      xs: 1,
      title: "#",
      getSortProperty: item => item.get("employeeNumber"),
    },
    {
      xs: 4,
      title: "Name",
      getSortProperty: item => item.get("employeeDisplayName"),
    },
    {
      xs: true,
      title: "Completed",
      getSortProperty: item => formatDate(item.get("endDate"), Format.date),
    },
    {
      xs: true,
      title: "Expires",
      getSortProperty: item =>
        formatDate(item.get("expirationDate"), Format.date),
    },
    {
      xs: true,
      component: Grid,
      title: "Certificate",
      getSortProperty: item => {
        const file = resolveEmployeeCourseFile(item);

        return file ? file.name : "";
      },
      getValue: item => {
        const file = resolveEmployeeCourseFile(item);

        return (
          <DownloadFileColumn
            file={file}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();

              this.props.dispatchPreviewOrSaveEmployeeCourseAttendanceFile(
                item.get("employeeId"),
                item.get("courseId"),
                item.get("bestAttendanceId"),
                file,
              );
            }}
          />
        );
      },
    },
    {
      xs: true,
      title: "",
      component: Grid,
      container: true,
      justify: "flex-end",
      getSortProperty: item => item.get("competencyStatus"),
      getValue: item => (
        <CompetencyStatusChip status={item.get("competencyStatus")} />
      ),
      filter: {
        label: "Status",
        defaultText: "All",
        getFilterText: filter => statusToTooltipText(filter),
      },
    },
  ];

  getKey = employee => employee.get("employeeId");

  renderIcon = employee => (
    <EmployeeAvatar employeeDisplayName={employee.get("employeeDisplayName")} />
  );

  render() {
    const { breadcrumb, dataState, employees } = this.props;

    return (
      <CrudBasePage
        Icon={null}
        items={employees}
        title={breadcrumb}
        dataState={dataState}
        displayAddButton={false}
        SortableListProps={{
          getKey: this.getKey,
          columns: this.columns,
          renderIcon: this.renderIcon,
          createSortableListRowProps: item => ({
            component: Link,
            to: `${routePaths.hrPortal}/employees/${item.get(
              "employeeId",
            )}/courses/${item.get("courseId")}`,
          }),
        }}
      />
    );
  }
}

export default compose(
  connect(
    state => ({
      employees: getEmployeeCoursesByCourseFromState(state),
      dataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_EMPLOYEES_BY_COURSE,
      ),
    }),
    {
      dispatchGetEmployeesByCourse: getEmployeeCoursesByCourse,
      dispatchPreviewOrSaveEmployeeCourseAttendanceFile: previewOrSaveEmployeeCourseAttendanceFile,
    },
  ),
)(CourseEmployeesContainer);
