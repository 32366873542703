import { compose } from "redux";
import isEqual from "lodash/isEqual";
import { connect } from "react-redux";
import isString from "lodash/isString";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core";
import GetApp from "@material-ui/icons/GetApp";
import { Grid, Button } from "@material-ui/core";
import { isImmutable, fromJS, List } from "immutable";
import React, { useState, useEffect, useCallback } from "react";

import { ReportHeaderIconButton } from "altus-ui-components";

import {
  getDepartmentsFromState,
  getEmployeesByEmployeeNumberFromState,
  getGapReportOptionsFromState,
} from "features/competence.selectors";

import { COMPETENCY_STATUS } from "app/app.constants";
import { parseQueryString, invokeIfFunction } from "utils/app.util";
import { updateGapReportOptions } from "features/competence.actions";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";
import GapReportModalItemSelector from "features/components/GapReport/GapReportModalItemSelector";
import GapReportAutocompleteFilter from "features/components/GapReport/GapReportAutocompleteFilter";
import { AdditionalReportOptions } from "features/components/GapReport/GapReportEmployeesHeaderOptions";

export const BASE_FILTERS = {
  STATUS: "statuses",
  EMPLOYEES: "employees",
  DEPARTMENTS: "departments",
};

const queryStringParse = (search, initialFilter) => {
  return fromJS(parseQueryString(search)).map((value, key) => {
    const initialValue = initialFilter.get(key);

    if (isImmutable(initialValue)) {
      return isString(value) ? initialValue.push(value) : value;
    }

    return value;
  });
};

const GapReportFilterBase = ({
  classes,
  location,
  departments,
  getGapReport,
  renderFilter,
  initialFilter,
  additionalOptions,
  clearGapReport,
  downloadGapReport,
  downloadBtnDisabled,
  employeesByEmployeeNumber,
  dispatchUpdateGapReportOptions,
}) => {
  const [filter, setFilter] = useState(
    initialFilter.merge(queryStringParse(location.search, initialFilter)),
  );

  useEffect(() => {
    Object.values(AdditionalReportOptions).forEach(option =>
      dispatchUpdateGapReportOptions({
        key: option,
        value: filter.get(option),
      }),
    );

    getGapReport(filter);
  }, [filter, getGapReport, dispatchUpdateGapReportOptions]);

  const searchButtonOnClick = useCallback(
    event => {
      event.preventDefault();

      getGapReport(filter.merge(additionalOptions));
    },
    [getGapReport, filter, additionalOptions],
  );

  const setFilterState = useCallback(
    (key, value) =>
      setFilter(filter =>
        value ? filter.set(key, value) : filter.delete(key),
      ),
    [],
  );

  const clearButtonOnClick = useCallback(() => {
    setFilter(initialFilter);
    clearGapReport();
  }, [initialFilter, clearGapReport]);

  const renderDepartmentFilter = useCallback(
    () =>
      filter.has(BASE_FILTERS.DEPARTMENTS) && (
        <Grid item xs>
          <GapReportAutocompleteFilter
            label="Departments"
            options={departments}
            getOptionLabel={department => department.get("name")}
            value={filter
              .get(BASE_FILTERS.DEPARTMENTS)
              .map(departmentId => departments.get(departmentId.toString()))}
            onChange={(_, value) =>
              setFilterState(
                BASE_FILTERS.DEPARTMENTS,
                List(value.map(department => department.get("departmentId"))),
              )
            }
          />
        </Grid>
      ),
    [filter, setFilterState, departments],
  );

  const renderEmployeeFilter = useCallback(
    () =>
      filter.has(BASE_FILTERS.EMPLOYEES) && (
        <Grid item xs>
          <GapReportModalItemSelector
            label="Employees"
            options={employeesByEmployeeNumber}
            getOptionLabel={employee => employee.get("displayName")}
            onSelectionChange={value =>
              setFilterState(
                BASE_FILTERS.EMPLOYEES,
                List(value.map(employee => employee.get("employeeNumber"))),
              )
            }
            value={filter
              .get(BASE_FILTERS.EMPLOYEES)
              .map(employeeNumber =>
                employeesByEmployeeNumber.get(employeeNumber.toString()),
              )
              .toSet()}
          />
        </Grid>
      ),
    [filter, setFilterState, employeesByEmployeeNumber],
  );

  const renderStatusFilter = useCallback(
    () =>
      filter.has(BASE_FILTERS.STATUS) && (
        <Grid item xs>
          <GapReportAutocompleteFilter
            label="Status"
            displayOptionsCount={false}
            getOptionLabel={item => statusToTooltipText(item)}
            onChange={(_, value) =>
              setFilterState(BASE_FILTERS.STATUS, List(value))
            }
            value={filter
              .get(BASE_FILTERS.STATUS)
              .map(status => status.toString())}
            options={List(Object.values(COMPETENCY_STATUS))
              .rest() // Exclude None option
              .map(status => status.toString())}
          />
        </Grid>
      ),
    [filter, setFilterState],
  );

  return (
    <Grid
      container
      spacing={3}
      component="form"
      justifyContent="flex-end"
      alignItems="center"
      id="course-gap-report"
      className={classes.root}
    >
      {invokeIfFunction(renderFilter, {
        filter: filter,
        setFilter: setFilterState,
        renderStatusFilter: renderStatusFilter,
        renderEmployeeFilter: renderEmployeeFilter,
        renderDepartmentFilter: renderDepartmentFilter,
      })}
      <Grid item>
        <Button
          variant="contained"
          onClick={clearButtonOnClick}
          className={classes.resetFilterButton}
          disabled={isEqual(filter, initialFilter)}
          classes={{
            disabled: classes.buttonDisabled,
          }}
        >
          Reset
        </Button>
      </Grid>
      <Grid item>
        <Button
          type="submit"
          color="default"
          variant="contained"
          onClick={searchButtonOnClick}
        >
          Search
        </Button>
      </Grid>
      {downloadGapReport && (
        <Grid item>
          <ReportHeaderIconButton
            Icon={GetApp}
            title="Download"
            onClick={downloadGapReport}
            disabled={downloadBtnDisabled}
          />
        </Grid>
      )}
    </Grid>
  );
};

const styles = theme => ({
  root: {
    color: theme.palette.background.default,
  },
  resetFilterButton: {
    background: theme.altus.color.accent,
    color: theme.palette.getContrastText(theme.altus.color.accent),
    "&:hover": {
      background: theme.altus.color.accent,
    },
  },
  buttonDisabled: {
    "&.Mui-disabled": {
      backgroundColor: theme.palette.grey[300],
    },
  },
});

export default compose(
  connect(
    state => ({
      departments: getDepartmentsFromState(state),
      employeesByEmployeeNumber: getEmployeesByEmployeeNumberFromState(state),
      additionalOptions: getGapReportOptionsFromState(state),
    }),
    {
      dispatchUpdateGapReportOptions: updateGapReportOptions,
    },
  ),
  withRouter,
  withStyles(styles),
)(GapReportFilterBase);
