import { compose } from "redux";
import { connect } from "react-redux";
import { PureComponent } from "react";

import { getEmployeeFromState } from "features/competence.selectors";

class EmployeeBreadcrumb extends PureComponent {
  render() {
    const { employee } = this.props;

    return employee ? employee.get("displayName") : "...";
  }
}

export default compose(
  connect((state, { match }) => ({
    employee: getEmployeeFromState(state, match.params.employeeId),
  })),
)(EmployeeBreadcrumb);
