import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { RouteContainer, NavTabsContainer } from "altus-ui-components";

import {
  specificationOnLoad,
  specificationOnUnload,
} from "features/competence.actions";

import { ROUTE_KEYS } from "app/routes";
import { EMPTY_MAP } from "app/app.constants";
import { setRouteCount } from "utils/route.util";
import { getSpecificationStatsFromState } from "features/competence.selectors";
import SpecificationDetailsHeader from "features/specification/components/SpecificationDetailsHeader";

class SpecificationContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad, specificationId } = this.props;

    dispatchOnLoad(specificationId);
  }

  componentWillUnmount() {
    const { dispatchOnUnload, specificationId } = this.props;

    dispatchOnUnload(specificationId);
  }

  getSpecificationRouteCount = key => {
    const { specificationStats } = this.props;
    switch (key) {
      case ROUTE_KEYS.SPECIFICATION_SKILL:
        return specificationStats.get("specificationSkillCount");
      case ROUTE_KEYS.SPECIFICATION_COURSE:
        return specificationStats.get("specificationCourseCount");
      default:
        return null;
    }
  };

  render() {
    const { routes, specificationId } = this.props;

    return (
      <>
        <SpecificationDetailsHeader specificationId={specificationId} />
        <NavTabsContainer
          routes={routes.map(route =>
            setRouteCount(route, this.getSpecificationRouteCount(route.key)),
          )}
        />
        <RouteContainer routes={routes} />
      </>
    );
  }
}

SpecificationContainer.defaultProps = {
  specificationStats: EMPTY_MAP,
};

export default compose(
  connect(
    (state, { specificationId }) => ({
      specificationStats: getSpecificationStatsFromState(
        state,
        specificationId,
      ),
    }),
    {
      dispatchOnLoad: specificationOnLoad,
      dispatchOnUnload: specificationOnUnload,
    },
  ),
)(SpecificationContainer);
