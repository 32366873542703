import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { ReceivedDataState } from "altus-datastate";

import { ACTIONS } from "app/app.constants";
import { clearRoleGapReport } from "features/competence.actions";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import RoleGapReport from "features/components/RoleGapReport/RoleGapReport";
import GapReportBasePage from "features/components/GapReport/GapReportBasePage";
import RoleDetailsModal from "features/components/RoleGapReport/RoleDetailsModal";
import SkillDetailsModal from "features/components/SkillGapReport/SkillDetailsModal";
import CourseDetailsModal from "features/components/RoleGapReport/CourseDetailsModal";
import RoleGapReportFilter from "features/components/RoleGapReport/RoleGapReportFilter";
import { getGapReportEmployeesByDepartmentFromState } from "features/competence.selectors";

class RoleGapReportBasePage extends PureComponent {
  componentWillUnmount() {
    const { dispatchClearRoleGapReport } = this.props;

    dispatchClearRoleGapReport();
  }

  renderFilter = () => {
    const {
      getRoleGapReport,
      downloadRoleGapReport,
      dispatchClearRoleGapReport,
    } = this.props;

    return (
      <RoleGapReportFilter
        getGapReport={getRoleGapReport}
        clearGapReport={dispatchClearRoleGapReport}
        downloadRoleGapReport={downloadRoleGapReport}
      />
    );
  };

  render() {
    const { root, title, dataState, employeesByDepartment } = this.props;

    return (
      <GapReportBasePage
        title={title}
        dataState={dataState}
        renderFilter={this.renderFilter}
        employeesByDepartment={employeesByDepartment}
      >
        {!!employeesByDepartment.size && (
          <RoleGapReport
            root={root}
            title={title}
            employeesByDepartment={employeesByDepartment}
          />
        )}
        <CourseDetailsModal />
        <SkillDetailsModal />
        <RoleDetailsModal />
      </GapReportBasePage>
    );
  }
}

RoleGapReportBasePage.defaultProps = {
  dataState: ReceivedDataState,
};

export default compose(
  connect(
    state => ({
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_ROLE_GAP_REPORT,
        ACTIONS.DOWNLOAD_ROLE_GAP_REPORT,
      ),
      employeesByDepartment: getGapReportEmployeesByDepartmentFromState(state),
    }),
    {
      dispatchClearRoleGapReport: clearRoleGapReport,
    },
  ),
)(RoleGapReportBasePage);
