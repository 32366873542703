import { compose } from "redux";
import memoize from "lodash/memoize";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { PureComponent } from "react";
import { Grid, Tooltip } from "@material-ui/core";

import {
  roleEmployeesOnUnload,
  getMyTeamRoleEmployeesByRole,
  getStatsForMyTeamRoleMembers,
} from "features/competence.actions";

import {
  getRoleFromState,
  getEmployeeFromState,
  getRoleEmployeesFromState,
  getRoleStatsByEmployeeAndRoleIdFromState,
} from "features/competence.selectors";

import routePaths from "app/routePaths";
import CrudBasePage from "app/components/CrudBasePage";
import EmployeeAvatar from "app/components/EmployeeAvatar";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import { SORT_DIRECTION, ACTIONS, EMPTY_MAP, ICONS } from "app/app.constants";
import EmployeeRoleProgressBar from "features/components/EmployeeRoleProgressBar";

class MyTeamRoleMembersContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.createColumnsMemoized = memoize(this.createColumns);
  }

  componentDidMount() {
    const {
      roleId,
      dispatchGetMyTeamRoleMembers,
      dispatchGetRoleStatsForMyTeamRoleMembers,
    } = this.props;

    dispatchGetMyTeamRoleMembers(roleId);
    dispatchGetRoleStatsForMyTeamRoleMembers(roleId);
  }

  componentWillUnmount() {
    const { dispatchOnUnload } = this.props;
    dispatchOnUnload();
  }

  createColumns = roleStatsByEmployeeAndRoleId => [
    {
      xs: 1,
      title: "#",
      getSortProperty: item => item.getIn(["employee", "employeeNumber"]),
    },
    {
      xs: true,
      title: "First Name",
      getSortProperty: item => item.getIn(["employee", "firstName"]),
    },
    {
      xs: true,
      title: "Last Name",
      getSortProperty: item => item.getIn(["employee", "lastName"]),
    },
    {
      xs: true,
      filter: {
        defaultText: "All positions",
      },
      title: "Position",
      getSortProperty: item => item.getIn(["employee", "position"]),
    },
    {
      xs: true,
      filter: {
        defaultText: "All departments",
      },
      title: "Department",
      getSortProperty: item => item.getIn(["employee", "departmentName"]),
    },
    {
      xs: 1,
      container: true,
      justify: "center",
      title: (
        <Tooltip title="Remaining Skill Count">
          <ICONS.SKILL />
        </Tooltip>
      ),
      getSortProperty: item => {
        const roleStats = roleStatsByEmployeeAndRoleId.getIn([
          item.getIn(["employee", "employeeId"]).toString(),
          this.props.roleId.toString(),
        ]);

        return roleStats
          ? roleStats.get("requiredSkillsCount") -
              roleStats.get("activeEmployeeSkillCount")
          : "-";
      },
    },
    {
      xs: 1,
      container: true,
      justify: "center",
      title: (
        <Tooltip title="Remaining Course Count">
          <ICONS.COURSE />
        </Tooltip>
      ),
      getSortProperty: item => {
        const roleStats = roleStatsByEmployeeAndRoleId.getIn([
          item.getIn(["employee", "employeeId"]).toString(),
          this.props.roleId.toString(),
        ]);

        return roleStats
          ? roleStats.get("requiredCoursesCount") -
              roleStats.get("activeEmployeeCourseCount")
          : "-";
      },
    },
    {
      xs: true,
      component: Grid,
      getSortProperty: item => (
        <EmployeeRoleProgressBar
          roleId={this.props.roleId}
          employeeId={item.getIn(["employee", "employeeId"])}
        />
      ),
    },
  ];

  renderIcon = roleEmployee => (
    <EmployeeAvatar
      employeeDisplayName={roleEmployee.getIn(["employee", "displayName"])}
    />
  );

  render() {
    const {
      dataState,
      breadcrumb,
      roleEmployees,
      roleStatsByEmployeeAndRoleId,
    } = this.props;

    const columns = this.createColumnsMemoized(roleStatsByEmployeeAndRoleId);

    return (
      <CrudBasePage
        Icon={null}
        title={breadcrumb}
        displaySearchField
        items={roleEmployees}
        dataState={dataState}
        SortableListProps={{
          actions: this.actions,
          columns: columns,
          renderIcon: this.renderIcon,
          getKey: item => item.get("employeeId"),
          defaultSortDirection: SORT_DIRECTION.ASC,
          defaultSortColumn: columns.findIndex(
            item => item.title === "Last Name",
          ),
          createSortableListRowProps: item => ({
            component: Link,
            to: `${routePaths.myTeam}/employees/${item.get("employeeId")}`,
          }),
        }}
      />
    );
  }
}

MyTeamRoleMembersContainer.defaultProps = {
  roleStatsByEmployeeAndRoleId: EMPTY_MAP,
};

export default compose(
  connect(
    (state, { roleId }) => ({
      role: getRoleFromState(state, roleId),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_ROLE_EMPLOYEES,
      ),
      roleStatsByEmployeeAndRoleId: getRoleStatsByEmployeeAndRoleIdFromState(
        state,
      ),
      // set employee on each roleEmployee, so that we do not have to pass selectors as props
      roleEmployees: getRoleEmployeesFromState(
        state,
        roleId,
      ).map(roleEmployee =>
        roleEmployee.set(
          "employee",
          getEmployeeFromState(state, roleEmployee.get("employeeId")),
        ),
      ),
    }),
    {
      dispatchOnUnload: roleEmployeesOnUnload,
      dispatchGetMyTeamRoleMembers: getMyTeamRoleEmployeesByRole,
      dispatchGetRoleStatsForMyTeamRoleMembers: getStatsForMyTeamRoleMembers,
    },
  ),
)(MyTeamRoleMembersContainer);
