import React from "react";
import { compose } from "redux";
import { Grid, Button } from "@material-ui/core";

import { LoadingButton } from "altus-ui-components";

import { EMPLOYEE_SKILL_FORM } from "features/competence.constants";

const EmployeeSkillModalActions = ({
  tabs,
  valid,
  change,
  activeTab,
  submitText,
  submitting,
  handleSubmit,
  selectedSkillId,
}) => {
  if (selectedSkillId) {
    return (
      <Grid container justify="space-between">
        <Grid item>
          {activeTab > 0 && (
            <Button
              onClick={() =>
                change(EMPLOYEE_SKILL_FORM.ACTIVE_TAB, activeTab - 1)
              }
            >
              Previous
            </Button>
          )}
        </Grid>
        {activeTab < tabs.length - 1 && (
          <Grid item>
            <Button
              onClick={() =>
                change(EMPLOYEE_SKILL_FORM.ACTIVE_TAB, activeTab + 1)
              }
            >
              Next
            </Button>
          </Grid>
        )}
        {activeTab === tabs.length - 1 && (
          <Grid item>
            <LoadingButton
              type="submit"
              color="primary"
              disabled={!valid || submitting}
              loading={submitting}
              variant="contained"
              onClick={handleSubmit}
            >
              {submitText}
            </LoadingButton>
          </Grid>
        )}
      </Grid>
    );
  }

  return null;
};

export default compose()(EmployeeSkillModalActions);
