import { compose } from "redux";
import { connect } from "react-redux";
import { PureComponent } from "react";
import { getCourseFromState } from "features/competence.selectors";

class CourseBreadcrumb extends PureComponent {
  render() {
    const { course } = this.props;

    return course ? course.get("name") : "...";
  }
}

export default compose(
  connect((state, { match }) => ({
    course: getCourseFromState(state, match.params.courseId),
  })),
)(CourseBreadcrumb);
