import { compose } from "redux";
import React, { PureComponent } from "react";

import EmployeeRoleContainer from "features/employeeRole/EmployeeRoleContainer";

class EmployeeEmployeeRoleContainer extends PureComponent {
  render() {
    const { roleId, employeeId, routes } = this.props;

    return (
      <EmployeeRoleContainer
        roleId={roleId}
        routes={routes}
        employeeId={employeeId}
      />
    );
  }
}

export default compose()(EmployeeEmployeeRoleContainer);
