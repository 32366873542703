import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { toggleModal } from "altus-modal";

export default WrappedComponent => {
  class Component extends PureComponent {
    render() {
      const {
        toggleModal,
        dispatchToggleModal,
        ...passthroughProps
      } = this.props;

      return (
        <WrappedComponent
          dispatchToggleModal={dispatchToggleModal}
          {...passthroughProps}
        />
      );
    }
  }

  return connect(null, {
    dispatchToggleModal: toggleModal,
  })(Component);
};
