import { compose } from "redux";
import { connect } from "react-redux";
import React, { useEffect, useMemo } from "react";

import { TILE_CHIP_TYPES, OverviewContainer } from "altus-ui-components";

import { ROUTE_KEYS } from "app/routes";
import { getHrStats } from "features/competence.actions";
import { getHrStatsFromState } from "features/competence.selectors";

const getChipType = (route, stats) => {
  switch (route.key) {
    case ROUTE_KEYS.APPROVALS:
      return stats.get(route.key) > 0
        ? TILE_CHIP_TYPES.NOTIFICATION
        : TILE_CHIP_TYPES.VISIBLE;
    default:
      break;
  }

  return route.key ? TILE_CHIP_TYPES.VISIBLE : TILE_CHIP_TYPES.HIDDEN;
};

const HROverviewContainer = ({
  Icon,
  match,
  hrStats,
  parentRoutes,
  parentBreadcrumb,
  dispatchGetHrStats,
}) => {
  useEffect(() => {
    dispatchGetHrStats();
  }, [dispatchGetHrStats]);

  const routesWithLabel = useMemo(
    () =>
      parentRoutes.map(route => ({
        ...route,
        chipType: getChipType(route, hrStats),
        chipLabel: hrStats.get(route.key),
      })),
    [parentRoutes, hrStats],
  );

  return (
    <OverviewContainer
      Icon={Icon}
      match={match}
      parentRoutes={routesWithLabel}
      parentBreadcrumb={parentBreadcrumb}
    />
  );
};

export default compose(
  connect(
    state => ({
      hrStats: getHrStatsFromState(state),
    }),
    {
      dispatchGetHrStats: getHrStats,
    },
  ),
  React.memo,
)(HROverviewContainer);
