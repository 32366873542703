import { Map, OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { EmployeeMapper, RoleMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case ACTIONS.CLEAR_EMPLOYEES: {
      return initialState;
    }

    case ACTIONS.GET_EMPLOYEES: {
      if (error) return state;

      return Map(
        payload.map(employee => [
          employee.employeeId.toString(),
          EmployeeMapper.from(employee),
        ]),
      );
    }

    case ACTIONS.GET_MY_TEAM_EMPLOYEES_BY_USER: {
      if (error) return state;

      return state.merge(
        payload.map(employee => [
          employee.employeeId.toString(),
          EmployeeMapper.from(employee),
        ]),
      );
    }

    case ACTIONS.GET_ROLE_EMPLOYEES:
    case ACTIONS.GET_MY_TEAM_ROLE_EMPLOYEES_BY_ROLE: {
      if (error) return state;

      return Map(
        payload.map(roleEmployee => [
          roleEmployee.employeeId.toString(),
          EmployeeMapper.from(roleEmployee.employee),
        ]),
      );
    }

    case ACTIONS.GET_EMPLOYEE: {
      if (error) return state;

      return state.set(
        payload.employeeId.toString(),
        EmployeeMapper.from(payload),
      );
    }

    case ACTIONS.GET_CURRENT_EMPLOYEE: {
      if (error) return state;

      return state.set(
        payload.employeeId.toString(),
        EmployeeMapper.from(payload),
      );
    }

    case ACTIONS.GET_AVAILABLE_ROLES_FOR_EMPLOYEE: {
      if (error) return state;

      return state.setIn(
        [action.employeeId.toString(), "availableRoles"],
        OrderedMap(
          payload.map(role => [role.roleId.toString(), RoleMapper.from(role)]),
        ),
      );
    }

    default:
      return state;
  }
};
