import { Map, OrderedMap, List } from "immutable";

import { ACTIONS, EMPTY_MAP } from "app/app.constants";

import {
  EmployeeSkillMapper,
  ServiceMapper,
  ServiceSkillMapper,
  ServiceCourseMapper,
} from "app/app.mappers";

import employeeCourseMappers from "app/mappers/employeeCourse.mappers";

const initialState = Map({
  employeeCourse: EMPTY_MAP,
  employeeSkill: EMPTY_MAP,
  service: EMPTY_MAP,
  serviceCourse: EMPTY_MAP,
  serviceSkill: EMPTY_MAP,
  employeeServiceStatus: EMPTY_MAP,
});

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_SERVICE_GAP_REPORT: {
      return initialState;
    }

    case ACTIONS.GET_SERVICE_GAP_REPORT: {
      if (error) return state;

      return state
        .set(
          "employeeCourse",
          List(payload.employeeCourses)
            .map(employeeCourseMappers.EmployeeCourse.from)
            .groupBy(employeeCourse =>
              employeeCourse.get("employeeId").toString(),
            )
            .map(employeeCoursesByEmployeeId =>
              Map(
                employeeCoursesByEmployeeId.map(employeeCourse => [
                  employeeCourse.get("courseId").toString(),
                  employeeCourse,
                ]),
              ),
            ),
        )
        .set(
          "employeeSkill",
          List(payload.employeeSkills)
            .map(EmployeeSkillMapper.from)
            .groupBy(employeeSkill =>
              employeeSkill.get("employeeId").toString(),
            )
            .map(employeeSkillsByEmployeeId =>
              Map(
                employeeSkillsByEmployeeId.map(employeeSkill => [
                  employeeSkill.get("skillId").toString(),
                  employeeSkill,
                ]),
              ),
            ),
        )
        .set(
          "service",
          OrderedMap(
            payload.services.map(service => [
              service.serviceId.toString(),
              ServiceMapper.from(service),
            ]),
          ),
        )
        .set(
          "serviceCourse",
          List(payload.serviceCourses)
            .map(ServiceCourseMapper.from)
            .groupBy(serviceCourse =>
              serviceCourse.get("serviceId").toString(),
            ),
        )
        .set(
          "serviceSkill",
          List(payload.serviceSkills)
            .map(ServiceSkillMapper.from)
            .groupBy(serviceSkill => serviceSkill.get("serviceId").toString()),
        )
        .set(
          "employeeServiceStatus",
          List(payload.employeeServiceStatuses)
            .map(employeeServiceStatus => Map(employeeServiceStatus))
            .groupBy(employeeServiceStatus =>
              employeeServiceStatus.get("employeeId").toString(),
            )
            .map(employeeServiceStatusesByEmployeeId =>
              Map(
                employeeServiceStatusesByEmployeeId.map(
                  employeeServiceStatus => [
                    employeeServiceStatus.get("serviceId").toString(),
                    employeeServiceStatus,
                  ],
                ),
              ),
            ),
        );
    }
    default:
      return state;
  }
};
