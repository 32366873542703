import { compose } from "redux";
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import React, { PureComponent } from "react";
import { Typography, Grid, Button, withStyles } from "@material-ui/core";

import { IncrementDecrementButton } from "altus-ui-components";

import routePaths from "app/routePaths";
import { isAdminOrHR } from "utils/app.util";
import { getCurrentUserFromState } from "app/app.selectors";
import SortableListRow from "app/components/SortableList/SortableListRow";

class CollectionConditionMatrixHeader extends PureComponent {
  render() {
    const {
      rows,
      title,
      classes,
      columns,
      onReset,
      disabled,
      currentUser,
      onRowsChange,
      collectionId,
      onColumnsChange,
      resetBtnDisabled,
    } = this.props;

    const to = generatePath(
      `${routePaths.hrPortal}/collections/:collectionId`,
      { collectionId },
    );

    const currentUserIsAdminOrHr = isAdminOrHR(currentUser);

    return (
      <SortableListRow className={classes.header}>
        {disabled && (
          <Grid item>
            <Typography
              to={currentUserIsAdminOrHr ? to : undefined}
              target={currentUserIsAdminOrHr ? "_blank" : undefined}
              component={currentUserIsAdminOrHr ? Link : undefined}
              className={classNames({
                [classes.headerTitle]: currentUserIsAdminOrHr,
              })}
            >
              <b>{title}</b>
            </Typography>
          </Grid>
        )}
        {!disabled && (
          <Grid container spacing={4} alignItems="center">
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography variant="body2">
                    <b>Rows</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <IncrementDecrementButton
                    minValue={0}
                    value={rows}
                    onChange={onRowsChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <Typography variant="body2">
                    <b>Columns</b>
                  </Typography>
                </Grid>
                <Grid item>
                  <IncrementDecrementButton
                    minValue={0}
                    value={columns}
                    onChange={onColumnsChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs justify="flex-end" alignItems="center">
              <Button disabled={resetBtnDisabled} onClick={onReset}>
                Reset
              </Button>
            </Grid>
          </Grid>
        )}
      </SortableListRow>
    );
  }
}

const styles = theme => ({
  header: {
    padding: `0 ${theme.spacing(1)}px`,
    border: theme.gapReport.defaultBorder,
    background: theme.palette.common.white,
  },
  headerTitle: {
    color: theme.urlLink.color,
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

export default compose(
  connect(state => ({
    currentUser: getCurrentUserFromState(state),
  })),
  withStyles(styles),
)(CollectionConditionMatrixHeader);
