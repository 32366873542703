import { compose } from "redux";
import React, { PureComponent } from "react";
import { Grid, Drawer, withStyles } from "@material-ui/core";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";

import { HeaderBase, LogoAndAppTitle } from "altus-ui-components";

import routePaths from "app/routePaths";
import { APP_NAME } from "app/app.constants";
import UserDropdown from "features/components/UserDropdown";
import ApplicationSidebarMenu from "app/components/ApplicationSidebar/ApplicationSidebarMenu";
import ApplicationSidebarTopContent from "app/components/ApplicationSidebar/ApplicationSidebarTopContent";

class ApplicationSidebar extends PureComponent {
  render() {
    const { classes, routes } = this.props;

    return (
      <Drawer
        variant="permanent"
        classes={{
          root: classes.root,
          paper: classes.paper,
        }}
      >
        <HeaderBase
          disableGutters={true}
          classes={{
            root: classes.header,
          }}
        >
          <LogoAndAppTitle title={APP_NAME} to={routePaths.root} />
        </HeaderBase>
        <ApplicationSidebarTopContent />
        <Grid xs item className={classes.contentContainer}>
          <ApplicationSidebarMenu routes={routes} />
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={classes.topBottomContainer}
        >
          <UserDropdown Icon={PowerSettingsNew} />
        </Grid>
      </Drawer>
    );
  }
}

const styles = theme => ({
  root: {
    width: theme.sidebar.width,
  },
  paper: {
    overflow: "hidden",
    borderRight: "none",
    position: "relative",
    background: theme.sidebar.backgroundColor,
  },
  contentContainer: {
    overflowY: "auto",
  },
  topBottomContainer: {
    padding: theme.spacing(2),
  },
  header: {
    background: "transparent",
    paddingLeft: theme.spacing(1),
  },
});

export default compose(withStyles(styles))(ApplicationSidebar);
