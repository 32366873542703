import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { COMPETENCY_STATUS, EMPTY_SET } from "app/app.constants";
import GapReportCell from "features/components/GapReport/GapReportCell";
import { getSkillGapReportEmployeeSkillsFromState } from "features/competence.selectors";

class SkillGapReportEmployeeRow extends PureComponent {
  render() {
    const {
      skills,
      root,
      employeeId,
      employeeSkills,
      index: employeeIndex,
    } = this.props;

    return (
      <>
        {skills.map((skill, skillIndex) => {
          const skillId = skill.get("skillId").toString();

          const employeeCourseCompetencyStatus = employeeSkills.getIn(
            [skillId, "competencyStatus"],
            COMPETENCY_STATUS.NONE,
          );

          return (
            <GapReportCell
              x={skillIndex}
              y={employeeIndex}
              key={skillIndex}
              status={employeeCourseCompetencyStatus}
              to={`${root}/employees/${employeeId}/skills/${skillId}`}
            />
          );
        })}
      </>
    );
  }
}

SkillGapReportEmployeeRow.defaultProps = {
  employeeSkills: EMPTY_SET,
};

export default compose(
  connect((state, { employeeId }) => ({
    employeeSkills: getSkillGapReportEmployeeSkillsFromState(state, employeeId),
  })),
)(SkillGapReportEmployeeRow);
