import { Map } from "immutable";

import { ACTIONS, EMPTY_MAP } from "app/app.constants";
import employeeCourseMappers from "app/mappers/employeeCourse.mappers";

const initialState = EMPTY_MAP;

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_EMPLOYEE_COURSE_ATTENDANCES: {
      if (error) return state;

      const { employeeId, courseId } = action;

      return state.setIn(
        [employeeId.toString(), courseId.toString()],
        Map(
          payload.map(EmployeeCourseAttendance => [
            EmployeeCourseAttendance.employeeCourseAttendanceId.toString(),
            employeeCourseMappers.EmployeeCourseAttendance.from(
              EmployeeCourseAttendance,
            ),
          ]),
        ),
      );
    }

    case ACTIONS.DELETE_EMPLOYEE_COURSE_ATTENDANCE_FILE: {
      if (error) return state;

      const { employeeId, courseId, attendanceId, attendanceFileId } = action;

      return state.deleteIn([
        employeeId.toString(),
        courseId.toString(),
        attendanceId.toString(),
        "files",
        attendanceFileId.toString(),
      ]);
    }

    case ACTIONS.DELETE_EMPLOYEE_COURSE_ATTENDANCE: {
      if (error) return state;

      const { employeeId, courseId, attendanceId } = action;

      return state.deleteIn([
        employeeId.toString(),
        courseId.toString(),
        attendanceId.toString(),
      ]);
    }

    default:
      return state;
  }
};
