import React from "react";
import { Link } from "react-router-dom";
import withStyles from "@material-ui/styles/withStyles";
import { IconButton, Grid } from "@material-ui/core";

import { TableRow } from "altus-ui-components";

import PlusSquare from "app/components/icons/PlusSquare";
import CloseSquare from "app/components/icons/CloseSquare";
import MinusSquare from "app/components/icons/MinusSquare";

export const ExpandableTableRow = ({
  row,
  theme,
  depth,
  classes,
  rowIcon,
  offsetPx,
  ...rest
}) => {
  const offsetPxModified = theme.spacing(3) * (depth + 1);

  return (
    <>
      <Grid className={classes.anchor}>
        <IconButton
          disabled={!row.canExpand}
          size="small"
          fontSize="small"
          {...row.getToggleRowExpandedProps()}
          classes={{ root: classes.expander }}
        >
          {row.canExpand ? (
            row.isExpanded ? (
              <MinusSquare />
            ) : (
              <PlusSquare />
            )
          ) : (
            <CloseSquare />
          )}
        </IconButton>
      </Grid>

      {row.original.get("path") ? (
        <Link to={row.original.get("path")}>
          <TableRow {...rest} row={row} offsetPx={offsetPxModified} />
        </Link>
      ) : (
        <TableRow {...rest} row={row} offsetPx={offsetPxModified} />
      )}
    </>
  );
};

const styles = theme => ({
  expander: {
    position: "absolute",
    left: ({ depth }) => theme.spacing(3) * depth,
    top: theme.spacing(2),
  },
  anchor: {
    position: "relative",
    width: 0,
    height: 0,
  },
});

export default withStyles(styles, { withTheme: true })(ExpandableTableRow);
