import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Tooltip } from "@material-ui/core";

import {
  initializeRoleDetailsForm,
  initializeCourseDetailsForm,
  initializeSkillDetailsForm,
} from "features/competence.actions";

import routePaths from "app/routePaths";
import { isAdminOrHR } from "utils/app.util";
import { EMPTY_SET, ICONS } from "app/app.constants";
import { getCurrentUserFromState } from "app/app.selectors";
import GapReportHeaderCell from "features/components/GapReport/GapReportHeaderCell";
import GapReportRequirementHeaderCell from "features/components/GapReport/GapReportRequirementHeaderCell";

class RoleGapReportHeader extends PureComponent {
  render() {
    const {
      roles,
      classes,
      currentUser,
      roleCourses,
      roleSkills,
      dispatchInitializeRoleDetailsForm,
      dispatchInitializeSkillDetailsForm,
      dispatchInitializeCourseDetailsForm,
    } = this.props;

    const currentUserIsAdminOrHr = isAdminOrHR(currentUser);

    return (
      <Grid item container wrap="nowrap" className={classes.root}>
        {roles.map(role => {
          const roleId = role.get("roleId").toString();

          return (
            <React.Fragment key={roleId}>
              <Tooltip title={role.get("name")}>
                {currentUserIsAdminOrHr ? (
                  <GapReportHeaderCell
                    target="_blank"
                    component={Link}
                    to={`${routePaths.hrPortal}/roles/${role.get("roleId")}`}
                  >
                    <Typography
                      noWrap
                      variant="subtitle2"
                      className={classes.header}
                    >
                      {role.get("name")}
                    </Typography>
                  </GapReportHeaderCell>
                ) : (
                  <GapReportHeaderCell
                    onClick={() =>
                      dispatchInitializeRoleDetailsForm(role.get("roleId"))
                    }
                  >
                    <Typography
                      noWrap
                      variant="subtitle2"
                      className={classes.header}
                    >
                      {role.get("name")}
                    </Typography>
                  </GapReportHeaderCell>
                )}
              </Tooltip>

              {roleCourses.get(roleId, EMPTY_SET).map(roleCourse => (
                <GapReportRequirementHeaderCell
                  Icon={ICONS.COURSE}
                  name={roleCourse.get("courseName")}
                  key={roleCourse.get("roleCourseId")}
                  title={`${roleCourse.get("courseName")} (${roleCourse.get(
                    "courseTypeName",
                  )})`}
                  to={
                    currentUserIsAdminOrHr
                      ? `${routePaths.hrPortal}/courses/${roleCourse.get(
                          "courseId",
                        )}`
                      : undefined
                  }
                  onClick={
                    !currentUserIsAdminOrHr
                      ? () =>
                          dispatchInitializeCourseDetailsForm(
                            roleCourse.get("courseId"),
                          )
                      : undefined
                  }
                />
              ))}
              {roleSkills.get(roleId, EMPTY_SET).map(roleSkill => (
                <GapReportRequirementHeaderCell
                  Icon={ICONS.SKILL}
                  name={roleSkill.get("skillName")}
                  key={roleSkill.get("roleSkillId")}
                  title={`${roleSkill.get("skillName")} (${roleSkill.get(
                    "skillTypeName",
                  )})`}
                  to={
                    currentUserIsAdminOrHr
                      ? `${routePaths.hrPortal}/skills/${roleSkill.get(
                          "skillId",
                        )}`
                      : undefined
                  }
                  onClick={
                    !currentUserIsAdminOrHr
                      ? () =>
                          dispatchInitializeSkillDetailsForm(
                            roleSkill.get("skillId"),
                          )
                      : undefined
                  }
                />
              ))}
            </React.Fragment>
          );
        })}
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    top: 0,
    zIndex: 3,
    position: "sticky",
    height: theme.gapReport.header.height,
    borderTop: theme.gapReport.defaultBorder,
    borderRight: theme.gapReport.defaultBorder,
    backgroundColor: theme.palette.background.default,
  },
  header: {
    transform: "rotate(-90deg)",
    minWidth: theme.gapReport.header.height - theme.spacing(1),
    maxWidth: theme.gapReport.header.height - theme.spacing(1),
  },
});

export default compose(
  connect(
    state => ({
      currentUser: getCurrentUserFromState(state),
    }),
    {
      dispatchInitializeRoleDetailsForm: initializeRoleDetailsForm,
      dispatchInitializeSkillDetailsForm: initializeSkillDetailsForm,
      dispatchInitializeCourseDetailsForm: initializeCourseDetailsForm,
    },
  ),
  withStyles(styles),
)(RoleGapReportHeader);
