import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isImmutable } from "immutable";
import React, { useMemo, useState, useRef, useEffect } from "react";
import { Typography, RootRef, Grid } from "@material-ui/core";

import {
  updateWorkHistoryApproval,
  removeWorkHistoryApproval,
  workHistoryApprovalOnChange,
  previewOrSaveWorkHistoryFile,
  getEmployeeWorkHistoryRequiringApproval,
} from "features/competence.actions";
import {
  ICONS,
  Format,
  ACTIONS,
  EMPTY_MAP,
  APPROVAL_STATUS,
} from "app/app.constants";

import { formatDate } from "utils/format.util";
import SortableTable from "app/components/Table/SortableTable";
import DownloadFileColumn from "app/components/DownloadFileColumn";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import ApproveButton from "features/hr/approval/components/ApproveButton";
import { getWorkHistoryApprovalFromState } from "features/competence.selectors";
import CustomDatePickerColumn from "features/hr/approval/components/CustomDatePickerColumn";

const getStartDate = (item, customStartDates) => {
  const workHistoryId = item.get("workHistoryId").toString();
  return customStartDates.get(workHistoryId) || item.get("from");
};

const getEndDate = (item, customEndDates) => {
  const workHistoryId = item.get("workHistoryId").toString();
  return customEndDates.get(workHistoryId) || item.get("to");
};

const WorkHistoryApproval = ({
  root,
  onWorkHistoryCountChange,
  workHistories = EMPTY_MAP,
  dispatchPreviewOrSaveWorkHistoryFile,
  dispatchGetEmployeeWorkHistoryRequiringApproval,
  dispatchUpdateWorkHistoryApproval,
  dispatchRemoveWorkHistoryApproval,
}) => {
  const rootRef = useRef();

  const [customEndDates, setCustomEndDates] = useState(EMPTY_MAP);
  const [customStartDates, setCustomStartDates] = useState(EMPTY_MAP);

  useEffect(() => {
    onWorkHistoryCountChange(workHistories.count());
  }, [workHistories, onWorkHistoryCountChange]);

  const columns = useMemo(
    () => [
      {
        xs: 2,
        title: "Title",
        Header: "Title",
        id: "title",
        component: Grid,
        canSort: true,
        accessor: item => {
          if (isImmutable(item)) {
            return item.get("title");
          }
        },
        Cell: ({ row }) => {
          if (row.depth === 0) {
            return (
              <Grid>
                <Typography noWrap variant="body2">
                  {row.original.get("title")}
                </Typography>
                <Typography noWrap variant="caption" component="p">
                  {row.original.get("employeeName")}
                </Typography>
              </Grid>
            );
          }

          return (
            <DownloadFileColumn
              file={row.original}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                const workHistory = workHistories
                  .filter(
                    x =>
                      x.get("workHistoryId").toString() ===
                      row.original.workHistoryId.toString(),
                  )
                  .first();
                dispatchPreviewOrSaveWorkHistoryFile(
                  workHistory.get("employeeId"),
                  workHistory.get("workHistoryId"),
                  row.original,
                );
              }}
            />
          );
        },
      },
      {
        xs: 2,
        id: "company",
        title: "Company",
        Header: "Company",
        canSort: true,
        filter: {
          label: "Company",
          defaultText: "All companies",
        },
        getSortProperty: item => item.get("company"),
        accessor: item => {
          if (isImmutable(item)) {
            return item.get("company");
          }
        },
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return row.original.get("company");
        },
      },
      {
        xs: 1,
        component: Grid,
        id: "category",
        Header: "Category",
        title: "Category",
        canSort: true,
        filter: {
          label: "Category",
          defaultText: "All categories",
        },
        getSortProperty: item => item.get("parentCompetencyCategoryName"),
        accessor: item => {
          if (isImmutable(item)) {
            return item.get("parentCompetencyCategoryName");
          }
        },
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return (
            <Grid>
              <Typography noWrap variant="body2">
                {row.original.get("parentCompetencyCategoryName")}
              </Typography>
              <Typography noWrap component="p" variant="caption">
                {row.original.get("competencyCategoryName")}
              </Typography>
            </Grid>
          );
        },
      },
      {
        xs: true,
        title: "Start",
        id: "start",
        Header: "Start",
        component: Grid,
        canSort: true,
        accessor: item => {
          if (isImmutable(item)) {
            return formatDate(item.get("from"), Format.date);
          }
        },
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          const setCustomDateValue = newDate => {
            const workHistoryId = row.original.get("workHistoryId").toString();
            setCustomStartDates(x => x.set(workHistoryId, newDate));
          };
          return (
            <CustomDatePickerColumn
              originalValue={getStartDate(row.original, customStartDates)}
              setCustomDateValue={setCustomDateValue}
            />
          );
        },
      },
      {
        xs: true,
        component: Grid,
        title: "Finished",
        Header: "Finished",
        id: "finished",
        canSort: true,
        accessor: item => {
          if (isImmutable(item)) {
            return formatDate(item.get("to"), Format.date);
          }
        },
        Cell: ({ row }) => {
          if (row.depth === 0) {
            const setCustomDateValue = newDate => {
              const workHistoryId = row.original
                .get("workHistoryId")
                .toString();
              setCustomEndDates(x => x.set(workHistoryId, newDate));
            };

            return (
              <CustomDatePickerColumn
                originalValue={getEndDate(row.original, customEndDates)}
                setCustomDateValue={setCustomDateValue}
              />
            );
          }

          return "";
        },
      },
      {
        title: "",
        xs: true,
        id: "approve",
        component: Grid,
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return (
            <ApproveButton
              item={row.original}
              id={row.original.get("workHistoryId")}
              approveAction={ACTIONS.UPDATE_WORK_HISTORY_APPROVAL}
              rejectAction={ACTIONS.UPDATE_WORK_HISTORY_APPROVAL}
              undoAction={ACTIONS.REMOVE_WORK_HISTORY_APPROVAL}
              onApprove={() => {
                const workHistoryId = row.original
                  .get("workHistoryId")
                  .toString();

                dispatchUpdateWorkHistoryApproval(
                  row.original.get("employeeId"),
                  row.original.get("workHistoryId"),
                  {
                    from: customStartDates.get(workHistoryId),
                    to: customEndDates.get(workHistoryId),
                    approvalStatus: APPROVAL_STATUS.APPROVED,
                  },
                );
              }}
              onReject={() => {
                const workHistoryId = row.original
                  .get("workHistoryId")
                  .toString();

                dispatchUpdateWorkHistoryApproval(
                  row.original.get("employeeId"),
                  row.original.get("workHistoryId"),
                  {
                    from: customStartDates.get(workHistoryId),
                    to: customEndDates.get(workHistoryId),
                    approvalStatus: APPROVAL_STATUS.NOT_APPROVED,
                  },
                );
              }}
              onUndo={() =>
                dispatchRemoveWorkHistoryApproval(
                  row.original.get("employeeId"),
                  row.original.get("workHistoryId"),
                )
              }
            />
          );
        },
      },
      {
        xs: true,
        title: "",
        id: "competencyStatus",
        component: Grid,
        container: true,
        justify: "flex-end",
        getSortProperty: item => item.get("competencyStatus"),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return (
            <CompetencyStatusChip
              status={row.original.get("competencyStatus")}
            />
          );
        },
      },
    ],
    [
      workHistories,
      customEndDates,
      customStartDates,
      dispatchUpdateWorkHistoryApproval,
      dispatchRemoveWorkHistoryApproval,
      dispatchPreviewOrSaveWorkHistoryFile,
    ],
  );

  return (
    <RootRef rootRef={rootRef}>
      <SortableTable
        title="Work History"
        Icon={ICONS.HISTORY}
        items={workHistories}
        defaultSortColumn={null}
        columns={columns}
        subListPropertyName="files"
        subListFilterPropName="name"
        subListKey="workHistoryFileId"
        keyName="workHistoryId"
        hideBaseHeader={true}
        excludePadding={true}
        displayNumberOfItems={true}
        onRefresh={dispatchGetEmployeeWorkHistoryRequiringApproval}
        noResultsMessage="No work history items waiting approval..."
        createSortableListRowProps={item => ({
          component: Link,
          to: `${root}/employees/${item.get("employeeId")}/work-history`,
          // Prevent date picker to navigate to employee work history
          onClick: event => {
            if (rootRef.current && !rootRef.current.contains(event.target)) {
              event.preventDefault();
              event.stopPropagation();
            }
          },
        })}
      />
    </RootRef>
  );
};

export default compose(
  connect(
    state => ({
      workHistories: getWorkHistoryApprovalFromState(state),
    }),
    {
      dispatchWorkHistoryApprovalOnChange: workHistoryApprovalOnChange,
      dispatchPreviewOrSaveWorkHistoryFile: previewOrSaveWorkHistoryFile,
      dispatchGetEmployeeWorkHistoryRequiringApproval: getEmployeeWorkHistoryRequiringApproval,
      dispatchUpdateWorkHistoryApproval: updateWorkHistoryApproval,
      dispatchRemoveWorkHistoryApproval: removeWorkHistoryApproval,
    },
  ),
)(WorkHistoryApproval);
