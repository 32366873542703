import { Map, List } from "immutable";
import { ACTIONS } from "app/app.constants";
import { EmployeeSkillsByRoleMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_EMPLOYEE_ROLE_SKILLS_GROUPED_BY_ROLE: {
      if (error) return state;

      return state.set(
        action.employeeId.toString(),
        List(payload).map(EmployeeSkillsByRoleMapper.from),
      );
    }

    case ACTIONS.DELETE_EMPLOYEE_SKILL: {
      if (error) return state;

      var roleSkillIndex = -1;
      var skillIndex = -1;
      state.getIn([action.employeeId.toString()]).map((role, index) => {
        if (role.get("roleId") === null) {
          roleSkillIndex = index;

          role.getIn(["employeeRoleSkills"]).map((skill, indexSkill) => {
            if (skill.get("skillId") === action.skillId) {
              skillIndex = indexSkill;
            }
            return skillIndex;
          });
        }
        return roleSkillIndex;
      });

      if (roleSkillIndex === -1 || skillIndex === -1) {
        return state;
      }

      return state.deleteIn([
        action.employeeId.toString(),
        roleSkillIndex.toString(),
        "employeeRoleSkills",
        skillIndex.toString(),
      ]);
    }

    default:
      return state;
  }
};
