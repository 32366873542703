import moment from "moment";
import { Map, Set } from "immutable";

import { EmployeeMapper } from "app/app.mappers";
import fileMappers from "app/mappers/file.mappers";
import { EMPTY_MAP, Format } from "app/app.constants";

const employeeCourseAttendanceFromDto = ({
  files,
  endDate,
  startDate,
  approvalDate,
  expirationDate,
  employeeCourseAttendanceId,
  ...employeeCourseAttendance
}) =>
  Map({
    attendanceId: employeeCourseAttendanceId,
    endDate: endDate ? moment(endDate) : null,
    startDate: startDate ? moment(startDate) : null,
    expirationDate: expirationDate ? moment(expirationDate) : null,
    approvalDate: approvalDate ? moment(approvalDate) : null,
    files: Map(
      files.map(file => [
        file.employeeCourseAttendanceFileId.toString(),
        fileMappers.File.from(file),
      ]),
    ),
    ...employeeCourseAttendance,
  });

const employeeCourseAttendanceToCreateDto = ({ startDate, endDate }) => ({
  endDate: endDate ? endDate.format(Format.date) : null,
  startDate: startDate ? startDate.format(Format.date) : null,
});

const employeeCourseAttendanceToUpdateDto = ({
  startDate,
  endDate,
  approvalStatus,
}) => ({
  endDate: endDate ? endDate.format(Format.date) : null,
  startDate: startDate ? startDate.format(Format.date) : null,
  approvalStatus,
});

const employeeCourseAttendanceToCreateOverrideDto = ({ overrideReason }) => ({
  overrideReason,
});

const employeeCourseFromDto = ({
  endDate,
  startDate,
  files,
  expirationDate,
  pendingApprovalAttendance,
  ...employeeCourse
}) =>
  Map({
    endDate: endDate ? moment(endDate) : null,
    startDate: startDate ? moment(startDate) : null,
    expirationDate: expirationDate ? moment(expirationDate) : null,
    files: Set(files).map(fileMappers.File.from),
    pendingApprovalAttendance: pendingApprovalAttendance
      ? employeeCourseAttendanceFromDto(pendingApprovalAttendance)
      : EMPTY_MAP,
    ...employeeCourse,
  });

const employeeTrainingReportDto = ({
  employeeDisplayName,
  attendances,
  ...employeeCourse
}) =>
  Map({
    employeeDisplayName: employeeDisplayName,
    attendances: Map(
      attendances.map(attendance => [
        attendance.employeeCourseAttendanceId.toString(),
        employeeCourseAttendanceFromDto(attendance),
      ]),
    ),
    ...employeeCourse,
  });

const courseTrainingReportDto = ({ courseName, ...course }) =>
  Map({
    courseName: courseName,
    ...course,
  });

const outstandingTrainingReportDto = ({ courseName, employees, ...course }) =>
  Map({
    courseName: courseName,
    employees: Map(
      employees.map(employee => [
        employee.employeeId.toString(),
        EmployeeMapper.from(employee),
      ]),
    ),
    ...course,
  });

export default {
  EmployeeCourse: {
    from: employeeCourseFromDto,
  },
  EmployeeTrainingReport: {
    from: employeeTrainingReportDto,
  },
  CourseTrainingReport: {
    from: courseTrainingReportDto,
  },
  OutstandingTrainingReport: {
    from: outstandingTrainingReportDto,
  },
  EmployeeCourseAttendance: {
    from: employeeCourseAttendanceFromDto,
    to: {
      create: employeeCourseAttendanceToCreateDto,
      update: employeeCourseAttendanceToUpdateDto,
      createOverride: employeeCourseAttendanceToCreateOverrideDto,
    },
  },
};
