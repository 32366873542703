import Agent from "infrastructure/agent";

const getSpecifications = () => Agent.get(`/specification`);

const getSpecification = specificationId =>
  Agent.get(`/specification/${specificationId}`);

const getSpecificationWithParents = specificationId =>
  Agent.get(`/specification/${specificationId}/withparents`);

const createSpecification = specification =>
  Agent.post(`/specification`, specification);

const updateSpecification = (specificationId, specification) =>
  Agent.put(`/specification/${specificationId}`, specification);

const deleteSpecification = specificationId =>
  Agent.delete(`/specification/${specificationId}`);

const getAvailableCoursesForSpecification = specificationId =>
  Agent.get(`/specification/${specificationId}/course/availablecourses`);

const getSpecificationCoursesBySpecificationWithParents = specificationId =>
  Agent.get(`/specification/${specificationId}/course/withparents`);

const createSpecificationCourses = (specificationId, courseIdList) =>
  Agent.post(
    `/specification/${specificationId}/course/assign-courses`,
    courseIdList,
  );

const updateSpecificationCourse = (
  specificationId,
  courseId,
  specificationCourse,
) =>
  Agent.put(
    `/specification/${specificationId}/course/${courseId}`,
    specificationCourse,
  );

const deleteSpecificationCourse = (specificationId, courseId) =>
  Agent.delete(`/specification/${specificationId}/course/${courseId}`);

const getAvailableSkillsForSpecification = specificationId =>
  Agent.get(`/specification/${specificationId}/skill/availableskills`);

const getSpecificationSkillsBySpecificationWithParents = specificationId =>
  Agent.get(`/specification/${specificationId}/skill/withparents`);

const createSpecificationSkills = (specificationId, specificationIdList) =>
  Agent.post(
    `/specification/${specificationId}/skill/assign-skills`,
    specificationIdList,
  );

const updateSpecificationSkill = (
  specificationId,
  skillId,
  specificationSkill,
) =>
  Agent.put(
    `/specification/${specificationId}/skill/${skillId}`,
    specificationSkill,
  );

const deleteSpecificationSkill = (specificationId, skillId) =>
  Agent.delete(`/specification/${specificationId}/skill/${skillId}`);

export default {
  getSpecifications,
  getSpecification,
  getSpecificationWithParents,
  createSpecification,
  updateSpecification,
  deleteSpecification,

  getAvailableCoursesForSpecification,
  getSpecificationCoursesBySpecificationWithParents,
  createSpecificationCourses,
  updateSpecificationCourse,
  deleteSpecificationCourse,

  getAvailableSkillsForSpecification,
  getSpecificationSkillsBySpecificationWithParents,
  updateSpecificationSkill,
  deleteSpecificationSkill,
  createSpecificationSkills,
};
