import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Helmet } from "altus-ui-components";

import {
  getMyTeamRoleGapReport,
  myTeamRoleGapReportOnLoad,
  downloadAndSaveMyTeamRoleGapReport,
} from "features/competence.actions";

import { APP_ROOT } from "app/app.constants";
import RoleGapReportBasePage from "features/components/RoleGapReport/RoleGapReportBasePage";

class MyTeamRoleGapReportContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad } = this.props;

    dispatchOnLoad();
  }

  render() {
    const {
      breadcrumb,
      dispatchGetMyTeamRoleGapReport,
      dispatchDownloadMyTeamRoleGapReport,
    } = this.props;

    return (
      <>
        <Helmet titleTemplate="%s">
          <title>{breadcrumb}</title>
        </Helmet>
        <RoleGapReportBasePage
          title={breadcrumb}
          root={APP_ROOT.MY_TEAM}
          getRoleGapReport={dispatchGetMyTeamRoleGapReport}
          downloadRoleGapReport={dispatchDownloadMyTeamRoleGapReport}
        />
      </>
    );
  }
}

export default compose(
  connect(null, {
    dispatchOnLoad: myTeamRoleGapReportOnLoad,
    dispatchGetMyTeamRoleGapReport: getMyTeamRoleGapReport,
    dispatchDownloadMyTeamRoleGapReport: downloadAndSaveMyTeamRoleGapReport,
  }),
)(MyTeamRoleGapReportContainer);
