import Downshift from "downshift";
import React, { PureComponent } from "react";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import { withStyles } from "@material-ui/core/styles";

import {
  Paper,
  Popper,
  MenuItem,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";

const renderInput = inputProps => {
  const {
    InputProps,
    classes,
    ref,
    onChange,
    TextFieldProps,
    ...other
  } = inputProps;

  return (
    <TextField
      margin="normal"
      variant="standard"
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
      {...TextFieldProps}
    />
  );
};

const renderSuggestion = ({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
}) => {
  const isHighlighted = highlightedIndex === index;
  const isSelected =
    (selectedItem ? selectedItem.label : "" || "").indexOf(suggestion.label) >
    -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.value}
      disabled={suggestion.disabled}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
};

const getSuggestions = (options, value) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0
    ? options
    : options.filter(option =>
        inputValue
          .split(" ")
          .every(word => option.label.toLowerCase().includes(word)),
      );
};

class AutoSuggestField extends PureComponent {
  constructor(props) {
    super(props);

    this.inputField = React.createRef();
    this.popperNode = React.createRef();
  }

  blur = () => {
    this.inputField.current.blur();
  };

  endAdornmentOnClick = (reset, selectItem) => {
    const { value } = this.inputField.current;
    const { input } = this.props;
    const { onChange } = input;

    if (value) {
      reset();
      selectItem(null);
    }

    onChange(null);

    setTimeout(() => this.inputField.current.focus(), 0);
  };

  render() {
    const {
      label,
      input,
      classes,
      options,
      autoFocus,
      placeholder,
      noResultsMessage,
      clearBtnRef,
      TextFieldProps,
    } = this.props;

    const { onChange, value } = input;
    const selectedItem =
      value && options.find(option => option.value === input.value);

    return (
      <Downshift
        selectedItem={selectedItem}
        itemToString={item => (item ? item.label : "")}
        onSelect={this.blur}
        onChange={item => (item ? onChange(item.value) : undefined)}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
          openMenu,
          reset,
          selectItem,
          ...rest
        }) => {
          const suggestions = getSuggestions(options, inputValue);

          return (
            <div className={classes.container}>
              {renderInput({
                label,
                onChange,
                autoFocus,
                fullWidth: true,
                classes,
                TextFieldProps,
                InputProps: getInputProps({
                  placeholder,
                  endAdornment: (
                    <InputAdornment>
                      <IconButton
                        buttonRef={clearBtnRef}
                        onClick={() =>
                          this.endAdornmentOnClick(reset, selectItem)
                        }
                      >
                        {!inputValue && (
                          <Search
                            className={classes.filterIcon}
                            fontSize="small"
                          />
                        )}
                        {inputValue && (
                          <Clear
                            className={classes.filterIcon}
                            fontSize="small"
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputRef: this.inputField,
                }),
                inputProps: {
                  onFocus: openMenu,
                },
              })}
              <div ref={this.popperNode} />
              <Popper
                open={isOpen}
                className={classes.popper}
                anchorEl={this.popperNode.current}
              >
                <div
                  {...(isOpen
                    ? getMenuProps({}, { suppressRefError: true })
                    : {})}
                >
                  <Paper
                    className={classes.paper}
                    style={{
                      width: this.popperNode.current
                        ? this.popperNode.current.clientWidth
                        : null,
                    }}
                  >
                    {suggestions.size > 0 &&
                      suggestions.map((suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({ item: suggestion }),
                          highlightedIndex,
                          selectedItem,
                        }),
                      )}
                    {suggestions.size === 0 && noResultsMessage && (
                      <MenuItem selected={true} component="div">
                        {noResultsMessage}
                      </MenuItem>
                    )}
                  </Paper>
                </div>
              </Popper>
            </div>
          );
        }}
      </Downshift>
    );
  }
}

const styles = theme => ({
  filterIcon: {
    fill: theme.palette.grey[700],
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    maxHeight: 250,
    overflow: "auto",
  },
  popper: {
    zIndex: theme.zIndex.modal + 200,
  },
});

export default withStyles(styles)(AutoSuggestField);
