import { compose } from "redux";
import { List } from "immutable";
import { Grid } from "@material-ui/core";
import { Field } from "redux-form/immutable";
import React, { useEffect, useState } from "react";

import { ServiceMapper, EmployeeMapper } from "app/app.mappers";

import { EMPTY_LIST } from "app/app.constants";
import { required } from "utils/validation.util";
import TextField from "app/components/form/TextField";
import employeeService from "services/employee.service";
import serviceService from "services/service.service";
import { SERVICE_FORM } from "features/competence.constants";
import AutoSuggestField from "app/components/form/AutoSuggestField";
import CountryMultiselectField from "app/components/form/CountryMultiselectField";

const ServiceForm = ({ currentService, handleSubmit }) => {
  const [stateLists, setStateLists] = useState({
    services: EMPTY_LIST,
    employees: EMPTY_LIST,
  });

  useEffect(() => {
    Promise.all([
      serviceService.getServices(),
      employeeService.getEmployees(),
    ]).then(([services, employees]) =>
      setStateLists({
        services: List(services).map(ServiceMapper.from),
        employees: List(employees).map(EmployeeMapper.from),
      }),
    );
  }, []);

  return (
    <form id={SERVICE_FORM.ID} onSubmit={handleSubmit}>
      <Grid container direction="column">
        <Grid container item>
          <Field
            autoFocus
            required
            label="Service Name"
            validate={[required]}
            component={TextField}
            name={SERVICE_FORM.NAME}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Field
              select
              name={SERVICE_FORM.PARENT_ID}
              component={AutoSuggestField}
              label="Inherit Requirements from Service"
              options={stateLists.services.map(service => ({
                label: service.get("name"),
                value: service.get("serviceId"),
                disabled: currentService
                  ? currentService.get("serviceId") === service.get("serviceId")
                  : false,
              }))}
            />
          </Grid>
        </Grid>
        <Grid container item>
          <Field
            multiline
            rows={5}
            label="Description"
            component={TextField}
            name={SERVICE_FORM.DESCRIPTION}
          />
        </Grid>
        <Grid item container>
          <Field
            select
            required
            label="Service Owner"
            validate={[required]}
            name={SERVICE_FORM.OWNER_ID}
            component={AutoSuggestField}
            TextFieldProps={{
              required: true,
            }}
            options={stateLists.employees.map(employee => ({
              label: employee.get("displayName"),
              value: employee.get("employeeId"),
            }))}
          />
        </Grid>
        <Grid item container>
          <Field
            name={SERVICE_FORM.COUNTRIES}
            component={CountryMultiselectField}
          />
        </Grid>
      </Grid>
    </form>
  );
};
export default compose()(ServiceForm);
