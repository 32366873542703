import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Grid } from "@material-ui/core";

import {
  getRoleCoursesByRole,
  roleCoursesOnUnload,
} from "features/competence.actions";

import { ACTIONS, ICONS } from "app/app.constants";
import CrudBasePage from "app/components/CrudBasePage";
import { getActionDataStateFromState } from "app/app.selectors";
import { getRolesFromState } from "features/competence.selectors";
import RoleRoleCourseList from "features/role/components/RoleRoleCourseList";
class MyTeamRoleCoursesContainer extends PureComponent {
  componentDidMount() {
    const { dispatchGetRoleCourses, roleId } = this.props;
    dispatchGetRoleCourses(roleId);
  }

  componentWillUnmount() {
    const { dispatchOnUnload } = this.props;
    dispatchOnUnload();
  }

  columns = [
    {
      xs: 4,
      title: "Course Name",
      getSortProperty: item => item.getIn(["course", "name"]),
    },
    {
      xs: true,
      title: "Group",
      getSortProperty: item => item.getIn(["course", "courseGroupName"]),
    },
    {
      xs: true,
      title: "Type",
      getSortProperty: item => item.getIn(["course", "courseTypeName"]),
    },
    {
      xs: true,
      title: "Valid",
      getSortProperty: item => item.getIn(["course", "validityMonthsString"]),
    },
    {
      xs: true,
      title: "Status",
      getSortProperty: item => item.getIn(["course", "status"]),
    },
  ];

  getKey = roleCourse => roleCourse.get("roleCourseId");

  render() {
    const { roles, breadcrumb, dataState } = this.props;

    return (
      <CrudBasePage
        dataState={dataState}
        title={breadcrumb}
        displaySearchField={true}
      >
        <Grid container spacing={5}>
          {roles.map(role => {
            const roleId = role.get("roleId");

            return (
              <RoleRoleCourseList
                Icon={ICONS.COURSE}
                key={roleId}
                roleId={roleId}
                getKey={this.getKey}
                actions={this.actions}
                columns={this.columns}
                inherited={roleId.toString() !== this.props.roleId.toString()}
              />
            );
          })}
        </Grid>
      </CrudBasePage>
    );
  }
}

export default compose(
  connect(
    (state, { roleId }) => ({
      roles: getRolesFromState(state, roleId),
      dataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_ROLE_COURSES_BY_ROLE,
      ),
    }),
    {
      dispatchGetRoleCourses: getRoleCoursesByRole,
      dispatchOnUnload: roleCoursesOnUnload,
    },
  ),
)(MyTeamRoleCoursesContainer);
