import { compose } from "redux";
import { connect } from "react-redux";
import { IconButton, Tooltip } from "@material-ui/core";
import React, { useEffect, useMemo, useCallback } from "react";

import { useModal } from "altus-modal";
import { MultiSelectModal } from "altus-ui-components";

import {
  updateServiceSkill,
  serviceSkillsOnLoad,
  serviceSkillsOnUnload,
  createServiceSkillsOnSubmit,
  getAvailableSkillsForService,
  deleteServiceSkillAskConfirmation,
} from "features/competence.actions";

import {
  getServicesFromState,
  getAvailableSkillsForServiceFromState,
} from "features/competence.selectors";

import {
  getActionDataStateFromState,
  getSummarizedDataStatesFromState,
} from "app/app.selectors";

import SwitchField from "app/components/SwitchField";
import CrudBasePage from "app/components/CrudBasePage";
import { ACTIONS, ICONS, CRITICALITY } from "app/app.constants";
import { CREATE_SERVICE_SKILL_FORM } from "features/competence.constants";
import ServiceSkillListContainer from "features/service/components/ServiceSkillListContainer";

const getKey = item => item.get("serviceSkillId");
const getSkillName = skill => skill.get("name");

const ServiceSkillsContainer = ({
  dataState,
  breadcrumb,
  services,
  dispatchOnLoad,
  availableSkills,
  serviceId,
  dispatchOnUnload,
  getAvailableSkillsDataState,
  dispatchDeleteServiceSkill,
  dispatchUpdateServiceSkill,
  dispatchCreateServiceSkillOnSubmit,
  dispatchGetAvailableSkillsForService,
}) => {
  const [isOpen, toggleModal] = useModal(CREATE_SERVICE_SKILL_FORM.ID);

  useEffect(() => {
    dispatchOnLoad(serviceId);

    return () => dispatchOnUnload();
  }, [serviceId, dispatchOnUnload, dispatchOnLoad]);

  useEffect(() => {
    if (isOpen) {
      dispatchGetAvailableSkillsForService(serviceId);
    }
  }, [serviceId, isOpen, dispatchGetAvailableSkillsForService]);

  const columns = useMemo(
    () => [
      {
        xs: 4,
        title: "Skill Name",
        getSortProperty: item => item.getIn(["skillName"]),
      },
      {
        xs: true,
        title: "Group",
        getSortProperty: item => item.getIn(["skillGroupName"]),
      },
      {
        xs: true,
        title: "Type",
        getSortProperty: item => item.getIn(["skillTypeName"]),
      },
      {
        xs: true,
        title: "Valid",
        getSortProperty: item => item.getIn(["skillValidityMonthsString"]),
      },
      {
        xs: true,
        justify: "center",
        container: true,
        title: "Criticality",
        getValue: item => (
          <SwitchField
            label="Critical"
            checked={item.get("criticality") === CRITICALITY.CRITICAL}
            onChange={() =>
              dispatchUpdateServiceSkill(
                item.get("serviceId"),
                item.get("skillId"),
                item.update("criticality", skillCriticality =>
                  skillCriticality === CRITICALITY.CRITICAL
                    ? CRITICALITY.NOT_CRITICAL
                    : CRITICALITY.CRITICAL,
                ),
              )
            }
          />
        ),
      },
    ],
    [dispatchUpdateServiceSkill],
  );

  const actions = useMemo(
    () => [
      {
        getValue: item =>
          item.get("serviceId").toString() === serviceId.toString() && (
            <Tooltip title="Delete">
              <IconButton onClick={() => dispatchDeleteServiceSkill(item)}>
                <ICONS.DELETE />
              </IconButton>
            </Tooltip>
          ),
      },
    ],
    [serviceId, dispatchDeleteServiceSkill],
  );

  const addSkillsSubmit = useCallback(
    selectedSkills => {
      dispatchCreateServiceSkillOnSubmit(
        serviceId,
        selectedSkills.map(skill => skill.get("skillId")).toArray(),
      ).then(toggleModal);
    },
    [serviceId, toggleModal, dispatchCreateServiceSkillOnSubmit],
  );

  return (
    <CrudBasePage
      dataState={dataState}
      title={breadcrumb}
      displayAddButton={true}
      displaySearchField={true}
      addButtonOnClick={toggleModal}
    >
      {services.map(service => {
        const innerServiceId = service.get("serviceId");

        return (
          <ServiceSkillListContainer
            Icon={ICONS.SKILL}
            key={innerServiceId}
            serviceId={innerServiceId}
            getKey={getKey}
            actions={actions}
            columns={columns}
            inherited={innerServiceId.toString() !== serviceId.toString()}
          />
        );
      })}
      <MultiSelectModal
        open={isOpen && !getAvailableSkillsDataState.isLoading()}
        displayChips
        onClose={toggleModal}
        getName={getSkillName}
        items={availableSkills}
        title="Add Skills"
        onSubmit={addSkillsSubmit}
      />
    </CrudBasePage>
  );
};

export default compose(
  connect(
    (state, { serviceId }) => ({
      services: getServicesFromState(state, serviceId),
      availableSkills: getAvailableSkillsForServiceFromState(state, serviceId),
      getAvailableSkillsDataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_AVAILABLE_SKILLS_FOR_SERVICE,
      ),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_SERVICE_SKILLS_BY_SERVICE_WITH_PARENTS,
        ACTIONS.GET_AVAILABLE_SKILLS_FOR_SERVICE,
        ACTIONS.UPDATE_SERVICE_SKILL,
      ),
    }),
    {
      dispatchOnLoad: serviceSkillsOnLoad,
      dispatchOnUnload: serviceSkillsOnUnload,
      dispatchUpdateServiceSkill: updateServiceSkill,
      dispatchDeleteServiceSkill: deleteServiceSkillAskConfirmation,
      dispatchCreateServiceSkillOnSubmit: createServiceSkillsOnSubmit,
      dispatchGetAvailableSkillsForService: getAvailableSkillsForService,
    },
  ),
)(ServiceSkillsContainer);
