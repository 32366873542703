import { compose } from "redux";
import { List } from "immutable";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import React, { useState, useEffect, useCallback } from "react";

import { Grid } from "@material-ui/core";

import { ReceivedDataState } from "altus-datastate";
import { Helmet, BasePage } from "altus-ui-components";

import {
  getEmployeeRoleGapReport,
  clearEmployeeRoleGapReport,
} from "features/competence.actions";

import {
  getEmployeeRoleGapReportRolesFromState,
  getEmployeeRoleGapReportSkillsFromState,
  getEmployeeRoleGapReportCoursesFromState,
  getEmployeeRoleGapReportRoleSkillsFromState,
  getEmployeeRoleGapReportRoleCoursesFromState,
  getEmployeeRoleGapReportEmployeeRolesFromState,
  getEmployeeRoleGapReportEmployeeSkillsFromState,
  getEmployeeRoleGapReportEmployeeCoursesFromState,
} from "features/competence.selectors";

import {
  ACTIONS,
  EMPTY_MAP,
  EMPTY_LIST,
  FEATURE_FLAG,
  EMPTY_SET,
} from "app/app.constants";

import {
  getFeatureFlagStatusFromState,
  getSummarizedDataStatesFromState,
} from "app/app.selectors";

import { invokeIfFunction } from "utils/app.util";
import EmployeeRoleGapReport from "features/employee/reports/EmployeeRoleGapReport";
import GapReportAutocompleteFilter from "features/components/GapReport/GapReportAutocompleteFilter";

const FILTERS = {
  ROLES: "roles",
  COURSES: "courses",
  SKILLS: "skills",
};

const initialFilter = EMPTY_MAP.set(FILTERS.ROLES, EMPTY_LIST)
  .set(FILTERS.COURSES, EMPTY_LIST)
  .set(FILTERS.SKILLS, EMPTY_LIST);

const EmployeeRoleGapReportContainer = ({
  classes,
  employeeId,
  breadcrumb,
  roles = EMPTY_LIST,
  skills = EMPTY_LIST,
  courses = EMPTY_LIST,
  roleCourses = EMPTY_SET,
  roleSkills = EMPTY_SET,
  employeeRoles = EMPTY_SET,
  employeeCourses = EMPTY_SET,
  isSkillsFeatureFlagEnabled,
  dataState = ReceivedDataState,
  dispatchGetEmployeeRoleGapReport,
  dispatchClearEmployeeRoleGapReport,
}) => {
  const [filter, setFilter] = useState(initialFilter);

  useEffect(() => {
    dispatchGetEmployeeRoleGapReport(employeeId, filter);

    return () => dispatchClearEmployeeRoleGapReport();
  }, [
    filter,
    employeeId,
    dispatchGetEmployeeRoleGapReport,
    dispatchClearEmployeeRoleGapReport,
  ]);

  const setFilterState = useCallback((key, value) => {
    setFilter(filter => (value ? filter.set(key, value) : filter.delete(key)));
  }, []);

  const renderFilter = useCallback(() => {
    return (
      <>
        <Grid item xs={2}>
          <GapReportAutocompleteFilter
            label="Roles"
            options={roles}
            getOptionLabel={role => role.get("name")}
            value={filter
              .get(FILTERS.ROLES)
              .map(roleId => roles.get(roleId.toString()))}
            onChange={(_, value) => {
              setFilterState(
                FILTERS.ROLES,
                List(value.map(role => role.get("roleId"))),
              );
            }}
          />
        </Grid>
      </>
    );
  }, [filter, setFilterState, roles]);

  return (
    <>
      <Helmet titleTemplate="%s">
        <title>{breadcrumb}</title>
      </Helmet>
      <BasePage
        dataState={dataState}
        classes={{
          root: classes.basePageRoot,
          children: classes.basePageChildren,
        }}
      >
        <Grid
          container
          wrap="nowrap"
          spacing={3}
          component="form"
          alignItems="flex-start"
          className={classes.filterActionContainer}
        >
          {invokeIfFunction(renderFilter)}
        </Grid>
        <EmployeeRoleGapReport
          roles={roles}
          courses={courses}
          skills={skills}
          employeeId={employeeId}
          roleCourses={roleCourses}
          roleSkills={roleSkills}
          employeeRoles={employeeRoles}
          employeeCourses={employeeCourses}
          isSkillsFeatureFlagEnabled={isSkillsFeatureFlagEnabled}
        />
      </BasePage>
    </>
  );
};

const styles = theme => ({
  basePageRoot: {
    overflow: "auto",
  },
  basePageChildren: {
    padding: `${theme.spacing(2)}px 0`,
  },
  filterActionContainer: {
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.sidebar.backgroundColor,
  },
});

export default compose(
  connect(
    state => ({
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_EMPLOYEE_ROLE_GAP_REPORT,
      ),
      isSkillsFeatureFlagEnabled: getFeatureFlagStatusFromState(
        state,
        FEATURE_FLAG.SKILLS,
      ),
      roles: getEmployeeRoleGapReportRolesFromState(state),
      skills: getEmployeeRoleGapReportSkillsFromState(state),
      courses: getEmployeeRoleGapReportCoursesFromState(state),
      roleSkills: getEmployeeRoleGapReportRoleSkillsFromState(state),
      roleCourses: getEmployeeRoleGapReportRoleCoursesFromState(state),
      employeeRoles: getEmployeeRoleGapReportEmployeeRolesFromState(state),
      employeeSkills: getEmployeeRoleGapReportEmployeeSkillsFromState(state),
      employeeCourses: getEmployeeRoleGapReportEmployeeCoursesFromState(state),
    }),
    {
      dispatchGetEmployeeRoleGapReport: getEmployeeRoleGapReport,
      dispatchClearEmployeeRoleGapReport: clearEmployeeRoleGapReport,
    },
  ),
  React.memo,
  withStyles(styles),
)(EmployeeRoleGapReportContainer);
