import { Map } from "immutable";
import { ACTIONS } from "app/app.constants";
import { SkillEvidenceMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_EMPLOYEE_SKILL_EVIDENCES: {
      if (error) return state;

      const { employeeId, skillId } = action;
      return state.setIn(
        [employeeId.toString(), skillId.toString()],
        Map(
          payload.map(employeeSkillEvidence => [
            employeeSkillEvidence.skillEvidenceId.toString(),
            SkillEvidenceMapper.from(employeeSkillEvidence),
          ]),
        ),
      );
    }

    case ACTIONS.DELETE_EMPLOYEE_SKILL_EVIDENCE: {
      if (error) return state;

      const { skillEvidenceId } = action;

      return state.delete(skillEvidenceId.toString());
    }

    default:
      return state;
  }
};
