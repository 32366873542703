import { compose } from "redux";
import { connect } from "react-redux";
import React, { useCallback, useEffect } from "react";

import { ReceivedDataState } from "altus-datastate";

import { ACTIONS } from "app/app.constants";

import { clearCourseGapReport } from "features/competence.actions";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import GapReportBasePage from "features/components/GapReport/GapReportBasePage";
import CourseGapReport from "features/components/CourseGapReport/CourseGapReport";
import { getGapReportEmployeesByDepartmentFromState } from "features/competence.selectors";
import CourseGapReportFilter from "features/components/CourseGapReport/CourseGapReportFilter";

const CourseGapReportBasePage = ({
  root,
  title,
  dataState = ReceivedDataState,
  employeesByDepartment,
  dispatchClearCourseGapReport,
  getCourseGapReport,
  downloadCourseGapReport,
}) => {
  useEffect(() => {
    dispatchClearCourseGapReport();
  }, [dispatchClearCourseGapReport]);

  const renderFilter = useCallback(
    () => (
      <CourseGapReportFilter
        getGapReport={getCourseGapReport}
        clearGapReport={dispatchClearCourseGapReport}
        downloadCourseGapReport={downloadCourseGapReport}
        downloadBtnDisabled={!employeesByDepartment.size}
      />
    ),
    [
      employeesByDepartment,
      getCourseGapReport,
      dispatchClearCourseGapReport,
      downloadCourseGapReport,
    ],
  );

  return (
    <GapReportBasePage
      title={title}
      dataState={dataState}
      renderFilter={renderFilter}
      employeesByDepartment={employeesByDepartment}
    >
      {!!employeesByDepartment.size && (
        <CourseGapReport
          root={root}
          title={title}
          employeesByDepartment={employeesByDepartment}
        />
      )}
    </GapReportBasePage>
  );
};

export default compose(
  connect(
    state => ({
      employeesByDepartment: getGapReportEmployeesByDepartmentFromState(state),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_COURSE_GAP_REPORT,
        ACTIONS.DOWNLOAD_COURSE_GAP_REPORT,
        ACTIONS.GET_EMPLOYEES,
        ACTIONS.GET_DEPARTMENTS,
      ),
    }),
    {
      dispatchClearCourseGapReport: clearCourseGapReport,
    },
  ),
)(CourseGapReportBasePage);
