import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { ACTIONS } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import { getCollectionsBySkill } from "features/competence.actions";
import CourseSkillCollectionBasePage from "features/components/CourseSkillCollectionBasePage";

class SkillCollectionContainer extends PureComponent {
  componentDidMount() {
    const { dispatchGetCollections, skillId } = this.props;

    dispatchGetCollections(skillId);
  }

  getOptionHighlighted = option => {
    const { skillId } = this.props;

    return option && option.get("skillId")
      ? option.get("skillId").toString() === skillId.toString()
      : false;
  };

  render() {
    const { dataState, breadcrumb } = this.props;

    return (
      <CourseSkillCollectionBasePage
        title={breadcrumb}
        dataState={dataState}
        getOptionHighlighted={this.getOptionHighlighted}
        noResultsMessage="This skill is not part of any collections"
      />
    );
  }
}

export default compose(
  connect(
    state => ({
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_SKILL,
        ACTIONS.GET_COLLECTIONS,
        ACTIONS.GET_COLLECTION_MATRIX,
      ),
    }),
    {
      dispatchGetCollections: getCollectionsBySkill,
    },
  ),
)(SkillCollectionContainer);
