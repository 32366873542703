import React from "react";
import { compose } from "redux";
import isString from "lodash/isString";
import { withStyles } from "@material-ui/styles";
import Refresh from "@material-ui/icons/Refresh";

import { SearchField } from "altus-ui-components";

import {
  Grid,
  Button,
  Tooltip,
  IconButton,
  Typography,
} from "@material-ui/core";

import { EMPTY_LIST } from "app/app.constants";
import { invokeIfFunction } from "utils/app.util";
import FilterDropdown from "app/components/SortableList/FilterDropdown";
import SortableListRow from "app/components/SortableList/SortableListRow";

const defaultFilterRender = ({ ...rest }) => <FilterDropdown {...rest} />;

const SortableListActionsHeader = ({
  Icon,
  items,
  title,
  classes,
  striped,
  columns,
  onRefresh,
  setFilter,
  setSearch,
  selectItem,
  clearFilter,
  listActions,
  deselectItems,
  selectedItems,
  filter: listFilter,
}) => {
  return (
    <SortableListRow
      noborder
      striped={striped}
      classes={{
        root: classes.root,
      }}
    >
      <Grid container alignItems="center">
        <Grid item>
          <Grid container alignItems="center">
            {/* <Grid item> */}
            {!!Icon && (
              <Grid item>
                <Icon className={classes.titleIcon} color="primary" />
              </Grid>
            )}
            {isString(title) ? (
              <Grid item>
                <Typography variant="h6">{title}</Typography>
              </Grid>
            ) : (
              title
            )}
            {/* </Grid> */}
            {onRefresh && (
              <Grid item>
                <Tooltip title="Refresh">
                  <IconButton
                    classes={{
                      root: classes.iconButtonRoot,
                    }}
                    onClick={onRefresh}
                  >
                    <Refresh />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {listActions.map((listAction, index) => {
              const { renderAction, getTitle } = listAction;

              return (
                <Grid item key={index}>
                  <Tooltip title={getTitle(selectedItems)}>
                    <Grid>
                      {invokeIfFunction(renderAction, {
                        selectedItems,
                        deselectItems,
                        selectItem,
                      })}
                    </Grid>
                  </Tooltip>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid
          xs
          item
          container
          spacing={3}
          justifyContent="flex-end"
          alignItems="center"
        >
          {columns.some(({ filter }) => !!filter) && (
            <Grid item>
              <Tooltip title="Reset">
                <Grid>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={clearFilter}
                    disabled={!listFilter.size}
                    className={classes.resetFilterButton}
                  >
                    Reset
                  </Button>
                </Grid>
              </Tooltip>
            </Grid>
          )}
          {columns.map(({ title, filter, getSortProperty }, columnNo) => {
            if (!filter) return null;

            const {
              defaultText = "All",
              label,
              getFilterText,
              render = defaultFilterRender,
              visible = true,
            } = filter;

            const currentColumnFilter = listFilter.get(columnNo.toString());

            return (
              visible && (
                <Grid item key={columnNo}>
                  {render({
                    items,
                    title,
                    label,
                    defaultText,
                    getFilterText,
                    getItemValue: getSortProperty,
                    onChange: value => setFilter(columnNo, value, items),
                    value: currentColumnFilter,
                  })}
                </Grid>
              )
            );
          })}
          <Grid item>
            <SearchField
              timeout={250}
              onChange={setSearch}
              TextFieldProps={{
                placeholder: "Search items...",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </SortableListRow>
  );
};

const styles = theme => ({
  root: {
    height: "auto",
    padding: `${theme.spacing(0.5)}px 0`,
    background: theme.palette.background.default,
  },
  iconButtonRoot: {
    padding: theme.spacing(1) + 1,
  },
  titleIcon: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(1),
  },
  filterButton: {
    maxWidth: 175,
    textTransform: "none",
  },
  resetFilterButton: {
    color: props => (props.filter.size ? "white" : undefined),
    background: props =>
      props.filter.size ? theme.altus.color.accent : undefined,
    "&:hover": {
      background: theme.altus.color.accent,
    },
  },
  filterLabel: {
    color: theme.palette.text.secondary,
  },
});

SortableListActionsHeader.defaultProps = {
  columns: EMPTY_LIST,
  filter: EMPTY_LIST,
  listActions: EMPTY_LIST,
};

export default compose(
  React.memo,
  withStyles(styles),
)(SortableListActionsHeader);
