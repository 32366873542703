import { compose } from "redux";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";

import { EMPTY_SET } from "app/app.constants";
import { invokeIfFunction } from "utils/app.util";
import AutoSizeSvg from "features/components/GapReport/AutoSizeSvg";
import GapReportEmployeeRow from "features/components/GapReport/GapReportEmployeeRow";

class GapReportDepartment extends PureComponent {
  render() {
    const { root, classes, employees, renderEmployee } = this.props;

    return (
      <Grid container wrap="nowrap">
        <Grid item container direction="column" className={classes.employees}>
          {employees.map((employee, index) => (
            <GapReportEmployeeRow root={root} key={index} employee={employee} />
          ))}
        </Grid>
        <AutoSizeSvg>
          {employees.map((employee, index) =>
            invokeIfFunction(renderEmployee, { employee, index }),
          )}
        </AutoSizeSvg>
      </Grid>
    );
  }
}

GapReportDepartment.defaultProps = {
  employees: EMPTY_SET,
};

const styles = theme => ({
  employees: {
    left: 0,
    zIndex: 4,
    position: "sticky",
    minWidth: theme.gapReport.employeesColumn.width,
    maxWidth: theme.gapReport.employeesColumn.width,
    background: theme.palette.background.default,
  },
});

export default compose(withStyles(styles))(GapReportDepartment);
