import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Typography, Grid } from "@material-ui/core";

import { BasePage } from "altus-ui-components";

import { ACTIONS } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import CollectionConditionMatrix from "features/components/CollectionConditionMatrix/CollectionConditionMatrix";

const CollectionConditionContainer = ({
  dataState,
  breadcrumb,
  collectionId,
}) => (
  <BasePage dataState={dataState}>
    <CollectionConditionMatrix title={breadcrumb} collectionId={collectionId} />
    <br />
    <Grid container justify="flex-end">
      <Typography variant="caption">
        <i>Higher override Lower Competence Statuses</i>
      </Typography>
    </Grid>
  </BasePage>
);

export default compose(
  connect(state => ({
    dataState: getSummarizedDataStatesFromState(
      state,
      ACTIONS.GET_SKILLS,
      ACTIONS.GET_COURSES,
      ACTIONS.UPDATE_COLLECTION,
      ACTIONS.GET_COLLECTION_ITEMS,
      ACTIONS.RESET_COLLECTION_MATRIX,
      ACTIONS.UPDATE_COLLECTION_MATRIX,
    ),
  })),
)(CollectionConditionContainer);
