import React, { PureComponent } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { darken } from "@material-ui/core/styles/colorManipulator";
import { Tooltip, withStyles } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { ICONS } from "app/app.constants";

export const LIST_VIEW = "LIST_VIEW";
export const TREE_VIEW = "TREE_VIEW";

class RolesViewToggleButtons extends PureComponent {
  render() {
    const { value, onChange, classes } = this.props;

    return (
      <ToggleButtonGroup
        size="small"
        value={value}
        exclusive
        onChange={onChange}
      >
        <ToggleButton
          value={LIST_VIEW}
          classes={{
            root: classes.toggleButton,
          }}
        >
          <Tooltip title="List view">
            <ICONS.LIST_VIEW />
          </Tooltip>
        </ToggleButton>
        <ToggleButton
          value={TREE_VIEW}
          classes={{
            root: classes.toggleButton,
          }}
        >
          <Tooltip title="Tree view">
            <ICONS.TREE_VIEW />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>
    );
  }
}

const styles = theme => ({
  toggleButton: {
    "&.Mui-selected": {
      backgroundColor: theme.altus.color.accent,
      color: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: darken(theme.altus.color.accent, 0.05),
      },
    },
  },
});

RolesViewToggleButtons.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf([LIST_VIEW, TREE_VIEW]).isRequired,
};

export default compose(withStyles(styles))(RolesViewToggleButtons);
