import React from "react";
import { compose } from "redux";
import { Grid } from "@material-ui/core";

import ReadOnlyFieldReduxForm from "app/components/form/ReadOnlyFieldReduxForm";
import { EMPTY_SET } from "app/app.constants";

const CourseDetails = ({ course }) => {
  return (
    <Grid item container>
      {!!course && (
        <Grid container direction="column">
          <Grid item container>
            <ReadOnlyFieldReduxForm
              label="Course Name"
              input={{
                value: course.get("name"),
              }}
            />
          </Grid>
          <Grid item container>
            <ReadOnlyFieldReduxForm
              rows={4}
              multiline
              label="Description"
              input={{
                value: course.get("description"),
              }}
            />
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs={6}>
              <ReadOnlyFieldReduxForm
                label="Course Type"
                input={{
                  value: course.get("courseTypeName"),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyFieldReduxForm
                label="Course Group"
                input={{
                  value: course.get("courseGroupName"),
                }}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs={6}>
              <ReadOnlyFieldReduxForm
                label="Status"
                input={{
                  value: course.get("status"),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyFieldReduxForm
                label="Valid"
                input={{
                  value: course.get("validityMonthsString"),
                }}
              />
            </Grid>
          </Grid>
          <Grid item container>
            <ReadOnlyFieldReduxForm
              label="Countries"
              input={{
                value: course.get("countries", EMPTY_SET).size
                  ? course
                      .get("countries")
                      .map(country => country.get("name"))
                      .join(", ")
                  : "Global",
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default compose()(CourseDetails);
