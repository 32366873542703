import { compose } from "redux";
import React, { PureComponent } from "react";

import EmployeeCourseContainer from "features/employee/employeeCourse/EmployeeCourseContainer";

class EmployeeEmployeeCourseContainer extends PureComponent {
  render() {
    const { courseId, employeeId, routes } = this.props;

    return (
      <EmployeeCourseContainer
        routes={routes}
        courseId={courseId}
        employeeId={employeeId}
      />
    );
  }
}

export default compose()(EmployeeEmployeeCourseContainer);
