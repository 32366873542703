import { Map, OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { SpecificationSkillMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case ACTIONS.GET_SPECIFICATION_SKILLS_BY_SPECIFICATION_WITH_PARENTS: {
      if (error) return state;

      return OrderedMap(
        payload.map(specificationSkill => [
          specificationSkill.specificationSkillId.toString(),
          SpecificationSkillMapper.from(specificationSkill),
        ]),
      ).groupBy(specificationSkill =>
        specificationSkill.get("specificationId").toString(),
      );
    }

    case ACTIONS.CREATE_SPECIFICATION_SKILLS: {
      if (error) return state;

      return state.set(
        action.specificationId.toString(),
        OrderedMap(
          payload.map(specificationSkill => [
            specificationSkill.specificationSkillId.toString(),
            SpecificationSkillMapper.from(specificationSkill),
          ]),
        ),
      );
    }

    case ACTIONS.UPDATE_SPECIFICATION_SKILL: {
      if (error) return state;

      return state.setIn(
        [
          payload.specificationId.toString(),
          payload.specificationSkillId.toString(),
        ],
        SpecificationSkillMapper.from(payload),
      );
    }
    case ACTIONS.DELETE_SPECIFICATION_SKILL: {
      if (error) return state;

      return state.deleteIn([
        action.specificationId.toString(),
        action.specificationSkillId.toString(),
      ]);
    }
    case ACTIONS.CLEAR_SPECIFICATION_SKILLS: {
      return initialState;
    }
    default:
      return state;
  }
};
