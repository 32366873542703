import { compose } from "redux";
import React, { useMemo } from "react";
import { withStyles, Typography, Box, Grid } from "@material-ui/core";

import { ICONS } from "app/app.constants";
import { TableRowActionsCell } from "altus-ui-components";
import withToggleModal from "app/components/withToggleModal";
import SortableTable from "app/components/Table/SortableTable";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import GapReportEmployeesHeaderOptions from "features/components/GapReport/GapReportEmployeesHeaderOptions";

const TrainingAndCompetencyreportTableContainter = ({ classes, employees }) => {
  const columns = useMemo(
    () => [
      {
        xl: true,
        id: "action",
        paddingLeft: "16px",
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (row.depth !== 0) return <TableRowActionsCell minItems={1} />;
          return null;
        },
      },
      {
        id: "sortOrder",
        getSortProperty: item => item.get("sortOrder"),
      },
      {
        xs: 10,
        id: "name",
        title: "Employee",
        Header: "Employee",
        Cell: ({ row }) => {
          if (row.depth !== 0 && row.original.get("rowType") === 1)
            return (
              <Typography>
                <Box className={classes.bold}>{row.original.get("name")}</Box>
              </Typography>
            );

          const rType = row.original.get("rowType");
          return (
            <Grid container className={classes.iconStyling}>
              <Grid item className={classes.nameStyling}>
                {rType === 2 ? (
                  <ICONS.SKILL />
                ) : rType === 3 ? (
                  <ICONS.COURSE />
                ) : (
                  ""
                )}
              </Grid>
              <Grid item>{row.original.get("name")}</Grid>
            </Grid>
          );
        },
      },
      {
        xs: true,
        title: "",
        id: "competencyStatus",
        Header: "Status",
        component: Grid,
        container: true,
        justify: "flex-end",
        getSortProperty: item => item.get("competencyStatus"),
        Cell: ({ row }) => {
          if (row.depth === 0) return "";
          return (
            <CompetencyStatusChip
              status={row.original.get("competencyStatus")}
            />
          );
        },
      },
    ],
    [classes.bold, classes.iconStyling, classes.nameStyling],
  );

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={9}>
            <Typography className={classes.titleText} variant="h5">
              {"Training and Competency"}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <GapReportEmployeesHeaderOptions
              allRolesVisible={false}
              rolesAssignedOnlyVisible={false}
              employeeAssignedOnly={false}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <SortableTable
          useExpanded
          stickyHeader
          disableSortBy
          displayAddButton={false}
          columns={columns}
          keyName="id"
          useGlobalFilter={false}
          subListPropertyName="trainingAndCompetencyItems"
          items={employees}
          noItemsMessage="No training and competency found..."
        />
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  formControlLabelRoot: {
    marginLeft: 0,
  },
  bold: {
    fontWeight: "bold",
  },
  iconStyling: {
    paddingLeft: "0px",
  },
  nameStyling: {
    marginRight: "16px",
  },
  titleText: {
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: "20px",
  },
});

export default compose(
  withStyles(styles),
  withToggleModal,
)(TrainingAndCompetencyreportTableContainter);
