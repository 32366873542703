import { Map, List } from "immutable";

import { EMPTY_MAP, ACTIONS } from "app/app.constants";

const initialState = Map({
  settings: EMPTY_MAP,
  hr: EMPTY_MAP,
  myTeam: EMPTY_MAP,
  employeeRole: EMPTY_MAP,
  employee: EMPTY_MAP,
  course: EMPTY_MAP,
  skill: EMPTY_MAP,
});

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_HR_STATS: {
      if (error) return state;

      return state.set("hr", Map(payload));
    }

    case ACTIONS.GET_MY_TEAM_STATS: {
      if (error) return state;

      return state.set("myTeam", Map(payload));
    }

    case ACTIONS.GET_VERIFIER_STATS: {
      if (error) return state;

      return state.set("verifier", Map(payload));
    }

    case ACTIONS.GET_STATS_FOR_EMPLOYEE:
    case ACTIONS.GET_CURRENT_EMPLOYEE_STATS: {
      if (error) return state;
      return state.setIn(
        ["employee", payload.employeeId.toString()],
        Map(payload),
      );
    }

    case ACTIONS.GET_SETTINGS_STATS: {
      if (error) return state;

      return state.set("settings", Map(payload));
    }

    case ACTIONS.GET_STATS_FOR_EMPLOYEE_ROLES:
    case ACTIONS.GET_STATS_FOR_ROLE_MEMBERS:
    case ACTIONS.GET_STATS_FOR_MY_TEAM_ROLE_MEMBERS: {
      if (error) return state;

      return state.update("employeeRole", employeeRole =>
        List(payload).reduce(
          (result, stat) =>
            result.setIn(
              [stat.employeeId.toString(), stat.roleId.toString()],
              Map(stat),
            ),
          employeeRole,
        ),
      );
    }

    case ACTIONS.GET_STATS_FOR_EMPLOYEE_ROLE: {
      if (error) return state;

      return state.setIn(
        [
          "employeeRole",
          payload.employeeId.toString(),
          payload.roleId.toString(),
        ],
        Map(payload),
      );
    }

    case ACTIONS.GET_STATS_FOR_ROLES:
    case ACTIONS.GET_STATS_FOR_MY_TEAM_ROLES: {
      if (error) return state;

      return state.set(
        "role",
        Map(
          payload.map(roleStats => [
            roleStats.roleId.toString(),
            Map(roleStats),
          ]),
        ),
      );
    }

    case ACTIONS.GET_STATS_FOR_ROLE:
    case ACTIONS.GET_STATS_FOR_MY_TEAM_ROLE: {
      if (error) return state;

      return state.setIn(["role", payload.roleId.toString()], Map(payload));
    }

    case ACTIONS.GET_STATS_FOR_MY_TEAM_EMPLOYEES:
    case ACTIONS.GET_STATS_FOR_EMPLOYEES: {
      if (error) return state;

      return state.update("employee", employee =>
        List(payload).reduce(
          (result, stat) =>
            result.setIn([stat.employeeId.toString()], Map(stat)),
          employee,
        ),
      );
    }

    case ACTIONS.GET_STATS_FOR_COURSE: {
      if (error) return state;

      return state.setIn(["course", payload.courseId.toString()], Map(payload));
    }

    case ACTIONS.GET_STATS_FOR_SKILL: {
      if (error) return state;

      return state.setIn(["skill", payload.skillId.toString()], Map(payload));
    }

    case ACTIONS.GET_STATS_FOR_COURSES: {
      if (error) return state;

      return state.set(
        "course",
        Map(
          payload.map(courseStats => [
            courseStats.courseId.toString(),
            Map(courseStats),
          ]),
        ),
      );
    }

    case ACTIONS.GET_STATS_FOR_SKILLS: {
      if (error) return state;

      return state.set(
        "skill",
        Map(
          payload.map(skillStats => [
            skillStats.skillId.toString(),
            Map(skillStats),
          ]),
        ),
      );
    }

    case ACTIONS.GET_STATS_FOR_SPECIFICATION: {
      if (error) return state;

      return state.setIn(
        ["specification", payload.specificationId.toString()],
        Map(payload),
      );
    }

    case ACTIONS.GET_STATS_FOR_SPECIFICATIONS: {
      if (error) return state;

      return state.set(
        "specification",
        Map(
          payload.map(specificationStats => [
            specificationStats.specificationId.toString(),
            Map(specificationStats),
          ]),
        ),
      );
    }

    case ACTIONS.GET_STATS_FOR_SERVICE: {
      if (error) return state;

      return state.setIn(
        ["service", payload.serviceId.toString()],
        Map(payload),
      );
    }

    case ACTIONS.GET_STATS_FOR_SERVICES: {
      if (error) return state;

      return state.set(
        "service",
        Map(
          payload.map(serviceStats => [
            serviceStats.serviceId.toString(),
            Map(serviceStats),
          ]),
        ),
      );
    }

    case ACTIONS.GET_STATS_FOR_COLLECTION: {
      if (error) return state;

      return state.setIn(
        ["collection", payload.competencyCollectionId.toString()],
        Map(payload),
      );
    }

    case ACTIONS.GET_STATS_FOR_COLLECTIONS: {
      if (error) return state;

      return state.set(
        "collection",
        Map(
          payload.map(collectionStats => [
            collectionStats.competencyCollectionId.toString(),
            Map(collectionStats),
          ]),
        ),
      );
    }

    case ACTIONS.GET_STATS_FOR_EXPERIENCE_RECORDS_BY_STATUS: {
      if (error) return state;
      return state.set("experienceRecordsFilter", Map(payload));
    }

    case ACTIONS.GET_STATS_FOR_EXPERIENCE_RECORDS_FOR_EMPLOYEE:
    case ACTIONS.GET_STATS_FOR_EXPERIENCE_RECORDS_FOR_VALIDATOR:
    case ACTIONS.GET_STATS_FOR_EXPERIENCE_RECORDS: {
      if (error) return state;

      return state.set(
        "experienceRecord",
        Map(
          payload.map(experienceRecordStats => [
            experienceRecordStats.experienceRecordId.toString(),
            Map(experienceRecordStats),
          ]),
        ),
      );
    }

    case ACTIONS.GET_STATS_FOR_EXPERIENCE_RECORD: {
      if (error) return state;

      return state.setIn(
        ["experienceRecord", payload.experienceRecordId.toString()],
        Map(payload),
      );
    }

    default:
      return state;
  }
};
