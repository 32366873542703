import { compose } from "redux";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";

class GapReportValueCell extends PureComponent {
  render() {
    const { classes, children, ...rest } = this.props;

    return (
      <Grid item {...rest} className={classes.root}>
        {children}
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: theme.gapReport.cellSize,
    minWidth: theme.gapReport.cellSize,
    minHeight: theme.gapReport.cellSize,
    borderLeft: theme.gapReport.defaultBorder,
    borderBottom: theme.gapReport.defaultBorder,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
});

export default compose(withStyles(styles))(GapReportValueCell);
