import { compose } from "redux";
import { List } from "immutable";

import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import React, { useCallback, useState } from "react";

import {
  getCourseTrainingReportCoursesFromState,
  getCoursesByCourseIdFromState,
} from "features/competence.selectors";

import { invokeIfFunction } from "utils/app.util";
import { EMPTY_MAP, EMPTY_LIST, ACTIONS } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import { BASE_FILTERS } from "features/components/GapReport/GapReportFilterBase";
import GapReportFilterBase from "features/components/GapReport/GapReportFilterBase";
import DateFromToPeriodSelection from "features/reports/common/DateFromToPeriodSelection";
import GapReportAutocompleteFilter from "features/components/GapReport/GapReportAutocompleteFilter";

const FILTERS = {
  DATE_RANGE: "dateRange",
  COURSES: "courses",
};

const initialFilter = EMPTY_MAP.set(FILTERS.DATE_RANGE, {})
  .set(FILTERS.COURSES, EMPTY_LIST)
  .set(BASE_FILTERS.DEPARTMENTS, EMPTY_LIST);

const CourseTrainingHistoryReportFilter = ({
  filter,
  courses,
  getGapReport,
  clearGapReport,
  downloadGapReport,
  coursesByDepartment,
}) => {
  const [count, setCount] = useState(0);

  const renderFilter = useCallback(
    ({ setFilter, filter, renderDepartmentFilter }) => (
      <>
        {invokeIfFunction(renderDepartmentFilter)}
        <Grid item xs>
          <GapReportAutocompleteFilter
            label="Courses"
            options={courses}
            getOptionLabel={course => course.get("name")}
            value={filter
              .get(FILTERS.COURSES)
              .map(courseId => courses.get(courseId.toString()))}
            onChange={(_, value) =>
              setFilter(
                FILTERS.COURSES,
                List(value.map(course => course.get("courseId"))),
              )
            }
          />
        </Grid>
        <DateFromToPeriodSelection
          clearPeriod={count}
          onChanged={period => setFilter(FILTERS.DATE_RANGE, period)}
        />
      </>
    ),
    [count, courses],
  );

  const clearReport = useCallback(() => {
    setCount(count + 1);
    clearGapReport();
  }, [clearGapReport, count]);

  return (
    <GapReportFilterBase
      courses={courses} // force rerender when this changes
      getGapReport={getGapReport}
      initialFilter={initialFilter}
      clearGapReport={clearReport}
      renderFilter={renderFilter}
      downloadGapReport={downloadGapReport}
      downloadBtnDisabled={!coursesByDepartment?.size}
    />
  );
};

export default compose(
  connect(state => ({
    courses: getCoursesByCourseIdFromState(state),
    coursesByDepartment: getCourseTrainingReportCoursesFromState(state),
    dataState: getSummarizedDataStatesFromState(
      state,
      ACTIONS.GET_COURSE_TRAINING_HISTORY_REPORT,
    ),
  })),
)(CourseTrainingHistoryReportFilter);
