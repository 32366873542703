import { compose } from "redux";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import React, { useState, useCallback, useEffect } from "react";

import {
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import {
  EXPORT_FILE_FORMAT,
  EMPLOYEE_CV_OPTIONS_FORM,
} from "features/competence.constants";

const FileFormatPicker = ({ input, label, classes }) => {
  const { onChange } = input;
  const [fileType, setFileType] = useState(EXPORT_FILE_FORMAT.WORD);
  const handleRadioChange = useCallback(
    fileFormat => {
      onChange(fileFormat);
      setFileType(fileFormat);
    },
    [onChange],
  );

  useEffect(() => {
    onChange(EXPORT_FILE_FORMAT.WORD);
  }, [onChange]);

  return (
    <>
      <Typography
        classes={{
          root: classNames(classes.radioGroupLabel),
        }}
      >
        {label}
      </Typography>
      <RadioGroup
        value={fileType}
        onChange={onChange}
        name={EMPLOYEE_CV_OPTIONS_FORM.FILE_FORMAT}
      >
        <FormControlLabel
          label="Word"
          control={<Radio color="primary" size="small" />}
          value={EXPORT_FILE_FORMAT.WORD}
          onChange={() => {
            handleRadioChange(EXPORT_FILE_FORMAT.WORD);
          }}
        />
        <FormControlLabel
          label="PDF"
          control={<Radio color="primary" size="small" />}
          value={EXPORT_FILE_FORMAT.PDF}
          onChange={() => {
            handleRadioChange(EXPORT_FILE_FORMAT.PDF);
          }}
        />
      </RadioGroup>
    </>
  );
};
const styles = theme => ({
  radioGroupLabel: {
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
  },
});

export default compose(withStyles(styles))(FileFormatPicker);
