import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import Delete from "@material-ui/icons/Delete";
import { Tooltip, IconButton, Grid } from "@material-ui/core";

import {
  getCourseTypes,
  editCourseTypeOnSubmit,
  initializeEditCourseType,
  createCourseTypeOnSubmit,
  deleteCourseTypeAskConfirmation,
} from "features/competence.actions";

import { ACTIONS, ICONS } from "app/app.constants";
import CrudBasePage from "app/components/CrudBasePage";
import { COURSE_TYPE_FORM } from "features/competence.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import { getCourseTypesFromState } from "features/competence.selectors";
import CourseTypeForm from "features/settings/components/CourseTypeForm";

class SettingsCourseTypesContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad } = this.props;
    dispatchOnLoad();
  }

  columns = [
    {
      xs: true,
      title: "Name",
      getSortProperty: item => item.get("name"),
    },
  ];

  actions = [
    {
      getValue: item => {
        const isCustom = item.get("isCustom");

        return (
          <Tooltip title={isCustom ? "You cannot delete this item" : "Delete"}>
            <Grid container>
              <IconButton
                onClick={() => this.props.dispatchDeleteCourseType(item)}
                disabled={isCustom}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Grid>
          </Tooltip>
        );
      },
    },
  ];

  render() {
    const {
      dataState,
      breadcrumb,
      courseTypes,
      dispatchEditCourseTypeOnSubmit,
      dispatchCreateCourseTypeOnSubmit,
      dispatchInitializeEditCourseType,
    } = this.props;

    return (
      <CrudBasePage
        displayAddButton
        displaySearchField
        Icon={ICONS.COURSE}
        items={courseTypes.toList()}
        title={breadcrumb}
        dataState={dataState}
        CreateEntityFormComponent={CourseTypeForm}
        EditEntityFormComponent={CourseTypeForm}
        createEntityForm={{
          form: COURSE_TYPE_FORM.ID,
        }}
        editEntityForm={{
          form: COURSE_TYPE_FORM.ID,
        }}
        CreateModalProps={{
          submitText: "Add",
          title: "Add Course Type",
          onSubmit: dispatchCreateCourseTypeOnSubmit,
        }}
        EditModalProps={{
          submitText: "Save",
          title: "Edit Course Type",
          formTitleField: COURSE_TYPE_FORM.NAME,
          onSubmit: dispatchEditCourseTypeOnSubmit,
        }}
        SortableListProps={{
          actions: this.actions,
          columns: this.columns,
          getKey: item => item.get("courseTypeId"),
          onRowClick: dispatchInitializeEditCourseType,
        }}
      />
    );
  }
}

export default compose(
  connect(
    state => ({
      courseTypes: getCourseTypesFromState(state),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_COURSE_TYPES,
        ACTIONS.DELETE_COURSE_TYPE,
      ),
    }),
    {
      dispatchOnLoad: getCourseTypes,
      dispatchEditCourseTypeOnSubmit: editCourseTypeOnSubmit,
      dispatchDeleteCourseType: deleteCourseTypeAskConfirmation,
      dispatchCreateCourseTypeOnSubmit: createCourseTypeOnSubmit,
      dispatchInitializeEditCourseType: initializeEditCourseType,
    },
  ),
)(SettingsCourseTypesContainer);
