import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { useEffect, useMemo, useCallback } from "react";
import Delete from "@material-ui/icons/Delete";
import { Tooltip, IconButton, Grid } from "@material-ui/core";

import { useModal } from "altus-modal";
import { MultiSelectModal } from "altus-ui-components";

import {
  getEmployees,
  roleEmployeesOnUnload,
  getRoleEmployeesByRole,
  getStatsForRoleMembers,
  createRoleEmployeeOnSubmit,
  getAvailableEmployeeForRole,
  initializeCreateRoleEmployee,
  deleteRoleEmployeeAskConfirmation,
} from "features/competence.actions";

import {
  getRoleFromState,
  getEmployeeFromState,
  getRoleEmployeesFromState,
  getAvailableEmployeesForRoleFromState,
} from "features/competence.selectors";

import {
  getActionDataStateFromState,
  getSummarizedDataStatesFromState,
} from "app/app.selectors";

import CrudBasePage from "app/components/CrudBasePage";
import EmployeeAvatar from "app/components/EmployeeAvatar";
import { SORT_DIRECTION, ACTIONS } from "app/app.constants";

import { CREATE_ROLE_EMPLOYEE_FORM } from "features/competence.constants";
import EmployeeRoleProgressBar from "features/components/EmployeeRoleProgressBar";

const getKey = roleEmployee => roleEmployee.get("employeeId");
const getEmployeeName = employee => employee.get("displayName");
const renderIcon = roleEmployee => (
  <EmployeeAvatar
    employeeDisplayName={roleEmployee.getIn(["employee", "displayName"])}
  />
);

const RoleMembersContainer = ({
  roleId,
  breadcrumb,
  dataState,
  roleEmployees,
  dispatchOnUnload,
  availableEmployees,
  dispatchDeleteRoleEmployee,
  dispatchGetStatsForRoleMembers,
  dispatchGetRoleEmployeesByRole,
  getAvailableEmployeesDataState,
  dispatchCreateRoleEmployeeOnSubmit,
  dispatchGetAvailableEmployeesForRole,
}) => {
  const [isOpen, toggleModal] = useModal(CREATE_ROLE_EMPLOYEE_FORM.ID);

  useEffect(() => {
    dispatchGetStatsForRoleMembers(roleId);
    dispatchGetRoleEmployeesByRole(roleId);

    return () => dispatchOnUnload();
  }, [
    roleId,
    dispatchOnUnload,
    dispatchGetRoleEmployeesByRole,
    dispatchGetStatsForRoleMembers,
  ]);

  useEffect(() => {
    if (isOpen) {
      dispatchGetAvailableEmployeesForRole(roleId);
    }
  }, [roleId, isOpen, dispatchGetAvailableEmployeesForRole]);

  const columns = useMemo(
    () => [
      {
        xs: 1,
        title: "#",
        getSortProperty: item => item.getIn(["employee", "employeeNumber"]),
      },
      {
        xs: true,
        title: "First Name",
        getSortProperty: item => item.getIn(["employee", "firstName"]),
      },
      {
        xs: true,
        title: "Last Name",
        getSortProperty: item => item.getIn(["employee", "lastName"]),
      },
      {
        xs: true,
        filter: {
          defaultText: "All positions",
        },
        title: "Position",
        getSortProperty: item => item.getIn(["employee", "position"]),
      },
      {
        xs: true,
        filter: {
          defaultText: "All departments",
        },
        title: "Department",
        getSortProperty: item => item.getIn(["employee", "departmentName"]),
      },
      {
        xs: 2,
        component: Grid,
        getValue: item => (
          <EmployeeRoleProgressBar
            roleId={item.get("roleId")}
            employeeId={item.get("employeeId")}
          />
        ),
      },
    ],
    [],
  );

  const actions = useMemo(
    () => [
      {
        getValue: item => (
          <Tooltip title="Delete">
            <IconButton
              onClick={() =>
                dispatchDeleteRoleEmployee(roleId, item.get("employeeId"))
              }
            >
              <Delete />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    [roleId, dispatchDeleteRoleEmployee],
  );

  const addEmployeesSubmit = useCallback(
    selectedEmployees => {
      dispatchCreateRoleEmployeeOnSubmit(
        roleId,
        selectedEmployees.map(employee => employee.get("employeeId")).toArray(),
      ).then(toggleModal);
    },
    [roleId, toggleModal, dispatchCreateRoleEmployeeOnSubmit],
  );

  return (
    <>
      <CrudBasePage
        Icon={null}
        title={breadcrumb}
        displayAddButton
        displaySearchField
        items={roleEmployees}
        dataState={dataState}
        addButtonOnClick={toggleModal}
        SortableListProps={{
          // selectable: true, // TODO after multiple delete support
          actions: actions,
          columns: columns,
          renderIcon: renderIcon,
          // listActions: this.listActions,// TODO after multiple delete support
          getKey: getKey,
          defaultSortDirection: SORT_DIRECTION.ASC,
          defaultSortColumn: columns.findIndex(
            item => item.title === "Last Name",
          ),
          createSortableListRowProps: item => ({
            component: Link,
            to: `/hr-portal/employees/${item.get("employeeId")}`,
          }),
        }}
      />
      <MultiSelectModal
        open={isOpen && !getAvailableEmployeesDataState.isLoading()}
        displayChips
        onClose={toggleModal}
        getName={getEmployeeName}
        items={availableEmployees}
        title="Add Members"
        onSubmit={addEmployeesSubmit}
      />
    </>
  );
};

export default compose(
  connect(
    (state, { roleId }) => ({
      roleId: roleId,
      role: getRoleFromState(state, roleId),
      getAvailableEmployeesDataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_AVAILABLE_EMPLOYEES_FOR_ROLE,
      ),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_ROLE_EMPLOYEES,
        ACTIONS.DELETE_ROLE_EMPLOYEE,
        ACTIONS.GET_AVAILABLE_EMPLOYEES_FOR_ROLE,
      ),
      availableEmployees: getAvailableEmployeesForRoleFromState(state, roleId),
      roleEmployees: getRoleEmployeesFromState(
        state,
        roleId,
      ).map(roleEmployee =>
        roleEmployee.set(
          "employee",
          getEmployeeFromState(state, roleEmployee.get("employeeId")),
        ),
      ),
    }),
    {
      dispatchGetAllEmployees: getEmployees,
      dispatchGetAvailableEmployeesForRole: getAvailableEmployeeForRole,
      dispatchOnUnload: roleEmployeesOnUnload,
      dispatchGetRoleEmployeesByRole: getRoleEmployeesByRole,
      dispatchDeleteRoleEmployee: deleteRoleEmployeeAskConfirmation,
      dispatchCreateRoleEmployeeOnSubmit: createRoleEmployeeOnSubmit,
      dispatchInitializeCreateRoleEmployee: initializeCreateRoleEmployee,
      dispatchGetStatsForRoleMembers: getStatsForRoleMembers,
    },
  ),
)(RoleMembersContainer);
