import React from "react";
import { compose } from "redux";
import range from "lodash/range";
import classNames from "classnames";
import { Typography, Grid, withStyles } from "@material-ui/core";

import CollectionConditionMatrixCell from "features/components/CollectionConditionMatrix/CollectionConditionMatrixCell";

const CollectionConditionMatrixRow = ({
  rowIndex,
  columns,
  classes,
  ...rest
}) => (
  <Grid container wrap="nowrap">
    {!!columns && (
      <Grid
        item
        container
        justify="center"
        alignItems="center"
        className={classNames(classes.cell, classes.rowIndexCell)}
      >
        <Typography variant="body2">
          <b>{rowIndex + 1}</b>
        </Typography>
      </Grid>
    )}
    {range(columns).map(columnIndex => (
      <CollectionConditionMatrixCell
        key={columnIndex}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        {...rest}
      />
    ))}
  </Grid>
);

const styles = theme => ({
  cell: {
    overflow: "hidden",
    height: theme.altus.largeRow.height,
    background: theme.palette.common.white,
    borderLeft: theme.gapReport.defaultBorder,
    borderBottom: theme.gapReport.defaultBorder,
    "&:last-child": {
      borderRight: theme.gapReport.defaultBorder,
    },
  },
  rowIndexCell: {
    width: theme.altus.largeRow.height,
  },
});

export default compose(withStyles(styles))(CollectionConditionMatrixRow);
