import { compose } from "redux";
import { List } from "immutable";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";

import {
  getGapReportEmployeesByDepartmentFromState,
  getSpecificationsBySpecificationIdFromState,
} from "features/competence.selectors";

import { invokeIfFunction } from "utils/app.util";
import { EMPTY_MAP, EMPTY_LIST } from "app/app.constants";
import { BASE_FILTERS } from "features/components/GapReport/GapReportFilterBase";
import GapReportFilterBase from "features/components/GapReport/GapReportFilterBase";
import GapReportAutocompleteFilter from "features/components/GapReport/GapReportAutocompleteFilter";

const FILTERS = {
  SPECIFICATIONS: "specifications",
};

const initialFilter = EMPTY_MAP.set(FILTERS.SPECIFICATIONS, EMPTY_LIST)
  .set(BASE_FILTERS.STATUS, EMPTY_LIST)
  .set(BASE_FILTERS.EMPLOYEES, EMPTY_LIST)
  .set(BASE_FILTERS.DEPARTMENTS, EMPTY_LIST);

class SpecificationGapReportFilter extends PureComponent {
  renderFilter = ({
    filter,
    setFilter,
    renderStatusFilter,
    renderEmployeeFilter,
    renderDepartmentFilter,
  }) => {
    const { specifications } = this.props;

    return (
      <>
        {invokeIfFunction(renderDepartmentFilter)}
        {invokeIfFunction(renderEmployeeFilter)}
        <Grid item xs>
          <GapReportAutocompleteFilter
            label="Specifications"
            options={specifications}
            getOptionLabel={specification => specification.get("name")}
            value={filter
              .get(FILTERS.SPECIFICATIONS)
              .map(specificationId =>
                specifications.get(specificationId.toString()),
              )}
            onChange={(_, value) =>
              setFilter(
                FILTERS.SPECIFICATIONS,
                List(
                  value.map(specification =>
                    specification.get("specificationId"),
                  ),
                ),
              )
            }
          />
        </Grid>
        {invokeIfFunction(renderStatusFilter)}
      </>
    );
  };

  render() {
    const {
      getGapReport,
      clearGapReport,
      specifications,
      downloadGapReport,
      employeesByDepartment,
    } = this.props;

    return (
      <GapReportFilterBase
        getGapReport={getGapReport}
        initialFilter={initialFilter}
        specifications={specifications} // force rerender when this changes
        clearGapReport={clearGapReport}
        renderFilter={this.renderFilter}
        downloadGapReport={downloadGapReport}
        downloadBtnDisabled={!employeesByDepartment.size}
      />
    );
  }
}

export default compose(
  connect(state => ({
    specifications: getSpecificationsBySpecificationIdFromState(state),
    employeesByDepartment: getGapReportEmployeesByDepartmentFromState(state),
  })),
)(SpecificationGapReportFilter);
