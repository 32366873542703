import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import { withStyles, Grid } from "@material-ui/core";

import {
  rejectExperienceRecord,
  approveExperienceRecord,
  undoExperienceRecordApproval,
  experienceRecordsForValidatorOnLoad,
} from "features/competence.actions";

import {
  getExperienceRecordsForValidatorFromState,
  getExperienceRecordsStatsFromState,
} from "features/competence.selectors";

import routePaths from "app/routePaths";
import { formatDate } from "utils/format.util";
import CrudBasePage from "app/components/CrudBasePage";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import ApproveButton from "features/hr/approval/components/ApproveButton";
import { ACTIONS, Format, ICONS, SORT_DIRECTION } from "app/app.constants";

const ExperienceRecordsValidatorContainer = ({
  dataState,
  employeeId,
  breadcrumb,
  dispatchOnLoad,
  undoExperience,
  experienceStats,
  rejectExprience,
  approveExperience,
  experienceRecords,
}) => {
  useEffect(() => {
    dispatchOnLoad(employeeId);
  }, [employeeId, dispatchOnLoad]);

  const columns = useMemo(
    () => [
      {
        xs: true,
        title: "Job",
        getSortProperty: item => item.get("jobId"),
      },
      {
        xs: true,
        title: "Employee",
        getSortProperty: item => item.get("employeeDisplayName"),
      },
      {
        xs: true,
        title: "Client",
        getSortProperty: item => item.get("experienceClientName"),
      },
      {
        xs: true,
        title: "Location",
        getSortProperty: item => item.get("locationName"),
      },
      {
        xs: true,
        title: "Discipline",
        getSortProperty: item => item.get("experienceDisciplineName"),
      },
      {
        xs: true,
        title: "Performed as",
        getSortProperty: item => item.get("performedAs"),
      },
      {
        xs: true,
        title: "From Date",
        getSortProperty: item => formatDate(item.get("from"), Format.date),
      },
      {
        xs: true,
        title: "To Date",
        getSortProperty: item => formatDate(item.get("to"), Format.date),
      },
      {
        xs: true,
        title: "XP",
        getSortProperty: item =>
          experienceStats?.getIn(
            [
              item.get("experienceRecordId").toString(),
              "experiencePointsCount",
            ],
            "-",
          ),
      },
      {
        xs: 2,
        title: "",
        component: Grid,
        getValue: item => (
          <ApproveButton
            item={item}
            id={item.get("experienceRecordId")}
            onApprove={() => {
              approveExperience(employeeId, item.get("experienceRecordId"));
            }}
            onReject={() => {
              rejectExprience(employeeId, item.get("experienceRecordId"));
            }}
            onUndo={() =>
              undoExperience(employeeId, item.get("experienceRecordId"))
            }
          />
        ),
      },
      {
        xs: true,
        title: "Status",
        component: Grid,
        container: true,
        justify: "flex-end",
        getSortProperty: item => item.get("competencyStatus"),
        getValue: item => (
          <CompetencyStatusChip status={item.get("competencyStatus")} />
        ),
      },
    ],
    [
      experienceStats,
      employeeId,
      approveExperience,
      rejectExprience,
      undoExperience,
    ],
  );

  return (
    <CrudBasePage
      items={experienceRecords}
      title={breadcrumb}
      displaySearchField
      Icon={ICONS.EXPERIENCES}
      dataState={dataState}
      SortableListProps={{
        columns: columns,
        defaultSortDirection: SORT_DIRECTION.ASC,
        defaultSortColumn: columns.findIndex(item => item.title === "Status"),
        displayNumberOfItems: true,
        noResultsMessage: "Nothing to approve",
        getKey: item => item.get("experienceRecordId"),
        createSortableListRowProps: item => ({
          component: Link,
          to: `${routePaths.myPage}/approvals/${item.get(
            "experienceRecordId",
          )}/employee/${item.get("employeeId")}`,
        }),
      }}
    />
  );
};

const styles = theme => ({
  iconButtonRoot: {
    padding: theme.spacing(1),
  },
});

export default compose(
  connect(
    state => ({
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_PENDING_EXPERIENCE_RECORDS_FOR_VALIDATOR,
      ),
      experienceRecords: getExperienceRecordsForValidatorFromState(state),
      experienceStats: getExperienceRecordsStatsFromState(state),
    }),
    {
      dispatchOnLoad: experienceRecordsForValidatorOnLoad,
      rejectExprience: rejectExperienceRecord,
      approveExperience: approveExperienceRecord,
      undoExperience: undoExperienceRecordApproval,
    },
  ),
  withStyles(styles),
)(ExperienceRecordsValidatorContainer);
