import { Map, OrderedMap, List } from "immutable";

import { ACTIONS, EMPTY_MAP } from "app/app.constants";

import {
  RoleMapper,
  SkillMapper,
  CourseMapper,
  RoleSkillMapper,
  RoleCourseMapper,
  EmployeeRoleMapper,
  EmployeeSkillMapper,
} from "app/app.mappers";

import employeeCourseMappers from "app/mappers/employeeCourse.mappers";

const initialState = Map({
  role: EMPTY_MAP,
  course: EMPTY_MAP,
  skill: EMPTY_MAP,
  employeeCourse: EMPTY_MAP,
  employeeSkill: EMPTY_MAP,
  employeeRole: EMPTY_MAP,
  roleCourse: EMPTY_MAP,
  roleSkill: EMPTY_MAP,
});

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_EMPLOYEE_ROLE_GAP_REPORT: {
      return initialState;
    }

    case ACTIONS.GET_EMPLOYEE_ROLE_GAP_REPORT: {
      if (error) return state;

      return state
        .set(
          "role",
          OrderedMap(
            payload.roles.map(role => [
              role.roleId.toString(),
              RoleMapper.from(role),
            ]),
          ),
        )
        .set(
          "roleCourse",
          List(payload.roleCourses)
            .map(RoleCourseMapper.from)
            .groupBy(roleCourse => roleCourse.get("roleId").toString())
            .map(coursesByRole =>
              Map(
                coursesByRole.map(roleCourse => [
                  roleCourse.get("courseId").toString(),
                  CourseMapper.from(roleCourse),
                ]),
              ),
            ),
        )
        .set(
          "roleSkill",
          List(payload.roleSkills)
            .map(RoleSkillMapper.from)
            .groupBy(roleSkill => roleSkill.get("roleId").toString())
            .map(skillsByRole =>
              Map(
                skillsByRole.map(roleSkill => [
                  roleSkill.get("skillId").toString(),
                  SkillMapper.from(roleSkill),
                ]),
              ),
            ),
        )
        .set(
          "course",
          OrderedMap(
            List(payload.roleCourses)
              .groupBy(roleCourse => roleCourse.courseId)
              .entrySeq()
              .map(([courseId, roleCourses]) => [
                courseId.toString(),
                CourseMapper.from(roleCourses.first()),
              ]),
          ),
        )
        .set(
          "skill",
          OrderedMap(
            List(payload.roleSkills)
              .groupBy(roleSkill => roleSkill.skillId)
              .entrySeq()
              .map(([skillId, roleSkills]) => [
                skillId.toString(),
                SkillMapper.from(roleSkills.first()),
              ]),
          ),
        )
        .set(
          "employeeCourse",
          OrderedMap(
            payload.employeeCourses.map(employeeCourse => [
              employeeCourse.courseId.toString(),
              employeeCourseMappers.EmployeeCourse.from(employeeCourse),
            ]),
          ),
        )
        .set(
          "employeeSkill",
          OrderedMap(
            payload.employeeSkills.map(employeeSkill => [
              employeeSkill.skillId.toString(),
              EmployeeSkillMapper.from(employeeSkill),
            ]),
          ),
        )
        .set(
          "employeeRole",
          OrderedMap(
            payload.roleEmployees.map(employeeRole => [
              employeeRole.roleId.toString(),
              EmployeeRoleMapper.from(employeeRole),
            ]),
          ),
        );
    }

    default:
      return state;
  }
};
