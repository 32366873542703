import { compose } from "redux";
import { connect } from "react-redux";
import { Field } from "redux-form/immutable";
import React, { PureComponent } from "react";
import { Grid, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import {
  getSkillTypesFromState,
  getSkillGroupsFromState,
} from "features/competence.selectors";

import TextField from "app/components/form/TextField";
import { SKILL_FORM } from "features/competence.constants";
import { required, greaterThanOrEqualTo } from "utils/validation.util";
import ValidityPeriodField from "features/components/ValidityPeriodField";
import CountryMultiselectField from "app/components/form/CountryMultiselectField";

class SkillForm extends PureComponent {
  greaterThanOrEqualTo0 = greaterThanOrEqualTo(0);
  greaterThanOrEqualTo1 = greaterThanOrEqualTo(1);

  render() {
    const { handleSubmit, skillGroups, skillTypes, classes } = this.props;

    return (
      <form id={SKILL_FORM.ID} onSubmit={handleSubmit}>
        <Grid container direction="column">
          <Grid item>
            <Field
              autoFocus
              label="Skill Name"
              component={TextField}
              name={SKILL_FORM.NAME}
              required
              validate={[required]}
            />
          </Grid>
          <Grid item container>
            <Field
              rows={5}
              multiline
              label={
                <Field
                  name={SKILL_FORM.DESCRIPTION}
                  component={({ input }) =>
                    input.value
                      ? `Description (${1000 -
                          input.value.length} characters remaining)`
                      : "Description"
                  }
                />
              }
              component={TextField}
              name={SKILL_FORM.DESCRIPTION}
              inputProps={{
                maxLength: 1000,
              }}
            />
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs={6}>
              <Field
                select
                label="Skill Type"
                component={TextField}
                name={SKILL_FORM.SKILL_TYPE}
                required
                validate={[required]}
              >
                {skillTypes.map(skillType => (
                  <MenuItem
                    key={skillType.get("skillTypeId")}
                    value={skillType.get("skillTypeId")}
                  >
                    {skillType.get("name")}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
            <Grid item xs={6}>
              <Field
                select
                label="Skill Group"
                component={TextField}
                name={SKILL_FORM.SKILL_GROUP}
              >
                <MenuItem value="">None</MenuItem>
                {skillGroups.map(skillGroup => (
                  <MenuItem
                    key={skillGroup.get("skillGroupId")}
                    value={skillGroup.get("skillGroupId")}
                  >
                    {skillGroup.get("name")}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs={6} className={classes.numberInputChildren}>
              <Field
                label="No. of Required Evidence"
                type="number"
                component={TextField}
                name={SKILL_FORM.REQUIRED_EVIDENCE_COUNT}
                validate={[this.greaterThanOrEqualTo0]}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name={SKILL_FORM.VALIDITY}
                component={ValidityPeriodField}
                format={null}
                validation={[this.greaterThanOrEqualTo1]}
              />
            </Grid>
          </Grid>
          <Grid>
            <Field
              name={SKILL_FORM.COUNTRIES}
              component={CountryMultiselectField}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

const styles = {
  numberInputChildren: {
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "inner-spin-button",
    },
    display: "flex",
    alignItems: "flex-end",
  },
};

export default compose(
  connect(state => ({
    skillGroups: getSkillGroupsFromState(state),
    skillTypes: getSkillTypesFromState(state),
  })),
)(withStyles(styles)(SkillForm));
