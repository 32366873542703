import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import React, { PureComponent } from "react";

import {
  Grid,
  Button,
  Tooltip,
  Typography,
  IconButton,
} from "@material-ui/core";

import {
  getEmployeeRoleCourseList,
  deleteEmployeeCourseAskConfirmation,
  createEmployeeCourseAttendanceOnEnter,
  previewOrSaveEmployeeCourseAttendanceFile,
  initializeAddEmployeeCourseAttendanceModal,
  createEmployeeCourseAttendanceFormOnSubmit,
} from "features/competence.actions";

import {
  ADD_EMPLOYEE_COURSE_ATTENDANCE_MODAL_ID,
  CREATE_EMPLOYEE_COURSE_ATTENDANCE_MODAL_ID,
} from "features/competence.constants";

import { formatDate } from "utils/format.util";
import CrudBasePage from "app/components/CrudBasePage";
import { resolveEmployeeCourseFile } from "utils/app.util";
import withToggleModal from "app/components/withToggleModal";
import DownloadFileColumn from "app/components/DownloadFileColumn";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import { ACTIONS, ICONS, EMPTY_LIST, Format } from "app/app.constants";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";
import { getSimpleEmployeeRolesFromState } from "features/competence.selectors";
import EmployeeRoleCourseList from "features/components/EmployeeRoleCourseList";
import CreateEmployeeCourseModal from "features/components/CreateEmployeeCourseModal";
import EmployeeMissingRoleCourseList from "features/components/EmployeeMissingRoleCourseList";
import AddEmployeeCourseCertificateModal from "features/components/AddEmployeeCourseCertificateModal";

const AddAttendanceButton = ({ onClick, ...rest }) => (
  <Button
    size="small"
    color="primary"
    variant="contained"
    {...rest}
    onClick={event => {
      event.preventDefault();
      event.stopPropagation();

      onClick();
    }}
  >
    <Typography noWrap variant="caption">
      + Attendance
    </Typography>
  </Button>
);

class EmployeeCoursesBasePage extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad, employeeId } = this.props;

    dispatchOnLoad(employeeId);
  }

  columns = [
    {
      xs: 4,
      title: "Course Name",
      getSortProperty: item => item.getIn(["courseName"]),
    },
    {
      xs: true,
      filter: {
        label: "Group",
        defaultText: "All groups",
      },
      title: "Group",
      getSortProperty: item => item.get("courseGroupName"),
    },
    {
      filter: {
        defaultText: "All types",
      },
      xs: true,
      title: "Type",
      getSortProperty: item => item.getIn(["courseTypeName"]),
    },
    {
      xs: true,
      title: "Completed",
      getSortProperty: item => formatDate(item.get("endDate"), Format.date),
    },
    {
      xs: true,
      title: "Expires",
      getSortProperty: item =>
        formatDate(item.get("expirationDate"), Format.date),
    },
    {
      xs: true,
      component: Grid,
      title: "Certificate",
      getSortProperty: item => {
        const file = resolveEmployeeCourseFile(item);

        return file ? file.name : "";
      },
      getValue: item => {
        const file = resolveEmployeeCourseFile(item);

        return (
          <DownloadFileColumn
            file={file}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();

              this.props.dispatchPreviewOrSaveEmployeeCourseAttendanceFile(
                item.get("employeeId"),
                item.get("courseId"),
                item.get("bestAttendanceId"),
                file,
              );
            }}
          />
        );
      },
    },
    {
      title: "",
      xs: true,
      component: Grid,
      getValue: item => {
        if (item.get("isCustom")) {
          return (
            <Tooltip title="Can't add attendances to a Custom course.">
              <Grid container>
                <AddAttendanceButton disabled />
              </Grid>
            </Tooltip>
          );
        }

        return (
          <AddAttendanceButton
            onClick={() => {
              const {
                dispatchInitializeAddEmployeeCourseAttendanceModal,
              } = this.props;

              dispatchInitializeAddEmployeeCourseAttendanceModal(
                item.get("employeeId"),
                item.get("courseId"),
                item.get("courseName"),
                this.toggleAddEmployeeCourseAttendanceModal,
              );
            }}
          />
        );
      },
    },
    {
      xs: true,
      title: "",
      component: Grid,
      container: true,
      justify: "flex-end",
      getSortProperty: item => item.get("competencyStatus"),
      getValue: item => (
        <CompetencyStatusChip status={item.get("competencyStatus")} />
      ),
      filter: {
        label: "Status",
        defaultText: "All",
        getFilterText: filter => statusToTooltipText(filter),
      },
    },
  ];

  actions = [
    {
      getValue: item => (
        <Tooltip title="Delete">
          <IconButton
            onClick={() => this.props.dispatchDeleteEmployeeCourse(item)}
          >
            <ICONS.DELETE fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  getKey = employeeCourse => employeeCourse.get("courseId");

  renderIcon = employeeCourse =>
    employeeCourse.get("isOverridden") ? (
      <ICONS.COURSE_OVERRIDEN color="primary" />
    ) : (
      <ICONS.COURSE color="primary" />
    );

  toggleCreateModal = () =>
    this.props.dispatchToggleModal({
      modalId: CREATE_EMPLOYEE_COURSE_ATTENDANCE_MODAL_ID,
    });

  toggleAddEmployeeCourseAttendanceModal = () =>
    this.props.dispatchToggleModal({
      modalId: ADD_EMPLOYEE_COURSE_ATTENDANCE_MODAL_ID,
    });

  render() {
    const {
      Icon,
      path,
      title,
      employeeId,
      employeeRoles,
      getRoleCoursesDataState,
      dispatchCreateEmployeeCourseAttendanceOnEnter,
      dispatchCreateEmployeeCourseAttendanceFormOnSubmit,
    } = this.props;

    return (
      <CrudBasePage
        Icon={Icon}
        title={title}
        displayAddButton
        displaySearchField
        dataState={getRoleCoursesDataState}
        addButtonOnClick={this.toggleCreateModal}
      >
        <Grid container>
          {employeeRoles.map(employeeRole => {
            const roleId = employeeRole.get("roleId");

            return (
              <EmployeeRoleCourseList
                roleId={roleId}
                getKey={this.getKey}
                renderIcon={this.renderIcon}
                columns={this.columns}
                employeeId={employeeId}
                key={employeeRole.get("roleEmployeeId")}
                title={employeeRole.get("roleName")}
                createSortableListRowProps={item => ({
                  component: Link,
                  to: `${generatePath(path, { employeeId })}/${item.get(
                    "courseId",
                  )}`,
                })}
              />
            );
          })}
          <EmployeeMissingRoleCourseList
            getKey={this.getKey}
            renderIcon={this.renderIcon}
            title="Other courses"
            columns={this.columns}
            actions={this.actions}
            employeeId={employeeId}
            createSortableListRowProps={item => ({
              component: Link,
              to: `${generatePath(path, { employeeId })}/${item.get(
                "courseId",
              )}`,
            })}
          />
          <CreateEmployeeCourseModal
            employeeId={employeeId}
            onEnter={dispatchCreateEmployeeCourseAttendanceOnEnter}
            onSubmit={dispatchCreateEmployeeCourseAttendanceFormOnSubmit(
              employeeId,
            )}
          />
          <AddEmployeeCourseCertificateModal employeeId={employeeId} />
        </Grid>
      </CrudBasePage>
    );
  }
}

EmployeeCoursesBasePage.defaultProps = {
  employeeRoles: EMPTY_LIST,
};

export default compose(
  connect(
    (state, { employeeId }) => ({
      employeeRoles: getSimpleEmployeeRolesFromState(state, employeeId),
      getRoleCoursesDataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_EMPLOYEE_COURSES_BY_ROLE,
        ACTIONS.GET_COURSE,
        ACTIONS.GET_EMPLOYEE_COURSE,
      ),
    }),
    {
      dispatchOnLoad: getEmployeeRoleCourseList,
      dispatchDeleteEmployeeCourse: deleteEmployeeCourseAskConfirmation,
      dispatchCreateEmployeeCourseAttendanceOnEnter: createEmployeeCourseAttendanceOnEnter,
      dispatchPreviewOrSaveEmployeeCourseAttendanceFile: previewOrSaveEmployeeCourseAttendanceFile,
      dispatchCreateEmployeeCourseAttendanceFormOnSubmit: createEmployeeCourseAttendanceFormOnSubmit,
      dispatchInitializeAddEmployeeCourseAttendanceModal: initializeAddEmployeeCourseAttendanceModal,
    },
  ),
  withToggleModal,
)(EmployeeCoursesBasePage);
