import { compose } from "redux";
import { List } from "immutable";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Grid } from "@material-ui/core";

import {
  getCoursesByCourseIdFromState,
  getRolesGroupedByRoleIdFromState,
  getSkillsGroupedBySkillIdFromState,
  getGapReportEmployeesByDepartmentFromState,
} from "features/competence.selectors";

import { invokeIfFunction } from "utils/app.util";
import { getFeatureFlagStatusFromState } from "app/app.selectors";
import { EMPTY_MAP, EMPTY_LIST, FEATURE_FLAG } from "app/app.constants";
import { BASE_FILTERS } from "features/components/GapReport/GapReportFilterBase";
import GapReportFilterBase from "features/components/GapReport/GapReportFilterBase";
import GapReportAutocompleteFilter from "features/components/GapReport/GapReportAutocompleteFilter";

const FILTERS = {
  ROLES: "roles",
  COURSES: "courses",
  SKILLS: "skills",
};

const initialFilter = EMPTY_MAP.set(BASE_FILTERS.DEPARTMENTS, EMPTY_LIST)
  .set(FILTERS.ROLES, EMPTY_LIST)
  .set(FILTERS.COURSES, EMPTY_LIST)
  .set(FILTERS.SKILLS, EMPTY_LIST)
  .set(BASE_FILTERS.STATUS, EMPTY_LIST)
  .set(BASE_FILTERS.EMPLOYEES, EMPTY_LIST);

class RoleGapReportFilter extends PureComponent {
  renderFilter = ({
    filter,
    setFilter,
    renderStatusFilter,
    renderEmployeeFilter,
    renderDepartmentFilter,
  }) => {
    const {
      rolesByRoleId,
      courses,
      skills,
      isSkillsFeatureFlagEnabled,
    } = this.props;

    return (
      <>
        {invokeIfFunction(renderDepartmentFilter)}
        {invokeIfFunction(renderEmployeeFilter)}
        <Grid item xs>
          <GapReportAutocompleteFilter
            label="Roles"
            options={rolesByRoleId}
            getOptionLabel={role => role.get("name")}
            value={filter
              .get(FILTERS.ROLES)
              .map(roleId => rolesByRoleId.get(roleId.toString()))}
            onChange={(_, value) =>
              setFilter(
                FILTERS.ROLES,
                List(value.map(role => role.get("roleId"))),
              )
            }
          />
        </Grid>
        <Grid item xs>
          <GapReportAutocompleteFilter
            label="Courses"
            options={courses}
            getOptionLabel={courses => courses.get("name")}
            value={filter
              .get(FILTERS.COURSES)
              .map(courseId => courses.get(courseId.toString()))}
            onChange={(_, value) =>
              setFilter(
                FILTERS.COURSES,
                List(value.map(course => course.get("courseId"))),
              )
            }
          />
        </Grid>
        {isSkillsFeatureFlagEnabled && (
          <Grid item xs>
            <GapReportAutocompleteFilter
              label="Skills"
              options={skills}
              getOptionLabel={skills => skills.get("name")}
              value={filter
                .get(FILTERS.SKILLS)
                .map(skillId => skills.get(skillId.toString()))}
              onChange={(_, value) =>
                setFilter(
                  FILTERS.SKILLS,
                  List(value.map(skill => skill.get("skillId"))),
                )
              }
            />
          </Grid>
        )}
        {invokeIfFunction(renderStatusFilter)}
      </>
    );
  };

  render() {
    const {
      courses,
      getGapReport,
      rolesByRoleId,
      clearGapReport,
      employeesByDepartment,
      downloadRoleGapReport,
    } = this.props;

    return (
      <GapReportFilterBase
        courses={courses} // force rerender when this changes
        getGapReport={getGapReport}
        rolesByRoleId={rolesByRoleId} // force rerender when this changes
        initialFilter={initialFilter}
        clearGapReport={clearGapReport}
        renderFilter={this.renderFilter}
        downloadGapReport={downloadRoleGapReport}
        downloadBtnDisabled={!employeesByDepartment.size}
      />
    );
  }
}

export default compose(
  connect(state => ({
    courses: getCoursesByCourseIdFromState(state),
    skills: getSkillsGroupedBySkillIdFromState(state),
    rolesByRoleId: getRolesGroupedByRoleIdFromState(state),
    employeesByDepartment: getGapReportEmployeesByDepartmentFromState(state),
    isSkillsFeatureFlagEnabled: getFeatureFlagStatusFromState(
      state,
      FEATURE_FLAG.SKILLS,
    ),
  })),
)(RoleGapReportFilter);
