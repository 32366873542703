import { compose } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";

import { LoadingButton } from "altus-ui-components";

import {
  getCurrentUserFromState,
  getActionDataStateFromState,
} from "app/app.selectors";

import routePaths from "app/routePaths";
import background from "assets/login.jpg";
import { ACTIONS } from "app/app.constants";
import { login } from "features/login/login.actions";

class LoginContainer extends PureComponent {
  render() {
    const { dataState, classes, dispatchLoginPopup, currentUser } = this.props;

    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        {currentUser.get("userId") && <Redirect to={routePaths.root} />}
        <Paper className={classes.paper}>
          <Grid container alignItems="center" spacing={4} direction="column">
            <Grid item>
              <Typography variant="h6">Authentication Required</Typography>
            </Grid>
            <Grid item>
              <Typography>You need to login to continue.</Typography>
            </Grid>
            <Grid item>
              <LoadingButton
                size="large"
                color="primary"
                variant="contained"
                onClick={dispatchLoginPopup}
                loading={dataState.isLoading()}
              >
                Login
              </LoadingButton>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  }
}

const styles = () => ({
  root: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    "&:before": {
      content: "no-open-quote",
      display: "block",
      backgroundImage: `url("${background}")`,
      backgroundPosition: "bottom",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: "100%",
      width: "100%",
      position: "fixed",
      opacity: 0.5,
      filter: "saturate(50%)",
      zIndex: -1,
    },
  },
  paper: {
    padding: 40,
  },
});

export default compose(
  connect(
    state => ({
      currentUser: getCurrentUserFromState(state),
      dataState: getActionDataStateFromState(state, ACTIONS.GET_CURRENT_USER),
    }),
    {
      dispatchLoginPopup: login,
    },
  ),
  withStyles(styles),
)(LoginContainer);
