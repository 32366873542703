import Agent from "infrastructure/agent";

const createRole = role => Agent.post(`/role`, role);

const getRole = roleId => Agent.get(`/role/${roleId}`);

const deleteRole = roleId => Agent.delete(`/role/${roleId}`);
const getRoleWithParents = roleId => Agent.get(`/role/${roleId}/withparents`);
const getRoles = filter => Agent.get(`/role`, filter);
const getMyTeamRoles = () => Agent.get(`/role/myteam`);
const updateRole = (roleId, role) => Agent.put(`/role/${roleId}`, role);

const createRoleCourses = (roleId, roleCourses) =>
  Agent.post(`/role/${roleId}/assign-courses`, roleCourses);
const deleteRoleCourse = (roleId, courseId) =>
  Agent.delete(`/role/${roleId}/course/${courseId}`);
const updateRoleCourse = (roleId, courseId, roleCourse) =>
  Agent.put(`/role/${roleId}/course/${courseId}`, roleCourse);
const getRoleCoursesByRole = roleId =>
  Agent.get(`/role/${roleId}/course/byrole`);
const getAvailableCoursesForRole = roleId =>
  Agent.get(`/role/${roleId}/course/availablecourses`);

const createRoleEmployee = (roleId, roleEmployee) =>
  Agent.post(`/role/${roleId}/employee`, roleEmployee);
const createRoleEmployees = (roleId, roleEmployees) =>
  Agent.post(`/role/${roleId}/assign-employees`, roleEmployees);
const deleteRoleEmployee = (roleId, employeeId) =>
  Agent.delete(`/role/${roleId}/employee/${employeeId}`);
const getRoleEmployees = roleId => Agent.get(`/role/${roleId}/employee`);
const getAvailableRoleEmployees = roleId =>
  Agent.get(`/role/${roleId}/employee/available-employees`);
const getMyTeamRoleEmployees = roleId =>
  Agent.get(`/role/myteam/${roleId}/employee`);

const getRolesByCourse = courseId => Agent.get(`/role/bycourse/${courseId}`);

const createRoleSkills = (roleId, roleSkills) =>
  Agent.post(`/role/${roleId}/skill/assign-skills`, roleSkills);
const deleteRoleSkill = (roleId, skillId) =>
  Agent.delete(`/role/${roleId}/skill/${skillId}`);
const updateRoleSkill = (roleId, skillId, roleSkill) =>
  Agent.put(`/role/${roleId}/skill/${skillId}`, roleSkill);
const getRoleSkillsWithParentsByRole = roleId =>
  Agent.get(`/role/${roleId}/skill/withparents`);
const getRolesBySkill = skillId => Agent.get(`/role/byskill/${skillId}`);
const getAvailableSkillsForRole = roleId =>
  Agent.get(`/role/${roleId}/skill/availableskills`);

export default {
  getRole,
  createRole,
  deleteRole,
  getRoleWithParents,
  getRoles,
  getMyTeamRoles,
  updateRole,

  createRoleCourses,
  deleteRoleCourse,
  getRoleCoursesByRole,
  getAvailableCoursesForRole,
  updateRoleCourse,

  createRoleEmployee,
  createRoleEmployees,
  deleteRoleEmployee,
  getAvailableRoleEmployees,
  getRoleEmployees,
  getMyTeamRoleEmployees,
  getRolesByCourse,

  createRoleSkills,
  deleteRoleSkill,
  updateRoleSkill,
  getRoleSkillsWithParentsByRole,
  getRolesBySkill,
  getAvailableSkillsForRole,
};
