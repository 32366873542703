import React, { PureComponent } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";

class CheckboxField extends PureComponent {
  render() {
    const { label, name, input } = this.props;
    const { value, onChange } = input;

    return (
      <FormControlLabel
        name={name}
        label={label}
        onChange={onChange}
        labelPlacement="end"
        control={<Checkbox checked={!!value} color="primary" />}
      />
    );
  }
}

export default CheckboxField;
