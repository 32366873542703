import { compose } from "redux";
import { connect } from "react-redux";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import React, { useEffect, useMemo, useCallback } from "react";

import { useModal } from "altus-modal";
import { MultiSelectModal } from "altus-ui-components";

import {
  getActionDataStateFromState,
  getSummarizedDataStatesFromState,
} from "app/app.selectors";

import {
  roleSkillsOnUnload,
  createRoleSkillsOnSubmit,
  getAvailableSkillsForRole,
  getRoleSkillsWithParentsByRole,
  deleteRoleSkillAskConfirmation,
  updateRoleSkillAndGetRoleStats,
} from "features/competence.actions";

import {
  getRolesFromState,
  getAvailableSkillsForRoleFromState,
} from "features/competence.selectors";

import SwitchField from "app/components/SwitchField";
import CrudBasePage from "app/components/CrudBasePage";
import { ACTIONS, ICONS, CRITICALITY } from "app/app.constants";
import { CREATE_ROLE_SKILL_FORM } from "features/competence.constants";
import RoleRoleSkillList from "features/role/components/RoleRoleSkillList";

const getSkillName = skill => skill.get("name");

const getKey = roleSkill => {
  return roleSkill.get("roleSkillId");
};

const RoleSkillsContainer = ({
  roles,
  roleId,
  dataState,
  breadcrumb,
  availableSkills,
  dispatchOnUnload,
  dispatchGetRoleSkills,
  dispatchDeleteRoleSkill,
  getAvailableSkillsDataState,
  dispatchCreateRoleSkillOnSubmit,
  dispatchGetAvailableSkillsForRole,
  dispatchUpdateRoleSkillAndGetRoleStats,
}) => {
  const [isOpen, toggleModal] = useModal(CREATE_ROLE_SKILL_FORM.ID);

  useEffect(() => {
    dispatchGetRoleSkills(roleId);

    return () => dispatchOnUnload();
  }, [roleId, dispatchOnUnload, dispatchGetRoleSkills]);

  useEffect(() => {
    if (isOpen) {
      dispatchGetAvailableSkillsForRole(roleId);
    }
  }, [roleId, isOpen, dispatchGetAvailableSkillsForRole]);

  const columns = useMemo(
    () => [
      {
        xs: 4,
        title: "Skill Name",
        getSortProperty: item => item.get("skillName"),
      },
      {
        xs: true,
        title: "Group",
        getSortProperty: item => item.get("skillGroupName"),
      },
      {
        xs: true,
        title: "Type",
        getSortProperty: item => item.get("skillTypeName"),
      },
      {
        xs: true,
        title: "Valid",
        getSortProperty: item => item.get("validityMonthsString"),
      },
      {
        xs: true,
        justify: "center",
        container: true,
        title: "Criticality",
        getValue: item => (
          <SwitchField
            label="Critical"
            checked={item.get("criticality") === CRITICALITY.CRITICAL}
            onChange={() =>
              dispatchUpdateRoleSkillAndGetRoleStats(
                item.update("criticality", skillCriticality =>
                  skillCriticality === CRITICALITY.CRITICAL
                    ? CRITICALITY.NOT_CRITICAL
                    : CRITICALITY.CRITICAL,
                ),
              )
            }
          />
        ),
      },
    ],
    [dispatchUpdateRoleSkillAndGetRoleStats],
  );

  const actions = useMemo(
    () => [
      {
        getValue: item =>
          item.get("roleId").toString() === roleId.toString() && (
            <Tooltip title="Delete">
              <IconButton onClick={() => dispatchDeleteRoleSkill(item)}>
                <ICONS.DELETE />
              </IconButton>
            </Tooltip>
          ),
      },
    ],
    [roleId, dispatchDeleteRoleSkill],
  );

  const addSkillsSubmit = useCallback(
    selectedSkills => {
      dispatchCreateRoleSkillOnSubmit(
        roleId,
        selectedSkills.map(skill => skill.get("skillId")).toArray(),
      ).then(toggleModal);
    },
    [roleId, toggleModal, dispatchCreateRoleSkillOnSubmit],
  );

  return (
    <CrudBasePage
      dataState={dataState}
      title={breadcrumb}
      displayAddButton={true}
      displaySearchField={true}
      addButtonOnClick={toggleModal}
    >
      <Grid container spacing={5}>
        {roles.map(role => {
          const mapRoleId = role.get("roleId");

          return (
            <RoleRoleSkillList
              Icon={ICONS.SKILL}
              key={mapRoleId}
              roleId={mapRoleId}
              getKey={getKey}
              actions={actions}
              columns={columns}
              inherited={mapRoleId?.toString() !== roleId?.toString()}
            />
          );
        })}
      </Grid>
      <MultiSelectModal
        open={isOpen && !getAvailableSkillsDataState.isLoading()}
        displayChips
        onClose={toggleModal}
        getName={getSkillName}
        items={availableSkills}
        title="Add Skills"
        onSubmit={addSkillsSubmit}
      />
    </CrudBasePage>
  );
};

export default compose(
  connect(
    (state, { roleId }) => ({
      roles: getRolesFromState(state, roleId),
      availableSkills: getAvailableSkillsForRoleFromState(state, roleId),
      getAvailableSkillsDataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_AVAILABLE_SKILLS_FOR_ROLE,
      ),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_ROLE_SKILLS_WITH_PARENTS_BY_ROLE,
        ACTIONS.UPDATE_ROLE_SKILL,
        ACTIONS.GET_AVAILABLE_SKILLS_FOR_ROLE,
      ),
    }),
    {
      dispatchGetAvailableSkillsForRole: getAvailableSkillsForRole,
      dispatchGetRoleSkills: getRoleSkillsWithParentsByRole,
      dispatchOnUnload: roleSkillsOnUnload,
      dispatchDeleteRoleSkill: deleteRoleSkillAskConfirmation,
      dispatchCreateRoleSkillOnSubmit: createRoleSkillsOnSubmit,
      dispatchUpdateRoleSkillAndGetRoleStats: updateRoleSkillAndGetRoleStats,
    },
  ),
)(RoleSkillsContainer);
