import { compose } from "redux";
import isNil from "lodash/isNil";
import range from "lodash/range";
import classNames from "classnames";
import { connect } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { Typography, Grid, withStyles, Button } from "@material-ui/core";

import { BasePageTitle } from "altus-ui-components";

import {
  collectionOnReset,
  getCollectionItems,
  updateCollectionMatrix,
} from "features/competence.actions";

import {
  getCollectionFromState,
  groupItemsByRowAndColumn,
  getAvailableCollectionItems,
  getCollectionItemsFromState,
} from "features/competence.selectors";

import { EMPTY_MAP } from "app/app.constants";
import { CollectionMapper } from "app/app.mappers";
import CollectionConditionMatrixRow from "features/components/CollectionConditionMatrix/CollectionConditionMatrixRow";
import CollectionConditionMatrixHeader from "features/components/CollectionConditionMatrix/CollectionConditionMatrixHeader";

const CollectionConditionMatrix = ({
  title,
  classes,
  disabled,
  collection,
  collectionId,
  collectionItems,
  getOptionHighlighted,
  dispatchGetCollectionItems,
  dispatchResetCollectionMatrix,
  dispatchUpdateCollectionMatrix,
}) => {
  const [rows, setRows] = useState(0);
  const [columns, setColumns] = useState(0);
  const [dirty, setDirty] = useState(false);

  const [items, setItems] = useState(EMPTY_MAP);
  const [availableItems, setAvailableItems] = useState(EMPTY_MAP);
  const [itemsByRowAndColumn, setItemsByRowAndColumn] = useState(EMPTY_MAP);

  useEffect(() => {
    dispatchGetCollectionItems(collectionId);
  }, [dispatchGetCollectionItems, collectionId]);

  const setInitialValues = useCallback(() => {
    // Revert the local changes to the initial data we'd fetched.
    setItems(collectionItems);
    setRows(collection.get("matrixRows"));
    setColumns(collection.get("matrixColumns"));
    setDirty(false);
  }, [collection, collectionItems]);

  useEffect(() => {
    // We received new data from the backend, so reset to the initial values.
    setInitialValues();
  }, [setInitialValues]);

  useEffect(() => {
    // The "items" will be our single source of truth.
    // Based on that, we figure out the ones that are still available
    // and we group the ones already included in the matrix by row/column.
    setItemsByRowAndColumn(groupItemsByRowAndColumn(items));
    setAvailableItems(
      items.filter(item => isNil(item.get("row")) || isNil(item.get("column"))),
    );
  }, [items]);

  const onRowsChange = useCallback((_, newRows) => {
    // Matrix rows size changed, remove items that fell out of the matrix boundaries.
    setItems(prev =>
      prev.map(item =>
        item.get("row") >= newRows
          ? item.set("row", null).set("column", null)
          : item,
      ),
    );
    setRows(newRows);
    setDirty(true);
  }, []);

  const onColumnsChange = useCallback((_, newColumns) => {
    // Matrix columns size changed, remove items that fell out of the matrix boundaries.
    setItems(prev =>
      prev.map(item =>
        item.get("column") >= newColumns
          ? item.set("row", null).set("column", null)
          : item,
      ),
    );
    setColumns(newColumns);
    setDirty(true);
  }, []);

  const onCellChange = useCallback(
    // An item was added or removed from a cell in the matrix. Update its row and column values accordingly.
    (rowIndex, columnIndex, option) => {
      setItems(prev => {
        const itemWasRemoved = !option;
        if (itemWasRemoved) {
          const itemRemovedId = itemsByRowAndColumn
            .getIn([rowIndex, columnIndex])
            .get("competencyCollectionItemId")
            .toString();

          return prev.update(itemRemovedId, item =>
            item.set("row", null).set("column", null),
          );
        }

        return prev.update(
          option.get("competencyCollectionItemId").toString(),
          item => item.set("row", rowIndex).set("column", columnIndex),
        );
      });
      setDirty(true);
    },
    [itemsByRowAndColumn],
  );

  const onSubmitMatrixUpdate = useCallback(() => {
    // Build the payload and submit the changes.
    const matrixUpdate = {
      matrixRows: rows,
      matrixColumns: columns,
      competencyCollectionItems: items.toList().toJS(),
    };

    dispatchUpdateCollectionMatrix(collectionId, matrixUpdate);
  }, [rows, columns, items, dispatchUpdateCollectionMatrix, collectionId]);

  const onResetCollectionMatrix = useCallback(
    () => dispatchResetCollectionMatrix(collectionId),
    [dispatchResetCollectionMatrix, collectionId],
  );

  return (
    <Grid container direction="column">
      <Grid container item className={classes.header}>
        <Grid item xs>
          <BasePageTitle title={title} />
        </Grid>
        {!disabled && dirty && (
          <Grid item container xs spacing={1} justify="flex-end">
            <Grid item>
              <Button size="small" onClick={setInitialValues}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={onSubmitMatrixUpdate}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <CollectionConditionMatrixHeader
        rows={rows}
        columns={columns}
        disabled={disabled}
        collectionId={collectionId}
        onRowsChange={onRowsChange}
        onColumnsChange={onColumnsChange}
        title={collection.get("name")}
        onReset={onResetCollectionMatrix}
        resetBtnDisabled={!itemsByRowAndColumn.size}
      />
      {!!rows && !!columns && (
        <Grid container>
          <Grid
            item
            className={classNames(classes.cell, classes.rowIndexCell)}
          />
          {range(columns).map(columnIndex => (
            <Grid
              xs
              item
              container
              key={columnIndex}
              justify="center"
              alignItems="center"
              className={classes.cell}
            >
              <Typography variant="body2">
                <b>{columnIndex + 1}</b>
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
      {range(0, rows)
        .reverse()
        .map(rowIndex => (
          <CollectionConditionMatrixRow
            key={rowIndex}
            columns={columns}
            disabled={disabled}
            rowIndex={rowIndex}
            onChange={onCellChange}
            availableCollectionItems={availableItems}
            getOptionHighlighted={getOptionHighlighted}
            collectionItemsByRowAndColumn={itemsByRowAndColumn}
          />
        ))}
    </Grid>
  );
};

const styles = theme => ({
  cell: {
    overflow: "hidden",
    height: theme.altus.largeRow.height,
    background: theme.palette.common.white,
    borderLeft: theme.gapReport.defaultBorder,
    borderBottom: theme.gapReport.defaultBorder,
    "&:last-child": {
      borderRight: theme.gapReport.defaultBorder,
    },
  },
  rowIndexCell: {
    width: theme.altus.largeRow.height,
  },
  header: {
    paddingTop: theme.spacing(2.25),
    paddingBottom: theme.spacing(3),
  },
});

CollectionConditionMatrix.defaultProps = {
  collection: CollectionMapper.initial,
  collectionItems: EMPTY_MAP,
};

export default compose(
  connect(
    (state, { collectionId }) => ({
      collection: getCollectionFromState(state, collectionId),
      availableCollectionItems: getAvailableCollectionItems(
        state,
        collectionId,
      ),
      collectionItems: getCollectionItemsFromState(state, collectionId),
    }),
    {
      dispatchGetCollectionItems: getCollectionItems,
      dispatchResetCollectionMatrix: collectionOnReset,
      dispatchUpdateCollectionMatrix: updateCollectionMatrix,
    },
  ),
  withStyles(styles),
)(CollectionConditionMatrix);
