import React from "react";
import { compose } from "redux";
import GetApp from "@material-ui/icons/GetApp";
import { withStyles } from "@material-ui/styles";
import Description from "@material-ui/icons/Description";
import { Grid, Typography, Tooltip } from "@material-ui/core";

const DownloadFileColumn = ({ file, onClick, classes }) => {
  if (!file) return null;

  return (
    <Tooltip title={`Download ${file.name}`}>
      <Grid
        container
        spacing={1}
        wrap="nowrap"
        onClick={onClick}
        alignItems="center"
        className={classes.root}
      >
        <Grid item>
          <Typography className={classes.iconWrapper}>
            <Description fontSize="small" className={classes.fileIcon} />
            <GetApp fontSize="small" className={classes.downloadIcon} />
          </Typography>
        </Grid>
        <Grid item zeroMinWidth>
          <Typography variant="body2" noWrap className={classes.fileName}>
            {file.name}
          </Typography>
        </Grid>
      </Grid>
    </Tooltip>
  );
};

const styles = theme => ({
  iconWrapper: {
    display: "flex",
    color: theme.palette.text.secondary,
  },
  downloadIcon: {
    display: "none",
    borderRadius: "50%",
  },
  fileIcon: {
    display: "initial",
  },
  fileName: {
    textDecoration: "none",
  },
  root: {
    cursor: "pointer",
    "&:hover $downloadIcon": {
      display: "initial",
    },
    "&:hover $fileIcon": {
      display: "none",
    },
    "&:hover $fileName": {
      textDecoration: "underline",
    },
  },
});

export default compose(React.memo, withStyles(styles))(DownloadFileColumn);
