import routePaths from "app/routePaths";
import Agent from "infrastructure/agent";
import config from "infrastructure/config";
import { ACTIONS } from "app/app.constants";
import { appOnLoad } from "app/app.actions";
import authService from "services/auth.service";
import { makeActionCreator } from "utils/app.util";

export const loginPopup = makeActionCreator({
  type: ACTIONS.LOGIN,
  payload: () =>
    authService.loginPopup([config.scope], "&prompt=select_account"),
});

export const logout = makeActionCreator({
  type: ACTIONS.LOGOUT,
  payload: () => new Promise(resolve => resolve(authService.logout())),
});

export const login = () => dispatch => {
  return dispatch(loginPopup()).then(() => {
    // reset the Agent so that the access_token refreshes immediately after logging in
    Agent.throttledRefreshToken.cancel();

    return dispatch(appOnLoad());
  });
};

export const changeUser = () => dispatch => {
  dispatch(loginPopup()).then(() => {
    window.location = routePaths.root;
  });
};
