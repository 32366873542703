import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { formValueSelector } from "redux-form/immutable";

import {
  EMPLOYEE_SKILL_FORM,
  ADD_EMPLOYEE_SKILL_EVIDENCE_MODAL_ID,
} from "features/competence.constants";

import { EMPTY_SET, ICONS } from "app/app.constants";
import SimpleFormModal from "app/components/SimpleFormModal";

import AddEmployeeSkillEvidenceForm from "features/components/AddEmployeeSkillEvidenceForm";
import AddEmployeeSkillEvidenceModalActions from "features/components/AddEmployeeSkillEvidenceModalActions";

const AddEmployeeSkillEvidenceModal = ({
  classes,
  employeeId,
  skillId,
  addEmployeeSkillEvidenceOnSubmit,
}) => {
  return (
    <SimpleFormModal
      Icon={ICONS.SKILL}
      submitText="Upload"
      title="Upload Evidence Files"
      displayCancelBtn={false}
      displaySubmitBtn={false}
      component={AddEmployeeSkillEvidenceForm}
      modalId={ADD_EMPLOYEE_SKILL_EVIDENCE_MODAL_ID}
      formTitleField={EMPLOYEE_SKILL_FORM.SKILL_NAME}
      ModalActions={AddEmployeeSkillEvidenceModalActions}
      ModalActionsProps={{
        employeeId,
        skillId,
        addEmployeeSkillEvidenceOnSubmit,
      }}
      FormProps={{
        employeeId,
        skillId,
      }}
      DialogProps={{
        maxWidth: "md",
        fullWidth: true,
        classes: {
          paper: classes.dialogPaper,
        },
      }}
      form={{
        form: EMPLOYEE_SKILL_FORM.ID,
        initialValues: {
          [EMPLOYEE_SKILL_FORM.FILE_EVIDENCES]: EMPTY_SET,
        },
      }}
    />
  );
};

const formSelector = formValueSelector(EMPLOYEE_SKILL_FORM.ID);

const styles = {
  dialogPaper: {
    height: "100%",
    maxHeight: 750,
  },
};

export default compose(
  connect(state => ({
    skillId: formSelector(state, EMPLOYEE_SKILL_FORM.SKILL_ID),
  })),
  withStyles(styles),
)(AddEmployeeSkillEvidenceModal);
