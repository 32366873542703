import { compose } from "redux";
import React, { PureComponent } from "react";

import EmployeeSkillContainer from "features/employee/employeeSkill/EmployeeSkillContainer";

class EmployeeEmployeeSkillContainer extends PureComponent {
  render() {
    const { skillId, employeeId, routes } = this.props;

    return (
      <EmployeeSkillContainer
        employeeId={employeeId}
        skillId={skillId}
        routes={routes}
      />
    );
  }
}

export default compose()(EmployeeEmployeeSkillContainer);
