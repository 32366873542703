import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";

import { LoadingButton } from "altus-ui-components";

import AccessControl from "app/components/AccessControl";
import { APP_ROLES_HR_AND_ADMIN } from "app/app.constants";
import { createEmployeeEducationOnSubmit } from "features/competence.actions";

const AddEmployeeEducationModalActions = ({
  valid,
  pristine,
  employeeId,
  submitText,
  submitting,
  toggleModal,
  handleSubmit,
  dispatchCreateEmployeeEducationOnSubmit,
}) => {
  const approveOnCreate = true;
  return (
    <Grid container justify="space-between">
      <Grid item>
        <AccessControl accessLevel={APP_ROLES_HR_AND_ADMIN}>
          <LoadingButton
            type="submit"
            color="primary"
            disabled={pristine || !valid || submitting}
            loading={submitting}
            variant="contained"
            onClick={handleSubmit(
              dispatchCreateEmployeeEducationOnSubmit(
                employeeId,
                approveOnCreate,
              )(toggleModal),
            )}
          >
            {`${submitText} & Approve`}
          </LoadingButton>
        </AccessControl>
      </Grid>
      <Grid item>
        <LoadingButton
          type="submit"
          color="primary"
          disabled={pristine || !valid || submitting}
          loading={submitting}
          variant="contained"
          onClick={handleSubmit(
            dispatchCreateEmployeeEducationOnSubmit(
              employeeId,
              !approveOnCreate,
            )(toggleModal),
          )}
        >
          {submitText}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default compose(
  connect(null, {
    dispatchCreateEmployeeEducationOnSubmit: createEmployeeEducationOnSubmit,
  }),
)(AddEmployeeEducationModalActions);
