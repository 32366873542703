import { compose } from "redux";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";

import React, { PureComponent } from "react";

import CrudBasePage from "app/components/CrudBasePage";
import { myTeamApprovalOnLoad } from "features/competence.actions";
import { ACTIONS, APP_ROOT } from "app/app.constants";
import { getActionDataStateFromState } from "app/app.selectors";
import EmployeeSkillFileEvidenceApproval from "features/myTeam/approval/EmployeeSkillFileEvidenceApproval";

class MyTeamApprovalContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad } = this.props;
    dispatchOnLoad();
  }

  render() {
    const {
      skills,
      myTeamEmployeeSkillsDataState,
      breadcrumb,
      Icon,
    } = this.props;

    return (
      <CrudBasePage
        Icon={Icon}
        items={skills}
        title={breadcrumb}
        dataState={myTeamEmployeeSkillsDataState}
      >
        <Grid container spacing={8}>
          <Grid container item>
            <EmployeeSkillFileEvidenceApproval root={APP_ROOT.MY_PAGE} />
          </Grid>
        </Grid>
      </CrudBasePage>
    );
  }
}

export default compose(
  connect(
    state => ({
      myTeamEmployeeSkillsDataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_MY_TEAM_FILE_EVIDENCES_NEEDING_APPROVAL,
      ),
    }),
    {
      dispatchOnLoad: myTeamApprovalOnLoad,
    },
  ),
)(MyTeamApprovalContainer);
