import React from "react";
import moment from "moment";
import { KeyboardDatePicker } from "@material-ui/pickers";

import { Format } from "app/app.constants";

const DatePickerField = ({
  input,
  format,
  DatePickerProps,
  inputVariant,
  margin,
}) => {
  const { onChange, value } = input;

  return (
    <KeyboardDatePicker
      autoOk
      fullWidth
      margin={margin}
      format={format}
      inputVariant={inputVariant}
      onChange={onChange}
      placeholder={format}
      value={moment.isMoment(value) ? value : null}
      {...DatePickerProps}
    />
  );
};

DatePickerField.defaultProps = {
  margin: "normal",
  format: Format.date,
  inputVariant: "standard",
};

export default DatePickerField;
