import { OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { CompetencyCategoryMapper } from "app/app.mappers";

const initialState = OrderedMap();

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case ACTIONS.CLEAR_WORK_HISTORY: {
      return initialState;
    }

    case ACTIONS.GET_EDUCATION_CATEGORIES:
    case ACTIONS.GET_WORK_HISTORY_CATEGORIES: {
      if (error) return state;

      return OrderedMap(
        payload.map(competencyCategory => [
          competencyCategory.competencyCategoryId.toString(),
          CompetencyCategoryMapper.from(competencyCategory),
        ]),
      );
    }

    default:
      return state;
  }
};
