import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { getCurrentEmployeeFromState } from "app/app.selectors";
import EmployeeRoleContainer from "features/employeeRole/EmployeeRoleContainer";

class MyPageEmployeeRoleContainer extends PureComponent {
  render() {
    const { roleId, currentEmployeeId, routes } = this.props;

    return (
      <EmployeeRoleContainer
        roleId={roleId}
        routes={routes}
        employeeId={currentEmployeeId}
      />
    );
  }
}

export default compose(
  connect(state => ({
    currentEmployeeId: getCurrentEmployeeFromState(state).get("employeeId"),
  })),
)(MyPageEmployeeRoleContainer);
