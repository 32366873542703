import { compose } from "redux";
import { Chip } from "@material-ui/core";
import React, { useCallback, useMemo } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { TextField as MuiTextField } from "@material-ui/core";

import { useModal } from "altus-modal";
import { MultiSelectModal } from "altus-ui-components";

import { EMPTY_LIST } from "app/app.constants";
import { CREATE_EXPERIENCE_RECORD_FORM } from "features/competence.constants";

const validate = () => true;

const getSubmitLabel = selectedItems =>
  selectedItems.size ? `Save (${selectedItems.size})` : "Save";

const getEmployeeName = employee => employee.get("displayName");

const MultiSelectEmployeeModalField = ({
  label,
  input,
  classes,
  disabled,
  employees,
}) => {
  const { onChange, value = EMPTY_LIST, name } = input;

  const [isOpen, toggleModal] = useModal(
    CREATE_EXPERIENCE_RECORD_FORM.MODAL_ID,
  );

  const renderValue = useCallback(
    () =>
      value
        .sortBy(employee => employee.get("displayName"))
        .map(employee => (
          <Chip
            className={classes.chip}
            key={employee.get("employeeNumber")}
            label={employee.get("displayName")}
          />
        )),
    [value, classes],
  );

  const initialSelectedItems = useMemo(() => value.toSet(), [value]);

  const onSubmit = useCallback(
    selectedEmployees => {
      onChange(selectedEmployees);
      toggleModal();
    },
    [onChange, toggleModal],
  );

  return (
    <>
      <MuiTextField
        select
        name={name}
        label={label}
        disabled={disabled}
        value={value.toArray()}
        SelectProps={{
          open: false,
          renderValue,
          multiple: true,
          onOpen: toggleModal,
        }}
      >
        {EMPTY_LIST.toArray()}
      </MuiTextField>
      <MultiSelectModal
        open={isOpen}
        onSubmit={onSubmit}
        items={employees}
        validate={validate}
        onClose={toggleModal}
        title="Select employees"
        getName={getEmployeeName}
        getSubmitLabel={getSubmitLabel}
        initialSelectedItems={initialSelectedItems}
      />
    </>
  );
};

const styles = theme => ({
  chip: {
    height: 27,
    marginLeft: 0,
    margin: theme.spacing(0.5),
  },
});

export default compose(withStyles(styles))(MultiSelectEmployeeModalField);
