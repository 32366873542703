import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { getEducationCategories } from "features/competence.actions";
import {
  ADD_EMPLOYEE_EDUCATION_MODAL_ID,
  EDUCATION_FORM,
} from "features/competence.constants";

import { EMPTY_SET } from "app/app.constants";
import SimpleFormModal from "app/components/SimpleFormModal";
import EmployeeEducationForm from "features/employee/education/components/EmployeeEducationForm";
import AddEmployeeEducationModalActions from "features/employee/education/components/AddEmployeeEducationModalActions";

const AddEmployeeEducationModal = ({
  employeeId,
  dispatchGetEducationCategories,
}) => {
  return (
    <SimpleFormModal
      submitText="ADD"
      title="Add Education"
      displaySubmitBtn={false}
      displayCancelBtn={false}
      onEnter={dispatchGetEducationCategories}
      component={EmployeeEducationForm}
      modalId={ADD_EMPLOYEE_EDUCATION_MODAL_ID}
      ModalActions={AddEmployeeEducationModalActions}
      ModalActionsProps={{
        employeeId,
      }}
      FormProps={{
        employeeId,
      }}
      DialogProps={{
        maxWidth: "sm",
        fullWidth: true,
      }}
      form={{
        form: EDUCATION_FORM.ID,
        initialValues: {
          [EDUCATION_FORM.FILES]: EMPTY_SET,
        },
      }}
    />
  );
};

export default compose(
  connect(null, { dispatchGetEducationCategories: getEducationCategories }),
)(AddEmployeeEducationModal);
