import { compose } from "redux";
import React, { PureComponent } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { BasePageTitle } from "altus-ui-components";

class GapReportMessagePage extends PureComponent {
  render() {
    const { classes, children, title } = this.props;

    return (
      <Grid
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
      >
        <Grid item>
          <BasePageTitle title={title} />
        </Grid>
        <br />
        <Grid item>
          <Typography variant="h6" className={classes.filterText}>
            {children}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  filterText: {
    color: theme.palette.text.hint,
  },
});

export default compose(withStyles(styles))(GapReportMessagePage);
