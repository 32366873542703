import { compose } from "redux";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { reduxForm } from "redux-form/immutable";

import {
  updateService,
  initializeEditService,
  serviceDetailsOnLoad,
} from "features/competence.actions";

import { ACTIONS } from "app/app.constants";
import { BasePage } from "altus-ui-components";
import { getActionDataStateFromState } from "app/app.selectors";
import { SERVICE_FORM } from "features/competence.constants";
import { getServiceFromState } from "features/competence.selectors";
import ServiceForm from "features/hr/services/components/ServiceForm";

const ServiceDetailsContainer = props => {
  const {
    breadcrumb,
    service,
    dataState,
    dispatchOnLoad,
    serviceId,
    ...rest
  } = props;

  useEffect(() => {
    dispatchOnLoad(serviceId);
  }, [serviceId, dispatchOnLoad]);

  return (
    <BasePage title={breadcrumb} dataState={dataState}>
      <Grid container item md={8} xs={12}>
        <ServiceForm currentService={service} {...rest} />
      </Grid>
    </BasePage>
  );
};

export default compose(
  connect(
    (state, { serviceId }) => ({
      service: getServiceFromState(state, serviceId),
      dataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_SERVICE_WITH_PARENTS,
      ),
    }),
    {
      dispatchOnLoad: serviceDetailsOnLoad,
    },
  ),
  reduxForm({
    form: SERVICE_FORM.ID,
    onChange: debounce((values, dispatch, props) => {
      const { dirty, serviceId } = props;

      if (dirty) {
        dispatch(updateService(serviceId, values.toJS())).then(service =>
          dispatch(initializeEditService(service)),
        );
      }
    }, 1000),
  }),
)(ServiceDetailsContainer);
