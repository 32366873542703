import moment from "moment";
import { compose } from "redux";
import React, { useMemo } from "react";

import { withStyles, Grid } from "@material-ui/core";

import { TableRowActionsCell } from "altus-ui-components";

import { formatDate } from "utils/format.util";
import { Format, EMPTY_MAP } from "app/app.constants";
import withToggleModal from "app/components/withToggleModal";
import SortableTable from "app/components/Table/SortableTable";

const CourseExpiryReportTableContainer = ({ courses = EMPTY_MAP }) => {
  const columns = useMemo(
    () => [
      {
        xl: true,
        id: "icon",
        paddingLeft: "16px",
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (row.depth !== 0) return <TableRowActionsCell minItems={1} />;
          return null;
        },
      },
      {
        xs: 9,
        id: "course",
        title: "Course",
        Header: "Course",
        Cell: ({ row }) => {
          if (row.depth === 0) return row.original.get("name");

          return row.original.get("displayName");
        },
      },
      {
        xs: true,
        id: "expires",
        title: "Expires",
        Header: "Expires",
        component: Grid,
        container: true,
        justify: "flex-end",
        Cell: ({ row }) => {
          if (row.depth !== 0 && row.original.get("expiryDate"))
            return formatDate(
              moment(row.original.get("expiryDate")),
              Format.date,
            );
          return "";
        },
      },
    ],
    [],
  );

  return (
    <>
      <SortableTable
        stickyHeader
        disableSortBy
        displayAddButton={false}
        columns={columns}
        title="Course Expiry Report"
        useGlobalFilter={false}
        subListPropertyName="expiryItems"
        items={courses}
        sortOrder="name"
        noItemsMessage="No course expiry found..."
      />
    </>
  );
};

const styles = () => ({
  formControlLabelRoot: {
    marginLeft: 0,
  },
});

export default compose(
  withStyles(styles),
  withToggleModal,
)(CourseExpiryReportTableContainer);
