import { PureComponent } from "react";
import { connect } from "react-redux";

import {
  getCurrentEmployeeFromState,
  getCurrentUserAppRolesFromState,
  getActionDataStateFromState,
} from "app/app.selectors";

import { hasRequiredAccessLevel } from "utils/app.util";
import { ACTIONS } from "app/app.constants";

class AccessControl extends PureComponent {
  render() {
    const {
      children,
      dataState,
      accessLevel,
      noAccessContent,
      currentEmployeeId,
      currentUserAppRoles,
    } = this.props;

    if (dataState.isLoading()) return children;

    const currentUserHasRequiredAccessLevel = hasRequiredAccessLevel(
      accessLevel,
      currentUserAppRoles,
      currentEmployeeId,
    );

    return currentUserHasRequiredAccessLevel ? children : noAccessContent;
  }
}

AccessControl.defaultProps = {
  noAccessContent: null,
};

export default connect(state => ({
  currentUserAppRoles: getCurrentUserAppRolesFromState(state),
  currentEmployeeId: getCurrentEmployeeFromState(state).get("employeeId"),
  dataState: getActionDataStateFromState(state, ACTIONS.GET_CURRENT_USER),
}))(AccessControl);
