import React from "react";
import Receipt from "@material-ui/icons/Receipt";

import SimpleFormModal from "app/components/SimpleFormModal";
import { EMPLOYEE_CV_OPTIONS_FORM } from "features/competence.constants";
import GenerateEmployeeCVForm from "features/components/GenerateEmployeeCVForm";

const GenerateCVModal = props => {
  return (
    <SimpleFormModal
      Icon={Receipt}
      title="Generate CV"
      submitText="Generate"
      component={GenerateEmployeeCVForm}
      form={{
        form: EMPLOYEE_CV_OPTIONS_FORM.ID,
        initialValues: {
          [EMPLOYEE_CV_OPTIONS_FORM.INCLUDE_COURSE]: true,
          [EMPLOYEE_CV_OPTIONS_FORM.INCLUDE_EDUCATION]: true,
          [EMPLOYEE_CV_OPTIONS_FORM.INCLUDE_WORK_EXPERIENCE]: true,
          [EMPLOYEE_CV_OPTIONS_FORM.FILE_FORMAT]: 0,
        },
      }}
      {...props}
    />
  );
};

export default GenerateCVModal;
