import React from "react";

import {
  Grid,
  Button,
  Tooltip,
  Typography,
  IconButton,
} from "@material-ui/core";

import { SORT_DIRECTION, ICONS } from "app/app.constants";
import SortableList from "app/components/SortableList/SortableList";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";

const EmployeeRoleSkillList = ({
  Icon,
  title,
  roleId,
  employeeRoleSkills,
  removeEmployeeSkill,
  createSortableListRowProps,
  initializeAddEmployeeSkillEvidence,
}) => {
  console.log("skills", employeeRoleSkills.toJS());

  const columns = [
    {
      xs: 4,
      title: "Skill Name",
      getSortProperty: item => item.getIn(["skillName"]),
    },
    {
      xs: true,
      filter: {
        label: "Group",
        defaultText: "All groups",
      },
      title: "Group",
      getSortProperty: item => item.get("skillGroupName"),
    },
    {
      filter: {
        defaultText: "All types",
      },
      xs: true,
      title: "Type",
      getSortProperty: item => item.getIn(["skillTypeName"]),
    },
    {
      xs: true,
      title: "Evidences",
      getSortProperty: item =>
        `${item.get("fileEvidencesCount")}/${item.getIn([
          "skillRequiredEvidenceCount",
        ])}`,
    },
    {
      title: "",
      xs: true,
      component: Grid,
      getValue: item => (
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();

            initializeAddEmployeeSkillEvidence(item);
          }}
        >
          <Typography noWrap variant="caption">
            + Evidence
          </Typography>
        </Button>
      ),
    },
    {
      xs: true,
      title: "",
      component: Grid,
      container: true,
      justify: "flex-end",
      getSortProperty: item => item.get("competencyStatus"),
      getValue: item => (
        <CompetencyStatusChip status={item.get("competencyStatus")} />
      ),
      filter: {
        label: "Status",
        defaultText: "All",
        getFilterText: filter => statusToTooltipText(filter),
      },
    },
  ];

  const actions =
    roleId === null
      ? [
          {
            getValue: item => (
              <Tooltip title="Delete">
                <IconButton onClick={() => removeEmployeeSkill(item)}>
                  <ICONS.DELETE fontSize="small" />
                </IconButton>
              </Tooltip>
            ),
          },
        ]
      : [];

  return (
    <>
      <Grid container item>
        <SortableList
          Icon={Icon}
          title={title}
          columns={columns}
          actions={actions}
          defaultSortColumn={0}
          items={employeeRoleSkills}
          defaultSortDirection={SORT_DIRECTION.ASC}
          getKey={employeeRoleSkill =>
            employeeRoleSkill.get("roleSkillId")
              ? `${employeeRoleSkill.get("roleSkillId")}_rs`
              : `${employeeRoleSkill.get("employeeSkillId")}_es`
          }
          createSortableListRowProps={createSortableListRowProps}
        />
      </Grid>
    </>
  );
};

export default EmployeeRoleSkillList;
