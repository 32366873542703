import { compose } from "redux";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";
import { Switch, Route } from "react-router-dom";

import { LoadingDataState } from "altus-datastate";

import {
  RouteContainer,
  OverviewContainer,
  NoContentBasePage,
  ApplicationBaseContent,
} from "altus-ui-components";

import {
  getFeatureFlagsFromState,
  getActionDataStateFromState,
  getCurrentEmployeeFromState,
  getCurrentUserAppRolesFromState,
} from "app/app.selectors";

import routes from "app/routes";
import routePaths from "app/routePaths";
import { ACTIONS } from "app/app.constants";
import { appOnLoad } from "app/app.actions";
import ErrorBoundary from "app/components/ErrorBoundary";
import BreadcrumbHeader from "features/components/BreadcrumbHeader";
import { hasRequiredAccessLevel, isFeatureFlagEnabled } from "utils/app.util";
import ApplicationSidebar from "app/components/ApplicationSidebar/ApplicationSidebar";

const NoAccessPage = () => (
  <NoContentBasePage
    header="Oops, we have nothing to show you..."
    description="Your employee data is not available on our systems yet. Don't
worry, we retrieve it regularly from SAP, so it should be
available soon. Please try again later. If the problem persists,
please contact the HR Department or the Service Desk for
assistance."
  />
);

class CompetenceContainer extends PureComponent {
  handleValidRoute = route => {
    if (!route.routes) return route;

    return {
      ...route,
      routes: route.routes.reduce(this.filterValidSubRoutes, []),
    };
  };

  filterValidSubRoutes = (validRoutes, route) => {
    const { currentUserAppRoles, currentEmployeeId, featureFlags } = this.props;

    const userHasRequiredAccessLevel = hasRequiredAccessLevel(
      route.accessLevel,
      currentUserAppRoles,
      currentEmployeeId,
    );

    const routeHasEnabledFeature = isFeatureFlagEnabled(
      featureFlags,
      route.featureFlag,
    );

    return userHasRequiredAccessLevel && routeHasEnabledFeature
      ? [...validRoutes, this.handleValidRoute(route)]
      : validRoutes;
  };

  renderBreadcrumbs = () => {
    return <Grid item component={BreadcrumbHeader} />;
  };

  render() {
    const { title, dataState } = this.props;

    const validRoutes = routes.reduce(this.filterValidSubRoutes, []);

    return (
      <Grid item xs container wrap="nowrap">
        <ApplicationSidebar title={title} routes={validRoutes} />
        <ErrorBoundary>
          <ApplicationBaseContent
            routes={validRoutes}
            dataState={dataState}
            renderTopContent={this.renderBreadcrumbs}
          >
            <Switch>
              {!!validRoutes.length && (
                <Route
                  exact
                  path={routePaths.root}
                  render={({ match }) => (
                    <OverviewContainer
                      match={match}
                      parentBreadcrumb="Home"
                      parentRoutes={validRoutes}
                    />
                  )}
                />
              )}
              {!!validRoutes.length && (
                <Route render={() => <RouteContainer routes={validRoutes} />} />
              )}
              <Route component={NoAccessPage} />
            </Switch>
          </ApplicationBaseContent>
        </ErrorBoundary>
      </Grid>
    );
  }
}

CompetenceContainer.defaultProps = {
  dataState: LoadingDataState,
};

export default compose(
  connect(
    state => ({
      featureFlags: getFeatureFlagsFromState(state),
      currentUserAppRoles: getCurrentUserAppRolesFromState(state),
      currentEmployeeId: getCurrentEmployeeFromState(state).get("employeeId"),
      dataState: getActionDataStateFromState(state, ACTIONS.APPLICATION_LOADED),
    }),
    {
      dispatchOnLoad: appOnLoad,
    },
  ),
)(CompetenceContainer);
