import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { getCurrentEmployeeFromState } from "app/app.selectors";
import EmployeeEducationBasePage from "features/components/EmployeeEducationBasePage";

class MyEducationContainer extends PureComponent {
  render() {
    const { currentEmployeeId, breadcrumb, Icon } = this.props;

    return (
      <EmployeeEducationBasePage
        title={breadcrumb}
        employeeId={currentEmployeeId}
        Icon={Icon}
      />
    );
  }
}

export default compose(
  connect(state => ({
    currentEmployeeId: getCurrentEmployeeFromState(state).get("employeeId"),
  })),
)(MyEducationContainer);
