import { PureComponent } from "react";
import { connect } from "react-redux";

import {
  getCurrentEmployeeFromState,
  getCurrentUserAppRolesFromState,
  getActionDataStateFromState,
} from "app/app.selectors";

import { hasRequiredAccessLevel } from "utils/app.util";
import { ACTIONS, APP_ROLES } from "app/app.constants";
import { getMyTeamEmployeeFromState } from "features/competence.selectors";

class ManagerEmployeeAccessControl extends PureComponent {
  render() {
    const {
      children,
      dataState,
      noAccessContent,
      currentEmployeeId,
      myTeamEmployee,
      currentUserAppRoles,
    } = this.props;

    if (dataState.isLoading()) return children;

    const currentUserIsManager = hasRequiredAccessLevel(
      APP_ROLES.MANAGER,
      currentUserAppRoles,
      currentEmployeeId,
    );

    const currentUserIsManagerForEmployee =
      currentUserIsManager && myTeamEmployee;

    return currentUserIsManagerForEmployee ? children : noAccessContent;
  }
}

ManagerEmployeeAccessControl.defaultProps = {
  noAccessContent: null,
};

export default connect((state, { employeeId }) => ({
  currentUserAppRoles: getCurrentUserAppRolesFromState(state),
  currentEmployeeId: getCurrentEmployeeFromState(state).get("employeeId"),
  dataState: getActionDataStateFromState(state, ACTIONS.GET_CURRENT_USER),
  myTeamEmployee: getMyTeamEmployeeFromState(state, employeeId),
}))(ManagerEmployeeAccessControl);
