import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import { IconButton, Tooltip } from "@material-ui/core";

import {
  servicesOnLoad,
  servicesOnUnload,
  deleteServiceAskConfirmation,
} from "features/competence.actions";

import routePaths from "app/routePaths";
import { ACTIONS, ICONS } from "app/app.constants";
import { getActionDataStateFromState } from "app/app.selectors";
import ServicesBasePage from "features/components/ServicesBasePage";
import { getServicesFromState } from "features/competence.selectors";

const createSortableListRowProps = item => ({
  component: Link,
  to: `${routePaths.hrPortal}/services/${item.get("serviceId")}`,
});

const ServicesContainer = ({
  services,
  dataState,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchDeleteService,
}) => {
  useEffect(() => {
    dispatchOnLoad();

    return () => dispatchOnUnload();
  }, [dispatchOnLoad, dispatchOnUnload]);

  const actions = useMemo(
    () => [
      {
        getValue: item => (
          <Tooltip title="Delete">
            <IconButton onClick={() => dispatchDeleteService(item)}>
              <ICONS.DELETE fontSize="small" />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    [dispatchDeleteService],
  );

  return (
    <ServicesBasePage
      displayAddButton
      title={breadcrumb}
      services={services}
      dataState={dataState}
      actions={actions}
      createSortableListRowProps={createSortableListRowProps}
    />
  );
};

export default compose(
  connect(
    state => ({
      services: getServicesFromState(state),
      dataState: getActionDataStateFromState(state, ACTIONS.GET_SERVICES),
    }),
    {
      dispatchOnLoad: servicesOnLoad,
      dispatchOnUnload: servicesOnUnload,
      dispatchDeleteService: deleteServiceAskConfirmation,
    },
  ),
)(ServicesContainer);
