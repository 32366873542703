import { OrderedMap, Map } from "immutable";

import { ACTIONS } from "app/app.constants";
import {
  ExperienceRecordItemMapper,
  ExperienceRecordItemReportMapper,
} from "app/app.mappers";

const initialState = OrderedMap();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_EXPERIENCES_RECORD_ITEMS:
      return initialState;

    case ACTIONS.GET_EXPERIENCE_RECORD_ITEMS: {
      if (error) return state;

      return state.set(
        action.experienceRecordId.toString(),
        Map(
          payload.map(experienceRecordItem => [
            experienceRecordItem.experienceRecordItemId.toString(),
            ExperienceRecordItemMapper.from(experienceRecordItem),
          ]),
        ),
      );
    }

    case ACTIONS.GET_EMPLOYEE_ALL_EXPERIENCE_RECORD_ITEMS:
    case ACTIONS.GET_EMPLOYEE_FILTERED_EXPERIENCE_RECORD_ITEMS: {
      if (error) return state;

      return state.set(
        action.employeeId.toString(),
        Map(
          payload.map(experienceRecordItemReport => [
            [
              experienceRecordItemReport.experienceItemId.toString(),
              experienceRecordItemReport.experienceCategoryCounterId.toString(),
            ],
            ExperienceRecordItemReportMapper.from(experienceRecordItemReport),
          ]),
        ),
      );
    }

    case ACTIONS.UPDATE_EXPERIENCE_RECORD_ITEM: {
      if (error) return state;

      if (!payload.count) {
        return state.deleteIn([
          action.experienceRecordId.toString(),
          payload.experienceRecordItemId.toString(),
        ]);
      }

      return state.setIn(
        [
          action.experienceRecordId.toString(),
          payload.experienceRecordItemId.toString(),
        ],
        ExperienceRecordItemMapper.from(payload),
      );
    }

    default:
      return state;
  }
};
