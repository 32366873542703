import { Map, OrderedMap, List } from "immutable";

import { ACTIONS } from "app/app.constants";
import { CourseMapper } from "app/app.mappers";

import employeeCourseMappers from "app/mappers/employeeCourse.mappers";

const initialState = Map({
  course: Map(),
  employeeCourse: Map(),
});

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_COURSE_GAP_REPORT: {
      return initialState;
    }

    case ACTIONS.GET_COURSE_GAP_REPORT: {
      if (error) return state;

      return state
        .set(
          "employeeCourse",
          List(payload.employeeCourses)
            .map(employeeCourseMappers.EmployeeCourse.from)
            .groupBy(employeeCourse =>
              employeeCourse.get("employeeId").toString(),
            )
            .map(employeeCoursesByEmployeeId =>
              Map(
                employeeCoursesByEmployeeId.map(employeeCourse => [
                  employeeCourse.get("courseId").toString(),
                  employeeCourse,
                ]),
              ),
            ),
        )
        .set(
          "course",
          OrderedMap(
            payload.courses.map(course => [
              course.courseId.toString(),
              CourseMapper.from(course),
            ]),
          ),
        );
    }

    default:
      return state;
  }
};
