import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { getDepartmentFromState } from "features/competence.selectors";
import { DepartmentMapper } from "app/app.mappers";

class GapReportDepartmentTitle extends PureComponent {
  render() {
    const { classes, department } = this.props;

    return (
      <Grid
        item
        container
        wrap="nowrap"
        alignItems="center"
        className={classes.departmentCell}
      >
        <Typography
          noWrap
          variant="subtitle2"
          className={classes.departmentName}
        >
          {department.get("name")}
        </Typography>
      </Grid>
    );
  }
}

const styles = theme => ({
  departmentCell: {
    zIndex: 5,
    position: "sticky",
    top: theme.gapReport.header.height,
    minHeight: theme.gapReport.cellSize,
    borderBottom: theme.gapReport.defaultBorder,
    background: theme.palette.background.default,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  departmentName: {
    left: theme.spacing(2),
    position: "sticky",
    fontWeight: theme.typography.fontWeightMedium,
  },
});

GapReportDepartmentTitle.defaultProps = {
  department: DepartmentMapper.initial,
};

export default compose(
  connect((state, { departmentId }) => ({
    department: getDepartmentFromState(state, departmentId),
  })),
  withStyles(styles),
)(GapReportDepartmentTitle);
