import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { grey } from "@material-ui/core/colors";

import {
  getServiceGapReportEmployeeServiceStatusesFromState,
  getServiceGapReportEmployeeCoursesFromState,
  getServiceGapReportEmployeeSkillsFromState,
} from "features/competence.selectors";

import { COMPETENCY_STATUS, EMPTY_SET } from "app/app.constants";
import GapReportCell from "features/components/GapReport/GapReportCell";

// const ServiceGapReportEmployeeRow = ({
//   root,
//   services,
//   employeeId,
//   serviceCourses,
//   serviceSkills,
//   employeeServiceStatuses,
//   employeeCourses,
//   employeeSkills,
//   index = employeeIndex,
// }) => {

//   let currentXPos = 0;

//   return (
//     <>
//       {services.map((service, serviceIndex) => {
//         const serviceId = service.get("serviceId").toString();
//         const employeeServiceStatus = employeeServiceStatuses.getIn(
//           [serviceId, "competencyStatus"],
//           COMPETENCY_STATUS.NONE,
//         );

//         const serviceXPos = currentXPos++;

//         return (
//           <React.Fragment key={serviceIndex}>
//             <GapReportCell
//               x={serviceXPos}
//               fill={grey[200]}
//               y={employeeIndex}
//               status={employeeServiceStatus}
//               to={`${root}/services/${serviceId}`}
//             />
//             {serviceCourses
//               .get(serviceId, EMPTY_SET)
//               .map((serviceCourse, serviceCourseIndex) => {
//                 const courseId = serviceCourse.get("courseId").toString();

//                 const employeeCourseCompetencyStatus = employeeCourses.getIn(
//                   [courseId, "competencyStatus"],
//                   COMPETENCY_STATUS.NONE,
//                 );

//                 const serviceCourseXPos = currentXPos++;

//                 return (
//                   <GapReportCell
//                     x={serviceCourseXPos}
//                     key={serviceCourseIndex}
//                     y={employeeIndex}
//                     status={employeeCourseCompetencyStatus}
//                     to={`${root}/employees/${employeeId}/courses/${courseId}`}
//                   />
//                 );
//               })}
//             {serviceSkills
//               .get(serviceId, EMPTY_SET)
//               .map((serviceSkill, serviceSkillIndex) => {
//                 const skillId = serviceSkill.get("skillId").toString();

//                 const employeeSkillCompetencyStatus = employeeSkills.getIn(
//                   [skillId, "competencyStatus"],
//                   COMPETENCY_STATUS.NONE,
//                 );

//                 const serviceSkillXPos = currentXPos++;

//                 return (
//                   <GapReportCell
//                     x={serviceSkillXPos}
//                     key={serviceSkillIndex}
//                     y={employeeIndex}
//                     status={employeeSkillCompetencyStatus}
//                     to={`${root}/employees/${employeeId}/skills/${skillId}`}
//                   />
//                 );
//               })}
//           </React.Fragment>
//         );
//       })}
//     </>
//   );
// };

class ServiceGapReportEmployeeRow extends PureComponent {
  render() {
    const {
      root,
      services,
      employeeId,
      serviceCourses,
      serviceSkills,
      employeeServiceStatuses,
      employeeCourses,
      employeeSkills,
      index: employeeIndex,
    } = this.props;

    let currentXPos = 0;

    return (
      <>
        {services.map((service, serviceIndex) => {
          const serviceId = service.get("serviceId").toString();
          const employeeServiceStatus = employeeServiceStatuses.getIn(
            [serviceId, "competencyStatus"],
            COMPETENCY_STATUS.NONE,
          );

          const serviceXPos = currentXPos++;

          return (
            <React.Fragment key={serviceIndex}>
              <GapReportCell
                x={serviceXPos}
                fill={grey[200]}
                y={employeeIndex}
                status={employeeServiceStatus}
                to={`${root}/services/${serviceId}`}
              />
              {serviceCourses
                .get(serviceId, EMPTY_SET)
                .map((serviceCourse, serviceCourseIndex) => {
                  const courseId = serviceCourse.get("courseId").toString();

                  const employeeCourseCompetencyStatus = employeeCourses.getIn(
                    [courseId, "competencyStatus"],
                    COMPETENCY_STATUS.NONE,
                  );

                  const serviceCourseXPos = currentXPos++;

                  return (
                    <GapReportCell
                      x={serviceCourseXPos}
                      key={serviceCourseIndex}
                      y={employeeIndex}
                      status={employeeCourseCompetencyStatus}
                      to={`${root}/employees/${employeeId}/courses/${courseId}`}
                    />
                  );
                })}
              {serviceSkills
                .get(serviceId, EMPTY_SET)
                .map((serviceSkill, serviceSkillIndex) => {
                  const skillId = serviceSkill.get("skillId").toString();

                  const employeeSkillCompetencyStatus = employeeSkills.getIn(
                    [skillId, "competencyStatus"],
                    COMPETENCY_STATUS.NONE,
                  );

                  const serviceSkillXPos = currentXPos++;

                  return (
                    <GapReportCell
                      x={serviceSkillXPos}
                      key={serviceSkillIndex}
                      y={employeeIndex}
                      status={employeeSkillCompetencyStatus}
                      to={`${root}/employees/${employeeId}/skills/${skillId}`}
                    />
                  );
                })}
            </React.Fragment>
          );
        })}
      </>
    );
  }
}

ServiceGapReportEmployeeRow.defaultProps = {
  employeeServiceStatuses: EMPTY_SET,
  employeeCourses: EMPTY_SET,
  employeeSkills: EMPTY_SET,
};

export default compose(
  connect((state, { employeeId }) => ({
    employeeServiceStatuses: getServiceGapReportEmployeeServiceStatusesFromState(
      state,
      employeeId,
    ),
    employeeCourses: getServiceGapReportEmployeeCoursesFromState(
      state,
      employeeId,
    ),
    employeeSkills: getServiceGapReportEmployeeSkillsFromState(
      state,
      employeeId,
    ),
  })),
)(ServiceGapReportEmployeeRow);
