import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { PureComponent } from "react";

import { ACTIONS, ICONS } from "app/app.constants";
import CrudBasePage from "app/components/CrudBasePage";
import { getRolesBySkill } from "features/competence.actions";
import { getActionDataStateFromState } from "app/app.selectors";
import { getRolesFromState } from "features/competence.selectors";
import routePaths from "app/routePaths";

class SkillRolesContainer extends PureComponent {
  componentDidMount() {
    const { dispatchGetRolesBySkill, skillId } = this.props;

    dispatchGetRolesBySkill(skillId);
  }

  columns = [
    {
      xs: 3,
      title: "Name",
      getSortProperty: item => item.get("name"),
    },
    {
      xs: true,
      title: "Description",
      getSortProperty: item => item.get("description"),
    },
    {
      xs: true,
      filter: {
        defaultText: "All statuses",
      },
      title: "Status",
      getSortProperty: item => item.get("status"),
    },
  ];

  getKey = role => role.get("roleId");

  render() {
    const { dataState, roles, breadcrumb } = this.props;

    return (
      <CrudBasePage
        items={roles}
        title={breadcrumb}
        dataState={dataState}
        displayAddButton={false}
        SortableListProps={{
          Icon: ICONS.ROLE,
          getKey: this.getKey,
          columns: this.columns,
          createSortableListRowProps: item => ({
            component: Link,
            to: `${routePaths.hrPortal}/roles/${item.get("roleId")}`,
          }),
        }}
      />
    );
  }
}

export default compose(
  connect(
    state => ({
      roles: getRolesFromState(state),
      dataState: getActionDataStateFromState(state, ACTIONS.GET_ROLES_BY_SKILL),
    }),
    {
      dispatchGetRolesBySkill: getRolesBySkill,
    },
  ),
)(SkillRolesContainer);
