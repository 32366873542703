import { Map, OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { RoleCourseMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case ACTIONS.CLEAR_ROLE_COURSES: {
      return initialState;
    }

    case ACTIONS.CREATE_ROLE_COURSES: {
      if (error) return state;

      return state.set(
        action.roleId.toString(),
        OrderedMap(
          payload.map(roleCourse => [
            roleCourse.roleCourseId.toString(),
            RoleCourseMapper.from(roleCourse),
          ]),
        ),
      );
    }

    case ACTIONS.UPDATE_ROLE_COURSE: {
      if (error) return state;

      return state.setIn(
        [payload.roleId.toString(), payload.roleCourseId.toString()],
        RoleCourseMapper.from(payload),
      );
    }

    case ACTIONS.DELETE_ROLE_COURSE: {
      if (error) return state;

      return state.deleteIn([
        action.roleId.toString(),
        action.roleCourseId.toString(),
      ]);
    }

    case ACTIONS.GET_EMPLOYEE_COURSES_BY_ROLE: {
      if (error) return state;

      return OrderedMap(
        payload
          .filter(roleCourse => roleCourse.roleCourseId)
          .map(roleCourse => [
            roleCourse.roleCourseId.toString(),
            RoleCourseMapper.from(roleCourse),
          ]),
      ).groupBy(roleCourse => roleCourse.get("roleId").toString());
    }

    case ACTIONS.GET_ROLE_COURSES_BY_ROLE: {
      if (error) return state;

      return OrderedMap(
        payload.roleCourses.map(roleCourse => [
          roleCourse.roleCourseId.toString(),
          RoleCourseMapper.from(roleCourse),
        ]),
      ).groupBy(roleCourse => roleCourse.get("roleId").toString());
    }

    default:
      return state;
  }
};
