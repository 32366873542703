import { compose } from "redux";
import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { SORT_DIRECTION, EMPTY_LIST } from "app/app.constants";
import SortableList from "app/components/SortableList/SortableList";
import { ServiceMapper } from "app/app.mappers";

const ServiceItemList = ({
  Icon,
  title,
  getKey,
  filter,
  columns,
  classes,
  actions,
  inherited,
  onRowClick,
  renderIcon,
  toggleEditModal,
  items = EMPTY_LIST,
  createSortableListRowProps,
  service = ServiceMapper.initial,
}) => {
  return (
    <Grid container item className={classes.root}>
      <SortableList
        Icon={Icon}
        items={items}
        getKey={getKey}
        filter={filter}
        columns={columns}
        actions={actions}
        renderIcon={renderIcon}
        defaultSortColumn={0}
        onRowClick={onRowClick}
        toggleEditModal={toggleEditModal}
        defaultSortDirection={SORT_DIRECTION.ASC}
        createSortableListRowProps={createSortableListRowProps}
        title={
          <Grid item>
            <Typography variant="h6" className={classes.title}>
              {title
                ? title
                : inherited
                ? `${service.get("name")} (Inherited)`
                : service.get("name")}
            </Typography>
          </Grid>
        }
      />
    </Grid>
  );
};

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(8),
  },
  title: {
    display: "flex",
  },
});

export default compose(withStyles(styles))(ServiceItemList);
