import Agent from "infrastructure/agent";

const getRoleGapReport = params =>
  Agent.get("/report/employee/role/gap", params);

const getEmployeeRoleGapReport = (employeeId, params) =>
  Agent.get(`/report/employee/${employeeId}/role/gap`, params);

const getCourseGapReport = params =>
  Agent.get("/report/employee/course/gap", params);

const getMyTeamRoleGapReport = params =>
  Agent.get("/report/employee/myteam/role/gap", params);

const getEmployeeSkillGapReport = params =>
  Agent.get("/report/employee/skill/gap", params);

const getMyTeamEmployeeSkillGapReport = params =>
  Agent.get("/report/employee/myteam/skill/gap", params);

const downloadRoleGapReport = params =>
  Agent.file.get("/report/employee/role/gap/download", params);

const downloadCourseGapReport = params =>
  Agent.file.get("/report/employee/course/gap/download", params);

const downloadMyTeamRoleGapReport = params =>
  Agent.file.get("/report/employee/myteam/role/gap/download", params);

const downloadEmployeeSkillGapReport = params =>
  Agent.file.get("/report/employee/skill/gap/download", params);

const downloadMyTeamEmployeeSkillGapReport = params =>
  Agent.file.get("/report/employee/myteam/skill/gap/download", params);

const getEmployeeSpecificationGapReport = params =>
  Agent.get(`/report/employee/specification/gap`, params);

const downloadSpecificationGapReport = params =>
  Agent.file.get(`/report/employee/specification/gap/download`, params);

const getEmployeeServiceGapReport = params =>
  Agent.get(`/report/employee/service/gap`, params);

const downloadServiceGapReport = params =>
  Agent.file.get(`/report/employee/service/gap/download`, params);

const getMyTeamCourseGapReport = params =>
  Agent.get("/report/employee/myteam/course/gap", params);

const downloadMyTeamCourseGapReport = params =>
  Agent.file.get("/report/employee/myteam/course/gap/download", params);

const getTrainingHistoryReport = params =>
  Agent.get(`/report/employee/trainingHistory`, params);

const downloadTrainingHistoryReport = params =>
  Agent.file.get(`/report/employee/trainingHistory/download`, params);

const getTrainingAndCmpetencyReport = params =>
  Agent.get(`/report/employee/trainingAndCompetency`, params);

const downloadTrainingAndCmpetencyReport = params =>
  Agent.file.get(`/report/employee/trainingAndCompetency/download`, params);

const getCourseTrainingHistoryReport = params =>
  Agent.get(`/report/course/trainingHistory`, params);

const downloadCourseTrainingHistoryReport = params =>
  Agent.file.get(`/report/course/trainingHistory/download`, params);

const getCourseExpiryReport = params =>
  Agent.get(`/report/course/courseExpiry`, params);

const downloadCourseExpiryReport = params =>
  Agent.file.get(`/report/course/courseExpiry/download`, params);

const getOutstandingTrainingHistoryReport = params =>
  Agent.get(`/report/course/outstandingTrainingHistory`, params);

const downloadOutstandingTrainingHistoryReport = params =>
  Agent.file.get(`/report/course/outstandingTrainingHistory/download`, params);

export default {
  getRoleGapReport,
  getEmployeeRoleGapReport,
  getMyTeamRoleGapReport,
  getEmployeeSkillGapReport,
  getMyTeamEmployeeSkillGapReport,
  downloadRoleGapReport,
  downloadMyTeamRoleGapReport,
  downloadEmployeeSkillGapReport,
  downloadMyTeamEmployeeSkillGapReport,
  downloadCourseGapReport,
  getCourseGapReport,
  getEmployeeSpecificationGapReport,
  downloadSpecificationGapReport,
  getEmployeeServiceGapReport,
  downloadServiceGapReport,
  getMyTeamCourseGapReport,
  downloadMyTeamCourseGapReport,
  getTrainingHistoryReport,
  downloadTrainingHistoryReport,
  getTrainingAndCmpetencyReport,
  downloadTrainingAndCmpetencyReport,
  getCourseTrainingHistoryReport,
  downloadCourseTrainingHistoryReport,
  getCourseExpiryReport,
  downloadCourseExpiryReport,
  getOutstandingTrainingHistoryReport,
  downloadOutstandingTrainingHistoryReport,
};
