import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { formValueSelector } from "redux-form/immutable";

import {
  EMPLOYEE_SKILL_FORM,
  CREATE_EMPLOYEE_SKILL_MODAL_ID,
} from "features/competence.constants";

import { EMPTY_SET } from "app/app.constants";
import SimpleFormModal from "app/components/SimpleFormModal";
import EmployeeSkillModalTabs from "features/components/EmployeeSkillModalTabs";
import CreateEmployeeSkillForm from "features/components/CreateEmployeeSkillForm";
import EmployeeSkillModalActions from "features/components/EmployeeSkillModalActions";

const CreateEmployeeSkillModal = ({
  classes,
  onEnter,
  onSubmit,
  activeTab,
  employeeId,
  selectedSkillId,
}) => {
  const tabs = Object.values({
    SKILL_DETAILS: {
      title: "Details",
    },
    SKILL_EVIDENCES: {
      title: "Evidence Files",
      disabled: !selectedSkillId,
    },
  });

  return (
    <SimpleFormModal
      Icon={null}
      submitText="Add"
      onEnter={onEnter}
      title="Add Skill"
      onSubmit={onSubmit}
      displaySubmitBtn={false}
      displayCancelBtn={false}
      Tabs={EmployeeSkillModalTabs}
      component={CreateEmployeeSkillForm}
      modalId={CREATE_EMPLOYEE_SKILL_MODAL_ID}
      ModalActions={EmployeeSkillModalActions}
      ModalActionsProps={{
        tabs,
        activeTab,
        selectedSkillId,
      }}
      TabsProps={{
        tabs,
        selectedSkillId,
      }}
      FormProps={{
        activeTab,
        employeeId,
        selectedSkillId,
      }}
      DialogProps={{
        maxWidth: "md",
        fullWidth: true,
        classes: {
          paper: classes.dialogPaper,
        },
      }}
      form={{
        form: EMPLOYEE_SKILL_FORM.ID,
        initialValues: {
          [EMPLOYEE_SKILL_FORM.ACTIVE_TAB]: 0,
          [EMPLOYEE_SKILL_FORM.FILE_EVIDENCES]: EMPTY_SET,
        },
      }}
    />
  );
};

const formSelector = formValueSelector(EMPLOYEE_SKILL_FORM.ID);

const styles = {
  dialogPaper: {
    height: "100%",
    maxHeight: 750,
  },
};

export default compose(
  connect(state => ({
    activeTab: formSelector(state, EMPLOYEE_SKILL_FORM.ACTIVE_TAB),
    selectedSkillId: formSelector(state, EMPLOYEE_SKILL_FORM.SKILL_ID),
  })),
  withStyles(styles),
)(CreateEmployeeSkillModal);
