import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { getCurrentEmployeeFromState } from "app/app.selectors";
import EmployeeCoursesBasePage from "features/components/EmployeeCoursesBasePage";

class MyCoursesContainer extends PureComponent {
  render() {
    const { Icon, currentEmployee, breadcrumb, path } = this.props;

    return (
      <EmployeeCoursesBasePage
        path={path}
        Icon={Icon}
        title={breadcrumb}
        employeeId={currentEmployee.get("employeeId")}
      />
    );
  }
}

export default compose(
  connect(state => ({
    currentEmployee: getCurrentEmployeeFromState(state),
  })),
)(MyCoursesContainer);
