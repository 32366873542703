import moment from "moment";
import memoize from "lodash/memoize";
import { List, Map, Set } from "immutable";

import fileMappers from "app/mappers/file.mappers";
import { EMPTY_MAP, EMPTY_LIST } from "app/app.constants";

const START_END_DATE_FORMAT = "YYYY-MM-DD";

export const validityMonthsToString = memoize(validityMonths =>
  validityMonths ? `${validityMonths} Months` : "Always",
);

export const isCourseActiveToString = memoize(
  isActive =>
    ({
      [true]: "Active",
      [false]: "Inactive",
    }[isActive]),
);

export const isRoleActiveToString = isCourseActiveToString;

export const AppRoleMapper = {
  initial: EMPTY_MAP,
  from: appRole => Map(appRole),
};

export const UserMapper = {
  initial: () =>
    Map({
      appRoles: EMPTY_LIST,
    }),
  from: ({ appRoles, ...user }) =>
    Map({
      appRoles: Set(appRoles),
      ...user,
    }),
};

export const CourseMapper = {
  initial: EMPTY_MAP,
  from: ({ modified, countries, ...course }) =>
    Map({
      modified: modified ? moment(modified) : modified,
      status: isCourseActiveToString(course.isActive),
      validityMonthsString: validityMonthsToString(course.validityMonths),
      countries: List(countries).map(CountryMapper.from),
      ...course,
    }),
};

export const CollectionMapper = {
  initial: EMPTY_MAP,
  from: ({ modified, countries, ...collection }) =>
    Map({
      modified: modified ? moment(modified) : modified,
      countries: List(countries).map(CountryMapper.from),
      ...collection,
    }),
};

export const DepartmentMapper = {
  initial: EMPTY_MAP,
  from: department => Map(department),
};

export const EmployeeMapper = {
  initial: Map({
    employeeId: NaN,
  }),
  from: ({
    firstName,
    lastName,
    dateOfBirth,
    hiredFrom,
    hiredTo,
    jobTitle,
    displayName,
    ...employee
  }) =>
    Map({
      firstName,
      lastName,
      displayName: displayName || `${firstName} ${lastName}`,
      dateOfBirth: dateOfBirth ? moment(dateOfBirth) : dateOfBirth,
      hiredFrom: hiredFrom ? moment(hiredFrom) : hiredFrom,
      hiredTo: hiredTo ? moment(hiredTo) : hiredTo,
      position: jobTitle,
      ...employee,
    }),
};

export const RoleMapper = {
  initial: Map(),
  from: ({
    modified,
    owner,
    countries,
    availableCourses,
    availableSkills,
    ...role
  }) =>
    Map({
      modified: modified ? moment(modified) : modified,
      owner: owner ? EmployeeMapper.from(owner) : owner,
      status: isRoleActiveToString(role.isActive),
      countries: List(countries).map(CountryMapper.from),
      availableCoursesIds: List(availableCourses).map(course =>
        course.get("courseId"),
      ),
      availableSkillsIds: List(availableSkills).map(skill =>
        skill.get("skillId"),
      ),
      ...role,
    }),
};

export const CourseGroupMapper = {
  initial: () => Map(),
  from: ({ modified, ...courseGroup }) =>
    Map({
      modified: moment(modified),
      ...courseGroup,
    }),
};

export const CourseTypeMapper = {
  initial: () => Map(),
  from: ({ modified, ...courseType }) =>
    Map({
      modified: moment(modified),
      ...courseType,
    }),
};

export const EmployeeRoleMapper = {
  initial: Map(),
  from: ({ employee, expirationDate, modified, role, ...employeeRole }) =>
    Map({
      expirationDate: expirationDate ? moment(expirationDate) : null,
      modified: modified ? moment(modified) : modified,
      treeMin: role?.treeMin,
      treeMax: role?.treeMax,
      name: role?.name,
      parentId: role?.parentId,
      parentName: role?.parentName,
      roleId: role?.roleId,
      role: role ? RoleMapper.from(role) : role,
      ...employeeRole,
    }),
};

export const RoleCourseMapper = {
  from: roleCourse => Map(roleCourse),
};

export const EmployeeEducationMapper = {
  from: ({ from, to, files, ...employeeEducation }) =>
    Map({
      to: moment(to),
      from: moment(from),
      files: Set(files).map(fileMappers.File.from),
      ...employeeEducation,
    }),
  to: ({ from, to, ...education }) => ({
    from: from ? from.format(START_END_DATE_FORMAT) : from,
    to: to ? to.format(START_END_DATE_FORMAT) : to,
    ...education,
  }),
};

export const TrainingHistoryMapper = {
  to: ({ dateRange, start, end, ...trainingHistory }) => ({
    start: dateRange?.start
      ? dateRange.start.format(START_END_DATE_FORMAT)
      : null,
    end: dateRange?.end ? dateRange.end.format(START_END_DATE_FORMAT) : null,
    ...trainingHistory,
  }),
};

export const CourseTrainingHistoryMapper = {
  to: ({ dateRange, start, end, ...trainingHistory }) => ({
    start: dateRange?.start
      ? dateRange.start.format(START_END_DATE_FORMAT)
      : null,
    end: dateRange?.end ? dateRange.end.format(START_END_DATE_FORMAT) : null,
    ...trainingHistory,
  }),
};

export const CourseExpiryFilterMapper = {
  to: ({ dateRange, start, end, ...courseExpiry }) => ({
    start: dateRange?.start
      ? dateRange.start.format(START_END_DATE_FORMAT)
      : null,
    end: dateRange?.end ? dateRange.end.format(START_END_DATE_FORMAT) : null,
    ...courseExpiry,
  }),
};

export const OutstandingTrainingHistoryMapper = {
  to: ({ dateRange, start, end, ...trainingHistory }) => ({
    start: dateRange?.start
      ? dateRange.start.format(START_END_DATE_FORMAT)
      : null,
    end: dateRange?.end ? dateRange.end.format(START_END_DATE_FORMAT) : null,
    ...trainingHistory,
  }),
};

export const CompetencyCategoryMapper = {
  from: competencyCategory => Map(competencyCategory),
};

export const WorkHistoryMapper = {
  from: ({ from, to, files, ...workHistory }) =>
    Map({
      from: moment.utc(from).local(),
      to: to && moment.utc(to).local(),
      files: Set(files).map(fileMappers.File.from),
      ...workHistory,
    }),
  to: ({ from, to, ...workHistory }) => ({
    from: from ? from.format(START_END_DATE_FORMAT) : from,
    to: to ? to.format(START_END_DATE_FORMAT) : to,
    ...workHistory,
  }),
};

export const CountryMapper = {
  from: country => Map(country),
};

export const SkillGroupMapper = {
  initial: () => Map(),
  from: ({ modified, ...skillGroup }) =>
    Map({
      modified: moment(modified),
      ...skillGroup,
    }),
};

export const SkillTypeMapper = {
  from: ({ modified, ...skillType }) =>
    Map({
      modified: moment(modified),
      ...skillType,
    }),
};

export const SkillMapper = {
  initial: EMPTY_MAP,
  from: ({ modified, countries, ...skill }) =>
    Map({
      modified: modified ? moment(modified) : modified,
      countries: List(countries).map(CountryMapper.from),
      validityMonthsString: validityMonthsToString(skill.validityMonths),
      ...skill,
    }),
};

export const ExperienceRecordItemMapper = {
  initial: EMPTY_MAP,
  from: ({ ...experienceRecordItem }) =>
    Map({
      ...experienceRecordItem,
    }),
};

export const ExperienceRecordItemReportMapper = {
  initial: EMPTY_MAP,
  from: ({ ...experienceRecordItemReport }) =>
    Map({
      ...experienceRecordItemReport,
    }),
};

export const ExperienceRecordsMapper = {
  initial: EMPTY_MAP,
  to: ({ employees, ...otherProps }) => {
    var empList = [];
    employees.forEach(employee => {
      empList.push({ employeeId: employee.employeeId, ...otherProps });
    });

    return empList;
  },
};

export const ExperienceRecordMapper = {
  initial: EMPTY_MAP,
  from: ({
    modified,
    from,
    to,
    approvalDate,
    employee,
    client,
    validator,
    discipline,
    position,
    ...experienceRecord
  }) =>
    Map({
      modified: modified ? moment(modified) : modified,
      from: from && moment(from),
      to: to && moment(to),
      approvalDate: approvalDate && moment(approvalDate),
      experienceClientName: client?.name,
      experienceDisciplineName: discipline?.name,
      performedAs: position?.name,
      employeeDepartment: employee.departmentName,
      employeePosition: employee.jobTitle,
      ...experienceRecord,
    }),
};

export const ExperienceRecordValidatorMapper = {
  initial: Map({
    employeeId: NaN,
  }),
  from: ({ ...validator }) =>
    Map({
      ...validator,
    }),
};

export const OrganizationMapper = {
  initial: EMPTY_MAP,
  from: ({ ...client }) =>
    Map({
      ...client,
    }),
};

export const ExperiencePositionMapper = {
  initial: EMPTY_MAP,
  from: ({ ...position }) =>
    Map({
      ...position,
    }),
};

export const ExperienceCounterMapper = {
  from: counter => Map(counter),
};

export const ExperienceItemMapper = {
  from: item => Map(item),
};

export const ExperienceCategoryMapper = {
  initial: EMPTY_MAP,
  from: ({ counters, items, ...category }) =>
    Map({
      counters: List(counters).map(ExperienceCounterMapper.from),
      items: List(items).map(ExperienceItemMapper.from),

      ...category,
    }),
};

export const FacilityMapper = {
  initial: EMPTY_MAP,
  from: ({ ...facility }) =>
    Map({
      ...facility,
    }),
};

export const ExperienceDisciplineMapper = {
  initial: EMPTY_MAP,
  from: ({ ...discipline }) =>
    Map({
      ...discipline,
    }),
};

export const RoleSkillMapper = {
  from: ({ modified, ...roleSkill }) =>
    Map({
      modified: modified && moment(modified),
      validityMonthsString: validityMonthsToString(roleSkill.validityMonths),
      ...roleSkill,
    }),
};

export const EmployeeRoleSkillMapper = {
  initial: EMPTY_MAP,
  from: ({
    expirationDate,
    fileEvidences,
    skillEvidences,
    ...employeeRoleSkill
  }) =>
    Map({
      expirationDate: expirationDate ? moment(expirationDate) : null,
      fileEvidences: List(fileEvidences).map(fileMappers.File.from),
      skillEvidences: SkillEvidenceMapper.from(skillEvidences),
      ...employeeRoleSkill,
    }),
};

export const EmployeeSkillsByRoleMapper = {
  from: ({ employeeRoleSkills, ...employeeSkillsByRole }) =>
    Map({
      employeeRoleSkills: List(employeeRoleSkills).map(
        EmployeeRoleSkillMapper.from,
      ),
      ...employeeSkillsByRole,
    }),
};

export const EmployeeSkillMapper = {
  initial: EMPTY_MAP,
  from: ({ ...employeeSkill }) =>
    Map({
      ...employeeSkill,
    }),
  to: ({ ...employeeSkill }) => ({
    ...employeeSkill,
  }),
};

export const SkillFileEvidenceMapper = {
  initial: EMPTY_MAP,
  from: ({ registrationDate, expirationDate, ...skillFileEvidence }) =>
    Map({
      registrationDate: moment(registrationDate),
      expirationDate: expirationDate && moment(expirationDate),
      ...skillFileEvidence,
    }),
  to: ({ when, ...skillFileEvidence }) => ({
    registrationDate: when ? when.format(START_END_DATE_FORMAT) : when,
    ...skillFileEvidence,
  }),
};

export const SkillEvidenceMapper = {
  initial: EMPTY_MAP,
  from: ({
    registrationDate,
    expirationDate,
    employeeSkillEvidenceFiles,
    ...skillEvidence
  }) =>
    Map({
      registrationDate: moment(registrationDate),
      expirationDate: expirationDate && moment(expirationDate),
      files: Set(employeeSkillEvidenceFiles).map(fileMappers.File.from),
      ...skillEvidence,
    }),
  to: ({ when, employeeSkillEvidenceFiles, ...skillEvidence }) => ({
    registrationDate: when ? when.format(START_END_DATE_FORMAT) : when,
    employeeSkillEvidenceFiles: Set(employeeSkillEvidenceFiles).map(
      fileMappers.File.from,
    ),
    ...skillEvidence,
  }),
};

export const SpecificationMapper = {
  initial: EMPTY_MAP,
  from: ({ modified, countries, ...specification }) =>
    Map({
      modified: modified ? moment(modified) : modified,
      countries: List(countries).map(CountryMapper.from),
      ...specification,
    }),
};

export const SpecificationCourseMapper = {
  from: ({ modified, ...specificationCourse }) =>
    Map({
      courseStatus: isCourseActiveToString(specificationCourse.courseIsActive),
      courseValidityMonthsString: validityMonthsToString(
        specificationCourse.courseValidityMonths,
      ),
      modified: modified && moment(modified),
      ...specificationCourse,
    }),
};

export const SpecificationSkillMapper = {
  from: ({ modified, ...specificationSkill }) =>
    Map({
      modified: modified && moment(modified),
      skillValidityMonthsString: validityMonthsToString(
        specificationSkill.validityMonths,
      ),
      ...specificationSkill,
    }),
};

export const ServiceMapper = {
  initial: EMPTY_MAP,
  from: ({ modified, countries, ...service }) =>
    Map({
      modified: modified ? moment(modified) : modified,
      countries: List(countries).map(CountryMapper.from),
      ...service,
    }),
};

export const ServiceCourseMapper = {
  from: ({ modified, ...serviceCourse }) =>
    Map({
      courseStatus: isCourseActiveToString(serviceCourse.courseIsActive),
      courseValidityMonthsString: validityMonthsToString(
        serviceCourse.courseValidityMonths,
      ),
      modified: modified && moment(modified),
      ...serviceCourse,
    }),
};

export const ServiceSkillMapper = {
  from: ({ modified, ...serviceSkill }) =>
    Map({
      modified: modified && moment(modified),
      skillValidityMonthsString: validityMonthsToString(
        serviceSkill.validityMonths,
      ),
      ...serviceSkill,
    }),
};

export const TrainingAndCompetencyItemMapper = {
  initial: EMPTY_MAP,
  from: ({ sortOrder, ...item }) =>
    Map({
      id: sortOrder,
      sortOrder: sortOrder,
      ...item,
    }),
};

export const TrainingAndCompetencyEmployeeMapper = {
  initial: Map({
    employeeId: NaN,
  }),
  from: ({ trainingAndCompetencyItems, displayName, ...employee }) =>
    Map({
      trainingAndCompetencyItems: Map(
        trainingAndCompetencyItems.map((item, index) => [
          index.toString(),
          TrainingAndCompetencyItemMapper.from(item),
        ]),
      ),
      name: displayName,
      ...employee,
    }),
};

export const CourseExpiryItemMapper = {
  initial: EMPTY_MAP,
  from: ({ ...item }) =>
    Map({
      ...item,
    }),
};

export const CourseExpiryMapper = {
  initial: Map({
    employeeId: NaN,
  }),
  from: ({ expiryItems, ...course }) =>
    Map({
      expiryItems: Map(
        expiryItems.map((item, index) => [
          index.toString(),
          CourseExpiryItemMapper.from(item),
        ]),
      ),
      ...course,
    }),
};
