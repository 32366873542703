import React from "react";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";

import Tab from "app/components/Tab/Tab";
import { invokeIfFunction } from "utils/app.util";

const ModalTab = ({
  classes,
  children,
  value,
  selected,
  onClick,
  disabled,
}) => (
  <Tab
    disabled={disabled}
    value={value}
    selected={selected}
    onClick={() => invokeIfFunction(onClick, value)}
    classes={{
      item: classes.tabItem,
      itemSelected: classes.tabItemSelected,
    }}
  >
    {children}
  </Tab>
);

const styles = theme => ({
  tabItemSelected: {
    backgroundColor: theme.palette.background.paper,
  },
  tabItem: {
    width: 150,
    display: "block",
    minHeight: "100%",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    "& svg": {
      width: "50%",
      height: "50%",
      fill: theme.altus.background.header,
    },
    "& p": {
      fontWeight: 700,
    },
  },
  icon: {
    width: "50%",
    height: "50%",
  },
});

export default compose(React.memo, withStyles(styles))(ModalTab);
