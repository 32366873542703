import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Tooltip } from "@material-ui/core";

import routePaths from "app/routePaths";
import { isAdminOrHR } from "utils/app.util";
import { getCurrentUserFromState } from "app/app.selectors";
import GapReportValueCell from "features/components/GapReport/GapReportValueCell";

class CourseGapReportHeader extends PureComponent {
  render() {
    const { courses, classes, currentUser } = this.props;

    const currentUserIsAdminOrHr = isAdminOrHR(currentUser);

    return (
      <Grid item container wrap="nowrap" className={classes.root}>
        {courses.map(course => (
          <Tooltip title={course.get("name")} key={course.get("courseId")}>
            {currentUserIsAdminOrHr ? (
              <GapReportValueCell
                target="_blank"
                component={Link}
                to={`${routePaths.hrPortal}/courses/${course.get("courseId")}`}
              >
                <Typography
                  noWrap
                  variant="caption"
                  className={classes.headerText}
                >
                  {course.get("name")}
                </Typography>
              </GapReportValueCell>
            ) : (
              <GapReportValueCell>
                <Typography
                  noWrap
                  variant="caption"
                  className={classes.headerText}
                >
                  {course.get("name")}
                </Typography>
              </GapReportValueCell>
            )}
          </Tooltip>
        ))}
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    top: 0,
    zIndex: 3,
    position: "sticky",
    height: theme.gapReport.header.height,
    borderTop: theme.gapReport.defaultBorder,
    borderRight: theme.gapReport.defaultBorder,
    backgroundColor: theme.palette.background.default,
  },
  headerText: {
    transform: "rotate(-90deg)",
    minWidth: theme.gapReport.header.height - theme.spacing(2),
    maxWidth: theme.gapReport.header.height - theme.spacing(2),
  },
});

export default compose(
  connect(state => ({
    currentUser: getCurrentUserFromState(state),
  })),
  withStyles(styles),
)(CourseGapReportHeader);
