import { Map } from "immutable";
import { ACTIONS } from "app/app.constants";
import { EmployeeRoleMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_EMPLOYEE_ROLES: {
      if (error) return state;

      return state.set(
        action.employeeId.toString(),
        Map(
          payload.map(employeeRole => [
            employeeRole.roleId.toString(),
            EmployeeRoleMapper.from(employeeRole),
          ]),
        ),
      );
    }

    case ACTIONS.CREATE_EMPLOYEE_ROLES: {
      if (error) return state;

      return state.mergeIn(
        [action.employeeId.toString()],
        Map(
          payload.map(employeeRole => [
            employeeRole.roleId.toString(),
            EmployeeRoleMapper.from(employeeRole),
          ]),
        ),
      );
    }

    case ACTIONS.DELETE_EMPLOYEE_ROLE: {
      if (error) return state;

      return state.deleteIn([
        action.employeeId.toString(),
        action.roleId.toString(),
      ]);
    }

    case ACTIONS.GET_EMPLOYEE_COURSES_BY_ROLE: {
      if (error) return state;

      return state.update(action.employeeId.toString(), employeeRoles =>
        Map(
          payload
            .filter(employeeRole => employeeRole.roleId)
            .map(employeeRole => [
              employeeRole.roleId.toString(),
              EmployeeRoleMapper.from(employeeRole),
            ]),
        ).merge(employeeRoles),
      );
    }

    default:
      return state;
  }
};
