import { compose } from "redux";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Helmet } from "altus-ui-components";

import {
  getRoleGapReport,
  hrRoleGapReportOnLoad,
  downloadAndSaveRoleGapReport,
} from "features/competence.actions";

import { APP_ROOT } from "app/app.constants";
import RoleGapReportBasePage from "features/components/RoleGapReport/RoleGapReportBasePage";

const HRRoleGapReportContainer = ({
  breadcrumb,
  dispatchOnLoad,
  dispatchGetRoleGapReport,
  dispatchDownloadRoleGapReport,
}) => {
  useEffect(() => {
    dispatchOnLoad();
  }, [dispatchOnLoad]);

  return (
    <>
      <Helmet titleTemplate="%s">
        <title>{breadcrumb}</title>
      </Helmet>
      <RoleGapReportBasePage
        root={APP_ROOT.HR}
        title={breadcrumb}
        getRoleGapReport={dispatchGetRoleGapReport}
        downloadRoleGapReport={dispatchDownloadRoleGapReport}
      />
    </>
  );
};

export default compose(
  connect(null, {
    dispatchOnLoad: hrRoleGapReportOnLoad,
    dispatchGetRoleGapReport: getRoleGapReport,
    dispatchDownloadRoleGapReport: downloadAndSaveRoleGapReport,
  }),
  React.memo,
)(HRRoleGapReportContainer);
