import { compose } from "redux";
import { connect } from "react-redux";
import React, { useEffect } from "react";

import { RouteContainer, NavTabsContainer } from "altus-ui-components";

import { setRouteCount } from "utils/route.util";
import { experienceRecordOnLoad } from "features/competence.actions";

import ExperienceRecordDetailsHeader from "features/experience/ExperienceRecordDetailsHeader";

const ExperienceRecordRouteContainer = ({
  experienceRecordId,
  employeeId,
  routes,
  dispatchOnLoad,
}) => {
  useEffect(() => {
    dispatchOnLoad(employeeId, experienceRecordId);
  }, [dispatchOnLoad, experienceRecordId, employeeId]);

  return (
    <>
      <ExperienceRecordDetailsHeader experienceRecordId={experienceRecordId} />
      <NavTabsContainer routes={routes.map(route => setRouteCount(route))} />
      <RouteContainer
        routes={routes}
        RouteProps={{
          employeeId,
        }}
      />
    </>
  );
};

export default compose(
  connect(null, {
    dispatchOnLoad: experienceRecordOnLoad,
  }),
)(ExperienceRecordRouteContainer);
