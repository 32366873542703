import { compose } from "redux";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";
import { Field, Fields } from "redux-form/immutable";

import { required } from "utils/validation.util";
import TextField from "app/components/form/TextField";
import { WORK_HISTORY_FORM } from "features/competence.constants";
import DatePickerField from "app/components/form/DatePickerField";
import CategorySelectField from "app/components/form/CategorySelectField";
import WorkHistoryFileUpload from "features/components/WorkHistoryFileUpload";
import FileUploadField from "app/components/form/FileUploadField/FileUploadField";

class WorkHistoryForm extends PureComponent {
  render() {
    const { employeeId, selectedWorkHistoryId, onSubmit } = this.props;

    return (
      <form id={WORK_HISTORY_FORM.ID} onSubmit={onSubmit}>
        <Grid container direction="column" spacing={1}>
          <Grid container item>
            <Field
              autoFocus
              required
              label="Title"
              validate={[required]}
              component={TextField}
              name={WORK_HISTORY_FORM.TITLE}
            />
          </Grid>
          <Grid item container>
            <Field
              required
              label="Company"
              component={TextField}
              validate={[required]}
              name={WORK_HISTORY_FORM.COMPANY}
            />
          </Grid>
          <Grid item container>
            <Field
              multiline
              rows={5}
              label="Description"
              component={TextField}
              name={WORK_HISTORY_FORM.DESCRIPTION}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={6}>
              <Field
                validate={[required]}
                component={DatePickerField}
                name={WORK_HISTORY_FORM.FROM}
                DatePickerProps={{
                  required: true,
                  margin: "normal",
                  variant: "filled",
                  label: "From",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={DatePickerField}
                name={WORK_HISTORY_FORM.TO}
                DatePickerProps={{
                  margin: "normal",
                  variant: "filled",
                  label: "To",
                }}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Fields
              validate={[required]}
              component={CategorySelectField}
              names={[
                WORK_HISTORY_FORM.PARENT_COMPETENCY_CATEGORY_ID,
                WORK_HISTORY_FORM.COMPETENCY_CATEGORY_ID,
              ]}
            />
          </Grid>
          <Grid item />
          <Grid container item>
            <Field
              multiple={true}
              label="Attachment"
              component={FileUploadField}
              name={WORK_HISTORY_FORM.FILES}
            >
              {({
                value,
                rootProps,
                removeFile,
                isDragActive,
                openFileDialog,
              }) => (
                <WorkHistoryFileUpload
                  value={value}
                  employeeId={employeeId}
                  workHistoryId={selectedWorkHistoryId}
                  rootProps={rootProps}
                  removeFile={removeFile}
                  isDragActive={isDragActive}
                  openFileDialog={openFileDialog}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default compose(
  connect((state, { formValueSelector }) => ({
    selectedWorkHistoryId: formValueSelector(
      state,
      WORK_HISTORY_FORM.WORK_HISTORY_ID,
    ),
  })),
)(WorkHistoryForm);
