import React, { PureComponent } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Helmet } from "altus-ui-components";

import {
  myTeamSkillGapReportOnLoad,
  getMyTeamEmployeeSkillGapReport,
  downloadAndSaveMyTeamSkillGapReport,
} from "features/competence.actions";

import { APP_ROOT } from "app/app.constants";
import SkillGapReportBasePage from "features/components/SkillGapReport/SkillGapReportBasePage";

class MyTeamSkillGapReportContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad } = this.props;

    dispatchOnLoad();
  }

  render() {
    const {
      breadcrumb,
      dispatchGetMyTeamEmployeeSkillGapReport,
      dispatchDownloadMyTeamEmployeeSkillGapReport,
    } = this.props;

    return (
      <>
        <Helmet titleTemplate="%s">
          <title>{breadcrumb}</title>
        </Helmet>
        <SkillGapReportBasePage
          title={breadcrumb}
          root={APP_ROOT.MY_TEAM}
          getSkillGapReport={dispatchGetMyTeamEmployeeSkillGapReport}
          downloadSkillGapReport={dispatchDownloadMyTeamEmployeeSkillGapReport}
        />
      </>
    );
  }
}

export default compose(
  connect(null, {
    dispatchOnLoad: myTeamSkillGapReportOnLoad,
    dispatchGetMyTeamEmployeeSkillGapReport: getMyTeamEmployeeSkillGapReport,
    dispatchDownloadMyTeamEmployeeSkillGapReport: downloadAndSaveMyTeamSkillGapReport,
  }),
)(MyTeamSkillGapReportContainer);
