import { compose } from "redux";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import React, { PureComponent } from "react";
import { reduxForm, initialize } from "redux-form/immutable";

import { Grid } from "@material-ui/core";

import { updateSkill, skillDetailsOnLoad } from "features/competence.actions";

import { ACTIONS } from "app/app.constants";
import { getActionDataStateFromState } from "app/app.selectors";
import { getSkillFromState } from "features/competence.selectors";
import { SKILL_FORM } from "features/competence.constants";
import { SkillMapper } from "app/app.mappers";
import SkillForm from "features/hr/skills/components/SkillForm";
import { BasePage } from "altus-ui-components";

class SkillDetailsContainer extends PureComponent {
  componentDidMount() {
    const { dispatchSkillDetailsOnLoad, skillId } = this.props;
    dispatchSkillDetailsOnLoad(skillId);
  }

  render() {
    const { skill, dataState, breadcrumb } = this.props;

    return (
      <>
        <BasePage title={breadcrumb} dataState={dataState}>
          <Grid container item spacing={4}>
            <Grid container item xs={12} md={8}>
              <SkillForm skill={skill}></SkillForm>
            </Grid>
          </Grid>
        </BasePage>
      </>
    );
  }
}

SkillDetailsContainer.defaultProps = {
  skill: SkillMapper.initial,
};

export default compose(
  connect(
    (state, { skillId }) => ({
      dataState: getActionDataStateFromState(state, ACTIONS.GET_SKILL),
      skillId: skillId,
      skill: getSkillFromState(state, skillId),
    }),
    {
      dispatchUpdateSkill: updateSkill,
      dispatchSkillDetailsOnLoad: skillDetailsOnLoad,
    },
  ),
  reduxForm({
    form: SKILL_FORM.ID,
    onChange: debounce((values, dispatch, props) => {
      const { dirty, skillId } = props;

      if (dirty) {
        dispatch(updateSkill(skillId, values.toJS())).then(skill =>
          dispatch(initialize(SKILL_FORM.ID, SkillMapper.from(skill))),
        );
      }
    }, 1000),
  }),
)(SkillDetailsContainer);
