import { compose } from "redux";
import { connect } from "react-redux";
import { generatePath } from "react-router";
import React, { useCallback, useEffect } from "react";
import { Grid, Button, Typography, Box, Tooltip } from "@material-ui/core";

import { useToggle } from "altus-hooks";
import { BasePage, NoContentBasePage } from "altus-ui-components";

import {
  redirectToPath,
  submitExperienceRecord,
  getExperienceRecordCategories,
} from "features/competence.actions";

import {
  getExperienceCategoriesFromState,
  getExperienceRecordAvailablePositionsFromState,
  getExperienceRecordAvailableValidatorsFromState,
  getExperienceRecordFromState,
  getExperienceRecordItemsFromState,
} from "features/competence.selectors";

import { ACTIONS, EMPTY_MAP } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import ExperienceRecordDetails from "features/experience/ExperienceRecordDetails";
import ExperienceRecordSubmitModal from "features/experience/ExperienceRecordSubmitModal";
import ExperienceRecordValidatorSelector from "features/experience/ExperienceRecordValidatorSelector";

const ExperienceRecordSummaryContainer = ({
  redirectPath,
  employeeId,
  breadcrumb,
  dataState,
  experienceRecord = EMPTY_MAP,
  dispatchRedirect,
  experienceRecordId,
  experienceRecordItems = EMPTY_MAP,
  experienceRecordCategories,
  dispatchSubmitExperienceRecord,
  experienceRecordAvailablePositions,
  experienceRecordAvailableValidators,
  dispatchGetExperienceRecordCategories,
}) => {
  const [open, toggleModal] = useToggle();

  useEffect(() => {
    dispatchGetExperienceRecordCategories(experienceRecordId);
  }, [experienceRecordId, dispatchGetExperienceRecordCategories]);

  const onSubmitExperienceSuccess = useCallback(() => {
    toggleModal();

    return dispatchRedirect(
      `${generatePath(redirectPath, {
        employeeId,
      })}`,
    );
  }, [redirectPath, dispatchRedirect, toggleModal, employeeId]);

  const onSubmitExperience = useCallback(() => {
    dispatchSubmitExperienceRecord(employeeId, experienceRecordId).then(() =>
      toggleModal(),
    );
  }, [
    dispatchSubmitExperienceRecord,
    toggleModal,
    employeeId,
    experienceRecordId,
  ]);

  if (!dataState.isLoading() && !experienceRecordItems.size) {
    return (
      <NoContentBasePage
        Icon={null}
        header="No experiences recorded yet"
        description="Go to the Record tab to start registering experiences"
      />
    );
  }
  var canSubmit =
    experienceRecord.get("positionId") &&
    experienceRecord.get("to") &&
    experienceRecord.get("validatorId");

  return (
    <BasePage title={breadcrumb} dataState={dataState}>
      <Grid container item component={Box} paddingBottom={2}>
        <Typography variant="subtitle2">
          <i>Please review information before submitting record...</i>
        </Typography>
      </Grid>
      <ExperienceRecordDetails
        readOnly={true}
        employeeId={employeeId}
        experienceRecord={experienceRecord}
        experienceRecordId={experienceRecordId}
        experienceRecordItems={experienceRecordItems}
        experienceRecordCategories={experienceRecordCategories}
        experienceRecordAvailablePositions={experienceRecordAvailablePositions}
      />
      <Grid container item justifyContent="center">
        <ExperienceRecordValidatorSelector
          readOnly={true}
          experienceRecord={experienceRecord}
          availableValidators={experienceRecordAvailableValidators}
        />
        {!experienceRecord.get("submitted") && (
          <Grid item>
            <Tooltip
              title={
                !canSubmit
                  ? "Validator, Performed As and To date have to be filled"
                  : ""
              }
            >
              <Grid>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  disabled={!canSubmit}
                  onClick={onSubmitExperience}
                >
                  Submit
                </Button>
              </Grid>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <ExperienceRecordSubmitModal
        open={open}
        handleClose={onSubmitExperienceSuccess}
      />
    </BasePage>
  );
};

export default compose(
  connect(
    (state, { experienceRecordId }) => ({
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_EXPERIENCE_RECORD,
        ACTIONS.UPDATE_EXPERIENCE_RECORD,
        ACTIONS.SUBMIT_EXPERIENCE_RECORD,
        ACTIONS.GET_EXPERIENCE_RECORD_ITEMS,
        ACTIONS.GET_STATS_FOR_EXPERIENCE_RECORD,
        ACTIONS.GET_EXPERIENCE_RECORD_CATEGORIES,
      ),
      experienceRecord: getExperienceRecordFromState(state, experienceRecordId),
      experienceRecordAvailablePositions: getExperienceRecordAvailablePositionsFromState(
        state,
      ),
      experienceRecordAvailableValidators: getExperienceRecordAvailableValidatorsFromState(
        state,
      ),
      experienceRecordCategories: getExperienceCategoriesFromState(state),
      experienceRecordItems: getExperienceRecordItemsFromState(
        state,
        experienceRecordId,
      ),
    }),
    {
      dispatchRedirect: redirectToPath,
      dispatchSubmitExperienceRecord: submitExperienceRecord,
      dispatchGetExperienceRecordCategories: getExperienceRecordCategories,
    },
  ),
)(ExperienceRecordSummaryContainer);
