import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import Delete from "@material-ui/icons/Delete";
import { Tooltip, IconButton } from "@material-ui/core";

import {
  getCourseGroups,
  editCourseGroupOnSubmit,
  createCourseGroupOnSubmit,
  initializeEditCourseGroup,
  deleteCourseGroupAskConfirmation,
} from "features/competence.actions";

import { ACTIONS, ICONS } from "app/app.constants";
import CrudBasePage from "app/components/CrudBasePage";
import { getActionDataStateFromState } from "app/app.selectors";
import { COURSE_GROUP_FORM } from "features/competence.constants";
import { getCourseGroupsFromState } from "features/competence.selectors";
import CourseGroupForm from "features/settings/components/CourseGroupForm";

class SettingsCourseGroupsContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad } = this.props;
    dispatchOnLoad();
  }

  columns = [
    {
      xs: true,
      title: "Name",
      getSortProperty: item => item.get("name"),
    },
  ];

  actions = [
    {
      getValue: item => (
        <Tooltip title="Delete">
          <IconButton
            onClick={() => this.props.dispatchDeleteCourseGroup(item)}
          >
            <Delete fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  render() {
    const {
      dataState,
      breadcrumb,
      courseGroups,
      dispatchEditCourseGroupOnSubmit,
      dispatchCreateCourseGroupOnSubmit,
      dispatchInitializeEditCourseGroup,
    } = this.props;

    return (
      <CrudBasePage
        displayAddButton
        displaySearchField
        Icon={ICONS.COURSE}
        items={courseGroups}
        title={breadcrumb}
        dataState={dataState}
        CreateEntityFormComponent={CourseGroupForm}
        EditEntityFormComponent={CourseGroupForm}
        createEntityForm={{
          form: COURSE_GROUP_FORM.ID,
        }}
        editEntityForm={{
          form: COURSE_GROUP_FORM.ID,
        }}
        CreateModalProps={{
          submitText: "Add",
          title: "Add course group",
          onSubmit: dispatchCreateCourseGroupOnSubmit,
        }}
        EditModalProps={{
          submitText: "Save",
          title: "Edit course group",
          formTitleField: COURSE_GROUP_FORM.NAME,
          onSubmit: dispatchEditCourseGroupOnSubmit,
        }}
        SortableListProps={{
          actions: this.actions,
          columns: this.columns,
          getKey: item => item.get("courseGroupId"),
          onRowClick: dispatchInitializeEditCourseGroup,
        }}
      />
    );
  }
}

export default compose(
  connect(
    state => ({
      courseGroups: getCourseGroupsFromState(state),
      dataState: getActionDataStateFromState(state, ACTIONS.GET_COURSE_GROUPS),
    }),
    {
      dispatchOnLoad: getCourseGroups,
      dispatchEditCourseGroupOnSubmit: editCourseGroupOnSubmit,
      dispatchDeleteCourseGroup: deleteCourseGroupAskConfirmation,
      dispatchCreateCourseGroupOnSubmit: createCourseGroupOnSubmit,
      dispatchInitializeEditCourseGroup: initializeEditCourseGroup,
    },
  ),
)(SettingsCourseGroupsContainer);
