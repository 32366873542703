import React from "react";
import { compose } from "redux";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import { Grid, Typography, Paper, Avatar } from "@material-ui/core";

import { CompetencyStatusChip } from "altus-ui-components";

const EntityCompetencyStatus = ({
  to,
  Icon,
  topText,
  classes,
  variant,
  elevation,
  bottomText,
  competencyStatus,
}) => (
  <Grid container to={to} component={to ? Link : undefined}>
    <Grid
      container
      component={Paper}
      elevation={elevation}
      className={classNames(classes.root, classes[variant], {
        [classes.rootLink]: to,
      })}
    >
      <Grid container spacing={2} alignItems="flex-start">
        {Icon && (
          <Grid item>
            <Avatar className={classes.avatar}>{Icon}</Avatar>
          </Grid>
        )}
        <Grid item xs container alignItems="center">
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.topText}>
              {topText}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption" className={classes.textWrap}>
              {bottomText ? bottomText : <i>No description..</i>}
            </Typography>
          </Grid>
        </Grid>
        {!!competencyStatus && (
          <Grid item container justify="flex-end">
            <CompetencyStatusChip status={competencyStatus} />
          </Grid>
        )}
      </Grid>
    </Grid>
  </Grid>
);

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
    minHeight: theme.spacing(10),
  },
  textWrap: {
    wordWrap: "break-word",
  },
  dashed: {
    borderWidth: 2,
    boxShadow: "none",
    borderStyle: "dashed",
    borderColor: theme.palette.grey[300],
  },
  rootLink: {
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
  topText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  avatar: {
    background: theme.palette.primary.main,
  },
  box: {
    borderWidth: 2,
    boxShadow: "none",
    borderStyle: "dashed",
    borderColor: theme.palette.grey[300],
  },
});

EntityCompetencyStatus.defaultProps = {
  elevation: 3,
  variant: "default",
};

export default compose(withStyles(styles))(EntityCompetencyStatus);
