import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { grey } from "@material-ui/core/colors";

import {
  getRoleGapReportEmployeeRolesFromState,
  getRoleGapReportEmployeeCoursesFromState,
  getRoleGapReportEmployeeSkillsFromState,
  getGapReportAppliedFiltersFromState,
} from "features/competence.selectors";

import { COMPETENCY_STATUS, EMPTY_SET, FEATURE_FLAG } from "app/app.constants";
import GapReportCell from "features/components/GapReport/GapReportCell";
import { getFeatureFlagStatusFromState } from "app/app.selectors";
import { getEmployeeRoleCompetencyItemStatusForGapReport } from "utils/app.util";

class RoleGapReportEmployeeRow extends PureComponent {
  render() {
    const {
      root,
      roles,
      employeeId,
      roleSkills,
      roleCourses,
      employeeRoles,
      employeeSkills,
      appliedFilters,
      employeeCourses,
      index: employeeIndex,
      isSkillsFeatureFlagEnabled,
    } = this.props;

    let currentXPos = 0;

    return (
      <>
        {roles.map((role, roleIndex) => {
          const roleId = role.get("roleId").toString();

          const isRoleAssigned = !!employeeRoles.get(roleId);
          const isMissingRequirementStatusIncludedInFilter =
            appliedFilters.get("statuses").isEmpty() ||
            appliedFilters
              .get("statuses")
              .contains(COMPETENCY_STATUS.MISSING_REQUIREMENTS.toString());

          const employeeRoleCompetencyStatus = employeeRoles.getIn(
            [
              roleId,
              isSkillsFeatureFlagEnabled
                ? "competencyStatus"
                : "courseCompetencyStatus",
            ],
            COMPETENCY_STATUS.NONE,
          );

          const roleXPos = currentXPos++;

          return (
            <React.Fragment key={roleIndex}>
              <GapReportCell
                x={roleXPos}
                fill={grey[200]}
                y={employeeIndex}
                status={employeeRoleCompetencyStatus}
                to={`${root}/employees/${employeeId}/roles/${roleId}`}
              />
              {roleCourses
                .get(roleId, EMPTY_SET)
                .map((roleCourse, roleCourseIndex) => {
                  const courseId = roleCourse.get("courseId").toString();

                  var employeeCourseCompetencyStatus = employeeCourses.getIn(
                    [courseId, "competencyStatus"],
                    COMPETENCY_STATUS.NONE,
                  );

                  employeeCourseCompetencyStatus = getEmployeeRoleCompetencyItemStatusForGapReport(
                    employeeCourseCompetencyStatus,
                    isRoleAssigned,
                    isMissingRequirementStatusIncludedInFilter,
                  );

                  const roleCourseXPos = currentXPos++;

                  return (
                    <GapReportCell
                      x={roleCourseXPos}
                      key={roleCourseIndex}
                      y={employeeIndex}
                      status={employeeCourseCompetencyStatus}
                      to={`${root}/employees/${employeeId}/courses/${courseId}`}
                    />
                  );
                })}
              {roleSkills
                .get(roleId, EMPTY_SET)
                .map((roleSkill, roleSkillIndex) => {
                  const skillId = roleSkill.get("skillId").toString();

                  var employeeSkillCompetencyStatus = employeeSkills.getIn(
                    [skillId, "competencyStatus"],
                    COMPETENCY_STATUS.NONE,
                  );

                  employeeSkillCompetencyStatus = getEmployeeRoleCompetencyItemStatusForGapReport(
                    employeeSkillCompetencyStatus,
                    isRoleAssigned,
                    isMissingRequirementStatusIncludedInFilter,
                  );

                  const roleSkillXPos = currentXPos++;

                  return (
                    <GapReportCell
                      x={roleSkillXPos}
                      key={roleSkillIndex}
                      y={employeeIndex}
                      status={employeeSkillCompetencyStatus}
                      to={`${root}/employees/${employeeId}/skills/${skillId}`}
                    />
                  );
                })}
            </React.Fragment>
          );
        })}
      </>
    );
  }
}

RoleGapReportEmployeeRow.defaultProps = {
  employeeRoles: EMPTY_SET,
  employeeCourses: EMPTY_SET,
  employeeSkills: EMPTY_SET,
};

export default compose(
  connect((state, { employeeId }) => ({
    isSkillsFeatureFlagEnabled: getFeatureFlagStatusFromState(
      state,
      FEATURE_FLAG.SKILLS,
    ),
    employeeRoles: getRoleGapReportEmployeeRolesFromState(state, employeeId),
    employeeCourses: getRoleGapReportEmployeeCoursesFromState(
      state,
      employeeId,
    ),
    employeeSkills: getRoleGapReportEmployeeSkillsFromState(state, employeeId),
    appliedFilters: getGapReportAppliedFiltersFromState(state),
  })),
)(RoleGapReportEmployeeRow);
