import FileSaver from "file-saver";
import isEqual from "lodash/isEqual";
import queryString from "query-string";
import castArray from "lodash/castArray";
import isFunction from "lodash/isFunction";
import { Stack, List, Map, isImmutable } from "immutable";
import { defaultMemoize, createSelectorCreator } from "reselect";

import {
  APP_ROLES,
  EMPTY_LIST,
  COMPETENCY_STATUS,
  APP_ROLES_HR_AND_ADMIN,
} from "app/app.constants";

export const createSelector = createSelectorCreator(defaultMemoize, isEqual);

export const invokeIfFunction = (fn, ...args) => isFunction(fn) && fn(...args);

export const makeActionCreator = (action, ...argNames) => {
  const { payload: payloadFn } = action;

  return (...args) => dispatch => {
    const payloadResult = payloadFn(...args);

    const payload = isFunction(payloadResult)
      ? dispatch(payloadResult)
      : payloadResult;

    const updatedAction = argNames.reduce(
      (sum, curr, index) => ({
        ...sum,
        [argNames[index]]: args[index],
      }),
      {
        ...action,
        payload,
      },
    );

    dispatch(updatedAction);

    return payload;
  };
};

export const getInitials = (displayName = "") => {
  if (!displayName) return "";

  return displayName
    .split(/\s/)
    .map(s => s.charAt(0).toUpperCase())
    .slice(0, 3)
    .join("");
};

export const getAvatar = avatar =>
  avatar ? `data:image/png;base64,${avatar}` : undefined;

export const renderContent = (content, props) =>
  isFunction(content) ? content(props) : content;

export const saveFile = (blob, name) => FileSaver.saveAs(blob, name);

const S4 = () =>
  (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);

export const createGuid = () =>
  `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;

export const resolveEmployeeCourseFile = employeeCourse => {
  const files = employeeCourse ? employeeCourse.get("files") : [];

  return files && files.size ? files.first() : null;
};

export const getSkillEvidenceCount = employeeSkill => {
  const evidences = employeeSkill ? employeeSkill.get("fileEvidencesCount") : 0;
  return evidences;
};

export const resolveEmployeeCourseAttendanceFile = employeeCourseAttendance => {
  const files = employeeCourseAttendance
    ? employeeCourseAttendance.get("files")
    : [];

  return files && files.size ? files.first() : null;
};

export const isAdminOrHR = user =>
  APP_ROLES_HR_AND_ADMIN.some(appRole =>
    user.get("appRoles", EMPTY_LIST).includes(appRole),
  );

export const isManager = user =>
  user.get("appRoles").includes(APP_ROLES.MANAGER);

export const hasRequiredAccessLevel = (accessLevel, appRoles, employeeId) => {
  if (!accessLevel) {
    return true;
  }

  if (!appRoles) {
    return false;
  }

  const hasAnyNonEmployeeRole = castArray(accessLevel).some(
    role => appRoles.includes(role) && role !== APP_ROLES.EMPLOYEE,
  );

  if (hasAnyNonEmployeeRole) {
    return true;
  }

  const hasRequiredAuthLevel = appRoles
    ? castArray(accessLevel).some(role => appRoles.includes(role))
    : false;

  return accessLevel.includes(APP_ROLES.EMPLOYEE)
    ? !!employeeId
    : hasRequiredAuthLevel;
};

export const isFeatureFlagEnabled = (enabledFeatureFlags, featureFlag) =>
  !!featureFlag ? enabledFeatureFlags.get(featureFlag) : true;

export const toQueryString = value =>
  queryString.stringify(isImmutable(value) ? value.toJS() : value, {
    arrayFormat: "none",
    parseBooleans: true,
  });

export const parseQueryString = value =>
  queryString.parse(value, { arrayFormat: "none", parseBooleans: true });

export const getEmployeeRoleCompetencyItemStatusForGapReport = (
  competencyItemStatus,
  isRoleAssigned,
  isMissingRequirementStatusIncludedInFilter = true,
) => {
  if (isRoleAssigned) {
    competencyItemStatus =
      competencyItemStatus === COMPETENCY_STATUS.NONE &&
      isMissingRequirementStatusIncludedInFilter
        ? COMPETENCY_STATUS.MISSING_REQUIREMENTS
        : competencyItemStatus;
  } else {
    competencyItemStatus =
      competencyItemStatus === COMPETENCY_STATUS.MISSING_REQUIREMENTS
        ? COMPETENCY_STATUS.NONE
        : competencyItemStatus;
  }

  return competencyItemStatus;
};

const moveSubtreeToParent = treeStack => {
  const completeSubtree = treeStack.peek();
  treeStack = treeStack.pop();
  const completeSubtreeParent = treeStack.peek();
  treeStack = treeStack.pop();

  return treeStack.push(
    completeSubtreeParent.set(
      "children",
      !completeSubtreeParent.get("children")
        ? List.of(completeSubtree)
        : completeSubtreeParent.get("children").push(completeSubtree),
    ),
  );
};

const isLeaf = item => item.get("treeMax") - item.get("treeMin") === 1;

const isChildOf = (item, getKey, possibleParent) =>
  item.get("parentId").toString() ===
  (getKey(possibleParent) || possibleParent.get("rootId")).toString();

export const buildItemTree = (items, getKey) => {
  if (!items || !items.size) {
    return EMPTY_LIST;
  }

  items = items.sortBy(item => item.get("treeMin"));

  // The root node doesn't usually come in the items list, but we still need it to build the tree structure.
  // Therefore, we create a fake root item with rootId as the parentId of the item with lowest treeMin.
  // If the parentId for the item with lowest treeMin is NULL, then it means that item is actually the root (it came in the response).
  const rootId = items.first().get("parentId") || getKey(items.first());

  let tree = items.reduce(
    (treeStack, item) => {
      // If the item is not the child of the item that is sitting on the top of the stack,
      // then it means we were piling up the children subtree of a brother and now we are done. So, before proceeding,
      // we need to summarize, collapse, the brother's subtree until we find the current's parent again.
      while (!isChildOf(item, getKey, treeStack.peek())) {
        treeStack = moveSubtreeToParent(treeStack);
      }

      // At this point, we know that what's sitting on top of the "treeStack" is the parent of "item".
      if (isLeaf(item)) {
        // If the current item is a leaf, then set an empty list as its children
        // and include it in its parent's children list.
        const leaf = item.set("children", EMPTY_LIST);
        const leafParent = treeStack.peek();
        treeStack = treeStack.pop();

        return treeStack.push(
          leafParent.set(
            "children",
            !leafParent.get("children")
              ? List.of(leaf)
              : leafParent.get("children").push(leaf),
          ),
        );
      } else {
        // If the item is not a leaf, just push it into the stack.
        return treeStack.push(item);
      }
    },
    Stack.of(
      Map({
        rootId,
        children: EMPTY_LIST,
      }),
    ),
  );

  // When we are done, we need to make sure to "collapse" the last subtree we were building.
  while (tree.size > 1) {
    tree = moveSubtreeToParent(tree);
  }

  // We don't want to return the full tree because the root item is fake. It exists only to create the tree structure.
  return tree.peek().get("children");
};
