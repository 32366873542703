import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { green } from "@material-ui/core/colors";
import { alpha } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";

import SwitchField from "app/components/SwitchField";
import AccessControl from "app/components/AccessControl";
import { ACTIONS, FEATURE_FLAG, APP_ROLES } from "app/app.constants";
import { updateGapReportOptions } from "features/competence.actions";
import { getGapReportOptionsFromState } from "features/competence.selectors";

import {
  getSummarizedDataStatesFromState,
  getFeatureFlagStatusFromState,
} from "app/app.selectors";

export const AdditionalReportOptions = {
  HIDE_COURSES: "hideCourses",
  HIDE_SKILLS: "hideSkills",
  SHOW_ALL_ROLES: "showAllRoles",
  SHOW_ASSIGNED_EMPLOYEES_ONLY: "showAssignedEmployeesOnly",
  SHOW_ASSIGNED_ROLES_ONLY: "showAssignedRolesOnly",
  SHOW_EX_EMPLOYEES: "showExEmployees",
};

const GapReportEmployeesHeaderOptions = ({
  options,
  dispatchUpdateGapReportOptions,
  isSkillsFeatureFlagEnabled,
  isCoursesFeatureFlagEnabled = true,
  classes,
  allRolesVisible = true,
  rolesAssignedOnlyVisible = true,
  employeeAssignedOnly = true,
  showExEmployees = false,
  forceHideSkills = false,
}) => {
  return (
    <FormControl component="fieldset">
      <AccessControl accessLevel={APP_ROLES.MANAGER}>
        {allRolesVisible && (
          <SwitchField
            label="All roles"
            checked={
              options.get(AdditionalReportOptions.SHOW_ALL_ROLES) === true
            }
            classes={{ coloredSwitch: classes.greenSwitch }}
            onChange={(_, value) =>
              dispatchUpdateGapReportOptions({
                key: AdditionalReportOptions.SHOW_ALL_ROLES,
                value,
              })
            }
          />
        )}
      </AccessControl>
      {rolesAssignedOnlyVisible && (
        <SwitchField
          label="Roles Assiged Only"
          checked={
            options.get(AdditionalReportOptions.SHOW_ASSIGNED_ROLES_ONLY) ===
            true
          }
          classes={{ coloredSwitch: classes.greenSwitch }}
          onChange={(_, value) =>
            dispatchUpdateGapReportOptions({
              key: AdditionalReportOptions.SHOW_ASSIGNED_ROLES_ONLY,
              value,
            })
          }
        />
      )}
      {employeeAssignedOnly && (
        <SwitchField
          label="Employees Assiged Only"
          checked={
            options.get(
              AdditionalReportOptions.SHOW_ASSIGNED_EMPLOYEES_ONLY,
            ) === true
          }
          classes={{ coloredSwitch: classes.greenSwitch }}
          onChange={(_, value) =>
            dispatchUpdateGapReportOptions({
              key: AdditionalReportOptions.SHOW_ASSIGNED_EMPLOYEES_ONLY,
              value,
            })
          }
        />
      )}
      {showExEmployees && (
        <SwitchField
          label="Show former employees"
          checked={
            options.get(AdditionalReportOptions.SHOW_EX_EMPLOYEES) === true
          }
          classes={{ coloredSwitch: classes.greenSwitch }}
          onChange={(_, value) =>
            dispatchUpdateGapReportOptions({
              key: AdditionalReportOptions.SHOW_EX_EMPLOYEES,
              value,
            })
          }
        />
      )}
      {isSkillsFeatureFlagEnabled && !forceHideSkills && (
        <SwitchField
          label="Hide Skills"
          checked={options.get(AdditionalReportOptions.HIDE_SKILLS) === true}
          classes={{ coloredSwitch: classes.greenSwitch }}
          onChange={(_, value) =>
            dispatchUpdateGapReportOptions({
              key: AdditionalReportOptions.HIDE_SKILLS,
              value,
            })
          }
        />
      )}
      {isCoursesFeatureFlagEnabled && (
        <SwitchField
          label="Hide Courses"
          checked={options.get(AdditionalReportOptions.HIDE_COURSES) === true}
          classes={{ coloredSwitch: classes.greenSwitch }}
          onChange={(_, value) =>
            dispatchUpdateGapReportOptions({
              key: AdditionalReportOptions.HIDE_COURSES,
              value,
            })
          }
        />
      )}
    </FormControl>
  );
};

const styles = theme => ({
  greenSwitch: {
    "&.Mui-checked": {
      color: green[600],
      "&:hover": {
        backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
      },
    },
    "&.Mui-checked + .MuiSwitch-track": {
      backgroundColor: green[600],
    },
  },
});

export default compose(
  connect(
    state => ({
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.UPDATE_GAP_REPORT_OPTIONS,
      ),
      isSkillsFeatureFlagEnabled: getFeatureFlagStatusFromState(
        state,
        FEATURE_FLAG.SKILLS,
      ),
      options: getGapReportOptionsFromState(state),
    }),
    {
      dispatchUpdateGapReportOptions: updateGapReportOptions,
    },
  ),
  withStyles(styles),
)(GapReportEmployeesHeaderOptions);
