import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { ICONS, FEATURE_FLAG } from "app/app.constants";
import {
  RoleMapper,
  EmployeeMapper,
  EmployeeRoleMapper,
} from "app/app.mappers";

import {
  getRoleFromState,
  getEmployeeFromState,
  getEmployeeRoleFromState,
} from "features/competence.selectors";

import { getFeatureFlagStatusFromState } from "app/app.selectors";
import EntityDetailsHeader from "app/components/EntityDetailsHeader";

const EmployeeRoleDetailsHeader = ({
  role,
  children,
  employee,
  employeeRole,
  isSkillsFeatureFlagEnabled,
}) => {
  return (
    <EntityDetailsHeader
      Icon={<ICONS.ROLE />}
      title={role.get("name")}
      subtitle={employee.get("displayName")}
      competencyStatus={
        isSkillsFeatureFlagEnabled
          ? employeeRole.get("competencyStatus")
          : employeeRole.get("courseCompetencyStatus")
      }
    >
      {children}
    </EntityDetailsHeader>
  );
};

EmployeeRoleDetailsHeader.defaultProps = {
  role: RoleMapper.initial,
  employee: EmployeeMapper.initial,
  employeeRole: EmployeeRoleMapper.initial,
};

export default compose(
  connect((state, { roleId, employeeId }) => ({
    role: getRoleFromState(state, roleId),
    employee: getEmployeeFromState(state, employeeId),
    employeeRole: getEmployeeRoleFromState(state, employeeId, roleId),
    isSkillsFeatureFlagEnabled: getFeatureFlagStatusFromState(
      state,
      FEATURE_FLAG.SKILLS,
    ),
  })),
)(EmployeeRoleDetailsHeader);
