import { compose } from "redux";
import { List } from "immutable";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { formValueSelector } from "redux-form/immutable";

import { BasePage } from "altus-ui-components";
import { DataState, LoadingDataState } from "altus-datastate";

import { EMPTY_LIST } from "app/app.constants";
import { createGuid, renderContent } from "utils/app.util";
import SimpleFormModal from "app/components/SimpleFormModal";
import withToggleModal from "app/components/withToggleModal";
import SortableList from "app/components/SortableList/SortableList";

class CrudBasePage extends PureComponent {
  constructor(props) {
    super(props);

    const {
      createEntityForm,
      editEntityForm,
      createModalId,
      editModalId,
    } = props;

    if (createEntityForm) {
      this.createModalId = createModalId || createGuid();
      this.createFormValueSelector = formValueSelector(createEntityForm.form);
    }

    if (editEntityForm) {
      this.editModalId = editModalId || createGuid();
      this.editFormValueSelector = formValueSelector(editEntityForm.form);
    }
  }

  toggleCreateModal = () => {
    const { dispatchToggleModal } = this.props;
    dispatchToggleModal({ modalId: this.createModalId });
  };

  toggleEditModal = () => {
    const { dispatchToggleModal } = this.props;
    dispatchToggleModal({ modalId: this.editModalId });
  };

  render() {
    const {
      Icon,
      title,
      items,
      children,
      dataState,
      endHeaderText,
      EditModalProps,
      editEntityForm,
      CreateModalProps,
      createEntityForm,
      displayAddButton,
      SortableListProps,
      addButtonOnClick,
      displaySearchField,
      EditEntityFormProps,
      CreateEntityFormProps,
      EditEntityFormComponent,
      CreateEntityFormComponent,
    } = this.props;

    return (
      <>
        <BasePage
          Icon={Icon}
          title={title}
          dataState={dataState}
          displayAddButton={displayAddButton}
          displaySearchField={displaySearchField}
          endHeaderText={endHeaderText}
          addButtonOnClick={
            addButtonOnClick
              ? addButtonOnClick
              : createEntityForm
              ? this.toggleCreateModal
              : null
          }
        >
          {SortableListProps && (
            <SortableList
              Icon={Icon}
              items={items}
              dataState={dataState}
              toggleEditModal={this.toggleEditModal}
              {...SortableListProps}
            />
          )}
          {renderContent(children, {
            dataState,
            toggleEditModal: this.toggleEditModal,
          })}
        </BasePage>
        {createEntityForm && (
          <SimpleFormModal
            Icon={Icon}
            form={createEntityForm}
            modalId={this.createModalId}
            component={CreateEntityFormComponent}
            formValueSelector={this.createFormValueSelector}
            FormProps={CreateEntityFormProps}
            {...CreateModalProps}
          />
        )}
        {editEntityForm && (
          <SimpleFormModal
            Icon={Icon}
            form={editEntityForm}
            modalId={this.editModalId}
            FormProps={EditEntityFormProps}
            component={EditEntityFormComponent}
            formValueSelector={this.editFormValueSelector}
            {...EditModalProps}
          />
        )}
      </>
    );
  }
}

CrudBasePage.propTypes = {
  Icon: PropTypes.object,
  items: PropTypes.instanceOf(List),
  dataState: PropTypes.oneOfType([
    PropTypes.instanceOf(DataState),
    PropTypes.instanceOf(List),
    PropTypes.arrayOf(PropTypes.instanceOf(DataState)),
  ]),

  SortableListProps: PropTypes.shape({
    getKey: PropTypes.func.isRequired,
    onRowClick: PropTypes.func,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        getSortProperty: PropTypes.func,
      }),
    ).isRequired,
  }),
};

CrudBasePage.defaultProps = {
  items: EMPTY_LIST,
  dataState: LoadingDataState,
};

export default compose(withToggleModal)(CrudBasePage);
