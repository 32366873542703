import { compose } from "redux";
import { List } from "immutable";
import { Field } from "redux-form/immutable";
import React, { PureComponent } from "react";
import { Grid } from "@material-ui/core";

import { SpecificationMapper, EmployeeMapper } from "app/app.mappers";

import { EMPTY_LIST } from "app/app.constants";
import { required } from "utils/validation.util";
import TextField from "app/components/form/TextField";
import employeeService from "services/employee.service";
import specificationService from "services/specification.service";
import { SPECIFICATION_FORM } from "features/competence.constants";
import AutoSuggestField from "app/components/form/AutoSuggestField";
import CountryMultiselectField from "app/components/form/CountryMultiselectField";

class SpecificationForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      specifications: EMPTY_LIST,
      employees: EMPTY_LIST,
    };
  }

  componentDidMount() {
    Promise.all([
      specificationService.getSpecifications(),
      employeeService.getEmployees(),
    ]).then(([specifications, employees, countries]) =>
      this.setState({
        specifications: List(specifications).map(SpecificationMapper.from),
        employees: List(employees).map(EmployeeMapper.from),
      }),
    );
  }

  render() {
    const { currentSpecification, handleSubmit } = this.props;

    return (
      <form id={SPECIFICATION_FORM.ID} onSubmit={handleSubmit}>
        <Grid container direction="column">
          <Grid container item>
            <Field
              autoFocus
              required
              label="Specification Name"
              validate={[required]}
              component={TextField}
              name={SPECIFICATION_FORM.NAME}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Field
                select
                name={SPECIFICATION_FORM.PARENT_ID}
                component={AutoSuggestField}
                label="Inherit Requirements from Specification"
                options={this.state.specifications.map(specification => ({
                  label: specification.get("name"),
                  value: specification.get("specificationId"),
                  disabled: currentSpecification
                    ? currentSpecification.get("specificationId") ===
                      specification.get("specificationId")
                    : false,
                }))}
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Field
              multiline
              rows={5}
              label="Description"
              component={TextField}
              name={SPECIFICATION_FORM.DESCRIPTION}
            />
          </Grid>
          <Grid item container>
            <Field
              select
              required
              label="Specification Owner"
              validate={[required]}
              name={SPECIFICATION_FORM.OWNER_ID}
              component={AutoSuggestField}
              TextFieldProps={{
                required: true,
              }}
              options={this.state.employees.map(employee => ({
                label: employee.get("displayName"),
                value: employee.get("employeeId"),
              }))}
            />
          </Grid>
          <Grid item container>
            <Field
              name={SPECIFICATION_FORM.COUNTRIES}
              component={CountryMultiselectField}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default compose()(SpecificationForm);
