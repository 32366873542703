import { compose } from "redux";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import GapReportValueCell from "features/components/GapReport/GapReportValueCell";
import { Link } from "react-router-dom";

class GapReportRequirementHeaderCell extends PureComponent {
  render() {
    const { to, name, Icon, title, classes, onClick, ...rest } = this.props;

    return (
      <Tooltip title={title}>
        <GapReportValueCell
          to={to}
          target="_blank"
          onClick={onClick}
          component={to ? Link : undefined}
          {...rest}
        >
          <Grid
            container
            className={classes.header}
            justify="space-between"
            alignContent="center"
          >
            <Grid item xs zeroMinWidth className={classes.headerText}>
              <Typography noWrap variant="caption">
                {name}
              </Typography>
            </Grid>
            {Icon && (
              <Grid item xs={1}>
                <Icon className={classes.headerIcon} />
              </Grid>
            )}
          </Grid>
        </GapReportValueCell>
      </Tooltip>
    );
  }
}

const styles = theme => ({
  header: {
    transform: "rotate(-90deg)",
    minWidth: theme.gapReport.header.height - theme.spacing(1),
    maxWidth: theme.gapReport.header.height - theme.spacing(1),
  },
  headerText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  headerIcon: {
    transform: "rotate(90deg)",
    fontSize: theme.spacing(2),
  },
});

export default compose(withStyles(styles))(GapReportRequirementHeaderCell);
