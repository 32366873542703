import React, { useCallback, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Helmet } from "altus-ui-components";

import {
  getSpecificationGapReport,
  clearSpecificationGapReport,
  specificationGapReportOnLoad,
  specificationGapReportOnUnload,
  downloadAndSaveSpecificationGapReport,
} from "features/competence.actions";

import { getGapReportEmployeesByDepartmentFromState } from "features/competence.selectors";

import { ACTIONS, APP_ROOT } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import GapReportBasePage from "features/components/GapReport/GapReportBasePage";
import SpecificationGapReport from "features/specification/components/SpecificationGapReport";
import SpecificationGapReportFilter from "features/specification/components/SpecificationGapReportFilter"; // FILTERS,

const SpecificationGapReportContainer = ({
  root = APP_ROOT.HR,
  dataState,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchGetGapReport,
  employeesByDepartment,
  dispatchClearGapReport,
  dispatchDownloadGapReport,
}) => {
  useEffect(() => {
    dispatchOnLoad();
    return () => dispatchOnUnload();
  }, [dispatchOnLoad, dispatchOnUnload]);

  const renderFilter = useCallback(
    () => (
      <SpecificationGapReportFilter
        getGapReport={dispatchGetGapReport}
        clearGapReport={dispatchClearGapReport}
        downloadGapReport={dispatchDownloadGapReport}
      />
    ),
    [dispatchGetGapReport, dispatchClearGapReport, dispatchDownloadGapReport],
  );

  return (
    <>
      <Helmet titleTemplate="%s">
        <title>{breadcrumb}</title>
      </Helmet>
      <GapReportBasePage
        title={breadcrumb}
        dataState={dataState}
        renderFilter={renderFilter}
        employeesByDepartment={employeesByDepartment}
      >
        {!!employeesByDepartment.size && (
          <SpecificationGapReport
            root={root}
            title={breadcrumb}
            employeesByDepartment={employeesByDepartment}
          />
        )}
      </GapReportBasePage>
    </>
  );
};

export default compose(
  connect(
    state => ({
      employeesByDepartment: getGapReportEmployeesByDepartmentFromState(state),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_SPECIFICATION_GAP_REPORT,
        ACTIONS.DOWNLOAD_SPECIFICATION_GAP_REPORT,
      ),
    }),
    {
      dispatchOnLoad: specificationGapReportOnLoad,
      dispatchGetGapReport: getSpecificationGapReport,
      dispatchOnUnload: specificationGapReportOnUnload,
      dispatchClearGapReport: clearSpecificationGapReport,
      dispatchDownloadGapReport: downloadAndSaveSpecificationGapReport,
    },
  ),
  React.memo,
)(SpecificationGapReportContainer);
