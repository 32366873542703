import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Avatar, Grid, Typography, withStyles } from "@material-ui/core";

import {
  getExperienceRecordFromState,
  getExperienceRecordStatsFromState,
} from "features/competence.selectors";

import { formatDate } from "utils/format.util";
import ReadOnlyField from "app/components/ReadOnlyField";
import { EMPTY_MAP, ICONS, Format } from "app/app.constants";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";

const ExperienceRecordDetailsHeader = ({
  classes,
  experienceRecord = EMPTY_MAP,
  experienceRecordStats = EMPTY_MAP,
}) => (
  <Grid container alignItems="center" className={classes.root}>
    <Grid container item xs={2} spacing={2} alignItems="center" wrap="nowrap">
      <Grid item xs container spacing={2}>
        <Grid item>
          <Avatar
            classes={{
              colorDefault: classes.avatarColorDefault,
            }}
          >
            {<ICONS.EXPERIENCES />}
          </Avatar>
        </Grid>
        <Grid item xs>
          <Grid container direction="column">
            <Grid item>
              <Typography className={classes.text}>
                Experience Record
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption">
                {experienceRecord.get("employeePosition")
                  ? experienceRecord.get("employeeDisplayName") -
                    experienceRecord.get("employeePosition")
                  : experienceRecord.get("employeeDisplayName")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    <Grid
      xs
      item
      container
      wrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item>
        <ReadOnlyField
          margin="none"
          className={classes.textField}
          label="Job"
          value={experienceRecord.get("jobId", "-")}
        />
      </Grid>
      <Grid item>
        <ReadOnlyField
          margin="none"
          className={classes.textField}
          label="Client"
          value={experienceRecord.get("experienceClientName", "-")}
        />
      </Grid>
      <Grid item>
        <ReadOnlyField
          margin="none"
          className={classes.textField}
          label="Location"
          value={experienceRecord.get("locationName", "-")}
        />
      </Grid>
      <Grid item>
        <ReadOnlyField
          margin="none"
          className={classes.textField}
          label="Department"
          value={experienceRecord.get("employeeDepartment", "-")}
        />
      </Grid>
      <Grid item>
        <ReadOnlyField
          margin="none"
          className={classes.textField}
          label="Performed as"
          value={experienceRecord.get("performedAs") || "-"}
        />
      </Grid>
      <Grid item>
        <ReadOnlyField
          margin="none"
          className={classes.textField}
          label="From"
          value={formatDate(experienceRecord.get("from"), Format.date) || "-"}
        />
      </Grid>
      <Grid item>
        <ReadOnlyField
          margin="none"
          className={classes.textField}
          label="To"
          value={formatDate(experienceRecord.get("to"), Format.date) || "-"}
        />
      </Grid>
      <Grid item>
        <ReadOnlyField
          margin="none"
          className={classes.textField}
          label="XP"
          value={experienceRecordStats.get("experiencePointsCount", "-")}
        />
      </Grid>
      <Grid item justifyContent="flex-end">
        <CompetencyStatusChip
          status={experienceRecord.get("competencyStatus")}
        />
      </Grid>
    </Grid>
  </Grid>
);

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    background: theme.palette.background.default,
  },
  avatarColorDefault: {
    background: theme.palette.primary.main,
  },
  text: {
    fontWeight: theme.typography.fontWeight,
  },
  textField: {
    paddingRight: theme.spacing(2),
  },
});

export default compose(
  connect((state, { experienceRecordId }) => ({
    experienceRecord: getExperienceRecordFromState(state, experienceRecordId),
    experienceRecordStats: getExperienceRecordStatsFromState(
      state,
      experienceRecordId,
    ),
  })),
  withStyles(styles),
)(ExperienceRecordDetailsHeader);
