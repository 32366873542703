import { compose } from "redux";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import React, { useCallback, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";

import { BasePage, Helmet } from "altus-ui-components";

import {
  getOutstandingTrainingHistoryReport,
  clearOutstandingTrainingHistoryReport,
  outstandingTrainingHistoryReportOnLoad,
  outstandingTrainingHistoryReportOnUnload,
  downloadAndSaveOutstandingTrainingHistoryReport,
} from "features/competence.actions";

import ErrorBoundary from "app/components/ErrorBoundary";
import { ACTIONS, APP_ROOT, EMPTY_MAP } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import GapReportMessagePage from "features/components/GapReport/GapReportMessagePage";
import GapReportBasePageHeader from "features/components/GapReport/GapReportBasePageHeader";
import { getOutstandingTrainingReportCoursesFromState } from "features/competence.selectors";
import OutstandingTrainingHistoryReportFilter from "features/reports/outstandingTrainingHistory/OutstandingTrainingHistoryReportFilter"; // FILTERS,
import OutstandingTrainingHistoryReportTableContainer from "features/reports/outstandingTrainingHistory/OutstandingTrainingHistoryReportTableContainer";

const OutstandingTrainingHistoryReport = ({
  root = APP_ROOT.HR,
  classes,
  dataState,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchGetReport,
  coursesByDepartment = EMPTY_MAP,
  dispatchClearReport,
  dispatchDownloadGapReport,
}) => {
  useEffect(() => {
    dispatchOnLoad();
    return () => dispatchOnUnload();
  }, [dispatchOnLoad, dispatchOnUnload]);

  const renderFilter = useCallback(
    () => (
      <OutstandingTrainingHistoryReportFilter
        getGapReport={dispatchGetReport}
        clearGapReport={dispatchClearReport}
        downloadGapReport={dispatchDownloadGapReport}
        coursesByDepartment={coursesByDepartment}
      />
    ),
    [
      dispatchGetReport,
      dispatchClearReport,
      dispatchDownloadGapReport,
      coursesByDepartment,
    ],
  );

  return (
    <>
      <Helmet titleTemplate="%s">
        <title>{breadcrumb}</title>
      </Helmet>
      <BasePage
        dataState={dataState}
        classes={{
          root: classes.basePageRoot,
          children: classes.basePageChildren,
        }}
      >
        <GapReportBasePageHeader renderFilter={renderFilter} />
        <ErrorBoundary>
          <Grid
            item
            container
            id="gap-report-scroll-container"
            className={classes.gapReportContainer}
          >
            <Route
              children={({ location }) => (
                <Switch>
                  {!location.search && !coursesByDepartment.size && (
                    <Route
                      render={() => (
                        <GapReportMessagePage title={breadcrumb}>
                          Click
                          <b>
                            <em>{` Search `}</em>
                          </b>
                          to generate report...
                        </GapReportMessagePage>
                      )}
                    />
                  )}
                  {location.search && !coursesByDepartment.size && (
                    <Route
                      render={() => (
                        <GapReportMessagePage title={breadcrumb}>
                          No results.. Try changing one or more filters
                        </GapReportMessagePage>
                      )}
                    />
                  )}
                </Switch>
              )}
            />
            {!!coursesByDepartment.size && (
              <OutstandingTrainingHistoryReportTableContainer
                root={root}
                title={breadcrumb}
                courses={coursesByDepartment}
              />
            )}
          </Grid>
        </ErrorBoundary>
      </BasePage>
    </>
  );
};

const styles = theme => ({
  paper: {
    padding: theme.spacing(5),
  },
  subRoot: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px 0px`,
  },
  gapReportContainer: {
    overflow: "auto",
    height: "100%",
  },
  basePageRoot: {
    overflow: "hidden",
  },
  basePageChildren: {
    height: "100%",
    padding: 0,
  },
});

export default compose(
  connect(
    state => ({
      coursesByDepartment: getOutstandingTrainingReportCoursesFromState(state),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_OUTSTANDING_TRAINING_HISTORY_REPORT,
        ACTIONS.DOWNLOAD_OUTSTANDING_TRAINING_HISTORY_REPORT,
      ),
    }),
    {
      dispatchOnLoad: outstandingTrainingHistoryReportOnLoad,
      dispatchGetReport: getOutstandingTrainingHistoryReport,
      dispatchOnUnload: outstandingTrainingHistoryReportOnUnload,
      dispatchClearReport: clearOutstandingTrainingHistoryReport,
      dispatchDownloadGapReport: downloadAndSaveOutstandingTrainingHistoryReport,
    },
  ),
  React.memo,
  withStyles(styles),
)(OutstandingTrainingHistoryReport);
