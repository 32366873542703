import React, { PureComponent } from "react";

import EmployeeEducationBasePage from "features/components/EmployeeEducationBasePage";

class EmployeeEducationContainer extends PureComponent {
  render() {
    const { employeeId, breadcrumb } = this.props;

    return (
      <EmployeeEducationBasePage title={breadcrumb} employeeId={employeeId} />
    );
  }
}

export default EmployeeEducationContainer;
