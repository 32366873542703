import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import {
  getMyTeamEmployeesByUser,
  getStatsForMyTeamEmployees,
} from "features/competence.actions";

import { ACTIONS } from "app/app.constants";
import { getActionDataStateFromState } from "app/app.selectors";
import EmployeesBasePage from "features/components/EmployeesBasePage";
import { getMyTeamEmployeesFromState } from "features/competence.selectors";

class MyTeamEmployeesContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad, dispatchGetStatsForMyTeamEmployees } = this.props;

    dispatchOnLoad();
    dispatchGetStatsForMyTeamEmployees();
  }

  render() {
    const {
      path,
      dataState,
      breadcrumb,
      dispatchOnLoad,
      myTeamEmployees,
    } = this.props;

    return (
      <EmployeesBasePage
        path={path}
        title={breadcrumb}
        dataState={dataState}
        employees={myTeamEmployees}
        onRefresh={dispatchOnLoad}
      />
    );
  }
}

export default compose(
  connect(
    state => ({
      myTeamEmployees: getMyTeamEmployeesFromState(state),
      dataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_MY_TEAM_EMPLOYEES_BY_USER,
      ),
    }),
    {
      dispatchOnLoad: getMyTeamEmployeesByUser,
      dispatchGetStatsForMyTeamEmployees: getStatsForMyTeamEmployees,
    },
  ),
)(MyTeamEmployeesContainer);
