import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Grid, Chip, Typography, withStyles } from "@material-ui/core";

import { EMPTY_MAP } from "app/app.constants";
import { getEmployeeStatsFromState } from "features/competence.selectors";

const MyPageExperienceRecordHeader = ({
  employeeId,
  employeeStats = EMPTY_MAP,
  classes,
}) => {
  return (
    <Grid item className={classes.chipContainer}>
      <Chip
        container
        wrap="nowrap"
        component={Grid}
        clickable={false}
        variant="outlined"
        justifyContent="space-between"
        title="Total XP"
        classes={{
          root: classes.chipRoot,
          label: classes.chipLabel,
        }}
        label={
          <>
            <Typography
              variant="inherit"
              noWrap
              className={classes.chipLabelTitle}
            >
              Total XP
            </Typography>
            <Typography variant="inherit" noWrap>
              {employeeStats.get("experiencePoints")}
            </Typography>
          </>
        }
      />
    </Grid>
  );
};

const styles = theme => ({
  chipContainer: {
    marginRight: theme.spacing(3),
  },
  chipRoot: {
    padding: theme.spacing(0.5),
    width: "auto",
    maxWidth: "100%",
    "&:focus": {
      backgroundColor: "white",
    },
  },
  chipLabel: {
    overflow: "hidden",
    fontWeight: theme.typography.fontWeightMedium,
  },
  chipLabelTitle: {
    marginRight: theme.spacing(1.5),
  },
});
export default compose(
  connect((state, { employeeId }) => ({
    employeeStats: getEmployeeStatsFromState(state, employeeId),
  })),
  withStyles(styles),
)(MyPageExperienceRecordHeader);
