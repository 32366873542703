import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Tooltip } from "@material-ui/core";

import routePaths from "app/routePaths";
import { isAdminOrHR } from "utils/app.util";
import { getCurrentUserFromState } from "app/app.selectors";
import { initializeSkillDetailsForm } from "features/competence.actions";
import GapReportValueCell from "features/components/GapReport/GapReportValueCell";

class SkillGapReportHeader extends PureComponent {
  render() {
    const {
      skills,
      classes,
      currentUser,
      dispatchInitializeSkillDetailsForm,
    } = this.props;

    const currentUserIsAdminOrHr = isAdminOrHR(currentUser);

    return (
      <Grid item container wrap="nowrap" className={classes.root}>
        {skills.map(skill => (
          <Tooltip title={skill.get("name")} key={skill.get("skillId")}>
            {currentUserIsAdminOrHr ? (
              <GapReportValueCell
                target="_blank"
                component={Link}
                to={`${routePaths.hrPortal}/skills/${skill.get("skillId")}`}
              >
                <Typography
                  noWrap
                  variant="caption"
                  className={classes.headerText}
                >
                  {skill.get("name")}
                </Typography>
              </GapReportValueCell>
            ) : (
              <GapReportValueCell
                onClick={() =>
                  dispatchInitializeSkillDetailsForm(skill.get("skillId"))
                }
              >
                <Typography
                  noWrap
                  variant="caption"
                  className={classes.headerText}
                >
                  {skill.get("name")}
                </Typography>
              </GapReportValueCell>
            )}
          </Tooltip>
        ))}
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    top: 0,
    zIndex: 3,
    position: "sticky",
    height: theme.gapReport.header.height,
    borderTop: theme.gapReport.defaultBorder,
    borderRight: theme.gapReport.defaultBorder,
    backgroundColor: theme.palette.background.default,
  },
  headerText: {
    transform: "rotate(-90deg)",
    minWidth: theme.gapReport.header.height - theme.spacing(2),
    maxWidth: theme.gapReport.header.height - theme.spacing(2),
  },
});

export default compose(
  connect(
    state => ({
      currentUser: getCurrentUserFromState(state),
    }),
    {
      dispatchInitializeSkillDetailsForm: initializeSkillDetailsForm,
    },
  ),
  withStyles(styles),
)(SkillGapReportHeader);
