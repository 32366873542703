import React from "react";
import { compose } from "redux";
import { Field } from "redux-form/immutable";
import { withStyles } from "@material-ui/styles";
import { Grid, Typography } from "@material-ui/core";

import Tab from "app/components/Tab/Tab";
import { ICONS } from "app/app.constants";
import Tabs from "app/components/Tab/Tabs";
import ModalTab from "app/components/Modal/ModalTab";
import ModalTabs from "app/components/Modal/ModalTabs";
import { EMPLOYEE_SKILL_FORM } from "features/competence.constants";

const EmployeeSkillModalTabs = ({ tabs, classes, skillName }) => {
  return (
    <Grid container direction="column">
      <Grid item>
        <ModalTabs value={1}>
          <Grid container alignItems="center">
            <ModalTab disabled>
              <ICONS.SKILL />
            </ModalTab>
            <Grid
              xs
              item
              container
              alignItems="center"
              className={classes.itemTitleContainer}
            >
              <Typography variant="h6" className={classes.itemTitle}>
                {skillName}
              </Typography>
            </Grid>
          </Grid>
        </ModalTabs>
      </Grid>
      <Grid item>
        <Field
          name={EMPLOYEE_SKILL_FORM.ACTIVE_TAB}
          component={({ input }) => (
            <Tabs
              value={input.value}
              classes={{
                root: classes.tabs,
              }}
            >
              {tabs.map(({ title, disabled }, index) => (
                <Tab
                  key={index}
                  value={index}
                  title={title}
                  disabled={disabled}
                  onClick={() => input.onChange(index)}
                  classes={{
                    item: classes.tabItem,
                    itemSelected: classes.tabItemSelected,
                  }}
                />
              ))}
            </Tabs>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default compose(
  withStyles(theme => ({
    itemTitleContainer: {
      marginLeft: theme.spacing(-2),
    },
    itemTitle: {
      color: theme.altus.background.header,
    },
    avatar: {
      background: "transparent",
    },
    tabs: {
      background: theme.palette.grey[200],
    },
    tabItem: {
      color: theme.palette.getContrastText(theme.palette.grey[200]),
    },
    tabItemSelected: {
      background: theme.palette.background.paper,
    },
  })),
)(EmployeeSkillModalTabs);
