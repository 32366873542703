import { Map } from "immutable";

import { ACTIONS } from "app/app.constants";
import { EmployeeMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_MY_TEAM_EMPLOYEES_BY_USER: {
      if (error) return state;

      return Map(
        payload.map(employee => [
          employee.employeeId.toString(),
          EmployeeMapper.from(employee),
        ]),
      );
    }

    default:
      return state;
  }
};
