import { Map, OrderedMap } from "immutable";

import { ACTIONS, EMPTY_MAP } from "app/app.constants";

import {
  WorkHistoryMapper,
  ExperienceRecordMapper,
  EmployeeEducationMapper,
  SkillEvidenceMapper,
} from "app/app.mappers";

import employeeCourseMappers from "app/mappers/employeeCourse.mappers";

const initialState = Map({
  employeeCourseAttendance: EMPTY_MAP,
  workHistory: EMPTY_MAP,
  education: EMPTY_MAP,
  skillFileEvidence: EMPTY_MAP,
  experienceRecord: EMPTY_MAP,
});

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case ACTIONS.GET_EMPLOYEE_COURSES_ATTENDANCES_APPROVAL: {
      if (error) return state;

      return state.set(
        "employeeCourseAttendance",
        OrderedMap(
          payload.map(employeeCourseAttendance => [
            employeeCourseAttendance.employeeCourseAttendanceId.toString(),
            employeeCourseMappers.EmployeeCourseAttendance.from(
              employeeCourseAttendance,
            ),
          ]),
        ),
      );
    }

    case ACTIONS.GET_EMPLOYEE_EDUCATION_APPROVAL: {
      if (error) return state;

      return state.set(
        "education",
        OrderedMap(
          payload.map(education => [
            education.educationId.toString(),
            EmployeeEducationMapper.from(education),
          ]),
        ),
      );
    }

    case ACTIONS.GET_EMPLOYEE_WORK_HISTORY_APPROVAL: {
      if (error) return state;

      return state.set(
        "workHistory",
        OrderedMap(
          payload.map(workHistory => [
            workHistory.workHistoryId.toString(),
            WorkHistoryMapper.from(workHistory),
          ]),
        ),
      );
    }

    case ACTIONS.GET_MY_TEAM_FILE_EVIDENCES_NEEDING_APPROVAL: {
      if (error) return state;

      return state.set(
        "skillFileEvidence",
        OrderedMap(
          payload.map(skillEvidence => [
            skillEvidence.skillEvidenceId.toString(),
            SkillEvidenceMapper.from(skillEvidence),
          ]),
        ),
      );
    }

    case ACTIONS.REMOVE_EMPLOYEE_SKILL_FILE_EVIDENCE_APPROVAL:
    case ACTIONS.UPDATE_EMPLOYEE_SKILL_FILE_EVIDENCE_APPROVAL: {
      if (error) return state;

      const { skillEvidenceId } = action;

      return state.setIn(
        ["skillFileEvidence", skillEvidenceId.toString()],
        SkillEvidenceMapper.from(payload),
      );
    }

    case ACTIONS.REMOVE_EMPLOYEE_EDUCATION_APPROVAL:
    case ACTIONS.UPDATE_EMPLOYEE_EDUCATION_APPROVAL: {
      if (error) return state;

      const { id: educationId } = action;

      return state.setIn(
        ["education", educationId.toString()],
        EmployeeEducationMapper.from(payload),
      );
    }

    case ACTIONS.EDUCATION_APPROVAL_ON_CHANGE: {
      const { educationId } = action;

      return state.setIn(["education", educationId.toString()], payload);
    }

    case ACTIONS.WORK_HISTORY_APPROVAL_ON_CHANGE: {
      const { workHistoryId } = action;

      return state.setIn(["workHistory", workHistoryId.toString()], payload);
    }

    case ACTIONS.EMPLOYEE_COURSE_APPROVAL_ON_CHANGE: {
      const { employeeCourseId } = action;

      return state.setIn(
        ["employeeCourse", employeeCourseId.toString()],
        payload,
      );
    }

    case ACTIONS.REMOVE_WORK_HISTORY_APPROVAL:
    case ACTIONS.UPDATE_WORK_HISTORY_APPROVAL: {
      if (error) return state;

      const { id: workHistoryId } = action;

      return state.setIn(
        ["workHistory", workHistoryId.toString()],
        WorkHistoryMapper.from(payload),
      );
    }

    case ACTIONS.UPDATE_EMPLOYEE_COURSE_ATTENDANCE_APPROVAL:
    case ACTIONS.REMOVE_EMPLOYEE_COURSE_ATTENDANCE_APPROVAL: {
      if (error) return state;

      const { employeeCourseAttendanceId } = payload;

      return state.setIn(
        ["employeeCourseAttendance", employeeCourseAttendanceId.toString()],
        employeeCourseMappers.EmployeeCourseAttendance.from(payload),
      );
    }

    case ACTIONS.GET_PENDING_EXPERIENCE_RECORDS_FOR_VALIDATOR: {
      if (error) return state;

      return state.set(
        "experienceRecord",
        OrderedMap(
          payload.map(experienceRecord => [
            experienceRecord.experienceRecordId.toString(),
            ExperienceRecordMapper.from(experienceRecord),
          ]),
        ),
      );
    }

    case ACTIONS.APPROVE_EXPERIENCE_RECORD:
    case ACTIONS.REJECT_EXPERIENCE_RECORD:
    case ACTIONS.UNDO_APPROVE_EXPERIENCE_RECORD:
    case ACTIONS.CHANGE_EXPERIENCE_RECORD_VALIDATOR: {
      if (error) return state;

      return state.setIn(
        ["experienceRecord", action.experienceRecordId.toString()],
        ExperienceRecordMapper.from(payload),
      );
    }

    default:
      return state;
  }
};
