import { Map, OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { EmployeeMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_MY_TEAM_MANAGERS:
    case ACTIONS.GET_MANAGERS: {
      if (error) return state;

      return OrderedMap(
        payload.map(managerEmployee => [
          managerEmployee.employeeNumber.toString(),
          EmployeeMapper.from(managerEmployee),
        ]),
      );
    }

    default:
      return state;
  }
};
