import { OrderedMap } from "immutable";
import { ACTIONS } from "app/app.constants";
import { ExperienceDisciplineMapper } from "app/app.mappers";

const initialState = OrderedMap();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_EXPERIENCE_DISCIPLINES: {
      if (error) return state;

      return OrderedMap(
        payload.map(discipline => [
          discipline.experienceDisciplineId.toString(),
          ExperienceDisciplineMapper.from(discipline),
        ]),
      );
    }

    default:
      return state;
  }
};
