import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { getRoleFromState } from "features/competence.selectors";
import RoleCourseList from "features/components/RoleCourseList/RoleCourseList";

class RoleCourseListContainer extends PureComponent {
  render() {
    const { items } = this.props;

    if (!items.size) return null;

    return <RoleCourseList {...this.props} />;
  }
}

export default compose(
  connect((state, { roleId }) => ({
    role: getRoleFromState(state, roleId),
  })),
)(RoleCourseListContainer);
