import { Map, OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { ServiceCourseMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case ACTIONS.GET_SERVICE_COURSES_BY_SERVICE_WITH_PARENTS: {
      if (error) return state;

      return OrderedMap(
        payload.map(serviceCourse => [
          serviceCourse.serviceCourseId.toString(),
          ServiceCourseMapper.from(serviceCourse),
        ]),
      ).groupBy(serviceCourse => serviceCourse.get("serviceId").toString());
    }

    case ACTIONS.CREATE_SERVICE_COURSES: {
      if (error) return state;

      return state.set(
        action.serviceId.toString(),
        OrderedMap(
          payload.map(serviceCourse => [
            serviceCourse.serviceCourseId.toString(),
            ServiceCourseMapper.from(serviceCourse),
          ]),
        ),
      );
    }

    case ACTIONS.UPDATE_SERVICE_COURSE: {
      if (error) return state;

      return state.setIn(
        [payload.serviceId.toString(), payload.serviceCourseId.toString()],
        ServiceCourseMapper.from(payload),
      );
    }
    case ACTIONS.DELETE_SERVICE_COURSE: {
      if (error) return state;

      return state.deleteIn([
        action.serviceId.toString(),
        action.serviceCourseId.toString(),
      ]);
    }
    case ACTIONS.CLEAR_SERVICE_COURSES: {
      return initialState;
    }
    default:
      return state;
  }
};
