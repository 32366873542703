import { compose } from "redux";
import { connect } from "react-redux";
import React, { useEffect, useMemo, useCallback } from "react";

import { useModal } from "altus-modal";
import { BasePage, MultiSelectModal } from "altus-ui-components";

import {
  getCollectionSkillsFromState,
  getCollectionCoursesFromState,
  getAvailableSkillsForCollectionFromState,
  getAvailableCoursesForCollectionFromState,
} from "features/competence.selectors";

import {
  collectionCollectionOnLoad,
  createCollectionSkillOnSubmit,
  createCollectionCourseOnSubmit,
  getAvailableSkillsForCollection,
  getAvailableCoursesForCollection,
  deleteCollectionSkillAskConfirmation,
  deleteCollectionCourseAskConfirmation,
} from "features/competence.actions";

import {
  ADD_COLLECTION_SKILL_MODAL_ID,
  ADD_COLLECTION_COURSE_MODAL_ID,
} from "features/competence.constants";

import {
  getActionDataStateFromState,
  getFeatureFlagStatusFromState,
  getSummarizedDataStatesFromState,
} from "app/app.selectors";

import { Link } from "react-router-dom";
import routePaths from "app/routePaths";
import { ACTIONS, FEATURE_FLAG } from "app/app.constants";
import CollectionItemList from "features/hr/collections/components/CollectionItemList";

const getSkillKey = skill => skill.get("skillId");
const getSkillName = skill => skill.get("name");
const getCourseKey = course => course.get("courseId");
const getCourseName = course => course.get("name");

const CollectionCollectionContainer = ({
  dataState,
  breadcrumb,
  collectionId,
  dispatchOnLoad,
  availableSkills,
  availableCourses,
  collectionSkills,
  collectionCourses,
  isSkillsFeatureFlagEnabled,
  getAvailableSkillsDataState,
  getAvailableCoursesDataState,
  dispatchDeleteCollectionSkill,
  dispatchDeleteCollectionCourse,
  dispatchCreateCollectionSkillOnSubmit,
  dispatchCreateCollectionCourseOnSubmit,
  dispatchGetAvailableSkillsForCollection,
  dispatchGetAvailableCoursesForCollection,
}) => {
  const [isCourseOpen, toggleCourseModal] = useModal(
    ADD_COLLECTION_COURSE_MODAL_ID,
  );
  const [isSkillOpen, toggleSkillModal] = useModal(
    ADD_COLLECTION_SKILL_MODAL_ID,
  );

  useEffect(() => {
    dispatchOnLoad(collectionId);
  }, [collectionId, dispatchOnLoad]);

  useEffect(() => {
    if (isSkillOpen) {
      dispatchGetAvailableSkillsForCollection(collectionId);
    }
  }, [collectionId, isSkillOpen, dispatchGetAvailableSkillsForCollection]);

  useEffect(() => {
    if (isCourseOpen) {
      dispatchGetAvailableCoursesForCollection(collectionId);
    }
  }, [collectionId, isCourseOpen, dispatchGetAvailableCoursesForCollection]);

  const columns = useMemo(
    () => [
      {
        xs: true,
        getSortProperty: item => item.get("name"),
      },
    ],
    [],
  );

  const addSkillsSubmit = useCallback(
    selectedSkills => {
      dispatchCreateCollectionSkillOnSubmit(
        collectionId,
        selectedSkills.map(skill => skill.get("skillId")).toArray(),
      ).then(toggleSkillModal);
    },
    [collectionId, toggleSkillModal, dispatchCreateCollectionSkillOnSubmit],
  );

  const addCoursesSubmit = useCallback(
    selectedCourses => {
      dispatchCreateCollectionCourseOnSubmit(
        collectionId,
        selectedCourses.map(course => course.get("courseId")).toArray(),
      ).then(toggleCourseModal);
    },
    [collectionId, toggleCourseModal, dispatchCreateCollectionCourseOnSubmit],
  );

  return (
    <BasePage title={breadcrumb} dataState={dataState}>
      <CollectionItemList
        title="Courses"
        columns={columns}
        items={collectionCourses}
        getKey={getCourseKey}
        onAdd={toggleCourseModal}
        onDelete={course =>
          dispatchDeleteCollectionCourse(collectionId, course)
        }
        SortableListProps={{
          createSortableListRowProps: course => ({
            component: Link,
            to: `${routePaths.hrPortal}/courses/${course.get("courseId")}`,
          }),
        }}
      />
      {isSkillsFeatureFlagEnabled && (
        <CollectionItemList
          title="Skills"
          columns={columns}
          items={collectionSkills}
          getKey={getSkillKey}
          onAdd={toggleSkillModal}
          onDelete={skill => dispatchDeleteCollectionSkill(collectionId, skill)}
          SortableListProps={{
            createSortableListRowProps: skill => ({
              component: Link,
              to: `${routePaths.hrPortal}/skills/${skill.get("skillId")}`,
            }),
          }}
        />
      )}
      <MultiSelectModal
        open={isSkillOpen && !getAvailableSkillsDataState.isLoading()}
        displayChips
        onClose={toggleSkillModal}
        getName={getSkillName}
        items={availableSkills}
        title="Add Skills"
        onSubmit={addSkillsSubmit}
      />
      <MultiSelectModal
        open={isCourseOpen && !getAvailableCoursesDataState.isLoading()}
        displayChips
        onClose={toggleCourseModal}
        getName={getCourseName}
        items={availableCourses}
        title="Add Courses"
        onSubmit={addCoursesSubmit}
      />
    </BasePage>
  );
};

export default compose(
  connect(
    (state, { collectionId }) => ({
      collectionSkills: getCollectionSkillsFromState(state, collectionId),
      collectionCourses: getCollectionCoursesFromState(state, collectionId),
      availableSkills: getAvailableSkillsForCollectionFromState(
        state,
        collectionId,
      ),
      availableCourses: getAvailableCoursesForCollectionFromState(
        state,
        collectionId,
      ),
      getAvailableSkillsDataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_AVAILABLE_SKILLS_FOR_COLLECTION,
      ),
      getAvailableCoursesDataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_AVAILABLE_COURSES_FOR_COLLECTION,
      ),
      isSkillsFeatureFlagEnabled: getFeatureFlagStatusFromState(
        state,
        FEATURE_FLAG.SKILLS,
      ),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_COLLECTION,
        ACTIONS.GET_COLLECTION_ITEMS,
        ACTIONS.DELETE_COLLECTION_ITEM,
        ACTIONS.GET_AVAILABLE_SKILLS_FOR_COLLECTION,
        ACTIONS.GET_AVAILABLE_COURSES_FOR_COLLECTION,
      ),
    }),
    {
      dispatchOnLoad: collectionCollectionOnLoad,
      dispatchDeleteCollectionSkill: deleteCollectionSkillAskConfirmation,
      dispatchCreateCollectionSkillOnSubmit: createCollectionSkillOnSubmit,
      dispatchDeleteCollectionCourse: deleteCollectionCourseAskConfirmation,
      dispatchCreateCollectionCourseOnSubmit: createCollectionCourseOnSubmit,
      dispatchGetAvailableSkillsForCollection: getAvailableSkillsForCollection,
      dispatchGetAvailableCoursesForCollection: getAvailableCoursesForCollection,
    },
  ),
)(CollectionCollectionContainer);
