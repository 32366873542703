import { compose } from "redux";
import classNames from "classnames";
import moment from "moment";
import React from "react";

import { withStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";

import { getInitials } from "utils/app.util";

const EmployeeAvatar = ({ classes, employeeDisplayName, employeeHiredTo }) => {
  const isFormerEmployee =
    !!employeeHiredTo && employeeHiredTo.isBefore(moment());

  return (
    <Avatar
      classes={{
        root: classNames(classes.avatarRoot, {
          [classes.avatarRootFormerEmployee]: isFormerEmployee,
        }),
      }}
    >
      {getInitials(employeeDisplayName)}
    </Avatar>
  );
};

const styles = theme => ({
  avatarRoot: {
    fontSize: theme.typography.body1.fontSize,
    background: theme.palette.primary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  avatarRootFormerEmployee: {
    background: theme.palette.grey[400],
  },
});

export default compose(withStyles(styles))(EmployeeAvatar);
