import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { EMPTY_LIST } from "app/app.constants";
import { getRoleSkillsForRoleFromState } from "features/competence.selectors";
import RoleSkillListContainer from "features/components/RoleSkillList/RoleSkillListContainer";

class RoleRoleSkillList extends PureComponent {
  render() {
    return <RoleSkillListContainer {...this.props} />;
  }
}

RoleRoleSkillList.defaultProps = {
  items: EMPTY_LIST,
};

export default compose(
  connect(
    (state, { roleId }) => ({
      items: getRoleSkillsForRoleFromState(state, roleId),
    }),
    null,
  ),
)(RoleRoleSkillList);
