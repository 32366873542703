import { compose } from "redux";
import isNil from "lodash/isNil";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import ProgressBar from "app/components/ProgressBar";
import { EMPTY_MAP } from "app/app.constants";
import { getCourseStatsFromState } from "features/competence.selectors";

class CourseProgressBar extends PureComponent {
  render() {
    const { courseStats } = this.props;

    const memberCount = courseStats.get("memberCount");
    const total = isNil(memberCount) ? 0 : memberCount;

    const activeCount = courseStats.get("activeCount");
    const value = isNil(activeCount)
      ? 0
      : total === 0
      ? 100
      : (activeCount / total) * 100;

    return <ProgressBar value={value} title={`${value.toFixed(0)}%`} />;
  }
}

CourseProgressBar.defaultProps = {
  courseStats: EMPTY_MAP,
};

export default compose(
  connect((state, { courseId, courseStats }) => ({
    courseStats: courseStats
      ? courseStats
      : getCourseStatsFromState(state, courseId),
  })),
)(CourseProgressBar);
