import { compose } from "redux";
import isNil from "lodash/isNil";
import { withStyles } from "@material-ui/styles";
import { Map, isImmutable, isMap } from "immutable";
import { Grid, Typography } from "@material-ui/core";
import { formValueSelector } from "redux-form/immutable";
import React, { useCallback, useState, useMemo } from "react";

import { Table, BasePageHeader } from "altus-ui-components";

import { EMPTY_MAP, EMPTY_LIST } from "app/app.constants";
import SimpleFormModal from "app/components/SimpleFormModal";
import { createGuid, invokeIfFunction } from "utils/app.util";
import TableRowSubTable from "app/components/Table/TableRowSubTable";
import SortableListRow from "app/components/SortableList/SortableListRow";
import SortableListActionsHeader from "app/components/SortableList/SortableListActionsHeader";

const defaultFilterMatch = (filterValue, cellValue) =>
  filterValue === cellValue;

const createDefaultFilter = columns =>
  Map(
    columns.map(({ filter }, index) => [
      index.toString(),
      filter && filter.defaultValue,
    ]),
  ).filter(Boolean);

const renderTableRowComponent = props => {
  return <TableRowSubTable {...props} />;
};

const SortableTable = ({
  Icon,
  title,
  classes,
  columns,
  onRowClick,
  listActions,
  editModalId,
  keyName = "",
  EditModalProps,
  editEntityForm,
  displayAddButton,
  addButtonOnClick,
  items = EMPTY_MAP,
  noItemsMessage = "",
  EditEntityFormProps,
  BasePageHeaderProps,
  editFormValueSelector,
  hideBaseHeader = false,
  excludePadding = false,
  sortOrder = "sortOrder",
  EditEntityFormComponent,
  subListPropertyName = "",
  subListKey = "subListKeyId",
  displayNumberOfItems = false,
  subListFilterPropName = "name",
}) => {
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(createDefaultFilter(columns));

  if (editEntityForm) {
    editModalId = editModalId || createGuid();
    editFormValueSelector = formValueSelector(editEntityForm.form);
  }

  const clearFilter = useCallback(() => setFilter(EMPTY_MAP), []);

  const getKey = useCallback(item => item.get(keyName), [keyName]);

  const getSubRows = useCallback(
    row => {
      if (isImmutable(row)) {
        if (!row.get(subListPropertyName)) return;
      } else {
        if (!row[subListPropertyName]) return;
      }
      var result = isImmutable(row)
        ? row.get(subListPropertyName, EMPTY_LIST)
        : row.original?.[subListPropertyName]?.toArray();

      return isMap(result)
        ? result
        : Map(result.map(r => [r[subListKey]?.toString(), r]));
    },
    [subListPropertyName, subListKey],
  );

  const setCurrentFilter = (columnNo, value, items) => {
    setFilter(
      isNil(value)
        ? filter.delete(columnNo.toString())
        : filter.set(columnNo.toString(), value),
    );
  };

  const itemMatchesFilter = useCallback(
    item => {
      return filter.size
        ? filter.every((value, columnNo) => {
            const { getSortProperty } = columns[columnNo];
            const { match = defaultFilterMatch } = columns[columnNo].filter;

            const itemValue = getSortProperty
              ? getSortProperty(item)
              : undefined;

            return match(value, itemValue);
          })
        : true;
    },
    [columns, filter],
  );

  const itemMatchesSearch = useCallback(
    item => {
      return search
        ? columns
            .map(({ getSortProperty }) =>
              invokeIfFunction(getSortProperty, item),
            )
            .filter(Boolean)
            .some(value =>
              value
                .toString()
                .toLowerCase()
                .includes(search.trim().toLowerCase()),
            )
        : true;
    },
    [columns, search],
  );

  const subItemMatchesSearch = useCallback(
    item => {
      return search
        ? item
            .get(subListPropertyName)
            .filter(Boolean)
            .some(value =>
              value[subListFilterPropName]
                ? value[subListFilterPropName]
                    .toString()
                    .toLowerCase()
                    .includes(search.trim().toLowerCase())
                : false,
            )
        : true;
    },
    [search, subListPropertyName, subListFilterPropName],
  );

  const filterItem = useCallback(
    item =>
      itemMatchesFilter(item) &&
      (itemMatchesSearch(item) || subItemMatchesSearch(item)),
    [itemMatchesFilter, itemMatchesSearch, subItemMatchesSearch],
  );

  const sortedItems = useMemo(() => items.filter(filterItem), [
    filterItem,
    items,
  ]);

  const sortBy = React.useMemo(
    () => [
      {
        id: sortOrder,
        desc: false,
      },
    ],
    [sortOrder],
  );

  return (
    <>
      <Grid
        container
        className={excludePadding ? classes.rootNoPadding : classes.root}
      >
        {(title || displayAddButton || addButtonOnClick) && !hideBaseHeader && (
          <BasePageHeader
            Icon={Icon}
            title={title}
            displayAddButton={displayAddButton}
            addButtonOnClick={addButtonOnClick}
            {...BasePageHeaderProps}
          />
        )}
        <Grid className={classes.sticky} container>
          <SortableListActionsHeader
            Icon={Icon}
            title={title}
            items={items}
            striped={false}
            filter={filter}
            setFilter={setCurrentFilter}
            listActions={listActions}
            columns={columns}
            setSearch={setSearch}
            clearFilter={clearFilter}
            classes={{ root: classes.placeholder }}
          />
        </Grid>
        <Table
          getKey={getKey}
          useExpanded
          stickyHeader
          onRowClick={onRowClick}
          columns={columns}
          useGlobalFilter={false}
          items={sortedItems}
          getSubRows={getSubRows}
          noItemsMessage={noItemsMessage}
          initialState={{
            sortBy: sortBy,
          }}
          TableRowComponent={renderTableRowComponent}
        />
        {displayNumberOfItems && !!sortedItems.size && (
          <SortableListRow striped={false} noborder>
            <Grid container justifyContent="flex-end">
              <Typography variant="caption">
                {`${sortedItems.size} item(s)`}
              </Typography>
            </Grid>
          </SortableListRow>
        )}
      </Grid>
      {editEntityForm && (
        <SimpleFormModal
          Icon={Icon}
          form={editEntityForm}
          modalId={editModalId}
          FormProps={EditEntityFormProps}
          component={EditEntityFormComponent}
          formValueSelector={editFormValueSelector}
          {...EditModalProps}
        />
      )}
    </>
  );
};
const styles = theme => ({
  placeholder: {
    backgroundColor: theme.palette.background.default,
    border: "none",
    margin: `0px ${theme.spacing(3)}px ${theme.spacing(2.25)}px`,
  },
  root: {
    position: "relative",
    padding: `0px ${theme.spacing(3)}px ${theme.spacing(2.25)}px`,
  },
  rootNoPadding: {
    position: "relative",
  },
  children: {
    padding: `0px ${theme.spacing(3)}px ${theme.spacing(2.25)}px`,
  },
  sticky: {
    top: 0,
    zIndex: 2,
    position: "sticky",
  },
});

export default compose(withStyles(styles))(SortableTable);
