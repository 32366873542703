import { Map } from "immutable";
import { ACTIONS } from "app/app.constants";
import { SkillFileEvidenceMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_EMPLOYEE_SKILL_FILE_EVIDENCES: {
      if (error) return state;

      return Map(
        payload.map(employeeSkillFileEvidence => [
          employeeSkillFileEvidence.employeeSkillFileEvidenceId.toString(),
          SkillFileEvidenceMapper.from(employeeSkillFileEvidence),
        ]),
      );
    }

    case ACTIONS.DELETE_EMPLOYEE_SKILL_FILE_EVIDENCE: {
      if (error) return state;

      const { employeeSkillFileEvidenceId } = action;

      return state.delete(employeeSkillFileEvidenceId.toString());
    }

    default:
      return state;
  }
};
