import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import {
  getGapReportDepartmentsFromState,
  getSkillGapReportSkillsFromState,
} from "features/competence.selectors";

import { GAP_REPORT } from "app/app.constants";
import GapReport from "features/components/GapReport/GapReport";
import SkillGapReportHeader from "features/components/SkillGapReport/SkillGapReportHeader";
import SkillGapReportEmployeeRow from "features/components/SkillGapReport/SkillGapReportEmployeeRow";

class SkillGapReport extends PureComponent {
  renderHeader = () => {
    const { skills } = this.props;

    return <SkillGapReportHeader skills={skills} />;
  };

  renderEmployee = ({ employee, index }) => {
    const { root, skills } = this.props;

    return (
      <SkillGapReportEmployeeRow
        root={root}
        index={index}
        skills={skills}
        key={employee.get("employeeId")}
        employeeId={employee.get("employeeId")}
      />
    );
  };

  render() {
    const { title, root, employeesByDepartment, departments } = this.props;

    return (
      <GapReport
        root={root}
        title={title}
        renderHeader={this.renderHeader}
        departments={departments}
        employeesByDepartment={employeesByDepartment}
        renderEmployee={({ employee, index }) =>
          this.renderEmployee({ employee, index })
        }
        gapReportType={GAP_REPORT.SKILL_GAP_REPORT}
      />
    );
  }
}

export default compose(
  connect(state => ({
    skills: getSkillGapReportSkillsFromState(state),
    departments: getGapReportDepartmentsFromState(state),
  })),
)(SkillGapReport);
