import { compose } from "redux";
import { connect } from "react-redux";
import React, { useCallback, useState } from "react";

import { invokeIfFunction } from "utils/app.util";
import { EMPTY_MAP, EMPTY_LIST, ACTIONS } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import { BASE_FILTERS } from "features/components/GapReport/GapReportFilterBase";
import GapReportFilterBase from "features/components/GapReport/GapReportFilterBase";
import { getTrainingAndCompetencyReportEmployeesFromState } from "features/competence.selectors";

const initialFilter = EMPTY_MAP.set(BASE_FILTERS.EMPLOYEES, EMPTY_LIST).set(
  BASE_FILTERS.DEPARTMENTS,
  EMPTY_LIST,
);

const TrainingAndCompetencyReportFilter = ({
  getGapReport,
  clearGapReport,
  downloadGapReport,
  employeesByDepartment,
}) => {
  const [count, setCount] = useState(0);
  const renderFilter = useCallback(
    ({ renderEmployeeFilter, renderDepartmentFilter }) => (
      <>
        {invokeIfFunction(renderDepartmentFilter)}
        {invokeIfFunction(renderEmployeeFilter)}
      </>
    ),
    [],
  );

  const clearReport = useCallback(() => {
    setCount(count + 1);
    clearGapReport();
  }, [clearGapReport, count]);

  return (
    <GapReportFilterBase
      getGapReport={getGapReport}
      initialFilter={initialFilter}
      clearGapReport={clearReport}
      renderFilter={renderFilter}
      downloadGapReport={downloadGapReport}
      downloadBtnDisabled={!employeesByDepartment?.size}
    />
  );
};

export default compose(
  connect(state => ({
    employeesByDepartment: getTrainingAndCompetencyReportEmployeesFromState(
      state,
    ),
    dataState: getSummarizedDataStatesFromState(
      state,
      ACTIONS.GET_TRAINING_AND_COMPETENCY_REPORT,
    ),
  })),
)(TrainingAndCompetencyReportFilter);
