import Agent from "infrastructure/agent";

/**
 * Collection
 */
const getCollections = filter => Agent.get(`/competencyCollection`, filter);

const getCollection = competencyCollectionId =>
  Agent.get(`/competencyCollection/${competencyCollectionId}`);

const createCollection = course => Agent.post(`/competencyCollection`, course);

const updateCollection = (competencyCollectionId, collection) =>
  Agent.put(`/competencyCollection/${competencyCollectionId}`, collection);

const updateCollectionMatrix = (competencyCollectionId, collectionMatrix) =>
  Agent.put(
    `/competencyCollection/${competencyCollectionId}/matrix`,
    collectionMatrix,
  );

const deleteCollection = competencyCollectionId =>
  Agent.delete(`/competencyCollection/${competencyCollectionId}`);

const getCollectionMatrix = competencyCollectionId =>
  Agent.get(`/competencyCollection/${competencyCollectionId}/matrix`);

const resetCollectionMatrix = competencyCollectionId =>
  Agent.put(`/competencyCollection/${competencyCollectionId}/matrix/reset`);

const getCollectionsByCourseId = courseId =>
  Agent.get(`/competencyCollection/bycourse/${courseId}`);

const getCollectionsBySkillId = skillId =>
  Agent.get(`/competencyCollection/byskill/${skillId}`);

/**
 * Collection Course
 */

const getCollectionCourses = competencyCollectionId =>
  Agent.get(`/competencyCollection/${competencyCollectionId}/course`);

const createCollectionCourses = (competencyCollectionId, coursesIds) =>
  Agent.post(
    `/competencyCollection/${competencyCollectionId}/course/assign-courses`,
    coursesIds,
  );

const deleteCollectionCourse = (competencyCollectionId, courseId) =>
  Agent.delete(
    `/competencyCollection/${competencyCollectionId}/course/${courseId}`,
  );

const getAvailableCoursesForCollection = competencyCollectionId =>
  Agent.get(
    `/competencyCollection/${competencyCollectionId}/course/availablecourses`,
  );

/**
 * Collection Skill
 */

const getCollectionSkills = competencyCollectionId =>
  Agent.get(`/competencyCollection/${competencyCollectionId}/skill`);

const createCollectionSkills = (competencyCollectionId, skillsIds) =>
  Agent.post(
    `/competencyCollection/${competencyCollectionId}/skill/assign-skills`,
    skillsIds,
  );

const deleteCollectionSkill = (competencyCollectionId, skillId) =>
  Agent.delete(
    `/competencyCollection/${competencyCollectionId}/skill/${skillId}`,
  );

const getAvailableSkillsForCollection = competencyCollectionId =>
  Agent.get(
    `/competencyCollection/${competencyCollectionId}/skill/availableskills`,
  );

export default {
  getCollection,
  getCollections,
  createCollection,
  updateCollection,
  deleteCollection,
  getCollectionSkills,
  getCollectionMatrix,
  getCollectionCourses,
  deleteCollectionSkill,
  createCollectionSkills,
  resetCollectionMatrix,
  updateCollectionMatrix,
  deleteCollectionCourse,
  createCollectionCourses,
  getCollectionsBySkillId,
  getCollectionsByCourseId,
  getAvailableSkillsForCollection,
  getAvailableCoursesForCollection,
};
