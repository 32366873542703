import { compose } from "redux";
import { connect } from "react-redux";
import React, { useMemo } from "react";
import { Grid, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Field, formValueSelector } from "redux-form/immutable";

import {
  getCourseTypesFromState,
  getCourseGroupsFromState,
} from "features/competence.selectors";

import TextField from "app/components/form/TextField";
import ValidityPeriodField from "features/components/ValidityPeriodField";
import { COURSE_FORM } from "features/competence.constants";
import CountryMultiselectField from "app/components/form/CountryMultiselectField";

import { required, greaterThanOrEqualTo } from "utils/validation.util";

const greaterThanOrEqualTo1 = greaterThanOrEqualTo(1);

const CourseForm = ({
  classes,
  courseTypes,
  courseGroups,
  handleSubmit,
  selectedCourseTypeId,
  validateCourseTypeChange,
}) => {
  var selectedCourseType = useMemo(
    () => courseTypes.get(selectedCourseTypeId?.toString()),
    [courseTypes, selectedCourseTypeId],
  );

  return (
    <form id={COURSE_FORM.ID} onSubmit={handleSubmit}>
      <Grid container direction="column">
        <Grid item>
          <Field
            label="Course Name"
            component={TextField}
            name={COURSE_FORM.NAME}
          />
        </Grid>
        <Grid item container>
          <Field
            rows={5}
            multiline
            label={
              <Field
                name={COURSE_FORM.DESCRIPTION}
                component={({ input }) =>
                  input.value
                    ? `Description (${1000 -
                        input.value.length} characters remaining)`
                    : "Description"
                }
              />
            }
            component={TextField}
            name={COURSE_FORM.DESCRIPTION}
            inputProps={{
              maxLength: 1000,
            }}
          />
        </Grid>
        <Grid container item spacing={1}>
          <Grid item xs={6}>
            <Field
              required
              select
              validate={[required]}
              disabled={
                validateCourseTypeChange && selectedCourseType?.get("isCustom")
              }
              label="Course Type"
              component={TextField}
              name={COURSE_FORM.COURSE_TYPE_ID}
            >
              {courseTypes
                .valueSeq()
                .filter(
                  courseType =>
                    !validateCourseTypeChange ||
                    !selectedCourseType ||
                    !courseType.get("isCustom") ||
                    selectedCourseType.get("isCustom"),
                )
                .map(courseType => (
                  <MenuItem
                    key={courseType.get("courseTypeId")}
                    value={courseType.get("courseTypeId")}
                  >
                    {courseType.get("name")}
                  </MenuItem>
                ))}
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              select
              label="Course group"
              component={TextField}
              name={COURSE_FORM.COURSE_GROUP}
            >
              <MenuItem value="">None</MenuItem>
              {courseGroups.map(courseGroup => (
                <MenuItem
                  key={courseGroup.get("courseGroupId")}
                  value={courseGroup.get("courseGroupId")}
                >
                  {courseGroup.get("name")}
                </MenuItem>
              ))}
            </Field>
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item xs={6} className={classes.statusPosition}>
            <Field
              select
              label="Status"
              component={TextField}
              name={COURSE_FORM.IS_ACTIVE}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Not Active</MenuItem>
            </Field>
          </Grid>
          <Grid item xs={6}>
            <Field
              name={COURSE_FORM.VALIDITY}
              component={ValidityPeriodField}
              format={null}
              validation={[greaterThanOrEqualTo1]}
            />
          </Grid>
        </Grid>
        <Grid>
          <Field
            name={COURSE_FORM.COUNTRIES}
            component={CountryMultiselectField}
          />
        </Grid>
        <Grid item>
          <Field
            label="External Link"
            component={TextField}
            placeholder="https://www.example.com"
            name={COURSE_FORM.EXTERNAL_URL}
          />
        </Grid>
      </Grid>
    </form>
  );
};

const formSelector = formValueSelector(COURSE_FORM.ID);

const styles = {
  statusPosition: {
    display: "flex",
    alignItems: "flex-end",
  },
};

export default compose(
  connect(state => ({
    courseGroups: getCourseGroupsFromState(state),
    courseTypes: getCourseTypesFromState(state),
    selectedCourseTypeId: formSelector(state, COURSE_FORM.COURSE_TYPE_ID),
  })),
  React.memo,
)(withStyles(styles)(CourseForm));
