import { Map, OrderedMap, List } from "immutable";

import { ACTIONS } from "app/app.constants";
import { EmployeeSkillMapper, SkillMapper } from "app/app.mappers";

const initialState = Map({
  skill: Map(),
  employeeSkill: Map(),
});

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_SKILL_GAP_REPORT: {
      return initialState;
    }

    case ACTIONS.GET_SKILL_GAP_REPORT: {
      if (error) return state;

      return state
        .set(
          "employeeSkill",
          List(payload.employeeSkills)
            .map(EmployeeSkillMapper.from)
            .groupBy(employeeSkill =>
              employeeSkill.get("employeeId").toString(),
            )
            .map(employeeSkillsByEmployeeId =>
              Map(
                employeeSkillsByEmployeeId.map(employeeSkill => [
                  employeeSkill.get("skillId").toString(),
                  employeeSkill,
                ]),
              ),
            ),
        )
        .set(
          "skill",
          OrderedMap(
            payload.skills.map(skill => [
              skill.skillId.toString(),
              SkillMapper.from(skill),
            ]),
          ),
        );
    }

    default:
      return state;
  }
};
