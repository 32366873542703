import { compose } from "redux";
import { connect } from "react-redux";
import { IconButton, Tooltip } from "@material-ui/core";
import React, { useEffect, useMemo, useCallback } from "react";

import { useModal } from "altus-modal";
import { MultiSelectModal } from "altus-ui-components";

import {
  updateServiceCourse,
  serviceCoursesOnLoad,
  serviceCoursesOnUnload,
  createServiceCoursesOnSubmit,
  getAvailableCoursesForService,
  deleteServiceCourseAskConfirmation,
} from "features/competence.actions";

import {
  getActionDataStateFromState,
  getSummarizedDataStatesFromState,
} from "app/app.selectors";

import {
  getServicesFromState,
  getAvailableCoursesForServiceFromState,
} from "features/competence.selectors";

import SwitchField from "app/components/SwitchField";
import CrudBasePage from "app/components/CrudBasePage";
import { ACTIONS, ICONS, CRITICALITY } from "app/app.constants";

import { CREATE_SERVICE_COURSE_FORM } from "features/competence.constants";
import ServiceCourseListContainer from "features/service/components/ServiceCourseListContainer";

const getKey = item => item.get("serviceSkillId");
const getCourseName = course => course.get("name");

const ServiceCoursesContainer = ({
  dataState,
  breadcrumb,
  services,
  dispatchOnLoad,
  serviceId,
  dispatchOnUnload,
  availableCourses,
  getAvailableCoursesDataState,
  dispatchUpdateServiceCourse,
  dispatchDeleteServiceCourse,
  dispatchCreateServiceCourseOnSubmit,
  dispatchGetAvailableCoursesForService,
}) => {
  const [isOpen, toggleModal] = useModal(CREATE_SERVICE_COURSE_FORM.ID);

  useEffect(() => {
    dispatchOnLoad(serviceId);

    return () => dispatchOnUnload();
  }, [serviceId, dispatchOnUnload, dispatchOnLoad]);

  useEffect(() => {
    if (isOpen) {
      dispatchGetAvailableCoursesForService(serviceId);
    }
  }, [serviceId, isOpen, dispatchGetAvailableCoursesForService]);

  const columns = useMemo(
    () => [
      {
        xs: 4,
        title: "Course Name",
        getSortProperty: item => item.getIn(["courseName"]),
      },
      {
        xs: true,
        title: "Group",
        getSortProperty: item => item.getIn(["courseGroupName"]),
      },
      {
        xs: true,
        title: "Type",
        getSortProperty: item => item.getIn(["courseTypeName"]),
      },
      {
        xs: true,
        title: "Valid",
        getSortProperty: item => item.getIn(["courseValidityMonthsString"]),
      },
      {
        xs: true,
        title: "Status",
        getSortProperty: item => item.getIn(["courseStatus"]),
      },
      {
        xs: true,
        justify: "center",
        container: true,
        title: "Criticality",
        getValue: item => (
          <SwitchField
            label="Critical"
            checked={item.get("criticality") === CRITICALITY.CRITICAL}
            onChange={() =>
              dispatchUpdateServiceCourse(
                item.get("serviceId"),
                item.get("courseId"),
                item.update("criticality", courseCriticality =>
                  courseCriticality === CRITICALITY.CRITICAL
                    ? CRITICALITY.NOT_CRITICAL
                    : CRITICALITY.CRITICAL,
                ),
              )
            }
          />
        ),
      },
    ],
    [dispatchUpdateServiceCourse],
  );

  const actions = useMemo(
    () => [
      {
        getValue: item =>
          item.get("serviceId").toString() === serviceId.toString() && (
            <Tooltip title="Delete">
              <IconButton onClick={() => dispatchDeleteServiceCourse(item)}>
                <ICONS.DELETE />
              </IconButton>
            </Tooltip>
          ),
      },
    ],
    [serviceId, dispatchDeleteServiceCourse],
  );

  const addCoursesSubmit = useCallback(
    selectedCourses => {
      dispatchCreateServiceCourseOnSubmit(
        serviceId,
        selectedCourses.map(course => course.get("courseId")).toArray(),
      ).then(toggleModal);
    },
    [serviceId, toggleModal, dispatchCreateServiceCourseOnSubmit],
  );

  return (
    <CrudBasePage
      dataState={dataState}
      title={breadcrumb}
      displayAddButton={true}
      displaySearchField={true}
      addButtonOnClick={toggleModal}
    >
      {services.map(service => {
        const innerServiceId = service.get("serviceId");

        return (
          <ServiceCourseListContainer
            Icon={ICONS.COURSE}
            key={innerServiceId}
            serviceId={innerServiceId}
            getKey={getKey}
            actions={actions}
            columns={columns}
            inherited={innerServiceId.toString() !== serviceId.toString()}
          />
        );
      })}
      <MultiSelectModal
        open={isOpen && !getAvailableCoursesDataState.isLoading()}
        displayChips
        onClose={toggleModal}
        getName={getCourseName}
        items={availableCourses}
        title="Add Courses"
        onSubmit={addCoursesSubmit}
      />
    </CrudBasePage>
  );
};

export default compose(
  connect(
    (state, { serviceId }) => ({
      services: getServicesFromState(state, serviceId),
      availableCourses: getAvailableCoursesForServiceFromState(
        state,
        serviceId,
      ),
      getAvailableCoursesDataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_AVAILABLE_COURSES_FOR_SERVICE,
      ),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_SERVICE_COURSES_BY_SERVICE_WITH_PARENTS,
        ACTIONS.GET_AVAILABLE_COURSES_FOR_SERVICE,
        ACTIONS.UPDATE_SERVICE_COURSE,
      ),
    }),
    {
      dispatchOnLoad: serviceCoursesOnLoad,
      dispatchOnUnload: serviceCoursesOnUnload,
      dispatchUpdateServiceCourse: updateServiceCourse,
      dispatchDeleteServiceCourse: deleteServiceCourseAskConfirmation,
      dispatchCreateServiceCourseOnSubmit: createServiceCoursesOnSubmit,
      dispatchGetAvailableCoursesForService: getAvailableCoursesForService,
    },
  ),
)(ServiceCoursesContainer);
