import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { RouteContainer, NavTabsContainer } from "altus-ui-components";

import { ROUTE_KEYS } from "app/routes";
import { EMPTY_MAP } from "app/app.constants";
import { setRouteCount } from "utils/route.util";
import { courseOnLoad } from "features/competence.actions";
import CourseDetailsHeader from "features/course/CourseDetailsHeader";
import { getCourseStatsFromState } from "features/competence.selectors";

class CourseContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad, courseId } = this.props;

    dispatchOnLoad(courseId);
  }

  getCourseRouteCount = key => {
    const { courseStats } = this.props;

    switch (key) {
      case ROUTE_KEYS.COURSE_ROLES:
        return courseStats.get("rolesCount");

      case ROUTE_KEYS.COURSE_MEMBERS: {
        return courseStats.get("memberCount");
      }
      default:
        return null;
    }
  };

  render() {
    const { routes, courseId } = this.props;

    return (
      <>
        <CourseDetailsHeader courseId={courseId} />
        <NavTabsContainer
          routes={routes.map(route =>
            setRouteCount(route, this.getCourseRouteCount(route.key)),
          )}
        />
        <RouteContainer routes={routes} />
      </>
    );
  }
}

CourseContainer.defaultProps = {
  courseStats: EMPTY_MAP,
};

export default compose(
  connect(
    (state, { courseId }) => ({
      courseStats: getCourseStatsFromState(state, courseId),
    }),
    {
      dispatchOnLoad: courseOnLoad,
    },
  ),
)(CourseContainer);
