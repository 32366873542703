import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Grid, withStyles, Typography } from "@material-ui/core";

import {
  getCurrentUserFromState,
  getCurrentEmployeeFromState,
} from "app/app.selectors";

import { compose } from "redux";
import CurrentUserAvatar from "app/components/CurrentUserAvatar";

class ApplicationSidebarTopContent extends PureComponent {
  render() {
    const { classes, currentEmployee, currentUser } = this.props;

    return (
      <Grid item className={classes.sidebarTopContainer}>
        <Grid
          item
          container
          justifyContent="center"
          className={classes.userAvatar}
        >
          <CurrentUserAvatar />
        </Grid>
        <Grid
          item
          container
          variant="caption"
          color="secondary"
          component={Typography}
          justifyContent="center"
        >
          {currentEmployee.get("employeeNumber")
            ? `#${currentEmployee.get("employeeNumber")}`
            : null}
        </Grid>
        <Grid
          item
          container
          variant="caption"
          color="secondary"
          justifyContent="center"
          component={Typography}
          className={classes.employeeName}
        >
          {currentUser.get("fullName")}
        </Grid>
        <Grid
          item
          container
          variant="caption"
          color="secondary"
          justifyContent="center"
          component={Typography}
          className={classes.employeeDepartmentName}
        >
          {currentEmployee.get("departmentName")}
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  sidebarTopContainer: {
    textAlign: "center",
    margin: theme.spacing(2),
  },
  employeeName: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  employeeDepartmentName: {
    fontSize: "0.7rem",
  },
  userAvatar: {
    margin: `${theme.spacing(1)}px 0`,
  },
});

export default compose(
  connect(state => ({
    currentUser: getCurrentUserFromState(state),
    currentEmployee: getCurrentEmployeeFromState(state),
  })),
  withStyles(styles),
)(ApplicationSidebarTopContent);
