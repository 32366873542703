import { compose } from "redux";
import { connect } from "react-redux";
import { mergeWith } from "immutable";
import React, { useEffect, useMemo } from "react";

import { OverviewContainer } from "altus-ui-components";

import {
  getCurrentEmployeeStats,
  getVerifierStats,
} from "features/competence.actions";

import {
  getEmployeeStatsFromState,
  getVerifierStatsFromState,
} from "features/competence.selectors";

import {
  getCurrentEmployeeFromState,
  getCurrentUserAppRolesFromState,
} from "app/app.selectors";

import { hasRequiredAccessLevel } from "utils/app.util";
import { APP_ROLES, EMPTY_MAP } from "app/app.constants";
import { getEmployeeRouteCount, getEmployeeChipType } from "utils/route.util";
import MyPageOverviewContainerTitle from "features/myPage/MyPageOverviewContainerTitle";

const MyPageOverviewContainer = ({
  Icon,
  match,
  parentRoutes,
  parentBreadcrumb,
  employeeStats = EMPTY_MAP,
  verifierStats = EMPTY_MAP,
  currentUserAppRoles = EMPTY_MAP,
  currentEmployee,
  dispatchGetVerifierStats,
  dispatchGetCurrentEmployeeStats,
}) => {
  useEffect(() => {
    const userHasEmployeeAccess = hasRequiredAccessLevel(
      APP_ROLES.EMPLOYEE,
      currentUserAppRoles,
      currentEmployee?.get("employeeId"),
    );
    if (userHasEmployeeAccess) {
      dispatchGetCurrentEmployeeStats();
    }

    const userHasVerifierAccess = hasRequiredAccessLevel(
      APP_ROLES.HR_ASSESSOR_VERIFIER,
      currentUserAppRoles,
      undefined,
    );

    if (userHasVerifierAccess) {
      dispatchGetVerifierStats();
    }
  }, [
    dispatchGetCurrentEmployeeStats,
    dispatchGetVerifierStats,
    currentEmployee,
    currentUserAppRoles,
  ]);

  const allStats = mergeWith(
    (oldValue, newValue) => oldValue + newValue,
    employeeStats,
    verifierStats,
  );

  const routesWithLabel = useMemo(
    () =>
      parentRoutes.map(route => ({
        ...route,
        chipType: getEmployeeChipType(allStats, route),
        chipLabel: getEmployeeRouteCount(allStats, route.key),
      })),
    [parentRoutes, allStats],
  );

  return (
    <OverviewContainer
      Icon={Icon}
      match={match}
      parentRoutes={routesWithLabel}
      BasePageProps={{
        title: (
          <MyPageOverviewContainerTitle
            Icon={Icon}
            title={parentBreadcrumb}
            status={employeeStats.get("competencyStatus")}
          />
        ),
      }}
    />
  );
};

export default compose(
  connect(
    state => {
      const currentEmployee = getCurrentEmployeeFromState(state);

      return {
        employeeStats: currentEmployee
          ? getEmployeeStatsFromState(state, currentEmployee.get("employeeId"))
          : undefined,
        verifierStats: getVerifierStatsFromState(state),
        currentEmployee: currentEmployee,
        currentUserAppRoles: getCurrentUserAppRolesFromState(state),
      };
    },
    {
      dispatchGetCurrentEmployeeStats: getCurrentEmployeeStats,
      dispatchGetVerifierStats: getVerifierStats,
    },
  ),
)(MyPageOverviewContainer);
