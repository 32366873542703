import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { EMPTY_MAP } from "app/app.constants";
import RoleProgressBar from "features/role/components/RoleProgressBar";
import { getEmployeeRoleStatsFromState } from "features/competence.selectors";

class EmployeeRoleProgressBar extends PureComponent {
  render() {
    const { roleStats } = this.props;

    return <RoleProgressBar roleStats={roleStats} />;
  }
}

EmployeeRoleProgressBar.defaultProps = {
  roleStats: EMPTY_MAP,
};

export default compose(
  connect((state, { employeeId, roleId }) => ({
    roleStats: getEmployeeRoleStatsFromState(state, employeeId, roleId),
  })),
)(EmployeeRoleProgressBar);
