import { List } from "immutable";

/**
 * Modal
 */
export const CREATE_ROLE_MODAL_ID = "CREATE_ROLE_MODAL";
export const ADD_ROLE_EMPLOYEE_MODAL_ID = "ADD_ROLE_EMPLOYEE_MODAL_ID";
export const CREATE_EMPLOYEE_COURSE_ATTENDANCE_MODAL_ID =
  "CREATE_EMPLOYEE_COURSE_ATTENDANCE_MODAL_ID";
export const EDIT_EMPLOYEE_COURSE_MODAL_ID = "EDIT_EMPLOYEE_COURSE_MODAL_ID";
export const EDIT_ROLE_MODAL_ID = "EDIT_ROLE_MODAL";
export const GENERATE_EMPLOYEES_CVS_MODAL_ID =
  "GENERATE_EMPLOYEES_CVS_MODAL_ID";
export const ADD_PREREQUISITE_COURSE_MODAL_ID = "ADD_PREREQUISITE_COURSE_MODAL";
export const COURSE_DETAILS_MODAL_ID = "COURSE_DETAILS_MODAL";
export const ROLE_DETAILS_MODAL_ID = "ROLE_DETAILS_MODAL";
export const CREATE_EMPLOYEE_SKILL_MODAL_ID = "CREATE_EMPLOYEE_SKILL_MODAL_ID";
export const ADD_EMPLOYEE_COURSE_ATTENDANCE_MODAL_ID =
  "ADD_EMPLOYEE_COURSE_ATTENDANCE_MODAL_ID";
export const ADD_EMPLOYEE_SKILL_EVIDENCE_MODAL_ID =
  "ADD_EMPLOYEE_SKILL_EVIDENCE_MODAL_ID";
export const SKILL_DETAILS_MODAL_ID = "SKILL_DETAILS_MODAL";
export const ADD_EMPLOYEE_ROLE_MODAL_ID = "ADD_EMPLOYEE_ROLE_MODAL_ID";
export const ADD_EMPLOYEE_EDUCATION_MODAL_ID =
  "ADD_EMPLOYEE_EDUCATION_MODAL_ID";
export const ADD_EMPLOYEE_WORK_HISTORY_MODAL_ID =
  "ADD_EMPLOYEE_WORK_HISTORY_MODAL_ID";
export const CREATE_SPECIFICATION_MODAL_ID = "CREATE_SPECIFICATION_MODAL";
export const ADD_COLLECTION_COURSE_MODAL_ID = "ADD_COLLECTION_COURSE_MODAL";
export const ADD_COLLECTION_SKILL_MODAL_ID = "ADD_COLLECTION_SKILL_MODAL";
export const CREATE_SERVICE_MODAL_ID = "CREATE_SERVICE_MODAL_ID";

/**
 * Misc
 */
export const VALIDITY_MONTHS = List([6, 12, 24, 36, 48, 60]);
export const DEFAULT_SKILL_FORM_REQUIRED_EVIDENCE_COUNT = 3;

export const VALIDITY_PERIOD_OPTIONS = {
  ALWAYS: 0,
  MONTHS: 1,
};

/**
 * Form
 */
export const COURSE_FORM = {
  ID: "createCourse",
  NAME: "name",
  DURATION: "duration",
  IS_ACTIVE: "isActive",
  DESCRIPTION: "description",
  VALIDITY: "validityMonths",
  COURSE_TYPE: "courseType",
  COURSE_TYPE_ID: "courseTypeId",
  EXTERNAL_URL: "externalUrl",
  COURSE_GROUP: "courseGroupId",
  COUNTRIES: "countries",
};

export const EMPLOYEE_FORM = {
  ID: "employee",
  PHONE: "phone",
  LAST_NAME: "lastName",
  POSITION: "position",
  FIRST_NAME: "firstName",
  EMAIL_WORK: "emailWork",
  EMPLOYEE_ID: "employeeId",
  EMPLOYEE_NUMBER: "employeeNumber",
  MOBILE_PHONE: "cellPhone",
  DISPLAY_NAME: "displayName",
};

export const EMPLOYEE_COURSE_FORM = {
  ID: "employeeCourse",
  EMPLOYEE_COURSE_ID: "employeeCourseId",
  COURSE_ID: "courseId",
  COURSE_NAME: "courseName",
  START_DATE: "startDate",
  END_DATE: "endDate",
  FILES: "files",
  EMPLOYEE: "employeeId",
  COURSE_GROUP: "courseGroupId",
  ACTIVE_TAB: "activeTab",
  COMPETENCY_STATUS: "competencyStatus",
  IS_ACTIVE: "isActive",
  IS_OVERRIDDEN: "isOverridden",
  OVERRIDE_REASON: "overrideReason",
  PREREQUISITE_COURSE_ID: "prerequisiteCourseId",
};

export const ROLE_FORM = {
  ID: "role",
  ROLE_ID: "roleId",
  NAME: "name",
  DESCRIPTION: "description",
  PARENT_ID: "parentId",
  OWNER_ID: "ownerId",
  IS_ACTIVE: "isActive",
  COUNTRIES: "countries",
};

export const EMPLOYEE_ROLE_FORM = {
  ID: "employeeRole",
  ROLE: "roleId",
  EMPLOYEE: "employeeId",
};

export const CREATE_ROLE_COURSE_FORM = {
  ID: "roleCourse",
  ROLE_ID: "roleId",
  COURSE_ID: "courseId",
};

export const CREATE_ROLE_EMPLOYEE_FORM = {
  ID: "roleEmployee",
  ROLE_ID: "roleId",
  EMPLOYEE_ID: "employeeId",
};

export const EDUCATION_FORM = {
  ID: "employeeEducation",
  EDUCATION_ID: "educationId",
  CATEGORY: "competencyCategoryId",
  PARENT_CATEGORY: "parentCompetencyCategoryId",
  FROM_DATE: "from",
  TO_DATE: "to",
  TITLE: "title",
  COMPETENCY_STATUS: "competencyStatus",
  FILES: "files",
};

export const WORK_HISTORY_FORM = {
  ID: "workHistory",
  WORK_HISTORY_ID: "workHistoryId",
  EMPLOYEE_ID: "employeeId",
  COMPETENCY_CATEGORY_ID: "competencyCategoryId",
  PARENT_COMPETENCY_CATEGORY_ID: "parentCompetencyCategoryId",
  TITLE: "title",
  COMPANY: "company",
  DESCRIPTION: "description",
  FROM: "from",
  TO: "to",
  COMPETENCY_STATUS: "competencyStatus",
  FILES: "files",
};

export const EMPLOYEE_CV_OPTIONS_FORM = {
  ID: "employeeCVOptions",
  INCLUDE_EDUCATION: "includeEducation",
  INCLUDE_WORK_EXPERIENCE: "includeWorkExperience",
  INCLUDE_COURSE: "includeCourse",
  EMPLOYEE_IDS: "employeeIds",
  DESCRIPTION: "description",
  FILE_FORMAT: "fileFormat",
};

export const EXPORT_FILE_FORMAT = {
  WORD: 0,
  PDF: 1,
};

export const COURSE_GROUP_FORM = {
  ID: "courseGroup",
  NAME: "name",
};

export const COURSE_TYPE_FORM = {
  ID: "courseType",
  NAME: "name",
};

export const ADD_PREREQUISITE_COURSE_FORM = {
  ID: "prerequisiteCourse",
  PREREQUISITE_COURSE_ID: "prerequisiteCourseId",
};

export const SKILL_GROUP_FORM = {
  ID: "skillGroup",
  NAME: "name",
};

export const SKILL_TYPE_FORM = {
  ID: "skillType",
  NAME: "name",
};

export const SKILL_FORM = {
  ID: "skill",
  NAME: "name",
  DESCRIPTION: "description",
  SKILL_TYPE: "skillTypeId",
  SKILL_GROUP: "skillGroupId",
  VALIDITY: "validityMonths",
  COUNTRIES: "countries",
  REQUIRED_EVIDENCE_COUNT: "requiredEvidenceCount",
};

export const EMPLOYEE_EXPERIENCE_RECORD_REPORT_FORM = {
  ID: "employeeExperienceRecordsReport",
  EXPERIENCE_CATEGORIES: "experienceRecordCategories",
};

export const EXPERIENCE_RECORD_DETAILS_FORM = {
  ID: "experienceRecord",
  EMPLOYEES: "employees",
  EMPLOYEE_NAME: "employeeDisplayName",
  EMPLOYEE_JOB_TITLE: "employeeJobTitle",
  EMPLOYEE_DEPARTMENT: "employeeDepartment",
  EMPLOYEE_POSITION: "employeePosition",
  PERFORMED_AS: "positionId",
  VALIDATOR: "validatorId",
  JOB_NUMBER: "jobId",
  CLIENT: "experienceClientName",
  LOCATION: "locationName",
  DISCIPLINE: "experienceDisciplineName",
  FROM: "from",
  TO: "to",
};

export const CREATE_EXPERIENCE_RECORD_FORM = {
  ID: "experienceRecord",
  MODAL_ID: "experienceRecordModal",
  EMPLOYEES_ID: "employees",
  JOB_NUMBER: "jobId",
  CLIENT_ID: "clientOrganizationId",
  LOCATION_ID: "facilityId",
  DISCIPLINE_ID: "disciplineId",
  FROM: "from",
  TO: "to",
};

export const CREATE_ROLE_SKILL_FORM = {
  ID: "roleSkill",
  ROLE_ID: "roleId",
  SKILL_ID: "skillId",
};

export const EMPLOYEE_SKILL_FORM = {
  ID: "employeeSkill",
  EMPLOYEE_SKILL_ID: "employeeSkillId",
  EMPLOYEE_ID: "employeeId",
  SKILL_ID: "skillId",
  SKILL_NAME: "skillName",
  SKILL_GROUP: "skillGroupId",
  FILE_EVIDENCES: "fileEvidences",
  WHEN: "when",
  EMPLOYEE: "employeeId",
  ACTIVE_TAB: "activeTab",
};

export const SPECIFICATION_FORM = {
  ID: "specification",
  SPECIFICATION_ID: "specificationId",
  NAME: "name",
  DESCRIPTION: "description",
  PARENT_ID: "parentId",
  OWNER_ID: "ownerId",
  COUNTRIES: "countries",
};

export const CREATE_SPECIFICATION_COURSE_FORM = {
  ID: "specificationCourse",
  SPECIFICATION_ID: "specificationId",
  COURSE_ID: "courseId",
};

export const CREATE_SPECIFICATION_SKILL_FORM = {
  ID: "specificationSkill",
  SPECIFICATION_ID: "specificationId",
  SKILL_ID: "skillId",
};

export const SERVICE_FORM = {
  ID: "service",
  SERVICE_ID: "serviceId",
  NAME: "name",
  DESCRIPTION: "description",
  PARENT_ID: "parentId",
  OWNER_ID: "ownerId",
  COUNTRIES: "countries",
};

export const CREATE_SERVICE_COURSE_FORM = {
  ID: "serviceCourse",
  SERVICE_ID: "serviceId",
  COURSE_ID: "courseId",
};

export const CREATE_SERVICE_SKILL_FORM = {
  ID: "serviceSkill",
  SERVICE_ID: "serviceId",
  SKILL_ID: "skillId",
};

export const COLLECTION_FORM = {
  ID: "collection",
  NAME: "name",
  DESCRIPTION: "description",
  COUNTRIES: "countries",
};

export const COLLECTION_COURSE_FORM = {
  ID: "collectionCourse",
  COURSE: "course",
};

export const COLLECTION_SKILL_FORM = {
  ID: "collectionSkill",
  SKILL: "skill",
};
