import { compose } from "redux";
import PropTypes from "prop-types";
import React, { PureComponent, createRef } from "react";

class AutoSizeSvg extends PureComponent {
  constructor(props) {
    super(props);

    this.root = createRef();

    this.state = {
      width: props.width, // initial width
      height: props.height, // initial height
    };
  }

  autoSize = () => {
    if (this.root.current) {
      const { width, height } = this.root.current.getBBox();

      this.setState({
        width: width + 1,
        height: height + 1,
      });
    }
  };

  componentDidMount() {
    this.autoSize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
      this.autoSize();
    }
  }

  render() {
    const { children } = this.props;

    return (
      <svg ref={this.root} width={this.state.width} height={this.state.height}>
        {children}
      </svg>
    );
  }
}

AutoSizeSvg.propTypes = {
  width: PropTypes.number,
  children: PropTypes.node,
  height: PropTypes.number,
};

export default compose()(AutoSizeSvg);
