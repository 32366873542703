import { compose } from "redux";
import { connect } from "react-redux";
import { Field } from "redux-form/immutable";
import React, { PureComponent } from "react";
import { Grid, MenuItem } from "@material-ui/core";

import { EMPTY_LIST } from "app/app.constants";
import TextField from "app/components/form/TextField";
import { getCompetencyCategoriesFromState } from "features/competence.selectors";

class CategorySelectField extends PureComponent {
  render() {
    const { names, competencyCategories } = this.props;

    const [parentCompetencyCategoryName, competencyCategoryName] = names;

    const parentCompetencyCategory = this.props[parentCompetencyCategoryName];
    const competencyCategory = this.props[competencyCategoryName];

    const selectedParentId = parentCompetencyCategory.input.value;

    return (
      <>
        <Grid item xs={6}>
          <Field
            select
            required
            label="Category"
            component={TextField}
            name={parentCompetencyCategoryName}
            onChange={() => competencyCategory.input.onChange(null)} // reset sub-category
          >
            <MenuItem value="" />
            {competencyCategories
              .filter(category => !category.get("parentId"))
              .map(category => {
                const competencyCategoryId = category.get(
                  "competencyCategoryId",
                );

                return (
                  <MenuItem
                    key={competencyCategoryId}
                    value={competencyCategoryId}
                  >
                    {category.get("name")}
                  </MenuItem>
                );
              })}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field
            select
            required
            label="Sub-category"
            component={TextField}
            disabled={!selectedParentId}
            name={competencyCategoryName}
          >
            <MenuItem value="" />
            {competencyCategories
              .filter(category => category.get("parentId") === selectedParentId)
              .map(category => {
                const competencyCategoryId = category.get(
                  "competencyCategoryId",
                );

                return (
                  <MenuItem
                    key={competencyCategoryId}
                    value={competencyCategoryId}
                  >
                    {category.get("name")}
                  </MenuItem>
                );
              })}
          </Field>
        </Grid>
      </>
    );
  }
}

CategorySelectField.defaultProps = {
  competencyCategories: EMPTY_LIST,
};

export default compose(
  connect(state => ({
    competencyCategories: getCompetencyCategoriesFromState(state),
  })),
)(CategorySelectField);
