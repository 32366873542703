import { compose } from "redux";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { reduxForm } from "redux-form/immutable";

import {
  updateExperienceRecord,
  initializeExperienceRecordForm,
} from "features/competence.actions";

import { EMPTY_MAP } from "app/app.constants";
import { EXPERIENCE_RECORD_DETAILS_FORM } from "features/competence.constants";

import ExperienceRecordDetailsForm from "features/experience/ExperienceRecordDetailsForm";
import ExperienceRecordCategory from "features/experience/ExperienceRecordCategory";

const ExperienceRecordDetails = ({
  readOnly,
  employeeId,
  experienceRecordId,
  onUpdateExperienceRecordItem,
  experienceRecord = EMPTY_MAP,
  experienceRecordItems = EMPTY_MAP,
  experienceRecordCategories = EMPTY_MAP,
  experienceRecordAvailablePositions = EMPTY_MAP,
  dispatchInitializeExperienceRecordForm,
}) => {
  useEffect(() => {
    if (experienceRecord.size) {
      dispatchInitializeExperienceRecordForm(experienceRecord);
    }
  }, [experienceRecord, dispatchInitializeExperienceRecordForm]);

  return (
    <Grid container item xs alignContent="flex-start" spacing={2}>
      <Grid container item>
        <ExperienceRecordDetailsForm
          minToDate={experienceRecord.get("from")}
          readOnly={readOnly}
          experienceRecordAvailablePositions={
            experienceRecordAvailablePositions
          }
        />
      </Grid>
      <Grid container item spacing={1} justifyContent="center" direction="row">
        {experienceRecordCategories.map((category, index) => (
          <ExperienceRecordCategory
            key={index}
            readOnly={readOnly}
            category={category}
            employeeId={employeeId}
            recordItems={experienceRecordItems}
            experienceRecordId={experienceRecordId}
            onUpdateExperienceRecordItem={onUpdateExperienceRecordItem}
          />
        ))}
      </Grid>
    </Grid>
  );
};

export default compose(
  connect(null, {
    dispatchInitializeExperienceRecordForm: initializeExperienceRecordForm,
  }),
  reduxForm({
    form: EXPERIENCE_RECORD_DETAILS_FORM.ID,
    onChange: debounce((values, dispatch, props) => {
      const { dirty, experienceRecordId, employeeId } = props;

      if (dirty) {
        dispatch(
          updateExperienceRecord(employeeId, experienceRecordId, values.toJS()),
        );
      }
    }, 1000),
  }),
  React.memo,
)(ExperienceRecordDetails);
