import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { NavTabsContainer, RouteContainer } from "altus-ui-components";

import { collectionOnLoad } from "features/competence.actions";
import CollectionDetailsHeader from "features/hr/collections/components/CollectionDetailsHeader";

class CollectionContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad, collectionId } = this.props;

    dispatchOnLoad(collectionId);
  }

  render() {
    const { routes, collectionId } = this.props;

    return (
      <>
        <CollectionDetailsHeader collectionId={collectionId} />
        <NavTabsContainer routes={routes} />
        <RouteContainer routes={routes} />
      </>
    );
  }
}

export default compose(
  connect(null, {
    dispatchOnLoad: collectionOnLoad,
  }),
)(CollectionContainer);
