import React, { useCallback, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Helmet } from "altus-ui-components";

import {
  getServiceGapReport,
  clearServiceGapReport,
  serviceGapReportOnLoad,
  serviceGapReportOnUnload,
  downloadAndSaveServiceGapReport,
} from "features/competence.actions";

import { getGapReportEmployeesByDepartmentFromState } from "features/competence.selectors";

import { ACTIONS, APP_ROOT } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import GapReportBasePage from "features/components/GapReport/GapReportBasePage";
import ServiceGapReport from "features/service/components/ServiceGapReport";
import ServiceGapReportFilter from "features/service/components/ServiceGapReportFilter"; // FILTERS,

const ServiceGapReportContainer = ({
  root = APP_ROOT.HR,
  dataState,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchGetGapReport,
  employeesByDepartment,
  dispatchClearGapReport,
  dispatchDownloadGapReport,
}) => {
  useEffect(() => {
    dispatchOnLoad();
    return () => dispatchOnUnload();
  }, [dispatchOnLoad, dispatchOnUnload]);

  const renderFilter = useCallback(
    () => (
      <ServiceGapReportFilter
        getGapReport={dispatchGetGapReport}
        clearGapReport={dispatchClearGapReport}
        downloadGapReport={dispatchDownloadGapReport}
      />
    ),
    [dispatchGetGapReport, dispatchClearGapReport, dispatchDownloadGapReport],
  );

  return (
    <>
      <Helmet titleTemplate="%s">
        <title>{breadcrumb}</title>
      </Helmet>
      <GapReportBasePage
        title={breadcrumb}
        dataState={dataState}
        renderFilter={renderFilter}
        employeesByDepartment={employeesByDepartment}
      >
        {!!employeesByDepartment.size && (
          <ServiceGapReport
            root={root}
            title={breadcrumb}
            employeesByDepartment={employeesByDepartment}
          />
        )}
      </GapReportBasePage>
    </>
  );
};

export default compose(
  connect(
    state => ({
      employeesByDepartment: getGapReportEmployeesByDepartmentFromState(state),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_SERVICE_GAP_REPORT,
        ACTIONS.DOWNLOAD_SERVICE_GAP_REPORT,
      ),
    }),
    {
      dispatchOnLoad: serviceGapReportOnLoad,
      dispatchGetGapReport: getServiceGapReport,
      dispatchOnUnload: serviceGapReportOnUnload,
      dispatchClearGapReport: clearServiceGapReport,
      dispatchDownloadGapReport: downloadAndSaveServiceGapReport,
    },
  ),
  React.memo,
)(ServiceGapReportContainer);
