import { OrderedMap, Map } from "immutable";

import { ACTIONS } from "app/app.constants";
import { CollectionMapper } from "app/app.mappers";

const initialState = OrderedMap();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_COLLECTIONS: {
      return initialState;
    }

    case ACTIONS.GET_COLLECTIONS: {
      if (error) return state;

      return Map(
        payload.map(collection => [
          collection.competencyCollectionId.toString(),
          CollectionMapper.from(collection),
        ]),
      );
    }

    case ACTIONS.CREATE_COLLECTION:
    case ACTIONS.UPDATE_COLLECTION:
    case ACTIONS.GET_COLLECTION: {
      if (error) return state;

      return state.set(
        payload.competencyCollectionId.toString(),
        CollectionMapper.from(payload),
      );
    }

    case ACTIONS.DELETE_COLLECTION: {
      if (error) return state;

      return state.delete(action.competencyCollectionId.toString());
    }

    default:
      return state;
  }
};
