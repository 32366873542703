import { compose } from "redux";
import { connect } from "react-redux";
import { Avatar } from "@material-ui/core";
import React, { PureComponent } from "react";
import AccountCircle from "@material-ui/icons/AccountCircle";

import { getAvatar, getInitials } from "utils/app.util";
import { getCurrentUserFromState } from "app/app.selectors";

class CurrentUserAvatar extends PureComponent {
  render() {
    const { currentUser, dispatch, ...rest } = this.props;

    const avatar = currentUser.get("avatar");
    const initials = getInitials(currentUser.get("fullName"));

    return (
      <Avatar src={getAvatar(currentUser.get("avatar"))} {...rest}>
        {avatar || initials || <AccountCircle />}
      </Avatar>
    );
  }
}

export default compose(
  connect(state => ({
    currentUser: getCurrentUserFromState(state),
  })),
)(CurrentUserAvatar);
