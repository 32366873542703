import React from "react";
import { compose } from "redux";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, SvgIcon, Typography } from "@material-ui/core";

import routePaths from "app/routePaths";
import { COMPETENCY_STATUS } from "app/app.constants";
import { statusToIcon } from "app/components/CompetencyStatusIcon";
import { getEmployeeRoleCompetencyItemStatusForGapReport } from "utils/app.util";

const EmployeeRoleGapReportRow = ({
  roleId,
  classes,
  skills,
  courses,
  roleName,
  employeeId,
  roleSkills,
  roleCourses,
  employeeRole,
  employeeSkills,
  employeeCourses,
  isSkillsFeatureFlagEnabled,
}) => {
  const employeeRoleCompetencyStatus = employeeRole.get(
    isSkillsFeatureFlagEnabled ? "competencyStatus" : "courseCompetencyStatus",
    COMPETENCY_STATUS.NONE,
  );

  const EmployeeRoleStatusIcon = statusToIcon(employeeRoleCompetencyStatus);

  return (
    <Grid container key={employeeRole.get("roleId")}>
      <Grid
        item
        xs={2}
        container
        wrap="nowrap"
        target="_blank"
        component={Link}
        alignItems="center"
        className={classes.employeeRoleCell}
        to={`${routePaths.hrPortal}/roles/${roleId}`}
      >
        <Grid xs item noWrap variant="caption" component={Typography}>
          {roleName}
        </Grid>
        <SvgIcon
          color="secondary"
          viewBox="-12 -12 48 48"
          className={classes.employeeRoleStatusCell}
        >
          <EmployeeRoleStatusIcon />
        </SvgIcon>
      </Grid>
      <Grid item>
        <Grid
          container
          wrap="nowrap"
          className={classes.employeeCompetencyItemsContainer}
        >
          {courses.toList().map(course => {
            const courseId = course.get("courseId").toString();
            const isCourseAssigned = !!roleCourses.getIn([roleId, courseId]);

            const employeeCourseCompetencyStatus = isCourseAssigned
              ? getEmployeeRoleCompetencyItemStatusForGapReport(
                  employeeCourses.getIn(
                    [courseId, "competencyStatus"],
                    COMPETENCY_STATUS.NONE,
                  ),
                  isCourseAssigned,
                )
              : COMPETENCY_STATUS.NONE;

            const IconCourseStatus = statusToIcon(
              employeeCourseCompetencyStatus,
            );

            return (
              <Grid
                xs
                item
                key={courseId}
                target="_blank"
                component={Link}
                className={classes.competencyItem}
                to={`${routePaths.hrPortal}/employees/${employeeId}/courses/${courseId}`}
              >
                {IconCourseStatus && (
                  <SvgIcon
                    color="secondary"
                    viewBox="-12 -12 48 48"
                    className={classes.competencyItemSvg}
                  >
                    <IconCourseStatus />
                  </SvgIcon>
                )}
              </Grid>
            );
          })}
          {skills.toList().map(skill => {
            const skillId = skill.get("skillId").toString();
            const isSkillAssigned = !!roleSkills.getIn([roleId, skillId]);

            const employeeSkillCompetencyStatus = isSkillAssigned
              ? getEmployeeRoleCompetencyItemStatusForGapReport(
                  employeeSkills.getIn(
                    [skillId, "competencyStatus"],
                    COMPETENCY_STATUS.NONE,
                  ),
                  isSkillAssigned,
                )
              : COMPETENCY_STATUS.NONE;

            const IconSkillStatus = statusToIcon(employeeSkillCompetencyStatus);

            return (
              <Grid
                xs
                item
                key={skillId}
                target="_blank"
                component={Link}
                className={classes.competencyItem}
                to={`${routePaths.hrPortal}/employees/${employeeId}/skills/${skillId}`}
              >
                {IconSkillStatus && (
                  <SvgIcon
                    color="secondary"
                    viewBox="-12 -12 48 48"
                    className={classes.competencyItemSvg}
                  >
                    <IconSkillStatus />
                  </SvgIcon>
                )}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  employeeRoleCell: {
    height: theme.gapReport.cellSize,
    paddingLeft: theme.spacing(2),
    borderLeft: theme.gapReport.defaultBorder,
    borderBottom: theme.gapReport.defaultBorder,
    background: theme.palette.grey[200],
  },
  employeeRoleStatusCell: {
    maxWidth: theme.gapReport.cellSize,
    height: theme.gapReport.cellSize,
    width: theme.gapReport.cellSize,
    alignItems: "center",
    justifyContent: "center",
  },
  employeeCompetencyItemsContainer: {
    borderRight: theme.gapReport.defaultBorder,
  },
  competencyItem: {
    height: theme.gapReport.cellSize,
    width: theme.gapReport.cellSize,
    borderLeft: theme.gapReport.defaultBorder,
    borderBottom: theme.gapReport.defaultBorder,
  },
  competencyItemSvg: {
    height: theme.gapReport.cellSize,
    width: theme.gapReport.cellSize,
  },
});

export default compose(
  React.memo,
  withStyles(styles),
)(EmployeeRoleGapReportRow);
