import React from "react";
import { compose } from "redux";
import { Field } from "redux-form/immutable";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, MenuItem } from "@material-ui/core";

import { EMPTY_MAP } from "app/app.constants";
import { required } from "utils/validation.util";
import TextField from "app/components/form/TextField";
import DatePickerField from "app/components/form/DatePickerField";
import { EXPERIENCE_RECORD_DETAILS_FORM } from "features/competence.constants";

const ExperienceRecordDetailsForm = ({
  classes,
  readOnly,
  handleSubmit,
  minToDate,
  experienceRecordAvailablePositions = EMPTY_MAP,
}) => (
  <form id={EXPERIENCE_RECORD_DETAILS_FORM.ID} onSubmit={handleSubmit}>
    <Grid container spacing={6}>
      <Grid container item xs={4}>
        <Typography
          noWrap
          align="left"
          title="Job"
          display="block"
          variant="caption"
          className={classes.recordCategoryTitle}
        >
          Job
        </Typography>
        <Field
          autoFocus
          label="Job Number"
          component={TextField}
          name={EXPERIENCE_RECORD_DETAILS_FORM.JOB_NUMBER}
          disabled
        />
        <Field
          autoFocus
          label="Client"
          component={TextField}
          name={EXPERIENCE_RECORD_DETAILS_FORM.CLIENT}
          disabled
        />
        <Field
          autoFocus
          label="Location"
          component={TextField}
          name={EXPERIENCE_RECORD_DETAILS_FORM.LOCATION}
          disabled
        />
      </Grid>
      <Grid container item xs={4}>
        <Typography
          noWrap
          align="left"
          title="Employee"
          display="block"
          variant="caption"
          className={classes.recordCategoryTitle}
        >
          Employee
        </Typography>
        <Field
          autoFocus
          label="Name"
          component={TextField}
          name={EXPERIENCE_RECORD_DETAILS_FORM.EMPLOYEE_NAME}
          disabled
        />
        <Field
          autoFocus
          label="Department"
          component={TextField}
          name={EXPERIENCE_RECORD_DETAILS_FORM.EMPLOYEE_DEPARTMENT}
          disabled
        />
        <Field
          autoFocus
          label="Position"
          component={TextField}
          name={EXPERIENCE_RECORD_DETAILS_FORM.EMPLOYEE_POSITION}
          disabled
        />
      </Grid>
      <Grid container item xs={4}>
        <Typography
          noWrap
          align="left"
          title="Performed"
          display="block"
          variant="caption"
          className={classes.recordCategoryTitle}
        >
          Performed
        </Typography>
        <Field
          autoFocus
          label="Discipline"
          component={TextField}
          name={EXPERIENCE_RECORD_DETAILS_FORM.DISCIPLINE}
          disabled
        />
        <Field
          select
          label="Performed as"
          component={TextField}
          name={EXPERIENCE_RECORD_DETAILS_FORM.PERFORMED_AS}
          required
          validate={[required]}
          disabled={readOnly}
        >
          {experienceRecordAvailablePositions.map(position => (
            <MenuItem
              key={position.get("experiencePositionId")}
              value={position.get("experiencePositionId")}
            >
              {position.get("name")}
            </MenuItem>
          ))}
        </Field>
        <Grid container item spacing={2}>
          <Grid item xs={6}>
            <Field
              inputVariant="standard"
              component={DatePickerField}
              name={EXPERIENCE_RECORD_DETAILS_FORM.FROM}
              DatePickerProps={{
                required: true,
                margin: "normal",
                label: "From",
                disabled: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              validate={[required]}
              inputVariant="standard"
              component={DatePickerField}
              name={EXPERIENCE_RECORD_DETAILS_FORM.TO}
              DatePickerProps={{
                required: true,
                margin: "normal",
                label: "To",
                disabled: readOnly,
                minDate: minToDate,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </form>
);

const styles = {
  subTitle: {
    fontStyle: "italic",
    fontSize: "1rem",
  },
  recordCategoryTitle: {
    fontWeight: "bold",
    fontSize: "1.25rem",
  },
};

export default compose()(withStyles(styles)(ExperienceRecordDetailsForm));
