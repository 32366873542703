import { compose } from "redux";
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";
import React, { PureComponent } from "react";

import {
  clearSpecifications,
  getStatsForSpecifications,
  initializeEditSpecification,
  createSpecificationOnSubmit,
  initializeCreateSpecification,
} from "features/competence.actions";

import ListTreeViewToggleButtons, {
  LIST_VIEW,
  TREE_VIEW,
} from "features/components/ListTreeViewToggleButtons";

import {
  SPECIFICATION_FORM,
  CREATE_SPECIFICATION_MODAL_ID,
} from "features/competence.constants";

import {
  getSpecificationsFromState,
  getSpecificationStatsBySpecificationIdFromState,
} from "features/competence.selectors";

import { ICONS, EMPTY_MAP } from "app/app.constants";
import TreeList from "app/components/TreeList/TreeList";
import { BasePage } from "altus-ui-components";
import SimpleFormModal from "app/components/SimpleFormModal";
import SortableList from "app/components/SortableList/SortableList";
import SpecificationForm from "features/hr/specifications/components/SpecificationForm";

class SpecificationsBasePage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedView: LIST_VIEW,
    };
  }

  componentDidMount() {
    const { dispatchGetStatsForSpecifications } = this.props;
    dispatchGetStatsForSpecifications();
  }

  componentWillUnmount() {
    const { dispatchClearSpecifications } = this.props;
    dispatchClearSpecifications();
  }

  columns = [
    {
      xs: 4,
      title: "Specification Name",
      getSortProperty: item => item.get("name"),
    },
    {
      xs: 1,
      visible: false,
      title: (
        <Tooltip title="Sort by Tree Structure">
          <ICONS.TREE_VIEW />
        </Tooltip>
      ),
      getSortProperty: item => item.get("treeMin"),
    },
    {
      xs: true,
      filter: {
        defaultText: "All owners",
      },
      title: "Owner",
      getSortProperty: item => item.get("ownerDisplayName"),
    },
    {
      filter: {
        defaultText: "Any",
        label: "Countries",
        getFilterText: filter => (filter === "" ? "Global" : filter),
      },
      xs: 1,
      title: "Countries",
      getSortProperty: item =>
        item
          .get("countries")
          .map(country => country.get("twoLetterCode"))
          .join(", "),
    },
    {
      xs: 1,
      container: true,
      justify: "center",
      title: (
        <Tooltip title="Skill Count">
          <ICONS.SKILL />
        </Tooltip>
      ),
      getSortProperty: item =>
        this.props.specificationStatsBySpecificationId.getIn(
          [item.get("specificationId").toString(), "specificationSkillCount"],
          "-",
        ),
    },
    {
      xs: 1,
      container: true,
      justify: "center",
      title: (
        <Tooltip title="Course Count">
          <ICONS.COURSE />
        </Tooltip>
      ),
      getSortProperty: item =>
        this.props.specificationStatsBySpecificationId.getIn(
          [item.get("specificationId").toString(), "specificationCourseCount"],
          "-",
        ),
    },
  ];

  listActions = [
    {
      renderAction: () => (
        <ListTreeViewToggleButtons
          value={this.state.selectedView}
          onChange={this.onSelectedViewChange}
        />
      ),
      getTitle: () => "",
    },
  ];

  onSelectedViewChange = (event, selectedView) =>
    selectedView && this.setState({ selectedView });

  render() {
    const {
      displayAddButton,
      specifications,
      title,
      actions,
      dataState,
      createSortableListRowProps,
      dispatchCreateSpecificationOnSubmit,
      dispatchInitializeCreateSpecification,
    } = this.props;

    const { selectedView } = this.state;

    return (
      <BasePage
        displayAddButton={displayAddButton}
        title={title}
        Icon={ICONS.SPECIFICATION}
        dataState={dataState}
        addButtonOnClick={dispatchInitializeCreateSpecification}
      >
        {selectedView === LIST_VIEW && (
          <SortableList
            Icon={ICONS.SPECIFICATION}
            items={specifications}
            actions={actions}
            listActions={this.listActions}
            dataState={dataState}
            columns={this.columns}
            defaultSortColumn={1}
            displayNumberOfItems={true}
            getKey={specification => specification.get("specificationId")}
            createSortableListRowProps={createSortableListRowProps}
          />
        )}
        {selectedView === TREE_VIEW && (
          <TreeList
            Icon={ICONS.SPECIFICATION}
            items={specifications}
            columns={this.columns}
            listActions={this.listActions}
            getKey={specification => specification.get("specificationId")}
            createSortableListRowProps={createSortableListRowProps}
          />
        )}
        <SimpleFormModal
          Icon={ICONS.SPECIFICATION}
          form={{ form: SPECIFICATION_FORM.ID }}
          modalId={CREATE_SPECIFICATION_MODAL_ID}
          component={SpecificationForm}
          submitText="Create"
          title="Create Specification"
          onSubmit={dispatchCreateSpecificationOnSubmit}
          formValueSelector={this.createFormValueSelector}
        />
      </BasePage>
    );
  }
}

SpecificationsBasePage.defaultProps = {
  specificationStatsBySpecificationId: EMPTY_MAP,
};

export default compose(
  connect(
    state => ({
      specifications: getSpecificationsFromState(state),
      specificationStatsBySpecificationId: getSpecificationStatsBySpecificationIdFromState(
        state,
      ),
    }),
    {
      dispatchClearSpecifications: clearSpecifications,
      dispatchInitializeEditSpecification: initializeEditSpecification,
      dispatchCreateSpecificationOnSubmit: createSpecificationOnSubmit,
      dispatchInitializeCreateSpecification: initializeCreateSpecification,
      dispatchGetStatsForSpecifications: getStatsForSpecifications,
    },
  ),
)(SpecificationsBasePage);
