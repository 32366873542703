import { compose } from "redux";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";
import { reduxForm } from "redux-form/immutable";

import {
  updateRole,
  initializeEditRole,
  roleDetailsOnLoad,
} from "features/competence.actions";

import { ACTIONS } from "app/app.constants";
import { BasePage } from "altus-ui-components";
import { ROLE_FORM } from "features/competence.constants";
import RoleForm from "features/hr/roles/components/RoleForm";
import { getActionDataStateFromState } from "app/app.selectors";
import { getRoleFromState } from "features/competence.selectors";

class RoleDetailsContainer extends PureComponent {
  componentDidMount() {
    const { dispatchRoleDetailsOnLoad, roleId } = this.props;

    dispatchRoleDetailsOnLoad(roleId);
  }

  render() {
    const { breadcrumb, role, dataState, ...rest } = this.props;

    return (
      <BasePage title={breadcrumb} dataState={dataState}>
        <Grid container item md={8} xs={12}>
          <RoleForm currentRole={role} {...rest} />
        </Grid>
      </BasePage>
    );
  }
}

export default compose(
  connect(
    (state, { roleId }) => ({
      role: getRoleFromState(state, roleId),
      dataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_ROLE_WITH_PARENTS,
      ),
    }),
    {
      dispatchRoleDetailsOnLoad: roleDetailsOnLoad,
    },
  ),
  reduxForm({
    form: ROLE_FORM.ID,
    onChange: debounce((values, dispatch, props) => {
      const { dirty, roleId } = props;

      if (dirty) {
        dispatch(updateRole(roleId, values.toJS())).then(role =>
          dispatch(initializeEditRole(role)),
        );
      }
    }, 1000),
  }),
)(RoleDetailsContainer);
