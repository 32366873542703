import { compose } from "redux";
import { Set } from "immutable";
import Dropzone from "react-dropzone";
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, InputLabel, FormControl, RootRef } from "@material-ui/core";

import { invokeIfFunction } from "utils/app.util";

class FileUploadField extends PureComponent {
  constructor(props) {
    super(props);

    this.dropzoneRef = React.createRef();
  }

  openFileDialog = () => {
    if (this.dropzoneRef.current) this.dropzoneRef.current.open();
  };

  onDrop = acceptedFiles => {
    const { input, multiple } = this.props;
    const { value, onChange } = input;

    onChange(multiple ? value.concat(acceptedFiles) : Set(acceptedFiles));
  };

  removeFile = file => {
    const { input } = this.props;
    const { value, onChange } = input;

    onChange(value.delete(file));
  };

  render() {
    const {
      label,
      required,
      children,
      input,
      classes,
      multiple,
      FormControlProps,
      DropzoneProps,
    } = this.props;
    const { value } = input;

    return (
      <FormControl
        fullWidth
        classes={{
          root: classes.formControl,
        }}
        {...FormControlProps}
      >
        <InputLabel required={required}>{label}</InputLabel>
        <Grid container spacing={1}>
          <Grid item container>
            <Dropzone
              noClick
              multiple={multiple}
              onDrop={this.onDrop}
              ref={this.dropzoneRef}
              {...DropzoneProps}
            >
              {({ getRootProps, getInputProps, isDragActive, ...rest }) => {
                const { ref, ...rootProps } = getRootProps();

                return (
                  <>
                    <input type="hidden" {...getInputProps()} />
                    <RootRef rootRef={ref}>
                      {invokeIfFunction(children, {
                        value,
                        rootProps,
                        isDragActive,
                        removeFile: this.removeFile,
                        openFileDialog: this.openFileDialog,
                        ...rest,
                      })}
                    </RootRef>
                  </>
                );
              }}
            </Dropzone>
          </Grid>
        </Grid>
      </FormControl>
    );
  }
}

const styles = theme => ({
  formControl: {
    paddingTop: theme.spacing(3),
  },
});

export default compose(withStyles(styles))(FileUploadField);
