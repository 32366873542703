import React from "react";
import { TableHeaderRow } from "altus-ui-components";
import { Grid } from "@material-ui/core";

const ExpandableTableHeaderRow = ({ headedrOffset = 24, ...rest }) => {
  return (
    <Grid container direction="row">
      <Grid item style={{ width: headedrOffset }}></Grid>
      <Grid item xs>
        <TableHeaderRow {...rest} />
      </Grid>
    </Grid>
  );
};
export default ExpandableTableHeaderRow;
