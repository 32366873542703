import { OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { SkillMapper } from "app/app.mappers";

const initialState = OrderedMap();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_COURSES:
      return initialState;

    case ACTIONS.GET_AVAILABLE_SKILLS_FOR_ROLE:
    case ACTIONS.GET_SKILLS: {
      if (error) return state;

      return state.merge(
        payload.map(skill => [
          skill.skillId.toString(),
          SkillMapper.from(skill),
        ]),
      );
    }

    case ACTIONS.GET_AVAILABLE_SKILLS_FOR_COLLECTION: {
      if (error) return state;

      return OrderedMap(
        payload.map(skill => [
          skill.skillId.toString(),
          SkillMapper.from(skill),
        ]),
      );
    }

    case ACTIONS.CREATE_SKILL:
    case ACTIONS.UPDATE_SKILL:
    case ACTIONS.GET_SKILL: {
      if (error) return state;

      return state.set(payload.skillId.toString(), SkillMapper.from(payload));
    }

    case ACTIONS.DELETE_SKILL: {
      if (error) return state;

      return state.delete(action.skillId.toString());
    }

    case ACTIONS.DELETE_SKILLS: {
      if (error) return state;
      return state.deleteAll(
        action.skillIds.map(skillId => skillId.toString()),
      );
    }

    default:
      return state;
  }
};
