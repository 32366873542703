import { compose } from "redux";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";
import { List, isCollection } from "immutable";

import { Autocomplete } from "altus-ui-components";

import { EMPTY_LIST } from "app/app.constants";
import { getCountries } from "features/competence.actions";
import { getCountriesFromState } from "features/competence.selectors";

class CountryMultiselectField extends PureComponent {
  componentDidMount() {
    const { dispatchGetCountries } = this.props;

    dispatchGetCountries();
  }

  getOptionLabel = country => country.get("name");

  onChange = (_, countries) => {
    const { input } = this.props;
    const { onChange } = input;

    onChange(List(countries));
  };

  render() {
    const { countriesByCountryId, input } = this.props;
    const { value } = input;

    const selectedCountries = isCollection(value)
      ? value
          .map(country =>
            countriesByCountryId.get(country.get("countryId").toString()),
          )
          .filter(Boolean)
          .toArray()
      : EMPTY_LIST.toArray();

    return (
      <Grid
        multiple
        container
        component={Autocomplete}
        onChange={this.onChange}
        value={selectedCountries}
        options={countriesByCountryId}
        getOptionLabel={this.getOptionLabel}
        TextFieldProps={{
          margin: "normal",
          label: "Countries",
          placeholder: value.size ? undefined : "Global",
        }}
      />
    );
  }
}

export default compose(
  connect(
    state => ({
      countriesByCountryId: getCountriesFromState(state),
    }),
    {
      dispatchGetCountries: getCountries,
    },
  ),
)(CountryMultiselectField);
