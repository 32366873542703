import { compose } from "redux";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { Grid, Divider } from "@material-ui/core";

import CrudBasePage from "app/components/CrudBasePage";
import { approvalOnLoad } from "features/competence.actions";
import { ACTIONS, APP_ROOT, ICONS } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import EducationApproval from "features/hr/approval/components/EducationApproval";
import WorkHistoryApproval from "features/hr/approval/components/WorkHistoryApproval";
import EmployeeCourseApproval from "features/hr/approval/components/EmployeeCourseApproval";

const ApprovalContainer = ({
  courses,
  dataState,
  breadcrumb,
  dispatchOnLoad,
}) => {
  const [courseCount, setCourseCount] = useState(0);
  const [educationCount, setEducationCount] = useState(0);
  const [workHistoryCount, setWorkHistoryCount] = useState(0);
  useEffect(() => {
    dispatchOnLoad();
  }, [dispatchOnLoad]);

  return (
    <CrudBasePage
      items={courses}
      title={breadcrumb}
      dataState={dataState}
      endHeaderText={`Total: ${courseCount +
        educationCount +
        workHistoryCount}`}
      Icon={ICONS.APPROVAL}
    >
      <Grid container spacing={4}>
        <Grid container item>
          <EmployeeCourseApproval
            root={APP_ROOT.HR}
            onCourseCountChange={count => setCourseCount(count)}
          />
        </Grid>
        <Grid container component={Divider} />
        <Grid container item>
          <EducationApproval
            root={APP_ROOT.HR}
            onEducationCountChange={count => setEducationCount(count)}
          />
        </Grid>
        <Grid container component={Divider} />
        <Grid container item>
          <WorkHistoryApproval
            root={APP_ROOT.HR}
            onWorkHistoryCountChange={count => setWorkHistoryCount(count)}
          />
        </Grid>
      </Grid>
    </CrudBasePage>
  );
};

export default compose(
  connect(
    state => ({
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_EMPLOYEE_COURSES_ATTENDANCES_APPROVAL,
        ACTIONS.GET_EMPLOYEE_WORK_HISTORY_APPROVAL,
        ACTIONS.GET_EMPLOYEE_EDUCATION_APPROVAL,
      ),
    }),
    {
      dispatchOnLoad: approvalOnLoad,
    },
  ),
)(ApprovalContainer);
