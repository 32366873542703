import { compose } from "redux";
import { connect } from "react-redux";
import React, { useEffect } from "react";

import { RouteContainer, NavTabsContainer } from "altus-ui-components";

import { serviceOnLoad, serviceOnUnload } from "features/competence.actions";

import { ROUTE_KEYS } from "app/routes";
import { EMPTY_MAP } from "app/app.constants";
import { setRouteCount } from "utils/route.util";
import { getServiceStatsFromState } from "features/competence.selectors";
import ServiceDetailsHeader from "features/service/components/ServiceDetailsHeader";

const getServiceRouteCount = (key, serviceStats) => {
  switch (key) {
    case ROUTE_KEYS.SERVICE_SKILL:
      return serviceStats.get("serviceSkillCount");
    case ROUTE_KEYS.SERVICE_COURSE:
      return serviceStats.get("serviceCourseCount");
    default:
      return null;
  }
};

const ServiceContainer = ({
  routes,
  serviceId,
  dispatchOnLoad,
  dispatchOnUnload,
  serviceStats = EMPTY_MAP,
}) => {
  useEffect(() => {
    dispatchOnLoad(serviceId);

    return () => dispatchOnUnload();
  }, [serviceId, dispatchOnUnload, dispatchOnLoad]);

  return (
    <>
      <ServiceDetailsHeader serviceId={serviceId} />
      <NavTabsContainer
        routes={routes.map(route =>
          setRouteCount(route, getServiceRouteCount(route.key, serviceStats)),
        )}
      />
      <RouteContainer routes={routes} />
    </>
  );
};

export default compose(
  connect(
    (state, { serviceId }) => ({
      serviceStats: getServiceStatsFromState(state, serviceId),
    }),
    {
      dispatchOnLoad: serviceOnLoad,
      dispatchOnUnload: serviceOnUnload,
    },
  ),
)(ServiceContainer);
