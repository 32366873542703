import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { RouteContainer, NavTabsContainer } from "altus-ui-components";

import { EMPTY_MAP } from "app/app.constants";
import { setRouteCount, getEmployeeRouteCount } from "utils/route.util";
import { getEmployeeStatsFromState } from "features/competence.selectors";
import { getEmployee, getStatsForEmployee } from "features/competence.actions";
import EmployeeDetailsHeader from "features/employee/components/EmployeeDetailsHeader";

class EmployeeContainer extends PureComponent {
  componentDidMount() {
    const {
      employeeId,
      dispatchGetEmployee,
      dispatchGetStatsForEmployee,
    } = this.props;

    dispatchGetEmployee(employeeId);
    dispatchGetStatsForEmployee(employeeId);
  }

  render() {
    const { routes, employeeId, path, employeeStats } = this.props;

    return (
      <>
        <EmployeeDetailsHeader path={path} employeeId={employeeId}>
          <NavTabsContainer
            routes={routes.map(route =>
              setRouteCount(
                route,
                getEmployeeRouteCount(employeeStats, route.key),
              ),
            )}
          />
        </EmployeeDetailsHeader>
        <RouteContainer routes={routes} />
      </>
    );
  }
}

EmployeeContainer.defaultProps = {
  employeeStats: EMPTY_MAP,
};

export default compose(
  connect(
    (state, { employeeId }) => ({
      employeeStats: getEmployeeStatsFromState(state, employeeId),
    }),
    {
      dispatchGetEmployee: getEmployee,
      dispatchGetStatsForEmployee: getStatsForEmployee,
    },
  ),
)(EmployeeContainer);
