import { Set, Map, List } from "immutable";

import { ACTIONS } from "app/app.constants";
import { ExperienceCategoryMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_EXPERIENCES_RECORD_CATEGORIES:
      return initialState;

    case ACTIONS.GET_EXPERIENCE_RECORD_CATEGORIES:
    case ACTIONS.GET_ALL_EXPERIENCE_RECORD_CATEGORIES:
    case ACTIONS.GET_EMPLOYEE_ALL_EXPERIENCE_RECORD_CATEGORIES: {
      if (error) return state;

      return List(payload).map(ExperienceCategoryMapper.from);
    }

    case ACTIONS.GET_EMPLOYEE_EXPERIENCE_RECORD_CATEGORIES_FOR_REPORT: {
      if (error) return state;

      return Set(payload);
    }

    default:
      return state;
  }
};
