import { compose } from "redux";
import isNil from "lodash/isNil";
import React, { useMemo } from "react";
import { Grid } from "@material-ui/core";
import { withStyles, Tooltip } from "@material-ui/core";

import { TableRowActionsCell } from "altus-ui-components";

import { EMPTY_MAP, ICONS } from "app/app.constants";
import ProgressBar from "app/components/ProgressBar";
import withToggleModal from "app/components/withToggleModal";
import SortableTable from "app/components/Table/SortableTable";

const CourseTrainingHistoryReportTableContainer = ({ courses = EMPTY_MAP }) => {
  const columns = useMemo(
    () => [
      {
        xl: true,
        id: "icon",
        paddingLeft: "16px",
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (row.depth !== 0) return <TableRowActionsCell minItems={1} />;
          return null;
        },
      },
      {
        xs: 4,
        id: "courseName",
        title: "Course",
        Header: "Course",
        Cell: ({ row }) => {
          return row.original.get("courseName");
        },
      },
      {
        xs: 2,
        id: "memberCount",
        Header: "Members",
        title: (
          <Tooltip title="Member Count">
            <ICONS.TEAM />
          </Tooltip>
        ),
        Cell: ({ row }) => {
          return row.original.get("memberCount");
        },
      },
      {
        xs: true,
        id: "coverage",
        component: Grid,
        Header: "Coverage",
        title: "Coverage",
        Cell: ({ row }) => {
          const memberCount = row.original.get("memberCount");
          const total = isNil(memberCount) ? 0 : memberCount;

          const activeCount = row.original.get("activeCount");
          const value = isNil(activeCount)
            ? 0
            : total === 0
            ? 100
            : (activeCount / total) * 100;
          return <ProgressBar value={value} title={`${value.toFixed(0)}%`} />;
        },
      },
    ],
    [],
  );

  return (
    <>
      <SortableTable
        stickyHeader
        disableSortBy
        displayAddButton={false}
        columns={columns}
        title="Course Training History Report"
        useGlobalFilter={false}
        items={courses}
        sortOrder="courseName"
        noItemsMessage="No courses found..."
      />
    </>
  );
};

const styles = () => ({
  formControlLabelRoot: {
    marginLeft: 0,
  },
});

export default compose(
  withStyles(styles),
  withToggleModal,
)(CourseTrainingHistoryReportTableContainer);
