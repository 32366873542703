import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form/immutable";
import { withStyles } from "@material-ui/core/styles";
import { formValueSelector } from "redux-form/immutable";
import React, { PureComponent, createElement } from "react";

import { LoadingButton } from "altus-ui-components";

import {
  Grid,
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import {
  getFormValueFromState,
  getFormInitialValueFromState,
} from "app/app.selectors";

import { createGuid } from "utils/app.util";
import withModal from "app/components/withModal";
import ModalTab from "app/components/Modal/ModalTab";
import ModalTabs from "app/components/Modal/ModalTabs";
import ModalHeader from "app/components/Modal/ModalHeader";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";

const ModalItemTitle = ({ itemTitle, classes }) => {
  return (
    <Grid
      xs
      item
      container
      alignItems="center"
      className={classes.itemTitleContainer}
    >
      <Typography variant="h6" className={classes.itemTitle}>
        {itemTitle}
      </Typography>
    </Grid>
  );
};

export const ModalCompetencyStatus = ({ status }) => {
  if (!status) return null;

  return (
    <Grid
      item
      style={{
        display: "flex",
        paddingRight: 32,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CompetencyStatusChip status={status} />
    </Grid>
  );
};

const SimpleModal = ({
  open,
  Icon,
  form,
  Icons,
  title,
  onEnter,
  onSubmit,
  component,
  submitText,
  FormProps,
  submitting,
  toggleModal,
  handleSubmit,
  DialogProps,
  ModalTitle,
  formTitleField,
  ModalActions,
  DialogContentProps,
  valid,
  Tabs,
  displaySubmitBtn,
  displayCancelBtn,
  TabsProps,
  ModalActionsProps,
  competencyStatus,
  cancelText,
  ...rest
}) => {
  const submit = onSubmit ? handleSubmit(onSubmit(toggleModal)) : undefined;

  return (
    <Dialog
      fullWidth
      open={open}
      onEnter={onEnter}
      onClose={toggleModal}
      {...DialogProps}
    >
      <ModalHeader title={title} toggleModal={toggleModal} />
      {Icon && !Icons && (
        <ModalTabs>
          <ModalTab disabled>
            <Icon color="secondary" />
          </ModalTab>
          <ModalTitle formTitleField={formTitleField} />
          <ModalCompetencyStatus status={competencyStatus} />
        </ModalTabs>
      )}
      {Icons && !Icon && (
        <ModalTabs>
          {Icons.map((Icon, index) => (
            <ModalTab key={index} disabled>
              <Icon color="secondary" />
            </ModalTab>
          ))}
          <ModalTitle formTitleField={formTitleField} />
        </ModalTabs>
      )}
      {Tabs && <Tabs {...TabsProps} {...rest} />}
      <DialogContent {...DialogContentProps}>
        {component &&
          createElement(component, {
            handleSubmit: submit,
            formValueSelector: formValueSelector(form),
            ...rest,
            ...FormProps,
          })}
      </DialogContent>

      <DialogActions>
        <>
          {displayCancelBtn && (
            <Button onClick={toggleModal}>{cancelText || "Cancel"}</Button>
          )}
          {ModalActions && (
            <ModalActions
              valid={valid}
              handleSubmit={submit ? submit : handleSubmit}
              onSubmit={onSubmit}
              submitText={submitText}
              submitting={submitting}
              toggleModal={toggleModal}
              formValueSelector={formValueSelector(form)}
              {...rest}
              {...ModalActionsProps}
            />
          )}
          {displaySubmitBtn && (
            <LoadingButton
              form={form}
              type="submit"
              color="primary"
              disabled={!valid || submitting}
              loading={submitting}
              variant="contained"
              onClick={submit}
            >
              {submitText}
            </LoadingButton>
          )}
        </>
      </DialogActions>
    </Dialog>
  );
};

SimpleModal.defaultProps = {
  displaySubmitBtn: true,
  displayCancelBtn: true,
};

const styles = theme => ({
  itemTitleContainer: {
    marginLeft: theme.spacing(-2),
  },
  itemTitle: {
    color: theme.altus.background.header,
  },
});

class SimpleFormModal extends PureComponent {
  constructor(props) {
    super(props);

    const { modalId, form, formTitleField, competencyStatusField } = props;

    this.ConnectedSimpleModal = compose(
      withModal(modalId || createGuid()),
      reduxForm(form),
      connect(state => ({
        competencyStatus: getFormValueFromState(
          state,
          form.form,
          competencyStatusField,
        ),
      })),
    )(SimpleModal);

    this.ModalItemTitle = compose(
      connect(state => ({
        itemTitle: getFormInitialValueFromState(
          state,
          form.form,
          formTitleField,
        ),
      })),
      withStyles(styles),
    )(ModalItemTitle);
  }

  render() {
    const { ConnectedSimpleModal, ModalItemTitle } = this;
    const { form, ...rest } = this.props;

    return <ConnectedSimpleModal ModalTitle={ModalItemTitle} {...rest} />;
  }
}

export default SimpleFormModal;
