import React from "react";

import {
  Grid,
  List,
  ListItem,
  Checkbox,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { EMPTY_MAP } from "app/app.constants";

const ModalCheckboxSelect = ({
  getKey,
  getTitle,
  isSelected,
  availableItems = EMPTY_MAP,
  onToggleSelect,
}) => (
  <Grid item xs component={List}>
    {availableItems.map(item => (
      <ListItem
        dense
        button
        key={getKey(item)}
        onClick={() => onToggleSelect(item)}
      >
        <ListItemIcon>
          <Checkbox
            color="primary"
            edge="start"
            disableRipple
            checked={isSelected(item)}
          />
        </ListItemIcon>
        <ListItemText primary={getTitle(item)} />
      </ListItem>
    ))}
  </Grid>
);

export default ModalCheckboxSelect;
