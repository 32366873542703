import { compose } from "redux";
import React, { PureComponent } from "react";
import { Grid, Typography, withStyles, Paper } from "@material-ui/core";

import { COMPETENCY_STATUS } from "app/app.constants";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";

class CustomCourseInfo extends PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <Grid component={Paper} className={classes.customCourseInfo}>
        <Typography paragraph variant="subtitle2">
          Custom Course
        </Typography>
        <Typography paragraph variant="caption">
          Use custom courses to group courses that fulfill the same
          requirements. An employee completing any one of the prerequisite
          courses below also meets the requirements for this course, and the
          employee's competency status will be shown as
          {` ${statusToTooltipText(COMPETENCY_STATUS.UP_TO_DATE)}`} both for
          this course and the prerequisite course.
        </Typography>
        <Typography variant="caption">
          A custom course must be Always valid, but you can add prerequisite
          courses that have different validity periods and expiration dates. The
          system chooses the expiration date furthest in the future for
          employees with multiple prerequisite courses.
        </Typography>
      </Grid>
    );
  }
}

const styles = theme => ({
  customCourseInfo: {
    height: "fit-content",
    padding: theme.spacing(2),
    background: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[200]}`,
  },
});

export default compose(withStyles(styles))(CustomCourseInfo);
