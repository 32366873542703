import React from "react";
import { compose } from "redux";
import { Grid } from "@material-ui/core";

import ReadOnlyFieldReduxForm from "app/components/form/ReadOnlyFieldReduxForm";
import { EMPTY_SET } from "app/app.constants";

const SkillDetails = ({ skill }) => {
  return (
    <Grid item container>
      {!!skill && (
        <Grid container direction="column">
          <Grid item container>
            <ReadOnlyFieldReduxForm
              label="Skill Name"
              input={{
                value: skill.get("name"),
              }}
            />
          </Grid>
          <Grid item container>
            <ReadOnlyFieldReduxForm
              rows={4}
              multiline
              label="Description"
              input={{
                value: skill.get("description"),
              }}
            />
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs={6}>
              <ReadOnlyFieldReduxForm
                label="Skill Type"
                input={{
                  value: skill.get("skillTypeName"),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ReadOnlyFieldReduxForm
                label="Skill Group"
                input={{
                  value: skill.get("skillGroupName"),
                }}
              />
            </Grid>
          </Grid>
          <Grid item container>
            <ReadOnlyFieldReduxForm
              label="Countries"
              input={{
                value: skill.get("countries", EMPTY_SET).size
                  ? skill
                      .get("countries")
                      .map(country => country.get("name"))
                      .join(", ")
                  : "Global",
              }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default compose()(SkillDetails);
