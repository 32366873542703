import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Grid, Typography } from "@material-ui/core";

import {
  getSkillFromState,
  getEmployeeFromState,
  getCollectionsFromState,
  getEmployeeSkillFromState,
  getCollectionItemsBySkillFromState,
} from "features/competence.selectors";

import {
  SkillMapper,
  EmployeeMapper,
  EmployeeSkillMapper,
} from "app/app.mappers";

import {
  getEmployeeSkill,
  getCollectionsBySkill,
  getCollectionItemsOverriddenBySkill,
} from "features/competence.actions";

import { getInitials } from "utils/app.util";
import { BasePage } from "altus-ui-components";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import { ICONS, ACTIONS, EMPTY_MAP, EMPTY_LIST } from "app/app.constants";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";
import EntityCompetencyStatus from "features/components/EntityRelationCompetencyStatus/EntityCompetencyStatus";
import EntityRelationCompetencyStatus from "features/components/EntityRelationCompetencyStatus/EntityRelationCompetencyStatus";

class EmployeeSkillStatusContainer extends PureComponent {
  componentDidMount() {
    const {
      skillId,
      employeeId,
      dispatchOnLoad,
      dispatchGetCollectionItemsOverriddenBySkill,
      dispatchGetCollections,
    } = this.props;

    dispatchGetCollections(skillId);
    dispatchOnLoad(employeeId, skillId);
    dispatchGetCollectionItemsOverriddenBySkill(skillId);
  }

  renderEmployee = () => {
    const { employee } = this.props;

    return (
      <EntityCompetencyStatus
        topText={employee.get("displayName")}
        bottomText={employee.get("departmentName")}
        Icon={getInitials(employee.get("displayName"))}
      />
    );
  };

  renderSkill = () => {
    const { skill } = this.props;

    return (
      <EntityCompetencyStatus
        Icon={<ICONS.SKILL />}
        topText={skill.get("name")}
        bottomText={skill.get("description")}
      />
    );
  };

  renderCollectionSkill = () => {
    const { collectionItems } = this.props;

    return (
      <Grid container item direction="column" spacing={1}>
        <Grid item>{this.renderSkill()}</Grid>
        {collectionItems.map(collectionItem => {
          const Icon = collectionItem.get("courseId")
            ? ICONS.COURSE
            : ICONS.SKILL;

          return (
            <Grid item key={collectionItem.get("competencyCollectionItemId")}>
              <EntityCompetencyStatus
                elevation={0}
                Icon={<Icon />}
                variant="dashed"
                topText={collectionItem.get("name")}
                bottomText={collectionItem.get("description")}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  render() {
    const { dataState, collections, employeeSkill, breadcrumb } = this.props;

    return (
      <BasePage title={breadcrumb} dataState={dataState}>
        <Grid container spacing={2}>
          <Grid item container xs={12} lg={10}>
            {!!collections.size && (
              <Grid container justify="flex-end">
                <Grid item xs />
                <Grid item xs />
                <Grid item container xs alignItems="center" direction="column">
                  {collections.map(collection => (
                    <Typography key={collection.get("competencyCollectionId")}>
                      <b>{collection.get("name")}</b>
                    </Typography>
                  ))}
                  <Typography variant="caption" paragraph>
                    Collection override
                  </Typography>
                </Grid>
              </Grid>
            )}
            <EntityRelationCompetencyStatus
              renderLeftEntity={this.renderEmployee}
              getCompetencyStatusText={statusToTooltipText}
              competencyStatus={employeeSkill.get("competencyStatus")}
              renderRightEntity={
                collections.size ? this.renderCollectionSkill : this.renderSkill
              }
            />
          </Grid>
        </Grid>
      </BasePage>
    );
  }
}

EmployeeSkillStatusContainer.defaultProps = {
  collections: EMPTY_MAP,
  skill: SkillMapper.initial,
  collectionItems: EMPTY_LIST,
  employee: EmployeeMapper.initial,
  employeeSkill: EmployeeSkillMapper.initial,
};

export default compose(
  connect(
    (initialState, { skillId, employeeId }) => {
      const employeeSkillSelector = getEmployeeSkillFromState(
        employeeId,
        skillId,
      );

      return state => ({
        skill: getSkillFromState(state, skillId),
        employeeSkill: employeeSkillSelector(state),
        collections: getCollectionsFromState(state),
        employee: getEmployeeFromState(state, employeeId),
        collectionItems: getCollectionItemsBySkillFromState(state, skillId),
        dataState: getSummarizedDataStatesFromState(
          state,
          ACTIONS.GET_SKILL,
          ACTIONS.GET_EMPLOYEE_SKILL,
        ),
      });
    },
    {
      dispatchOnLoad: getEmployeeSkill,
      dispatchGetCollections: getCollectionsBySkill,
      dispatchGetCollectionItemsOverriddenBySkill: getCollectionItemsOverriddenBySkill,
    },
  ),
)(EmployeeSkillStatusContainer);
