import { compose } from "redux";
import isNaN from "lodash/isNaN";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import ProgressBar from "app/components/ProgressBar";
import { EMPTY_MAP, FEATURE_FLAG } from "app/app.constants";
import { getFeatureFlagStatusFromState } from "app/app.selectors";
import { getRoleStatsFromState } from "features/competence.selectors";

class RoleProgressBar extends PureComponent {
  getTitle = ({ activeCount, requiredCount }) => {
    // We do not want the ProgressBar to display NaN/NaN while loading the stats
    const numerator = isNaN(activeCount) ? 0 : activeCount;
    const denominator = isNaN(requiredCount) ? 0 : requiredCount;

    return `${numerator}/${denominator}`;
  };

  render() {
    const { roleStats, isSkillsFeatureFlagEnabled } = this.props;

    const requiredCourses = roleStats.get("requiredCoursesCount");
    const requiredSkills = isSkillsFeatureFlagEnabled
      ? roleStats.get("requiredSkillsCount")
      : 0;

    const activeCourses = roleStats.get("activeEmployeeCourseCount");
    const activeSkills = isSkillsFeatureFlagEnabled
      ? roleStats.get("activeEmployeeSkillCount")
      : 0;

    const requiredCount = requiredCourses + requiredSkills;
    const activeCount = activeCourses + activeSkills;

    const value =
      requiredCount === 0 ? 100 : (activeCount / requiredCount) * 100;

    return (
      <ProgressBar
        value={value}
        title={this.getTitle({ activeCount, requiredCount })}
      />
    );
  }
}

RoleProgressBar.defaultProps = {
  roleStats: EMPTY_MAP,
};

export default compose(
  connect((state, { roleId, roleStats }) => ({
    roleStats: roleStats ? roleStats : getRoleStatsFromState(state, roleId),
    isSkillsFeatureFlagEnabled: getFeatureFlagStatusFromState(
      state,
      FEATURE_FLAG.SKILLS,
    ),
  })),
)(RoleProgressBar);
