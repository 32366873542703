import { compose } from "redux";
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";

import { HeaderBase } from "altus-ui-components";

import { invokeIfFunction } from "utils/app.util";

class GapReportBasePageHeader extends PureComponent {
  render() {
    const { classes, renderFilter } = this.props;

    return (
      <HeaderBase
        classes={{
          root: classes.headerBaseRoot,
        }}
      >
        {invokeIfFunction(renderFilter)}
      </HeaderBase>
    );
  }
}

const styles = theme => ({
  headerBaseRoot: {
    backgroundColor: theme.sidebar.backgroundColor,
    padding: `${theme.spacing(1)}px ${theme.spacing(0)}px`,
  },
});

export default compose(withStyles(styles))(GapReportBasePageHeader);
