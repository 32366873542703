import moment from "moment";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { useEffect, useMemo, useCallback, useState } from "react";
import { withStyles, Grid, IconButton, Tooltip } from "@material-ui/core";

import { Tab, Tabs } from "altus-ui-components";

import {
  experienceRecordsOnUnload,
  createExperienceFormOnLoad,
  createExperienceFormOnSubmit,
  deleteExperienceRecordAskConfirmation,
  deleteExperienceRecordsAskConfirmation,
  experienceRecordsByCompetencyStatusOnLoad,
} from "features/competence.actions";

import {
  getExperienceRecordsFromState,
  getExperienceRecordsStatsByStatusFromState,
  getExperienceRecordsStatsFromState,
} from "features/competence.selectors";

import {
  ACTIONS,
  COMPETENCY_STATUS,
  EMPTY_MAP,
  Format,
  ICONS,
} from "app/app.constants";

import routePaths from "app/routePaths";
import { formatDate } from "utils/format.util";
import CrudBasePage from "app/components/CrudBasePage";
import DatePickerField from "app/components/form/DatePickerField";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";
import { EXPERIENCE_RECORD_DETAILS_FORM } from "features/competence.constants";
import CreateExperienceRecordForm from "features/hr/experiences/components/CreateExperienceRecordForm";

const ExperienceRecordsContainer = ({
  dataState,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
  experienceRecords,
  experienceRecordStats = EMPTY_MAP,
  experienceRecordStatsByStatus = EMPTY_MAP,
  dispatchDeleteExperienceRecord,
  dispatchDeleteExperienceRecords,
  dispatchCreateExperienceOnSubmit,
  dispatchCreateExperienceFormOnLoad,
}) => {
  const [competencyStatus, setCompetencyStatus] = useState(
    COMPETENCY_STATUS.NONE,
  );

  const loadExperienceRecords = useCallback(
    () => dispatchOnLoad(competencyStatus),
    [competencyStatus, dispatchOnLoad],
  );

  useEffect(() => {
    loadExperienceRecords();

    return () => dispatchOnUnload();
  }, [loadExperienceRecords, dispatchOnUnload]);

  const onSelectCompetencyStatus = useCallback(
    event => {
      setCompetencyStatus(event);
    },
    [setCompetencyStatus],
  );

  const getCompetencyStatusCount = useCallback(
    key => {
      return experienceRecordStatsByStatus.get("competencyStatusesCount") &&
        experienceRecordStatsByStatus.get("competencyStatusesCount")[key]
        ? experienceRecordStatsByStatus.get("competencyStatusesCount")[key]
        : 0;
    },
    [experienceRecordStatsByStatus],
  );

  const columns = useMemo(
    () => [
      {
        xs: 1,
        title: "Job",
        getSortProperty: item => item.get("jobId"),
      },
      {
        xs: 2,
        title: "Employee",
        getSortProperty: item => item.get("employeeDisplayName"),
      },
      {
        xs: true,
        filter: {
          label: "Client",
          defaultText: "All",
        },
        title: "Client",
        getSortProperty: item => item.get("experienceClientName"),
      },
      {
        xs: true,
        filter: {
          multiple: true,
          label: "Location",
          defaultText: "All",
        },
        title: "Location",
        getSortProperty: item => item.get("locationName"),
      },
      {
        xs: true,
        filter: {
          multiple: true,
          label: "Discipline",
          defaultText: "All",
        },
        title: "Discipline",
        getSortProperty: item => item.get("experienceDisciplineName"),
      },
      {
        xs: true,
        filter: {
          multiple: true,
          label: "Performed as",
          defaultText: "All",
        },
        title: "Performed as",
        getSortProperty: item => item.get("performedAs"),
      },
      {
        xs: true,
        filter: {
          multiple: true,
          label: "Validator",
          defaultText: "All",
        },
        title: "Validator",
        getSortProperty: item => item.get("validatorDisplayName"),
      },
      {
        filter: {
          render: ({ onChange, value, ...rest }) => (
            <DatePickerField
              input={{
                onChange,
                value,
              }}
              inputVariant="standard"
              DatePickerProps={{
                margin: "normal",
                label: "From Date",
              }}
              {...rest}
            />
          ),
          match: (filterValue, cellValue) =>
            moment(cellValue).isSameOrAfter(filterValue, "day"),
        },
        xs: true,
        title: "From Date",
        getSortProperty: item => formatDate(item.get("from"), Format.date),
      },
      {
        filter: {
          render: ({ onChange, value, ...rest }) => (
            <DatePickerField
              input={{
                onChange,
                value,
              }}
              inputVariant="standard"
              DatePickerProps={{
                margin: "normal",
                label: "To Date",
              }}
              {...rest}
            />
          ),
          match: (filterValue, cellValue) =>
            moment(cellValue).isSameOrBefore(filterValue, "day"),
        },
        xs: true,
        title: "To Date",
        getSortProperty: item => formatDate(item.get("to"), Format.date),
      },
      {
        xs: true,
        title: "XP",
        getSortProperty: item =>
          experienceRecordStats.getIn(
            [
              item.get("experienceRecordId").toString(),
              "experiencePointsCount",
            ],
            "-",
          ),
      },
      {
        xs: true,
        title: "Status",
        component: Grid,
        container: true,
        justify: "flex-end",
        getSortProperty: item => item.get("competencyStatus"),
        getValue: item => (
          <CompetencyStatusChip status={item.get("competencyStatus")} />
        ),
        filter: {
          visible: competencyStatus === 0,
          label: "Status",
          defaultText: "All",
          getFilterText: filter => statusToTooltipText(filter),
        },
      },
    ],
    [experienceRecordStats, competencyStatus],
  );

  const listActions = useMemo(
    () => [
      {
        renderAction: ({ selectedItems }) => (
          <IconButton
            classes={{
              root: styles.iconButtonRoot,
            }}
            disabled={!selectedItems.size}
            onClick={() => dispatchDeleteExperienceRecords(selectedItems)}
          >
            <ICONS.DELETE />
          </IconButton>
        ),
        getTitle: selectedItems => `Delete (${selectedItems.size})`,
      },
    ],
    [dispatchDeleteExperienceRecords],
  );

  const actions = useMemo(
    () => [
      {
        getValue: item => (
          <Tooltip title="Delete">
            <IconButton onClick={() => dispatchDeleteExperienceRecord(item)}>
              <ICONS.DELETE fontSize="small" />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    [dispatchDeleteExperienceRecord],
  );

  return (
    <>
      <Grid>
        <Tabs
          value={competencyStatus}
          onChange={(event, value) => onSelectCompetencyStatus(value)}
        >
          <Tab
            label={`All (${getCompetencyStatusCount(COMPETENCY_STATUS.NONE)})`}
            value={COMPETENCY_STATUS.NONE}
          />
          <Tab
            label={`Not Completed (${getCompetencyStatusCount(
              COMPETENCY_STATUS.MISSING_REQUIREMENTS,
            )})`}
            value={COMPETENCY_STATUS.MISSING_REQUIREMENTS}
          />
          <Tab
            label={`Needs Approval (${getCompetencyStatusCount(
              COMPETENCY_STATUS.REQUIRES_APPROVAL,
            )})`}
            value={COMPETENCY_STATUS.REQUIRES_APPROVAL}
          />
          <Tab
            label={`Not Approved (${getCompetencyStatusCount(
              COMPETENCY_STATUS.NOT_APPROVED,
            )})`}
            value={COMPETENCY_STATUS.NOT_APPROVED}
          />
          <Tab
            label={`Up To Date (${getCompetencyStatusCount(
              COMPETENCY_STATUS.UP_TO_DATE,
            )})`}
            value={COMPETENCY_STATUS.UP_TO_DATE}
          />
        </Tabs>
      </Grid>
      <CrudBasePage
        items={experienceRecords}
        displayAddButton
        title={breadcrumb}
        displaySearchField
        Icon={ICONS.EXPERIENCES}
        CreateEntityFormComponent={CreateExperienceRecordForm}
        dataState={dataState}
        SortableListProps={{
          selectable: true,
          columns,
          actions,
          listActions,
          onRefresh: dispatchOnLoad,
          displayNumberOfItems: true,
          getKey: item => item.get("experienceRecordId"),
          createSortableListRowProps: item => ({
            component: Link,
            to: `${routePaths.hrPortal}/experiences/${item.get(
              "experienceRecordId",
            )}/employee/${item.get("employeeId")}`,
          }),
        }}
        CreateModalProps={{
          submitText: "Add",
          title: "Add Experience Record",
          onSubmit: dispatchCreateExperienceOnSubmit,
          onEnter: dispatchCreateExperienceFormOnLoad,
        }}
        createEntityForm={{
          form: EXPERIENCE_RECORD_DETAILS_FORM.ID,
        }}
      />
    </>
  );
};

const styles = theme => ({
  iconButtonRoot: {
    padding: theme.spacing(1),
  },
});

export default compose(
  connect(
    state => ({
      experienceRecords: getExperienceRecordsFromState(state),
      experienceRecordStats: getExperienceRecordsStatsFromState(state),
      experienceRecordStatsByStatus: getExperienceRecordsStatsByStatusFromState(
        state,
      ),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_EXPERIENCE_RECORDS_BY_COMPETENCY_STATUS,
        ACTIONS.DELETE_EXPERIENCE_RECORD,
        ACTIONS.DELETE_EXPERIENCE_RECORDS,
      ),
    }),
    {
      dispatchOnLoad: experienceRecordsByCompetencyStatusOnLoad,
      dispatchOnUnload: experienceRecordsOnUnload,
      dispatchCreateExperienceOnSubmit: createExperienceFormOnSubmit,
      dispatchCreateExperienceFormOnLoad: createExperienceFormOnLoad,
      dispatchDeleteExperienceRecord: deleteExperienceRecordAskConfirmation,
      dispatchDeleteExperienceRecords: deleteExperienceRecordsAskConfirmation,
    },
  ),
  withStyles(styles),
)(ExperienceRecordsContainer);
