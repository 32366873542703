import { compose } from "redux";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";

import { ACTIONS } from "app/app.constants";
import RoleDetails from "features/components/RoleDetails";
import { getRoleFromState } from "features/competence.selectors";
import { getActionDataStateFromState } from "app/app.selectors";
import { BasePage } from "altus-ui-components";

class EmployeeRoleDetailsContainer extends PureComponent {
  render() {
    const { role, dataState, breadcrumb } = this.props;

    return (
      <BasePage dataState={dataState} title={breadcrumb}>
        <Grid container item xs={12} md={8}>
          <RoleDetails role={role} />
        </Grid>
      </BasePage>
    );
  }
}

export default compose(
  connect((state, { roleId }) => ({
    dataState: getActionDataStateFromState(state, ACTIONS.GET_ROLE),
    role: getRoleFromState(state, roleId),
  })),
)(EmployeeRoleDetailsContainer);
