import Agent from "infrastructure/agent";
import authService from "services/auth.service";

export const getCurrentUser = () => Agent.get("/user/current");
export const getUsers = () => Agent.get("/user");
export const updateUser = (userId, user) => Agent.put(`/user/${userId}`, user);

const getAllAppRoles = () => Agent.get(`/user/approles`);

const createUserAppRoles = (userId, appRoles) =>
  Agent.post(`/user/${userId}/approles`, appRoles);

const getAppFeatures = () => Agent.get("/features");

const previewFile = url =>
  authService
    .acquireTokenSilent([Agent.config.scope])
    .then(token =>
      window.open(
        `${Agent.config.apiRoot}${url}?access_token=${token}`,
        "_blank",
      ),
    );

export default {
  getUsers,
  updateUser,
  getCurrentUser,
  getAllAppRoles,
  createUserAppRoles,
  getAppFeatures,
  previewFile,
};
