import { createSelector } from "utils/app.util";

import {
  getSummarizedDataState,
  getActionDataStateSelector,
  getActionsDataStatesSelector,
} from "altus-datastate";

export const getActionDataStateFromState = createSelector(
  getActionDataStateSelector,
  dataState => dataState,
);

export const getSummarizedDataStatesFromState = createSelector(
  getActionsDataStatesSelector,
  getSummarizedDataState,
);

export const getActionsDataStatesFromState = createSelector(
  getActionsDataStatesSelector,
  dataStates => dataStates,
);

const getCurrentUserSelector = state => state.getIn(["app", "currentUser"]);

const getCurrentUserAppRolesSelector = state =>
  state.getIn(["app", "currentUser", "appRoles"]);

export const getCurrentEmployeeSelector = state =>
  state.getIn(["app", "currentEmployee"]);

export const getCurrentUserFromState = createSelector(
  getCurrentUserSelector,
  currentUser => currentUser,
);

export const getCurrentUserAppRolesFromState = createSelector(
  getCurrentUserAppRolesSelector,
  appRoles => appRoles,
);

export const getCurrentEmployeeFromState = createSelector(
  getCurrentEmployeeSelector,
  currentEmployee => currentEmployee,
);

const getCurrentPathnameSelector = state =>
  state.getIn(["router", "location", "pathname"]);

export const getCurrentPathnameFromState = createSelector(
  getCurrentPathnameSelector,
  pathname => pathname,
);

const getUsersSelector = state => state.getIn(["app", "users"]);

export const getUsersFromState = createSelector(getUsersSelector, users =>
  users.toList(),
);

export const getUserFromState = createSelector(
  (state, userId) => state.getIn(["app", "users", userId.toString()]),
  user => user,
);

const getApplicationFailureSelector = state =>
  state.getIn(["app", "appFailure"]);

export const getApplicationFailureFromState = createSelector(
  getApplicationFailureSelector,
  applicationFailure => applicationFailure,
);

export const getFormFromState = (state, formId) =>
  state.getIn(["form", formId.toString()]);

export const getFormValuesFromState = createSelector(getFormFromState, form =>
  form ? form.get("values") : undefined,
);

export const getFormInitialValuesFromState = createSelector(
  getFormFromState,
  form => (form ? form.get("initial") : undefined),
);

const getFormInitialValueSelector = (state, formId, field) =>
  state.getIn(["form", formId.toString(), "initial", field]);

export const getFormInitialValueFromState = createSelector(
  getFormInitialValueSelector,
  value => value,
);

const getFormValueSelector = (state, formId, field) =>
  state.getIn(["form", formId.toString(), "values", field]);

export const getFormValueFromState = createSelector(
  getFormValueSelector,
  value => value,
);

const appRolesSelector = state => state.getIn(["app", "appRoles"]);

export const getAppRolesFromState = createSelector(
  appRolesSelector,
  appRoles => appRoles,
);

const featureFlagsSelector = state => state.getIn(["app", "featureFlags"]);

export const getFeatureFlagsFromState = createSelector(
  featureFlagsSelector,
  featureFlags => featureFlags,
);

export const getFeatureFlagStatusFromState = createSelector(
  (state, featureFlag) =>
    state.getIn(["app", "featureFlags", featureFlag], true),
  featureFlagStatus => featureFlagStatus,
);
