import Agent from "infrastructure/agent";

/**
 * Skill Group
 */
const getSkillGroups = () => Agent.get(`/skill/group`);

const getSkillGroup = skillGroupId => Agent.get(`/skill/group/${skillGroupId}`);

const createSkillGroup = skillGroup => Agent.post(`/skill/group`, skillGroup);

const updateSkillGroup = (skillGroupId, skillGroup) =>
  Agent.put(`/skill/group/${skillGroupId}`, skillGroup);

const deleteSkillGroup = skillGroupId =>
  Agent.delete(`/skill/group/${skillGroupId}`);

/**
 * Skill Type
 */
const getSkillTypes = () => Agent.get(`/skill/type`);
const getSkillType = skillTypeId => Agent.get(`/skill/type/${skillTypeId}`);
const createSkillType = skillType => Agent.post(`/skill/type`, skillType);
const updateSkillType = (skillTypeId, skillType) =>
  Agent.put(`/skill/type/${skillTypeId}`, skillType);
const deleteSkillType = skillTypeId =>
  Agent.delete(`/skill/type/${skillTypeId}`);

/**
 * Skill
 */
const getSkills = () => Agent.get(`/skill`);
const getSkill = skillId => Agent.get(`/skill/${skillId}`);
const createSkill = skill => Agent.post(`/skill`, skill);
const updateSkill = (skillId, skill) => Agent.put(`/skill/${skillId}`, skill);
const deleteSkill = skillId => Agent.delete(`/skill/${skillId}`);
const deleteSkills = skillIds => Agent.post(`/skill/remove`, skillIds);

const getCollectionItemsOverriddenBySkill = skillId =>
  Agent.get(`/skill/${skillId}/overrides`);

export default {
  getSkillGroups,
  getSkillGroup,
  createSkillGroup,
  updateSkillGroup,
  deleteSkillGroup,
  getSkillTypes,
  getSkillType,
  createSkillType,
  updateSkillType,
  deleteSkillType,
  getSkills,
  getSkill,
  createSkill,
  updateSkill,
  deleteSkill,
  deleteSkills,
  getCollectionItemsOverriddenBySkill,
};
