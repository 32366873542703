import { compose } from "redux";
import { connect } from "react-redux";
import { Field } from "redux-form/immutable";
import React, { PureComponent } from "react";

import { ADD_PREREQUISITE_COURSE_FORM } from "features/competence.constants";
import { getAvailablePrerequisiteCoursesFromState } from "features/competence.selectors";
import AutoSuggestField from "app/components/form/AutoSuggestField";

class AddPrerequisiteCourseForm extends PureComponent {
  render() {
    const { handleSubmit, availableCourses } = this.props;

    return (
      <form id={ADD_PREREQUISITE_COURSE_FORM.ID} onSubmit={handleSubmit}>
        <Field
          autoFocus
          required
          label="Select Course"
          component={AutoSuggestField}
          name={ADD_PREREQUISITE_COURSE_FORM.PREREQUISITE_COURSE_ID}
          options={availableCourses.map(course => ({
            label: course.get("name"),
            value: course.get("courseId"),
          }))}
        />
      </form>
    );
  }
}

export default compose(
  connect(state => ({
    availableCourses: getAvailablePrerequisiteCoursesFromState(state),
  })),
)(AddPrerequisiteCourseForm);
