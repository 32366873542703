import React from "react";
import { PureComponent } from "react";
import { compose } from "redux";
import classNames from "classnames";
import { withStyles } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import TreeItem from "@material-ui/lab/TreeItem";

import { EMPTY_LIST } from "app/app.constants";
import SortableListRow from "app/components/SortableList/SortableListRow";
import SortableListRowColumn from "app/components/SortableList/SortableListRowColumn";
import { invokeIfFunction } from "utils/app.util";

class TreeListItem extends PureComponent {
  render() {
    const {
      Icon,
      item: currentItem,
      itemChildren,
      getKey,
      createSortableListRowProps,
      classes,
    } = this.props;
    return (
      <TreeItem
        nodeId={getKey(currentItem).toString()}
        label={
          <SortableListRow
            {...invokeIfFunction(createSortableListRowProps, currentItem)}
          >
            <SortableListRowColumn>
              <Icon />
            </SortableListRowColumn>
            <SortableListRowColumn>
              {currentItem.get("name")}
            </SortableListRowColumn>
          </SortableListRow>
        }
        classes={{
          root: classes.root,
          content: classes.content,
          iconContainer: classes.iconContainer,
          group: classes.group,
          label: classNames(classes.label, {
            [classes.labelStriped]: currentItem.get("index") % 2,
          }),
        }}
      >
        {!!itemChildren.size
          ? itemChildren.map(childItem => (
              <TreeListItem
                Icon={Icon}
                key={getKey(childItem).toString()}
                item={childItem}
                itemChildren={childItem.get("children")}
                getKey={getKey}
                createSortableListRowProps={createSortableListRowProps}
                classes={classes}
              />
            ))
          : undefined}
      </TreeItem>
    );
  }
}

const styles = theme => ({
  root: {
    "&:focus > .MuiTreeItem-content": {
      backgroundColor: "transparent",
    },
    marginLeft: theme.spacing(1),
  },
  content: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: theme.spacing(1) - 1,
    paddingLeft: theme.spacing(1) - 1,
  },
  label: {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    marginTop: -1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: `${blue[100]}42`,
    },
  },
  labelStriped: {
    backgroundColor: theme.palette.grey[200],
  },
});

TreeListItem.defaultProps = {
  itemChildren: EMPTY_LIST,
};

export default compose(withStyles(styles))(TreeListItem);
