import { compose } from "redux";
import { List } from "immutable";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import React, { useCallback } from "react";

import {
  getGapReportEmployeesByDepartmentFromState,
  getServicesByServiceIdFromState,
} from "features/competence.selectors";

import { invokeIfFunction } from "utils/app.util";
import { EMPTY_MAP, EMPTY_LIST } from "app/app.constants";
import { BASE_FILTERS } from "features/components/GapReport/GapReportFilterBase";
import GapReportFilterBase from "features/components/GapReport/GapReportFilterBase";
import GapReportAutocompleteFilter from "features/components/GapReport/GapReportAutocompleteFilter";

const FILTERS = {
  SERVICES: "services",
};

const initialFilter = EMPTY_MAP.set(FILTERS.SERVICES, EMPTY_LIST)
  .set(BASE_FILTERS.STATUS, EMPTY_LIST)
  .set(BASE_FILTERS.EMPLOYEES, EMPTY_LIST)
  .set(BASE_FILTERS.DEPARTMENTS, EMPTY_LIST);

const ServiceGapReportFilter = ({
  getGapReport,
  clearGapReport,
  services,
  downloadGapReport,
  employeesByDepartment,
}) => {
  const renderFilter = useCallback(
    ({
      filter,
      setFilter,
      renderStatusFilter,
      renderEmployeeFilter,
      renderDepartmentFilter,
    }) => (
      <>
        {invokeIfFunction(renderDepartmentFilter)}
        {invokeIfFunction(renderEmployeeFilter)}
        <Grid item xs>
          <GapReportAutocompleteFilter
            label="Services"
            options={services}
            getOptionLabel={service => service.get("name")}
            value={filter
              .get(FILTERS.SERVICES)
              .map(serviceId => services.get(serviceId.toString()))}
            onChange={(_, value) =>
              setFilter(
                FILTERS.SERVICES,
                List(value.map(service => service.get("serviceId"))),
              )
            }
          />
        </Grid>
        {invokeIfFunction(renderStatusFilter)}
      </>
    ),
    [services],
  );

  return (
    <GapReportFilterBase
      getGapReport={getGapReport}
      initialFilter={initialFilter}
      services={services} // force rerender when this changes
      clearGapReport={clearGapReport}
      renderFilter={renderFilter}
      downloadGapReport={downloadGapReport}
      downloadBtnDisabled={!employeesByDepartment.size}
    />
  );
};

export default compose(
  connect(state => ({
    services: getServicesByServiceIdFromState(state),
    employeesByDepartment: getGapReportEmployeesByDepartmentFromState(state),
  })),
)(ServiceGapReportFilter);
