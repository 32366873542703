import memoize from "lodash/memoize";
import { List, Set, Map } from "immutable";
import Info from "@material-ui/icons/Info";
import Home from "@material-ui/icons/Home";
import Build from "@material-ui/icons/Build";
import Delete from "@material-ui/icons/Delete";
import People from "@material-ui/icons/People";
import School from "@material-ui/icons/School";
import Person from "@material-ui/icons/Person";
import ClassIcon from "@material-ui/icons/Class";
import History from "@material-ui/icons/History";
import ThumbUp from "@material-ui/icons/ThumbUp";
import SchoolIcon from "@material-ui/icons/School";
import CloudOff from "@material-ui/icons/CloudOff";
import Settings from "@material-ui/icons/Settings";
import Security from "@material-ui/icons/Security";
import Business from "@material-ui/icons/Business";
import ArtTrack from "@material-ui/icons/ArtTrack";
import BarChart from "@material-ui/icons/BarChart";
import EmojiEvents from "@material-ui/icons/EmojiEvents";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import Apps from "@material-ui/icons/Apps";

import Certificate from "app/components/icons/Certificate";
import ServiceHand from "app/components/icons/ServiceHand";

export const Environments = {
  LOCAL: "Local",
  DEV: "ALTUSDEV",
  TST: "ALTUSTST",
  PRD: "ALTUSPRD",
  SBX: "ALTUSSBX",
  SCA: "ALTUSSCA",
};

export const IN_DEV = process.env.NODE_ENV === "development" ? true : false;

export const DEFAULT_MOMENT_LOCALE = "en-gb";

export const SAP_HELP_URL =
  "https://insiteqinterra.sharepoint.com/sites/HR/HRO%20Doc%20test/Personal%20profile%20-%20quick%20guide.pdf";

export const ACTIONS = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  GET_CURRENT_USER: "GET_CURRENT_USER",
  APPLICATION_LOADED: "APPLICATION_LOADED",
  GET_CURRENT_EMPLOYEE: "GET_CURRENT_EMPLOYEE",
  SET_APPLICATION_FAILURE: "SET_APPLICATION_FAILURE",
  CONFIRMATION_DIALOG_PENDING: "CONFIRMATION_DIALOG_PENDING",

  /**
   * Department
   */
  GET_DEPARTMENTS: "GET_DEPARTMENTS",
  GET_MY_TEAM_DEPARTMENTS: "GET_MY_TEAM_DEPARTMENTS",

  /**
   * Manager
   */
  GET_MANAGERS: "GET_MANAGERS",
  GET_MY_TEAM_MANAGERS: "GET_MY_TEAM_MANAGERS",

  /**
   * Course
   */
  CLEAR_COURSES: "CLEAR_COURSES",
  CREATE_COURSE: "CREATE_COURSE",
  DELETE_COURSE: "DELETE_COURSE",
  DELETE_COURSE_ASK_CONFIRMATION: "DELETE_COURSE_ASK_CONFIRMATION",
  DELETE_COURSES: "DELETE_COURSES",
  DELETE_COURSES_ASK_CONFIRMATION: "DELETE_COURSES_ASK_CONFIRMATION",
  GET_COURSE: "GET_COURSE",
  GET_COURSES: "GET_COURSES",
  UPDATE_COURSE: "UPDATE_COURSE",

  GET_AVAILABLE_PREREQUISITE_COURSES: "GET_AVAILABLE_PREREQUISITE_COURSES",
  GET_PREREQUISITE_COURSES: "GET_PREREQUISITE_COURSES",
  ADD_PREREQUISITE_COURSE: "ADD_PREREQUISITE_COURSE",
  REMOVE_PREREQUISITE_COURSE: "REMOVE_PREREQUISITE_COURSE",
  REMOVE_PREREQUISITE_COURSE_ASK_CONFIRMATION:
    "REMOVE_PREREQUISITE_COURSE_ASK_CONFIRMATION",

  /**
   * Collection
   */
  CLEAR_COLLECTIONS: "CLEAR_COLLECTIONS",
  CREATE_COLLECTION: "CREATE_COLLECTION",
  DELETE_COLLECTION: "DELETE_COLLECTION",
  DELETE_COLLECTION_ASK_CONFIRMATION: "DELETE_COLLECTION_ASK_CONFIRMATION",
  GET_COLLECTION: "GET_COLLECTION",
  GET_COLLECTIONS: "GET_COLLECTIONS",
  UPDATE_COLLECTION: "UPDATE_COLLECTION",
  UPDATE_COLLECTION_MATRIX: "UPDATE_COLLECTION_MATRIX",
  GET_COLLECTION_MATRIX: "GET_COLLECTION_MATRIX",
  RESET_COLLECTION_MATRIX: "RESET_COLLECTION_MATRIX",
  GET_COLLECTION_ITEMS: "GET_COLLECTION_ITEMS",
  DELETE_COLLECTION_ITEM: "DELETE_COLLECTION_ITEM",
  CREATE_COLLECTION_ITEMS: "CREATE_COLLECTION_ITEMS",
  GET_COLLECTION_ITEM: "GET_COLLECTION_ITEM",
  GET_COURSE_COLLECTION_OVERRIDES: "GET_COURSE_COLLECTION_OVERRIDES",
  GET_SKILL_COLLECTION_OVERRIDES: "GET_SKILL_COLLECTION_OVERRIDES",

  /**
   * Collection Course
   */
  GET_AVAILABLE_COURSES_FOR_COLLECTION: "GET_AVAILABLE_COURSES_FOR_COLLECTION",

  /**
   * Collection Skill
   */
  GET_AVAILABLE_SKILLS_FOR_COLLECTION: "GET_AVAILABLE_SKILLS_FOR_COLLECTION",

  /**
   * Employee Course
   */
  GET_EMPLOYEE_COURSE: "GET_EMPLOYEE_COURSE",
  DELETE_EMPLOYEE_COURSE: "DELETE_EMPLOYEE_COURSE",
  UPDATE_EMPLOYEE_COURSE: "UPDATE_EMPLOYEE_COURSE",
  UPDATE_EMPLOYEE_COURSE_APPROVAL: "UPDATE_EMPLOYEE_COURSE_APPROVAL",
  REMOVE_EMPLOYEE_COURSE_APPROVAL: "REMOVE_EMPLOYEE_COURSE_APPROVAL",
  CREATE_EMPLOYEE_COURSE: "CREATE_EMPLOYEE_COURSE",
  CREATE_AND_APPROVE_EMPLOYEE_COURSE: "CREATE_AND_APPROVE_EMPLOYEE_COURSE",
  CREATE_EMPLOYEE_COURSE_OVERRIDE: "CREATE_EMPLOYEE_COURSE_OVERRIDE",
  DELETE_EMPLOYEE_COURSE_ASK_CONFIRMATION:
    "DELETE_EMPLOYEE_COURSE_ASK_CONFIRMATION",
  GET_PREREQUISITE_EMPLOYEE_COURSES: "GET_PREREQUISITE_EMPLOYEE_COURSES",

  /**
   * Employee Course Attendance
   */
  GET_EMPLOYEE_COURSES_BY_ROLE: "GET_EMPLOYEE_COURSES_BY_ROLE",
  DELETE_EMPLOYEE_COURSE_ATTENDANCE: "DELETE_EMPLOYEE_COURSE_ATTENDANCE",
  DELETE_EMPLOYEE_COURSE_ATTENDANCE_ASK_CONFIRMATION:
    "DELETE_EMPLOYEE_COURSE_ATTENDANCE_ASK_CONFIRMATION",
  GET_EMPLOYEE_COURSES_ATTENDANCES_APPROVAL:
    "GET_EMPLOYEE_COURSES_ATTENDANCES_APPROVAL",
  UPDATE_EMPLOYEE_COURSE_ATTENDANCE_APPROVAL:
    "UPDATE_EMPLOYEE_COURSE_ATTENDANCE_APPROVAL",
  REMOVE_EMPLOYEE_COURSE_ATTENDANCE_APPROVAL:
    "REMOVE_EMPLOYEE_COURSE_ATTENDANCE_APPROVAL",
  GET_EMPLOYEE_COURSE_ATTENDANCES: "GET_EMPLOYEE_COURSE_ATTENDANCES",
  CREATE_EMPLOYEE_COURSE_ATTENDANCE_OVERRIDE:
    "CREATE_EMPLOYEE_COURSE_ATTENDANCE_OVERRIDE",
  CREATE_EMPLOYEE_COURSE_ATTENDANCE: "CREATE_EMPLOYEE_COURSE_ATTENDANCE",
  CREATE_AND_APPROVE_EMPLOYEE_COURSE_ATTENDANCE:
    "CREATE_AND_APPROVE_EMPLOYEE_COURSE_ATTENDANCE",
  TOGGLE_ROLE_EMPLOYEE_COURSE_OVERRIDE: "TOGGLE_ROLE_EMPLOYEE_COURSE_OVERRIDE",
  TOGGLE_ROLE_EMPLOYEE_SKILL_OVERRIDE: "TOGGLE_ROLE_EMPLOYEE_SKILL_OVERRIDE",

  /**
   * Employee
   */
  CLEAR_EMPLOYEES: "CLEAR_EMPLOYEES",
  GET_EMPLOYEES: "GET_EMPLOYEES",
  GET_MY_TEAM_EMPLOYEES_BY_USER: "GET_MY_TEAM_EMPLOYEES_BY_USER",
  GET_EMPLOYEE: "GET_EMPLOYEE",
  GET_EMPLOYEES_BY_COURSE: "GET_EMPLOYEES_BY_COURSE",
  GET_EMPLOYEE_SERVICES: "GET_EMPLOYEE_SERVICES",

  /**
   * Role
   */
  CLEAR_ROLES: "CLEAR_ROLES",
  CREATE_ROLE: "CREATE_ROLE",
  DELETE_ROLE: "DELETE_ROLE",
  DELETE_ROLE_ASK_CONFIRMATION: "DELETE_ROLE_ASK_CONFIRMATION",
  GET_ROLE_WITH_PARENTS: "GET_ROLE_WITH_PARENTS",
  GET_ROLES: "GET_ROLES",
  GET_ROLES_MEMBER_COUNT: "GET_ROLES_MEMBER_COUNT",
  GET_ROLES_BY_USER: "GET_ROLES_BY_USER",
  GET_MY_TEAM_ROLES_MEMBER_COUNT: "GET_MY_TEAM_ROLES_MEMBER_COUNT",
  UPDATE_ROLE: "UPDATE_ROLE",
  GET_ROLES_BY_COURSE: "GET_ROLES_BY_COURSE",
  GET_ROLES_BY_SKILL: "GET_ROLES_BY_SKILL",
  GET_ROLE: "GET_ROLE",
  GET_AVAILABLE_EMPLOYEES_FOR_ROLE: "GET_AVAILABLE_EMPLOYEES_FOR_ROLE",
  GET_AVAILABLE_COURSES_FOR_ROLE: "GET_AVAILABLE_COURSES_FOR_ROLE",
  GET_AVAILABLE_SKILLS_FOR_ROLE: "GET_AVAILABLE_SKILLS_FOR_ROLE",

  /**
   * Employee Role
   */
  GET_EMPLOYEE_ROLES: "GET_EMPLOYEE_ROLES",
  CREATE_EMPLOYEE_ROLES: "CREATE_EMPLOYEE_ROLES",
  DELETE_EMPLOYEE_ROLE: "DELETE_EMPLOYEE_ROLE",
  DELETE_EMPLOYEE_ROLE_ASK_CONFIRMATION:
    "DELETE_EMPLOYEE_ROLE_ASK_CONFIRMATION",
  GET_AVAILABLE_ROLES_FOR_EMPLOYEE: "GET_AVAILABLE_ROLES_FOR_EMPLOYEE",
  /**
   * RoleCourse
   */
  CLEAR_ROLE_COURSES: "CLEAR_ROLE_COURSES",
  CREATE_ROLE_COURSES: "CREATE_ROLE_COURSE",
  DELETE_ROLE_COURSE: "DELETE_ROLE_COURSE",
  DELETE_ROLE_COURSE_ASK_CONFIRMATION: "DELETE_ROLE_COURSE_ASK_CONFIRMATION",
  GET_ROLE_COURSES_BY_ROLE: "GET_ROLE_COURSES_BY_ROLE",
  UPDATE_ROLE_COURSE: "UPDATE_ROLE_COURSE",

  /**
   * RoleEmployee
   */
  CLEAR_ROLE_EMPLOYEES: "CLEAR_ROLE_EMPLOYEES",
  CREATE_ROLE_EMPLOYEE: "CREATE_ROLE_EMPLOYEE",
  DELETE_ROLE_EMPLOYEE: "DELETE_ROLE_EMPLOYEE",
  DELETE_ROLE_EMPLOYEE_ASK_CONFIRMATION:
    "DELETE_ROLE_EMPLOYEE_ASK_CONFIRMATION",
  GET_ROLE_EMPLOYEES: "GET_ROLE_EMPLOYEES",
  GET_MY_TEAM_ROLE_EMPLOYEES_BY_ROLE: "GET_MY_TEAM_ROLE_EMPLOYEES_BY_ROLE",

  /**
   * Users
   */
  CLEAR_USERS: "CLEAR_USERS",
  GET_USERS: "GET_USERS",
  UPDATE_USER: "UDPATE_USER",

  /**
   * Employee Course Attendance File
   */
  UPLOAD_EMPLOYEE_COURSE_ATTENDANCE_FILE:
    "UPLOAD_EMPLOYEE_COURSE_ATTENDANCE_FILE",
  DELETE_EMPLOYEE_COURSE_ATTENDANCE_FILE:
    "DELETE_EMPLOYEE_COURSE_ATTENDANCE_FILE",
  DELETE_EMPLOYEE_COURSE_ATTENDANCE_FILE_ASK_CONFIRMATION:
    "DELETE_EMPLOYEE_COURSE_ATTENDANCE_FILE_ASK_CONFIRMATION",

  /**
   * Education File
   */
  UPLOAD_EDUCATION_FILE: "UPLOAD_EDUCATION_FILE",
  DELETE_EDUCATION_FILE: "DELETE_EDUCATION_FILE",
  DELETE_EDUCATION_FILE_ASK_CONFIRMATION:
    "DELETE_EDUCATION_FILE_ASK_CONFIRMATION",

  /**
   * Work History File
   */
  UPLOAD_WORK_HISTORY_FILE: "UPLOAD_WORK_HISTORY_FILE",
  DELETE_WORK_HISTORY_FILE: "DELETE_WORK_HISTORY_FILE",
  DELETE_WORK_HISTORY_FILE_ASK_CONFIRMATION:
    "DELETE_WORK_HISTORY_FILE_ASK_CONFIRMATION",

  /**
   * Employee Education
   */
  GET_EMPLOYEE_EDUCATION_ITEMS: "GET_EMPLOYEE_EDUCATION_ITEMS",
  GET_EMPLOYEE_EDUCATION_ITEM: "GET_EMPLOYEE_EDUCATION_ITEM",
  CREATE_EMPLOYEE_EDUCATION_ITEM: "CREATE_EMPLOYEE_EDUCATION_ITEM",
  CREATE_AND_APPROVE_EMPLOYEE_EDUCATION_ITEM:
    "CREATE_AND_APPROVE_EMPLOYEE_EDUCATION_ITEM",
  UPDATE_EMPLOYEE_EDUCATION_ITEM: "UPDATE_EMPLOYEE_EDUCATION_ITEM",
  UPDATE_EMPLOYEE_EDUCATION_APPROVAL: "UPDATE_EMPLOYEE_EDUCATION_APPROVAL",
  REMOVE_EMPLOYEE_EDUCATION_APPROVAL: "REMOVE_EMPLOYEE_EDUCATION_APPROVAL",
  DELETE_EMPLOYEE_EDUCATION_ITEM: "DELETE_EMPLOYEE_EDUCATION_ITEM",
  DELETE_EMPLOYEE_EDUCATION_ITEM_ASK_CONFIRMATION:
    "DELETE_EMPLOYEE_EDUCATION_ITEM_ASK_CONFIRMATION",
  DELETE_EMPLOYEE_EDUCATION_ITEMS_ASK_CONFIRMATION:
    "DELETE_EMPLOYEE_EDUCATION_ITEMS_ASK_CONFIRMATION",

  /**
   * Employee Experience
   */
  GET_EMPLOYEE_EXPERIENCE_RECORDS: "GET_EMPLOYEE_EXPERIENCE_RECORDS",
  GET_EMPLOYEE_EXPERIENCE_RECORDS_FOR_REPORT:
    "GET_EMPLOYEE_EXPERIENCE_RECORDS_FOR_REPORT",

  /*
   * WorkHistoryCategory
   */
  GET_WORK_HISTORY_CATEGORIES: "GET_WORK_HISTORY_CATEGORIES",

  /**
   * WorkHistory
   */
  CLEAR_WORK_HISTORY: "CLEAR_WORK_HISTORY",
  CREATE_WORK_HISTORY: "CREATE_WORK_HISTORY",
  CREATE_AND_APPROVE_WORK_HISTORY: "CREATE_AND_APPROVE_WORK_HISTORY",
  DELETE_WORK_HISTORY: "DELETE_WORK_HISTORY",
  DELETE_WORK_HISTORY_ITEM_ASK_CONFIRMATION:
    "DELETE_WORK_HISTORY_ITEM_ASK_CONFIRMATION",
  DELETE_WORK_HISTORY_ITEMS_ASK_CONFIRMATION:
    "DELETE_WORK_HISTORY_ITEMS_ASK_CONFIRMATION",
  GET_WORK_HISTORY: "GET_WORK_HISTORY",
  UPDATE_WORK_HISTORY: "UPDATE_WORK_HISTORY",
  UPDATE_WORK_HISTORY_APPROVAL: "UPDATE_WORK_HISTORY_APPROVAL",
  REMOVE_WORK_HISTORY_APPROVAL: "REMOVE_WORK_HISTORY_APPROVAL",

  /**
   * Education Category
   */
  GET_EDUCATION_CATEGORIES: "GET_EDUCATION_CATEGORIES",

  /**
   * Employee CV
   */
  GET_EMPLOYEE_CV: "GET_EMPLOYEE_CV",
  GET_EMPLOYEES_CVS: "GET_EMPLOYEES_CVS",

  /**
   * Gap Report Modal Select
   */
  GAP_REPORT_SELECT_EMPLOYEES: "GAP_REPORT_SELECT_EMPLOYEES",

  /**
   * Role Gap Report
   */
  GET_EMPLOYEE_ROLE_GAP_REPORT: "GET_EMPLOYEE_ROLE_GAP_REPORT",
  CLEAR_EMPLOYEE_ROLE_GAP_REPORT: "CLEAR_EMPLOYEE_ROLE_GAP_REPORT",

  UPDATE_GAP_REPORT_OPTIONS: "UPDATE_GAP_REPORT_OPTIONS",
  GET_ROLE_GAP_REPORT: "GET_ROLE_GAP_REPORT",
  CLEAR_ROLE_GAP_REPORT: "CLEAR_ROLE_GAP_REPORT",
  DOWNLOAD_ROLE_GAP_REPORT: "DOWNLOAD_ROLE_GAP_REPORT",

  /**
   * Course Gap Report
   */
  GET_COURSE_GAP_REPORT: "GET_COURSE_GAP_REPORT",
  CLEAR_COURSE_GAP_REPORT: "CLEAR_COURSE_GAP_REPORT",
  DOWNLOAD_COURSE_GAP_REPORT: "DOWNLOAD_COURSE_GAP_REPORT",

  /**
   * Course Group
   */
  GET_COURSE_GROUPS: "GET_COURSE_GROUPS",
  GET_COURSE_GROUP: "GET_COURSE_GROUP",
  UPDATE_COURSE_GROUP: "UPDATE_COURSE_GROUP",
  CREATE_COURSE_GROUP: "CREATE_COURSE_GROUP",
  DELETE_COURSE_GROUP: "DELETE_COURSE_GROUP",
  DELETE_COURSE_GROUP_ASK_CONFIRMATION: "DELETE_COURSE_GROUP_ASK_CONFIRMATION",

  /**
   * Course Type
   */
  GET_COURSE_TYPES: "GET_COURSE_TYPES",
  GET_COURSE_TYPE: "GET_COURSE_TYPE",
  UPDATE_COURSE_TYPE: "UPDATE_COURSE_TYPE",
  CREATE_COURSE_TYPE: "CREATE_COURSE_TYPE",
  DELETE_COURSE_TYPE: "DELETE_COURSE_TYPE",
  DELETE_COURSE_TYPE_ASK_CONFIRMATION: "DELETE_COURSE_TYPE_ASK_CONFIRMATION",

  /**
   * Approval
   */
  GET_EMPLOYEE_COURSES_APPROVAL: "GET_EMPLOYEE_COURSES_APPROVAL",
  EMPLOYEE_COURSE_APPROVAL_ON_CHANGE: "EMPLOYEE_COURSE_APPROVAL_ON_CHANGE",

  GET_EMPLOYEE_EDUCATION_APPROVAL: "GET_EMPLOYEE_EDUCATION_APPROVAL",
  EDUCATION_APPROVAL_ON_CHANGE: "EDUCATION_APPROVAL_ON_CHANGE",

  GET_EMPLOYEE_WORK_HISTORY_APPROVAL: "GET_EMPLOYEE_WORK_HISTORY_APPROVAL",
  WORK_HISTORY_APPROVAL_ON_CHANGE: "WORK_HISTORY_APPROVAL_ON_CHANGE",

  /**
   * Skill Group
   */
  GET_SKILL_GROUPS: "GET_SKILL_GROUPS",
  GET_SKILL_GROUP: "GET_SKILL_GROUP",
  UPDATE_SKILL_GROUP: "UPDATE_SKILL_GROUP",
  CREATE_SKILL_GROUP: "CREATE_SKILL_GROUP",
  DELETE_SKILL_GROUP: "DELETE_SKILL_GROUP",
  DELETE_SKILL_GROUP_ASK_CONFIRMATION: "DELETE_SKILL_GROUP_ASK_CONFIRMATION",

  /**
   * Skill Type
   */
  GET_SKILL_TYPES: "GET_SKILL_TYPES",
  GET_SKILL_TYPE: "GET_SKILL_TYPE",
  CREATE_SKILL_TYPE: "CREATE_SKILL_TYPE",
  UPDATE_SKILL_TYPE: "UPDATE_SKILL_TYPE",
  DELETE_SKILL_TYPE: "DELETE_SKILL_TYPE",
  DELETE_SKILL_TYPE_ASK_CONFIRMATION: "DELETE_SKILL_TYPE_ASK_CONFIRMATION",

  /**
   * EXPERIENCE
   */
  GET_EXPERIENCE_RECORD_AVAILABLE_POSITIONS:
    "GET_EXPERIENCE_RECORD_AVAILABLE_POSITIONS",
  GET_EXPERIENCE_RECORD_AVAILABLE_VALIDATORS:
    "GET_EXPERIENCE_RECORD_AVAILABLE_VALIDATORS",
  GET_EXPERIENCE_DISCIPLINES: "GET_EXPERIENCE_DISCIPLINES",
  GET_EXPERIENCE_RECORD: "GET_EXPERIENCE_RECORD",
  GET_EXPERIENCE_RECORDS: "GET_EXPERIENCE_RECORDS",
  GET_EXPERIENCE_RECORDS_BY_COMPETENCY_STATUS:
    "GET_EXPERIENCE_RECORDS_BY_COMPETENCY_STATUS",
  CREATE_EXPERIENCE_RECORDS: "CREATE_EXPERIENCE_RECORDS",
  UPDATE_EXPERIENCE_RECORD: "UPDATE_EXPERIENCE_RECORD",
  SUBMIT_EXPERIENCE_RECORD: "SUBMIT_EXPERIENCE_RECORD",
  CLEAR_EXPERIENCE_RECORDS: "CLEAR_EXPERIENCE_RECORDS",
  GET_EXPERIENCE_RECORD_ITEMS: "GET_EXPERIENCE_RECORD_ITEMS",
  GET_EMPLOYEE_ALL_EXPERIENCE_RECORD_ITEMS:
    "GET_EMPLOYEE_ALL_EXPERIENCE_RECORD_ITEMS",
  GET_EMPLOYEE_FILTERED_EXPERIENCE_RECORD_ITEMS:
    "GET_EMPLOYEE_FILTERED_EXPERIENCE_RECORD_ITEMS",
  GET_EMPLOYEE_ALL_EXPERIENCE_RECORD_CATEGORIES:
    "GET_EMPLOYEE_ALL_EXPERIENCE_RECORD_CATEGORIES",
  UPDATE_EXPERIENCE_RECORD_ITEM: "UPDATE_EXPERIENCE_RECORD_ITEM",
  CLEAR_EXPERIENCES_RECORD_ITEMS: "CLEAR_EXPERIENCES_RECORD_ITEMS",
  GET_ALL_EXPERIENCE_RECORD_CATEGORIES: "GET_ALL_EXPERIENCE_RECORD_CATEGORIES",
  GET_EMPLOYEE_EXPERIENCE_RECORD_CATEGORIES_FOR_REPORT:
    "GET_EMPLOYEE_EXPERIENCE_RECORD_CATEGORIES_FOR_REPORT",
  GET_EXPERIENCE_RECORD_CATEGORIES: "GET_EXPERIENCE_RECORD_CATEGORIES",
  CLEAR_EXPERIENCES_RECORD_CATEGORIES: "CLEAR_EXPERIENCES_RECORD_CATEGORIES",
  DELETE_EXPERIENCE_RECORD: "DELETE_EXPERIENCE_RECORD",
  DELETE_EXPERIENCE_RECORD_ASK_CONFIRMATION:
    "DELETE_EXPERIENCE_RECORD_ASK_CONFIRMATION",
  DELETE_EXPERIENCE_RECORDS: "DELETE_EXPERIENCE_RECORDS",
  DELETE_EXPERIENCE_RECORDS_ASK_CONFIRMATION:
    "DELETE_EXPERIENCE_RECORDS_ASK_CONFIRMATION",
  GENERATE_EMPLOYEE_EXPERIENCE_RECORDS_REPORT:
    "GENERATE_EMPLOYEE_EXPERIENCE_RECORDS_REPORT",

  /**
   * EXPERIENCE_APPROVAL
   */
  REJECT_EXPERIENCE_RECORD: "REJECT_EXPERIENCE_RECORD",
  APPROVE_EXPERIENCE_RECORD: "APPROVE_EXPERIENCE_RECORD",
  UNDO_APPROVE_EXPERIENCE_RECORD: "UNDO_APPROVE_EXPERIENCE_RECORD",
  GET_PENDING_EXPERIENCE_RECORDS_FOR_VALIDATOR:
    "GET_PENDING_EXPERIENCE_RECORDS_FOR_VALIDATOR",
  CHANGE_EXPERIENCE_RECORD_VALIDATOR: "CHANGE_EXPERIENCE_RECORD_VALIDATOR",

  /**
   * Skill
   */
  GET_SKILLS: "GET_SKILLS",
  GET_SKILL: "GET_SKILL",
  CLEAR_SKILLS: "CLEAR_SKILLS",
  CREATE_SKILL: "CREATE_SKILL",
  DELETE_SKILL: "DELETE_SKILL",
  DELETE_SKILL_ASK_CONFIRMATION: "DELETE_SKILL_ASK_CONFIRMATION",
  DELETE_SKILLS: "DELETE_SKILLS",
  DELETE_SKILL_LIST_ASK_CONFIRMATION: "DELETE_SKILL_LIST_ASK_CONFIRMATION",
  UPDATE_SKILL: "UPDATE_SKILL",

  /**
   * RoleSkill
   */
  CLEAR_ROLE_SKILLS: "CLEAR_ROLE_SKILLS",
  CREATE_ROLE_SKILLS: "CREATE_ROLE_SKILL",
  DELETE_ROLE_SKILL: "DELETE_ROLE_SKILL",
  DELETE_ROLE_SKILL_ASK_CONFIRMATION: "DELETE_ROLE_SKILL_ASK_CONFIRMATION",
  GET_ROLE_SKILLS_WITH_PARENTS_BY_ROLE: "GET_ROLE_SKILLS_WITH_PARENTS_BY_ROLE",
  UPDATE_ROLE_SKILL: "UPDATE_ROLE_SKILL",

  /**
   * Employee Skill
   */
  GET_EMPLOYEE_ROLE_SKILLS_GROUPED_BY_ROLE:
    "GET_EMPLOYEE_ROLE_SKILLS_GROUPED_BY_ROLE",
  CREATE_EMPLOYEE_SKILL: "CREATE_EMPLOYEE_SKILL",
  DELETE_EMPLOYEE_SKILL: "DELETE_EMPLOYEE_SKILL",
  MY_TEAM_APPROVAL_ON_LOAD: "MY_TEAM_APPROVAL_ON_LOAD",
  GET_MY_TEAM_FILE_EVIDENCES_NEEDING_APPROVAL:
    "GET_MY_TEAM_FILE_EVIDENCES_NEEDING_APPROVAL",
  UPDATE_EMPLOYEE_SKILL_FILE_EVIDENCE_APPROVAL:
    "UPDATE_EMPLOYEE_SKILL_FILE_EVIDENCE_APPROVAL",
  REMOVE_EMPLOYEE_SKILL_FILE_EVIDENCE_APPROVAL:
    "REMOVE_EMPLOYEE_SKILL_FILE_EVIDENCE_APPROVAL",
  GET_EMPLOYEE_SKILL: "GET_EMPLOYEE_SKILL",
  GET_EMPLOYEE_SKILLS_BY_SKILL: "GET_EMPLOYEE_SKILLS_BY_SKILL",
  GET_EMPLOYEE_SKILL_FILE_EVIDENCES: "GET_EMPLOYEE_SKILL_FILE_EVIDENCES",
  GET_EMPLOYEE_SKILL_EVIDENCES: "GET_EMPLOYEE_SKILL_EVIDENCES",
  DELETE_EMPLOYEE_SKILL_FILE_EVIDENCE: "DELETE_EMPLOYEE_SKILL_FILE_EVIDENCE",
  DELETE_EMPLOYEE_SKILL_FILE_EVIDENCE_ASK_CONFIRMATION:
    "DELETE_EMPLOYEE_SKILL_FILE_EVIDENCE_ASK_CONFIRMATION",
  DELETE_EMPLOYEE_SKILL_EVIDENCE: "DELETE_EMPLOYEE_SKILL_EVIDENCE",
  DELETE_EMPLOYEE_SKILL_EVIDENCE_ASK_CONFIRMATION:
    "DELETE_EMPLOYEE_SKILL_EVIDENCE_ASK_CONFIRMATION",

  /**
   * Skill Gap Report
   */
  GET_SKILL_GAP_REPORT: "GET_SKILL_GAP_REPORT",
  CLEAR_SKILL_GAP_REPORT: "CLEAR_SKILL_GAP_REPORT",
  DOWNLOAD_SKILL_GAP_REPORT: "DOWNLOAD_SKILL_GAP_REPORT",

  /**
   * Specifications
   */
  GET_SPECIFICATIONS: "GET_SPECIFICATIONS",
  GET_SPECIFICATION: "GET_SPECIFICATION",
  GET_AVAILABLE_SKILLS_FOR_SPECIFICATION:
    "GET_AVAILABLE_SKILLS_FOR_SPECIFICATION",
  GET_AVAILABLE_COURSES_FOR_SPECIFICATION:
    "GET_AVAILABLE_COURSES_FOR_SPECIFICATION",
  GET_SPECIFICATION_WITH_PARENTS: "GET_SPECIFICATION_WITH_PARENTS",
  CREATE_SPECIFICATION: "CREATE_SPECIFICATION",
  UPDATE_SPECIFICATION: "UPDATE_SPECIFICATION",
  DELETE_SPECIFICATION: "DELETE_SPECIFICATION",
  DELETE_SPECIFICATION_ASK_CONFIRMATION:
    "DELETE_SPECIFICATION_ASK_CONFIRMATION",
  CLEAR_SPECIFICATIONS: "CLEAR_SPECIFICATIONS",

  /**
   * SpecificationCourses
   */
  GET_SPECIFICATION_COURSES_BY_SPECIFICATION_WITH_PARENTS:
    "GET_SPECIFICATION_COURSES_BY_SPECIFICATION_WITH_PARENTS",
  CREATE_SPECIFICATION_COURSES: "CREATE_SPECIFICATION_COURSE",
  DELETE_SPECIFICATION_COURSE: "DELETE_SPECIFICATION_COURSE",
  DELETE_SPECIFICATION_COURSE_ASK_CONFIRMATION:
    "DELETE_SPECIFICATION_COURSE_ASK_CONFIRMATION",
  CLEAR_SPECIFICATION_COURSES: "CLEAR_SPECIFICATION_COURSES",
  UPDATE_SPECIFICATION_COURSE: "UPDATE_SPECIFICATION_COURSE",

  /**
   * SpecificationSkills
   */
  GET_SPECIFICATION_SKILLS_BY_SPECIFICATION_WITH_PARENTS:
    "GET_SPECIFICATION_SKILLS_BY_SPECIFICATION_WITH_PARENTS",
  CREATE_SPECIFICATION_SKILLS: "CREATE_SPECIFICATION_SKILL",
  DELETE_SPECIFICATION_SKILL: "DELETE_SPECIFICATION_SKILL",
  DELETE_SPECIFICATION_SKILL_ASK_CONFIRMATION:
    "DELETE_SPECIFICATION_SKILL_ASK_CONFIRMATION",
  CLEAR_SPECIFICATION_SKILLS: "CLEAR_SPECIFICATION_SKILLS",
  UPDATE_SPECIFICATION_SKILL: "UPDATE_SPECIFICATION_SKILL",

  /**
   * Specification Gap Report
   */
  GET_SPECIFICATION_GAP_REPORT: "GET_SPECIFICATION_GAP_REPORT",
  CLEAR_SPECIFICATION_GAP_REPORT: "CLEAR_SPECIFICATION_GAP_REPORT",
  DOWNLOAD_SPECIFICATION_GAP_REPORT: "DOWNLOAD_SPECIFICATION_GAP_REPORT",

  /**
   * Services
   */
  GET_SERVICES: "GET_SERVICES",
  GET_SERVICE: "GET_SERVICE",
  GET_AVAILABLE_SKILLS_FOR_SERVICE: "GET_AVAILABLE_SKILLS_FOR_SERVICE",
  GET_AVAILABLE_COURSES_FOR_SERVICE: "GET_AVAILABLE_COURSES_FOR_SERVICE",
  GET_SERVICE_WITH_PARENTS: "GET_SERVICE_WITH_PARENTS",
  CREATE_SERVICE: "CREATE_SERVICE",
  UPDATE_SERVICE: "UPDATE_SERVICE",
  DELETE_SERVICE: "DELETE_SERVICE",
  DELETE_SERVICE_ASK_CONFIRMATION: "DELETE_SERVICE_ASK_CONFIRMATION",
  CLEAR_SERVICES: "CLEAR_SERVICES",

  /**
   * ServiceCourses
   */
  GET_SERVICE_COURSES_BY_SERVICE_WITH_PARENTS:
    "GET_SERVICE_COURSES_BY_SERVICE_WITH_PARENTS",
  CREATE_SERVICE_COURSES: "CREATE_SERVICE_COURSE",
  DELETE_SERVICE_COURSE: "DELETE_SERVICE_COURSE",
  DELETE_SERVICE_COURSE_ASK_CONFIRMATION:
    "DELETE_SERVICE_COURSE_ASK_CONFIRMATION",
  CLEAR_SERVICE_COURSES: "CLEAR_SERVICE_COURSES",
  UPDATE_SERVICE_COURSE: "UPDATE_SERVICE_COURSE",

  /**
   * ServiceSkills
   */
  GET_SERVICE_SKILLS_BY_SERVICE_WITH_PARENTS:
    "GET_SERVICE_SKILLS_BY_SERVICE_WITH_PARENTS",
  CREATE_SERVICE_SKILLS: "CREATE_SERVICE_SKILL",
  DELETE_SERVICE_SKILL: "DELETE_SERVICE_SKILL",
  DELETE_SERVICE_SKILL_ASK_CONFIRMATION:
    "DELETE_SERVICE_SKILL_ASK_CONFIRMATION",
  CLEAR_SERVICE_SKILLS: "CLEAR_SERVICE_SKILLS",
  UPDATE_SERVICE_SKILL: "UPDATE_SERVICE_SKILL",

  /**
   * Service Gap Report
   */
  GET_SERVICE_GAP_REPORT: "GET_SERVICE_GAP_REPORT",
  CLEAR_SERVICE_GAP_REPORT: "CLEAR_SERVICE_GAP_REPORT",
  DOWNLOAD_SERVICE_GAP_REPORT: "DOWNLOAD_SERVICE_GAP_REPORT",

  /**
   * Training History Report
   */
  GET_TRAINING_HISTORY_REPORT: "GET_TRAINING_HISTORY_REPORT",
  CLEAR_TRAINING_HISTORY_REPORT: "CLEAR_TRAINING_HISTORY_REPORT",
  DOWNLOAD_TRAINING_HISTORY_REPORT: "DOWNLOAD_TRAINING_HISTORY_REPORT",

  /**
   * Training And Competency Report
   */
  GET_TRAINING_AND_COMPETENCY_REPORT: "GET_TRAINING_AND_COMPETENCY_REPORT",
  CLEAR_TRAINING_AND_COMPETENCY_REPORT: "CLEAR_TRAINING_AND_COMPETENCY_REPORT",
  DOWNLOAD_TRAINING_AND_COMPETENCY_REPORT:
    "DOWNLOAD_TRAINING_AND_COMPETENCY_REPORT",

  /**
   * Course Expiry Report
   */
  GET_COURSE_EXPIRY_REPORT: "GET_COURSE_EXPIRY_REPORT",
  CLEAR_COURSE_EXPIRY_REPORT: "CLEAR_COURSE_EXPIRY_REPORT",
  DOWNLOAD_COURSE_EXPIRY_REPORT: "DOWNLOAD_COURSE_EXPIRY_REPORT",

  /**
   *Course  Training History Report
   */
  GET_COURSE_TRAINING_HISTORY_REPORT: "GET_COURSE_TRAINING_HISTORY_REPORT",
  CLEAR_COURSE_TRAINING_HISTORY_REPORT: "CLEAR_COURSE_TRAINING_HISTORY_REPORT",
  DOWNLOAD_COURSE_TRAINING_HISTORY_REPORT:
    "DOWNLOAD_COURSE_TRAINING_HISTORY_REPORT",

  /**
   * Outstanding  Training History Report
   */
  GET_OUTSTANDING_TRAINING_HISTORY_REPORT:
    "GET_OUTSTANDING_TRAINING_HISTORY_REPORT",
  CLEAR_OUTSTANDING_TRAINING_HISTORY_REPORT:
    "CLEAR_OUTSTANDING_TRAINING_HISTORY_REPORT",
  DOWNLOAD_OUTSTANDING_TRAINING_HISTORY_REPORT:
    "DOWNLOAD_OUTSTANDING_TRAINING_HISTORY_REPORT",

  /**
   * Misc
   */
  ASK_CONFIRMATION: "ASK_CONFIRMATION", // TODO: Update to latest altus-redux-middlewares to avoid creating dummy action
  EMPLOYEE_COURSES_ON_LOAD: "EMPLOYEE_COURSES_ON_LOAD",
  APPROVAL_ON_LOAD: "APPROVAL_ON_LOAD",
  GET_ALL_APP_ROLES: "GET_ALL_APP_ROLES",
  CREATE_USER_APP_ROLES: "CREATE_USER_APP_ROLES",
  GET_COUNTRIES: "GET_COUNTRIES",
  GET_APPLICATION_FEATURES: "GET_APPLICATION_FEATURES",
  GET_ORGANIZATIONS: "GET_ORGANIZATIONS",
  GET_FACILITIES: "GET_FACILITIES",

  UPLOAD_EMPLOYEE_SKILL_EVIDENCE_FILE: "UPLOAD_EMPLOYEE_SKILL_EVIDENCE_FILE",
  CREATE_SKILL_EVIDENCE: "CREATE_SKILL_EVIDENCE",

  UPLOAD_AND_APPROVE_EMPLOYEE_SKILL_EVIDENCE_FILE:
    "UPLOAD_EMPLOYEE_SKILL_EVIDENCE_FILE",
  GET_HR_STATS: "GET_HR_STATS",
  GET_MY_TEAM_STATS: "GET_MY_TEAM_STATS",
  GET_VERIFIER_STATS: "GET_VERIFIER_STATS",
  GET_CURRENT_EMPLOYEE_STATS: "GET_CURRENT_EMPLOYEE_STATS",
  GET_SETTINGS_STATS: "GET_SETTINGS_STATS",
  GET_STATS_FOR_ROLE: "GET_STATS_FOR_ROLE",
  GET_STATS_FOR_ROLE_MEMBERS: "GET_STATS_FOR_ROLE_MEMBERS",
  GET_STATS_FOR_ROLES: "GET_STATS_FOR_ROLES",
  GET_STATS_FOR_MY_TEAM_ROLE: "GET_STATS_FOR_MY_TEAM_ROLE",
  GET_STATS_FOR_MY_TEAM_ROLES: "GET_STATS_FOR_MY_TEAM_ROLES",
  GET_STATS_FOR_EMPLOYEE_ROLES: "GET_STATS_FOR_EMPLOYEE_ROLES",
  GET_STATS_FOR_EMPLOYEE_ROLE: "GET_STATS_FOR_EMPLOYEE_ROLE",
  GET_STATS_FOR_MY_TEAM_ROLE_MEMBERS: "GET_STATS_FOR_MY_TEAM_ROLE_MEMBERS",
  GET_STATS_FOR_MY_TEAM_EMPLOYEES: "GET_STATS_FOR_MY_TEAM_EMPLOYEES",
  GET_STATS_FOR_EMPLOYEES: "GET_STATS_FOR_EMPLOYEES",
  GET_STATS_FOR_COURSES: "GET_STATS_FOR_COURSES",
  GET_STATS_FOR_COURSE: "GET_STATS_FOR_COURSE",
  GET_STATS_FOR_SKILLS: "GET_STATS_FOR_SKILLS",
  GET_STATS_FOR_SKILL: "GET_STATS_FOR_SKILL",
  GET_STATS_FOR_EMPLOYEE: "GET_STATS_FOR_EMPLOYEE",
  GET_STATS_FOR_SPECIFICATIONS: "GET_STATS_FOR_SPECIFICATIONS",
  GET_STATS_FOR_SPECIFICATION: "GET_STATS_FOR_SPECIFICATION",
  GET_STATS_FOR_COLLECTIONS: "GET_STATS_FOR_COLLECTIONS",
  GET_STATS_FOR_COLLECTION: "GET_STATS_FOR_COLLECTION",
  GET_STATS_FOR_SERVICES: "GET_STATS_FOR_SERVICES",
  GET_STATS_FOR_SERVICE: "GET_STATS_FOR_SERVICE",
  GET_STATS_FOR_EXPERIENCE_RECORD: "GET_STATS_FOR_EXPERIENCE_RECORD",
  GET_STATS_FOR_EXPERIENCE_RECORDS: "GET_STATS_FOR_EXPERIENCE_RECORDS",
  GET_STATS_FOR_EXPERIENCE_RECORDS_FOR_EMPLOYEE:
    "GET_STATS_FOR_EXPERIENCE_RECORDS_FOR_EMPLOYEE",
  GET_STATS_FOR_EXPERIENCE_RECORDS_FOR_VALIDATOR:
    "GET_STATS_FOR_EXPERIENCE_RECORDS_FOR_VALIDATOR",
  GET_STATS_FOR_EXPERIENCE_RECORDS_BY_STATUS:
    "GET_STATS_FOR_EXPERIENCE_RECORDS_BY_STATUS",
};

export const APP_ROLES = {
  HR_ASSESSOR_VERIFIER: "HRCompetencyAssessorVerifier",
  EMPLOYEE: "HRCompetencyEmployee",
  MANAGER: "HRCompetencyManager",
  HR_SCANDINAVIA: "HRCompetencyHRScandinavia",
  ADMIN: "HRCompetencyAdmin",
};

export const APP_ROLES_HR_AND_ADMIN = [
  APP_ROLES.HR_SCANDINAVIA,
  APP_ROLES.ADMIN,
];

export const SORT_DIRECTION = {
  ASC: "asc",
  DESC: "desc",
};

export const Format = {
  date: "YYYY-MM-DD",
  time: "YYYY-MM-DD HH:mm",
};

export const ICONS = {
  COURSE: ClassIcon,
  COURSE_CERTIFICATE: Certificate,
  DELETE: Delete,
  EDUCATION: School,
  HISTORY: History,
  REPORTS: BarChart,
  ROLE: VerifiedUser,
  TEAM: People,
  APPROVAL: Info,
  SKILL: Build,
  EXPERIENCES: EmojiEvents,
  NO_CONTENT: CloudOff,
  FILE_EVIDENCE: FingerprintIcon,
  SETTINGS: Settings,
  USER_ACCESS: Security,
  HOME: Home,
  EMPLOYEE: Person,
  EMPLOYEE_DETAILS: ArtTrack,
  HR_PORTAL: Business,
  COURSE_OVERRIDEN: ThumbUp,
  TREE_VIEW: AccountTreeIcon,
  LIST_VIEW: FormatAlignJustifyIcon,
  SPECIFICATION: FormatListBulletedIcon,
  SERVICE: ServiceHand,
  COLLECTION: Apps,
  TRAINING_HISTORY: SchoolIcon,
};

export const COMPETENCY_STATUS = {
  NONE: 0,
  MISSING_REQUIREMENTS: 1,
  EXPIRED: 2,
  NOT_APPROVED: 3,
  EXPIRES_SOON: 4,
  REQUIRES_APPROVAL: 5,
  UP_TO_DATE: 6,
  REJECTED: 7,
};

export const APPROVAL_STATUS = {
  NEEDS_APPROVAL: 0,
  NOT_APPROVED: 1,
  APPROVED: 2,
  REJECTED: 3,
};

export const GAP_REPORT = {
  ROLE_GAP_REPORT: 0,
  SKILL_GAP_REPORT: 1,
  COURSE_GAP_REPORT: 2,
  SERVICE_GAP_REPORT: 3,
  SPECIFICATION_GAP_REPORT: 4,
};

export const CRITICALITY = {
  CRITICAL: 0,
  NOT_CRITICAL: 1,
};

export const criticalityToString = memoize(
  criticality =>
    ({
      [CRITICALITY.CRITICAL]: "Critical",
      [CRITICALITY.NOT_CRITICAL]: "Not Critical",
    }[criticality]),
);

export const EMPTY_ARRAY = [];
export const EMPTY_LIST = List();
export const EMPTY_SET = Set();
export const EMPTY_MAP = Map();

export const HTTP_STATUS = {
  UNAUTHORIZED: 401,
};

export const FEATURE_FLAG = {
  SKILLS: "skills",
  SPECIFICATIONS: "specifications",
  SERVICES: "services",
  COLLECTIONS: "collections",
  EXPERIENCES: "experiences",
  REPORTS: "reports",
  EMPLOYEE_ROLE_GAP: "employee_role_gap",
  ExportPDF: "exportPDF",
};

export const APP_ROOT = {
  MY_PAGE: `/my-page`,
  MY_TEAM: `/my-team`,
  HR: `/hr-portal`,
};

export const APP_NAME = "Competence Planner";
