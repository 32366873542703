import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";

import { LoadingButton } from "altus-ui-components";

import AccessControl from "app/components/AccessControl";
import { ACTIONS, APP_ROLES_HR_AND_ADMIN } from "app/app.constants";
import { getEmployeeCourseAttendancesFromState } from "features/competence.selectors";
import { addEmployeeCourseAttendanceModalOnSubmit } from "features/competence.actions";
import { getSummarizedDataStatesFromState } from "app/app.selectors";

const AddEmployeeCourseCertificateModalActions = ({
  dataState,
  valid,
  pristine,
  courseId,
  employeeId,
  submitText,
  toggleModal,
  handleSubmit,
  isOverridden,
  employeeCourseAttendances,
  dispatchAddEmployeeCourseAttendance,
}) => {
  const approveOnCreate = true;
  const isSubmitting = dataState.isLoading();
  return (
    <Grid container justify="space-between">
      <Grid item>
        <AccessControl accessLevel={APP_ROLES_HR_AND_ADMIN}>
          <LoadingButton
            type="submit"
            color="primary"
            disabled={pristine || !valid || isSubmitting || isOverridden}
            loading={isSubmitting}
            variant="contained"
            onClick={handleSubmit(
              dispatchAddEmployeeCourseAttendance(
                employeeId,
                courseId,
                employeeCourseAttendances,
                approveOnCreate,
              )(toggleModal),
            )}
          >
            {`${submitText} & Approve`}
          </LoadingButton>
        </AccessControl>
      </Grid>
      <Grid item>
        <LoadingButton
          type="submit"
          color="primary"
          disabled={pristine || !valid || isSubmitting}
          loading={isSubmitting}
          variant="contained"
          onClick={handleSubmit(
            dispatchAddEmployeeCourseAttendance(
              employeeId,
              courseId,
              employeeCourseAttendances,
              !approveOnCreate,
            )(toggleModal),
          )}
        >
          {submitText}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default compose(
  connect(
    (state, { employeeId, courseId }) => ({
      employeeCourseAttendances: getEmployeeCourseAttendancesFromState(
        state,
        employeeId,
        courseId,
      ),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.CREATE_EMPLOYEE_COURSE_ATTENDANCE,
        ACTIONS.CREATE_AND_APPROVE_EMPLOYEE_COURSE_ATTENDANCE,
        ACTIONS.UPLOAD_EMPLOYEE_COURSE_ATTENDANCE_FILE,
      ),
    }),
    {
      dispatchAddEmployeeCourseAttendance: addEmployeeCourseAttendanceModalOnSubmit,
    },
  ),
)(AddEmployeeCourseCertificateModalActions);
