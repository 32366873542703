import { compose } from "redux";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";

import EmployeeDetailsForm from "features/components/EmployeeDetailsForm";
import { getEmployeeAndInitializeForm } from "features/competence.actions";
import { getActionDataStateFromState } from "app/app.selectors";
import { ACTIONS } from "app/app.constants";
import { BasePage } from "altus-ui-components";

class EmployeeDetailsContainer extends PureComponent {
  componentDidMount() {
    const { dispatchGetEmployeeAndInitializeForm, employeeId } = this.props;
    dispatchGetEmployeeAndInitializeForm(employeeId);
  }

  render() {
    const { employeeId, dataState } = this.props;

    return (
      <BasePage dataState={dataState} title="Details">
        <Grid container item xs>
          <Grid item md={9} xs={12}>
            <EmployeeDetailsForm employeeId={employeeId} />
          </Grid>
        </Grid>
      </BasePage>
    );
  }
}

export default compose(
  connect(
    state => ({
      dataState: getActionDataStateFromState(state, ACTIONS.GET_EMPLOYEE),
    }),
    {
      dispatchGetEmployeeAndInitializeForm: getEmployeeAndInitializeForm,
    },
  ),
)(EmployeeDetailsContainer);
