import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router/immutable";

import {
  thunkMiddleware,
  promiseMiddleware,
  confirmationMiddleware,
  createNotificationMiddleware,
} from "altus-redux-middlewares";

import history from "infrastructure/history";
import { onUnauthorized } from "app/app.actions";
import rootReducer from "app/reducers/rootReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const notificationMiddleware = createNotificationMiddleware({
  onUnauthorized: () => store.dispatch(onUnauthorized()),
});

const middlewares = [
  confirmationMiddleware,
  thunkMiddleware,
  routerMiddleware(history),
  promiseMiddleware,
  notificationMiddleware,
];

const store = createStore(
  rootReducer(history),
  composeEnhancers(applyMiddleware(...middlewares)),
);

export default store;
