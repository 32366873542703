import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { NavTabsContainer, RouteContainer } from "altus-ui-components";

import {
  getRoleWithParents,
  getStatsForRole,
} from "features/competence.actions";

import { EMPTY_MAP } from "app/app.constants";
import { setRouteCount, getRoleRouteCount } from "utils/route.util";
import { getRoleStatsFromState } from "features/competence.selectors";
import RoleProgressBar from "features/role/components/RoleProgressBar";
import RoleDetailsHeader from "features/role/components/RoleDetailsHeader";

class RoleContainer extends PureComponent {
  componentDidMount() {
    const {
      roleId,
      dispatchGetRoleStats,
      dispatchGetRoleWithParents,
    } = this.props;

    dispatchGetRoleStats(roleId);
    dispatchGetRoleWithParents(roleId);
  }

  render() {
    const { routes, roleStats, roleId } = this.props;

    return (
      <>
        <RoleDetailsHeader roleId={roleId}>
          <RoleProgressBar roleId={roleId} />
        </RoleDetailsHeader>
        <NavTabsContainer
          routes={routes.map(route =>
            setRouteCount(route, getRoleRouteCount(roleStats, route.key)),
          )}
        />
        <RouteContainer routes={routes} />
      </>
    );
  }
}

RoleContainer.defaultProps = {
  roleStats: EMPTY_MAP,
};

export default compose(
  connect(
    (state, { roleId }) => ({
      roleStats: getRoleStatsFromState(state, roleId),
    }),
    {
      dispatchGetRoleStats: getStatsForRole,
      dispatchGetRoleWithParents: getRoleWithParents,
    },
  ),
)(RoleContainer);
