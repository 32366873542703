import { compose } from "redux";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";

class Tab extends PureComponent {
  touchRippleProps = {
    classes: {
      child: this.props.classes.touchRipleRoot,
    },
  };

  render() {
    const {
      to,
      title,
      classes,
      onClick,
      selected,
      children,
      disabled,
    } = this.props;

    return (
      <ButtonBase
        to={to}
        component={to ? Link : undefined}
        disabled={disabled}
        onClick={onClick}
        TouchRippleProps={this.touchRippleProps}
        classes={{
          root: classNames(classes.item, {
            [classes.itemSelected]: selected,
          }),
          disabled: classes.buttonBaseDisabled,
        }}
      >
        {title}
        {children}
      </ButtonBase>
    );
  }
}

const styles = theme => ({
  item: {
    ...theme.typography.body2,
    fontWeight: 700,
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
    color: theme.palette.getContrastText(theme.palette.primary.light),
    padding: `${theme.spacing(1.5)}px ${theme.spacing(5)}px`,
    "&:hover": {
      background: "rgba(255, 255, 255, 0.4)",
    },
  },
  itemSelected: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.getContrastText(theme.palette.background.default),
    "&:hover": {
      backgroundColor: theme.palette.background.default,
    },
  },
  itemDisabled: {},
  touchRipleRoot: {
    backgroundColor: "rgba(0, 0, 0, 0.1)",
  },
  buttonBaseDisabled: {
    opacity: 0.6,
    fontWeight: 400,
  },
});

export default compose(withStyles(styles, { name: "Tab" }), withRouter)(Tab);
