import { compose } from "redux";
import React, { useMemo } from "react";
import { withStyles } from "@material-ui/core";

import { TableRowActionsCell } from "altus-ui-components";

import { EMPTY_MAP } from "app/app.constants";
import withToggleModal from "app/components/withToggleModal";
import SortableTable from "app/components/Table/SortableTable";

const OutstandingTrainingHistoryReportTableContainer = ({
  courses = EMPTY_MAP,
}) => {
  const columns = useMemo(
    () => [
      {
        xl: true,
        id: "icon",
        paddingLeft: "16px",
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (row.depth !== 0) return <TableRowActionsCell minItems={1} />;
          return null;
        },
      },
      {
        xs: 4,
        id: "courseName",
        title: "Course",
        Header: "Course",
        getSortProperty: item => item.get("courseName"),
        Cell: ({ row }) => {
          if (row.depth === 0) return row.original.get("courseName");
          return row.original.get("displayName");
        },
      },
    ],
    [],
  );

  return (
    <>
      <SortableTable
        stickyHeader
        disableSortBy
        displayAddButton={false}
        columns={columns}
        title="Outstanding Training History Report"
        useGlobalFilter={false}
        subListPropertyName="employees"
        keyName="employeeId"
        items={courses}
        sortOrder="courseName"
        noItemsMessage="No course found..."
      />
    </>
  );
};

const styles = () => ({
  formControlLabelRoot: {
    marginLeft: 0,
  },
});

export default compose(
  withStyles(styles),
  withToggleModal,
)(OutstandingTrainingHistoryReportTableContainer);
