import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { RouteContainer, NavTabsContainer } from "altus-ui-components";

import { ROUTE_KEYS } from "app/routes";
import { EMPTY_MAP } from "app/app.constants";
import { setRouteCount } from "utils/route.util";
import { skillOnLoad } from "features/competence.actions";
import SkillDetailsHeader from "features/skill/SkillDetailsHeader";
import { getSkillStatsFromState } from "features/competence.selectors";

class SkillContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad, skillId } = this.props;

    dispatchOnLoad(skillId);
  }

  getSkillRouteCount = key => {
    const { skillStats } = this.props;

    switch (key) {
      case ROUTE_KEYS.SKILL_MEMBERS:
        return skillStats.get("memberCount");
      case ROUTE_KEYS.SKILL_ROLES:
        return skillStats.get("rolesCount");
      default:
        return null;
    }
  };

  render() {
    const { skillId, routes } = this.props;

    return (
      <>
        <SkillDetailsHeader skillId={skillId} />
        <NavTabsContainer
          routes={routes.map(route =>
            setRouteCount(route, this.getSkillRouteCount(route.key)),
          )}
        />
        <RouteContainer routes={routes} />
      </>
    );
  }
}

SkillContainer.defaultProps = {
  skillStats: EMPTY_MAP,
};

export default compose(
  connect(
    (state, { skillId }) => ({
      skillStats: getSkillStatsFromState(state, skillId),
    }),
    {
      dispatchOnLoad: skillOnLoad,
    },
  ),
)(SkillContainer);
