import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";

import { RoleMapper } from "app/app.mappers";
import ReadOnlyFieldReduxForm from "app/components/form/ReadOnlyFieldReduxForm";

class RoleDetails extends PureComponent {
  render() {
    const { role } = this.props;

    return (
      <Grid container direction="column">
        <Grid item>
          <ReadOnlyFieldReduxForm
            defaultValue=""
            label="Role Name"
            input={{
              value: role.get("name"),
            }}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={8}>
            <ReadOnlyFieldReduxForm
              defaultValue=""
              label="Inherit Competence from Role"
              input={{
                value: role.get("parentName"),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <ReadOnlyFieldReduxForm
              defaultValue=""
              label="Status"
              input={{
                value: role.get("status"),
              }}
            />
          </Grid>
        </Grid>
        <Grid item>
          <ReadOnlyFieldReduxForm
            multiline
            rows={4}
            defaultValue=""
            label="Description"
            input={{
              value: role.get("description"),
            }}
          />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={4}>
            <ReadOnlyFieldReduxForm
              defaultValue=""
              label="Role Owner"
              input={{
                value: role.get("ownerDisplayName"),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

RoleDetails.defaultProps = {
  role: RoleMapper.initial,
};

export default RoleDetails;
