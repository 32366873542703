import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import {
  getEmployeeFromState,
  getSkillFromState,
  getEmployeeSkillFromState,
} from "features/competence.selectors";

import {
  EmployeeMapper,
  SkillMapper,
  EmployeeSkillMapper,
} from "app/app.mappers";

import { ICONS } from "app/app.constants";
import EntityDetailsHeader from "app/components/EntityDetailsHeader";

const EmployeeSkillDetailsHeader = ({
  skill,
  employee,
  employeeSkill,
  children,
}) => {
  return (
    <EntityDetailsHeader
      Icon={<ICONS.SKILL />}
      title={skill.get("name")}
      subtitle={employee.get("displayName")}
      competencyStatus={employeeSkill.get("competencyStatus")}
    >
      {children}
    </EntityDetailsHeader>
  );
};

EmployeeSkillDetailsHeader.defaultProps = {
  skill: SkillMapper.initial,
  employee: EmployeeMapper.initial,
  employeeSkill: EmployeeSkillMapper.initial,
};

export default compose(
  connect((initialState, { skillId, employeeId }) => {
    const employeeSkillSelector = getEmployeeSkillFromState(
      employeeId,
      skillId,
    );

    return state => ({
      skill: getSkillFromState(state, skillId),
      employee: getEmployeeFromState(state, employeeId),
      employeeSkill: employeeSkillSelector(state),
    });
  }),
)(EmployeeSkillDetailsHeader);
