import React, { PureComponent } from "react";
import { compose } from "redux";
import { withStyles } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";

class ServiceHand extends PureComponent {
  render() {
    const { classes } = this.props;

    return (
      <SvgIcon classes={classes.root} {...this.props}>
        <path
          d="M39.255,23.062a2.3,2.3,0,0,0-2.958,0L29.88,28.194a4.421,4.421,0,0,1-2.778.972H18.888a1.111,1.111,0,1,1,0-2.222h5.437A2.312,2.312,0,0,0,26.637,25.1a2.225,2.225,0,0,0-2.194-2.6H13.333a8.173,8.173,0,0,0-5.146,1.826L4.958,26.944H1.111A1.114,1.114,0,0,0,0,28.055v6.666a1.114,1.114,0,0,0,1.111,1.111H25.887a4.45,4.45,0,0,0,2.778-.972l10.5-8.4A2.221,2.221,0,0,0,39.255,23.062Z"
          transform="translate(0 -6) scale(0.6)"
        />
      </SvgIcon>
    );
  }
}

const styles = theme => ({
  root: {
    display: "flex",
  },
});

export default compose(withStyles(styles))(ServiceHand);
