import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { ICONS } from "app/app.constants";
import { SkillMapper } from "app/app.mappers";
import { getSkillFromState } from "features/competence.selectors";
import EntityDetailsHeader from "app/components/EntityDetailsHeader";

const SkillDetailsHeader = ({ skill, children }) => {
  return (
    <EntityDetailsHeader
      Icon={<ICONS.SKILL />}
      title={skill.get("name")}
      subtitle={skill.get("skillTypeName")}
      competencyStatus={skill.get("competencyStatus")}
    >
      {children}
    </EntityDetailsHeader>
  );
};

SkillDetailsHeader.defaultProps = {
  skill: SkillMapper.initial,
};

export default compose(
  connect((state, { skillId }) => ({
    skill: getSkillFromState(state, skillId),
  })),
)(SkillDetailsHeader);
