import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { Grid } from "@material-ui/core";

import { ACTIONS } from "app/app.constants";
import { getActionDataStateFromState } from "app/app.selectors";

import { getSkillFromState } from "features/competence.selectors";
import { SkillMapper } from "app/app.mappers";
import SkillDetails from "features/components/SkillDetails";
import { BasePage } from "altus-ui-components";

class EmployeeSkillDetailsContainer extends PureComponent {
  render() {
    const { skill, dataState } = this.props;

    return (
      <BasePage title="Details" dataState={dataState}>
        <Grid container item spacing={4}>
          <Grid container item xs={12} md={8}>
            <SkillDetails skill={skill}></SkillDetails>
          </Grid>
        </Grid>
      </BasePage>
    );
  }
}

EmployeeSkillDetailsContainer.defaultProps = {
  skill: SkillMapper.initial,
};

export default compose(
  connect((state, { skillId }) => ({
    dataState: getActionDataStateFromState(state, ACTIONS.GET_SKILL),
    skill: getSkillFromState(state, skillId),
  })),
)(EmployeeSkillDetailsContainer);
