import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";

import { BasePageTitle } from "altus-ui-components";

import CompetencyStatusChip from "app/components/CompetencyStatusChip";

export default class MyPageOverviewContainerTitle extends PureComponent {
  render() {
    const { title, Icon, status } = this.props;

    return (
      <>
        <Grid container item xs wrap="nowrap" alignItems="center">
          <BasePageTitle title={title} Icon={Icon} />
        </Grid>
        <CompetencyStatusChip status={status} />
      </>
    );
  }
}
