import { Map } from "immutable";
import { ACTIONS } from "app/app.constants";
import { ServiceMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_EMPLOYEE_SERVICES: {
      if (error) return state;

      return state.set(
        action.employeeId.toString(),
        Map(
          payload.map(employeeService => [
            employeeService.serviceId.toString(),
            ServiceMapper.from(employeeService),
          ]),
        ),
      );
    }

    default:
      return state;
  }
};
