import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { createEmployeeRoleCoursesSelector } from "features/competence.selectors";
import RoleCourseListContainer from "features/components/RoleCourseList/RoleCourseListContainer";

class EmployeeRoleCourseList extends PureComponent {
  render() {
    return <RoleCourseListContainer {...this.props} />;
  }
}

export default compose(
  connect((initialState, { employeeId, roleId }) => {
    const employeeRoleCoursesSelector = createEmployeeRoleCoursesSelector(
      employeeId,
      roleId,
    );

    return state => ({
      items: employeeRoleCoursesSelector(state),
    });
  }),
)(EmployeeRoleCourseList);
