import { compose } from "redux";
import { connect } from "react-redux";
import { getServiceFromState } from "features/competence.selectors";

const ServiceBreadcrumb = ({ service }) => {
  return service ? service.get("name") : "...";
};

export default compose(
  connect((state, { match }) => ({
    service: getServiceFromState(state, match.params.serviceId),
  })),
)(ServiceBreadcrumb);
