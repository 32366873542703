import { compose } from "redux";
import { connect } from "react-redux";
import { getExperienceRecordFromState } from "features/competence.selectors";

const ExperienceRecordBreadcrumb = ({ experienceRecord }) =>
  experienceRecord
    ? `Job ${experienceRecord.get("jobId")} (${experienceRecord.get(
        "employeeDisplayName",
      )})`
    : "...";

export default compose(
  connect((state, { match }) => ({
    experienceRecord: getExperienceRecordFromState(
      state,
      match.params.experienceRecordId,
    ),
  })),
)(ExperienceRecordBreadcrumb);
