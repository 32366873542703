import React from "react";
import { compose } from "redux";
import isNil from "lodash/isNil";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Avatar, Grid, Chip, Typography, withStyles } from "@material-ui/core";

import { CompetencyStatusChip } from "altus-ui-components";

const EntityDetailsHeader = ({
  Icon,
  title,
  classes,
  children,
  subtitle,
  titleUrl,
  subtitleUrl,
  competencyStatus,
  experiencePoints,
  competencyStatuses = [],
  isExperienceFeatureFlagEnabled = false,
}) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      className={classes.root}
    >
      <Grid container item xs spacing={2} alignItems="center" wrap="nowrap">
        <Grid item xs container spacing={2}>
          <Grid item>
            <Avatar
              classes={{
                colorDefault: classes.avatarColorDefault,
              }}
            >
              {Icon}
            </Avatar>
          </Grid>
          <Grid item xs>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  to={titleUrl}
                  component={titleUrl ? Link : undefined}
                  className={classNames(classes.text, {
                    [classes.link]: titleUrl,
                  })}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  to={subtitleUrl}
                  variant="caption"
                  component={subtitleUrl ? Link : undefined}
                  className={classNames({
                    [classes.link]: titleUrl,
                  })}
                >
                  {subtitle}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs container justifyContent="center">
          {children}
        </Grid>
        <Grid item xs container justifyContent="flex-end">
          {!isNil(experiencePoints) && isExperienceFeatureFlagEnabled && (
            <Grid item className={classes.chipContainer}>
              <Chip
                container
                wrap="nowrap"
                component={Grid}
                clickable={false}
                variant="outlined"
                justifyContent="space-between"
                title="Total XP"
                classes={{
                  root: classes.chipRoot,
                  label: classes.chipLabel,
                }}
                label={
                  <>
                    <Typography
                      variant="inherit"
                      noWrap
                      className={classes.chipLabelTitle}
                    >
                      Total XP
                    </Typography>
                    <Typography variant="inherit" noWrap>
                      {experiencePoints}
                    </Typography>
                  </>
                }
              />
            </Grid>
          )}
          <Grid item>
            {competencyStatuses &&
              competencyStatuses.map((status, index) => (
                <CompetencyStatusChip key={index} status={status} />
              ))}
            <CompetencyStatusChip status={competencyStatus} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    background: theme.palette.background.default,
  },
  avatarColorDefault: {
    background: theme.palette.primary.main,
  },
  text: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  link: {
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  chipContainer: {
    marginRight: theme.spacing(1),
  },
  chipRoot: {
    padding: theme.spacing(0.5),
    width: "auto",
    maxWidth: "100%",
    "&:focus": {
      backgroundColor: "white",
    },
  },
  chipLabel: {
    overflow: "hidden",
    fontWeight: theme.typography.fontWeightMedium,
  },
  chipLabelTitle: {
    marginRight: theme.spacing(1.5),
  },
});

export default compose(withStyles(styles))(EntityDetailsHeader);
