import Agent from "infrastructure/agent";

const getHrStats = () => Agent.get(`/stats/hr`);

const getSettingsStats = () => Agent.get(`/stats/settings`);

const getMyTeamStats = () => Agent.get(`/stats/myteam`);

const getVerifierStats = () => Agent.get(`/stats/verifier`);

const getCurrentEmployeeStats = () => Agent.get(`/stats/current`);

const getStatsForRoles = () => Agent.get(`/stats/role`);

const getStatsForRole = roleId => Agent.get(`/stats/role/${roleId}`);

const getStatsForEmployeeRoles = employeeId =>
  Agent.get(`/stats/role/employee/${employeeId}`);

const getStatsForMyTeamRoleMembers = roleId =>
  Agent.get(`/stats/role/${roleId}/myteam/employee`);

const getStatsForEmployeeRole = (employeeId, roleId) =>
  Agent.get(`/stats/role/${roleId}/employee/${employeeId}`);

const getStatsForMyTeamRoles = () => Agent.get(`/stats/role/myteam`);

const getStatsForMyTeamRole = roleId =>
  Agent.get(`/stats/role/${roleId}/myteam`);

const getStatsForRoleMembers = roleId =>
  Agent.get(`/stats/role/${roleId}/members`);

const getStatsForEmployees = filter => Agent.get(`/stats/employee`, filter);

const getStatsForMyTeamEmployees = () => Agent.get(`/stats/employee/myteam`);

const getStatsForCourses = () => Agent.get(`/stats/course`);

const getStatsForCourse = courseId => Agent.get(`/stats/course/${courseId}`);

const getStatsForSkills = () => Agent.get(`/stats/skill`);

const getStatsForSkill = skillId => Agent.get(`/stats/skill/${skillId}`);

const getStatsForEmployee = employeeId =>
  Agent.get(`/stats/employee/${employeeId}`);

const getStatsForSpecifications = () => Agent.get(`/stats/specification`);

const getStatsForSpecification = specificationId =>
  Agent.get(`/stats/specification/${specificationId}`);

const getStatsForCollections = () => Agent.get(`/stats/competencyCollection`);

const getStatsForCollection = competencyCollectionId =>
  Agent.get(`/stats/competencyCollection/${competencyCollectionId}`);

const getStatsForServices = () => Agent.get(`/stats/service`);

const getStatsForExperience = () => Agent.get(`/stats/experience`); // TODOA get stats for experience

const getStatsForExperienceRecordsByStatus = () =>
  Agent.get(`/stats/experienceRecord/status`);

const getStatsForService = serviceId =>
  Agent.get(`/stats/service/${serviceId}`);

const getStatsForExperienceRecords = () => Agent.get(`/stats/experienceRecord`);

const getStatsForExperienceRecord = experienceRecordId =>
  Agent.get(`/stats/experienceRecord/${experienceRecordId}`);

const getStatsForExperienceRecordsForEmployee = employeeId =>
  Agent.get(`/stats/experienceRecord/employee/${employeeId}`);

const getStatsForExperienceRecordsForValidator = employeeId =>
  Agent.get(`/stats/experienceRecord/validator/${employeeId}`);

export default {
  getHrStats,
  getMyTeamStats,
  getVerifierStats,
  getSettingsStats,
  getStatsForRole,
  getStatsForRoles,
  getStatsForMyTeamRoles,
  getCurrentEmployeeStats,
  getStatsForEmployeeRoles,
  getStatsForMyTeamRole,
  getStatsForEmployeeRole,
  getStatsForMyTeamRoleMembers,
  getStatsForRoleMembers,
  getStatsForEmployees,
  getStatsForMyTeamEmployees,
  getStatsForCourses,
  getStatsForSkills,
  getStatsForCourse,
  getStatsForSkill,
  getStatsForEmployee,
  getStatsForSpecifications,
  getStatsForSpecification,
  getStatsForCollections,
  getStatsForCollection,
  getStatsForServices,
  getStatsForService,
  getStatsForExperience,
  getStatsForExperienceRecord,
  getStatsForExperienceRecords,
  getStatsForExperienceRecordsForEmployee,
  getStatsForExperienceRecordsForValidator,
  getStatsForExperienceRecordsByStatus,
};
