import React from "react";
import { withStyles } from "@material-ui/core/styles";

import {
  Grid,
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

import { ICONS } from "app/app.constants";

const ExperienceRecordSubmitModal = ({ open, handleClose, classes }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    classes={{ paper: classes.dialogPaper }}
  >
    <DialogContent>
      <Grid
        container
        item
        xs
        spacing={2}
        direction="column"
        justifyContent="center"
      >
        <Grid container item xs justifyContent="center">
          <Typography noWrap title="Job" display="block" variant="h4">
            Thank you!
          </Typography>
        </Grid>
        <Grid container item xs justifyContent="center">
          <ICONS.EXPERIENCES className={classes.titleIcon} color="primary" />
        </Grid>
        <Grid container item xs justifyContent="center">
          <Typography noWrap display="block" variant="subtitle1">
            Experience Record
          </Typography>
        </Grid>
        <Grid container item xs justifyContent="center">
          <Typography noWrap title="Job" display="block" variant="subtitle1">
            Sent for Approval
          </Typography>
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Grid container item xs justifyContent="center">
        <Button
          onClick={handleClose}
          size="large"
          color="primary"
          variant="contained"
        >
          OK
        </Button>
      </Grid>
    </DialogActions>
  </Dialog>
);

const styles = theme => ({
  dialogPaper: {
    height: "100%",
    maxHeight: 380,
    width: 380,
    paddingBottom: theme.spacing(2),
  },
  titleIcon: {
    width: 120,
    height: 120,
    marginRight: theme.spacing(1),
  },
});

export default withStyles(styles)(ExperienceRecordSubmitModal);
