import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { getCurrentEmployeeFromState } from "app/app.selectors";
import ExperienceRecordRouteContainer from "features/experience/ExperienceRecordRouteContainer";

const MyPageEmployeeExperienceRecordContainer = ({
  currentEmployeeId,
  experienceRecordId,
  routes,
  Icon,
}) => {
  return (
    <ExperienceRecordRouteContainer
      Icon={Icon}
      experienceRecordId={experienceRecordId}
      employeeId={currentEmployeeId}
      routes={routes}
    />
  );
};

export default compose(
  connect(state => ({
    currentEmployeeId: getCurrentEmployeeFromState(state).get("employeeId"),
  })),
)(MyPageEmployeeExperienceRecordContainer);
