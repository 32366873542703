import { compose } from "redux";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";
import { Fields, Field } from "redux-form/immutable";
import { connect } from "react-redux";

import { required } from "utils/validation.util";
import TextField from "app/components/form/TextField";
import { EDUCATION_FORM } from "features/competence.constants";
import DatePickerField from "app/components/form/DatePickerField";
import CategorySelectField from "app/components/form/CategorySelectField";
import FileUploadField from "app/components/form/FileUploadField/FileUploadField";
import EducationFileUpload from "features/components/EducationFileUpload";

class EmployeeEducationForm extends PureComponent {
  render() {
    const { employeeId, selectedEducationId } = this.props;

    return (
      <form id={EDUCATION_FORM.ID}>
        <Grid container direction="column" spacing={1}>
          <Grid container item xs={12}>
            <Field
              required
              autoFocus
              label="Title"
              validate={[required]}
              component={TextField}
              name={EDUCATION_FORM.TITLE}
            />
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <Field
                validate={[required]}
                name={EDUCATION_FORM.FROM_DATE}
                component={DatePickerField}
                DatePickerProps={{
                  required: true,
                  label: "From",
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                validate={[required]}
                name={EDUCATION_FORM.TO_DATE}
                component={DatePickerField}
                DatePickerProps={{
                  required: true,
                  label: "To",
                }}
              />
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Fields
              validate={[required]}
              component={CategorySelectField}
              names={[EDUCATION_FORM.PARENT_CATEGORY, EDUCATION_FORM.CATEGORY]}
            />
          </Grid>
          <Grid item />
          <Grid container item>
            <Field
              multiple={true}
              label="Attachment"
              component={FileUploadField}
              name={EDUCATION_FORM.FILES}
            >
              {({
                value,
                rootProps,
                removeFile,
                isDragActive,
                openFileDialog,
              }) => (
                <EducationFileUpload
                  value={value}
                  employeeId={employeeId}
                  rootProps={rootProps}
                  removeFile={removeFile}
                  isDragActive={isDragActive}
                  openFileDialog={openFileDialog}
                  educationId={selectedEducationId}
                />
              )}
            </Field>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default compose(
  connect((state, { formValueSelector }) => ({
    selectedEducationId: formValueSelector(state, EDUCATION_FORM.EDUCATION_ID),
  })),
)(EmployeeEducationForm);
