import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Grid } from "@material-ui/core";

import {
  getRoleSkillsWithParentsByRole,
  roleSkillsOnUnload,
} from "features/competence.actions";

import { ACTIONS, ICONS } from "app/app.constants";
import CrudBasePage from "app/components/CrudBasePage";
import { getActionDataStateFromState } from "app/app.selectors";
import { getRolesFromState } from "features/competence.selectors";
import RoleRoleSkillList from "features/role/components/RoleRoleSkillList";

class MyTeamRoleSkillsContainer extends PureComponent {
  componentDidMount() {
    const { dispatchGetRoleSkills, roleId } = this.props;
    dispatchGetRoleSkills(roleId);
  }

  componentWillUnmount() {
    const { dispatchOnUnload } = this.props;
    dispatchOnUnload();
  }

  columns = [
    {
      xs: 4,
      title: "Skill Name",
      getSortProperty: item => item.get("skillName"),
    },
    {
      xs: true,
      title: "Group",
      getSortProperty: item => item.get("skillGroupName"),
    },
    {
      xs: true,
      title: "Type",
      getSortProperty: item => item.get("skillTypeName"),
    },
    {
      xs: true,
      title: "Valid",
      getSortProperty: item => item.get("validityMonthsString"),
    },
  ];

  getKey = roleSkill => roleSkill.get("roleSkillId");

  render() {
    const { roles, dataState, breadcrumb } = this.props;

    return (
      <CrudBasePage
        dataState={dataState}
        title={breadcrumb}
        displaySearchField={true}
      >
        <Grid container spacing={5}>
          {roles.map(role => {
            const roleId = role.get("roleId");

            return (
              <RoleRoleSkillList
                Icon={ICONS.SKILL}
                key={roleId}
                roleId={roleId}
                getKey={this.getKey}
                actions={this.actions}
                columns={this.columns}
                inherited={roleId.toString() !== this.props.roleId.toString()}
              />
            );
          })}
        </Grid>
      </CrudBasePage>
    );
  }
}

export default compose(
  connect(
    (state, { roleId }) => ({
      roles: getRolesFromState(state, roleId),
      dataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_ROLE_SKILLS_WITH_PARENTS_BY_ROLE,
      ),
    }),
    {
      dispatchGetRoleSkills: getRoleSkillsWithParentsByRole,
      dispatchOnUnload: roleSkillsOnUnload,
    },
  ),
)(MyTeamRoleSkillsContainer);
