import React from "react";
import { compose } from "redux";
import { Grid } from "@material-ui/core";

import { LoadingButton } from "altus-ui-components";

import ManagerEmployeeAccessControl from "app/components/ManagerEmployeeAccessControl";

const AddEmployeeSkillEvidenceModalActions = ({
  valid,
  skillId,
  pristine,
  submitText,
  submitting,
  employeeId,
  toggleModal,
  handleSubmit,
  addEmployeeSkillEvidenceOnSubmit,
}) => {
  const approveOnCreate = true;
  return (
    <Grid container justify="space-between">
      <Grid item>
        <ManagerEmployeeAccessControl employeeId={employeeId}>
          <LoadingButton
            type="submit"
            color="primary"
            disabled={pristine || !valid || submitting}
            loading={submitting}
            variant="contained"
            onClick={handleSubmit(
              addEmployeeSkillEvidenceOnSubmit(
                employeeId,
                skillId,
                approveOnCreate,
              )(toggleModal),
            )}
          >
            {`${submitText} & Approve`}
          </LoadingButton>
        </ManagerEmployeeAccessControl>
      </Grid>
      <Grid item>
        <LoadingButton
          type="submit"
          color="primary"
          disabled={pristine || !valid || submitting}
          loading={submitting}
          variant="contained"
          onClick={handleSubmit(
            addEmployeeSkillEvidenceOnSubmit(
              employeeId,
              skillId,
              !approveOnCreate,
            )(toggleModal),
          )}
        >
          {submitText}
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default compose()(AddEmployeeSkillEvidenceModalActions);
