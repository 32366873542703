import moment from "moment";
import { formValueSelector } from "redux-form";
import { replace } from "connected-react-router";
import { initialize } from "redux-form/immutable";

import { toggleModal } from "altus-modal";

import {
  addNotification,
  NOTIFICATION_VARIANTS,
} from "altus-redux-middlewares";

import {
  ROLE_FORM,
  SKILL_FORM,
  COURSE_FORM,
  SERVICE_FORM,
  EMPLOYEE_FORM,
  EDUCATION_FORM,
  COLLECTION_FORM,
  SKILL_TYPE_FORM,
  SKILL_GROUP_FORM,
  COURSE_TYPE_FORM,
  WORK_HISTORY_FORM,
  COURSE_GROUP_FORM,
  SPECIFICATION_FORM,
  EMPLOYEE_SKILL_FORM,
  CREATE_ROLE_MODAL_ID,
  EMPLOYEE_COURSE_FORM,
  ROLE_DETAILS_MODAL_ID,
  SKILL_DETAILS_MODAL_ID,
  CREATE_SERVICE_MODAL_ID,
  COURSE_DETAILS_MODAL_ID,
  EMPLOYEE_CV_OPTIONS_FORM,
  CREATE_ROLE_EMPLOYEE_FORM,
  ADD_PREREQUISITE_COURSE_FORM,
  CREATE_SPECIFICATION_MODAL_ID,
  GENERATE_EMPLOYEES_CVS_MODAL_ID,
  DEFAULT_SKILL_FORM_REQUIRED_EVIDENCE_COUNT,
  EXPERIENCE_RECORD_DETAILS_FORM,
} from "features/competence.constants";

import {
  getCurrentUserFromState,
  getFormInitialValuesFromState,
} from "app/app.selectors";

import {
  ACTIONS,
  EMPTY_SET,
  EMPTY_LIST,
  APPROVAL_STATUS,
  COMPETENCY_STATUS,
} from "app/app.constants";

import {
  SkillMapper,
  CourseMapper,
  EmployeeMapper,
  CollectionMapper,
  SkillEvidenceMapper,
  TrainingHistoryMapper,
  CourseExpiryFilterMapper,
  CourseTrainingHistoryMapper,
  OutstandingTrainingHistoryMapper,
} from "app/app.mappers";

import {
  getRoleFromState,
  getSkillFromState,
  getCourseFromState,
  getServiceFromState,
  getCountriesFromState,
  getSpecificationFromState,
  getGapReportAppliedFiltersFromState,
} from "features/competence.selectors";

import { toQueryString } from "utils/app.util";
import dataService from "services/data.service";
import roleService from "services/role.service";
import skillService from "services/skill.service";
import statsService from "services/stats.service";
import courseService from "services/course.service";
import reportService from "services/report.service";
import serviceService from "services/service.service";
import facilityService from "services/facility.service";
import employeeService from "services/employee.service";
import approvalsService from "services/approvals.service";
import { ExperienceRecordsMapper } from "app/app.mappers";
import collectionService from "services/collection.service";
import experienceService from "services/experience.service";
import organizationService from "services/organization.service";
import specificationService from "services/specification.service";
import employeeCourseService from "services/employeeCourse.service";
import employeeCourseMappers from "app/mappers/employeeCourse.mappers";
import { getUsers, getAllAppRoles, getAppFeatures } from "app/app.actions";

import {
  saveFile,
  makeActionCreator,
  getSkillEvidenceCount,
  resolveEmployeeCourseFile,
} from "utils/app.util";

/**
 * Department
 */
export const getDepartments = makeActionCreator({
  type: ACTIONS.GET_DEPARTMENTS,
  payload: dataService.getDepartments,
});

export const getMyTeamDepartments = makeActionCreator({
  type: ACTIONS.GET_MY_TEAM_DEPARTMENTS,
  payload: dataService.getMyTeamDepartments,
});

/**
 * Manager
 */
export const getManagers = makeActionCreator({
  type: ACTIONS.GET_MANAGERS,
  payload: dataService.getManagers,
});

export const getMyTeamManagers = makeActionCreator({
  type: ACTIONS.GET_MY_TEAM_MANAGERS,
  payload: dataService.getMyTeamManagers,
});

/**
 * Course
 */
export const coursesOnLoad = () => dispatch =>
  Promise.all([
    dispatch(getCourses({ includeInActive: true })),
    dispatch(getCourseGroups()),
    dispatch(getCourseTypes()),
  ]);

export const coursesOnUnload = () => dispatch => {
  dispatch(clearCourses());
};

export const clearCourses = () => dispatch =>
  dispatch({
    type: ACTIONS.CLEAR_COURSES,
  });

export const courseOnLoad = courseId => dispatch =>
  Promise.all([
    dispatch(getCourse(courseId)),
    dispatch(getStatsForCourse(courseId)),
  ]);

export const getCourse = makeActionCreator({
  type: ACTIONS.GET_COURSE,
  payload: courseService.getCourse,
});

export const getCourses = makeActionCreator({
  type: ACTIONS.GET_COURSES,
  payload: courseService.getCourses,
});

export const getMyTeamCourses = makeActionCreator({
  type: ACTIONS.GET_COURSES,
  payload: courseService.getMyTeamCourses,
});

export const createCourse = makeActionCreator({
  type: ACTIONS.CREATE_COURSE,
  payload: courseService.createCourse,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Course successfully created",
  },
});

export const updateCourse = (courseId, course) => dispatch => {
  const payload = courseService.updateCourse(courseId, course);
  dispatch({
    type: ACTIONS.UPDATE_COURSE,
    payload: payload,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Course successfully updated",
      [NOTIFICATION_VARIANTS.INFO]: "Loading...",
    },
  });
  return payload;
};

export const deleteCourse = makeActionCreator(
  {
    type: ACTIONS.DELETE_COURSE,
    payload: courseService.deleteCourse,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Course successfully deleted",
    },
  },
  "courseId",
);

export const deleteEmployeeCourse = makeActionCreator(
  {
    type: ACTIONS.DELETE_EMPLOYEE_COURSE,
    payload: employeeCourseService.deleteEmployeeCourse,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Employee course successfully deleted",
    },
  },
  "employeeId",
  "courseId",
);

export const deleteEmployeeSkill = makeActionCreator(
  {
    type: ACTIONS.DELETE_EMPLOYEE_SKILL,
    payload: employeeService.deleteEmployeeSkill,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Employee skill successfully deleted",
    },
  },
  "employeeId",
  "skillId",
);

export const deleteCourses = makeActionCreator(
  {
    type: ACTIONS.DELETE_COURSES,
    payload: courseService.deleteCourses,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Courses successfully deleted",
    },
  },
  "courseIds",
);

export const deleteCoursesAskConfirmation = courseIds => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_COURSES_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Delete Courses",
      confirmButtonText: "Delete",
      description: `Are you sure you want to delete the selected courses?`,
    },
    payload: () => dispatch(deleteCourses(courseIds)),
  });

export const deleteCourseAskConfirmation = course => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_COURSE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Delete Course",
      confirmButtonText: "Delete",
      description: `Are you sure you want to delete '${course.get("name")}'?`,
    },
    payload: () => dispatch(deleteCourse(course.get("courseId"))),
  });

export const getRolesByCourse = makeActionCreator({
  type: ACTIONS.GET_ROLES_BY_COURSE,
  payload: roleService.getRolesByCourse,
});

export const deleteEmployeeCourseAskConfirmation = course => dispatch => {
  var file = resolveEmployeeCourseFile(course);

  var hasAttendance = course.get("bestAttendanceId") ? true : false;

  if (!file && !hasAttendance) {
    dispatch({
      type: ACTIONS.DELETE_COURSE_ASK_CONFIRMATION,
      confirmationDialog: {
        title: "Delete Employee Course",
        confirmButtonText: "Delete",
        description: `Are you sure you want to delete '${course.get(
          "courseName",
        )}'?`,
      },
      payload: () =>
        dispatch(
          deleteEmployeeCourse(
            course.get("employeeId"),
            course.get("courseId"),
          ),
        ),
    });
  } else if (file) {
    dispatch(
      addNotification({
        message: `Employee course ${course.get(
          "courseName",
        )} has uploaded certificate and cannot be removed.`,
        variant: NOTIFICATION_VARIANTS.ERROR,
      }),
    );
  } else if (hasAttendance) {
    dispatch(
      addNotification({
        message: `Employee course ${course.get(
          "courseName",
        )} has attendances and cannot be removed.`,
        variant: NOTIFICATION_VARIANTS.ERROR,
      }),
    );
  }
};

export const deleteEmployeeSkillAskConfirmation = skill => dispatch => {
  var fileCount = getSkillEvidenceCount(skill);
  if (fileCount === 0) {
    dispatch({
      type: ACTIONS.DELETE_SKILL_ASK_CONFIRMATION,
      confirmationDialog: {
        title: "Delete Employee Skill",
        confirmButtonText: "Delete",
        description: `Are you sure you want to delete '${skill.get(
          "skillName",
        )}'?`,
      },
      payload: () =>
        dispatch(
          deleteEmployeeSkill(skill.get("employeeId"), skill.get("skillId")),
        ),
    });
  } else {
    dispatch(
      addNotification({
        message: `Employee skill ${skill.get(
          "skillName",
        )} has uploaded evidence and cannot be removed.`,
        variant: NOTIFICATION_VARIANTS.ERROR,
      }),
    );
  }
};

export const createEmployeeCourseAttendanceOnEnter = () => dispatch =>
  Promise.all([
    dispatch(getCourses()),
    dispatch(getCourseGroups()),
    dispatch(getCourseTypes()),
  ]);

export const createCourseOnSubmit = toggleModal => dispatch => course =>
  dispatch(createCourse(course.toJS()))
    .then(createdCourse => dispatch(getStatsForCourse(createdCourse.courseId)))
    .then(toggleModal);

export const initializeCreateCourse = () => dispatch =>
  dispatch(
    initialize(COURSE_FORM.ID, {
      [COURSE_FORM.IS_ACTIVE]: true,
      [COURSE_FORM.COURSE_COUNTRIES]: EMPTY_LIST,
    }),
  );

export const initializeCourseDetailsForm = courseId => (dispatch, getState) => {
  dispatch(toggleModal({ modalId: COURSE_DETAILS_MODAL_ID }));
  dispatch(
    initialize(COURSE_FORM.ID, getCourseFromState(getState(), courseId), false),
  );
};

export const initializeRoleDetailsForm = roleId => (dispatch, getState) => {
  dispatch(toggleModal({ modalId: ROLE_DETAILS_MODAL_ID }));
  dispatch(initialize(ROLE_FORM.ID, getRoleFromState(getState(), roleId)));
};

/**
 * Collection
 */
export const collectionsOnLoad = () => dispatch =>
  Promise.all([dispatch(getCollections()), dispatch(getCountries())]);

export const collectionsOnUnload = () => dispatch => {
  dispatch(clearCollections());
};

export const clearCollections = () => dispatch =>
  dispatch({
    type: ACTIONS.CLEAR_COLLECTIONS,
  });

export const collectionOnLoad = competencyCollectionId => dispatch =>
  Promise.all([
    dispatch(getCollection(competencyCollectionId)),
    dispatch(getCountries()),
  ]);

export const getCollection = makeActionCreator(
  {
    type: ACTIONS.GET_COLLECTION,
    payload: collectionService.getCollection,
  },
  "collectionId",
);

export const getCollections = makeActionCreator({
  type: ACTIONS.GET_COLLECTIONS,
  payload: collectionService.getCollections,
});

export const createCollection = makeActionCreator({
  type: ACTIONS.CREATE_COLLECTION,
  payload: collectionService.createCollection,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Collection successfully created",
  },
});

export const createCollectionOnSubmit = toggleModal => dispatch => collection =>
  dispatch(createCollection(collection.toJS()))
    // TODO
    //.then(createdCollection => dispatch(getStatsForCollection(createdCollection.competencyCollectionId)))
    .then(toggleModal);

export const updateCollection = makeActionCreator({
  type: ACTIONS.UPDATE_COLLECTION,
  payload: collectionService.updateCollection,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Collection successfully updated",
    [NOTIFICATION_VARIANTS.INFO]: "Loading...",
  },
});

export const deleteCollection = makeActionCreator(
  {
    type: ACTIONS.DELETE_COLLECTION,
    payload: collectionService.deleteCollection,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Collection successfully deleted",
    },
  },
  "competencyCollectionId",
);

export const deleteCollectionsAskConfirmation = collection => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_COLLECTION_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Delete collection",
      confirmButtonText: "Delete",
      description: `Are you sure you want to delete ${collection.get("name")}?`,
    },
    payload: () =>
      dispatch(deleteCollection(collection.get("competencyCollectionId"))),
  });

/**
 * Prerequisite Course
 */

export const getAvailablePrerequisiteCourses = makeActionCreator({
  type: ACTIONS.GET_AVAILABLE_PREREQUISITE_COURSES,
  payload: courseService.getAvailablePrerequisiteCourses,
});

export const getPrerequisiteCourses = makeActionCreator({
  type: ACTIONS.GET_PREREQUISITE_COURSES,
  payload: courseService.getPrerequisiteCourses,
});

export const addPrerequisiteCourse = makeActionCreator({
  type: ACTIONS.ADD_PREREQUISITE_COURSE,
  payload: courseService.addPrerequisiteCourse,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Course successfully added",
  },
});

export const addPrerequisiteCourseOnSubmit = courseId => dispatch => toggleModal => formValues =>
  dispatch(
    addPrerequisiteCourse(
      courseId,
      formValues.get(ADD_PREREQUISITE_COURSE_FORM.PREREQUISITE_COURSE_ID),
    ),
  ).then(toggleModal);

export const removePrerequisiteCourse = makeActionCreator(
  {
    type: ACTIONS.REMOVE_PREREQUISITE_COURSE,
    payload: courseService.removePrerequisiteCourse,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Course successfully removed",
    },
  },
  "courseId",
  "prerequisiteCourseId",
);

export const removePrerequisiteCourseAskConfirmation = (
  course,
  prerequisiteCourse,
) => dispatch =>
  dispatch({
    type: ACTIONS.REMOVE_PREREQUISITE_COURSE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove prerequisite course",
      confirmButtonText: "Remove",
      description: `Are you sure you want to remove '${prerequisiteCourse.get(
        "name",
      )}' from '${course.get("name")}'?`,
    },
    payload: () =>
      dispatch(
        removePrerequisiteCourse(
          course.get("courseId"),
          prerequisiteCourse.get("courseId"),
        ),
      ),
  });

/**
 * Employee.
 */
export const getEmployeeServices = makeActionCreator(
  {
    type: ACTIONS.GET_EMPLOYEE_SERVICES,
    payload: employeeService.getEmployeeServices,
  },
  "employeeId",
);

export const clearEmployees = () => dispatch =>
  dispatch({
    type: ACTIONS.CLEAR_EMPLOYEES,
  });

export const getCurrentEmployee = () => (dispatch, getState) => {
  const currentUser = getCurrentUserFromState(getState());

  if (!currentUser.get("employeeId")) return Promise.resolve();

  const payload = employeeService.getCurrentEmployee();

  dispatch({
    payload,
    type: ACTIONS.GET_CURRENT_EMPLOYEE,
  });

  return payload;
};

export const getEmployee = makeActionCreator({
  type: ACTIONS.GET_EMPLOYEE,
  payload: employeeService.getEmployee,
});

export const getEmployeeCoursesByCourse = makeActionCreator({
  type: ACTIONS.GET_EMPLOYEES_BY_COURSE,
  payload: employeeService.getEmployeesByCourse,
});

/**
 * Employee Course
 */

export const getEmployeeRoleCourseList = makeActionCreator(
  {
    type: ACTIONS.GET_EMPLOYEE_COURSES_BY_ROLE,
    payload: employeeCourseService.getEmployeeRoleCourseList,
  },
  "employeeId",
);

export const getEmployeeCourse = makeActionCreator({
  type: ACTIONS.GET_EMPLOYEE_COURSE,
  payload: employeeCourseService.getEmployeeCourse,
});

export const updateEmployeeCourseAttendanceApproval = (
  employeeId,
  courseId,
  attendanceId,
  employeeCourseAttendance,
) => dispatch => {
  const actionText =
    employeeCourseAttendance.approvalStatus === APPROVAL_STATUS.APPROVED
      ? "approved"
      : "not approved";

  dispatch({
    type: ACTIONS.UPDATE_EMPLOYEE_COURSE_ATTENDANCE_APPROVAL,
    payload: () =>
      employeeCourseService.updateEmployeeCourseAttendanceApproval(
        employeeId,
        courseId,
        attendanceId,
        employeeCourseAttendance,
      ),
    employeeId,
    courseId,
    attendanceId,
    id: attendanceId,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: `Course attendance ${actionText}`,
    },
  });
};

export const removeEmployeeCourseAttendanceApproval = (
  employeeId,
  courseId,
  attendanceId,
) => dispatch =>
  dispatch({
    type: ACTIONS.REMOVE_EMPLOYEE_COURSE_ATTENDANCE_APPROVAL,
    payload: () =>
      employeeCourseService.deleteEmployeeCourseAttendanceApproval(
        employeeId,
        courseId,
        attendanceId,
      ),
    id: attendanceId,
    employeeId,
    courseId,
    attendanceId,
  });

export const toggleRoleEmployeeCoursesOverride = (
  employeeId,
  roleId,
) => dispatch => {
  const payload = employeeService
    .toggleRoleEmployeeCoursesOverride(employeeId, roleId)
    .then(() =>
      Promise.all([
        dispatch(getStatsForEmployeeRole(employeeId, roleId)),
        dispatch(getEmployeeRoles(employeeId)),
      ]),
    );

  dispatch({
    employeeId,
    roleId,
    type: ACTIONS.TOGGLE_ROLE_EMPLOYEE_COURSE_OVERRIDE,
    payload,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Role Courses successfully overridden",
      [NOTIFICATION_VARIANTS.INFO]: "Overriding...",
    },
  });

  return payload;
};

export const toggleRoleEmployeeSkillsOverride = (
  employeeId,
  roleId,
) => dispatch => {
  const payload = employeeService
    .toggleRoleEmployeeSkillsOverride(employeeId, roleId)
    .then(() =>
      Promise.all([
        dispatch(getStatsForEmployeeRole(employeeId, roleId)),
        dispatch(getEmployeeRoles(employeeId)),
      ]),
    );

  dispatch({
    employeeId,
    roleId,
    type: ACTIONS.TOGGLE_ROLE_EMPLOYEE_SKILL_OVERRIDE,
    payload,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Role Skills successfully overridden",
      [NOTIFICATION_VARIANTS.INFO]: "Overriding...",
    },
  });

  return payload;
};

export const createEmployeeCourseAttendanceOverride = (
  employeeId,
  courseId,
  attendance,
) => dispatch => {
  const payload = employeeCourseService.createEmployeeCourseAttendanceOverride(
    employeeId,
    courseId,
    attendance,
  );

  dispatch({
    courseId,
    employeeId,
    type: ACTIONS.CREATE_EMPLOYEE_COURSE_ATTENDANCE_OVERRIDE,
    payload,
  });

  return payload;
};

export const createEmployeeCourseAttendance = (
  employeeId,
  courseId,
  attendance,
) => dispatch => {
  const payload = employeeCourseService.createEmployeeCourseAttendance(
    employeeId,
    courseId,
    attendance,
  );

  dispatch({
    courseId,
    employeeId,
    type: ACTIONS.CREATE_EMPLOYEE_COURSE_ATTENDANCE,
    payload,
  });

  return payload;
};

export const createAndApproveEmployeeCourseAttendance = (
  employeeId,
  courseId,
  attendance,
) => dispatch => {
  const payload = employeeCourseService.createAndApproveEmployeeCourseAttendance(
    employeeId,
    courseId,
    attendance,
  );

  dispatch({
    courseId,
    employeeId,
    type: ACTIONS.CREATE_AND_APPROVE_EMPLOYEE_COURSE_ATTENDANCE,
    payload,
  });

  return payload;
};

export const deleteEmployeeCourseAttendance = (
  employeeId,
  courseId,
  attendanceId,
) => dispatch => {
  const payload = employeeCourseService.deleteEmployeeCourseAttendance(
    employeeId,
    courseId,
    attendanceId,
  );

  dispatch({
    employeeId,
    courseId,
    attendanceId,
    type: ACTIONS.DELETE_EMPLOYEE_COURSE_ATTENDANCE,
    payload,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Course attendance deleted successfully",
    },
  });

  return payload;
};

export const deleteEmployeeCourseAttendanceAskConfirmation = employeeCourseAttendance => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_EMPLOYEE_COURSE_ATTENDANCE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove course attendance",
      confirmButtonText: "Remove",
      description: `Are you sure you want to remove this attendance to ${employeeCourseAttendance.get(
        "courseName",
      )}?`,
    },
    payload: () =>
      dispatch(
        deleteEmployeeCourseAttendance(
          employeeCourseAttendance.get("employeeId"),
          employeeCourseAttendance.get("courseId"),
          employeeCourseAttendance.get("attendanceId"),
        ),
      ).then(() =>
        dispatch(
          getEmployeeCourse(
            employeeCourseAttendance.get("employeeId"),
            employeeCourseAttendance.get("courseId"),
          ),
        ),
      ),
  });

export const deleteEmployeeCourseAttendanceFile = (
  employeeId,
  courseId,
  attendanceId,
  attendanceFileId,
) => dispatch => {
  const payload = employeeCourseService.deleteEmployeeCourseAttendanceFile(
    employeeId,
    courseId,
    attendanceId,
    attendanceFileId,
  );

  dispatch({
    employeeId,
    courseId,
    attendanceId,
    attendanceFileId,
    type: ACTIONS.DELETE_EMPLOYEE_COURSE_ATTENDANCE_FILE,
    payload,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Course attendance file deleted successfully",
    },
  });

  return payload;
};

export const deleteEmployeeCourseAttendanceFileAskConfirmation = employeeCourseAttendanceFile => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_EMPLOYEE_COURSE_ATTENDANCE_FILE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove course attendance file",
      confirmButtonText: "Remove",
      description: `Are you sure you want to remove file ${employeeCourseAttendanceFile.name} from attendance?`,
    },
    payload: () =>
      dispatch(
        deleteEmployeeCourseAttendanceFile(
          employeeCourseAttendanceFile.employeeId,
          employeeCourseAttendanceFile.courseId,
          employeeCourseAttendanceFile.attendanceId,
          employeeCourseAttendanceFile.attendanceFileId,
        ),
      ).then(() =>
        dispatch(
          getEmployeeCourse(
            employeeCourseAttendanceFile.employeeId,
            employeeCourseAttendanceFile.courseId,
          ),
        ),
      ),
  });

/**
 * My Competence
 */
export const createEmployeeCourseAttendanceFormOnSubmit = employeeId => dispatch => toggleModal => employeeCourseAttendance =>
  dispatch(
    createEmployeeCourseAttendance(
      employeeId,
      employeeCourseAttendance.get("courseId"),
      employeeCourseAttendance.toJS(),
    ),
  )
    .then(Attendance =>
      Promise.all([
        employeeCourseAttendance
          .get("files", EMPTY_SET)
          .map(file =>
            dispatch(
              uploadEmployeeCourseAttendanceFile(
                employeeId,
                Attendance.courseId,
                Attendance.employeeCourseAttendanceId,
                file,
              ),
            ),
          ),
        dispatch(getEmployeeRoleCourseList(employeeId)),
        dispatch(getStatsForEmployee(employeeId)),
      ]),
    )
    .then(() =>
      dispatch(
        getEmployeeCourse(employeeId, employeeCourseAttendance.get("courseId")),
      ),
    )
    .then(toggleModal);

export const addEmployeeCourseAttendanceModalOnSubmit = (
  employeeId,
  courseId,
  employeeCourseAttendances = EMPTY_LIST,
  approveOnCreate = false,
) => dispatch => toggleModal => employeeCourseAttendance => {
  let attendanceWithSameDateAndFileNameExists = false;

  if (employeeCourseAttendance.get("files").size > 0) {
    // get file name that is to be uploaded
    const fileNameToBeUploaded = Array.from(
      employeeCourseAttendance.get("files"),
    )[0].name;

    // check if we have attendance with same start date, end date and file name
    attendanceWithSameDateAndFileNameExists =
      employeeCourseAttendances.filter(
        attendance =>
          moment(attendance.get("startDate")).isSame(
            employeeCourseAttendance.get("startDate"),
            "day",
          ) &&
          moment(attendance.get("endDate")).isSame(
            employeeCourseAttendance.get("endDate"),
            "day",
          ) &&
          attendance
            .get("files")
            .filter(file => file.name === fileNameToBeUploaded).size > 0,
      ).size > 0;
  }

  if (attendanceWithSameDateAndFileNameExists) {
    dispatch(
      addNotification({
        message: `Employee course attendance with same date and file already exists`,
        variant: NOTIFICATION_VARIANTS.ERROR,
      }),
    );
  } else {
    dispatch(
      addEmployeeCourseAttendanceOnSubmit(
        employeeCourseAttendance,
        employeeId,
        courseId,
        approveOnCreate,
      ),
    ).then(toggleModal);
  }
};

const addEmployeeCourseAttendanceOnSubmit = (
  employeeCourseAttendance,
  employeeId,
  courseId,
  approveOnCreate = false,
) => (dispatch, getState) => {
  const state = getState();
  const course = getCourseFromState(state, courseId);

  if (course.get("isCustom")) {
    courseId = formValueSelector(EMPLOYEE_COURSE_FORM.ID)(
      state,
      EMPLOYEE_COURSE_FORM.PREREQUISITE_COURSE_ID,
    );
  }

  return Promise.resolve(
    employeeCourseAttendance
      .set("employeeId", employeeId)
      .set("courseId", courseId),
  )
    .then(employeeCourseAttendance => {
      if (employeeCourseAttendance.get("isOverridden")) {
        return dispatch(
          createEmployeeCourseAttendanceOverride(
            employeeId,
            courseId,
            employeeCourseAttendance.toJS(),
          ),
        );
      }
      if (approveOnCreate) {
        return dispatch(
          createAndApproveEmployeeCourseAttendance(
            employeeId,
            courseId,
            employeeCourseAttendance.toJS(),
          ),
        );
      }
      return dispatch(
        createEmployeeCourseAttendance(
          employeeId,
          courseId,
          employeeCourseAttendance.toJS(),
        ),
      );
    })
    .then(Attendance =>
      Promise.all(
        employeeCourseAttendance
          .get("files", EMPTY_SET)
          .map(file =>
            dispatch(
              uploadEmployeeCourseAttendanceFile(
                employeeId,
                Attendance.courseId,
                Attendance.employeeCourseAttendanceId,
                file,
              ),
            ),
          ),
      ),
    )
    .then(() => dispatch(getEmployeeCourse(employeeId, courseId)))
    .then(() => dispatch(getEmployeeCourseAttendances(employeeId, courseId)));
};

export const initializeAddEmployeeCourseAttendanceModal = (
  employeeId,
  courseId,
  courseName,
  toggleModal,
) => dispatch =>
  dispatch(
    initializeAddEmployeeCourseAttendanceForm(employeeId, courseId, courseName),
  ).then(toggleModal);

export const initializeAddEmployeeCourseAttendanceForm = (
  employeeId,
  courseId,
  courseName,
) => dispatch => {
  return Promise.all([
    dispatch(getCourse(courseId)),
    dispatch(getEmployeeCourse(employeeId, courseId))
      .then(employeeCourse => {
        const { pendingApprovalAttendance } = employeeCourse;

        if (
          pendingApprovalAttendance?.competencyStatus ===
          COMPETENCY_STATUS.REQUIRES_APPROVAL
        ) {
          //If the employeeCourse has an attendance with pending approval, fill in its information.
          dispatch(
            initialize(
              EMPLOYEE_COURSE_FORM.ID,
              employeeCourseMappers.EmployeeCourseAttendance.from(
                pendingApprovalAttendance,
              ),
            ),
          );
        } else {
          // If it's not requiring approval, use default initialize.
          dispatch(
            initializeDefaultAddEmployeeCourseAttendanceForm(
              courseId,
              courseName,
            ),
          );
        }
      })
      .catch(() =>
        //If there is no employeeCourse yet, also use default initialize.
        dispatch(
          initializeDefaultAddEmployeeCourseAttendanceForm(
            courseId,
            courseName,
          ),
        ),
      ),
  ]);
};

export const initializeDefaultAddEmployeeCourseAttendanceForm = (
  courseId,
  courseName,
) => dispatch =>
  dispatch(
    initialize(EMPLOYEE_COURSE_FORM.ID, {
      [EMPLOYEE_COURSE_FORM.COURSE_NAME]: courseName || "",
      [EMPLOYEE_COURSE_FORM.COURSE_ID]: courseId,
      [EMPLOYEE_COURSE_FORM.FILES]: EMPTY_SET,
    }),
  );

export const getEmployeeCourseAttendances = (
  employeeId,
  courseId,
  filter,
) => dispatch =>
  dispatch({
    courseId,
    employeeId,
    type: ACTIONS.GET_EMPLOYEE_COURSE_ATTENDANCES,
    payload: () =>
      employeeCourseService.getEmployeeCourseAttendances(
        employeeId,
        courseId,
        filter,
      ),
  });

export const getPrerequisiteEmployeeCourses = makeActionCreator(
  {
    type: ACTIONS.GET_PREREQUISITE_EMPLOYEE_COURSES,
    payload: employeeCourseService.getPrerequisiteEmployeeCourses,
  },
  "employeeId",
  "courseId",
);

export const getEmployees = makeActionCreator({
  type: ACTIONS.GET_EMPLOYEES,
  payload: employeeService.getEmployees,
});

export const getMyTeamEmployeesByUser = makeActionCreator({
  type: ACTIONS.GET_MY_TEAM_EMPLOYEES_BY_USER,
  payload: employeeService.getMyTeamEmployeesByUser,
});

export const getEmployeesAndStats = filter => dispatch => {
  return Promise.all([
    dispatch(getEmployees(filter)),
    dispatch(getStatsForEmployees(filter)),
  ]);
};

/**
 * Roles
 */
export const rolesOnLoad = () => dispatch =>
  Promise.all([dispatch(getRoles({ includeInActive: true }))]);

export const myTeamRolesOnLoad = () => dispatch =>
  Promise.all([dispatch(getMyTeamRoles()), dispatch(getStatsForMyTeamRoles())]);

export const getMyTeamRoleEmployeesByRole = makeActionCreator({
  type: ACTIONS.GET_MY_TEAM_ROLE_EMPLOYEES_BY_ROLE,
  payload: roleService.getMyTeamRoleEmployees,
});

export const rolesOnUnload = () => dispatch => {
  dispatch(clearEmployees());
  dispatch(clearRoles());
};

export const getStatsForMyTeamRoleMembers = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_MY_TEAM_ROLE_MEMBERS,
  payload: statsService.getStatsForMyTeamRoleMembers,
});

export const getStatsForRoles = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_ROLES,
  payload: statsService.getStatsForRoles,
});

export const getStatsForMyTeamRoles = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_MY_TEAM_ROLES,
  payload: statsService.getStatsForMyTeamRoles,
});

export const getStatsForRole = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_ROLE,
  payload: statsService.getStatsForRole,
});

export const getStatsForMyTeamRole = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_MY_TEAM_ROLE,
  payload: statsService.getStatsForMyTeamRole,
});

export const getStatsForEmployeeRoles = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_EMPLOYEE_ROLES,
  payload: statsService.getStatsForEmployeeRoles,
});

export const getStatsForEmployeeRole = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_EMPLOYEE_ROLE,
  payload: statsService.getStatsForEmployeeRole,
});

export const getStatsForRoleMembers = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_ROLE_MEMBERS,
  payload: statsService.getStatsForRoleMembers,
});

export const getStatsForEmployees = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_EMPLOYEES,
  payload: statsService.getStatsForEmployees,
});

export const getStatsForMyTeamEmployees = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_MY_TEAM_EMPLOYEES,
  payload: statsService.getStatsForMyTeamEmployees,
});

export const getStatsForCourses = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_COURSES,
  payload: statsService.getStatsForCourses,
});

export const getStatsForCourse = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_COURSE,
  payload: statsService.getStatsForCourse,
});

export const getStatsForSkills = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_SKILLS,
  payload: statsService.getStatsForSkills,
});

export const getStatsForSkill = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_SKILL,
  payload: statsService.getStatsForSkill,
});

export const getStatsForSpecifications = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_SPECIFICATIONS,
  payload: statsService.getStatsForSpecifications,
});

export const getStatsForSpecification = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_SPECIFICATION,
  payload: statsService.getStatsForSpecification,
});

export const getStatsForServices = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_SERVICES,
  payload: statsService.getStatsForServices,
});

export const getStatsForService = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_SERVICE,
  payload: statsService.getStatsForService,
});

export const getStatsForCollections = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_COLLECTIONS,
  payload: statsService.getStatsForCollections,
});

export const getStatsForCollection = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_COLLECTION,
  payload: statsService.getStatsForCollection,
});

export const getStatsForExperience = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_EXPERIENCE_RECORD,
  payload: statsService.getStatsForExperience,
});

export const roleDetailsOnLoad = roleId => (dispatch, getState) =>
  Promise.all([
    dispatch(getRoleWithParents(roleId)).then(() => {
      const currentRole = getRoleFromState(getState(), roleId);

      dispatch(initialize(ROLE_FORM.ID, currentRole));

      return currentRole;
    }),
  ]);

export const clearRoles = () => dispatch =>
  dispatch({
    type: ACTIONS.CLEAR_ROLES,
  });

export const createRole = makeActionCreator({
  type: ACTIONS.CREATE_ROLE,
  payload: roleService.createRole,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Role successfully created",
  },
});

export const createRoleOnSubmit = toggleModal => dispatch => role =>
  dispatch(createRole(role.toJS()))
    .then(createdRole =>
      Promise.all([
        dispatch(getRoles({ includeInActive: true })),
        dispatch(getStatsForRole(createdRole.roleId)),
      ]),
    )
    .then(toggleModal);

export const deleteRole = makeActionCreator(
  {
    type: ACTIONS.DELETE_ROLE,
    payload: roleService.deleteRole,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Role successfully deleted",
    },
  },
  "roleId",
);

export const deleteRoleAskConfirmation = role => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_ROLE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Delete role",
      confirmButtonText: "Delete",
      description: `Are you sure you want to delete ${role.get("name")}?`,
    },
    payload: () =>
      dispatch(deleteRole(role.get("roleId"))).then(() =>
        dispatch(getRoles({ includeInActive: true })),
      ),
  });

export const getRoleWithParents = makeActionCreator({
  type: ACTIONS.GET_ROLE_WITH_PARENTS,
  payload: roleService.getRoleWithParents,
});

export const getRoles = makeActionCreator({
  type: ACTIONS.GET_ROLES,
  payload: roleService.getRoles,
});

export const getMyTeamRoles = makeActionCreator({
  type: ACTIONS.GET_ROLES,
  payload: roleService.getMyTeamRoles,
});

export const initializeCreateRole = () => dispatch => {
  dispatch(
    initialize(ROLE_FORM.ID, {
      [ROLE_FORM.PARENT_ID]: null,
      [ROLE_FORM.IS_ACTIVE]: true,
      [ROLE_FORM.IS_PRIMARY]: false,
      [ROLE_FORM.COUNTRIES]: EMPTY_LIST,
    }),
  );
  dispatch(toggleModal({ modalId: CREATE_ROLE_MODAL_ID }));
};

export const initializeEditRole = role => dispatch => {
  dispatch(initialize(ROLE_FORM.ID, role));
};

export const updateRole = makeActionCreator({
  type: ACTIONS.UPDATE_ROLE,
  payload: roleService.updateRole,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Role successfully updated",
    [NOTIFICATION_VARIANTS.INFO]: "Updating...",
  },
});

export const getRole = makeActionCreator({
  type: ACTIONS.GET_ROLE,
  payload: roleService.getRole,
});

export const getAvailableEmployeeForRole = makeActionCreator(
  {
    type: ACTIONS.GET_AVAILABLE_EMPLOYEES_FOR_ROLE,
    payload: roleService.getAvailableRoleEmployees,
  },
  "roleId",
);

export const getAvailableCoursesForRole = makeActionCreator(
  {
    type: ACTIONS.GET_AVAILABLE_COURSES_FOR_ROLE,
    payload: roleService.getAvailableCoursesForRole,
  },
  "roleId",
);

export const getAvailableSkillsForRole = makeActionCreator(
  {
    type: ACTIONS.GET_AVAILABLE_SKILLS_FOR_ROLE,
    payload: roleService.getAvailableSkillsForRole,
  },
  "roleId",
);

/**
 * Employee Experience
 */

export const employeeExperienceRecordsOnLoad = employeeId => dispatch => {
  Promise.all([
    dispatch(getEmployeeExperienceRecords(employeeId)),
    dispatch(getStatsForExperienceRecordsForEmployee(employeeId)),
  ]);
};

export const getEmployeeExperienceRecords = makeActionCreator({
  type: ACTIONS.GET_EMPLOYEE_EXPERIENCE_RECORDS,
  payload: employeeService.getEmployeeExperienceRecords,
});

export const getEmployeeExperienceRecordsForReport = makeActionCreator({
  type: ACTIONS.GET_EMPLOYEE_EXPERIENCE_RECORDS_FOR_REPORT,
  payload: employeeService.getEmployeeExperienceRecordsForReport,
});

export const getStatsForExperienceRecordsForEmployee = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_EXPERIENCE_RECORDS_FOR_EMPLOYEE,
  payload: statsService.getStatsForExperienceRecordsForEmployee,
});

/**
 * Employee Role
 */

export const getAvailableRolesForEmployee = makeActionCreator(
  {
    type: ACTIONS.GET_AVAILABLE_ROLES_FOR_EMPLOYEE,
    payload: employeeService.getAvailableEmployeeRoles,
  },
  "employeeId",
);

export const getEmployeeRoles = makeActionCreator(
  {
    type: ACTIONS.GET_EMPLOYEE_ROLES,
    payload: employeeService.getEmployeeRoles,
  },
  "employeeId",
);

export const createEmployeeRoles = makeActionCreator(
  {
    type: ACTIONS.CREATE_EMPLOYEE_ROLES,
    payload: employeeService.createEmployeeRoles,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Roles successfully added",
    },
  },
  "employeeId",
);

export const deleteEmployeeRole = makeActionCreator(
  {
    type: ACTIONS.DELETE_EMPLOYEE_ROLE,
    payload: employeeService.deleteEmployeeRole,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Role successfully removed",
    },
  },
  "employeeId",
  "roleId",
);

export const createEmployeeRoleOnSubmit = (
  employeeId,
  roleIdList,
) => dispatch =>
  dispatch(createEmployeeRoles(employeeId, roleIdList)).then(() =>
    Promise.all([
      dispatch(getStatsForEmployee(employeeId)),
      dispatch(getEmployeeRoles(employeeId)),
      dispatch(getStatsForEmployeeRoles(employeeId)),
    ]),
  );

export const deleteEmployeeRoleAskConfirmation = employeeRole => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_EMPLOYEE_ROLE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove role",
      confirmButtonText: "Remove",
      description: `Are you sure you want to remove ${employeeRole.getIn([
        "role",
        "name",
      ])}?`,
    },
    payload: () =>
      dispatch(
        deleteEmployeeRole(
          employeeRole.get("employeeId"),
          employeeRole.getIn(["role", "roleId"]),
        ),
      ).then(() =>
        Promise.all([
          dispatch(getStatsForEmployee(employeeRole.get("employeeId"))),
          dispatch(getEmployeeRoles(employeeRole.get("employeeId"))),
        ]),
      ),
  });

/**
 * RoleCourse
 */
export const getRoleCoursesByRole = makeActionCreator(
  {
    type: ACTIONS.GET_ROLE_COURSES_BY_ROLE,
    payload: roleService.getRoleCoursesByRole,
  },
  "roleId",
);

export const roleCoursesOnUnload = () => dispatch =>
  dispatch({
    type: ACTIONS.CLEAR_ROLE_COURSES,
  });

export const createRoleCourses = makeActionCreator(
  {
    type: ACTIONS.CREATE_ROLE_COURSES,
    payload: roleService.createRoleCourses,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Courses successfully added to the role",
    },
  },
  "roleId",
);

export const createRoleCourseOnSubmit = (roleId, courseIdList) => dispatch =>
  dispatch(createRoleCourses(roleId, courseIdList)).then(() =>
    dispatch(getStatsForRole(roleId)),
  );

export const deleteRoleCourse = makeActionCreator(
  {
    type: ACTIONS.DELETE_ROLE_COURSE,
    payload: roleService.deleteRoleCourse,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Course successfully removed from the role",
    },
  },
  "roleId",
  "courseId",
  "roleCourseId",
);

export const deleteRoleCourseAskConfirmation = roleCourse => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_ROLE_COURSE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove Course from Role",
      confirmButtonText: "Delete",
      description: `Are you sure you want to remove "${roleCourse.get(
        "courseName",
      )}" from "${roleCourse.get("roleName")}"?`,
    },
    payload: () =>
      dispatch(
        deleteRoleCourse(
          roleCourse.get("roleId"),
          roleCourse.get("courseId"),
          roleCourse.get("roleCourseId"),
        ),
      ).then(() => dispatch(getStatsForRole(roleCourse.get("roleId")))),
  });

const updateRoleCourse = makeActionCreator({
  type: ACTIONS.UPDATE_ROLE_COURSE,
  payload: roleService.updateRoleCourse,
});

export const updateRoleCourseAndGetRoleStats = roleCourse => dispatch =>
  dispatch(
    updateRoleCourse(
      roleCourse.get("roleId"),
      roleCourse.get("courseId"),
      roleCourse,
    ),
  ).then(() => dispatch(getStatsForRole(roleCourse.get("roleId"))));

/**
 * RoleEmployee
 */
export const getRoleEmployeesByRole = makeActionCreator({
  type: ACTIONS.GET_ROLE_EMPLOYEES,
  payload: roleService.getRoleEmployees,
});

export const roleEmployeesOnUnload = () => dispatch =>
  dispatch({
    type: ACTIONS.CLEAR_ROLE_EMPLOYEES,
  });

export const createRoleEmployees = makeActionCreator(
  {
    type: ACTIONS.CREATE_ROLE_EMPLOYEE,
    payload: (roleId, employeeIdList) =>
      roleService.createRoleEmployees(roleId, employeeIdList),
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Employees successfully added to the role",
    },
  },
  "roleId",
);

export const createRoleEmployeeOnSubmit = (
  roleId,
  employeeIdList,
) => dispatch =>
  dispatch(createRoleEmployees(roleId, employeeIdList)).then(() =>
    Promise.all([
      dispatch(getStatsForRole(roleId)),
      dispatch(getStatsForRoleMembers(roleId)),
      dispatch(getRoleEmployeesByRole(roleId)),
    ]),
  );

export const initializeCreateRoleEmployee = roleId => dispatch => () => {
  dispatch(getEmployees());
  dispatch(
    initialize(CREATE_ROLE_EMPLOYEE_FORM.ID, {
      [CREATE_ROLE_EMPLOYEE_FORM.ROLE_ID]: roleId,
    }),
  );
};

export const deleteRoleEmployee = makeActionCreator(
  {
    type: ACTIONS.DELETE_ROLE_EMPLOYEE,
    payload: roleService.deleteRoleEmployee,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Employee successfully removed from the role",
    },
  },
  "roleId",
  "employeeId",
);

export const deleteRoleEmployeeAskConfirmation = (
  roleId,
  employeeId,
) => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_ROLE_EMPLOYEE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove Employee from Role",
      confirmButtonText: "Delete",
      description: `Are you sure you want to remove this member?`,
    },
    payload: () =>
      dispatch(deleteRoleEmployee(roleId, employeeId)).then(() =>
        dispatch(getStatsForRole(roleId)),
      ),
  });

/**
 * Education File
 */
export const uploadEducationFile = makeActionCreator(
  {
    type: ACTIONS.UPLOAD_EDUCATION_FILE,
    payload: employeeService.uploadEducationFile,
  },
  "employeeId",
  "educationId",
);

export const deleteEducationFile = makeActionCreator({
  type: ACTIONS.DELETE_EDUCATION_FILE,
  payload: employeeService.deleteEducationFile,
});

export const previewOrSaveEducationFile = (
  employeeId,
  educationId,
  file,
) => () =>
  file.educationFileId
    ? employeeService.previewEducationFile(
        employeeId,
        educationId,
        file.educationFileId,
      )
    : saveFile(file);

/**
 * Work History File
 */
export const uploadWorkHistoryFile = makeActionCreator(
  {
    type: ACTIONS.UPLOAD_WORK_HISTORY_FILE,
    payload: employeeService.uploadWorkHistoryFile,
  },
  "employeeId",
  "workHistoryId",
);

export const deleteWorkHistoryFile = makeActionCreator({
  type: ACTIONS.DELETE_WORK_HISTORY_FILE,
  payload: employeeService.deleteWorkHistoryFile,
});

export const previewOrSaveWorkHistoryFile = (
  employeeId,
  workHistoryId,
  file,
) => () =>
  file.workHistoryFileId
    ? employeeService.previewWorkHistoryFile(
        employeeId,
        workHistoryId,
        file.workHistoryFileId,
      )
    : saveFile(file);

export const deleteWorkHistoryFileAskConfirmation = workHistoryFile => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_WORK_HISTORY_FILE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove work history file",
      confirmButtonText: "Remove",
      description: `Are you sure you want to remove file ${workHistoryFile.name} from work history?`,
    },
    payload: () =>
      dispatch(
        deleteWorkHistoryFile(
          workHistoryFile.employeeId,
          workHistoryFile.workHistoryId,
          workHistoryFile.workHistoryFileId,
        ),
      ).then(() =>
        dispatch(
          getWorkHistory(
            workHistoryFile.employeeId,
            workHistoryFile.workHistoryId,
          ),
        ),
      ),
  });

/**
 * Employee Course Attendance File
 */
export const uploadEmployeeCourseAttendanceFile = (
  employeeId,
  courseId,
  attendanceId,
  file,
) => dispatch => {
  const payload = employeeCourseService.uploadEmployeeCourseAttendanceFile(
    employeeId,
    courseId,
    attendanceId,
    file,
  );

  dispatch({
    type: ACTIONS.UPLOAD_EMPLOYEE_COURSE_ATTENDANCE_FILE,
    payload,
  });

  return payload;
};

export const previewOrSaveEmployeeCourseAttendanceFile = (
  employeeId,
  courseId,
  attendanceId,
  file,
) => () =>
  file.employeeCourseAttendanceFileId
    ? employeeCourseService.previewEmployeeCourseAttendanceFile(
        employeeId,
        courseId,
        attendanceId,
        file.employeeCourseAttendanceFileId,
      )
    : saveFile(file);

export const previewEmployeeCourseAttendanceFile = (
  employeeId,
  courseId,
  attendanceId,
  employeeCourseAttendanceFileId,
) => () =>
  employeeCourseService.previewEmployeeCourseAttendanceFile(
    employeeId,
    courseId,
    attendanceId,
    employeeCourseAttendanceFileId,
  );

/**
 * Employee Education
 */
export const getEmployeeEducationItems = makeActionCreator({
  type: ACTIONS.GET_EMPLOYEE_EDUCATION_ITEMS,
  payload: employeeService.getEmployeeEducationItems,
});

export const getEmployeeEducationItem = makeActionCreator({
  type: ACTIONS.GET_EMPLOYEE_EDUCATION_ITEM,
  payload: employeeService.getEmployeeEducationItem,
});

export const createEmployeeEducationItem = makeActionCreator({
  type: ACTIONS.CREATE_EMPLOYEE_EDUCATION_ITEM,
  payload: employeeService.createEmployeeEducationItem,
});

export const createAndApproveEmployeeEducationItem = makeActionCreator({
  type: ACTIONS.CREATE_AND_APPROVE_EMPLOYEE_EDUCATION_ITEM,
  payload: employeeService.createAndApproveEmployeeEducationItem,
});

export const updateEmployeeEducationItem = makeActionCreator(
  {
    type: ACTIONS.UPDATE_EMPLOYEE_EDUCATION_ITEM,
    payload: employeeService.updateEmployeeEducationItem,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Education successfully updated",
    },
  },
  "employeeId",
  "id",
);

export const updateEmployeeEducationApproval = (
  employeeId,
  id,
  education,
) => dispatch => {
  const actionText =
    education.approvalStatus === APPROVAL_STATUS.APPROVED
      ? "approved"
      : "not approved";
  dispatch({
    type: ACTIONS.UPDATE_EMPLOYEE_EDUCATION_APPROVAL,
    payload: () =>
      employeeService.updateEmployeeEducationApproval(
        employeeId,
        id,
        education,
      ),
    employeeId,
    id,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: `Education ${actionText}`,
    },
  });
};

export const removeEmployeeEducationApproval = makeActionCreator(
  {
    type: ACTIONS.REMOVE_EMPLOYEE_EDUCATION_APPROVAL,
    payload: employeeService.removeEmployeeEducationApproval,
  },
  "employeeId",
  "id",
);

export const deleteEmployeeEducationItem = makeActionCreator(
  {
    type: ACTIONS.DELETE_EMPLOYEE_EDUCATION_ITEM,
    payload: employeeService.deleteEmployeeEducationItem,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Education successfully deleted",
    },
  },
  "employeeId",
  "educationId",
);

export const createEmployeeEducationOnSubmit = (
  employeeId,
  approveOnCreate,
) => dispatch => toggleModal => education => {
  return dispatch(
    approveOnCreate
      ? createAndApproveEmployeeEducationItem(employeeId, education.toJS())
      : createEmployeeEducationItem(employeeId, education.toJS()),
  )
    .then(EducationResponse => {
      Promise.all([
        education
          .get("files", EMPTY_SET)
          .map(file =>
            dispatch(
              uploadEducationFile(
                employeeId,
                EducationResponse.educationId,
                file,
              ),
            ),
          ),
        dispatch(getStatsForEmployee(employeeId)),
      ]);
    })
    .then(toggleModal);
};

export const editEmployeeEducationOnSubmit = employeeId => (
  dispatch,
  getState,
) => toggleModal => education => {
  const initialFiles = getFormInitialValuesFromState(
    getState(),
    EDUCATION_FORM.ID,
  ).get("files");

  const updatedFiles = education.get("files");

  const filesToDelete = initialFiles.subtract(updatedFiles);

  const filesToUpload = updatedFiles.filter(file => !file.educationFileId);

  return Promise.all(
    filesToDelete.map(file =>
      dispatch(
        deleteEducationFile(
          employeeId,
          education.get("educationId"),
          file.educationFileId,
        ),
      ),
    ),
  )
    .then(() =>
      dispatch(
        updateEmployeeEducationItem(
          employeeId,
          education.get("educationId"),
          education.toJS(),
        ),
      ),
    )
    .then(() => {
      return Promise.all(
        filesToUpload.map(file =>
          dispatch(
            uploadEducationFile(employeeId, education.get("educationId"), file),
          ),
        ),
      );
    })
    .then(toggleModal);
};

export const initializeEditEmployeeEducation = (
  education,
  toggleModal,
) => dispatch => {
  dispatch(initialize(EDUCATION_FORM.ID, education));
  toggleModal();
};

export const deleteEmployeeEducationFileAskConfirmation = employeeEducationFile => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_EDUCATION_FILE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove education file",
      confirmButtonText: "Remove",
      description: `Are you sure you want to remove file ${employeeEducationFile.name} from education?`,
    },
    payload: () =>
      dispatch(
        deleteEducationFile(
          employeeEducationFile.employeeId,
          employeeEducationFile.educationId,
          employeeEducationFile.educationFileId,
        ),
      ).then(() =>
        dispatch(
          getEmployeeEducationItem(
            employeeEducationFile.employeeId,
            employeeEducationFile.educationId,
          ),
        ),
      ),
  });

export const deleteEmployeeEducationItemAskConfirmation = education => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_EMPLOYEE_EDUCATION_ITEM_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove Education",
      confirmButtonText: "Delete",
      description: `Are you sure you want to remove '${education.get(
        "title",
      )}'?`,
    },
    payload: () =>
      dispatch(
        deleteEmployeeEducationItem(
          education.get("employeeId"),
          education.get("educationId"),
        ),
      ).then(() => dispatch(getStatsForEmployee(education.get("employeeId")))),
  });

export const deleteEmployeeEducationItemsAskConfirmation = (
  employeeId,
  educationIds,
) => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_EMPLOYEE_EDUCATION_ITEMS_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove Education",
      confirmButtonText: "Remove",
      description: `Are you sure you want to remove these items?`,
    },
    payload: () =>
      Promise.all(
        educationIds.map(educationId =>
          dispatch(deleteEmployeeEducationItem(employeeId, educationId)),
        ),
      ).then(() => dispatch(getStatsForEmployee(employeeId))),
  });

/*
 * Work History Categories
 */
export const getWorkHistoryCategories = makeActionCreator({
  type: ACTIONS.GET_WORK_HISTORY_CATEGORIES,
  payload: dataService.getWorkHistoryCategories,
});

/**
 * Work History
 */
export const getWorkHistory = makeActionCreator({
  type: ACTIONS.GET_WORK_HISTORY,
  payload: employeeService.getWorkHistory,
});

export const workHistoryOnUnload = () => dispatch =>
  dispatch({ type: ACTIONS.CLEAR_WORK_HISTORY });

export const createWorkHistory = makeActionCreator({
  type: ACTIONS.CREATE_WORK_HISTORY,
  payload: employeeService.createWorkHistory,
});

export const createAndApproveWorkHistory = makeActionCreator({
  type: ACTIONS.CREATE_AND_APPROVE_WORK_HISTORY,
  payload: employeeService.createAndApproveWorkHistory,
});

export const deleteWorkHistory = makeActionCreator(
  {
    type: ACTIONS.DELETE_WORK_HISTORY,
    payload: employeeService.deleteWorkHistory,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Work history successfully removed",
    },
  },
  "employeeId",
  "workHistoryId",
);

export const updateWorkHistory = makeActionCreator(
  {
    type: ACTIONS.UPDATE_WORK_HISTORY,
    payload: employeeService.updateWorkHistory,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Work history successfully updated",
    },
  },
  "employeeId",
  "id",
);

export const updateWorkHistoryApproval = (
  employeeId,
  id,
  workHistory,
) => dispatch => {
  const actionText =
    workHistory.approvalStatus === APPROVAL_STATUS.APPROVED
      ? "approved"
      : "not approved";
  dispatch({
    type: ACTIONS.UPDATE_WORK_HISTORY_APPROVAL,
    payload: () =>
      employeeService.updateWorkHistoryApproval(employeeId, id, workHistory),
    employeeId,
    id,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: `Work history ${actionText}`,
    },
  });
};

export const removeWorkHistoryApproval = makeActionCreator(
  {
    type: ACTIONS.REMOVE_WORK_HISTORY_APPROVAL,
    payload: employeeService.removeWorkHistoryApproval,
  },
  "employeeId",
  "id",
);

export const createWorkHistoryOnSubmit = (
  employeeId,
  approveOnCreate,
) => dispatch => toggleModal => workHistory => {
  return dispatch(
    approveOnCreate
      ? createAndApproveWorkHistory(employeeId, workHistory.toJS())
      : createWorkHistory(employeeId, workHistory.toJS()),
  )
    .then(WorkHistoryResponse => {
      Promise.all([
        workHistory
          .get("files", EMPTY_SET)
          .map(file =>
            dispatch(
              uploadWorkHistoryFile(
                employeeId,
                WorkHistoryResponse.workHistoryId,
                file,
              ),
            ),
          ),
        dispatch(getStatsForEmployee(employeeId)),
      ]);
    })
    .then(toggleModal);
};

export const deleteWorkHistoryItemAskConfirmation = workHistory => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_WORK_HISTORY_ITEM_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove Work History from Employee",
      confirmButtonText: "Remove",
      description: `Are you sure you want to remove '${workHistory.get(
        "title",
      )}'?`,
    },
    payload: () =>
      dispatch(
        deleteWorkHistory(
          workHistory.get("employeeId"),
          workHistory.get("workHistoryId"),
        ),
      ).then(() =>
        dispatch(getStatsForEmployee(workHistory.get("employeeId"))),
      ),
  });

export const deleteWorkHistoryItemsAskConfirmation = (
  employeeId,
  workHistoryIds,
) => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_WORK_HISTORY_ITEMS_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove Work History from Employee",
      confirmButtonText: "Delete",
      description: "Are you sure you want to remove these Work history items?",
    },
    payload: () =>
      Promise.all(
        workHistoryIds.map(workHistoryId =>
          dispatch(deleteWorkHistory(employeeId, workHistoryId)),
        ),
      ).then(() => dispatch(getStatsForEmployee(employeeId))),
  });

export const getExperienceRecordsReportForEmployee = makeActionCreator({
  type: ACTIONS.GENERATE_EMPLOYEE_EXPERIENCE_RECORDS_REPORT,
  payload: experienceService.getExperienceRecordsReportForEmployee,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]:
      "Experience records report generated successfully ",
  },
});

export const generateExperienceRecordsReportForEmployee = (
  employeeId,
  selectedCategories,
) => dispatch =>
  dispatch(
    getExperienceRecordsReportForEmployee(employeeId, selectedCategories),
  ).then(([downloadedFile, fileName]) => saveFile(downloadedFile, fileName));

export const editWorkHistoryOnSubmit = toggleModal => (
  dispatch,
  getState,
) => workHistory => {
  const employeeId = workHistory.get("employeeId");
  const workHistoryId = workHistory.get("workHistoryId");

  const initialFiles = getFormInitialValuesFromState(
    getState(),
    WORK_HISTORY_FORM.ID,
  ).get("files");

  const updatedFiles = workHistory.get("files");

  const filesToDelete = initialFiles.subtract(updatedFiles);

  const filesToUpload = updatedFiles.filter(file => !file.workHistoryFileId);

  return Promise.all(
    filesToDelete.map(file =>
      dispatch(
        deleteWorkHistoryFile(
          employeeId,
          workHistoryId,
          file.workHistoryFileId,
        ),
      ),
    ),
  )
    .then(() =>
      dispatch(updateWorkHistory(employeeId, workHistoryId, workHistory)),
    )
    .then(() => {
      return Promise.all(
        filesToUpload.map(file =>
          dispatch(uploadWorkHistoryFile(employeeId, workHistoryId, file)),
        ),
      );
    })
    .then(toggleModal);
};

export const initializeCreateWorkHistory = employeeId => dispatch => () => {
  dispatch(getWorkHistoryCategories());
};

export const initializeEditWorkHistory = (
  workHistory,
  toggleModal,
) => dispatch => {
  dispatch(getWorkHistoryCategories());
  dispatch(initialize(WORK_HISTORY_FORM.ID, workHistory));
  toggleModal();
};

/**
 * Education Category
 */
export const getEducationCategories = makeActionCreator({
  type: ACTIONS.GET_EDUCATION_CATEGORIES,
  payload: dataService.getEducationCategories,
});

/**
 * Employee CV
 */
export const generateEmployeeCV = makeActionCreator({
  type: ACTIONS.GET_EMPLOYEE_CV,
  payload: employeeService.generateEmployeeCV,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "CV generated successfully ",
  },
});

export const generateEmployeesCVs = makeActionCreator({
  type: ACTIONS.GET_EMPLOYEES_CVS,
  payload: employeeService.generateEmployeesCVs,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "CVs generated successfully ",
  },
});

export const generateAndDownloadEmployeeCV = employeeId => dispatch => toggleModal => CVOptionsFormValues =>
  dispatch(generateEmployeeCV(employeeId, CVOptionsFormValues.toJS()))
    .then(([downloadedFile, filename]) => saveFile(downloadedFile, filename))
    .then(toggleModal);

export const generateAndDownloadEmployeesCVs = toggleModal => dispatch => CVOptionsFormValues =>
  dispatch(generateEmployeesCVs(CVOptionsFormValues.toJS()))
    .then(([downloadedFile, filename]) => saveFile(downloadedFile, filename))
    .then(toggleModal);

/**
 * Course Gap Report
 */
export const getCourseGapReport = filters => dispatch => {
  const queryString = toQueryString(filters);

  const payload = reportService.getCourseGapReport(queryString);

  dispatch({
    payload,
    filters,
    type: ACTIONS.GET_COURSE_GAP_REPORT,
  });

  dispatch(replace({ search: queryString }));

  return payload;
};

export const getMyTeamCourseGapReport = filters => dispatch => {
  const queryString = toQueryString(filters);

  dispatch({
    type: ACTIONS.GET_COURSE_GAP_REPORT,
    payload: () => reportService.getMyTeamCourseGapReport(queryString),
    filters,
  });
  dispatch(replace({ search: queryString }));
};

export const clearCourseGapReport = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_COURSE_GAP_REPORT,
  });
  dispatch(replace({ search: null }));
};

export const downloadCourseGapReport = makeActionCreator(
  {
    type: ACTIONS.DOWNLOAD_COURSE_GAP_REPORT,
    payload: reportService.downloadCourseGapReport,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Gap Report Downloaded successfully ",
    },
  },
  "queryString",
);

export const downloadAndSaveCourseGapReport = () => (dispatch, getState) => {
  const appliedFilters = getGapReportAppliedFiltersFromState(getState());

  return dispatch(
    downloadCourseGapReport(toQueryString(appliedFilters)),
  ).then(([downloadedFile, filename]) => saveFile(downloadedFile, filename));
};

export const downloadMyTeamCourseGapReport = makeActionCreator(
  {
    type: ACTIONS.DOWNLOAD_COURSE_GAP_REPORT,
    payload: reportService.downloadMyTeamCourseGapReport,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Gap Report Downloaded successfully ",
    },
  },
  "queryString",
);

export const downloadAndSaveMyTeamCourseGapReport = () => (
  dispatch,
  getState,
) => {
  const appliedFilters = getGapReportAppliedFiltersFromState(getState());

  return dispatch(
    downloadMyTeamCourseGapReport(toQueryString(appliedFilters)),
  ).then(([downloadedFile, filename]) => saveFile(downloadedFile, filename));
};

export const hrCourseGapReportOnLoad = () => dispatch =>
  Promise.all([
    dispatch(getEmployees()),
    dispatch(getCourses()),
    dispatch(getDepartments()),
  ]);

export const myTeamCourseGapReportOnLoad = () => dispatch =>
  Promise.all([
    dispatch(getMyTeamCourses()),
    dispatch(getMyTeamDepartments()),
    dispatch(getMyTeamEmployeesByUser()),
  ]);

/**
 * Role Gap Report
 */
export const updateGapReportOptions = option => dispatch => {
  dispatch({
    type: ACTIONS.UPDATE_GAP_REPORT_OPTIONS,
    option,
  });
};

export const getRoleGapReport = filters => dispatch => {
  const queryString = toQueryString(filters);

  dispatch({
    type: ACTIONS.GET_ROLE_GAP_REPORT,
    payload: () => reportService.getRoleGapReport(queryString),
    filters,
  });
  dispatch(replace({ search: queryString }));
};

export const getEmployeeRoleGapReport = (employeeId, filters) => dispatch => {
  const queryString = toQueryString(filters);

  dispatch({
    type: ACTIONS.GET_EMPLOYEE_ROLE_GAP_REPORT,
    payload: () =>
      reportService.getEmployeeRoleGapReport(employeeId, queryString),
    filters,
  });
  dispatch(replace({ search: queryString }));
};

export const getMyTeamRoleGapReport = filters => dispatch => {
  const queryString = toQueryString(filters);

  dispatch({
    type: ACTIONS.GET_ROLE_GAP_REPORT,
    payload: () => reportService.getMyTeamRoleGapReport(queryString),
    filters,
  });
  dispatch(replace({ search: queryString }));
};

export const clearRoleGapReport = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_ROLE_GAP_REPORT,
  });
  dispatch(replace({ search: null }));
};

export const clearEmployeeRoleGapReport = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_ROLE_GAP_REPORT,
  });
  dispatch(replace({ search: null }));
};

export const hrRoleGapReportOnLoad = () => dispatch =>
  Promise.all([
    dispatch(getRoles()),
    dispatch(getSkills()),
    dispatch(getCourses()),
    dispatch(getEmployees()),
    dispatch(getDepartments()),
  ]);

export const myTeamRoleGapReportOnLoad = () => dispatch =>
  Promise.all([
    dispatch(getSkills()),
    dispatch(getCourses()),
    dispatch(getMyTeamRoles()),
    dispatch(getMyTeamDepartments()),
    dispatch(getMyTeamEmployeesByUser()),
  ]);

export const downloadRoleGapReport = makeActionCreator(
  {
    type: ACTIONS.DOWNLOAD_ROLE_GAP_REPORT,
    payload: reportService.downloadRoleGapReport,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Gap Report Downloaded successfully ",
    },
  },
  "queryString",
);

export const downloadMyTeamRoleGapReport = makeActionCreator(
  {
    type: ACTIONS.DOWNLOAD_ROLE_GAP_REPORT,
    payload: reportService.downloadMyTeamRoleGapReport,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Gap Report Downloaded successfully ",
    },
  },
  "queryString",
);

export const downloadAndSaveRoleGapReport = () => (dispatch, getState) => {
  const appliedFilters = getGapReportAppliedFiltersFromState(getState());

  return dispatch(
    downloadRoleGapReport(toQueryString(appliedFilters)),
  ).then(([downloadedFile, filename]) => saveFile(downloadedFile, filename));
};

export const downloadAndSaveMyTeamRoleGapReport = () => (
  dispatch,
  getState,
) => {
  const appliedFilters = getGapReportAppliedFiltersFromState(getState());

  return dispatch(
    downloadMyTeamRoleGapReport(toQueryString(appliedFilters)),
  ).then(([downloadedFile, filename]) => saveFile(downloadedFile, filename));
};

/**
 * Course Groups
 */
export const getCourseGroups = makeActionCreator({
  type: ACTIONS.GET_COURSE_GROUPS,
  payload: dataService.getCourseGroups,
});

export const getCourseGroup = makeActionCreator({
  type: ACTIONS.GET_COURSE_GROUP,
  payload: dataService.getCourseGroup,
});

export const createCourseGroup = makeActionCreator({
  type: ACTIONS.CREATE_COURSE_GROUP,
  payload: dataService.createCourseGroup,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Course group successfully created",
  },
});

export const updateCourseGroup = makeActionCreator({
  type: ACTIONS.UPDATE_COURSE_GROUP,
  payload: dataService.updateCourseGroup,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Course group successfully updated",
  },
});

export const deleteCourseGroup = makeActionCreator(
  {
    type: ACTIONS.DELETE_COURSE_GROUP,
    payload: dataService.deleteCourseGroup,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Course group successfully deleted",
    },
  },
  "courseGroupId",
);

export const deleteCourseGroupAskConfirmation = courseGroup => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_COURSE_GROUP_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Delete course group",
      confirmButtonText: "Delete",
      description: `Are you sure you want delete ${courseGroup.get("name")}`,
    },
    payload: () =>
      dispatch(deleteCourseGroup(courseGroup.get("courseGroupId"))),
  });

export const createCourseGroupOnSubmit = toggleModal => dispatch => courseGroup =>
  dispatch(createCourseGroup(courseGroup.toJS())).then(toggleModal);

export const editCourseGroupOnSubmit = toggleModal => dispatch => courseGroup =>
  dispatch(
    updateCourseGroup(courseGroup.get("courseGroupId"), courseGroup.toJS()),
  ).then(toggleModal);

export const initializeEditCourseGroup = (
  courseGroup,
  toggleModal,
) => dispatch => {
  dispatch(initialize(COURSE_GROUP_FORM.ID, courseGroup));
  toggleModal();
};

/**
 * Course Type
 */
export const getCourseTypes = makeActionCreator({
  type: ACTIONS.GET_COURSE_TYPES,
  payload: dataService.getCourseTypes,
});

export const getCourseType = makeActionCreator({
  type: ACTIONS.GET_COURSE_TYPE,
  payload: dataService.getCourseType,
});

export const createCourseType = makeActionCreator({
  type: ACTIONS.CREATE_COURSE_TYPE,
  payload: dataService.createCourseType,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Course type successfully created",
  },
});

export const updateCourseType = makeActionCreator({
  type: ACTIONS.UPDATE_COURSE_TYPE,
  payload: dataService.updateCourseType,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Course type successfully updated",
  },
});

export const deleteCourseType = makeActionCreator(
  {
    type: ACTIONS.DELETE_COURSE_TYPE,
    payload: dataService.deleteCourseType,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Course type successfully deleted",
    },
  },
  "courseTypeId",
);

export const deleteCourseTypeAskConfirmation = courseType => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_COURSE_TYPE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Delete course type",
      confirmButtonText: "Delete",
      description: `Are you sure you want delete ${courseType.get("name")}`,
    },
    payload: () => dispatch(deleteCourseType(courseType.get("courseTypeId"))),
  });

export const initializeEditCourseType = (
  courseType,
  toggleModal,
) => dispatch => {
  dispatch(initialize(COURSE_TYPE_FORM.ID, courseType));
  toggleModal();
};

export const createCourseTypeOnSubmit = toggleModal => dispatch => courseType =>
  dispatch(createCourseType(courseType.toJS())).then(toggleModal);

export const editCourseTypeOnSubmit = toggleModal => dispatch => courseType =>
  dispatch(
    updateCourseType(courseType.get("courseTypeId"), courseType.toJS()),
  ).then(toggleModal);

/**
 * CV
 */

export const initializeGenerateEmployeesCVs = employeeIds => dispatch => {
  dispatch(toggleModal({ modalId: GENERATE_EMPLOYEES_CVS_MODAL_ID }));
  dispatch(
    initialize(EMPLOYEE_CV_OPTIONS_FORM.ID, {
      [EMPLOYEE_CV_OPTIONS_FORM.INCLUDE_COURSE]: true,
      [EMPLOYEE_CV_OPTIONS_FORM.INCLUDE_EDUCATION]: true,
      [EMPLOYEE_CV_OPTIONS_FORM.INCLUDE_WORK_EXPERIENCE]: true,
      [EMPLOYEE_CV_OPTIONS_FORM.EMPLOYEE_IDS]: employeeIds,
    }),
  );
};

/**
 * Approval
 */
export const getEmployeeCoursesNeedingApproval = makeActionCreator({
  type: ACTIONS.GET_EMPLOYEE_COURSES_APPROVAL,
  payload: employeeService.getEmployeeCoursesNeedingApproval,
});

export const getEmployeeCoursesAttendancesNeedingApproval = makeActionCreator({
  type: ACTIONS.GET_EMPLOYEE_COURSES_ATTENDANCES_APPROVAL,
  payload: employeeService.getEmployeeCoursesAttendancesNeedingApproval,
});

export const getEmployeeEducationRequiringApproval = makeActionCreator({
  type: ACTIONS.GET_EMPLOYEE_EDUCATION_APPROVAL,
  payload: employeeService.getEmployeeEducationRequiringApproval,
});

export const getEmployeeWorkHistoryRequiringApproval = makeActionCreator({
  type: ACTIONS.GET_EMPLOYEE_WORK_HISTORY_APPROVAL,
  payload: employeeService.getEmployeeWorkHistoryRequiringApproval,
});

export const approvalOnLoad = () => dispatch => {
  const payload = Promise.all([
    dispatch(getEmployeeCoursesAttendancesNeedingApproval()),
    dispatch(getEmployeeEducationRequiringApproval()),
    dispatch(getEmployeeWorkHistoryRequiringApproval()),
  ]);

  dispatch({
    type: ACTIONS.APPROVAL_ON_LOAD,
    payload,
  });

  return payload;
};

export const getHrStats = makeActionCreator({
  type: ACTIONS.GET_HR_STATS,
  payload: statsService.getHrStats,
});

export const getSettingsStats = makeActionCreator({
  type: ACTIONS.GET_SETTINGS_STATS,
  payload: statsService.getSettingsStats,
});

export const getMyTeamStats = makeActionCreator({
  type: ACTIONS.GET_MY_TEAM_STATS,
  payload: statsService.getMyTeamStats,
});

export const getVerifierStats = makeActionCreator({
  type: ACTIONS.GET_VERIFIER_STATS,
  payload: statsService.getVerifierStats,
});

export const getCurrentEmployeeStats = makeActionCreator({
  type: ACTIONS.GET_CURRENT_EMPLOYEE_STATS,
  payload: statsService.getCurrentEmployeeStats,
});

export const getStatsForEmployee = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_EMPLOYEE,
  payload: statsService.getStatsForEmployee,
});

export const employeeCourseApprovalOnChange = employeeCourse => dispatch =>
  dispatch({
    type: ACTIONS.EMPLOYEE_COURSE_APPROVAL_ON_CHANGE,
    payload: employeeCourse,
    employeeCourseId: employeeCourse.get("employeeCourseId"),
  });

export const workHistoryApprovalOnChange = workHistory => dispatch =>
  dispatch({
    type: ACTIONS.WORK_HISTORY_APPROVAL_ON_CHANGE,
    payload: workHistory,
    workHistoryId: workHistory.get("workHistoryId"),
  });

export const educationApprovalOnChange = education => dispatch =>
  dispatch({
    type: ACTIONS.EDUCATION_APPROVAL_ON_CHANGE,
    payload: education,
    educationId: education.get("educationId"),
  });

export const courseDetailsOnLoad = courseId => dispatch =>
  Promise.all([
    dispatch(getCourseGroups()),
    dispatch(getCourseTypes()),
    dispatch(getPrerequisiteCourses(courseId)),
    dispatch(getCourse(courseId)).then(course =>
      dispatch(initialize(COURSE_FORM.ID, CourseMapper.from(course))),
    ),
  ]);

export const getEmployeeAndInitializeForm = employeeId => dispatch =>
  (employeeId
    ? dispatch(getEmployee(employeeId))
    : dispatch(getCurrentEmployee())
  ).then(employee =>
    dispatch(initialize(EMPLOYEE_FORM.ID, EmployeeMapper.from(employee))),
  );

export const userAccessOnLoad = () => dispatch =>
  Promise.all([dispatch(getUsers()), dispatch(getAllAppRoles())]);

export const userAccessOnUnload = () => dispatch =>
  dispatch({
    type: ACTIONS.CLEAR_USERS,
  });

export const getCountries = () => (dispatch, getState) => {
  const countries = getCountriesFromState(getState());

  if (!countries.size) {
    const payload = countries.size ? countries : dataService.getCountries();

    dispatch({
      type: ACTIONS.GET_COUNTRIES,
      payload,
    });

    return payload;
  }

  return Promise.resolve(countries);
};

/**
 * Skill Groups
 */
export const getSkillGroups = makeActionCreator({
  type: ACTIONS.GET_SKILL_GROUPS,
  payload: skillService.getSkillGroups,
});

export const getSkillGroup = makeActionCreator({
  type: ACTIONS.GET_SKILL_GROUP,
  payload: skillService.getSkillGroup,
});

export const createSkillGroup = makeActionCreator({
  type: ACTIONS.CREATE_SKILL_GROUP,
  payload: skillService.createSkillGroup,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Skill group successfully created",
  },
});

export const updateSkillGroup = makeActionCreator({
  type: ACTIONS.UPDATE_SKILL_GROUP,
  payload: skillService.updateSkillGroup,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Skill group successfully updated",
  },
});

export const deleteSkillGroup = makeActionCreator(
  {
    type: ACTIONS.DELETE_SKILL_GROUP,
    payload: skillService.deleteSkillGroup,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Skill group successfully deleted",
    },
  },
  "skillGroupId",
);

export const deleteSkillGroupAskConfirmation = skillGroup => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_SKILL_GROUP_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Delete skill group",
      confirmButtonText: "Delete",
      description: `Are you sure you want delete ${skillGroup.get("name")}`,
    },
    payload: () => dispatch(deleteSkillGroup(skillGroup.get("skillGroupId"))),
  });

export const createSkillGroupOnSubmit = toggleModal => dispatch => skillGroup =>
  dispatch(createSkillGroup(skillGroup.toJS())).then(toggleModal);

export const editSkillGroupOnSubmit = toggleModal => dispatch => skillGroup =>
  dispatch(
    updateSkillGroup(skillGroup.get("skillGroupId"), skillGroup.toJS()),
  ).then(toggleModal);

export const initializeEditSkillGroup = (
  skillGroup,
  toggleModal,
) => dispatch => {
  dispatch(initialize(SKILL_GROUP_FORM.ID, skillGroup));
  toggleModal();
};

/**
 * Skill Types
 */
export const getSkillTypes = makeActionCreator({
  type: ACTIONS.GET_SKILL_TYPES,
  payload: skillService.getSkillTypes,
});

export const getSkillType = makeActionCreator({
  type: ACTIONS.GET_SKILL_TYPE,
  payload: skillService.getSkillType,
});

export const createSkillType = makeActionCreator({
  type: ACTIONS.CREATE_SKILL_TYPE,
  payload: skillService.createSkillType,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Skill Type successfully created",
  },
});

export const updateSkillType = makeActionCreator({
  type: ACTIONS.UPDATE_SKILL_TYPE,
  payload: skillService.updateSkillType,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Skill Type successfully updated",
  },
});

export const deleteSkillType = makeActionCreator(
  {
    type: ACTIONS.DELETE_SKILL_TYPE,
    payload: skillService.deleteSkillType,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Skill Type successfully deleted",
    },
  },
  "skillTypeId",
);

export const deleteSkillTypeAskConfirmation = skillType => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_SKILL_TYPE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Delete skill Type",
      confirmButtonText: "Delete",
      description: `Are you sure you want delete ${skillType.get("name")}`,
    },
    payload: () => dispatch(deleteSkillType(skillType.get("skillTypeId"))),
  });

export const createSkillTypeOnSubmit = toggleModal => dispatch => skillType =>
  dispatch(createSkillType(skillType.toJS())).then(toggleModal);

export const editSkillTypeOnSubmit = toggleModal => dispatch => skillType =>
  dispatch(
    updateSkillType(skillType.get("skillTypeId"), skillType.toJS()),
  ).then(toggleModal);

export const initializeEditSkillType = (skillType, toggleModal) => dispatch => {
  dispatch(initialize(SKILL_TYPE_FORM.ID, skillType));
  toggleModal();
};

/**
 * Experience
 */

export const experienceRecordsOnLoad = () => dispatch =>
  Promise.all([
    dispatch(getExperienceRecords()),
    dispatch(getStatsForExperienceRecords()),
  ]);

export const experienceRecordsByCompetencyStatusOnLoad = competencyStatus => dispatch =>
  Promise.all([
    dispatch(getExperienceRecordsByCompetencyStatus(competencyStatus)),
    dispatch(getStatsForExperienceRecordsByStatus()),
    dispatch(getStatsForExperienceRecords()),
  ]);

export const experienceRecordsOnUnload = () => dispatch =>
  dispatch(clearExperienceRecords());

export const clearExperienceRecords = () => dispatch =>
  dispatch({
    type: ACTIONS.CLEAR_EXPERIENCE_RECORDS,
  });

export const getExperienceRecordItems = makeActionCreator(
  {
    type: ACTIONS.GET_EXPERIENCE_RECORD_ITEMS,
    payload: experienceService.getExperienceRecordItems,
  },
  "employeeId",
  "experienceRecordId",
);

export const getEmployeeAllExperienceRecordItems = makeActionCreator(
  {
    type: ACTIONS.GET_EMPLOYEE_ALL_EXPERIENCE_RECORD_ITEMS,
    payload: experienceService.getEmployeeAllExperienceRecordItems,
  },
  "employeeId",
);

export const getEmployeeFilteredExperienceRecordItems = makeActionCreator(
  {
    type: ACTIONS.GET_EMPLOYEE_ALL_EXPERIENCE_RECORD_ITEMS,
    payload: experienceService.getEmployeeFilteredExperienceRecordItems,
  },
  "employeeId",
  "experienceRecords",
);

export const updateExperienceRecordItem = (
  employeeId,
  experienceRecordId,
  experienceRecordItem,
) => dispatch => {
  const payload = experienceService
    .updateExperienceRecordItem(
      employeeId,
      experienceRecordId,
      experienceRecordItem,
    )
    .then(experienceRecord => {
      dispatch({
        type: ACTIONS.UPDATE_EXPERIENCE_RECORD,
        payload: payload,
        notification: {
          [NOTIFICATION_VARIANTS.INFO]: "Updating...",
          [NOTIFICATION_VARIANTS.SUCCESS]:
            "Experience record item successfully updated",
        },
      });
      var item = experienceRecord.recordItems.find(
        q => q.experienceItemId === experienceRecordItem.experienceItemId,
      );

      if (item) {
        dispatch({
          type: ACTIONS.UPDATE_EXPERIENCE_RECORD_ITEM,
          payload: item,
          employeeId,
          experienceRecordId,
        });
      }
      return experienceRecord;
    })
    .then(item => {
      dispatch(getStatsForExperienceRecord(experienceRecordId));
      dispatch(getExperienceRecordItems(employeeId, experienceRecordId));
      return item;
    });
  return payload;
};

export const getExperienceRecords = makeActionCreator({
  type: ACTIONS.GET_EXPERIENCE_RECORDS,
  payload: experienceService.getExperienceRecords,
});

export const getExperienceRecordsByCompetencyStatus = makeActionCreator(
  {
    type: ACTIONS.GET_EXPERIENCE_RECORDS_BY_COMPETENCY_STATUS,
    payload: experienceService.getExperienceRecordsByCompetencyStatus,
  },
  "competencyStatus",
);

export const getExperienceRecord = makeActionCreator({
  type: ACTIONS.GET_EXPERIENCE_RECORD,
  payload: experienceService.getExperienceRecord,
});

export const getEmployeeExperienceRecordCategoriesForReport = makeActionCreator(
  {
    type: ACTIONS.GET_EMPLOYEE_EXPERIENCE_RECORD_CATEGORIES_FOR_REPORT,
    payload: experienceService.getEmployeeExperienceRecordCategoriesForReport,
  },
  "employeeId",
);

export const getExperienceRecordCategoriesForExperienceRecords = makeActionCreator(
  {
    type: ACTIONS.GET_EMPLOYEE_EXPERIENCE_RECORD_CATEGORIES_FOR_REPORT,
    payload:
      experienceService.getExperienceRecordCategoriesForExperienceRecords,
  },
  "employeeId",
  "experienceRecords",
);

export const employeeExperienceReportOnLoad = employeeId => dispatch =>
  Promise.all([
    dispatch(getEmployeeExperienceRecordsForReport(employeeId)),
    dispatch(getEmployeeAllExperienceRecordItems(employeeId)),
    dispatch(getStatsForExperienceRecordsForEmployee(employeeId)),
    dispatch(getEmployeeExperienceRecordCategoriesForReport(employeeId)),
  ]);

export const employeeExperienceReportOnFilter = (
  employeeId,
  items,
) => dispatch =>
  Promise.all([
    dispatch(getEmployeeFilteredExperienceRecordItems(employeeId, items)),
    dispatch(getStatsForExperienceRecordsForEmployee(employeeId)),
    dispatch(
      getExperienceRecordCategoriesForExperienceRecords(employeeId, items),
    ),
  ]);

export const experienceRecordOnLoad = (
  employeeId,
  experienceRecordId,
) => dispatch =>
  Promise.all([
    dispatch(getExperienceRecord(employeeId, experienceRecordId)),
    dispatch(getExperienceRecordItems(employeeId, experienceRecordId)),
    dispatch(
      getExperienceRecordAvailablePositions(employeeId, experienceRecordId),
    ),
    dispatch(
      getExperienceRecordAvailableValidators(employeeId, experienceRecordId),
    ),
    dispatch(getStatsForExperienceRecord(experienceRecordId)),
  ]);

export const initializeExperienceRecordForm = experienceRecord => dispatch =>
  dispatch(initialize(EXPERIENCE_RECORD_DETAILS_FORM.ID, experienceRecord));

export const redirectToPath = path => dispatch => dispatch(replace(path));

export const submitExperienceRecord = (
  employeeId,
  experienceRecordId,
) => dispatch => {
  const payload = experienceService.submitExperienceRecord(
    employeeId,
    experienceRecordId,
  );

  dispatch({
    type: ACTIONS.SUBMIT_EXPERIENCE_RECORD,
    payload: payload,
  });

  return payload;
};

export const updateExperienceRecord = (
  employeeId,
  experienceRecordId,
  experienceRecord,
) => dispatch => {
  const payload = experienceService.updateExperienceRecord(
    employeeId,
    experienceRecordId,
    experienceRecord,
  );

  dispatch({
    type: ACTIONS.UPDATE_EXPERIENCE_RECORD,
    payload: payload,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Experience successfully updated",
    },
  });

  return payload;
};

export const getOrganizations = makeActionCreator({
  type: ACTIONS.GET_ORGANIZATIONS,
  payload: organizationService.getOrganizations,
});

export const getExperienceRecordAvailablePositions = makeActionCreator(
  {
    type: ACTIONS.GET_EXPERIENCE_RECORD_AVAILABLE_POSITIONS,
    payload: experienceService.getExperienceRecordAvailablePositions,
  },
  "employeeId",
  "experienceRecordId",
);

export const getExperienceRecordAvailableValidators = makeActionCreator(
  {
    type: ACTIONS.GET_EXPERIENCE_RECORD_AVAILABLE_VALIDATORS,
    payload: experienceService.getExperienceRecordAvailableValidators,
  },
  "employeeId",
  "experienceRecordId",
);

export const getAllExperienceRecordCategories = makeActionCreator(
  {
    type: ACTIONS.GET_ALL_EXPERIENCE_RECORD_CATEGORIES,
    payload: experienceService.getAllExperienceRecordCategories,
  },
  "experienceRecordId",
);

export const getExperienceRecordCategories = makeActionCreator(
  {
    type: ACTIONS.GET_EXPERIENCE_RECORD_CATEGORIES,
    payload: experienceService.getExperienceRecordCategories,
  },
  "experienceRecordId",
);

export const getExperienceDepartments = makeActionCreator({
  type: ACTIONS.GET_EXPERIENCE_DEPARTMENTS,
  payload: experienceService.getExperienceDepartments,
});

export const getExperienceFacilities = makeActionCreator({
  type: ACTIONS.GET_FACILITIES,
  payload: facilityService.getFacilities,
});

export const getExperienceDisciplines = makeActionCreator({
  type: ACTIONS.GET_EXPERIENCE_DISCIPLINES,
  payload: experienceService.getExperienceDisciplines,
});

export const createExperiences = makeActionCreator({
  type: ACTIONS.CREATE_EXPERIENCE_RECORDS,
  payload: experienceService.createExperienceRecords,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Experience record successfully created",
  },
});

export const createExperienceFormOnSubmit = toggleModal => dispatch => experience =>
  dispatch(createExperiences(ExperienceRecordsMapper.to(experience.toJS())))
    .then(toggleModal)
    .then(() => dispatch(getStatsForExperienceRecordsByStatus()));

export const createExperienceFormOnLoad = () => dispatch =>
  Promise.all([
    dispatch(getEmployees()),
    dispatch(getOrganizations()),
    dispatch(getExperienceFacilities()),
    dispatch(getExperienceDisciplines()),
  ]);

export const getStatsForExperienceRecords = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_EXPERIENCE_RECORDS,
  payload: statsService.getStatsForExperienceRecords,
});

export const getStatsForExperienceRecordsByStatus = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_EXPERIENCE_RECORDS_BY_STATUS,
  payload: statsService.getStatsForExperienceRecordsByStatus,
});

export const getStatsForExperienceRecord = makeActionCreator(
  {
    type: ACTIONS.GET_STATS_FOR_EXPERIENCE_RECORD,
    payload: statsService.getStatsForExperienceRecord,
  },
  "experienceRecordId",
);

export const deleteExperienceRecord = makeActionCreator(
  {
    type: ACTIONS.DELETE_EXPERIENCE_RECORD,
    payload: experienceService.deleteExperienceRecord,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Experience record successfully deleted",
    },
  },
  "experienceId",
);

export const deleteExperienceRecords = makeActionCreator(
  {
    type: ACTIONS.DELETE_EXPERIENCE_RECORDS,
    payload: experienceService.deleteExperienceRecords,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Experience records successfully deleted",
    },
  },
  "experienceIds",
);

export const deleteExperienceRecordsAskConfirmation = experienceIds => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_EXPERIENCE_RECORDS_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Delete Ecperience Records",
      confirmButtonText: "Delete",
      description: `Are you sure you want to delete the selected experience records?`,
    },
    payload: () =>
      dispatch(deleteExperienceRecords(experienceIds)).then(() =>
        dispatch(getStatsForExperienceRecordsByStatus()),
      ),
  });

export const deleteExperienceRecordAskConfirmation = experience => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_EXPERIENCE_RECORD_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Delete Experience Record",
      confirmButtonText: "Delete",
      description: `Are you sure you want to delete 
      ${experience.get("employeeDisplayName")}'s Job 
      ${experience.get("jobId")} Experience Record?`,
    },
    payload: () =>
      dispatch(
        deleteExperienceRecord(experience.get("experienceRecordId")),
      ).then(() => dispatch(getStatsForExperienceRecordsByStatus())),
  });

/**
 * Approvals
 */
export const getPendingExperienceRecordsForValidator = makeActionCreator(
  {
    type: ACTIONS.GET_PENDING_EXPERIENCE_RECORDS_FOR_VALIDATOR,
    payload: approvalsService.getPendingExperienceRecordsForValidator,
  },
  "employeeId",
);

export const rejectExperienceRecord = makeActionCreator(
  {
    type: ACTIONS.REJECT_EXPERIENCE_RECORD,
    payload: approvalsService.rejectExperienceRecord,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Experience Record successfully rejected",
    },
  },
  "employeeId",
  "experienceRecordId",
);

export const approveExperienceRecordSubmit = makeActionCreator(
  {
    type: ACTIONS.APPROVE_EXPERIENCE_RECORD,
    payload: approvalsService.approveExperienceRecord,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Experience Record successfully approved",
    },
  },
  "employeeId",
  "experienceRecordId",
);

export const approveExperienceRecord = (
  employeeId,
  experienceRecordId,
) => dispatch => {
  Promise.all([
    dispatch(approveExperienceRecordSubmit(employeeId, experienceRecordId)),
    dispatch(getStatsForExperienceRecordsForEmployee(employeeId)),
  ]);
};

export const undoExperienceRecordApproval = makeActionCreator(
  {
    type: ACTIONS.UNDO_APPROVE_EXPERIENCE_RECORD,
    payload: approvalsService.undoApprovalExperienceRecord,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Experience Record successfully undo action",
    },
  },
  "employeeId",
  "experienceRecordId",
);

export const getStatsForExperienceRecordsForValidator = makeActionCreator({
  type: ACTIONS.GET_STATS_FOR_EXPERIENCE_RECORDS_FOR_VALIDATOR,
  payload: statsService.getStatsForExperienceRecordsForValidator,
});

export const experienceRecordsForValidatorOnLoad = employeeId => dispatch => {
  Promise.all([
    dispatch(getPendingExperienceRecordsForValidator(employeeId)),
    dispatch(getStatsForExperienceRecordsForValidator(employeeId)),
  ]);
};

export const changeExperienceRecordValidator = makeActionCreator(
  {
    type: ACTIONS.CHANGE_EXPERIENCE_RECORD_VALIDATOR,
    payload: approvalsService.changeExperienceRecordValidator,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Experience Record validator successfully changed",
    },
  },
  "employeeId",
  "experienceRecordId",
);

/**
 */

/**
 * Skill
 */
export const skillsOnLoad = () => dispatch =>
  Promise.all([
    dispatch(getSkills()),
    dispatch(getSkillGroups()),
    dispatch(getSkillTypes()),
  ]);

export const skillsOnUnload = () => dispatch => dispatch(clearSkills());

export const skillOnLoad = skillId => dispatch =>
  Promise.all([
    dispatch(getSkill(skillId)),
    dispatch(getStatsForSkill(skillId)),
  ]);

export const getSkills = makeActionCreator({
  type: ACTIONS.GET_SKILLS,
  payload: skillService.getSkills,
});

export const clearSkills = () => dispatch =>
  dispatch({
    type: ACTIONS.CLEAR_SKILLS,
  });

export const getSkill = makeActionCreator({
  type: ACTIONS.GET_SKILL,
  payload: skillService.getSkill,
});

export const createSkill = makeActionCreator({
  type: ACTIONS.CREATE_SKILL,
  payload: skillService.createSkill,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Skill successfully created",
  },
});

export const updateSkill = (skillId, skill) => dispatch => {
  const payload = skillService.updateSkill(skillId, skill);
  dispatch({
    type: ACTIONS.UPDATE_SKILL,
    payload: payload,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Skill successfully updated",
    },
  });
  return payload;
};

export const deleteSkill = makeActionCreator(
  {
    type: ACTIONS.DELETE_SKILL,
    payload: skillService.deleteSkill,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Skill successfully deleted",
    },
  },
  "skillId",
);

export const deleteSkillAskConfirmation = skill => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_SKILL_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Delete Skill",
      confirmButtonText: "Delete",
      description: `Are you sure you want to delete '${skill.get("name")}'?`,
    },
    payload: () => dispatch(deleteSkill(skill.get("skillId"))),
  });

export const deleteSkillLists = makeActionCreator(
  {
    type: ACTIONS.DELETE_SKILLS,
    payload: skillService.deleteSkills,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Skills successfully deleted",
    },
  },
  "skillIds",
);

export const deleteSkillListAskConfirmation = skillIds => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_SKILL_LIST_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Delete Skills",
      confirmButtonText: "Delete",
      description: `Are you sure you want to delete the selected skills?`,
    },
    payload: () => dispatch(deleteSkillLists(skillIds)),
  });

export const createSkillOnSubmit = toggleModal => dispatch => skill =>
  dispatch(createSkill(skill.toJS()))
    .then(() => dispatch(getStatsForSkills()))
    .then(toggleModal);

export const initializeCreateSkill = () => dispatch =>
  dispatch(
    initialize(SKILL_FORM.ID, {
      [SKILL_FORM.REQUIRED_EVIDENCE_COUNT]: DEFAULT_SKILL_FORM_REQUIRED_EVIDENCE_COUNT,
      [SKILL_FORM.COUNTRIES]: EMPTY_LIST,
    }),
  );

export const skillDetailsOnLoad = skillId => dispatch =>
  Promise.all([
    dispatch(getSkillGroups()),
    dispatch(getSkillTypes()),
    dispatch(getSkill(skillId)).then(skill =>
      dispatch(initialize(SKILL_FORM.ID, SkillMapper.from(skill))),
    ),
  ]);

export const courseFormOnChange = (values, dispatch, props) => {
  const { dirty, courseId } = props;

  if (dirty) {
    dispatch(updateCourse(courseId, values.toJS())).then(course =>
      dispatch(initialize(COURSE_FORM.ID, CourseMapper.from(course))),
    );
  }
};

/**
 * RoleSkill
 */
export const getRoleSkillsWithParentsByRole = makeActionCreator(
  {
    type: ACTIONS.GET_ROLE_SKILLS_WITH_PARENTS_BY_ROLE,
    payload: roleService.getRoleSkillsWithParentsByRole,
  },
  "roleId",
);

export const roleSkillsOnUnload = () => dispatch =>
  dispatch({
    type: ACTIONS.CLEAR_ROLE_SKILLS,
  });

export const createRoleSkills = makeActionCreator(
  {
    type: ACTIONS.CREATE_ROLE_SKILLS,
    payload: roleService.createRoleSkills,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Skills successfully added to the role",
    },
  },
  "roleId",
);

export const createRoleSkillsOnSubmit = (roleId, skillIdList) => dispatch =>
  dispatch(createRoleSkills(roleId, skillIdList)).then(() => {
    dispatch(getStatsForRole(roleId));
  });

export const deleteRoleSkill = makeActionCreator(
  {
    type: ACTIONS.DELETE_ROLE_SKILL,
    payload: roleService.deleteRoleSkill,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Skill successfully removed from the role",
    },
  },
  "roleId",
  "skillId",
  "roleSkillId",
);

export const deleteRoleSkillAskConfirmation = roleSkill => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_ROLE_SKILL_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove Skill from Role",
      confirmButtonText: "Delete",
      description: `Are you sure you want to remove "${roleSkill.get(
        "skillName",
      )}" from "${roleSkill.get("roleName")}"?`,
    },
    payload: () =>
      dispatch(
        deleteRoleSkill(
          roleSkill.get("roleId"),
          roleSkill.get("skillId"),
          roleSkill.get("roleSkillId"),
        ),
      ).then(() => dispatch(getStatsForRole(roleSkill.get("roleId")))),
  });

const updateRoleSkill = makeActionCreator({
  type: ACTIONS.UPDATE_ROLE_SKILL,
  payload: roleService.updateRoleSkill,
});

export const updateRoleSkillAndGetRoleStats = roleSkill => dispatch => {
  return dispatch(
    updateRoleSkill(
      roleSkill.get("roleId"),
      roleSkill.get("skillId"),
      roleSkill,
    ),
  ).then(() => dispatch(getStatsForRole(roleSkill.get("roleId"))));
};

export const getRolesBySkill = makeActionCreator({
  type: ACTIONS.GET_ROLES_BY_SKILL,
  payload: roleService.getRolesBySkill,
});

/**
 * Employee Skill
 */

export const getEmployeeRoleSkillList = makeActionCreator(
  {
    type: ACTIONS.GET_EMPLOYEE_ROLE_SKILLS_GROUPED_BY_ROLE,
    payload: employeeService.getEmployeeRoleSkillList,
  },
  "employeeId",
);

export const getEmployeeSkillsBySkill = makeActionCreator({
  type: ACTIONS.GET_EMPLOYEE_SKILLS_BY_SKILL,
  payload: employeeService.getEmployeesBySkill,
});

export const employeeSkillsBasePageOnLoad = employeeId => dispatch =>
  Promise.all([
    dispatch(getEmployeeRoleSkillList(employeeId)),
    dispatch(getMyTeamEmployeesByUser()),
  ]);

export const createEmployeeSkillOnEnter = () => dispatch =>
  Promise.all([
    dispatch(getSkills()),
    dispatch(getSkillGroups()),
    dispatch(getSkillTypes()),
  ]);

export const initializeAddEmployeeSkillEvidenceForm = (
  employeeSkill,
  toggleModal,
) => dispatch => {
  dispatch(
    initialize(EMPLOYEE_SKILL_FORM.ID, {
      [EMPLOYEE_SKILL_FORM.SKILL_ID]: employeeSkill.get("skillId"),
      [EMPLOYEE_SKILL_FORM.SKILL_NAME]: employeeSkill.get("skillName"),
      [EMPLOYEE_SKILL_FORM.FILE_EVIDENCES]: EMPTY_SET,
    }),
  );
  toggleModal();
};

export const createEmployeeSkillFormOnSubmit = employeeId => dispatch => toggleModal => employeeSkill => {
  return dispatch(
    createEmployeeSkill(employeeId, {
      ...employeeSkill.toJS(),
      skillEvidences: [
        { registrationDate: employeeSkill.get("when").format() },
      ],
    }),
  )
    .then(employeeSkillResponse => {
      return dispatch(
        uploadSkillFileEvidencesConcurrently(
          employeeId,
          employeeSkillResponse.skillId,
          employeeSkillResponse.skillEvidences[0].skillEvidenceId,
          employeeSkill.get("fileEvidences", EMPTY_SET),
        ),
      );
    })
    .then(() => dispatch(getEmployeeRoleSkillList(employeeId)))
    .then(() => dispatch(getStatsForEmployee(employeeId)))
    .then(toggleModal);
};

export const addEmployeeSkillEvidenceAndFetchEmployeeSkillFileEvidences = (
  employeeId,
  skillId,
  approveOnCreate,
) => dispatch => toggleModal => employeeSkill =>
  dispatch(
    createEmployeeSkillAndUploadEvidences(
      employeeId,
      skillId,
      employeeSkill,
      approveOnCreate,
    ),
  )
    .then(toggleModal)
    .then(() => dispatch(getEmployeeSkillFileEvidences(employeeId, skillId)));

export const addEmployeeSkillEvidenceAndFetchEmployeeRoleSkills = (
  employeeId,
  skillId,
  approveOnCreate,
) => dispatch => toggleModal => employeeSkill => {
  return dispatch(
    createEmployeeSkillAndUploadEvidences(
      employeeId,
      skillId,
      employeeSkill,
      approveOnCreate,
    ),
  )
    .then(toggleModal)
    .then(() => dispatch(getEmployeeRoleSkillList(employeeId, skillId)));
};
const createEmployeeSkillAndUploadEvidences = (
  employeeId,
  skillId,
  employeeSkill,
  approveOnCreate,
) => dispatch => {
  return dispatch(
    createEmployeeSkill(employeeId, {
      ...employeeSkill.toJS(),
      skillEvidences: [
        { registrationDate: employeeSkill.get("when").format() },
      ],
    }),
  )
    .then(employeeSkillResponse =>
      dispatch(
        uploadSkillFileEvidencesConcurrently(
          employeeId,
          skillId,
          employeeSkillResponse.skillEvidences[0].skillEvidenceId,
          employeeSkill.get("fileEvidences", EMPTY_SET),
          approveOnCreate,
        ),
      ),
    )
    .then(() => dispatch(getEmployeeSkill(employeeId, skillId)));
};

export const addSkillEvidenceAndFetchEmployeeeSkillEvidences = (
  employeeId,
  skillId,
  approveOnCreate,
) => dispatch => toggleModal => employeeSkill =>
  dispatch(
    createEmployeeSkillAndUploadEvidences(
      employeeId,
      skillId,
      employeeSkill,
      approveOnCreate,
    ),
  )
    .then(toggleModal)
    .then(() =>
      dispatch(employeeSkillFileEvidencesContainerOnLoad(employeeId, skillId)),
    );

const uploadSkillFileEvidencesConcurrently = (
  employeeId,
  skillId,
  evidenceId,
  files,
  approveOnCreate,
) => dispatch =>
  Promise.all(
    files.map(file =>
      approveOnCreate
        ? dispatch(
            uploadAndApproveEmployeeSkillFileEvidence(
              employeeId,
              skillId,
              file,
            ),
          )
        : dispatch(
            uploadEmployeeSkillFileEvidence(
              employeeId,
              skillId,
              evidenceId,
              file,
            ),
          ),
    ),
  );

export const createEmployeeSkill = makeActionCreator({
  type: ACTIONS.CREATE_EMPLOYEE_SKILL,
  payload: employeeService.createEmployeeSkill,
});

export const getEmployeeSkill = makeActionCreator({
  type: ACTIONS.GET_EMPLOYEE_SKILL,
  payload: employeeService.getEmployeeSkill,
});

export const employeeSkillContainerOnLoad = (employeeId, skillId) => dispatch =>
  Promise.all([
    dispatch(getSkill(skillId)),
    dispatch(getEmployeeSkill(employeeId, skillId)),
  ]);

/**
 * Employee Skill Evidence File
 */

export const createEmployeeSkillEvidence = makeActionCreator({
  type: ACTIONS.CREATE_SKILL_EVIDENCE,
  payload: employeeService.createEmployeeSkillEvidence,
});

export const uploadEmployeeSkillFileEvidence = makeActionCreator({
  type: ACTIONS.UPLOAD_EMPLOYEE_SKILL_EVIDENCE_FILE,
  payload: employeeService.uploadEmployeeSkillFileEvidence,
});

export const uploadAndApproveEmployeeSkillFileEvidence = makeActionCreator({
  type: ACTIONS.UPLOAD_AND_APPROVE_EMPLOYEE_SKILL_EVIDENCE_FILE,
  payload: employeeService.uploadAndApproveEmployeeSkillFileEvidence,
});

export const previewOrSaveEmployeeSkillFileEvidence = (
  employeeId,
  skillId,
  file,
) => () =>
  file.employeeSkillEvidenceFileId
    ? employeeService.previewEmployeeSkillFileEvidence(
        employeeId,
        skillId,
        file.employeeSkillEvidenceFileId,
      )
    : saveFile(file);

export const updateEmployeeSkillFileEvidenceApproval = (
  employeeId,
  skillId,
  employeeSkillEvidence,
) => dispatch => {
  const actionText =
    employeeSkillEvidence.get("approvalStatus") === APPROVAL_STATUS.APPROVED
      ? "approved"
      : "not approved";
  const skillEvidenceId = employeeSkillEvidence.get("skillEvidenceId");
  dispatch({
    type: ACTIONS.UPDATE_EMPLOYEE_SKILL_FILE_EVIDENCE_APPROVAL,
    payload: () =>
      employeeService.updateEmployeeSkillFileEvidenceApproval(
        employeeId,
        skillId,
        skillEvidenceId,
        SkillEvidenceMapper.to(employeeSkillEvidence.toJS()),
      ),
    employeeId,
    skillId,
    skillEvidenceId,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: `Skill Evidence ${actionText}`,
    },
  });
};

export const removeEmployeeSkillFileEvidenceApproval = (
  employeeId,
  skillId,
  skillEvidenceId,
) => dispatch =>
  dispatch({
    type: ACTIONS.REMOVE_EMPLOYEE_SKILL_FILE_EVIDENCE_APPROVAL,
    payload: () =>
      employeeService.removeEmployeeSkillFileEvidenceApproval(
        employeeId,
        skillId,
        skillEvidenceId,
      ),
    employeeId,
    skillId,
    skillEvidenceId,
  });

export const getEmployeeSkillFileEvidences = makeActionCreator(
  {
    type: ACTIONS.GET_EMPLOYEE_SKILL_FILE_EVIDENCES,
    payload: employeeService.getEmployeeSkillFileEvidences,
  },
  "employeeId",
  "skillId",
);

export const getEmployeeSkillEvidences = makeActionCreator(
  {
    type: ACTIONS.GET_EMPLOYEE_SKILL_EVIDENCES,
    payload: employeeService.getEmployeeSkillEvidences,
  },
  "employeeId",
  "skillId",
);

export const employeeSkillFileEvidencesContainerOnLoad = (
  employeeId,
  skillId,
) => dispatch =>
  Promise.all([
    dispatch(getEmployeeSkillEvidences(employeeId, skillId)),
    dispatch(getMyTeamEmployeesByUser()),
  ]);

export const deleteEmployeeSkilEvidence = makeActionCreator(
  {
    type: ACTIONS.DELETE_EMPLOYEE_SKILL_EVIDENCE,
    payload: employeeService.deleteEmployeeSkillEvidence,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Skill evidence deleted successfully",
    },
  },
  "employeeId",
  "skillId",
  "skillEvidenceId",
);

export const deleteEmployeeSkillEvidenceAskConfirmation = skillEvidence => dispatch => {
  const actionText =
    skillEvidence.get("files")?.toArray().length === 0
      ? ""
      : ", this evidence has files attached, they will also be removed";

  dispatch({
    type: ACTIONS.DELETE_EMPLOYEE_SKILL_EVIDENCE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove skill evidence",
      confirmButtonText: "Remove",
      description: `Are you sure you want to remove ${skillEvidence.get(
        "name",
      )}${actionText}?`,
    },
    payload: () =>
      dispatch(
        deleteEmployeeSkilEvidence(
          skillEvidence.get("employeeId"),
          skillEvidence.get("skillId"),
          skillEvidence.get("skillEvidenceId"),
        ),
      ).then(() =>
        dispatch(
          getEmployeeSkillEvidences(
            skillEvidence.get("employeeId"),
            skillEvidence.get("skillId"),
          ),
        ),
      ),
  });
};

export const deleteEmployeeSkillFileEvidence = makeActionCreator(
  {
    type: ACTIONS.DELETE_EMPLOYEE_SKILL_FILE_EVIDENCE,
    payload: employeeService.deleteEmployeeSkillFileEvidence,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Skill evidence deleted successfully",
    },
  },
  "employeeId",
  "skillId",
  "employeeSkillFileEvidenceId",
);

export const deleteEmployeeSkillFileEvidenceAskConfirmation = employeeSkillFileEvidence => dispatch =>
  dispatch({
    type: ACTIONS.DELETE_EMPLOYEE_SKILL_FILE_EVIDENCE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove skill file evidence",
      confirmButtonText: "Remove",
      description: `Are you sure you want to remove ${employeeSkillFileEvidence.get(
        "name",
      )}?`,
    },
    payload: () =>
      dispatch(
        deleteEmployeeSkillFileEvidence(
          employeeSkillFileEvidence.get("employeeId"),
          employeeSkillFileEvidence.get("skillId"),
          employeeSkillFileEvidence.get("employeeSkillFileEvidenceId"),
        ),
      ).then(() =>
        dispatch(
          getEmployeeSkill(
            employeeSkillFileEvidence.get("employeeId"),
            employeeSkillFileEvidence.get("skillId"),
          ),
        ),
      ),
  });

/**
 * My Team Employee Skills
 */
export const getMyTeamFileEvidencesNeedingApproval = makeActionCreator({
  type: ACTIONS.GET_MY_TEAM_FILE_EVIDENCES_NEEDING_APPROVAL,
  payload: employeeService.getMyTeamFileEvidencesNeedingApproval,
});

// For now MyTeamApprovalContainer has to load only File Evidences, but in the future other types
// of Evidences will need to be loaded.
export const myTeamApprovalOnLoad = () => dispatch => {
  const payload = dispatch(getMyTeamFileEvidencesNeedingApproval());

  dispatch({
    type: ACTIONS.MY_TEAM_APPROVAL_ON_LOAD,
    payload,
  });

  return payload;
};

/**
 * Skill Gap Report
 */

export const getEmployeeSkillGapReport = filters => dispatch => {
  const queryString = toQueryString(filters);

  dispatch({
    type: ACTIONS.GET_SKILL_GAP_REPORT,
    payload: () => reportService.getEmployeeSkillGapReport(queryString),
    filters,
  });
  dispatch(replace({ search: queryString }));
};

export const getMyTeamEmployeeSkillGapReport = filters => dispatch => {
  const queryString = toQueryString(filters);

  dispatch({
    type: ACTIONS.GET_SKILL_GAP_REPORT,
    payload: () => reportService.getMyTeamEmployeeSkillGapReport(queryString),
    filters,
  });
  dispatch(replace({ search: queryString }));
};

export const clearSkillGapReport = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_SKILL_GAP_REPORT,
  });
  dispatch(replace({ search: null }));
};

export const hrSkillGapReportOnLoad = () => dispatch =>
  Promise.all([
    dispatch(getSkills()),
    dispatch(getDepartments()),
    dispatch(getEmployees()),
  ]);

export const myTeamSkillGapReportOnLoad = () => dispatch =>
  Promise.all([
    dispatch(getSkills()),
    dispatch(getMyTeamDepartments()),
    dispatch(getMyTeamEmployeesByUser()),
  ]);

export const initializeSkillDetailsForm = skillId => (dispatch, getState) => {
  dispatch(toggleModal({ modalId: SKILL_DETAILS_MODAL_ID }));
  dispatch(
    initialize(SKILL_FORM.ID, getSkillFromState(getState(), skillId), false),
  );
};

export const downloadSkillGapReport = makeActionCreator(
  {
    type: ACTIONS.DOWNLOAD_SKILL_GAP_REPORT,
    payload: reportService.downloadEmployeeSkillGapReport,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Gap Report Downloaded successfully ",
    },
  },
  "queryString",
);

export const downloadMyTeamSkillGapReport = makeActionCreator(
  {
    type: ACTIONS.DOWNLOAD_SKILL_GAP_REPORT,
    payload: reportService.downloadMyTeamEmployeeSkillGapReport,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Gap Report Downloaded successfully ",
    },
  },
  "queryString",
);

export const downloadAndSaveSkillGapReport = () => (dispatch, getState) => {
  const appliedFilters = getGapReportAppliedFiltersFromState(getState());

  return dispatch(
    downloadSkillGapReport(toQueryString(appliedFilters)),
  ).then(([downloadedFile, filename]) => saveFile(downloadedFile, filename));
};

export const downloadAndSaveMyTeamSkillGapReport = () => (
  dispatch,
  getState,
) => {
  const appliedFilters = getGapReportAppliedFiltersFromState(getState());

  return dispatch(
    downloadMyTeamSkillGapReport(toQueryString(appliedFilters)),
  ).then(([downloadedFile, filename]) => saveFile(downloadedFile, filename));
};

/**
 * Specifications
 */

export const getAvailableCoursesForSpecification = makeActionCreator(
  {
    type: ACTIONS.GET_AVAILABLE_COURSES_FOR_SPECIFICATION,
    payload: specificationService.getAvailableCoursesForSpecification,
  },
  "specificationId",
);

export const getAvailableSkillsForSpecification = makeActionCreator(
  {
    type: ACTIONS.GET_AVAILABLE_SKILLS_FOR_SPECIFICATION,
    payload: specificationService.getAvailableSkillsForSpecification,
  },
  "specificationId",
);

export const getSpecifications = makeActionCreator({
  type: ACTIONS.GET_SPECIFICATIONS,
  payload: specificationService.getSpecifications,
});

export const getSpecification = makeActionCreator({
  type: ACTIONS.GET_SPECIFICATION,
  payload: specificationService.getSpecification,
});

export const getSpecificationWithParents = makeActionCreator({
  type: ACTIONS.GET_SPECIFICATION_WITH_PARENTS,
  payload: specificationService.getSpecificationWithParents,
});

export const createSpecification = makeActionCreator({
  type: ACTIONS.CREATE_SPECIFICATION,
  payload: specificationService.createSpecification,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Specification successfully created",
  },
});

export const updateSpecification = makeActionCreator({
  type: ACTIONS.UPDATE_SPECIFICATION,
  payload: specificationService.updateSpecification,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Specification successfully updated",
    [NOTIFICATION_VARIANTS.INFO]: "Updating...",
  },
});

export const deleteSpecification = makeActionCreator(
  {
    type: ACTIONS.DELETE_SPECIFICATION,
    payload: specificationService.deleteSpecification,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Specification successfully deleted",
    },
  },
  "specificationId",
);

export const clearSpecifications = () => dispatch => {
  return dispatch({
    type: ACTIONS.CLEAR_SPECIFICATIONS,
  });
};

export const specificationsOnLoad = () => dispatch => {
  return Promise.all([dispatch(getSpecifications())]);
};

export const specificationsOnUnload = () => dispatch => {
  return Promise.all([
    dispatch(clearEmployees()),
    dispatch(clearSpecifications()),
  ]);
};

export const specificationOnLoad = specificationId => dispatch => {
  return Promise.all([
    dispatch(getSpecification(specificationId)),
    dispatch(getStatsForSpecification(specificationId)),
  ]);
};

export const specificationDetailsOnLoad = specificationId => (
  dispatch,
  getState,
) =>
  Promise.all([
    dispatch(getSpecificationWithParents(specificationId)).then(() => {
      const specification = getSpecificationFromState(
        getState(),
        specificationId,
      );

      dispatch(initialize(SPECIFICATION_FORM.ID, specification));

      return specification;
    }),
  ]);

export const specificationOnUnload = () => dispatch => {
  return dispatch(clearSpecifications());
};

export const initializeCreateSpecification = () => dispatch => {
  dispatch(
    initialize(SPECIFICATION_FORM.ID, {
      [SPECIFICATION_FORM.PARENT_ID]: null,
      [SPECIFICATION_FORM.COUNTRIES]: EMPTY_LIST,
    }),
  );
  dispatch(toggleModal({ modalId: CREATE_SPECIFICATION_MODAL_ID }));
};

export const initializeEditSpecification = specification => dispatch =>
  dispatch(initialize(SPECIFICATION_FORM.ID, specification));

export const createSpecificationOnSubmit = toggleModal => dispatch => specification =>
  dispatch(createSpecification(specification.toJS()))
    .then(() => dispatch(getSpecifications()))
    .then(() => dispatch(getStatsForSpecifications()))
    .then(toggleModal);

export const deleteSpecificationAskConfirmation = specification => dispatch => {
  return dispatch({
    type: ACTIONS.DELETE_SPECIFICATION_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Delete Specification",
      confirmButtonText: "Delete",
      description: `Are you sure you want to delete '${specification.get(
        "name",
      )}'?`,
    },
    payload: () =>
      dispatch(
        deleteSpecification(specification.get("specificationId")),
      ).then(() => dispatch(getSpecifications())),
  });
};

/**
 * SpecificationCourses
 */

export const getSpecificationCoursesBySpecificationWithParents = makeActionCreator(
  {
    type: ACTIONS.GET_SPECIFICATION_COURSES_BY_SPECIFICATION_WITH_PARENTS,
    payload:
      specificationService.getSpecificationCoursesBySpecificationWithParents,
  },
  "specificationId",
);

export const createSpecificationCourses = makeActionCreator(
  {
    type: ACTIONS.CREATE_SPECIFICATION_COURSES,
    payload: specificationService.createSpecificationCourses,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Courses successfully added to the Specification",
    },
  },
  "specificationId",
);

export const deleteSpecificationCourse = makeActionCreator(
  {
    type: ACTIONS.DELETE_SPECIFICATION_COURSE,
    payload: specificationService.deleteSpecificationCourse,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Course successfully removed from the Specification",
    },
  },
  "specificationId",
  "courseId",
  "specificationCourseId",
);

export const deleteSpecificationCourseAskConfirmation = specificationCourse => dispatch => {
  return dispatch({
    type: ACTIONS.DELETE_SPECIFICATION_COURSE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove Course from Specification",
      confirmButtonText: "Remove",
      description: `Are you sure you want to remove "${specificationCourse.get(
        "courseName",
      )}" from "${specificationCourse.get("specificationName")}"?`,
    },
    payload: () =>
      dispatch(
        deleteSpecificationCourse(
          specificationCourse.get("specificationId"),
          specificationCourse.get("courseId"),
          specificationCourse.get("specificationCourseId"),
        ),
      ).then(() =>
        dispatch(
          getStatsForSpecification(specificationCourse.get("specificationId")),
        ),
      ),
  });
};

export const clearSpecificationCourses = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_SPECIFICATION_COURSES,
  });
};

export const createSpecificationCoursesOnSubmit = (
  specificationId,
  courseIdList,
) => dispatch =>
  dispatch(createSpecificationCourses(specificationId, courseIdList)).then(() =>
    dispatch(getStatsForSpecification(specificationId)),
  );

export const specificationCoursesOnLoad = specificationId => dispatch => {
  dispatch(getSpecificationCoursesBySpecificationWithParents(specificationId));
};

export const specificationCoursesOnUnload = () => dispatch => {
  dispatch(clearSpecificationCourses());
};

export const updateSpecificationCourse = makeActionCreator({
  type: ACTIONS.UPDATE_SPECIFICATION_COURSE,
  payload: specificationService.updateSpecificationCourse,
});

/**
 * SpecificationSkills
 */

export const getSpecificationSkillsBySpecificationWithParents = makeActionCreator(
  {
    type: ACTIONS.GET_SPECIFICATION_SKILLS_BY_SPECIFICATION_WITH_PARENTS,
    payload:
      specificationService.getSpecificationSkillsBySpecificationWithParents,
  },
  "specificationId",
);

export const createSpecificationSkills = makeActionCreator(
  {
    type: ACTIONS.CREATE_SPECIFICATION_SKILLS,
    payload: specificationService.createSpecificationSkills,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Skills successfully added to the Specification",
    },
  },
  "specificationId",
);

export const deleteSpecificationSkill = makeActionCreator(
  {
    type: ACTIONS.DELETE_SPECIFICATION_SKILL,
    payload: specificationService.deleteSpecificationSkill,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Skill successfully removed from the Specification",
    },
  },
  "specificationId",
  "skiillId",
  "specificationSkillId",
);

export const deleteSpecificationSkillAskConfirmation = specificationSkill => dispatch => {
  return dispatch({
    type: ACTIONS.DELETE_SPECIFICATION_SKILL_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove Skill from Specification",
      confirmButtonText: "Remove",
      description: `Are you sure you want to remove "${specificationSkill.get(
        "skillName",
      )}" from "${specificationSkill.get("specificationName")}"?`,
    },
    payload: () =>
      dispatch(
        deleteSpecificationSkill(
          specificationSkill.get("specificationId"),
          specificationSkill.get("skillId"),
          specificationSkill.get("specificationSkillId"),
        ),
      ).then(() =>
        dispatch(
          getStatsForSpecification(specificationSkill.get("specificationId")),
        ),
      ),
  });
};

export const clearSpecificationSkills = () => dispatch => {
  return dispatch({
    type: ACTIONS.CLEAR_SPECIFICATION_SKILLS,
  });
};

export const createSpecificationSkillsOnSubmit = (
  specificationId,
  skillIdList,
) => dispatch =>
  dispatch(createSpecificationSkills(specificationId, skillIdList)).then(() =>
    dispatch(getStatsForSpecification(specificationId)),
  );

export const specificationSkillsOnLoad = specificationId => dispatch => {
  return dispatch(
    getSpecificationSkillsBySpecificationWithParents(specificationId),
  );
};

export const specificationSkillsOnUnload = () => dispatch => {
  return dispatch(clearSpecificationSkills());
};

export const updateSpecificationSkill = makeActionCreator({
  type: ACTIONS.UPDATE_SPECIFICATION_SKILL,
  payload: specificationService.updateSpecificationSkill,
});

/**
 * Specification Gap Report
 */

export const specificationGapReportOnLoad = () => dispatch => {
  return Promise.all([
    dispatch(getDepartments()),
    dispatch(getSpecifications()),
    dispatch(getEmployees()),
  ]);
};

export const specificationGapReportOnUnload = () => dispatch => {
  return Promise.all([
    dispatch(clearSpecificationGapReport()),
    dispatch(clearSpecifications()),
  ]);
};

export const getSpecificationGapReport = filters => dispatch => {
  const queryString = toQueryString(filters);

  dispatch({
    type: ACTIONS.GET_SPECIFICATION_GAP_REPORT,
    payload: () => reportService.getEmployeeSpecificationGapReport(queryString),
    filters,
  });
  dispatch(replace({ search: queryString }));
};

export const clearSpecificationGapReport = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_SPECIFICATION_GAP_REPORT,
  });
  dispatch(replace({ search: null }));
};

export const downloadSpecificationGapReport = makeActionCreator(
  {
    type: ACTIONS.DOWNLOAD_SPECIFICATION_GAP_REPORT,
    payload: reportService.downloadSpecificationGapReport,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Gap Report Downloaded successfully ",
    },
  },
  "queryString",
);

export const downloadAndSaveSpecificationGapReport = () => (
  dispatch,
  getState,
) => {
  const appliedFilters = getGapReportAppliedFiltersFromState(getState());

  return dispatch(
    downloadSpecificationGapReport(toQueryString(appliedFilters)),
  ).then(([downloadedFile, filename]) => saveFile(downloadedFile, filename));
};

/**
 * Services
 */

export const getAvailableCoursesForService = makeActionCreator(
  {
    type: ACTIONS.GET_AVAILABLE_COURSES_FOR_SERVICE,
    payload: serviceService.getAvailableCoursesForService,
  },
  "serviceId",
);

export const getAvailableSkillsForService = makeActionCreator(
  {
    type: ACTIONS.GET_AVAILABLE_SKILLS_FOR_SERVICE,
    payload: serviceService.getAvailableSkillsForService,
  },
  "serviceId",
);

export const getServices = makeActionCreator({
  type: ACTIONS.GET_SERVICES,
  payload: serviceService.getServices,
});

export const getService = makeActionCreator({
  type: ACTIONS.GET_SERVICE,
  payload: serviceService.getService,
});

export const getServiceWithParents = makeActionCreator({
  type: ACTIONS.GET_SERVICE_WITH_PARENTS,
  payload: serviceService.getServiceWithParents,
});

export const createService = makeActionCreator({
  type: ACTIONS.CREATE_SERVICE,
  payload: serviceService.createService,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Service successfully created",
  },
});

export const updateService = makeActionCreator({
  type: ACTIONS.UPDATE_SERVICE,
  payload: serviceService.updateService,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "Service successfully updated",
    [NOTIFICATION_VARIANTS.INFO]: "Updating...",
  },
});

export const deleteService = makeActionCreator(
  {
    type: ACTIONS.DELETE_SERVICE,
    payload: serviceService.deleteService,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Service successfully deleted",
    },
  },
  "serviceId",
);

export const clearServices = () => dispatch => {
  return dispatch({
    type: ACTIONS.CLEAR_SERVICES,
  });
};

export const servicesOnLoad = () => dispatch => {
  return Promise.all([dispatch(getServices())]);
};

export const servicesOnUnload = () => dispatch => {
  return Promise.all([dispatch(clearEmployees()), dispatch(clearServices())]);
};

export const serviceOnLoad = serviceId => dispatch => {
  return Promise.all([
    dispatch(getService(serviceId)),
    dispatch(getStatsForService(serviceId)),
  ]);
};

export const serviceDetailsOnLoad = serviceId => (dispatch, getState) =>
  Promise.all([
    dispatch(getServiceWithParents(serviceId)).then(() => {
      const service = getServiceFromState(getState(), serviceId);

      dispatch(initialize(SERVICE_FORM.ID, service));

      return service;
    }),
  ]);

export const serviceOnUnload = () => dispatch => {
  return dispatch(clearServices());
};

export const initializeCreateService = () => dispatch => {
  dispatch(
    initialize(SERVICE_FORM.ID, {
      [SERVICE_FORM.PARENT_ID]: null,
      [SERVICE_FORM.COUNTRIES]: EMPTY_LIST,
    }),
  );
  dispatch(toggleModal({ modalId: CREATE_SERVICE_MODAL_ID }));
};

export const initializeEditService = service => dispatch =>
  dispatch(initialize(SERVICE_FORM.ID, service));

export const createServiceOnSubmit = toggleModal => dispatch => service =>
  dispatch(createService(service.toJS()))
    .then(() => dispatch(getServices()))
    .then(() => dispatch(getStatsForServices()))
    .then(toggleModal);

export const deleteServiceAskConfirmation = service => dispatch => {
  return dispatch({
    type: ACTIONS.DELETE_SERVICE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Delete Service",
      confirmButtonText: "Delete",
      description: `Are you sure you want to delete '${service.get("name")}'?`,
    },
    payload: () =>
      dispatch(deleteService(service.get("serviceId"))).then(() =>
        dispatch(getServices()),
      ),
  });
};

/**
 * ServiceCourses
 */

export const getServiceCoursesByServiceWithParents = makeActionCreator(
  {
    type: ACTIONS.GET_SERVICE_COURSES_BY_SERVICE_WITH_PARENTS,
    payload: serviceService.getServiceCoursesByServiceWithParents,
  },
  "serviceId",
);

export const createServiceCourses = makeActionCreator(
  {
    type: ACTIONS.CREATE_SERVICE_COURSES,
    payload: serviceService.createServiceCourses,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Courses successfully added to the Service",
    },
  },
  "serviceId",
);

export const deleteServiceCourse = makeActionCreator(
  {
    type: ACTIONS.DELETE_SERVICE_COURSE,
    payload: serviceService.deleteServiceCourse,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Course successfully removed from the Service",
    },
  },
  "serviceId",
  "courseId",
  "serviceCourseId",
);

export const deleteServiceCourseAskConfirmation = serviceCourse => dispatch => {
  return dispatch({
    type: ACTIONS.DELETE_SERVICE_COURSE_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove Course from Service",
      confirmButtonText: "Remove",
      description: `Are you sure you want to remove "${serviceCourse.get(
        "courseName",
      )}" from "${serviceCourse.get("serviceName")}"?`,
    },
    payload: () =>
      dispatch(
        deleteServiceCourse(
          serviceCourse.get("serviceId"),
          serviceCourse.get("courseId"),
          serviceCourse.get("serviceCourseId"),
        ),
      ).then(() =>
        dispatch(getStatsForService(serviceCourse.get("serviceId"))),
      ),
  });
};

export const clearServiceCourses = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_SERVICE_COURSES,
  });
};

export const createServiceCoursesOnSubmit = (
  serviceId,
  courseIdList,
) => dispatch =>
  dispatch(createServiceCourses(serviceId, courseIdList)).then(() =>
    dispatch(getStatsForService(serviceId)),
  );

export const serviceCoursesOnLoad = serviceId => dispatch => {
  dispatch(getServiceCoursesByServiceWithParents(serviceId));
};

export const serviceCoursesOnUnload = () => dispatch => {
  dispatch(clearServiceCourses());
};

export const updateServiceCourse = makeActionCreator({
  type: ACTIONS.UPDATE_SERVICE_COURSE,
  payload: serviceService.updateServiceCourse,
});

/**
 * ServiceSkills
 */

export const getServiceSkillsByServiceWithParents = makeActionCreator(
  {
    type: ACTIONS.GET_SERVICE_SKILLS_BY_SERVICE_WITH_PARENTS,
    payload: serviceService.getServiceSkillsByServiceWithParents,
  },
  "serviceId",
);

export const createServiceSkills = makeActionCreator(
  {
    type: ACTIONS.CREATE_SERVICE_SKILLS,
    payload: serviceService.createServiceSkills,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Skills successfully added to the Service",
    },
  },
  "serviceId",
);

export const deleteServiceSkill = makeActionCreator(
  {
    type: ACTIONS.DELETE_SERVICE_SKILL,
    payload: serviceService.deleteServiceSkill,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Skill successfully removed from the Service",
    },
  },
  "serviceId",
  "skiillId",
  "serviceSkillId",
);

export const deleteServiceSkillAskConfirmation = serviceSkill => dispatch => {
  return dispatch({
    type: ACTIONS.DELETE_SERVICE_SKILL_ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove Skill from Service",
      confirmButtonText: "Remove",
      description: `Are you sure you want to remove "${serviceSkill.get(
        "skillName",
      )}" from "${serviceSkill.get("serviceName")}"?`,
    },
    payload: () =>
      dispatch(
        deleteServiceSkill(
          serviceSkill.get("serviceId"),
          serviceSkill.get("skillId"),
          serviceSkill.get("serviceSkillId"),
        ),
      ).then(() => dispatch(getStatsForService(serviceSkill.get("serviceId")))),
  });
};

export const clearServiceSkills = () => dispatch => {
  return dispatch({
    type: ACTIONS.CLEAR_SERVICE_SKILLS,
  });
};

export const createServiceSkillsOnSubmit = (
  serviceId,
  skillIdList,
) => dispatch =>
  dispatch(createServiceSkills(serviceId, skillIdList)).then(() =>
    dispatch(getStatsForService(serviceId)),
  );

export const serviceSkillsOnLoad = serviceId => dispatch => {
  return dispatch(getServiceSkillsByServiceWithParents(serviceId));
};

export const serviceSkillsOnUnload = () => dispatch => {
  return dispatch(clearServiceSkills());
};

export const updateServiceSkill = makeActionCreator({
  type: ACTIONS.UPDATE_SERVICE_SKILL,
  payload: serviceService.updateServiceSkill,
});

/**
 * Training History Report
 */

export const trainingHistoryReportOnLoad = () => dispatch => {
  return Promise.all([dispatch(getDepartments()), dispatch(getEmployees())]);
};

export const trainingHistoryReportOnUnload = () => dispatch => {
  return Promise.all([dispatch(clearTrainingHistoryReport())]);
};

export const clearTrainingHistoryReport = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_TRAINING_HISTORY_REPORT,
  });
  dispatch(replace({ search: null }));
};

export const getTrainingHistoryReport = filters => dispatch => {
  const queryString = toQueryString(TrainingHistoryMapper.to(filters.toJS()));
  dispatch({
    type: ACTIONS.GET_TRAINING_HISTORY_REPORT,
    payload: () => reportService.getTrainingHistoryReport(queryString),
    filters,
  });
  dispatch(replace({ search: queryString }));
};

export const downloadTrainingHistoryReport = makeActionCreator(
  {
    type: ACTIONS.DOWNLOAD_TRAINING_HISTORY_REPORT,
    payload: reportService.downloadTrainingHistoryReport,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Training History Report Downloaded successfully ",
    },
  },
  "queryString",
);

export const downloadAndSaveTrainingHistoryReport = () => (
  dispatch,
  getState,
) => {
  const appliedFilters = getGapReportAppliedFiltersFromState(getState());
  const queryString = toQueryString(
    TrainingHistoryMapper.to(appliedFilters.toJS()),
  );
  return dispatch(
    downloadTrainingHistoryReport(queryString),
  ).then(([downloadedFile, filename]) => saveFile(downloadedFile, filename));
};

/**
 * Training and Competency Report
 */

export const trainingAndCompetencyReportOnLoad = () => dispatch => {
  return Promise.all([dispatch(getDepartments()), dispatch(getEmployees())]);
};

export const trainingAndCompetencyReportOnUnload = () => dispatch => {
  return Promise.all([dispatch(clearTrainingAndCompetencyReport())]);
};

export const getTrainingAndCompetencyReport = filters => dispatch => {
  const queryString = toQueryString(filters);

  dispatch({
    type: ACTIONS.GET_TRAINING_AND_COMPETENCY_REPORT,
    payload: () => reportService.getTrainingAndCmpetencyReport(queryString),
    filters,
  });
  dispatch(replace({ search: queryString }));
};

export const clearTrainingAndCompetencyReport = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_TRAINING_AND_COMPETENCY_REPORT,
  });
  dispatch(replace({ search: null }));
};

export const downloadTrainingAndCompetencyReport = makeActionCreator(
  {
    type: ACTIONS.DOWNLOAD_TRAINING_AND_COMPETENCY_REPORT,
    payload: reportService.downloadTrainingAndCmpetencyReport,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Training Anc Competency Report Downloaded successfully ",
    },
  },
  "queryString",
);

export const downloadAndSaveTrainingAndCompetencyReport = () => (
  dispatch,
  getState,
) => {
  const appliedFilters = getGapReportAppliedFiltersFromState(getState());

  return dispatch(
    downloadTrainingAndCompetencyReport(toQueryString(appliedFilters)),
  ).then(([downloadedFile, filename]) => saveFile(downloadedFile, filename));
};

/**
 * Course Expiry Report
 */

export const courseExpiryReportOnLoad = () => dispatch => {
  return Promise.all([dispatch(getCourses())]);
};

export const courseExpiryReportOnUnload = () => dispatch => {
  return Promise.all([dispatch(clearCourseExpiryReport())]);
};

export const clearCourseExpiryReport = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_COURSE_EXPIRY_REPORT,
  });
  dispatch(replace({ search: null }));
};

export const getCourseExpiryReport = filters => dispatch => {
  const queryString = toQueryString(
    CourseExpiryFilterMapper.to(filters.toJS()),
  );
  dispatch({
    type: ACTIONS.GET_COURSE_EXPIRY_REPORT,
    payload: () => reportService.getCourseExpiryReport(queryString),
    filters,
  });
  dispatch(replace({ search: queryString }));
};

export const downloadCourseExpiryReport = makeActionCreator(
  {
    type: ACTIONS.DOWNLOAD_COURSE_TRAINING_HISTORY_REPORT,
    payload: reportService.downloadCourseExpiryReport,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Course Expiry Report Downloaded successfully ",
    },
  },
  "queryString",
);

export const downloadAndSaveCourseExpiryReport = () => (dispatch, getState) => {
  const appliedFilters = getGapReportAppliedFiltersFromState(getState());

  const queryString = toQueryString(
    CourseExpiryFilterMapper.to(appliedFilters.toJS()),
  );
  return dispatch(
    downloadCourseExpiryReport(queryString),
  ).then(([downloadedFile, filename]) => saveFile(downloadedFile, filename));
};

/**
 * Course Training History Report
 */

export const courseTrainingHistoryReportOnLoad = () => dispatch => {
  return Promise.all([dispatch(getDepartments()), dispatch(getCourses())]);
};

export const courseTrainingHistoryReportOnUnload = () => dispatch => {
  return Promise.all([dispatch(clearCourseTrainingHistoryReport())]);
};

export const clearCourseTrainingHistoryReport = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_COURSE_TRAINING_HISTORY_REPORT,
  });
  dispatch(replace({ search: null }));
};

export const getCourseTrainingHistoryReport = filters => dispatch => {
  const queryString = toQueryString(
    CourseTrainingHistoryMapper.to(filters.toJS()),
  );
  dispatch({
    type: ACTIONS.GET_COURSE_TRAINING_HISTORY_REPORT,
    payload: () => reportService.getCourseTrainingHistoryReport(queryString),
    filters,
  });
  dispatch(replace({ search: queryString }));
};

export const downloadCourseTrainingHistoryReport = makeActionCreator(
  {
    type: ACTIONS.DOWNLOAD_COURSE_TRAINING_HISTORY_REPORT,
    payload: reportService.downloadCourseTrainingHistoryReport,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Course Training History Report Downloaded successfully ",
    },
  },
  "queryString",
);

export const downloadAndSaveCourseTrainingHistoryReport = () => (
  dispatch,
  getState,
) => {
  const appliedFilters = getGapReportAppliedFiltersFromState(getState());

  const queryString = toQueryString(
    TrainingHistoryMapper.to(appliedFilters.toJS()),
  );
  return dispatch(
    downloadCourseTrainingHistoryReport(queryString),
  ).then(([downloadedFile, filename]) => saveFile(downloadedFile, filename));
};
/**
 * Outstanding Training History Report
 */

export const outstandingTrainingHistoryReportOnLoad = () => dispatch => {
  return Promise.all([dispatch(getDepartments()), dispatch(getCourses())]);
};

export const outstandingTrainingHistoryReportOnUnload = () => dispatch => {
  return Promise.all([dispatch(clearOutstandingTrainingHistoryReport())]);
};

export const clearOutstandingTrainingHistoryReport = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_OUTSTANDING_TRAINING_HISTORY_REPORT,
  });
  dispatch(replace({ search: null }));
};

export const getOutstandingTrainingHistoryReport = filters => dispatch => {
  const queryString = toQueryString(
    OutstandingTrainingHistoryMapper.to(filters.toJS()),
  );
  dispatch({
    type: ACTIONS.GET_OUTSTANDING_TRAINING_HISTORY_REPORT,
    payload: () =>
      reportService.getOutstandingTrainingHistoryReport(queryString),
    filters,
  });
  dispatch(replace({ search: queryString }));
};

export const downloadOutstandingTrainingHistoryReport = makeActionCreator(
  {
    type: ACTIONS.DOWNLOAD_OUTSTANDING_TRAINING_HISTORY_REPORT,
    payload: reportService.downloadOutstandingTrainingHistoryReport,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Outstanding Training History Report Downloaded successfully ",
    },
  },
  "queryString",
);

export const downloadAndSaveOutstandingTrainingHistoryReport = () => (
  dispatch,
  getState,
) => {
  const appliedFilters = getGapReportAppliedFiltersFromState(getState());

  const queryString = toQueryString(
    OutstandingTrainingHistoryMapper.to(appliedFilters.toJS()),
  );
  return dispatch(
    downloadOutstandingTrainingHistoryReport(queryString),
  ).then(([downloadedFile, filename]) => saveFile(downloadedFile, filename));
};

/**
 * Service Gap Report
 */

export const serviceGapReportOnLoad = () => dispatch => {
  return Promise.all([
    dispatch(getDepartments()),
    dispatch(getServices()),
    dispatch(getEmployees()),
  ]);
};

export const serviceGapReportOnUnload = () => dispatch => {
  return Promise.all([
    dispatch(clearServiceGapReport()),
    dispatch(clearServices()),
  ]);
};

export const getServiceGapReport = filters => dispatch => {
  const queryString = toQueryString(filters);

  dispatch({
    type: ACTIONS.GET_SERVICE_GAP_REPORT,
    payload: () => reportService.getEmployeeServiceGapReport(queryString),
    filters,
  });
  dispatch(replace({ search: queryString }));
};

export const clearServiceGapReport = () => dispatch => {
  dispatch({
    type: ACTIONS.CLEAR_SERVICE_GAP_REPORT,
  });
  dispatch(replace({ search: null }));
};

export const downloadServiceGapReport = makeActionCreator(
  {
    type: ACTIONS.DOWNLOAD_SERVICE_GAP_REPORT,
    payload: reportService.downloadServiceGapReport,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Gap Report Downloaded successfully ",
    },
  },
  "queryString",
);

export const downloadAndSaveServiceGapReport = () => (dispatch, getState) => {
  const appliedFilters = getGapReportAppliedFiltersFromState(getState());

  return dispatch(
    downloadServiceGapReport(toQueryString(appliedFilters)),
  ).then(([downloadedFile, filename]) => saveFile(downloadedFile, filename));
};

const initializeCollectionDetailsForm = collection => dispatch =>
  dispatch(initialize(COLLECTION_FORM.ID, CollectionMapper.from(collection)));

export const collectionDetailsOnLoad = collectionId => dispatch =>
  dispatch(getCollection(collectionId)).then(collection =>
    dispatch(initializeCollectionDetailsForm(collection)),
  );

export const collectionDetailsOnChange = (values, dispatch, props) => {
  const { dirty, collectionId } = props;

  if (dirty) {
    return dispatch(updateCollection(collectionId, values)).then(collection =>
      dispatch(initializeCollectionDetailsForm(collection)),
    );
  }
};

export const createCollectionCourses = makeActionCreator(
  {
    type: ACTIONS.CREATE_COLLECTION_ITEMS,
    payload: collectionService.createCollectionCourses,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Courses successfully added to collection",
    },
  },
  "collectionId",
);

export const createCollectionSkills = makeActionCreator(
  {
    type: ACTIONS.CREATE_COLLECTION_ITEMS,
    payload: collectionService.createCollectionSkills,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Skills successfully added to collection",
    },
  },
  "collectionId",
);

export const deleteCollectionCourseAskConfirmation = (
  collectionId,
  collectionCourse,
) => dispatch =>
  dispatch({
    type: ACTIONS.ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove Course from collection",
      confirmButtonText: "Remove",
      description: `Are you sure you want to remove ${collectionCourse.get(
        "name",
      )} from this collection?`,
    },
    payload: () =>
      dispatch(
        deleteCollectionCourse(
          collectionId,
          collectionCourse.get("courseId"),
          collectionCourse.get("competencyCollectionItemId"),
        ),
      ),
  });

export const deleteCollectionSkillAskConfirmation = (
  collectionId,
  collectionSkill,
) => dispatch =>
  dispatch({
    type: ACTIONS.ASK_CONFIRMATION,
    confirmationDialog: {
      title: "Remove Skill from collection",
      confirmButtonText: "Delete",
      description: `Are you sure you want to remove ${collectionSkill.get(
        "name",
      )} from this collection?`,
    },
    payload: () =>
      dispatch(
        deleteCollectionSkill(
          collectionId,
          collectionSkill.get("skillId"),
          collectionSkill.get("competencyCollectionItemId"),
        ),
      ),
  });

export const deleteCollectionCourse = makeActionCreator(
  {
    type: ACTIONS.DELETE_COLLECTION_ITEM,
    payload: collectionService.deleteCollectionCourse,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Course successfully removed from collection",
    },
  },
  "collectionId",
  "courseId",
  "competencyCollectionItemId",
);

export const deleteCollectionSkill = makeActionCreator(
  {
    type: ACTIONS.DELETE_COLLECTION_ITEM,
    payload: collectionService.deleteCollectionSkill,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]:
        "Skill successfully removed from collection",
    },
  },
  "collectionId",
  "skillId",
  "competencyCollectionItemId",
);

export const collectionCollectionOnLoad = collectionId => dispatch =>
  Promise.all([
    dispatch(getAppFeatures()),
    dispatch(getCollectionItems(collectionId)),
  ]);

export const createCollectionCourseOnSubmit = (
  collectionId,
  courseIdList,
) => dispatch => dispatch(createCollectionCourses(collectionId, courseIdList));

export const createCollectionSkillOnSubmit = (
  collectionId,
  skillIdList,
) => dispatch => dispatch(createCollectionSkills(collectionId, skillIdList));

export const getAvailableCoursesForCollection = makeActionCreator({
  type: ACTIONS.GET_AVAILABLE_COURSES_FOR_COLLECTION,
  payload: collectionService.getAvailableCoursesForCollection,
});

export const getAvailableSkillsForCollection = makeActionCreator({
  type: ACTIONS.GET_AVAILABLE_SKILLS_FOR_COLLECTION,
  payload: collectionService.getAvailableSkillsForCollection,
});

export const getCollectionMatrix = makeActionCreator(
  {
    type: ACTIONS.GET_COLLECTION_MATRIX,
    payload: collectionService.getCollectionMatrix,
  },
  "collectionId",
);

export const resetCollectionMatrix = makeActionCreator(
  {
    type: ACTIONS.RESET_COLLECTION_MATRIX,
    payload: collectionService.resetCollectionMatrix,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Collection successfully reset",
    },
  },
  "collectionId",
);

export const collectionOnChange = (collectionId, collection) => dispatch =>
  dispatch(updateCollection(collectionId, collection));

export const collectionOnReset = collectionId => dispatch =>
  dispatch(resetCollectionMatrix(collectionId)).then(() =>
    dispatch(getCollectionItems(collectionId)),
  );

export const getCollectionItems = makeActionCreator(
  {
    type: ACTIONS.GET_COLLECTION_ITEMS,
    payload: collectionId =>
      Promise.all([
        collectionService.getCollectionCourses(collectionId),
        collectionService.getCollectionSkills(collectionId),
      ]).then(([collectionCourses, collectionSkills]) => [
        ...collectionCourses,
        ...collectionSkills,
      ]),
  },
  "collectionId",
);

export const updateCollectionMatrix = (
  collectionId,
  collectionMatrixUpdate,
) => dispatch => {
  const payload = collectionService
    .updateCollectionMatrix(collectionId, collectionMatrixUpdate)
    .then(() =>
      Promise.all([
        dispatch(getCollection(collectionId)),
        dispatch(getCollectionItems(collectionId)),
      ]),
    );

  dispatch({
    collectionId,
    type: ACTIONS.UPDATE_COLLECTION_MATRIX,
    payload,
    notification: {
      [NOTIFICATION_VARIANTS.SUCCESS]: "Collection successfully updated",
    },
  });

  return payload;
};

export const getCollectionsByCourse = makeActionCreator({
  type: ACTIONS.GET_COLLECTIONS,
  payload: collectionService.getCollectionsByCourseId,
});

export const getCollectionsBySkill = makeActionCreator({
  type: ACTIONS.GET_COLLECTIONS,
  payload: collectionService.getCollectionsBySkillId,
});

export const getCollectionItemsOverriddenBySkill = makeActionCreator(
  {
    type: ACTIONS.GET_SKILL_COLLECTION_OVERRIDES,
    payload: skillService.getCollectionItemsOverriddenBySkill,
  },
  "skillId",
);

export const getCollectionItemsOverriddenByCourse = makeActionCreator(
  {
    type: ACTIONS.GET_COURSE_COLLECTION_OVERRIDES,
    payload: courseService.getCollectionItemsOverriddenByCourse,
  },
  "courseId",
);
