import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { ACTIONS } from "app/app.constants";
import { getActionDataStateFromState } from "app/app.selectors";

import { getEmployeeServices } from "features/competence.actions";
import ServicesBasePage from "features/components/ServicesBasePage";
import { getEmployeeServicesFromState } from "features/competence.selectors";

const EmployeeServicesContainer = ({
  services,
  dataState,
  employeeId,
  breadcrumb,
  dispatchGetEmployeeServices,
}) => {
  useEffect(() => {
    dispatchGetEmployeeServices(employeeId);
  }, [employeeId, dispatchGetEmployeeServices]);

  return (
    <ServicesBasePage
      title={breadcrumb}
      dataState={dataState}
      services={services}
    />
  );
};

export default compose(
  connect(
    (state, { employeeId }) => {
      const employeeServiceSelector = getEmployeeServicesFromState(employeeId);

      return state => ({
        services: employeeServiceSelector(state),
        dataState: getActionDataStateFromState(
          state,
          ACTIONS.GET_EMPLOYEE_SERVICES,
        ),
      });
    },
    {
      dispatchGetEmployeeServices: getEmployeeServices,
    },
  ),
)(EmployeeServicesContainer);
