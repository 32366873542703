import { Map, OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { SpecificationCourseMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case ACTIONS.GET_SPECIFICATION_COURSES_BY_SPECIFICATION_WITH_PARENTS: {
      if (error) return state;

      return OrderedMap(
        payload.map(specificationCourse => [
          specificationCourse.specificationCourseId.toString(),
          SpecificationCourseMapper.from(specificationCourse),
        ]),
      ).groupBy(specificationCourse =>
        specificationCourse.get("specificationId").toString(),
      );
    }

    case ACTIONS.CREATE_SPECIFICATION_COURSES: {
      if (error) return state;

      return state.set(
        action.specificationId.toString(),
        OrderedMap(
          payload.map(specificationCourse => [
            specificationCourse.specificationCourseId.toString(),
            SpecificationCourseMapper.from(specificationCourse),
          ]),
        ),
      );
    }

    case ACTIONS.UPDATE_SPECIFICATION_COURSE: {
      if (error) return state;

      return state.setIn(
        [
          payload.specificationId.toString(),
          payload.specificationCourseId.toString(),
        ],
        SpecificationCourseMapper.from(payload),
      );
    }
    case ACTIONS.DELETE_SPECIFICATION_COURSE: {
      if (error) return state;

      return state.deleteIn([
        action.specificationId.toString(),
        action.specificationCourseId.toString(),
      ]);
    }
    case ACTIONS.CLEAR_SPECIFICATION_COURSES: {
      return initialState;
    }
    default:
      return state;
  }
};
