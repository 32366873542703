import React from "react";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "app/components/Tab/Tabs";

const ModalTabs = ({ classes, value, children }) => (
  <Tabs
    value={value}
    classes={{
      root: classes.tabsRoot,
    }}
  >
    {children}
  </Tabs>
);

const styles = theme => ({
  tabsRoot: {
    minHeight: 100,
    maxHeight: 100,
    background: theme.palette.grey[200],
  },
});

export default compose(React.memo, withStyles(styles))(ModalTabs);
