import { compose } from "redux";
import classNames from "classnames";
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Switch, FormControlLabel } from "@material-ui/core";

class SwitchField extends PureComponent {
  render() {
    const { label, labelPlacement, checked, onChange, classes } = this.props;
    return (
      <FormControlLabel
        classes={{
          label: classNames(classes.label, {
            [classes.labelNotChecked]: !checked,
          }),
        }}
        label={label}
        labelPlacement={labelPlacement}
        checked={checked}
        control={
          <Switch
            color="primary"
            classes={{
              switchBase: classes.coloredSwitch,
            }}
            onChange={onChange}
          />
        }
      />
    );
  }
}

const styles = theme => ({
  labelNotChecked: {
    color: theme.palette.text.disabled,
  },
  label: {
    ...theme.typography.body2,
  },
  coloredSwitch: {},
});

SwitchField.defaultProps = {
  labelPlacement: "end",
  color: "primary",
};

export default compose(withStyles(styles))(SwitchField);
