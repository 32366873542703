import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Grid, MenuItem, Typography } from "@material-ui/core";
import { Field, formValueSelector } from "redux-form/immutable";

import { EMPLOYEE_COURSE_FORM } from "features/competence.constants";

import {
  getCourseFromState,
  getEmployeeCourseFromState,
  getPrerequisiteCoursesFromState,
} from "features/competence.selectors";

import {
  ACTIONS,
  COMPETENCY_STATUS,
  APP_ROLES_HR_AND_ADMIN,
  EMPTY_MAP,
} from "app/app.constants";

import { required } from "utils/validation.util";
import TextField from "app/components/form/TextField";
import AccessControl from "app/components/AccessControl";
import CheckboxField from "app/components/form/CheckboxField";
import { getActionDataStateFromState } from "app/app.selectors";
import DatePickerField from "app/components/form/DatePickerField";
import FileUploadField from "app/components/form/FileUploadField/FileUploadField";
import EmployeeCourseFileUpload from "features/components/EmployeeCourseFileUpload";

/**
 *  From react-redux docs:
 * --------------------------------
 *  IMPORTANT: If the validation function returns errors
 *  and the form does not currently render fields for all of the errors,
 *  then the form will be considered valid and will be submitted.
 * --------------------------------
 * Since the validateEmployeeCourseForm checks agains competencyStatus and isActive,
 * we must render some "empty" (null) Fields for it to work.
 */
const PlaceholderComponent = () => null;

class AddEmployeeCourseCertificateForm extends PureComponent {
  render() {
    const {
      courseId,
      employeeId,
      employeeCourse,
      handleSubmit,
      isOverridden,
      prerequisiteCourses,
      course,
    } = this.props;

    const waitingApproval =
      employeeCourse.getIn([
        "pendingApprovalAttendance",
        "competencyStatus",
      ]) === COMPETENCY_STATUS.REQUIRES_APPROVAL;

    return (
      <form onSubmit={handleSubmit} id={EMPLOYEE_COURSE_FORM.ID}>
        <Grid container item xs={12} direction="column">
          <Grid container spacing={5}>
            {waitingApproval && (
              <Grid container item justify="center" spacing={1}>
                <Grid container justify="center" item>
                  <Typography variant="caption">
                    HR needs to approve the latest reqistered Course Attendance.
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Field
              component={PlaceholderComponent}
              name={EMPLOYEE_COURSE_FORM.IS_ACTIVE}
            />
            <Field
              component={PlaceholderComponent}
              name={EMPLOYEE_COURSE_FORM.COMPETENCY_STATUS}
            />
            <AccessControl accessLevel={APP_ROLES_HR_AND_ADMIN}>
              <Grid item>
                <Field
                  component={CheckboxField}
                  label="Override - Employee meets requirements for this course"
                  name={EMPLOYEE_COURSE_FORM.IS_OVERRIDDEN}
                />
                {isOverridden && (
                  <Field
                    autoFocus
                    fullWidth
                    required
                    margin="none"
                    variant="standard"
                    label="Override"
                    placeholder="Please provide an explanation for the override"
                    validate={isOverridden ? [required] : undefined}
                    component={TextField}
                    name={EMPLOYEE_COURSE_FORM.OVERRIDE_REASON}
                    inputProps={{
                      maxLength: 200,
                    }}
                  />
                )}
              </Grid>
            </AccessControl>
            {course.get("isCustom") && (
              <Grid container item>
                <Field
                  fullWidth
                  required
                  select
                  component={TextField}
                  label="Select Course"
                  validate={[required]}
                  name={EMPLOYEE_COURSE_FORM.PREREQUISITE_COURSE_ID}
                >
                  {prerequisiteCourses.map(prereq => (
                    <MenuItem
                      key={prereq.get("courseId")}
                      value={prereq.get("courseId")}
                    >
                      {prereq.get("name")}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            )}
            <Grid container item>
              <Field
                multiple={true}
                required={!isOverridden}
                validate={isOverridden ? undefined : [required]}
                component={FileUploadField}
                label="Upload certificate"
                name={EMPLOYEE_COURSE_FORM.FILES}
              >
                {({
                  value,
                  rootProps,
                  removeFile,
                  isDragActive,
                  openFileDialog,
                }) => (
                  <EmployeeCourseFileUpload
                    value={value}
                    courseId={courseId}
                    rootProps={rootProps}
                    removeFile={removeFile}
                    employeeId={employeeId}
                    disabled={waitingApproval}
                    isDragActive={isDragActive}
                    openFileDialog={openFileDialog}
                  />
                )}
              </Field>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item>
                <Field
                  inputVariant="standard"
                  validate={isOverridden ? undefined : [required]}
                  component={DatePickerField}
                  name={EMPLOYEE_COURSE_FORM.START_DATE}
                  DatePickerProps={{
                    disabled: waitingApproval || isOverridden,
                    required: !isOverridden,
                    margin: "none",
                    label: "Start Date",
                  }}
                />
              </Grid>
              <Grid item>
                <Field
                  inputVariant="standard"
                  validate={isOverridden ? undefined : [required]}
                  component={DatePickerField}
                  name={EMPLOYEE_COURSE_FORM.END_DATE}
                  DatePickerProps={{
                    disabled: waitingApproval || isOverridden,
                    required: !isOverridden,
                    margin: "none",
                    label: "End Date",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

AddEmployeeCourseCertificateForm.defaultProps = {
  course: EMPTY_MAP,
  employeeCourse: EMPTY_MAP,
};

const employeeCourseFormValueSelector = formValueSelector(
  EMPLOYEE_COURSE_FORM.ID,
);

export default compose(
  connect((state, { courseId, employeeId }) => ({
    course: getCourseFromState(state, courseId),
    employeeCourse: getEmployeeCourseFromState(state, employeeId, courseId),
    dataState: getActionDataStateFromState(state, ACTIONS.GET_EMPLOYEE_COURSE),
    isOverridden: employeeCourseFormValueSelector(
      state,
      EMPLOYEE_COURSE_FORM.IS_OVERRIDDEN,
    ),
    prerequisiteCourses: getPrerequisiteCoursesFromState(state, courseId),
  })),
)(AddEmployeeCourseCertificateForm);
