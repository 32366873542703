import React from "react";
import { compose } from "redux";
import { List } from "immutable";
import { withStyles } from "@material-ui/styles";
import AddCircle from "@material-ui/icons/AddCircle";
import { Chip, Grid, MenuItem } from "@material-ui/core";

import { DropdownMenu } from "altus-ui-components";

import { EMPTY_SET } from "app/app.constants";

const APP_ROLE_PREFIX = "HR Competency";

const AppRolesSelectField = ({ value, appRoles, onChange, classes }) => {
  const roles = List(value)
    .map(uniqueInternalName => appRoles.get(uniqueInternalName))
    .filter(Boolean);

  return (
    <Grid container spacing={1} wrap="nowrap" alignItems="center">
      {appRoles.size !== roles.size && (
        <Grid item>
          <DropdownMenu Trigger={<AddCircle className={classes.addIcon} />}>
            {appRoles
              .filterNot(appRole =>
                value.contains(appRole.get("uniqueInternalName")),
              )
              .toList()
              .map(appRole => (
                <MenuItem
                  onClick={() =>
                    onChange(value.add(appRole.get("uniqueInternalName")))
                  }
                  key={appRole.get("appRoleId")}
                >
                  {appRole
                    .get("name")
                    .replace(APP_ROLE_PREFIX, "")
                    .trim()}
                </MenuItem>
              ))}
          </DropdownMenu>
        </Grid>
      )}
      {value.size
        ? roles
            .sortBy(appRole => appRole.get("uniqueInternalName"))
            .map(appRole => (
              <Grid item key={appRole.get("appRoleId")}>
                <Chip
                  onDelete={() =>
                    onChange(value.delete(appRole.get("uniqueInternalName")))
                  }
                  label={appRole
                    .get("name")
                    .replace(APP_ROLE_PREFIX, "")
                    .trim()}
                />
              </Grid>
            ))
        : null}
    </Grid>
  );
};

AppRolesSelectField.defaultProps = {
  appRoles: EMPTY_SET,
};

const styles = theme => ({
  menuItemRoot: {
    "&.Mui-selected": {
      backgroundColor: "initial",
    },
  },
  addIcon: {
    cursor: "pointer",
    fill: theme.palette.primary.light,
    color: theme.palette.getContrastText(theme.palette.primary.light),
    "&:hover": {
      fill: theme.palette.primary.main,
    },
    "&:active": {
      fill: theme.palette.primary.main,
    },
    "&:focus": {
      fill: theme.palette.primary.main,
    },
  },
  chipLabel: {
    paddingLeft: theme.spacing(0.25),
    paddingRight: theme.spacing(0.25),
  },
});

export default compose(withStyles(styles))(AppRolesSelectField);
