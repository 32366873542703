import { compose } from "redux";
import { connect } from "react-redux";
import { generatePath } from "react-router";
import React, { useCallback, useEffect } from "react";
import { Grid, Button, Typography, Box } from "@material-ui/core";

import { BasePage } from "altus-ui-components";

import {
  redirectToPath,
  updateExperienceRecord,
  updateExperienceRecordItem,
  changeExperienceRecordValidator,
  getAllExperienceRecordCategories,
} from "features/competence.actions";

import {
  getExperienceRecordFromState,
  getExperienceCategoriesFromState,
  getExperienceRecordItemsFromState,
  getExperienceRecordAvailablePositionsFromState,
  getExperienceRecordAvailableValidatorsFromState,
} from "features/competence.selectors";

import {
  ACTIONS,
  EMPTY_MAP,
  APP_ROOT,
  APPROVAL_STATUS,
} from "app/app.constants";

import { ROUTE_KEYS } from "app/routes";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import ExperienceRecordDetails from "features/experience/ExperienceRecordDetails";
import ExperienceRecordValidatorSelector from "features/experience/ExperienceRecordValidatorSelector";

const ExperienceRecordDetailsContainer = ({
  path,
  source,
  employeeId,
  breadcrumb,
  dataState,
  experienceRecord = EMPTY_MAP,
  dispatchRedirect,
  experienceRecordId,
  experienceRecordItems,
  experienceRecordCategories,
  dispatchUpdateExperienceRecord,
  experienceRecordAvailablePositions,
  dispatchUpdateExperienceRecordItem,
  experienceRecordAvailableValidators,
  dispatchChangeExperienceRecordValidator,
  dispatchGetAllExperienceRecordCategories,
}) => {
  useEffect(() => {
    dispatchGetAllExperienceRecordCategories(experienceRecordId);
  }, [experienceRecordId, dispatchGetAllExperienceRecordCategories]);

  const onUpdateExperienceRecordItem = useCallback(
    recordItem =>
      dispatchUpdateExperienceRecordItem(
        employeeId,
        experienceRecordId,
        recordItem,
      ),
    [employeeId, experienceRecordId, dispatchUpdateExperienceRecordItem],
  );

  const onShowSummary = useCallback(
    () =>
      dispatchRedirect(
        `${generatePath(path.replace("record", ""), {
          employeeId,
          experienceRecordId,
        })}`,
      ),
    [path, employeeId, experienceRecordId, dispatchRedirect],
  );

  const onUpdateValidator = useCallback(
    validatorId => {
      if (source && source === ROUTE_KEYS.APPROVALS) {
        dispatchChangeExperienceRecordValidator(
          employeeId,
          experienceRecordId,
          validatorId,
        ).then(() => {
          dispatchRedirect(`${APP_ROOT.MY_PAGE}/approvals`);
        });
      } else {
        dispatchUpdateExperienceRecord(employeeId, experienceRecordId, {
          ...experienceRecord.toJS(),
          validatorId,
        });
      }
    },

    [
      source,
      employeeId,
      dispatchRedirect,
      experienceRecord,
      experienceRecordId,
      dispatchUpdateExperienceRecord,
      dispatchChangeExperienceRecordValidator,
    ],
  );

  const readOnly =
    experienceRecord.get("approvalStatus") === APPROVAL_STATUS.NOT_APPROVED;

  return (
    <BasePage title={breadcrumb} dataState={dataState}>
      <Grid container item component={Box} paddingBottom={2}>
        <Typography variant="subtitle2">
          <i>Please fill out this form to record experience for this job</i>
        </Typography>
      </Grid>
      <ExperienceRecordDetails
        readOnly={readOnly}
        employeeId={employeeId}
        experienceRecord={experienceRecord}
        experienceRecordId={experienceRecordId}
        experienceRecordItems={experienceRecordItems}
        experienceRecordCategories={experienceRecordCategories}
        onUpdateExperienceRecordItem={onUpdateExperienceRecordItem}
        experienceRecordAvailablePositions={experienceRecordAvailablePositions}
      />
      <Grid container item justifyContent="center">
        <ExperienceRecordValidatorSelector
          readOnly={readOnly}
          experienceRecord={experienceRecord}
          onUpdateValidator={onUpdateValidator}
          availableValidators={experienceRecordAvailableValidators}
        />
        <Grid item>
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={onShowSummary}
          >
            Show summary
          </Button>
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default compose(
  connect(
    (state, { experienceRecordId }) => ({
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_EXPERIENCE_RECORD,
        ACTIONS.UPDATE_EXPERIENCE_RECORD,
        ACTIONS.SUBMIT_EXPERIENCE_RECORD,
        ACTIONS.GET_EXPERIENCE_RECORD_ITEMS,
        ACTIONS.GET_STATS_FOR_EXPERIENCE_RECORD,
        ACTIONS.GET_ALL_EXPERIENCE_RECORD_CATEGORIES,
      ),
      experienceRecord: getExperienceRecordFromState(state, experienceRecordId),
      experienceRecordAvailablePositions: getExperienceRecordAvailablePositionsFromState(
        state,
      ),
      experienceRecordAvailableValidators: getExperienceRecordAvailableValidatorsFromState(
        state,
      ),
      experienceRecordCategories: getExperienceCategoriesFromState(state),
      experienceRecordItems: getExperienceRecordItemsFromState(
        state,
        experienceRecordId,
      ),
    }),
    {
      dispatchRedirect: redirectToPath,
      dispatchUpdateExperienceRecord: updateExperienceRecord,
      dispatchUpdateExperienceRecordItem: updateExperienceRecordItem,
      dispatchChangeExperienceRecordValidator: changeExperienceRecordValidator,
      dispatchGetAllExperienceRecordCategories: getAllExperienceRecordCategories,
    },
  ),
)(ExperienceRecordDetailsContainer);
