import React from "react";
import { compose } from "redux";
import memoize from "lodash/memoize";
import { Tooltip, SvgIcon } from "@material-ui/core";

import {
  Expired,
  Rejected,
  UpToDate,
  ExpiresSoon,
  NotApproved,
  RequiresApproval,
  MissingRequirements,
} from "altus-ui-components";

import { COMPETENCY_STATUS } from "app/app.constants";

export const statusToIcon = memoize(
  status =>
    ({
      [COMPETENCY_STATUS.NONE]: null,
      [COMPETENCY_STATUS.MISSING_REQUIREMENTS]: MissingRequirements,
      [COMPETENCY_STATUS.EXPIRED]: Expired,
      [COMPETENCY_STATUS.EXPIRES_SOON]: ExpiresSoon,
      [COMPETENCY_STATUS.NOT_APPROVED]: NotApproved,
      [COMPETENCY_STATUS.REQUIRES_APPROVAL]: RequiresApproval,
      [COMPETENCY_STATUS.UP_TO_DATE]: UpToDate,
      [COMPETENCY_STATUS.REJECTED]: Rejected,
    }[status]),
);

export const statusToTooltipText = memoize(
  status =>
    ({
      [COMPETENCY_STATUS.NONE]: "None",
      [COMPETENCY_STATUS.MISSING_REQUIREMENTS]: "Not completed",
      [COMPETENCY_STATUS.EXPIRED]: "Expired",
      [COMPETENCY_STATUS.EXPIRES_SOON]: "Expires soon",
      [COMPETENCY_STATUS.NOT_APPROVED]: "Not approved",
      [COMPETENCY_STATUS.REQUIRES_APPROVAL]: "Needs approval",
      [COMPETENCY_STATUS.UP_TO_DATE]: "Up-to-date",
      [COMPETENCY_STATUS.REJECTED]: "Rejected",
    }[status]),
);

const CompetencyStatusIcon = ({
  status,
  IconProps,
  displayTooltipText,
  Icon = statusToIcon(status),
}) => {
  const tooltipText = displayTooltipText ? statusToTooltipText(status) : "";

  if (!Icon) return null;

  return (
    <Tooltip title={tooltipText}>
      <SvgIcon color="secondary" {...IconProps}>
        <Icon />
      </SvgIcon>
    </Tooltip>
  );
};

CompetencyStatusIcon.defaultProps = {
  displayTooltipText: true,
};

export default compose()(CompetencyStatusIcon);
