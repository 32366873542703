import React from "react";
import { Field } from "redux-form/immutable";
import { Grid, Typography } from "@material-ui/core";

import { EMPLOYEE_CV_OPTIONS_FORM } from "features/competence.constants";

import TextField from "app/components/form/TextField";
import CheckboxField from "app/components/form/CheckboxField";
import ExportFileFormat from "features/components/ExportFileFormat";

const GenerateEmployeeCVForm = ({ isPdfExportFeatureFlagEnabled = false }) => {
  return (
    <form>
      <Grid container direction="column" spacing={1}>
        <Grid item /> {/* For spacing */}
        <Grid item>
          <Typography variant="subtitle2">Select items to include:</Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            label="Courses"
            component={CheckboxField}
            name={EMPLOYEE_CV_OPTIONS_FORM.INCLUDE_COURSE}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            label="Education"
            component={CheckboxField}
            name={EMPLOYEE_CV_OPTIONS_FORM.INCLUDE_EDUCATION}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            label="Work Experience"
            component={CheckboxField}
            name={EMPLOYEE_CV_OPTIONS_FORM.INCLUDE_WORK_EXPERIENCE}
          />
        </Grid>
        {isPdfExportFeatureFlagEnabled && (
          <Grid item xs={12}>
            <Field
              label="Export File Format"
              name={EMPLOYEE_CV_OPTIONS_FORM.FILE_FORMAT}
              component={ExportFileFormat}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Field
            rows={3}
            multiline
            label="Description"
            component={TextField}
            name={EMPLOYEE_CV_OPTIONS_FORM.DESCRIPTION}
            inputProps={{
              maxLength: 1000,
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default GenerateEmployeeCVForm;
