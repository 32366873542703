import { Map } from "immutable";
import { LOCATION_CHANGE } from "connected-react-router/immutable";

import { ACTIONS } from "app/app.constants";
import { UserMapper, EmployeeMapper, AppRoleMapper } from "app/app.mappers";

const initialState = Map({
  appFailure: null,
  appLoaded: null,
  currentUser: UserMapper.initial(),
  currentEmployee: EmployeeMapper.initial,
  users: Map(),
  appRoles: Map(),
  featureFlags: Map(),
});

export default (state = initialState, action) => {
  const { type, error, payload } = action;

  switch (type) {
    case ACTIONS.GET_CURRENT_USER: {
      if (error) return state;

      return state.set("currentUser", UserMapper.from(payload));
    }

    case ACTIONS.GET_CURRENT_EMPLOYEE: {
      if (error) return state;

      return state.set("currentEmployee", EmployeeMapper.from(payload));
    }

    case ACTIONS.CLEAR_USERS: {
      return state.set("users", initialState.get("users"));
    }

    case ACTIONS.GET_USERS: {
      if (error) return state;

      return state.set(
        "users",
        Map(
          payload.map(user => [user.userId.toString(), UserMapper.from(user)]),
        ),
      );
    }

    case ACTIONS.CREATE_USER_APP_ROLES:
    case ACTIONS.UPDATE_USER: {
      if (error) return state;

      return state.setIn(
        ["users", payload.userId.toString()],
        UserMapper.from(payload),
      );
    }

    case ACTIONS.SET_APPLICATION_FAILURE: {
      return state.set("appFailure", payload);
    }

    case LOCATION_CHANGE: {
      return state.get("appFailure") ? state.set("appFailure", null) : state;
    }

    case ACTIONS.GET_ALL_APP_ROLES: {
      if (error) return state;

      return state.set(
        "appRoles",
        Map(
          payload.map(appRole => [
            appRole.uniqueInternalName.toString(),
            AppRoleMapper.from(appRole),
          ]),
        ),
      );
    }

    case ACTIONS.GET_APPLICATION_FEATURES: {
      if (error) return state;

      return state.set("featureFlags", Map(payload));
    }

    default:
      return state;
  }
};
