import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import {
  getGapReportDepartmentsFromState,
  getCourseGapReportCoursesFromState,
} from "features/competence.selectors";

import { GAP_REPORT } from "app/app.constants";
import GapReport from "features/components/GapReport/GapReport";
import CourseGapReportHeader from "features/components/CourseGapReport/CourseGapReportHeader";
import CourseGapReportEmployeeRow from "features/components/CourseGapReport/CourseGapReportEmployeeRow";

class RoleGapReport extends PureComponent {
  renderHeader = () => {
    const { courses } = this.props;

    return <CourseGapReportHeader courses={courses} />;
  };

  renderEmployee = ({ employee, index }) => {
    const { root, courses } = this.props;

    return (
      <CourseGapReportEmployeeRow
        root={root}
        index={index}
        courses={courses}
        key={employee.get("employeeId")}
        employeeId={employee.get("employeeId")}
      />
    );
  };

  render() {
    const { root, title, employeesByDepartment, departments } = this.props;

    return (
      <GapReport
        root={root}
        title={title}
        departments={departments}
        renderHeader={this.renderHeader}
        employeesByDepartment={employeesByDepartment}
        renderEmployee={({ employee, index }) =>
          this.renderEmployee({ employee, index })
        }
        gapReportType={GAP_REPORT.COURSE_GAP_REPORT}
      />
    );
  }
}

export default compose(
  connect(state => ({
    courses: getCourseGapReportCoursesFromState(state),
    departments: getGapReportDepartmentsFromState(state),
  })),
)(RoleGapReport);
