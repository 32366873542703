import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isImmutable } from "immutable";
import { Grid, Typography, RootRef } from "@material-ui/core";
import React, { useMemo, useRef, useState, useEffect } from "react";

import {
  removeEmployeeCourseAttendanceApproval,
  updateEmployeeCourseAttendanceApproval,
  previewOrSaveEmployeeCourseAttendanceFile,
  getEmployeeCoursesAttendancesNeedingApproval,
} from "features/competence.actions";

import {
  ICONS,
  Format,
  ACTIONS,
  EMPTY_MAP,
  APPROVAL_STATUS,
} from "app/app.constants";

import { formatDate } from "utils/format.util";
import SortableTable from "app/components/Table/SortableTable";
import DownloadFileColumn from "app/components/DownloadFileColumn";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import ApproveButton from "features/hr/approval/components/ApproveButton";
import CustomDatePickerColumn from "features/hr/approval/components/CustomDatePickerColumn";
import { getEmployeeCoursesAttendancesApprovalFromState } from "features/competence.selectors";

const renderIcon = employeeCourse =>
  employeeCourse.get("isOverridden") ? (
    <ICONS.COURSE_OVERRIDEN color="primary" />
  ) : (
    <ICONS.COURSE color="primary" />
  );

const getStartDate = (item, customStartDates) => {
  const attendanceId = item.get("attendanceId").toString();
  const date = customStartDates.get(attendanceId) || item.get("startDate");
  return date;
};

const getEndDate = (item, customEndDates) => {
  const attendanceId = item.get("attendanceId").toString();
  const date = customEndDates.get(attendanceId) || item.get("endDate");
  return date;
};

const EmployeeCourseApproval = ({
  root,
  onCourseCountChange,
  employeeCoursesAttendances = EMPTY_MAP,
  dispatchUpdateEmployeeCourseAttendanceApproval,
  dispatchRemoveEmployeeCourseAttendanceApproval,
  dispatchPreviewOrSaveEmployeeCourseAttendanceFile,
  dispatchGetEmployeeCoursesAttendancesNeedingApproval,
}) => {
  const rootRef = useRef();

  const [customEndDates, setCustomEndDates] = useState(EMPTY_MAP);
  const [customStartDates, setCustomStartDates] = useState(EMPTY_MAP);

  useEffect(() => {
    onCourseCountChange(employeeCoursesAttendances.count());
  }, [employeeCoursesAttendances, onCourseCountChange]);

  const columns = useMemo(
    () => [
      {
        xs: 4,
        id: "courseName",
        component: Grid,
        Header: "Course Attendance",
        title: "Course Attendance",
        canSort: true,
        accessor: item => {
          if (isImmutable(item)) {
            return item.get("courseName");
          }
        },
        Cell: ({ row }) => {
          if (row.depth === 0) {
            return (
              <Grid>
                <Typography noWrap variant="body2">
                  {row.original.get("courseName")}
                </Typography>
                <Typography noWrap component="p" variant="caption">
                  {row.original.get("employeeDisplayName")}
                </Typography>
              </Grid>
            );
          }
          return (
            <DownloadFileColumn
              file={row.original}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();

                const employeeCoursesAttendance = employeeCoursesAttendances
                  .filter(
                    x =>
                      x.get("attendanceId").toString() ===
                      row.original.employeeCourseAttendanceId.toString(),
                  )
                  .first();

                dispatchPreviewOrSaveEmployeeCourseAttendanceFile(
                  employeeCoursesAttendance.get("employeeId"),
                  employeeCoursesAttendance.get("courseId"),
                  employeeCoursesAttendance.get("attendanceId"),
                  row.original,
                );
              }}
            />
          );
        },
      },
      {
        xs: 2,
        id: "courseTypeName",
        title: "Course Type",
        Header: "Course Type",
        canSort: true,
        filter: {
          label: "Type",
          defaultText: "All types",
        },
        getSortProperty: item => item.get("courseTypeName"),
        accessor: item => {
          if (isImmutable(item)) {
            return item.get("courseTypeName");
          }
        },
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return row.original.get("courseTypeName");
        },
      },
      {
        xs: true,
        title: "Start",
        Header: "Start",
        id: "start",
        canSort: true,
        accessor: item => {
          if (isImmutable(item)) {
            return formatDate(item.get("startDate"), Format.date);
          }
        },
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          const setCustomDateValue = newDate => {
            const attendanceId = row.original.get("attendanceId").toString();
            setCustomStartDates(x => x.set(attendanceId, newDate));
          };

          return (
            <CustomDatePickerColumn
              originalValue={getStartDate(row.original, customStartDates)}
              setCustomDateValue={setCustomDateValue}
            />
          );
        },
      },
      {
        xs: true,
        component: Grid,
        title: "Finished",
        Header: "Finished",
        id: "finish",
        canSort: true,
        accessor: item => {
          if (isImmutable(item)) {
            return formatDate(item.get("endDate"), Format.date);
          }
        },
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          const setCustomDateValue = newDate => {
            const attendanceId = row.original.get("attendanceId").toString();
            setCustomEndDates(x => x.set(attendanceId, newDate));
          };

          return (
            <CustomDatePickerColumn
              originalValue={getEndDate(row.original, customEndDates)}
              setCustomDateValue={setCustomDateValue}
            />
          );
        },
      },
      {
        title: "",
        id: "actions",
        xs: true,
        component: Grid,
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";

          return (
            <ApproveButton
              item={row.original}
              id={row.original.get("attendanceId")}
              approveAction={ACTIONS.UPDATE_EMPLOYEE_COURSE_ATTENDANCE_APPROVAL}
              rejectAction={ACTIONS.UPDATE_EMPLOYEE_COURSE_ATTENDANCE_APPROVAL}
              undoAction={ACTIONS.REMOVE_EMPLOYEE_COURSE_ATTENDANCE_APPROVAL}
              onApprove={() => {
                const attendanceId = row.original
                  .get("attendanceId")
                  .toString();

                dispatchUpdateEmployeeCourseAttendanceApproval(
                  row.original.get("employeeId"),
                  row.original.get("courseId"),
                  attendanceId,
                  {
                    startDate: customStartDates.get(attendanceId),
                    endDate: customEndDates.get(attendanceId),
                    approvalStatus: APPROVAL_STATUS.APPROVED,
                  },
                );
              }}
              onReject={() => {
                const attendanceId = row.original
                  .get("attendanceId")
                  .toString();

                dispatchUpdateEmployeeCourseAttendanceApproval(
                  row.original.get("employeeId"),
                  row.original.get("courseId"),
                  attendanceId,
                  {
                    startDate: customStartDates.get(attendanceId),
                    endDate: customEndDates.get(attendanceId),
                    approvalStatus: APPROVAL_STATUS.NOT_APPROVED,
                  },
                );
              }}
              onUndo={() =>
                dispatchRemoveEmployeeCourseAttendanceApproval(
                  row.original.get("employeeId"),
                  row.original.get("courseId"),
                  row.original.get("attendanceId"),
                )
              }
            />
          );
        },
      },
      {
        xs: true,
        title: "",
        id: "competencyStatus",
        component: Grid,
        container: true,
        justify: "flex-end",
        getSortProperty: item => item.get("competencyStatus"),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return (
            <CompetencyStatusChip
              status={row.original.get("competencyStatus")}
            />
          );
        },
      },
    ],
    [
      employeeCoursesAttendances,
      customEndDates,
      customStartDates,
      dispatchUpdateEmployeeCourseAttendanceApproval,
      dispatchRemoveEmployeeCourseAttendanceApproval,
      dispatchPreviewOrSaveEmployeeCourseAttendanceFile,
    ],
  );

  return (
    <RootRef rootRef={rootRef}>
      <SortableTable
        renderIcon={renderIcon}
        Icon={ICONS.COURSE}
        title="Course"
        items={employeeCoursesAttendances}
        defaultSortColumn={null}
        columns={columns}
        subListPropertyName="files"
        subListFilterPropName="name"
        keyName="attendanceId"
        hideBaseHeader={true}
        excludePadding={true}
        displayNumberOfItems={true}
        noResultsMessage="No courses attendances waiting approval..."
        onRefresh={dispatchGetEmployeeCoursesAttendancesNeedingApproval}
        createSortableListRowProps={item => ({
          component: Link,
          to: `${root}/employees/${item.get("employeeId")}/courses/${item.get(
            "courseId",
          )}/attendance`,
          // Prevent date picker to navigate to employee course
          onClick: event => {
            if (rootRef.current && !rootRef.current.contains(event.target)) {
              event.preventDefault();
              event.stopPropagation();
            }
          },
        })}
      />
    </RootRef>
  );
};

export default compose(
  connect(
    state => ({
      employeeCoursesAttendances: getEmployeeCoursesAttendancesApprovalFromState(
        state,
      ),
    }),
    {
      dispatchUpdateEmployeeCourseAttendanceApproval: updateEmployeeCourseAttendanceApproval,
      dispatchRemoveEmployeeCourseAttendanceApproval: removeEmployeeCourseAttendanceApproval,
      dispatchPreviewOrSaveEmployeeCourseAttendanceFile: previewOrSaveEmployeeCourseAttendanceFile,
      dispatchGetEmployeeCoursesAttendancesNeedingApproval: getEmployeeCoursesAttendancesNeedingApproval,
    },
  ),
)(EmployeeCourseApproval);
