import { compose } from "redux";
import memoize from "lodash/memoize";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { PureComponent } from "react";
import { IconButton, Tooltip } from "@material-ui/core";

import {
  rolesOnLoad,
  rolesOnUnload,
  getStatsForRoles,
  deleteRoleAskConfirmation,
} from "features/competence.actions";

import { getActionDataStateFromState } from "app/app.selectors";

import routePaths from "app/routePaths";
import { ACTIONS, ICONS, EMPTY_MAP } from "app/app.constants";
import RolesBasePage from "features/components/RolesBasePage";
import { getRoleStatsByRoleIdFromState } from "features/competence.selectors";

class RolesCatalogue extends PureComponent {
  constructor(props) {
    super(props);

    this.createColumnsMemoized = memoize(this.createColumns);
  }

  componentDidMount() {
    const { dispatchOnLoad, dispatchGetRoleStatsForRoles } = this.props;

    dispatchOnLoad();
    dispatchGetRoleStatsForRoles();
  }

  componentWillUnmount() {
    const { dispatchOnUnload } = this.props;
    dispatchOnUnload();
  }

  actions = [
    {
      getValue: item => (
        <Tooltip title="Delete">
          <IconButton onClick={() => this.props.dispatchDeleteRole(item)}>
            <ICONS.DELETE fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  createSortableListRowProps = item => ({
    component: Link,
    to: `${routePaths.hrPortal}/roles/${item.get("roleId")}`,
  });

  createColumns = roleStatsByRoleId => [
    {
      xs: 4,
      title: "Role Name",
      getSortProperty: item => item.get("name"),
    },
    {
      xs: 1,
      visible: false,
      title: (
        <Tooltip title="Sort by Tree Structure">
          <ICONS.TREE_VIEW />
        </Tooltip>
      ),
      getSortProperty: item => item.get("treeMin"),
    },
    {
      xs: true,
      filter: {
        defaultText: "All owners",
      },
      title: "Owner",
      getSortProperty: item => item.get("ownerDisplayName"),
    },
    {
      xs: 1,
      title: "Status",
      getSortProperty: item => item.get("status"),
    },
    {
      filter: {
        defaultText: "Any",
        label: "Countries",
        getFilterText: filter => (filter === "" ? "Global" : filter),
      },
      xs: 1,
      title: "Countries",
      getSortProperty: item =>
        item
          .get("countries")
          .map(c => c.get("twoLetterCode"))
          .join(", "),
    },
    {
      xs: 1,
      container: true,
      justify: "center",
      title: (
        <Tooltip title="Skill Count">
          <ICONS.SKILL />
        </Tooltip>
      ),
      getSortProperty: item =>
        roleStatsByRoleId.getIn(
          [item.get("roleId").toString(), "requiredSkillsCount"],
          "-",
        ),
    },
    {
      xs: 1,
      container: true,
      justify: "center",
      title: (
        <Tooltip title="Course Count">
          <ICONS.COURSE />
        </Tooltip>
      ),
      getSortProperty: item =>
        roleStatsByRoleId.getIn(
          [item.get("roleId").toString(), "requiredCoursesCount"],
          "-",
        ),
    },
    {
      xs: 1,
      container: true,
      justify: "center",
      title: (
        <Tooltip title="Member Count">
          <ICONS.TEAM />
        </Tooltip>
      ),
      getSortProperty: item =>
        roleStatsByRoleId.getIn(
          [item.get("roleId").toString(), "memberCount"],
          "-",
        ),
    },
  ];

  render() {
    const { dataState, breadcrumb, roleStatsByRoleId } = this.props;

    // the columns does not update automatically when the roleStats are fetched, so we
    // use this little hack
    const columns = this.createColumnsMemoized(roleStatsByRoleId);

    return (
      <RolesBasePage
        displayAddButton
        columns={columns}
        title={breadcrumb}
        dataState={dataState}
        actions={this.actions}
        createSortableListRowProps={this.createSortableListRowProps}
      />
    );
  }
}

RolesCatalogue.defaultProps = {
  roleStatsByRoleId: EMPTY_MAP,
};

export default compose(
  connect(
    state => ({
      roleStatsByRoleId: getRoleStatsByRoleIdFromState(state),
      dataState: getActionDataStateFromState(state, ACTIONS.GET_ROLES),
    }),
    {
      dispatchOnLoad: rolesOnLoad,
      dispatchOnUnload: rolesOnUnload,
      dispatchDeleteRole: deleteRoleAskConfirmation,
      dispatchGetRoleStatsForRoles: getStatsForRoles,
    },
  ),
)(RolesCatalogue);
