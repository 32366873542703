import { compose } from "redux";
import isNil from "lodash/isNil";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import ProgressBar from "app/components/ProgressBar";
import { EMPTY_MAP } from "app/app.constants";
import { getSkillStatsFromState } from "features/competence.selectors";

class SkillProgressBar extends PureComponent {
  render() {
    const { skillStats } = this.props;

    const memberCount = skillStats.get("memberCount");
    const total = isNil(memberCount) ? 0 : memberCount;

    const activeCount = skillStats.get("activeCount");
    const value = isNil(activeCount)
      ? 0
      : total === 0
      ? 100
      : (activeCount / total) * 100;

    return <ProgressBar value={value} title={`${value.toFixed(0)}%`} />;
  }
}

SkillProgressBar.defaultProps = {
  skillStats: EMPTY_MAP,
};

export default compose(
  connect((state, { skillId, skillStats }) => ({
    skillStats: skillStats
      ? skillStats
      : getSkillStatsFromState(state, skillId),
  })),
)(SkillProgressBar);
