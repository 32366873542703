import { compose } from "redux";
import { connect } from "react-redux";
import { Grid, Tooltip } from "@material-ui/core";
import React, { PureComponent } from "react";

import {
  getEmployeeRoleCourseList,
  previewOrSaveEmployeeCourseAttendanceFile,
} from "features/competence.actions";

import {
  ICONS,
  Format,
  ACTIONS,
  COMPETENCY_STATUS,
  criticalityToString,
} from "app/app.constants";

import { formatDate } from "utils/format.util";
import CrudBasePage from "app/components/CrudBasePage";
import { resolveEmployeeCourseFile } from "utils/app.util";
import { getActionDataStateFromState } from "app/app.selectors";
import DownloadFileColumn from "app/components/DownloadFileColumn";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";
import { createEmployeeRoleCoursesSelector } from "features/competence.selectors";

class EmployeeRoleCoursesContainer extends PureComponent {
  componentDidMount() {
    const { employeeId, dispatchGetRoleCoursesByRole } = this.props;
    dispatchGetRoleCoursesByRole(employeeId);
  }

  columns = [
    {
      xs: 4,
      title: "Course Name",
      getSortProperty: employeeRoleCourse =>
        employeeRoleCourse.getIn(["courseName"]),
    },
    {
      filter: {
        defaultText: "All types",
      },
      xs: true,
      title: "Type",
      getSortProperty: employeeRoleCourse =>
        employeeRoleCourse.getIn(["courseTypeName"]),
    },
    {
      xs: true,
      title: "Completed",
      getSortProperty: employeeRoleCourse =>
        formatDate(employeeRoleCourse.get("endDate", ""), Format.date),
    },
    {
      xs: true,
      title: "Expires",
      getSortProperty: employeeRoleCourse =>
        formatDate(employeeRoleCourse.get("expirationDate", ""), Format.date),
    },
    {
      xs: true,
      component: Grid,
      title: "Certificate",
      getSortProperty: employeeRoleCourse => {
        const file = resolveEmployeeCourseFile(employeeRoleCourse);

        return file ? file.name : "";
      },
      getValue: employeeRoleCourse => {
        const file = resolveEmployeeCourseFile(employeeRoleCourse);

        return (
          <DownloadFileColumn
            file={file}
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();

              this.props.dispatchPreviewOrSaveEmployeeCourseAttendanceFile(
                this.props.employeeId,
                employeeRoleCourse.get("courseId"),
                employeeRoleCourse.get("bestAttendanceId"),
                file,
              );
            }}
          />
        );
      },
    },
    {
      xs: true,
      title: "Criticality",
      getSortProperty: employeeRoleCourse =>
        criticalityToString(employeeRoleCourse.get("criticality")),
    },
    {
      xs: true,
      title: "",
      component: Grid,
      container: true,
      justify: "flex-end",
      getSortProperty: employeeRoleCourse =>
        employeeRoleCourse.get(
          "competencyStatus",
          COMPETENCY_STATUS.MISSING_REQUIREMENTS,
        ),
      getValue: employeeRoleCourse => (
        <CompetencyStatusChip
          status={employeeRoleCourse.get(
            "competencyStatus",
            COMPETENCY_STATUS.MISSING_REQUIREMENTS,
          )}
        />
      ),
      filter: {
        label: "Status",
        defaultText: "All",
        getFilterText: filter => statusToTooltipText(filter),
      },
    },
  ];

  getKey = employeeRoleCourse => employeeRoleCourse.get("courseId");

  renderIcon = employeeRoleCourse =>
    employeeRoleCourse.get("isOverridden") ? (
      <Tooltip title="Overridden">
        <ICONS.COURSE_OVERRIDEN color="primary" />
      </Tooltip>
    ) : (
      <ICONS.COURSE color="primary" />
    );

  render() {
    const { dataState, employeeRoleCourses, breadcrumb } = this.props;

    return (
      <CrudBasePage
        title={breadcrumb}
        items={employeeRoleCourses}
        dataState={dataState}
        SortableListProps={{
          dataState,
          renderIcon: this.renderIcon,
          getKey: this.getKey,
          columns: this.columns,
        }}
      />
    );
  }
}

export default compose(
  connect(
    (initialState, { employeeId, roleId }) => {
      const employeeRoleCoursesSelector = createEmployeeRoleCoursesSelector(
        employeeId,
        roleId,
      );

      return state => ({
        employeeRoleCourses: employeeRoleCoursesSelector(state),
        dataState: getActionDataStateFromState(
          state,
          ACTIONS.GET_EMPLOYEE_COURSES_BY_ROLE,
        ),
      });
    },
    {
      dispatchGetRoleCoursesByRole: getEmployeeRoleCourseList,
      dispatchPreviewOrSaveEmployeeCourseAttendanceFile: previewOrSaveEmployeeCourseAttendanceFile,
    },
  ),
)(EmployeeRoleCoursesContainer);
