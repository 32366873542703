import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { isImmutable } from "immutable";
import React, { useMemo, useState, useRef, useEffect } from "react";

import { Grid, RootRef, Typography } from "@material-ui/core";

import {
  educationApprovalOnChange,
  getEmployeeEducationRequiringApproval,
  previewOrSaveEducationFile,
  updateEmployeeEducationApproval,
  removeEmployeeEducationApproval,
} from "features/competence.actions";
import {
  ICONS,
  Format,
  ACTIONS,
  EMPTY_MAP,
  APPROVAL_STATUS,
} from "app/app.constants";

import { formatDate } from "utils/format.util";
import SortableTable from "app/components/Table/SortableTable";
import DownloadFileColumn from "app/components/DownloadFileColumn";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import ApproveButton from "features/hr/approval/components/ApproveButton";
import { getEducationsApprovalFromState } from "features/competence.selectors";
import CustomDatePickerColumn from "features/hr/approval/components/CustomDatePickerColumn";

const getStartDate = (item, customStartDates) => {
  const educationId = item.get("educationId").toString();
  return customStartDates.get(educationId) || item.get("from");
};

const getEndDate = (item, customEndDates) => {
  const educationId = item.get("educationId").toString();
  return customEndDates.get(educationId) || item.get("to");
};

const EducationApproval = ({
  root,
  onEducationCountChange,
  educations = EMPTY_MAP,
  dispatchUpdateEmployeeEducationApproval,
  dispatchPreviewOrSaveEducationFile,
  dispatchRemoveEmployeeEducationApproval,
  dispatchGetEmployeeEducationRequiringApproval,
}) => {
  const rootRef = useRef();

  const [customEndDates, setCustomEndDates] = useState(EMPTY_MAP);
  const [customStartDates, setCustomStartDates] = useState(EMPTY_MAP);

  useEffect(() => {
    onEducationCountChange(educations.count());
  }, [educations, onEducationCountChange]);

  const columns = useMemo(
    () => [
      {
        xs: 3,
        title: "Title",
        Header: "Title",
        id: "title",
        component: Grid,
        canSort: true,
        accessor: item => {
          if (isImmutable(item)) {
            return item.get("title");
          }
        },
        Cell: ({ row }) => {
          if (row.depth === 0) {
            return (
              <Grid>
                <Typography noWrap variant="body2">
                  {row.original.get("title")}
                </Typography>
                <Typography noWrap variant="caption" component="p">
                  {row.original.get("employeeName")}
                </Typography>
              </Grid>
            );
          }

          return (
            <DownloadFileColumn
              file={row.original}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();

                const education = educations
                  .filter(
                    x =>
                      x.get("educationId").toString() ===
                      row.original.educationId.toString(),
                  )
                  .first();
                dispatchPreviewOrSaveEducationFile(
                  education.get("employeeId"),
                  education.get("educationId"),
                  row.original,
                );
              }}
            />
          );
        },
      },
      {
        xs: 2,
        component: Grid,
        title: "Category",
        Header: "Category",
        id: "category",
        canSort: true,
        filter: {
          label: "Category",
          defaultText: "All categories",
        },
        getSortProperty: item => item.get("parentCompetencyCategoryName"),
        accessor: item => {
          if (isImmutable(item)) {
            return item.get("parentCompetencyCategoryName");
          }
        },
        Cell: ({ row }) => {
          if (row.depth === 0) {
            return (
              <Grid>
                <Typography noWrap variant="body2">
                  {row.original.get("parentCompetencyCategoryName")}
                </Typography>
                <Typography noWrap component="p" variant="caption">
                  {row.original.get("competencyCategoryName")}
                </Typography>
              </Grid>
            );
          }

          return "";
        },
      },
      {
        xs: true,
        title: "Start",
        id: "start",
        Header: "Start",
        component: Grid,
        canSort: true,
        accessor: item => {
          if (isImmutable(item)) {
            return formatDate(item.get("from"), Format.date);
          }
        },
        Cell: ({ row }) => {
          if (row.depth === 0) {
            const setCustomDateValue = newDate => {
              const educationId = row.original.get("educationId").toString();
              setCustomStartDates(x => x.set(educationId, newDate));
            };

            return (
              <CustomDatePickerColumn
                originalValue={getStartDate(row.original, customStartDates)}
                setCustomDateValue={setCustomDateValue}
              />
            );
          }

          return "";
        },
      },
      {
        xs: true,
        component: Grid,
        title: "Finished",
        Header: "Finished",
        id: "finished",
        canSort: true,
        accessor: item => {
          if (isImmutable(item)) {
            return formatDate(item.get("to"), Format.date);
          }
        },
        Cell: ({ row }) => {
          if (row.depth === 0) {
            const setCustomDateValue = newDate => {
              const educationId = row.original.get("educationId").toString();
              setCustomEndDates(x => x.set(educationId, newDate));
            };

            return (
              <CustomDatePickerColumn
                originalValue={getEndDate(row.original, customEndDates)}
                setCustomDateValue={setCustomDateValue}
              />
            );
          }

          return "";
        },
      },
      {
        title: "",
        xs: true,
        id: "approve",
        component: Grid,
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";

          return (
            <ApproveButton
              item={row.original}
              id={row.original.get("educationId")}
              approveAction={ACTIONS.UPDATE_EMPLOYEE_EDUCATION_APPROVAL}
              rejectAction={ACTIONS.UPDATE_EMPLOYEE_EDUCATION_APPROVAL}
              undoAction={ACTIONS.REMOVE_EMPLOYEE_EDUCATION_APPROVAL}
              onApprove={() => {
                const educationId = row.original.get("educationId").toString();

                dispatchUpdateEmployeeEducationApproval(
                  row.original.get("employeeId"),
                  row.original.get("educationId"),
                  {
                    from: customStartDates.get(educationId),
                    to: customEndDates.get(educationId),
                    approvalStatus: APPROVAL_STATUS.APPROVED,
                  },
                  //row.original.set("approvalStatus", APPROVAL_STATUS.APPROVED),
                );
              }}
              onReject={() => {
                const educationId = row.original.get("educationId").toString();
                dispatchUpdateEmployeeEducationApproval(
                  row.original.get("employeeId"),
                  row.original.get("educationId"),
                  {
                    from: customStartDates.get(educationId),
                    to: customEndDates.get(educationId),
                    approvalStatus: APPROVAL_STATUS.NOT_APPROVED,
                  },
                );
              }}
              onUndo={() =>
                dispatchRemoveEmployeeEducationApproval(
                  row.original.get("employeeId"),
                  row.original.get("educationId"),
                )
              }
            />
          );
        },
      },
      {
        xs: true,
        title: "",
        id: "competencyStatus",
        component: Grid,
        container: true,
        justify: "flex-end",
        getSortProperty: item => item.get("competencyStatus"),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return (
            <CompetencyStatusChip
              status={row.original.get("competencyStatus")}
            />
          );
        },
      },
    ],
    [
      educations,
      customEndDates,
      customStartDates,
      dispatchUpdateEmployeeEducationApproval,
      dispatchPreviewOrSaveEducationFile,
      dispatchRemoveEmployeeEducationApproval,
    ],
  );

  return (
    <RootRef rootRef={rootRef}>
      <SortableTable
        title="Education"
        items={educations}
        Icon={ICONS.EDUCATION}
        columns={columns}
        defaultSortColumn={null}
        subListPropertyName="files"
        subListFilterPropName="name"
        subListKey="educationFileId"
        keyName="educationId"
        hideBaseHeader={true}
        excludePadding={true}
        displayNumberOfItems={true}
        noResultsMessage="No education items waiting approval..."
        onRefresh={dispatchGetEmployeeEducationRequiringApproval}
        createSortableListRowProps={item => ({
          component: Link,
          to: `${root}/employees/${item.get("employeeId")}/education`,
          // Prevent date picker to navigate to employee education
          onClick: event => {
            if (rootRef.current && !rootRef.current.contains(event.target)) {
              event.preventDefault();
              event.stopPropagation();
            }
          },
        })}
      />
    </RootRef>
  );
};

export default compose(
  connect(
    state => ({
      educations: getEducationsApprovalFromState(state),
    }),
    {
      dispatchEducationApprovalOnChange: educationApprovalOnChange,
      dispatchPreviewOrSaveEducationFile: previewOrSaveEducationFile,
      dispatchGetEmployeeEducationRequiringApproval: getEmployeeEducationRequiringApproval,
      dispatchUpdateEmployeeEducationApproval: updateEmployeeEducationApproval,
      dispatchRemoveEmployeeEducationApproval: removeEmployeeEducationApproval,
    },
  ),
)(EducationApproval);
