import { compose } from "redux";
import { List } from "immutable";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";

import { getCoursesByCourseIdFromState } from "features/competence.selectors";

import { invokeIfFunction } from "utils/app.util";
import { EMPTY_MAP, EMPTY_LIST } from "app/app.constants";
import { BASE_FILTERS } from "features/components/GapReport/GapReportFilterBase";
import GapReportFilterBase from "features/components/GapReport/GapReportFilterBase";
import GapReportAutocompleteFilter from "features/components/GapReport/GapReportAutocompleteFilter";

const FILTERS = {
  COURSES: "courses",
};

const initialFilter = EMPTY_MAP.set(BASE_FILTERS.DEPARTMENTS, EMPTY_LIST)
  .set(BASE_FILTERS.EMPLOYEES, EMPTY_LIST)
  .set(BASE_FILTERS.STATUS, EMPTY_LIST)
  .set(FILTERS.COURSES, EMPTY_LIST);

class CourseGapReportFilter extends PureComponent {
  renderFilter = ({
    filter,
    setFilter,
    renderStatusFilter,
    renderEmployeeFilter,
    renderDepartmentFilter,
  }) => {
    const { courses } = this.props;

    return (
      <>
        {invokeIfFunction(renderDepartmentFilter)}
        {invokeIfFunction(renderEmployeeFilter)}
        <Grid item xs>
          <GapReportAutocompleteFilter
            label="Courses"
            options={courses}
            getOptionLabel={course => course.get("name")}
            value={filter
              .get(FILTERS.COURSES)
              .map(courseId => courses.get(courseId.toString()))}
            onChange={(_, value) =>
              setFilter(
                FILTERS.COURSES,
                List(value.map(course => course.get("courseId"))),
              )
            }
          />
        </Grid>
        {invokeIfFunction(renderStatusFilter)}
      </>
    );
  };

  render() {
    const {
      courses,
      getGapReport,
      clearGapReport,
      downloadBtnDisabled,
      downloadCourseGapReport,
    } = this.props;

    return (
      <GapReportFilterBase
        courses={courses} // force rerender when this changes
        getGapReport={getGapReport}
        initialFilter={initialFilter}
        clearGapReport={clearGapReport}
        renderFilter={this.renderFilter}
        downloadGapReport={downloadCourseGapReport}
        downloadBtnDisabled={downloadBtnDisabled}
      />
    );
  }
}
export default compose(
  connect(state => ({
    courses: getCoursesByCourseIdFromState(state),
  })),
)(CourseGapReportFilter);
