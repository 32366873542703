import { compose } from "redux";
import { List } from "immutable";
import { Field } from "redux-form/immutable";
import React, { PureComponent } from "react";
import { Grid, MenuItem } from "@material-ui/core";

import {
  RoleMapper,
  EmployeeMapper,
  isRoleActiveToString,
} from "app/app.mappers";

import { EMPTY_LIST } from "app/app.constants";
import roleService from "services/role.service";
import { required } from "utils/validation.util";
import TextField from "app/components/form/TextField";
import employeeService from "services/employee.service";
import { ROLE_FORM } from "features/competence.constants";
import AutoSuggestField from "app/components/form/AutoSuggestField";
import CountryMultiselectField from "app/components/form/CountryMultiselectField";

class RoleForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      roles: EMPTY_LIST,
      employees: EMPTY_LIST,
      countries: EMPTY_LIST,
    };
  }

  componentDidMount() {
    Promise.all([roleService.getRoles(), employeeService.getEmployees()]).then(
      ([roles, employees, countries]) =>
        this.setState({
          roles: List(roles).map(RoleMapper.from),
          employees: List(employees).map(EmployeeMapper.from),
        }),
    );
  }

  render() {
    const { currentRole, handleSubmit } = this.props;

    return (
      <form id={ROLE_FORM.ID} onSubmit={handleSubmit}>
        <Grid container direction="column">
          <Grid container item>
            <Field
              required
              label="Role Name"
              validate={[required]}
              component={TextField}
              name={ROLE_FORM.NAME}
            />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={8}>
              <Field
                select
                name={ROLE_FORM.PARENT_ID}
                component={AutoSuggestField}
                label="Inherit Competence from Role"
                options={this.state.roles.map(role => ({
                  label: role.get("name"),
                  value: role.get("roleId"),
                  disabled: currentRole
                    ? currentRole.get("roleId") === role.get("roleId")
                    : false,
                }))}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                select
                label="Status"
                component={TextField}
                name={ROLE_FORM.IS_ACTIVE}
              >
                {[true, false].map((value, key) => (
                  <MenuItem key={key} value={value}>
                    {isRoleActiveToString(value)}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
          <Grid container item>
            <Field
              multiline
              rows={5}
              label="Description"
              component={TextField}
              name={ROLE_FORM.DESCRIPTION}
            />
          </Grid>
          <Grid item container>
            <Field
              select
              required
              label="Role Owner"
              validate={[required]}
              name={ROLE_FORM.OWNER_ID}
              component={AutoSuggestField}
              TextFieldProps={{
                required: true,
              }}
              options={this.state.employees.map(employee => ({
                label: employee.get("displayName"),
                value: employee.get("employeeId"),
              }))}
            />
          </Grid>
          <Grid item container>
            <Field
              name={ROLE_FORM.COUNTRIES}
              component={CountryMultiselectField}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default compose()(RoleForm);
