import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { getCurrentEmployeeFromState } from "app/app.selectors";
import EmployeeCourseContainer from "features/employee/employeeCourse/EmployeeCourseContainer";

class MyPageEmployeeCourseContainer extends PureComponent {
  render() {
    const { location, currentEmployeeId, routes, courseId, Icon } = this.props;

    return (
      <EmployeeCourseContainer
        Icon={Icon}
        routes={routes}
        courseId={courseId}
        location={location}
        employeeId={currentEmployeeId}
      />
    );
  }
}

export default compose(
  connect(state => ({
    currentEmployeeId: getCurrentEmployeeFromState(state).get("employeeId"),
  })),
)(MyPageEmployeeCourseContainer);
