import { Map, OrderedMap } from "immutable";

import { ACTIONS, EMPTY_LIST } from "app/app.constants";

import { CourseExpiryMapper } from "app/app.mappers";

const initialState = Map({
  courses: EMPTY_LIST,
});

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_COURSE_EXPIRY_REPORT: {
      return initialState;
    }

    case ACTIONS.GET_COURSE_EXPIRY_REPORT: {
      if (error) return state;
      return state.set(
        "courses",
        OrderedMap(
          payload.map(course => [
            course.courseId.toString(),
            CourseExpiryMapper.from(course),
          ]),
        ),
      );
    }

    default:
      return state;
  }
};
