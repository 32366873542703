import { compose } from "redux";
import { connect } from "react-redux";
import React, { useCallback } from "react";

import {
  getGapReportDepartmentsFromState,
  getServiceGapReportServicesFromState,
  getServiceGapReportServiceCoursesByServiceFromState,
  getServiceGapReportServiceSkillsByServiceFromState,
} from "features/competence.selectors";

import { GAP_REPORT } from "app/app.constants";
import GapReport from "features/components/GapReport/GapReport";
import ServiceGapReportHeader from "features/service/components/ServiceGapReportHeader";
import ServiceGapReportEmployeeRow from "features/service/components/ServiceGapReportEmployeeRow";

const ServiceGapReport = ({
  root,
  title,
  employeesByDepartment,
  departments,
  services,
  serviceCourses,
  serviceSkills,
}) => {
  const renderHeader = useCallback(
    () => (
      <ServiceGapReportHeader
        services={services}
        serviceCourses={serviceCourses}
        serviceSkills={serviceSkills}
      />
    ),
    [services, serviceSkills, serviceCourses],
  );

  const renderEmployee = useCallback(
    ({ employee, index }) => (
      <ServiceGapReportEmployeeRow
        root={root}
        index={index}
        services={services}
        serviceCourses={serviceCourses}
        serviceSkills={serviceSkills}
        key={employee.get("employeeId")}
        employeeId={employee.get("employeeId")}
      />
    ),
    [root, services, serviceCourses, serviceSkills],
  );

  return (
    <GapReport
      root={root}
      title={title}
      renderHeader={renderHeader}
      departments={departments}
      employeesByDepartment={employeesByDepartment}
      renderEmployee={({ employee, index }) =>
        renderEmployee({ employee, index })
      }
      gapReportType={GAP_REPORT.SERVICE_GAP_REPORT}
    />
  );
};

export default compose(
  connect(state => ({
    departments: getGapReportDepartmentsFromState(state),
    services: getServiceGapReportServicesFromState(state),
    serviceCourses: getServiceGapReportServiceCoursesByServiceFromState(state),
    serviceSkills: getServiceGapReportServiceSkillsByServiceFromState(state),
  })),
)(ServiceGapReport);
