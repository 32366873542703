import React, { PureComponent } from "react";
import moment from "moment";
import isNil from "lodash/isNil";

import { TextField as MuiTextField, MenuItem } from "@material-ui/core";

export default class TextField extends PureComponent {
  render() {
    const {
      input,
      meta,
      margin,
      select,
      variant,
      children,
      formatDate,
      defaultValue,
      noResultsMessage,
      ...rest
    } = this.props;
    const { onChange, value } = input;

    const invalidInput = meta && meta.dirty && meta.invalid;

    return (
      <MuiTextField
        select={select}
        margin={margin}
        variant={variant}
        value={
          moment.isMoment(value)
            ? formatDate(value)
            : isNil(value)
            ? defaultValue
            : value
        }
        onChange={input ? onChange : undefined}
        error={invalidInput}
        helperText={meta.error && meta.error !== true ? meta.error : ""}
        {...rest}
      >
        {children}
        {select && !React.Children.count(children) && (
          <MenuItem disabled>{noResultsMessage}</MenuItem>
        )}
      </MuiTextField>
    );
  }
}

TextField.defaultProps = {
  fullWidth: true,
  margin: "normal",
  noResultsMessage: "Empty",
};
