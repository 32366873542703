import React from "react";
import { IconButton, Grid } from "@material-ui/core";
import withStyles from "@material-ui/styles/withStyles";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";

import { TableRow } from "altus-ui-components";

const TableRowSubTable = ({
  item,
  theme,
  classes,
  TableRowComponent = TableRow,
  onRowClick,
  row,
  ...rest
}) => {
  const offsetPxModified = theme.spacing(3);
  const showExpander = row.depth === 0 && row.canExpand;

  return (
    <>
      {showExpander ? (
        <Grid className={classes.anchor}>
          <IconButton
            size="small"
            fontSize="small"
            {...row.getToggleRowExpandedProps()}
            classes={{ root: classes.expander }}
          >
            {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Grid>
      ) : (
        ""
      )}

      <TableRowComponent
        striped={false}
        {...rest}
        classes={{
          root: row.depth === 0 ? classes.mainTable : classes.childTable,
        }}
        style={{
          paddingLeft: offsetPxModified,
        }}
        item={item}
        row={row}
        onClick={onRowClick ? () => onRowClick(item) : onRowClick}
      />
    </>
  );
};
const styles = theme => ({
  expander: {
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(0.5),
  },
  childTable: {
    backgroundColor: theme.altus.components.SortableListRow.stripedColor,
  },
  mainTable: {
    backgroundColor: theme.altus.components.SortableListRow.background,
  },
  anchor: {
    position: "relative",
    width: 0,
    height: 0,
  },
});

export default withStyles(styles, { withTheme: true })(TableRowSubTable);
