import { compose } from "redux";
import isString from "lodash/isString";
import { NavLink } from "react-router-dom";
import { generatePath } from "react-router";
import React, { PureComponent, createElement } from "react";
import { Grid, withStyles, Typography, ButtonBase } from "@material-ui/core";

class ApplicationSidebarMenuItem extends PureComponent {
  isMenuItemActive = match => {
    const { depth, maxDepth } = this.props;

    return match ? (match.isExact ? true : depth === maxDepth - 1) : false;
  };

  render() {
    const { match, route, classes } = this.props;
    const { breadcrumb, Icon, path } = route;

    const to = match ? generatePath(path, match.params) : path;

    return (
      <NavLink
        to={to}
        isActive={this.isMenuItemActive}
        activeClassName={classes.activeNavLink}
        title={isString(breadcrumb) ? breadcrumb : undefined}
        onClick={() =>
          to === window.location.pathname ? window.location.reload() : undefined
        }
      >
        <Grid
          container
          wrap="nowrap"
          component={ButtonBase}
          className={classes.navLinkBtn}
        >
          <Grid
            item
            color="secondary"
            component={Icon}
            className={classes.navLinkIcon}
          />
          <Grid
            xs
            item
            noWrap
            align="left"
            variant="body2"
            color="secondary"
            component={Typography}
            className={classes.navLinkText}
          >
            {isString(breadcrumb) && breadcrumb}
            {!isString(breadcrumb) && createElement(breadcrumb, { match })}
          </Grid>
        </Grid>
      </NavLink>
    );
  }
}
const styles = theme => ({
  activeNavLink: {
    "& $navLinkBtn": {
      background: theme.sidebar.hover,
    },
    "& $navLinkText": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  navLinkBtn: {
    padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    paddingLeft: ({ depth }) => depth * theme.spacing(4) + theme.spacing(1),
    "&:hover": {
      background: theme.sidebar.hover,
    },
  },
  navLinkIcon: {
    marginRight: theme.spacing(1),
  },
  navLinkText: {
    fontWeight: theme.typography.fontWeightRegular,
  },
});

export default compose(withStyles(styles))(ApplicationSidebarMenuItem);
