import { Map } from "immutable";
import { compose } from "redux";
import React, { useState, useMemo, useCallback } from "react";
import { Grid, withStyles } from "@material-ui/core";

import { Table } from "altus-ui-components";

import { buildItemTree } from "utils/app.util";
import { EMPTY_LIST, EMPTY_MAP } from "app/app.constants";
import ExpandableTableRow from "app/components/Table/ExpandableTableRow";
import ExpandableTableCell from "app/components/Table/ExpandableTableCell";
import ExpandableTableHeaderRow from "app/components/Table/ExpandableTableHeaderRow";
import SortableListActionsHeader from "app/components/SortableList/SortableListActionsHeader";

const getSubRows = row => {
  if (row.original) return;

  return row.get("children", EMPTY_LIST).valueSeq();
};

const createDefaultFilter = columns =>
  Map(
    columns.map(({ filter }, index) => [
      index.toString(),
      filter && filter.defaultValue,
    ]),
  ).filter(Boolean);

const itemMatchesFilters = (item, filter, columns) => {
  return filter.size
    ? filter.every((value, columnNo) => {
        const { getSortProperty } = columns[columnNo];
        const itemValue = getSortProperty ? getSortProperty(item) : undefined;

        return value === itemValue;
      })
    : true;
};

const filterItemsKeepingParents = (items, getKey, matchPredicate) => {
  return items
    .sortBy(item => -item.get("treeMin"))
    .reduce((matchingItems, item) => {
      const isParentOfMatchingItem = matchingItems
        .map(matchingItem => matchingItem.get("parentId").toString())
        .includes(getKey(item).toString());

      return isParentOfMatchingItem || matchPredicate(item)
        ? matchingItems.push(item)
        : matchingItems;
    }, EMPTY_LIST);
};

const itemMatchesSearch = (item, search) =>
  item
    .get("name")
    .toUpperCase()
    .includes(search.toUpperCase());

const getInitStateForLevel = (items, level) => {
  return items
    .toArray()
    .reduce((fieldsExpanded, _currentField, currentFieldIndex) => {
      const currentLevel = `${level}${
        level.length > 0 ? "." : ""
      }${currentFieldIndex}`;

      return {
        ...fieldsExpanded,
        ...(_currentField.has("children") &&
        _currentField.get("children").size > 0
          ? getInitStateForLevel(_currentField.get("children"), currentLevel)
          : undefined),
        [currentLevel]: true,
      };
    }, {});
};

const TableWithFilter = ({
  items,
  getKey,
  classes,
  columns,
  rowIcon,
  listActions,
  gridRowClick,
}) => {
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(createDefaultFilter(columns));

  const clearFilter = useCallback(() => setFilter(EMPTY_MAP), []);

  const filteredItems = useMemo(
    () =>
      items
        .update(items =>
          filterItemsKeepingParents(
            items,
            getKey,
            item =>
              itemMatchesSearch(item, search) &&
              itemMatchesFilters(item, filter, columns),
          ),
        )
        .map((item, index) => item.set("index", index)), // Index is used later to set the striped rows.
    [columns, filter, getKey, items, search],
  );

  const itemTree = useMemo(() => buildItemTree(filteredItems, getKey), [
    filteredItems,
    getKey,
  ]);

  const initialState = useMemo(
    () => ({
      pageSize: 50,
      expanded: getInitStateForLevel(itemTree, ""),
    }),
    [itemTree],
  );

  return (
    <>
      <Grid className={classes.sticky} container>
        <SortableListActionsHeader
          items={items}
          striped={false}
          filter={filter}
          columns={columns}
          setSearch={setSearch}
          clearFilter={clearFilter}
          listActions={listActions}
          classes={{ root: classes.placeholder }}
        />
      </Grid>
      <Table
        useExpanded
        usePagination
        subrowOffset={3}
        columns={columns}
        rowIcon={rowIcon}
        TableRowComponent={ExpandableTableRow}
        TableCellComponent={ExpandableTableCell}
        TableHeaderRowComponent={ExpandableTableHeaderRow}
        items={itemTree}
        useGlobalFilter={false}
        expandSubRows
        initialState={initialState}
        onRowClick={gridRowClick}
        noItemsMessage="No roles..."
        getSubRows={getSubRows}
      />
    </>
  );
};

const styles = theme => ({
  placeholder: {
    backgroundColor: theme.palette.background.default,
    border: "none",
  },
  sticky: {
    top: 0,
    zIndex: 2,
    position: "sticky",
  },
});

export default compose(withStyles(styles))(TableWithFilter);
