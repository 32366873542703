import { OrderedMap } from "immutable";
import { ACTIONS } from "app/app.constants";
import { FacilityMapper } from "app/app.mappers";

const initialState = OrderedMap();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_FACILITIES: {
      if (error) return state;

      return OrderedMap(
        payload.map(location => [
          location.facilityId.toString(),
          FacilityMapper.from(location),
        ]),
      );
    }

    default:
      return state;
  }
};
