import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import { EMPLOYEE_COURSE_FORM } from "features/competence.constants";
import { COMPETENCY_STATUS } from "app/app.constants";
import { isImmutable } from "immutable";

export const required = value =>
  isString(value)
    ? isEmpty(value)
    : isImmutable(value)
    ? value.isEmpty()
    : isNil(value);

export const notEmpty = value => (isNil(value) ? true : !value.size);

export const greaterThanOrEqualTo = min => value => {
  return Number.isInteger(+value)
    ? value && value >= min
      ? undefined
      : `Please enter a number more than or equal ${min}.`
    : "Number must be integer.";
};

export const validateEmployeeCourseForm = employeeCourse => {
  const errors = {};

  // This will set it to invalid by default, until it loads the employeeCourse.
  if (!employeeCourse.size) {
    errors[EMPLOYEE_COURSE_FORM.COMPETENCY_STATUS] = true;
  }

  if (employeeCourse.get(EMPLOYEE_COURSE_FORM.IS_ACTIVE)) {
    errors[EMPLOYEE_COURSE_FORM.IS_ACTIVE] =
      "You can't add a Certificate to an active Course.";
  }

  if (
    employeeCourse.get(EMPLOYEE_COURSE_FORM.COMPETENCY_STATUS) ===
    COMPETENCY_STATUS.REQUIRES_APPROVAL
  ) {
    errors[EMPLOYEE_COURSE_FORM.COMPETENCY_STATUS] =
      "HR needs to approve the latest reqistered Course Attendance.";
  }

  if (employeeCourse.get(EMPLOYEE_COURSE_FORM.IS_OVERRIDDEN)) {
    if (employeeCourse.get(EMPLOYEE_COURSE_FORM.OVERRIDE_REASON)?.length < 3)
      errors[EMPLOYEE_COURSE_FORM.OVERRIDE_REASON] =
        "Override reason should be minimum 3 characters and a maximum of 200 characters long.";
  }

  return errors;
};
