import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import React, { useEffect, useMemo, useCallback, useState } from "react";
import { IconButton, Tooltip, Grid, Box, Typography } from "@material-ui/core";

import { useModal } from "altus-modal";
import { LoadingDataState } from "altus-datastate";

import {
  MultiSelectModal,
  BasePage,
  TableRowActionsCell,
} from "altus-ui-components";

import {
  getRoles,
  getEmployeeRoles,
  getStatsForEmployeeRoles,
  createEmployeeRoleOnSubmit,
  getAvailableRolesForEmployee,
  deleteEmployeeRoleAskConfirmation,
} from "features/competence.actions";

import {
  getCurrentUserFromState,
  getActionDataStateFromState,
} from "app/app.selectors";

import {
  getEmployeeRolesFromState,
  getAvailableEmployeeRolesFromState,
  getRoleStatsByEmployeeAndRoleIdFromState,
} from "features/competence.selectors";

import ListTreeViewToggleButtons, {
  LIST_VIEW,
  TREE_VIEW,
} from "features/components/ListTreeViewToggleButtons";

import { isAdminOrHR } from "utils/app.util";
import { ICONS, ACTIONS, EMPTY_LIST } from "app/app.constants";
import TableWithFilter from "app/components/Table/TableWithFilter";
import SortableList from "app/components/SortableList/SortableList";
import { ADD_EMPLOYEE_ROLE_MODAL_ID } from "features/competence.constants";
import EmployeeRoleProgressBar from "features/components/EmployeeRoleProgressBar";

const getEmployeeRoleKey = employeeRole => employeeRole.get("roleEmployeeId");
const getSkillName = skill => skill.get("name");
const getKey = item => item.get("roleId");

const EmployeeRolesBasePage = ({
  path,
  Icon,
  title,
  classes,
  employeeId,
  currentUser,
  availableRoles = EMPTY_LIST,
  hideViewsToggle = false,
  dispatchGetEmployeeRoles,
  employeeRoles = EMPTY_LIST,
  getAvailableRollsDataState,
  dispatchDeleteEmployeeRole,
  dataState = LoadingDataState,
  dispatchGetEmployeeRoleStats,
  roleStatsByEmployeeAndRoleId,
  dispatchGetAvailableEmployeeRoles,
  dispatchCreateEmployeeRoleOnSubmit,
}) => {
  const [selectedView, setSelectedView] = useState(LIST_VIEW);
  const [isOpen, toggleModal] = useModal(ADD_EMPLOYEE_ROLE_MODAL_ID);

  useEffect(() => {
    dispatchGetEmployeeRoles(employeeId).then(
      dispatchGetEmployeeRoleStats(employeeId),
    );
  }, [employeeId, dispatchGetEmployeeRoles, dispatchGetEmployeeRoleStats]);

  useEffect(() => {
    if (isOpen) {
      dispatchGetAvailableEmployeeRoles(employeeId);
    }
  }, [employeeId, isOpen, dispatchGetAvailableEmployeeRoles]);

  const deleteClick = useCallback(
    (event, row) => {
      event.preventDefault();
      event.stopPropagation();

      dispatchDeleteEmployeeRole(row.original);
    },
    [dispatchDeleteEmployeeRole],
  );

  const listColumns = useMemo(
    () => [
      {
        xs: true,
        title: "Role Name",
        getSortProperty: item => item.getIn(["role", "name"]),
      },
      {
        xs: true,
        title: "Prerequisite Role",
        listOnlyColumn: true,
        getSortProperty: item => item.getIn(["role", "parentName"]),
      },
      {
        xs: 2,
        title: "Status",
        getSortProperty: item => item.getIn(["role", "status"]),
      },
      {
        xs: 1,
        container: true,
        justify: "center",
        title: (
          <Tooltip title="Remaining Skill Count">
            <ICONS.SKILL />
          </Tooltip>
        ),
        getSortProperty: item => {
          const roleStats = roleStatsByEmployeeAndRoleId.getIn([
            employeeId.toString(),
            item.get("roleId").toString(),
          ]);

          return roleStats
            ? roleStats.get("requiredSkillsCount") -
                roleStats.get("activeEmployeeSkillCount")
            : "-";
        },
      },
      {
        xs: 1,
        container: true,
        justify: "center",
        title: (
          <Tooltip title="Remaining Course Count">
            <ICONS.COURSE />
          </Tooltip>
        ),
        getSortProperty: item => {
          const roleStats = roleStatsByEmployeeAndRoleId.getIn([
            employeeId.toString(),
            item.get("roleId").toString(),
          ]);

          return roleStats
            ? roleStats.get("requiredCoursesCount") -
                roleStats.get("activeEmployeeCourseCount")
            : "-";
        },
      },
      {
        xs: 2,
        component: Grid,
        getValue: item => (
          <EmployeeRoleProgressBar
            roleId={item.get("roleId")}
            employeeId={employeeId}
          />
        ),
      },
    ],
    [employeeId, roleStatsByEmployeeAndRoleId],
  );

  const treeColumns = useMemo(
    () => [
      {
        xs: 6,
        id: "name",
        Header: "Role Name",
        Cell: ({ row }) => {
          return (
            <Grid container alignItems="center">
              <ICONS.ROLE />
              <Typography variant="body2" component={Box} paddingLeft={1}>
                {row.original.getIn(["role", "name"])}
              </Typography>
            </Grid>
          );
        },
      },
      {
        xs: 2,
        id: "status",
        Header: "Status",
        accessor: item => item.getIn(["role", "status"]),
      },
      {
        xs: 1,
        id: "skillCount",
        justify: "center",
        Header: (
          <Tooltip title="Remaining Skill Count">
            <ICONS.SKILL />
          </Tooltip>
        ),
        Cell: ({ row }) => {
          const roleStats = roleStatsByEmployeeAndRoleId.getIn([
            employeeId.toString(),
            row.original.get("roleId").toString(),
          ]);

          return roleStats
            ? roleStats.get("requiredSkillsCount") -
                roleStats.get("activeEmployeeSkillCount")
            : "-";
        },
      },
      {
        xs: 1,
        id: "courseCount",
        justify: "center",
        Header: (
          <Tooltip title="Remaining Course Count">
            <ICONS.COURSE />
          </Tooltip>
        ),
        Cell: ({ row }) => {
          const roleStats = roleStatsByEmployeeAndRoleId.getIn([
            employeeId.toString(),
            row.original.get("roleId").toString(),
          ]);

          return roleStats
            ? roleStats.get("requiredCoursesCount") -
                roleStats.get("activeEmployeeCourseCount")
            : "-";
        },
      },
      {
        xs: 2,
        id: "progress",
        Cell: ({ row }) => {
          return (
            <EmployeeRoleProgressBar
              roleId={row.original.get("roleId")}
              employeeId={employeeId}
            />
          );
        },
      },
      isAdminOrHR(currentUser)
        ? {
            id: "delete",
            Header: <TableRowActionsCell minItems={1} />,
            Cell: ({ row }) => {
              return (
                <Tooltip title="Remove">
                  <TableRowActionsCell>
                    <IconButton onClick={event => deleteClick(event, row)}>
                      <ICONS.DELETE fontSize="small" />
                    </IconButton>
                  </TableRowActionsCell>
                </Tooltip>
              );
            },
          }
        : undefined,
    ],
    [employeeId, roleStatsByEmployeeAndRoleId, currentUser, deleteClick],
  );

  const actions = useMemo(
    () => [
      {
        getValue: item => (
          <Tooltip title="Remove">
            <IconButton onClick={() => dispatchDeleteEmployeeRole(item)}>
              <ICONS.DELETE fontSize="small" />
            </IconButton>
          </Tooltip>
        ),
      },
    ],
    [dispatchDeleteEmployeeRole],
  );

  const addRolesSubmit = useCallback(
    selectedRoles => {
      dispatchCreateEmployeeRoleOnSubmit(
        employeeId,
        selectedRoles.map(role => role.get("roleId")).toArray(),
      )
        .then(dispatchGetEmployeeRoleStats(employeeId))
        .then(toggleModal);
    },
    [
      employeeId,
      toggleModal,
      dispatchGetEmployeeRoleStats,
      dispatchCreateEmployeeRoleOnSubmit,
    ],
  );

  const listActions = [
    {
      renderAction: () =>
        !hideViewsToggle && (
          <ListTreeViewToggleButtons
            value={selectedView}
            onChange={onSelectedViewChange}
          />
        ),
      getTitle: () => "",
    },
  ];

  const onSelectedViewChange = (event, selectedView) =>
    selectedView && setSelectedView(selectedView);

  return (
    <BasePage
      title={title}
      Icon={Icon}
      dataState={dataState}
      displayAddButton={isAdminOrHR(currentUser)}
      displaySearchField
      addButtonOnClick={toggleModal}
    >
      {selectedView === LIST_VIEW && (
        <SortableList
          Icon={ICONS.ROLE}
          items={employeeRoles}
          actions={isAdminOrHR(currentUser) ? actions : []}
          listActions={listActions}
          dataState={dataState}
          columns={listColumns}
          defaultSortColumn={1}
          displayNumberOfItems={true}
          getKey={getEmployeeRoleKey}
          createSortableListRowProps={item => ({
            component: Link,
            to: `${generatePath(path, { employeeId })}/${item.get("roleId")}`,
          })}
        />
      )}
      {selectedView === TREE_VIEW && (
        <TableWithFilter
          getKey={getKey}
          items={employeeRoles.map(item =>
            item.set(
              "path",
              `${generatePath(path, { employeeId })}/${item.get("roleId")}`,
            ),
          )}
          subrowOffset={3}
          expandSubRows={true}
          actions={isAdminOrHR(currentUser) ? actions : []}
          columns={treeColumns}
          useGlobalFilter={false}
          listActions={listActions}
          rowIcon={ICONS.ROLE}
        />
      )}
      <MultiSelectModal
        open={isOpen && !getAvailableRollsDataState.isLoading()}
        displayChips
        onClose={toggleModal}
        getName={getSkillName}
        items={availableRoles}
        title="Add Roles"
        onSubmit={addRolesSubmit}
      />
    </BasePage>
  );
};

export default compose(
  connect(
    (initialState, { employeeId }) => {
      const employeeRolesSelector = getEmployeeRolesFromState(employeeId);

      return state => ({
        currentUser: getCurrentUserFromState(state),
        employeeRoles: employeeRolesSelector(state),
        availableRoles: getAvailableEmployeeRolesFromState(state, employeeId),
        roleStatsByEmployeeAndRoleId: getRoleStatsByEmployeeAndRoleIdFromState(
          state,
        ),
        getAvailableRollsDataState: getActionDataStateFromState(
          state,
          ACTIONS.GET_AVAILABLE_ROLES_FOR_EMPLOYEE,
        ),
        dataState: getActionDataStateFromState(
          state,
          ACTIONS.GET_EMPLOYEE_ROLES,
          ACTIONS.GET_AVAILABLE_ROLES_FOR_EMPLOYEE,
        ),
      });
    },
    {
      dispatchGetRoles: getRoles,
      dispatchGetEmployeeRoles: getEmployeeRoles,
      dispatchGetEmployeeRoleStats: getStatsForEmployeeRoles,
      dispatchDeleteEmployeeRole: deleteEmployeeRoleAskConfirmation,
      dispatchCreateEmployeeRoleOnSubmit: createEmployeeRoleOnSubmit,
      dispatchGetAvailableEmployeeRoles: getAvailableRolesForEmployee,
    },
  ),
)(EmployeeRolesBasePage);
