import { compose } from "redux";
import { connect } from "react-redux";
import { PureComponent } from "react";
import { getRoleFromState } from "features/competence.selectors";

class RoleBreadcrumb extends PureComponent {
  render() {
    const { role } = this.props;

    return role ? role.get("name") : "...";
  }
}

export default compose(
  connect((state, { match }) => ({
    role: getRoleFromState(state, match.params.roleId),
  })),
)(RoleBreadcrumb);
