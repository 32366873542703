import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { ICONS } from "app/app.constants";
import { SpecificationMapper } from "app/app.mappers";
import { getSpecificationFromState } from "features/competence.selectors";
import EntityDetailsHeader from "app/components/EntityDetailsHeader";

const SpecificationDetailsHeader = ({ children, specification }) => {
  return (
    <EntityDetailsHeader
      Icon={<ICONS.SPECIFICATION />}
      title={specification.get("name")}
      subtitle={specification.get("description")}
    >
      {children}
    </EntityDetailsHeader>
  );
};

SpecificationDetailsHeader.defaultProps = {
  specification: SpecificationMapper.initial,
};

export default compose(
  connect((state, { specificationId }) => ({
    specification: getSpecificationFromState(state, specificationId),
  })),
)(SpecificationDetailsHeader);
