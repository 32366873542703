import { compose } from "redux";
import classNames from "classnames";
import isEqual from "lodash/isEqual";
import React, { useCallback } from "react";
import { Grid, withStyles } from "@material-ui/core";

import { Autocomplete } from "altus-ui-components";

import { invokeIfFunction } from "utils/app.util";

const groupCoursesAndSkills = option =>
  option.get("courseId") ? "Courses" : option.get("skillId") ? "Skills" : null;

const getOptionSelected = () => (option, selectedOption) =>
  isEqual(option, selectedOption);

const CollectionConditionMatrixCell = ({
  classes,
  disabled,
  onChange,
  rowIndex,
  columnIndex,
  AutocompleteProps,
  getOptionHighlighted,
  availableCollectionItems,
  collectionItemsByRowAndColumn,
}) => {
  const value = collectionItemsByRowAndColumn.getIn(
    [rowIndex, columnIndex],
    null,
  );

  const getOptionLabel = useCallback(
    option => {
      const defaultLabel = option.get("name");
      const isOptionHighlighted = !!invokeIfFunction(
        getOptionHighlighted,
        option,
      );
      return isOptionHighlighted ? `${defaultLabel} (this)` : defaultLabel;
    },
    [getOptionHighlighted],
  );

  return (
    <Grid
      xs
      item
      key={columnIndex}
      className={classNames(classes.cell, classes.autocompleteCell)}
    >
      <Autocomplete
        blurOnSelect
        value={value}
        disabled={disabled}
        disableCloseOnSelect={false}
        options={availableCollectionItems}
        getOptionLabel={getOptionLabel}
        groupBy={groupCoursesAndSkills}
        getOptionSelected={getOptionSelected}
        onChange={(_, value) => onChange(rowIndex, columnIndex, value)}
        classes={{
          input: classes.autocompleteInput,
          inputRoot: classNames(classes.autocompleteInputRoot, {
            [classes.highlightedOption]: !!invokeIfFunction(
              getOptionHighlighted,
              value,
            ),
          }),
        }}
        TextFieldProps={{
          margin: "none",
        }}
        {...AutocompleteProps}
      />
    </Grid>
  );
};

const styles = theme => ({
  cell: {
    overflow: "hidden",
    height: theme.altus.largeRow.height,
    background: theme.palette.common.white,
    borderLeft: theme.gapReport.defaultBorder,
    borderBottom: theme.gapReport.defaultBorder,
    "&:last-child": {
      borderRight: theme.gapReport.defaultBorder,
    },
  },
  autocompleteCell: {
    "& .MuiInput-underline:before": {
      borderBottom: theme.gapReport.defaultBorder,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: `2px solid ${theme.palette.grey[500]}`,
    },
  },
  autocompleteInputRoot: {
    paddingLeft: theme.spacing(1),
    height: theme.altus.largeRow.height,
    "& .Mui-disabled": {
      color: theme.palette.text.primary,
    },
  },
  autocompleteInput: {
    boxSizing: "border-box",
    height: theme.altus.largeRow.height,
  },
  highlightedOption: {
    "& .Mui-disabled": {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
});

export default compose(withStyles(styles))(CollectionConditionMatrixCell);
