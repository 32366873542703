import { Map, OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { ServiceSkillMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case ACTIONS.GET_SERVICE_SKILLS_BY_SERVICE_WITH_PARENTS: {
      if (error) return state;

      return OrderedMap(
        payload.map(serviceSkill => [
          serviceSkill.serviceSkillId.toString(),
          ServiceSkillMapper.from(serviceSkill),
        ]),
      ).groupBy(serviceSkill => serviceSkill.get("serviceId").toString());
    }

    case ACTIONS.CREATE_SERVICE_SKILLS: {
      if (error) return state;

      return state.set(
        action.serviceId.toString(),
        OrderedMap(
          payload.map(serviceSkill => [
            serviceSkill.serviceSkillId.toString(),
            ServiceSkillMapper.from(serviceSkill),
          ]),
        ),
      );
    }

    case ACTIONS.UPDATE_SERVICE_SKILL: {
      if (error) return state;

      return state.setIn(
        [payload.serviceId.toString(), payload.serviceSkillId.toString()],
        ServiceSkillMapper.from(payload),
      );
    }
    case ACTIONS.DELETE_SERVICE_SKILL: {
      if (error) return state;

      return state.deleteIn([
        action.serviceId.toString(),
        action.serviceSkillId.toString(),
      ]);
    }
    case ACTIONS.CLEAR_SERVICE_SKILLS: {
      return initialState;
    }
    default:
      return state;
  }
};
