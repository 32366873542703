import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useMemo, useState } from "react";
import { Grid, IconButton, Tooltip, Box } from "@material-ui/core";

import { TableRowActionsCell } from "altus-ui-components";

import {
  getEmployeeEducationItems,
  previewOrSaveEducationFile,
  editEmployeeEducationOnSubmit,
  initializeEditEmployeeEducation,
  deleteEmployeeEducationItemAskConfirmation,
  deleteEmployeeEducationItemsAskConfirmation,
  getEducationCategories,
  deleteEmployeeEducationFileAskConfirmation,
} from "features/competence.actions";

import {
  ADD_EMPLOYEE_EDUCATION_MODAL_ID,
  EDUCATION_FORM,
} from "features/competence.constants";

import {
  getCurrentUserFromState,
  getSummarizedDataStatesFromState,
} from "app/app.selectors";

import { formatDate } from "utils/format.util";
import { createGuid, isAdminOrHR } from "utils/app.util";
import { ICONS, ACTIONS, Format } from "app/app.constants";
import withToggleModal from "app/components/withToggleModal";
import SortableTable from "app/components/Table/SortableTable";
import DownloadFileColumn from "app/components/DownloadFileColumn";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";
import { getEmployeeEducationFromState } from "features/competence.selectors";
import EmployeeEducationForm from "features/employee/education/components/EmployeeEducationForm";
import AddEmployeeEducationModal from "features/employee/education/components/AddEmployeeEducationModal";

const EmployeeEducationBasePage = ({
  classes,
  employeeId,
  currentUser,
  educationItems,
  dispatchToggleModal,
  dispatchGetEducationCategories,
  dispatchGetEmployeeEducationItems,
  dispatchPreviewOrSaveEducationFile,
  dispatchDeleteEmployeeEducationItem,
  dispatchEmployeeEducationOnNotAllowed,
  dispatchEditEmployeeEducationOnSubmit,
  dispatchInitializeEditEmployeeEducation,
  dispatchDeleteEmployeeEducationFileAskConfirmation,
}) => {
  useEffect(() => {
    dispatchGetEmployeeEducationItems(employeeId);
  }, [employeeId, dispatchGetEmployeeEducationItems]);

  const columns = useMemo(
    () => [
      {
        xl: true,
        id: "icon",
        Header: <TableRowActionsCell className={classes.root} minItems={2} />,
        Cell: ({ row }) => {
          if (row.depth !== 0) return <TableRowActionsCell minItems={1} />;

          return (
            <TableRowActionsCell>
              <ICONS.EDUCATION color="primary" />
            </TableRowActionsCell>
          );
        },
      },
      {
        id: "title",
        xs: 3,
        title: "Title",
        Header: "Title",
        getSortProperty: item => item.get("title"),
        Cell: ({ row }) => {
          if (row.depth === 0) return row.original.get("title");

          return (
            <DownloadFileColumn
              file={row.original}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();

                dispatchPreviewOrSaveEducationFile(
                  employeeId,
                  row.original.educationId,
                  row.original,
                );
              }}
            />
          );
        },
      },
      {
        xs: 1,
        id: "from",
        title: "From",
        Header: "From",
        getSortProperty: item => formatDate(item.get("from"), Format.date),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return formatDate(row.original.get("from"), Format.date);
        },
      },
      {
        xs: 1,
        id: "to",
        title: "To",
        Header: "To",
        getSortProperty: item => formatDate(item.get("to"), Format.date),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return formatDate(row.original.get("to"), Format.date);
        },
      },
      {
        xs: 2,
        id: "category",
        title: "Category",
        Header: "Category",
        getSortProperty: item => item.get("parentCompetencyCategoryName"),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return row.original.get("parentCompetencyCategoryName");
        },
      },
      {
        xs: 2,
        id: "subCategory",
        title: "Sub-category",
        Header: "Sub-category",
        getSortProperty: item => item.get("competencyCategoryName"),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return row.original.get("competencyCategoryName");
        },
      },
      {
        xs: 2,
        title: "",
        id: "competencyStatus",
        Header: "Status",
        component: Grid,
        container: true,
        justify: "flex-end",
        getSortProperty: item => item.get("competencyStatus"),
        Cell: ({ row }) => {
          if (row.depth !== 0) return "";
          return (
            <CompetencyStatusChip
              status={row.original.get("competencyStatus")}
            />
          );
        },
        filter: {
          label: "Status",
          defaultText: "All",
          getFilterText: filter => statusToTooltipText(filter),
        },
      },
      {
        id: "actions",
        xs: true,
        justify: "flex-end",
        Header: <TableRowActionsCell minItems={1} />,
        Footer: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          var canDelete = true;

          if (row.depth === 0)
            canDelete =
              !row.original.get("isApproved") || isAdminOrHR(currentUser);

          return (
            <TableRowActionsCell minItems={1}>
              <Tooltip
                title={
                  row.depth === 0
                    ? !canDelete
                      ? "Only HR can delete approved education"
                      : "Delete employee education"
                    : "Delete employee education file"
                }
              >
                <Box>
                  <IconButton
                    disabled={!canDelete}
                    onClick={event => {
                      event.preventDefault();
                      event.stopPropagation();
                      row.depth === 0
                        ? dispatchDeleteEmployeeEducationItem(row.original)
                        : dispatchDeleteEmployeeEducationFileAskConfirmation({
                            employeeId,
                            educationId: row.original.educationId,
                            educationFileId: row.original.educationFileId,
                            name: row.original.name,
                          });
                    }}
                  >
                    <ICONS.DELETE fontSize="small" />
                  </IconButton>
                </Box>
              </Tooltip>
            </TableRowActionsCell>
          );
        },
      },
    ],
    [
      employeeId,
      currentUser,
      dispatchDeleteEmployeeEducationItem,
      dispatchPreviewOrSaveEducationFile,
      dispatchDeleteEmployeeEducationFileAskConfirmation,
      classes,
    ],
  );

  const toggleCreateModal = () =>
    dispatchToggleModal({
      modalId: ADD_EMPLOYEE_EDUCATION_MODAL_ID,
    });

  const [editModalId, setEditModalId] = useState(0);

  useState(() => {
    setEditModalId(createGuid());
  }, []);

  const toggleEditModal = () => dispatchToggleModal({ modalId: editModalId });

  return (
    <>
      <SortableTable
        stickyHeader
        disableSortBy
        displayAddButton
        columns={columns}
        title="Education"
        addButtonOnClick={toggleCreateModal}
        keyName="educationId"
        useGlobalFilter={false}
        subListPropertyName="files"
        subListKey="educationFileId"
        items={educationItems}
        noItemsMessage="No education added yet..."
        editModalId={editModalId}
        toggleEditModal={toggleEditModal}
        onRowClick={item => {
          item.get("isApproved") && !isAdminOrHR(currentUser)
            ? dispatchEmployeeEducationOnNotAllowed()
            : dispatchInitializeEditEmployeeEducation(item, toggleEditModal);
        }}
        EditEntityFormComponent={EmployeeEducationForm}
        EditModalProps={{
          submitText: "Save",
          title: "Edit Education",
          formTitleField: EDUCATION_FORM.TITLE,
          onEnter: dispatchGetEducationCategories,
          competencyStatusField: EDUCATION_FORM.COMPETENCY_STATUS,
          onSubmit: dispatchEditEmployeeEducationOnSubmit(employeeId),
        }}
        editEntityForm={{
          form: EDUCATION_FORM.ID,
        }}
        EditEntityFormProps={{
          employeeId,
        }}
      />
      <AddEmployeeEducationModal employeeId={employeeId} />
    </>
  );
};

const emloyeeEducationOnNotAllowed = () => dispatch =>
  dispatch({
    type: ACTIONS.CONFIRMATION_DIALOG_PENDING,
    confirmationDialog: {
      title: "Edit or Delete Education",
      description: "You cannot edit or delete approved Education items.",
      confirmButtonText: "Close",
      noCancel: true,
    },
  });

const styles = theme => ({
  root: {
    paddingRight: ` ${theme.spacing(4)}px`,
  },
});

export default compose(
  connect(
    state => ({
      currentUser: getCurrentUserFromState(state),
      educationItems: getEmployeeEducationFromState(state),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_EMPLOYEE_EDUCATION_ITEMS,
        ACTIONS.DELETE_EMPLOYEE_EDUCATION_ITEM,
      ),
    }),
    {
      dispatchGetEmployeeEducationItems: getEmployeeEducationItems,
      dispatchPreviewOrSaveEducationFile: previewOrSaveEducationFile,
      dispatchEditEmployeeEducationOnSubmit: editEmployeeEducationOnSubmit,
      dispatchInitializeEditEmployeeEducation: initializeEditEmployeeEducation,
      dispatchDeleteEmployeeEducationItems: deleteEmployeeEducationItemsAskConfirmation,
      dispatchDeleteEmployeeEducationItem: deleteEmployeeEducationItemAskConfirmation,
      dispatchDeleteEmployeeEducationFileAskConfirmation: deleteEmployeeEducationFileAskConfirmation,
      dispatchEmployeeEducationOnNotAllowed: emloyeeEducationOnNotAllowed,
      dispatchGetEducationCategories: getEducationCategories,
    },
  ),
  withStyles(styles),
  withToggleModal,
)(EmployeeEducationBasePage);
