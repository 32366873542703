import { Map, OrderedMap, List } from "immutable";

import { ACTIONS, EMPTY_MAP } from "app/app.constants";

import {
  EmployeeSkillMapper,
  SpecificationMapper,
  SpecificationSkillMapper,
  SpecificationCourseMapper,
} from "app/app.mappers";

import employeeCourseMappers from "app/mappers/employeeCourse.mappers";

const initialState = Map({
  employeeCourse: EMPTY_MAP,
  employeeSkill: EMPTY_MAP,
  specification: EMPTY_MAP,
  specificationCourse: EMPTY_MAP,
  specificationSkill: EMPTY_MAP,
  employeeSpecificationStatus: EMPTY_MAP,
});

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_SPECIFICATION_GAP_REPORT: {
      return initialState;
    }

    case ACTIONS.GET_SPECIFICATION_GAP_REPORT: {
      if (error) return state;

      return state
        .set(
          "employeeCourse",
          List(payload.employeeCourses)
            .map(employeeCourseMappers.EmployeeCourse.from)
            .groupBy(employeeCourse =>
              employeeCourse.get("employeeId").toString(),
            )
            .map(employeeCoursesByEmployeeId =>
              Map(
                employeeCoursesByEmployeeId.map(employeeCourse => [
                  employeeCourse.get("courseId").toString(),
                  employeeCourse,
                ]),
              ),
            ),
        )
        .set(
          "employeeSkill",
          List(payload.employeeSkills)
            .map(EmployeeSkillMapper.from)
            .groupBy(employeeSkill =>
              employeeSkill.get("employeeId").toString(),
            )
            .map(employeeSkillsByEmployeeId =>
              Map(
                employeeSkillsByEmployeeId.map(employeeSkill => [
                  employeeSkill.get("skillId").toString(),
                  employeeSkill,
                ]),
              ),
            ),
        )
        .set(
          "specification",
          OrderedMap(
            payload.specifications.map(specification => [
              specification.specificationId.toString(),
              SpecificationMapper.from(specification),
            ]),
          ),
        )
        .set(
          "specificationCourse",
          List(payload.specificationCourses)
            .map(SpecificationCourseMapper.from)
            .groupBy(specificationCourse =>
              specificationCourse.get("specificationId").toString(),
            ),
        )
        .set(
          "specificationSkill",
          List(payload.specificationSkills)
            .map(SpecificationSkillMapper.from)
            .groupBy(specificationSkill =>
              specificationSkill.get("specificationId").toString(),
            ),
        )
        .set(
          "employeeSpecificationStatus",
          List(payload.employeeSpecificationStatuses)
            .map(employeeSpecificationStatus =>
              Map(employeeSpecificationStatus),
            )
            .groupBy(employeeSpecificationStatus =>
              employeeSpecificationStatus.get("employeeId").toString(),
            )
            .map(employeeSpecificationStatusesByEmployeeId =>
              Map(
                employeeSpecificationStatusesByEmployeeId.map(
                  employeeSpecificationStatus => [
                    employeeSpecificationStatus
                      .get("specificationId")
                      .toString(),
                    employeeSpecificationStatus,
                  ],
                ),
              ),
            ),
        );
    }
    default:
      return state;
  }
};
