import React, { PureComponent } from "react";

import EmployeeRolesBasePage from "features/components/EmployeeRolesBasePage";

class EmployeeRolesContainer extends PureComponent {
  render() {
    const { employeeId, breadcrumb, path } = this.props;

    return (
      <EmployeeRolesBasePage
        path={path}
        title={breadcrumb}
        employeeId={employeeId}
      />
    );
  }
}

export default EmployeeRolesContainer;
