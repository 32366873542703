import { combineReducers } from "redux-immutable";
import { reducer as formReducer } from "redux-form/immutable";
import { connectRouter } from "connected-react-router/immutable";

import {
  confirmationReducer,
  notificationReducer,
} from "altus-redux-middlewares";

import { modalReducer } from "altus-modal";
import { dataStateReducer } from "altus-datastate";

import appReducer from "app/app.reducer";
import competenceReducer from "features/competence.reducer";

export default history =>
  combineReducers({
    app: appReducer,
    form: formReducer,
    modal: modalReducer,
    dataState: dataStateReducer,
    router: connectRouter(history),
    confirmation: confirmationReducer,
    notification: notificationReducer,
    competence: competenceReducer,
  });
