import { compose } from "redux";
import LazyLoad from "react-lazyload";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";

import { invokeIfFunction } from "utils/app.util";
import GapReportDepartment from "features/components/GapReport/GapReportDepartment";
import GapReportEmployeesHeader from "features/components/GapReport/GapReportEmployeesHeader";
import GapReportDepartmentTitle from "features/components/GapReport/GapReportDepartmentTitle";

class GapReport extends PureComponent {
  render() {
    const {
      root,
      title,
      theme,
      classes,
      renderHeader,
      gapReportType,
      renderEmployee,
      employeesByDepartment,
      departments,
    } = this.props;

    const { cellSize } = theme.gapReport;

    return (
      <Grid container wrap="nowrap" className={classes.root} direction="column">
        <Grid container wrap="nowrap" className={classes.headerRoot}>
          <Grid
            item
            title={title}
            gapReportType={gapReportType}
            component={GapReportEmployeesHeader}
          />
          {invokeIfFunction(renderHeader)}
        </Grid>
        <Grid item container direction="column">
          {departments.keySeq().map(departmentId => {
            const height =
              employeesByDepartment.get(departmentId).size * cellSize;

            return (
              <React.Fragment key={departmentId}>
                <GapReportDepartmentTitle departmentId={departmentId} />
                <LazyLoad
                  once
                  resize
                  height={height}
                  offset={-cellSize}
                  key={departmentId}
                  scrollContainer="#gap-report-scroll-container"
                >
                  <GapReportDepartment
                    root={root}
                    height={height}
                    key={departmentId}
                    departmentId={departmentId}
                    renderEmployee={renderEmployee}
                    employees={employeesByDepartment.get(departmentId)}
                  />
                </LazyLoad>
              </React.Fragment>
            );
          })}
        </Grid>
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    width: "auto",
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  headerRoot: {
    top: 0,
    zIndex: 5,
    position: "sticky",
  },
});

export default compose(withTheme, withStyles(styles))(GapReport);
