import { compose } from "redux";
import { withStyles } from "@material-ui/styles";
import { DatePicker } from "@material-ui/pickers";
import DateRange from "@material-ui/icons/DateRange";
import React, { useState, useCallback } from "react";
import { Grid, Typography, Tooltip, IconButton } from "@material-ui/core";

import { Format } from "app/app.constants";
import { invokeIfFunction } from "utils/app.util";

const DatePickerTextField = ({ value, inputRef, classes, onClick }) => {
  return (
    <>
      <Typography noWrap variant="body2">
        {value}
      </Typography>
      <Tooltip title="Edit date">
        <IconButton
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();

            invokeIfFunction(onClick);
          }}
          buttonRef={inputRef}
          className={classes.iconButton}
        >
          <DateRange fontSize="small"></DateRange>
        </IconButton>
      </Tooltip>
    </>
  );
};

const CustomDatePickerColumn = ({
  originalValue,
  classes,
  onAccept,
  setCustomDateValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState(originalValue);

  const onClose = useCallback(() => setIsOpen(false), []);
  const onOpen = useCallback(() => setIsOpen(true), []);

  return (
    <Grid item xs={true} className={classes.root}>
      <DatePicker
        autoOk
        open={isOpen}
        value={value}
        onOpen={onOpen}
        onChange={x => {
          setValue(x);
          setCustomDateValue(x);
        }}
        variant="inline"
        onClose={onClose}
        classes={classes}
        onAccept={onAccept}
        format={Format.date}
        TextFieldComponent={DatePickerTextField}
        PopoverProps={{
          onBackdropClick: event => {
            event.preventDefault();
            event.stopPropagation();

            onClose();
          },
        }}
      />
    </Grid>
  );
};

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  iconButton: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
  },
});

export default compose(React.memo, withStyles(styles))(CustomDatePickerColumn);
