import { compose } from "redux";
import { connect } from "react-redux";
import { Field } from "redux-form/immutable";
import React, { PureComponent, createRef } from "react";
import { Grid, MenuItem, Typography } from "@material-ui/core";

import {
  getCourseGroupsFromState,
  getAvailableCoursesForEmployee,
} from "features/competence.selectors";

import { saveFile } from "utils/app.util";
import { required, notEmpty } from "utils/validation.util";
import TextField from "app/components/form/TextField";
import CourseDetails from "features/components/CourseDetails";
import DatePickerField from "app/components/form/DatePickerField";
import AutoSuggestField from "app/components/form/AutoSuggestField";
import { EMPLOYEE_COURSE_FORM } from "features/competence.constants";
import FileUploadField from "app/components/form/FileUploadField/FileUploadField";
import EmployeeCourseFileUpload from "features/components/EmployeeCourseFileUpload";

class CreateEmployeeCourseAttendanceForm extends PureComponent {
  constructor(props) {
    super(props);

    this.clearEmployeeCourseRef = createRef();
  }
  render() {
    const {
      change,
      courses,
      employeeId,
      activeTab,
      handleSubmit,
      courseGroups,
      selectedCourseId,
      selectedCourseGroupId,
    } = this.props;

    const filteredCourses = courses.filter(course =>
      selectedCourseGroupId
        ? course.get("courseGroupId") === selectedCourseGroupId
        : true,
    );

    const selectedCourse = courses.find(
      course => course.get("courseId") === selectedCourseId,
    );

    return (
      <form onSubmit={handleSubmit} id={EMPLOYEE_COURSE_FORM.ID}>
        <Grid container spacing={3}>
          {activeTab === 1 && (
            <Grid container item direction="column">
              <Grid item>
                <Field
                  validate={[required, notEmpty]}
                  multiple={true}
                  downloadFile={saveFile}
                  component={FileUploadField}
                  name={EMPLOYEE_COURSE_FORM.FILES}
                  label="Upload certificate *"
                  FormControlProps={{
                    margin: "normal",
                  }}
                >
                  {({
                    value,
                    rootProps,
                    removeFile,
                    isDragActive,
                    openFileDialog,
                  }) => (
                    <EmployeeCourseFileUpload
                      value={value}
                      rootProps={rootProps}
                      employeeId={employeeId}
                      removeFile={removeFile}
                      courseId={selectedCourseId}
                      isDragActive={isDragActive}
                      openFileDialog={openFileDialog}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    inputVariant="standard"
                    validate={[required]}
                    component={DatePickerField}
                    name={EMPLOYEE_COURSE_FORM.START_DATE}
                    DatePickerProps={{
                      required: true,
                      margin: "normal",
                      label: "Start Date",
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    inputVariant="standard"
                    validate={[required]}
                    component={DatePickerField}
                    name={EMPLOYEE_COURSE_FORM.END_DATE}
                    DatePickerProps={{
                      required: true,
                      margin: "normal",
                      label: "End Date",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {activeTab === 0 && (
            <>
              <Grid container item xs={12} spacing={1}>
                <Grid item xs={3}>
                  <Field
                    select
                    autoFocus
                    label="Course group"
                    component={TextField}
                    name={EMPLOYEE_COURSE_FORM.COURSE_GROUP}
                    onChange={() => {
                      if (this.clearEmployeeCourseRef.current) {
                        this.clearEmployeeCourseRef.current.click();
                      }

                      change(EMPLOYEE_COURSE_FORM.COURSE_ID, null);
                    }}
                  >
                    <MenuItem value={""}>All</MenuItem>
                    {courseGroups.map(courseGroup => (
                      <MenuItem
                        key={courseGroup.get("courseGroupId")}
                        value={courseGroup.get("courseGroupId")}
                      >
                        {courseGroup.get("name")}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs>
                  <Field
                    required
                    validate={[required]}
                    placeholder="Search..."
                    label="Select Course"
                    component={AutoSuggestField}
                    clearBtnRef={this.clearEmployeeCourseRef}
                    name={EMPLOYEE_COURSE_FORM.COURSE_ID}
                    noResultsMessage="No courses available..."
                    TextFieldProps={{
                      required: true,
                    }}
                    options={filteredCourses.map(course => ({
                      label: `${course.get("name")} (${course.get(
                        "courseTypeName",
                      )})`,
                      value: course.get("courseId"),
                    }))}
                  />
                </Grid>
              </Grid>
              <Grid item container>
                <Grid container>
                  <Typography variant="h6">Course Details</Typography>
                </Grid>
                {!selectedCourseId && (
                  <Grid container>
                    <Grid item>
                      <Typography variant="caption">
                        No course selected
                      </Typography>
                    </Grid>
                  </Grid>
                )}
                <CourseDetails course={selectedCourse} />
              </Grid>
            </>
          )}
        </Grid>
      </form>
    );
  }
}

export default compose(
  connect((state, { employeeId, formValueSelector }) => ({
    courseGroups: getCourseGroupsFromState(state),
    courses: getAvailableCoursesForEmployee(state, employeeId),
    selectedCourseGroupId: formValueSelector(
      state,
      EMPLOYEE_COURSE_FORM.COURSE_GROUP,
    ),
  })),
)(CreateEmployeeCourseAttendanceForm);
