import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { BasePage } from "altus-ui-components";

import {
  clearRoles,
  initializeEditRole,
  createRoleOnSubmit,
  initializeCreateRole,
} from "features/competence.actions";

import ListTreeViewToggleButtons, {
  LIST_VIEW,
  TREE_VIEW,
} from "features/components/ListTreeViewToggleButtons";

import { ICONS } from "app/app.constants";
import TreeList from "app/components/TreeList/TreeList";
import SimpleFormModal from "app/components/SimpleFormModal";
import RoleForm from "features/hr/roles/components/RoleForm";
import { getRolesFromState } from "features/competence.selectors";
import SortableList from "app/components/SortableList/SortableList";
import { ROLE_FORM, CREATE_ROLE_MODAL_ID } from "features/competence.constants";

class RolesBasePage extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedView: LIST_VIEW,
    };
  }

  componentWillUnmount() {
    const { dispatchClearRoles } = this.props;
    dispatchClearRoles();
  }

  listActions = [
    {
      renderAction: () =>
        !this.props.hideViewsToggle && (
          <ListTreeViewToggleButtons
            value={this.state.selectedView}
            onChange={this.onSelectedViewChange}
          />
        ),
      getTitle: () => "",
    },
  ];

  onSelectedViewChange = (event, selectedView) =>
    selectedView && this.setState({ selectedView });

  render() {
    const {
      roles,
      title,
      actions,
      columns,
      dataState,
      displayAddButton,
      createSortableListRowProps,
      dispatchCreateRoleOnSubmit,
      dispatchInitializeCreateRole,
    } = this.props;

    const { selectedView } = this.state;

    return (
      <BasePage
        displayAddButton={displayAddButton}
        title={title}
        Icon={ICONS.ROLE}
        dataState={dataState}
        addButtonOnClick={dispatchInitializeCreateRole}
      >
        {selectedView === LIST_VIEW && (
          <SortableList
            Icon={ICONS.ROLE}
            items={roles}
            actions={actions}
            listActions={this.listActions}
            dataState={dataState}
            columns={columns}
            defaultSortColumn={1}
            displayNumberOfItems={true}
            getKey={role => role.get("roleId")}
            createSortableListRowProps={createSortableListRowProps}
          />
        )}
        {selectedView === TREE_VIEW && (
          <TreeList
            Icon={ICONS.ROLE}
            items={roles}
            columns={columns}
            listActions={this.listActions}
            getKey={role => role.get("roleId")}
            createSortableListRowProps={createSortableListRowProps}
          />
        )}
        <SimpleFormModal
          Icon={ICONS.ROLE}
          form={{ form: ROLE_FORM.ID }}
          modalId={CREATE_ROLE_MODAL_ID}
          component={RoleForm}
          submitText="Create"
          title="Create role"
          onSubmit={dispatchCreateRoleOnSubmit}
        />
      </BasePage>
    );
  }
}

export default compose(
  connect(
    state => ({
      roles: getRolesFromState(state),
    }),
    {
      dispatchClearRoles: clearRoles,
      dispatchInitializeEditRole: initializeEditRole,
      dispatchCreateRoleOnSubmit: createRoleOnSubmit,
      dispatchInitializeCreateRole: initializeCreateRole,
    },
  ),
)(RolesBasePage);
