import React from "react";
import { compose } from "redux";
import classNames from "classnames";
import { blue } from "@material-ui/core/colors";
import { Grid, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import SortableListRowIcon from "app/components/SortableList/SortableListRowIcon";

const SortableListRow = ({
  classes,
  children,
  striped,
  selected,
  onClick,
  to,
  href,
  noborder,
  Icon,
  StatusIcon = null,
  Action,
  component,
  selectable,
  selectItem,
  target,
  listHasSelectedItems,
  ...rest
}) => {
  return (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      className={classNames(classes.root, {
        [classes.rootSelected]: selected,
        [classes.rootStriped]: striped,
        [classes.rootClickable]: onClick || to || href,
        [classes.rootBorder]: !noborder,
        [classes.rootSelectable]: !!Icon ? listHasSelectedItems : selectable,
        [classes.rootSelectItem]: selectItem,
      })}
      {...rest}
    >
      <Grid item className={classes.icon}>
        {Icon}
      </Grid>
      <Grid item className={classes.selectIcon}>
        <SortableListRowIcon transparent>
          <Checkbox
            classes={{
              colorSecondary: classes.checkbox,
            }}
            color="primary"
            checked={selected}
            onClick={selectItem}
          />
        </SortableListRowIcon>
      </Grid>

      <Grid
        to={to}
        container
        href={href}
        target={target}
        onClick={onClick}
        alignItems="center"
        component={component}
        className={classes.root}
      >
        {children}
      </Grid>
      {StatusIcon}
      {Action}
    </Grid>
  );
};

SortableListRow.defaultProps = {
  striped: true,
  selectable: false,
};

const styles = theme => ({
  root: {
    textAlign: "start",
    overflow: "hidden",
    height: theme.altus.largeRow.height,
    backgroundClip: "padding-box",
  },
  rootStriped: {
    "&:nth-child(even)": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  rootSelected: {
    backgroundColor: blue[100],
    "&:nth-child(even)": {
      backgroundColor: blue[100],
    },
    "& $selectIcon": {
      display: "inherit",
    },
    "& $icon": {
      display: "none",
    },
  },
  rootClickable: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: `${blue[100]}42`,
    },
    "&:hover $rootStriped": {
      backgroundColor: `${blue[100]}42`,
    },
  },
  rootSelectable: {
    "& $selectIcon": {
      display: "inherit",
    },
    "& $icon": {
      display: "none",
    },
  },
  rootIcon: {
    "& $selectIcon": {
      display: "none",
    },
    "& $icon": {
      display: "inherit",
    },
  },
  rootBorder: {
    borderWidth: 1,
    borderColor: theme.palette.grey[300],
    borderStyle: "none solid solid solid",
  },
  icon: {},
  selectIcon: { display: "none" },
  rootSelectItem: {
    "&:hover $selectIcon": {
      display: "inherit",
    },
    "&:hover $icon": {
      display: "none",
    },
  },
  checkbox: {
    color: "white",
  },
});

export default compose(withStyles(styles))(SortableListRow);
