import { compose } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { generatePath } from "react-router";
import React, { useEffect, useMemo } from "react";
import { withStyles, Grid } from "@material-ui/core";

import { employeeExperienceRecordsOnLoad } from "features/competence.actions";

import {
  getExperienceRecordsFromState,
  getExperienceRecordsStatsFromState,
} from "features/competence.selectors";

import { formatDate } from "utils/format.util";
import CrudBasePage from "app/components/CrudBasePage";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import { ACTIONS, Format, ICONS, SORT_DIRECTION } from "app/app.constants";

const EmployeeExperienceRecordsContainer = ({
  employeeId,
  path,
  experienceRecords,
  dataState,
  breadcrumb,
  experienceRecordsStats,
  dispatchOnLoad,
}) => {
  useEffect(() => {
    dispatchOnLoad(employeeId);
  }, [employeeId, dispatchOnLoad]);

  const columns = useMemo(
    () => [
      {
        xs: 1,
        title: "Job",
        getSortProperty: item => item.get("jobId"),
      },
      {
        xs: true,
        title: "Client",
        getSortProperty: item => item.get("experienceClientName"),
      },
      {
        xs: true,
        title: "Location",
        getSortProperty: item => item.get("locationName"),
      },
      {
        xs: true,
        title: "Discipline",
        getSortProperty: item => item.get("experienceDisciplineName"),
      },
      {
        xs: true,
        title: "Performed as",
        getSortProperty: item => item.get("performedAs"),
      },
      {
        xs: true,
        title: "Validator",
        getSortProperty: item => item.get("validatorDisplayName"),
      },
      {
        xs: true,
        title: "From Date",
        getSortProperty: item => formatDate(item.get("from"), Format.date),
      },
      {
        xs: true,
        title: "To Date",
        getSortProperty: item => formatDate(item.get("to"), Format.date),
      },
      {
        xs: true,
        title: "XP",
        getSortProperty: item =>
          experienceRecordsStats?.getIn(
            [
              item.get("experienceRecordId").toString(),
              "experiencePointsCount",
            ],
            "-",
          ),
      },
      {
        xs: true,
        title: "Status",
        component: Grid,
        container: true,
        justify: "flex-end",
        getSortProperty: item => item.get("competencyStatus"),
        getValue: item => (
          <CompetencyStatusChip status={item.get("competencyStatus")} />
        ),
      },
    ],
    [experienceRecordsStats],
  );

  return (
    <CrudBasePage
      items={experienceRecords}
      title={breadcrumb}
      displaySearchField
      Icon={ICONS.EXPERIENCES}
      dataState={dataState}
      SortableListProps={{
        columns: columns,
        defaultSortDirection: SORT_DIRECTION.ASC,
        defaultSortColumn: columns.findIndex(item => item.title === "Status"),
        displayNumberOfItems: true,
        getKey: item => item.get("experienceRecordId"),
        createSortableListRowProps: item => ({
          component: Link,
          to: `${generatePath(path, { employeeId })}/${item.get(
            "experienceRecordId",
          )}`,
        }),
      }}
    />
  );
};

const styles = theme => ({
  iconButtonRoot: {
    padding: theme.spacing(1),
  },
});

export default compose(
  connect(
    state => ({
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_EMPLOYEE_EXPERIENCE_RECORDS,
      ),
      experienceRecords: getExperienceRecordsFromState(state),
      experienceRecordsStats: getExperienceRecordsStatsFromState(state),
    }),
    {
      dispatchOnLoad: employeeExperienceRecordsOnLoad,
    },
  ),
  withStyles(styles),
)(EmployeeExperienceRecordsContainer);
