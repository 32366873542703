import { compose } from "redux";
import React, { useMemo } from "react";
import { isImmutable } from "immutable";
import { withStyles, Grid, Typography } from "@material-ui/core";

import { TableRowActionsCell } from "altus-ui-components";

import { formatDate } from "utils/format.util";
import { Format, EMPTY_MAP } from "app/app.constants";
import withToggleModal from "app/components/withToggleModal";
import SortableTable from "app/components/Table/SortableTable";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import GapReportEmployeesHeaderOptions from "features/components/GapReport/GapReportEmployeesHeaderOptions";

const TrainingHistoryReportTableContainer = ({
  classes,
  employees = EMPTY_MAP,
}) => {
  const columns = useMemo(
    () => [
      {
        xl: true,
        id: "icon",
        paddingLeft: "16px",
        Header: <TableRowActionsCell minItems={1} />,
        Cell: ({ row }) => {
          if (row.depth !== 0) return <TableRowActionsCell minItems={1} />;
          return null;
        },
      },
      {
        xs: 4,
        id: "employeeDisplayName",
        title: "Employee",
        Header: "Employee",
        getSortProperty: item => item.get("employeeDisplayName"),
        Cell: ({ row }) => {
          if (row.depth === 0) return row.original.get("employeeDisplayName");

          return row.original.get("courseName");
        },
      },
      {
        xs: true,
        id: "startDate",
        title: "Start",
        Header: "Start",
        getSortProperty: item => formatDate(item.get("startDate"), Format.date),
        accessor: item =>
          isImmutable(item)
            ? formatDate(item.get("startDate"), Format.date)
            : "",
        Cell: ({ value }) => {
          if (!value) {
            return null;
          }

          return value;
        },
      },
      {
        xs: true,
        id: "endDate",
        title: "Finished",
        Header: "Finished",
        getSortProperty: item => formatDate(item.get("endDate"), Format.date),
        accessor: item =>
          isImmutable(item) ? formatDate(item.get("endDate"), Format.date) : "",
        Cell: ({ value }) => {
          if (!value) {
            return null;
          }

          return value;
        },
      },
      {
        xs: true,
        title: "",
        id: "competencyStatus",
        Header: "Status",
        component: Grid,
        container: true,
        justify: "flex-end",
        getSortProperty: item => item.get("competencyStatus"),
        Cell: ({ row }) => {
          if (row.depth === 0) return "";
          return (
            <CompetencyStatusChip
              status={row.original.get("competencyStatus")}
            />
          );
        },
      },
    ],
    [],
  );

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={9}>
            <Typography className={classes.titleText} variant="h5">
              {"Training History Report"}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <GapReportEmployeesHeaderOptions
              forceHideSkills={true}
              isCoursesFeatureFlagEnabled={false}
              allRolesVisible={false}
              rolesAssignedOnlyVisible={false}
              employeeAssignedOnly={false}
              showExEmployees={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <SortableTable
          stickyHeader
          disableSortBy
          displayAddButton={false}
          columns={columns}
          keyName="employeeCourseAttendanceId"
          useGlobalFilter={false}
          subListPropertyName="attendances"
          items={employees}
          sortOrder="employeeDisplayName"
          noItemsMessage="No training history found..."
        />
      </Grid>
    </Grid>
  );
};

const styles = theme => ({
  formControlLabelRoot: {
    marginLeft: 0,
  },
  titleText: {
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: "20px",
  },
});

export default compose(
  withStyles(styles),
  withToggleModal,
)(TrainingHistoryReportTableContainer);
