import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { MenuItem, IconButton, withStyles } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { DropdownMenu } from "altus-ui-components";

import { logout, changeUser } from "features/login/login.actions";

const UserDropdown = ({ classes, dispatchChangeUser, dispatchLogout }) => {
  return (
    <DropdownMenu
      Trigger={
        <IconButton color={"secondary"} className={classes.iconButton}>
          <ExitToAppIcon />
        </IconButton>
      }
    >
      <MenuItem onClick={dispatchChangeUser}>Change user</MenuItem>
      <MenuItem onClick={dispatchLogout}>Logout</MenuItem>
    </DropdownMenu>
  );
};

const styles = theme => ({
  iconButton: {
    "&:hover": {
      backgroundColor: theme.sidebar.hover,
    },
  },
});

export default compose(
  connect(null, {
    dispatchLogout: logout,
    dispatchChangeUser: changeUser,
  }),
  withStyles(styles),
)(UserDropdown);
