import { Map, OrderedMap, List } from "immutable";

import { ACTIONS, EMPTY_MAP } from "app/app.constants";

const initialState = EMPTY_MAP;

const sortByColumnDescendingThenRowDescending = (
  collectionItemA,
  collectionItemB,
) => {
  if (collectionItemA.column > collectionItemB.column) {
    return -1;
  } else if (collectionItemA.column < collectionItemB.column) {
    return 1;
  }

  if (collectionItemA.row > collectionItemB.row) {
    return -1;
  } else if (collectionItemA.row < collectionItemB.row) {
    return 1;
  }

  return 0;
};

const groupCollectionItemsByItemId = collectionItems =>
  OrderedMap(
    List(collectionItems)
      .sort(sortByColumnDescendingThenRowDescending)
      .map(collectionItem => [
        collectionItem.competencyCollectionItemId.toString(),
        Map(collectionItem),
      ]),
  );

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case ACTIONS.DELETE_COLLECTION_ITEM: {
      if (error) return state;

      const { collectionId, competencyCollectionItemId } = action;

      return state.deleteIn([
        "byCollectionId",
        collectionId.toString(),
        competencyCollectionItemId.toString(),
      ]);
    }

    case ACTIONS.CREATE_COLLECTION_ITEMS: {
      if (error) return state;

      return state.mergeIn(
        ["byCollectionId", action.collectionId.toString()],
        OrderedMap(
          payload.map(collectionItem => [
            collectionItem.competencyCollectionItemId.toString(),
            Map(collectionItem),
          ]),
        ),
      );
    }

    case ACTIONS.GET_COLLECTION_ITEM: {
      if (error) return state;

      return state.setIn(
        [
          "byCollectionId",
          payload.competencyCollectionId.toString(),
          payload.competencyCollectionItemId.toString(),
        ],
        Map(payload),
      );
    }

    case ACTIONS.GET_COLLECTION_ITEMS: {
      if (error) return state;

      const { collectionId } = action;

      return state.setIn(
        ["byCollectionId", collectionId.toString()],
        groupCollectionItemsByItemId(payload),
      );
    }

    case ACTIONS.RESET_COLLECTION_MATRIX: {
      if (error) return state;

      const { collectionId } = action;

      return state.setIn(
        ["byCollectionId", collectionId.toString()],
        groupCollectionItemsByItemId([
          ...payload.competencyCollectionCourses,
          ...payload.competencyCollectionSkills,
        ]),
      );
    }

    case ACTIONS.GET_SKILL_COLLECTION_OVERRIDES: {
      if (error) return state;

      const { skillId } = action;

      return state.setIn(
        ["bySkillId", skillId.toString()],
        groupCollectionItemsByItemId([
          ...payload.competencyCollectionCourses,
          ...payload.competencyCollectionSkills,
        ]),
      );
    }

    case ACTIONS.GET_COURSE_COLLECTION_OVERRIDES: {
      if (error) return state;

      const { courseId } = action;

      return state.setIn(
        ["byCourseId", courseId.toString()],
        groupCollectionItemsByItemId([
          ...payload.competencyCollectionCourses,
          ...payload.competencyCollectionSkills,
        ]),
      );
    }

    default:
      return state;
  }
};
