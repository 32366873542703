import { OrderedMap } from "immutable";
import { ACTIONS } from "app/app.constants";
import { OrganizationMapper } from "app/app.mappers";

const initialState = OrderedMap();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_ORGANIZATIONS: {
      if (error) return state;

      return OrderedMap(
        payload.map(organization => [
          organization.organizationId.toString(),
          OrganizationMapper.from(organization),
        ]),
      );
    }

    default:
      return state;
  }
};
