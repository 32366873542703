import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import {
  getEmployeeCoursesFromState,
  getAllRoleCoursesByRoleIdByState,
} from "features/competence.selectors";

import { EMPTY_LIST } from "app/app.constants";
import RoleCourseList from "features/components/RoleCourseList/RoleCourseList";

class EmployeeMissingRoleCourseList extends PureComponent {
  render() {
    const { items } = this.props;

    return items.size ? <RoleCourseList {...this.props} /> : null;
  }
}

export default compose(
  connect((initialState, { employeeId }) => {
    const employeeCoursesSelector = getEmployeeCoursesFromState(employeeId);

    return state => {
      const employeeCourses = employeeCoursesSelector(state) || EMPTY_LIST;
      const roleCoursesByRoleId = getAllRoleCoursesByRoleIdByState(state);

      return {
        items: employeeCourses.filter(
          employeeCourse =>
            !roleCoursesByRoleId.some(roleCourses =>
              roleCourses.some(
                roleCourse =>
                  roleCourse.get("courseId") === employeeCourse.get("courseId"),
              ),
            ),
        ),
      };
    };
  }),
)(EmployeeMissingRoleCourseList);
