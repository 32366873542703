import { compose } from "redux";
import memoize from "lodash/memoize";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import React, { PureComponent } from "react";

import { getSummarizedDataStatesFromState } from "app/app.selectors";

import routePaths from "app/routePaths";
import { ACTIONS, ICONS, EMPTY_MAP } from "app/app.constants";
import RolesBasePage from "features/components/RolesBasePage";
import { myTeamRolesOnLoad } from "features/competence.actions";
import { getRoleStatsByRoleIdFromState } from "features/competence.selectors";

class MyTeamEmployeesContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.createColumnsMemoized = memoize(this.createColumns);
  }

  componentDidMount() {
    const { dispatchOnLoad } = this.props;
    dispatchOnLoad();
  }

  createColumns = roleStatsByRoleId => [
    {
      xs: true,
      title: "Role Name",
      getSortProperty: item => item.get("name"),
    },
    {
      xs: 1,
      visible: false,
      title: (
        <Tooltip title="Sort by Tree Structure">
          <ICONS.TREE_VIEW />
        </Tooltip>
      ),
      getSortProperty: item => item.get("treeMin"),
    },
    {
      xs: 2,
      title: "Status",
      getSortProperty: item => item.get("status"),
    },
    {
      xs: 1,
      container: true,
      justify: "center",
      title: (
        <Tooltip title="Skill Count">
          <ICONS.SKILL />
        </Tooltip>
      ),
      getSortProperty: item =>
        roleStatsByRoleId.getIn(
          [item.get("roleId").toString(), "requiredSkillsCount"],
          "-",
        ),
    },
    {
      xs: 1,
      container: true,
      justify: "center",
      title: (
        <Tooltip title="Course Count">
          <ICONS.COURSE />
        </Tooltip>
      ),
      getSortProperty: item =>
        roleStatsByRoleId.getIn(
          [item.get("roleId").toString(), "requiredCoursesCount"],
          "-",
        ),
    },
    {
      xs: 1,
      container: true,
      justify: "center",
      title: (
        <Tooltip title="Member Count">
          <ICONS.TEAM />
        </Tooltip>
      ),
      getSortProperty: item =>
        roleStatsByRoleId.getIn(
          [item.get("roleId").toString(), "memberCount"],
          "-",
        ),
    },
  ];

  createSortableListRowProps = item => ({
    component: Link,
    to: `${routePaths.myTeam}/roles/${item.get("roleId")}`,
  });

  render() {
    const {
      path,
      dataState,
      breadcrumb,
      dispatchOnLoad,
      roleStatsByRoleId,
    } = this.props;

    // the columns does not update automatically when the roleStats are fetched, so we
    // use this little hack
    const columns = this.createColumnsMemoized(roleStatsByRoleId);

    return (
      <RolesBasePage
        path={path}
        hideViewsToggle
        columns={columns}
        title={breadcrumb}
        dataState={dataState}
        onRefresh={dispatchOnLoad}
        createSortableListRowProps={this.createSortableListRowProps}
      />
    );
  }
}

MyTeamEmployeesContainer.defaultProps = {
  roleStatsByRoleId: EMPTY_MAP,
};

export default compose(
  connect(
    state => ({
      roleStatsByRoleId: getRoleStatsByRoleIdFromState(state),
      dataState: getSummarizedDataStatesFromState(state, ACTIONS.GET_ROLES),
    }),
    {
      dispatchOnLoad: myTeamRolesOnLoad,
    },
  ),
)(MyTeamEmployeesContainer);
