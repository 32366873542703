import { compose } from "redux";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
import React, { useState, useCallback, useEffect } from "react";

import DatePickerField from "app/components/form/DatePickerField";

const DateFromToPeriodSelection = ({ classes, onChanged, clearPeriod }) => {
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  useEffect(() => {
    setStart(null);
    setEnd(null);
  }, [clearPeriod]);

  const startChanged = useCallback(
    value => {
      setStart(value);
      onChanged && onChanged({ start: value, end: end });
    },
    [end, onChanged],
  );

  const endChanged = useCallback(
    value => {
      setEnd(value);
      onChanged && onChanged({ start: start, end: value });
    },
    [start, onChanged],
  );

  return (
    <>
      <Grid item xs>
        <DatePickerField
          input={{
            onChange: startChanged,
            value: start,
          }}
          inputVariant="filled"
          DatePickerProps={{
            margin: "none",
            label: "Start Date",
            classes: {
              root: classes.textFieldRoot,
            },
          }}
        />
      </Grid>
      <Grid item xs>
        <DatePickerField
          input={{
            onChange: endChanged,
            value: end,
          }}
          inputVariant="filled"
          DatePickerProps={{
            margin: "none",
            label: "End Date",
            classes: {
              root: classes.textFieldRoot,
            },
          }}
        />
      </Grid>
    </>
  );
};

const styles = theme => ({
  textFieldRoot: {
    "& .MuiFilledInput-root": {
      background: theme.palette.common.white,
      "&:hover": {
        background: theme.palette.grey[100],
      },
    },
  },
});

export default compose(withStyles(styles))(DateFromToPeriodSelection);
