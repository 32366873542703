import { compose } from "redux";
import { connect } from "react-redux";
import React, { useCallback, useState } from "react";

import { invokeIfFunction } from "utils/app.util";
import { EMPTY_MAP, EMPTY_LIST, ACTIONS } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import { BASE_FILTERS } from "features/components/GapReport/GapReportFilterBase";
import GapReportFilterBase from "features/components/GapReport/GapReportFilterBase";
import { getTrainingReportEmployeesFromState } from "features/competence.selectors";
import DateFromToPeriodSelection from "features/reports/common/DateFromToPeriodSelection";

const FILTERS = {
  DATE_RANGE: "dateRange",
};

const initialFilter = EMPTY_MAP.set(FILTERS.DATE_RANGE, {})
  .set(BASE_FILTERS.EMPLOYEES, EMPTY_LIST)
  .set(BASE_FILTERS.DEPARTMENTS, EMPTY_LIST);

const TrainingHistoryReportFilter = ({
  classes,
  getGapReport,
  clearGapReport,
  downloadGapReport,
  employeesByDepartment,
}) => {
  const [count, setCount] = useState(0);
  const renderFilter = useCallback(
    ({ setFilter, renderEmployeeFilter, renderDepartmentFilter }) => (
      <>
        {invokeIfFunction(renderDepartmentFilter)}
        {invokeIfFunction(renderEmployeeFilter)}
        <DateFromToPeriodSelection
          clearPeriod={count}
          onChanged={period => setFilter(FILTERS.DATE_RANGE, period)}
        />
      </>
    ),
    [count],
  );

  const clearReport = useCallback(() => {
    setCount(count + 1);
    clearGapReport();
  }, [clearGapReport, count]);

  return (
    <GapReportFilterBase
      getGapReport={getGapReport}
      initialFilter={initialFilter}
      clearGapReport={clearReport}
      renderFilter={renderFilter}
      downloadGapReport={downloadGapReport}
      downloadBtnDisabled={!employeesByDepartment?.size}
    />
  );
};

export default compose(
  connect(state => ({
    employeesByDepartment: getTrainingReportEmployeesFromState(state),
    dataState: getSummarizedDataStatesFromState(
      state,
      ACTIONS.GET_TRAINING_HISTORY_REPORT,
    ),
  })),
)(TrainingHistoryReportFilter);
