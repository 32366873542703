import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import {
  getCourseFromState,
  getEmployeeFromState,
  getEmployeeCourseFromState,
} from "features/competence.selectors";

import { EMPTY_MAP, ICONS } from "app/app.constants";
import EntityDetailsHeader from "app/components/EntityDetailsHeader";

const EmployeeCourseDetailsHeader = ({
  course,
  children,
  employee,
  employeeCourse,
}) => {
  return (
    <EntityDetailsHeader
      Icon={<ICONS.COURSE />}
      title={course.get("name")}
      subtitle={employee.get("displayName")}
      competencyStatus={employeeCourse.get("competencyStatus")}
    >
      {children}
    </EntityDetailsHeader>
  );
};

EmployeeCourseDetailsHeader.defaultProps = {
  course: EMPTY_MAP,
  employee: EMPTY_MAP,
  employeeCourse: EMPTY_MAP,
};

export default compose(
  connect((state, { courseId, employeeId }) => ({
    course: getCourseFromState(state, courseId),
    employee: getEmployeeFromState(state, employeeId),
    employeeCourse: getEmployeeCourseFromState(state, employeeId, courseId),
  })),
)(EmployeeCourseDetailsHeader);
