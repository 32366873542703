import { List } from "immutable";
import { ACTIONS, EMPTY_MAP } from "app/app.constants";
import { ExperiencePositionMapper } from "app/app.mappers";

const initialState = EMPTY_MAP;

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_EXPERIENCE_RECORD_AVAILABLE_POSITIONS: {
      if (error) return state;

      return state.set(
        "available",
        List(payload).map(ExperiencePositionMapper.from),
      );
    }

    default:
      return state;
  }
};
