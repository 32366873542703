import React, { PureComponent } from "react";

import EmployeeSkillsBasePage from "features/components/EmployeeSkillsBasePage";

class EmployeeSkillsContainer extends PureComponent {
  render() {
    const { employeeId, breadcrumb, path } = this.props;

    return (
      <EmployeeSkillsBasePage
        path={path}
        title={breadcrumb}
        employeeId={employeeId}
      />
    );
  }
}

export default EmployeeSkillsContainer;
