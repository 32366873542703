import { compose } from "redux";
import { Link } from "react-router-dom";
import React, { PureComponent } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { EMPTY_SET } from "app/app.constants";

class GapReportEmployeeRow extends PureComponent {
  render() {
    const { root, classes, employee, children } = this.props;

    return (
      <Grid container className={classes.root}>
        <Grid
          item
          container
          wrap="nowrap"
          component={Link}
          alignItems="center"
          className={classes.employeeCell}
          to={`${root}/employees/${employee.get("employeeId")}/courses`}
        >
          <Grid container spacing={2}>
            <Grid item xs={2} component={Typography} noWrap variant="caption">
              {employee.get("employeeNumber")}
            </Grid>
            <Grid item xs={5} variant="caption" noWrap component={Typography}>
              {employee.get("displayName")}
            </Grid>
            <Grid item xs={5} variant="caption" noWrap component={Typography}>
              {employee.get("position")}
            </Grid>
          </Grid>
        </Grid>
        {children}
      </Grid>
    );
  }
}

GapReportEmployeeRow.defaultProps = {
  employees: EMPTY_SET,
};

const styles = theme => ({
  root: {
    borderRight: theme.gapReport.defaultBorder,
  },
  employeeCell: {
    left: 0,
    zIndex: 4,
    position: "sticky",
    minHeight: theme.gapReport.cellSize,
    padding: `0px ${theme.spacing(2)}px`,
    borderBottom: theme.gapReport.defaultBorder,
    background: theme.palette.background.default,
    width: theme.gapReport.employeesColumn.width,
    "&:hover": {
      background: theme.palette.action.hover,
    },
  },
});

export default compose(withStyles(styles))(GapReportEmployeeRow);
