import { compose } from "redux";
import { Field } from "redux-form/immutable";
import React, { PureComponent } from "react";

import { required } from "utils/validation.util";
import TextField from "app/components/form/TextField";
import { SKILL_GROUP_FORM } from "features/competence.constants";

class SkillGroupForm extends PureComponent {
  render() {
    const { handleSubmit } = this.props;

    return (
      <form id={SKILL_GROUP_FORM.ID} onSubmit={handleSubmit}>
        <Field
          autoFocus
          required
          label="Name"
          validate={[required]}
          component={TextField}
          name={SKILL_GROUP_FORM.NAME}
        />
      </form>
    );
  }
}

export default compose()(SkillGroupForm);
