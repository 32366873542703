import { Map } from "immutable";
import { createTheme } from "@material-ui/core/styles";

import {
  blue,
  red,
  grey,
  orange,
  yellow,
  purple,
  lightGreen,
} from "@material-ui/core/colors";

import { defaultOptions as defaultThemeOptions, THEMES } from "altus-ui-theme";

import { Environments } from "app/app.constants";

const defaultThemeType = THEMES.LIGHT;

const defaultTheme = createTheme({
  palette: {
    type: defaultThemeType,
  },
});

const competencePlannerOptions = {
  sidebar: {
    width: 244,
    hover: grey[700],
    backgroundColor: grey[900],
  },
  gapReport: {
    cellSize: 32,
    defaultBorder: `1px solid ${grey[300]}`,
    employeesColumn: {
      width: 450,
    },
    header: {
      height: 270,
    },
  },
  typography: {
    useNextVariants: true,
  },
  palette: {
    environment: {
      [Environments.LOCAL]: lightGreen[500],
      [Environments.DEV]: orange[500],
      [Environments.TST]: yellow[500],
      [Environments.SBX]: purple[500],
      [Environments.SCA]: red[500],
    },
    primary: {
      main: grey[900],
    },
    secondary: {
      main: defaultTheme.palette.common.white,
    },
  },
  altus: {
    background: {
      view: "#ebebeb",
    },
    border: {
      row: "#dadada",
    },
    largeRow: {
      height: 50,
    },
    components: {
      SortableListRow: {
        stripedColor: grey[200],
      },
      SortableListRowColumn: {
        textColor: defaultTheme.palette.text.primary,
      },
    },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        backgroundColor: "#4b5a64",
        color: defaultTheme.palette.common.white,
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: defaultTheme.palette.common.white,
      },
    },
    MuiFilledInput: {
      input: {
        padding: "26px 12px 5px",
      },
    },
    MuiTab: {
      textColorInherit: {
        opacity: 1,
      },
    },
    MuiBreadcrumbs: {
      separator: {
        color: defaultTheme.palette.common.black,
      },
    },
  },
  props: {
    MuiSelect: {
      displayEmpty: true,
    },
    MuiTextField: {
      fullWidth: true,
      margin: "normal",
    },
    MuiTooltip: {
      enterDelay: 500,
    },
  },
  urlLink: {
    color: blue[600],
  },
};

const mergedOptions = Map(defaultThemeOptions)
  .mergeDeep(Map(competencePlannerOptions))
  .toJS();

export default createTheme(mergedOptions);
