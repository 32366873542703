import { combineReducers } from "redux-immutable";

import competencyCategoryReducer from "features/reducers/competencyCategory.reducer";
import courseReducer from "features/reducers/course.reducer";
import collectionReducer from "features/reducers/collection.reducer";
import employeeCourseReducer from "features/reducers/employeeCourse.reducer";
import employeeReducer from "features/reducers/employee.reducer";
import employeeRoleReducer from "features/reducers/employeeRole.reducer";
import roleCourseReducer from "features/reducers/roleCourse.reducer";
import roleEmployeeReducer from "features/reducers/roleEmployee.reducer";
import roleReducer from "features/reducers/role.reducer";
import employeeEducationReducer from "features/reducers/employeeEducation.reducer";
import workHistoryReducer from "features/reducers/workHistory.reducer";
import courseGroupReducer from "features/reducers/courseGroup.reducer";
import approvalReducer from "features/reducers/approval.reducer";
import employeeCourseAttendanceReducer from "features/reducers/employeeCourseAttendance.reducer";
import departmentReducer from "features/reducers/department.reducer";
import managerReducer from "features/reducers/manager.reducer";
import countryReducer from "features/reducers/country.reducer";
import skillGroupReducer from "features/reducers/skillGroup.reducer";
import skillTypeReducer from "features/reducers/skillType.reducer";
import courseTypeReducer from "features/reducers/courseType.reducer";
import skillReducer from "features/reducers/skill.reducer";
import experienceRecordReducer from "features/reducers/experienceRecord.reducer";
import experienceRecordItemReducer from "features/reducers/experienceRecordItem.reducer";
import experienceRecordCategoryReducer from "features/reducers/experienceRecordCategoryReducer.reducer";
import experienceRecordPositionReducer from "features/reducers/experienceRecordPosition.reducer";
import experienceRecordValidatorReducer from "features/reducers/experienceRecordValidator.reducer";
import experienceDisciplineReducer from "features/reducers/experienceDiscipline.reducer";
import organizationReducer from "features/reducers/organization.reducer";
import facilityReducer from "features/reducers/facility.reducer";
import roleSkillReducer from "features/reducers/roleSkill.reducer";
import employeeRoleSkillReducer from "features/reducers/employeeRoleSkill.reducer";
import employeeServiceReducer from "features/reducers/employeeService.reducer";
import employeeSkillReducer from "features/reducers/employeeSkill.reducer";
import skillGapReportReducer from "features/reducers/skillGapReport.reducer";
import statsReducer from "features/reducers/stats.reducer";
import employeeSkillFileEvidenceReducer from "features/reducers/employeeSkillFileEvidence.reducer";
import employeeSkillEvidenceReducer from "features/reducers/employeeSkillEvidence.reducer";
import specificationReducer from "features/reducers/specification.reducer";
import specificationCourseReducer from "features/reducers/specificationCourse.reducer";
import specificationSkillReducer from "features/reducers/specificationSkill.reducer";
import specificationGapReportReducer from "features/reducers/specificationGapReport.reducer";
import myTeamReducer from "features/reducers/myTeam.reducer";
import courseGapReportReducer from "features/reducers/courseGapReport.reducer";
import gapReportReducer from "features/reducers/gapReport.reducer";
import roleGapReportReducer from "features/reducers/roleGapReport.reducer";
import employeeRoleGapReportReducer from "features/reducers/employeeRoleGapReport.reducer";
import trainingHistoryReportReducer from "features/reducers/trainingHistoryReport.reducer";
import trainingAndCompetencyReport from "features/reducers/trainingAndCompetencyReport.reducer";
import courseTrainingHistoryReportReducer from "features/reducers/courseTrainingHistoryReport.reducer";
import courseExpiryReport from "features/reducers/courseExpiryReport.reducer";
import outstandingTrainingHistoryReportReducer from "features/reducers/outstandingTrainingHistoryReport.reducer";

import collectionItemReducer from "features/reducers/collectionItem.reducer";
import serviceReducer from "features/reducers/service.reducer";
import serviceCourseReducer from "features/reducers/serviceCourse.reducer";
import serviceSkillReducer from "features/reducers/serviceSkill.reducer";
import serviceGapReportReducer from "features/reducers/serviceGapReport.reducer";

export default combineReducers({
  competencyCategory: competencyCategoryReducer,
  course: courseReducer,
  collection: collectionReducer,
  employee: employeeReducer,
  employeeCourse: employeeCourseReducer,
  employeeRole: employeeRoleReducer,
  role: roleReducer,
  roleCourse: roleCourseReducer,
  roleEmployee: roleEmployeeReducer,
  employeeEducation: employeeEducationReducer,
  workHistory: workHistoryReducer,
  courseGroup: courseGroupReducer,
  approval: approvalReducer,
  employeeCourseAttendance: employeeCourseAttendanceReducer,
  department: departmentReducer,
  roleGapReport: roleGapReportReducer,
  employeeRoleGapReport: employeeRoleGapReportReducer,
  trainingHistoryReport: trainingHistoryReportReducer,
  courseTrainingHistoryReport: courseTrainingHistoryReportReducer,
  outstandingTrainingHistoryReport: outstandingTrainingHistoryReportReducer,
  courseGapReport: courseGapReportReducer,
  manager: managerReducer,
  country: countryReducer,
  skillGroup: skillGroupReducer,
  skillType: skillTypeReducer,
  courseType: courseTypeReducer,
  skill: skillReducer,
  experienceRecord: experienceRecordReducer,
  experienceRecordItem: experienceRecordItemReducer,
  experienceRecordCategory: experienceRecordCategoryReducer,
  experienceRecordPosition: experienceRecordPositionReducer,
  experienceRecordValidator: experienceRecordValidatorReducer,
  experienceDiscipline: experienceDisciplineReducer,
  organization: organizationReducer,
  facility: facilityReducer,
  roleSkill: roleSkillReducer,
  employeeRoleSkill: employeeRoleSkillReducer,
  employeeSkill: employeeSkillReducer,
  skillGapReport: skillGapReportReducer,
  employeeSkillFileEvidence: employeeSkillFileEvidenceReducer,
  employeeSkillEvidence: employeeSkillEvidenceReducer,
  stats: statsReducer,
  specification: specificationReducer,
  specificationCourse: specificationCourseReducer,
  specificationSkill: specificationSkillReducer,
  specificationGapReport: specificationGapReportReducer,
  myTeam: myTeamReducer,
  gapReport: gapReportReducer,
  collectionItem: collectionItemReducer,
  service: serviceReducer,
  serviceCourse: serviceCourseReducer,
  serviceSkill: serviceSkillReducer,
  serviceGapReport: serviceGapReportReducer,
  employeeService: employeeServiceReducer,
  trainingAndCompetencyReport: trainingAndCompetencyReport,
  courseExpiryReport: courseExpiryReport,
});
