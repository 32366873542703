import { compose } from "redux";
import { connect } from "react-redux";
import Info from "@material-ui/icons/Info";
import React, { PureComponent } from "react";
import { blue } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";

import {
  getCurrentEmployeeFromState,
  getActionDataStateFromState,
} from "app/app.selectors";

import { ACTIONS, SAP_HELP_URL } from "app/app.constants";
import EmployeeDetailsForm from "features/components/EmployeeDetailsForm";
import { getEmployeeAndInitializeForm } from "features/competence.actions";
import { BasePage } from "altus-ui-components";

class MyDetailsContainer extends PureComponent {
  componentDidMount() {
    const { dispatchGetEmployeeAndInitializeForm } = this.props;
    dispatchGetEmployeeAndInitializeForm();
  }

  render() {
    const {
      currentEmployee,
      dataState,
      classes,
      breadcrumb,
      Icon,
    } = this.props;

    return (
      <BasePage Icon={Icon} title={breadcrumb} dataState={dataState}>
        <Grid container item xs spacing={3}>
          <Grid item md={9} xs={12}>
            <EmployeeDetailsForm employeeId={currentEmployee.get("employeeId")}>
              <Paper
                classes={{
                  root: classes.root,
                }}
              >
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Grid container>
                      <Info className={classes.icon} />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      Note: This information is imported from SAP.
                    </Typography>
                    &nbsp;
                    <Grid container />
                    <Typography variant="caption">
                      <span className={classes.linkText}>How to: </span>
                      <a
                        target="_blank"
                        href={SAP_HELP_URL}
                        className={classes.link}
                        rel="noopener noreferrer"
                      >
                        Update your personal profile in SAP
                      </a>
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </EmployeeDetailsForm>
          </Grid>
        </Grid>
      </BasePage>
    );
  }
}

const styles = theme => ({
  root: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    background: blue[50],
  },
  link: {
    textDecoration: "underline",
  },
  linkText: {
    fontWeight: 500,
  },
  icon: {
    fill: blue[300],
  },
});

export default compose(
  connect(
    state => ({
      dataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_CURRENT_EMPLOYEE,
      ),
      currentEmployee: getCurrentEmployeeFromState(state),
    }),
    {
      dispatchGetEmployeeAndInitializeForm: getEmployeeAndInitializeForm,
    },
  ),
  withStyles(styles),
)(MyDetailsContainer);
