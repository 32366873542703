import { compose } from "redux";
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";
import React, { useEffect, useState, useMemo, useCallback } from "react";

import {
  clearServices,
  getStatsForServices,
  initializeEditService,
  createServiceOnSubmit,
  initializeCreateService,
} from "features/competence.actions";

import ListTreeViewToggleButtons, {
  LIST_VIEW,
  TREE_VIEW,
} from "features/components/ListTreeViewToggleButtons";

import {
  SERVICE_FORM,
  CREATE_SERVICE_MODAL_ID,
} from "features/competence.constants";

import { BasePage } from "altus-ui-components";
import { ICONS, EMPTY_MAP } from "app/app.constants";
import TreeList from "app/components/TreeList/TreeList";
import SimpleFormModal from "app/components/SimpleFormModal";
import SortableList from "app/components/SortableList/SortableList";
import ServiceForm from "features/hr/services/components/ServiceForm";
import { getServiceStatsByServiceIdFromState } from "features/competence.selectors";

const ServicesBasePage = ({
  title,
  actions,
  services,
  dataState,
  displayAddButton,
  dispatchClearServices,
  createFormValueSelector,
  createSortableListRowProps,
  dispatchGetStatsForServices,
  dispatchCreateServiceOnSubmit,
  dispatchInitializeCreateService,
  serviceStatsByServiceId = EMPTY_MAP,
}) => {
  const [selectedView, setSelectedView] = useState(LIST_VIEW);

  useEffect(() => {
    dispatchGetStatsForServices();

    return () => dispatchClearServices();
  }, [dispatchGetStatsForServices, dispatchClearServices]);

  const onSelectedViewChange = useCallback((event, selView) => {
    selView && setSelectedView(selView);
  }, []);

  const columns = useMemo(
    () => [
      {
        xs: 4,
        title: "Service Name",
        getSortProperty: item => item.get("name"),
      },
      {
        xs: 1,
        visible: false,
        title: (
          <Tooltip title="Sort by Tree Structure">
            <ICONS.TREE_VIEW />
          </Tooltip>
        ),
        getSortProperty: item => item.get("treeMin"),
      },
      {
        xs: true,
        filter: {
          defaultText: "All owners",
        },
        title: "Owner",
        getSortProperty: item => item.get("ownerDisplayName"),
      },
      {
        filter: {
          defaultText: "Any",
          label: "Countries",
          getFilterText: filter => (filter === "" ? "Global" : filter),
        },
        xs: 1,
        title: "Countries",
        getSortProperty: item =>
          item
            .get("countries")
            .map(country => country.get("twoLetterCode"))
            .join(", "),
      },
      {
        xs: 1,
        container: true,
        justify: "center",
        title: (
          <Tooltip title="Skill Count">
            <ICONS.SKILL />
          </Tooltip>
        ),
        getSortProperty: item =>
          serviceStatsByServiceId.getIn(
            [item.get("serviceId").toString(), "serviceSkillCount"],
            "-",
          ),
      },
      {
        xs: 1,
        container: true,
        justify: "center",
        title: (
          <Tooltip title="Course Count">
            <ICONS.COURSE />
          </Tooltip>
        ),
        getSortProperty: item =>
          serviceStatsByServiceId.getIn(
            [item.get("serviceId").toString(), "serviceCourseCount"],
            "-",
          ),
      },
    ],
    [serviceStatsByServiceId],
  );

  const listActions = useMemo(
    () => [
      {
        renderAction: () => (
          <ListTreeViewToggleButtons
            value={selectedView}
            onChange={onSelectedViewChange}
          />
        ),
        getTitle: () => "",
      },
    ],
    [selectedView, onSelectedViewChange],
  );

  return (
    <BasePage
      displayAddButton={displayAddButton}
      title={title}
      Icon={ICONS.SERVICE}
      dataState={dataState}
      addButtonOnClick={dispatchInitializeCreateService}
    >
      {selectedView === LIST_VIEW && (
        <SortableList
          Icon={ICONS.SERVICE}
          items={services}
          actions={actions}
          listActions={listActions}
          dataState={dataState}
          columns={columns}
          defaultSortColumn={1}
          displayNumberOfItems={true}
          getKey={service => service.get("serviceId")}
          createSortableListRowProps={createSortableListRowProps}
        />
      )}
      {selectedView === TREE_VIEW && (
        <TreeList
          Icon={ICONS.SERVICE}
          items={services}
          columns={columns}
          listActions={listActions}
          getKey={service => service.get("serviceId")}
          createSortableListRowProps={createSortableListRowProps}
        />
      )}
      <SimpleFormModal
        Icon={ICONS.SERVICE}
        form={{ form: SERVICE_FORM.ID }}
        modalId={CREATE_SERVICE_MODAL_ID}
        component={ServiceForm}
        submitText="Create"
        title="Create Service"
        onSubmit={dispatchCreateServiceOnSubmit}
        formValueSelector={createFormValueSelector}
      />
    </BasePage>
  );
};

export default compose(
  connect(
    state => ({
      serviceStatsByServiceId: getServiceStatsByServiceIdFromState(state),
    }),
    {
      dispatchClearServices: clearServices,
      dispatchInitializeEditService: initializeEditService,
      dispatchCreateServiceOnSubmit: createServiceOnSubmit,
      dispatchInitializeCreateService: initializeCreateService,
      dispatchGetStatsForServices: getStatsForServices,
    },
  ),
)(ServicesBasePage);
