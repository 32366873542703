import { compose } from "redux";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";
import { reduxForm } from "redux-form/immutable";

import {
  updateSpecification,
  initializeEditSpecification,
  specificationDetailsOnLoad,
} from "features/competence.actions";

import { ACTIONS } from "app/app.constants";
import { BasePage } from "altus-ui-components";
import { getActionDataStateFromState } from "app/app.selectors";
import { SPECIFICATION_FORM } from "features/competence.constants";
import { getSpecificationFromState } from "features/competence.selectors";
import SpecificationForm from "features/hr/specifications/components/SpecificationForm";

class SpecificationDetailsContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad, specificationId } = this.props;

    dispatchOnLoad(specificationId);
  }

  render() {
    const { breadcrumb, specification, dataState, ...rest } = this.props;

    return (
      <BasePage title={breadcrumb} dataState={dataState}>
        <Grid container item md={8} xs={12}>
          <SpecificationForm currentSpecification={specification} {...rest} />
        </Grid>
      </BasePage>
    );
  }
}

export default compose(
  connect(
    (state, { specificationId }) => ({
      specification: getSpecificationFromState(state, specificationId),
      dataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_SPECIFICATION_WITH_PARENTS,
      ),
    }),
    {
      dispatchOnLoad: specificationDetailsOnLoad,
    },
  ),
  reduxForm({
    form: SPECIFICATION_FORM.ID,
    onChange: debounce((values, dispatch, props) => {
      const { dirty, specificationId } = props;

      if (dirty) {
        dispatch(
          updateSpecification(specificationId, values.toJS()),
        ).then(specification =>
          dispatch(initializeEditSpecification(specification)),
        );
      }
    }, 1000),
  }),
)(SpecificationDetailsContainer);
