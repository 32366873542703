import React from "react";

import { compose } from "redux";
import { connect } from "react-redux";
import { Field } from "redux-form/immutable";
import { Grid, MenuItem } from "@material-ui/core";
import { formValueSelector } from "redux-form/immutable";

import { required } from "utils/validation.util";
import TextField from "app/components/form/TextField";
import DatePickerField from "app/components/form/DatePickerField";
import { CREATE_EXPERIENCE_RECORD_FORM } from "features/competence.constants";
import MultiSelectEmployeeModalField from "app/components/form/MultiSelectModalField";

import {
  getEmployeesFromState,
  getExperienceClientsFromState,
  getExperienceLocationsFromState,
  getExperienceDisciplinesFromState,
} from "features/competence.selectors";

const formSelector = formValueSelector(CREATE_EXPERIENCE_RECORD_FORM.ID);

const CreateExperienceRecordForm = ({
  fromDate,
  clients,
  employees,
  locations,
  disciplines,
  handleSubmit,
}) => {
  return (
    <form id={CREATE_EXPERIENCE_RECORD_FORM.ID} onSubmit={handleSubmit}>
      <Grid container direction="column">
        <Grid item>
          <Field
            required
            format={null}
            label="Employees"
            validate={[required]}
            employees={employees}
            component={MultiSelectEmployeeModalField}
            name={CREATE_EXPERIENCE_RECORD_FORM.EMPLOYEES_ID}
          />
        </Grid>
        <Grid item>
          <Field
            required
            label="Job Number"
            validate={[required]}
            component={TextField}
            name={CREATE_EXPERIENCE_RECORD_FORM.JOB_NUMBER}
          />
        </Grid>
        <Grid item>
          <Field
            select
            required
            label="Client"
            validate={[required]}
            component={TextField}
            name={CREATE_EXPERIENCE_RECORD_FORM.CLIENT_ID}
          >
            {clients.map(clients => (
              <MenuItem
                key={clients.get("organizationId")}
                value={clients.get("organizationId")}
              >
                {clients.get("name")}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item>
          <Field
            select
            required
            label="Location"
            validate={[required]}
            component={TextField}
            name={CREATE_EXPERIENCE_RECORD_FORM.LOCATION_ID}
          >
            {locations.map(location => (
              <MenuItem
                key={location.get("facilityId")}
                value={location.get("facilityId")}
              >
                {location.get("name")}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item>
          <Field
            select
            required
            label="Discipline"
            validate={[required]}
            component={TextField}
            name={CREATE_EXPERIENCE_RECORD_FORM.DISCIPLINE_ID}
          >
            {disciplines.map(discipline => (
              <MenuItem
                key={discipline.get("experienceDisciplineId")}
                value={discipline.get("experienceDisciplineId")}
              >
                {discipline.get("name")}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <Field
              validate={[required]}
              component={DatePickerField}
              name={CREATE_EXPERIENCE_RECORD_FORM.FROM}
              DatePickerProps={{
                required: true,
                margin: "normal",
                variant: "filled",
                label: "From",
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={DatePickerField}
              name={CREATE_EXPERIENCE_RECORD_FORM.TO}
              DatePickerProps={{
                margin: "normal",
                variant: "filled",
                label: "To",
                minDate: fromDate,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default compose(
  connect(state => ({
    employees: getEmployeesFromState(state),
    clients: getExperienceClientsFromState(state),
    locations: getExperienceLocationsFromState(state),
    disciplines: getExperienceDisciplinesFromState(state),
    fromDate: formSelector(state, CREATE_EXPERIENCE_RECORD_FORM.FROM),
  })),
)(CreateExperienceRecordForm);
