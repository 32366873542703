import React from "react";
import { compose } from "redux";
import withStyles from "@material-ui/styles/withStyles";
import useBreadcrumbs from "use-react-router-breadcrumbs";

import { BreadcrumbHeader as BreadcrumbHeaderBase } from "altus-ui-components";

import routes from "app/routes";
import routePaths from "app/routePaths";

const BreadcrumbHeader = ({ classes }) => {
  const breadcrumbs = useBreadcrumbs(routes, {
    disableDefaults: true,
    excludePaths: [routePaths.root],
  });

  return (
    <BreadcrumbHeaderBase
      breadcrumbs={breadcrumbs}
      classes={{
        header: classes.header,
        breadcrumbText: classes.breadcrumbText,
      }}
    />
  );
};

const styles = theme => ({
  header: {
    backgroundColor: "transparent",
    color: theme.palette.primary,
  },
  breadcrumbText: {
    color: theme.palette.primary,
  },
});

export default compose(withStyles(styles))(BreadcrumbHeader);
