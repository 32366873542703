import { compose } from "redux";
import { Field } from "redux-form/immutable";
import React, { PureComponent, createRef } from "react";
import { Grid } from "@material-ui/core";

import { saveFile } from "utils/app.util";
import { required, notEmpty } from "utils/validation.util";
import { EMPLOYEE_SKILL_FORM } from "features/competence.constants";
import FileUploadField from "app/components/form/FileUploadField/FileUploadField";
import EmployeeSkillFileUpload from "features/components/EmployeeSkillFileUpload";
import DatePickerField from "app/components/form/DatePickerField";

class AddEmployeeSkillEvidenceForm extends PureComponent {
  constructor(props) {
    super(props);

    this.clearEmployeeSkillRef = createRef();
  }

  render() {
    const { handleSubmit, employeeId, skillId } = this.props;

    return (
      <form onSubmit={handleSubmit} id={EMPLOYEE_SKILL_FORM.ID}>
        <Grid container spacing={3}>
          <Grid container item direction="column">
            <Grid item>
              <Field
                validate={[required, notEmpty]}
                multiple={true}
                downloadFile={saveFile}
                component={FileUploadField}
                name={EMPLOYEE_SKILL_FORM.FILE_EVIDENCES}
                label="Evidences Files"
                FormControlProps={{
                  required: true,
                  margin: "normal",
                }}
              >
                {({
                  value,
                  rootProps,
                  removeFile,
                  isDragActive,
                  openFileDialog,
                }) => (
                  <EmployeeSkillFileUpload
                    value={value}
                    rootProps={rootProps}
                    employeeId={employeeId}
                    removeFile={removeFile}
                    skillId={skillId}
                    isDragActive={isDragActive}
                    openFileDialog={openFileDialog}
                  />
                )}
              </Field>
            </Grid>
            <Grid container item>
              <Grid item>
                <Field
                  inputVariant="standard"
                  validate={[required]}
                  component={DatePickerField}
                  name={EMPLOYEE_SKILL_FORM.WHEN}
                  DatePickerProps={{
                    required: true,
                    margin: "normal",
                    label: "When",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default compose()(AddEmployeeSkillEvidenceForm);
