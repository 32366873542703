import React from "react";
import { compose } from "redux";
import classNames from "classnames";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const SortableListRowIcon = ({ classes, children, transparent, ...props }) => (
  <Grid item className={classes.iconColumn} {...props}>
    <Grid
      classes={{
        root: classNames({
          [classes.transparent]: transparent,
        }),
      }}
    >
      {children}
    </Grid>
  </Grid>
);

const styles = theme => ({
  iconColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: theme.altus.largeRow.height,
    minWidth: theme.altus.largeRow.height,
    height: theme.altus.largeRow.height - 1,
  },
  iconColumnBackground: {
    background: theme.altus.background.header,
  },
  transparent: {
    background: "transparent",
  },
});

SortableListRowIcon.defaultProps = {
  transparent: false,
};

export default compose(React.memo, withStyles(styles))(SortableListRowIcon);
