import React from "react";
import { compose } from "redux";
import ExperienceRecordRouteContainer from "features/experience/ExperienceRecordRouteContainer";

const EmployeeExperienceRecordContainer = ({
  employeeId,
  experienceRecordId,
  routes,
  Icon,
}) => {
  return (
    <ExperienceRecordRouteContainer
      Icon={Icon}
      experienceRecordId={experienceRecordId}
      employeeId={employeeId}
      routes={routes}
    />
  );
};

export default compose()(EmployeeExperienceRecordContainer);
