import React from "react";
import { compose } from "redux";

import { Grid } from "@material-ui/core";

import { EMPTY_LIST, EMPTY_SET } from "app/app.constants";

import EmployeeRoleGapReportRow from "features/employee/reports/EmployeeRoleGapReportRow";
import EmployeeRoleGapReportHeader from "features/employee/reports/EmployeeRoleGapReportHeader";

const EmployeeRoleGapReport = ({
  employeeId,
  roles = EMPTY_LIST,
  courses = EMPTY_LIST,
  skills = EMPTY_LIST,
  roleSkills = EMPTY_SET,
  roleCourses = EMPTY_SET,
  employeeRoles = EMPTY_SET,
  employeeSkills = EMPTY_SET,
  employeeCourses = EMPTY_SET,
  isSkillsFeatureFlagEnabled,
}) => (
  <Grid item container wrap="nowrap" direction="column">
    <EmployeeRoleGapReportHeader courses={courses} skills={skills} />
    {employeeRoles.toList().map(employeeRole => {
      const roleId = employeeRole.get("roleId").toString();
      const roleName = roles.getIn([roleId, "name"]);
      const roleEmployeeId = employeeRole.get("roleEmployeeId");

      return (
        <EmployeeRoleGapReportRow
          roleId={roleId}
          courses={courses}
          skills={skills}
          roleName={roleName}
          key={roleEmployeeId}
          employeeId={employeeId}
          roleCourses={roleCourses}
          roleSkills={roleSkills}
          employeeRole={employeeRole}
          employeeSkills={employeeSkills}
          employeeCourses={employeeCourses}
          isSkillsFeatureFlagEnabled={isSkillsFeatureFlagEnabled}
        />
      );
    })}
  </Grid>
);

export default compose(React.memo)(EmployeeRoleGapReport);
