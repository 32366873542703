import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { Grid } from "@material-ui/core";

import { getEmployeeRoleSkillList } from "features/competence.actions";

import { formatDate } from "utils/format.util";
import CrudBasePage from "app/components/CrudBasePage";
import { getActionDataStateFromState } from "app/app.selectors";
import CompetencyStatusChip from "app/components/CompetencyStatusChip";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";
import { ACTIONS, ICONS, Format, criticalityToString } from "app/app.constants";
import { getEmployeeRoleSkillsByRoleSelector } from "features/competence.selectors";

class EmployeeRoleSkillsContainer extends PureComponent {
  componentDidMount() {
    const { employeeId, dispatchOnLoad } = this.props;
    dispatchOnLoad(employeeId);
  }

  columns = [
    {
      xs: 4,
      title: "Skill Name",
      getSortProperty: item => item.get("skillName"),
    },
    {
      xs: true,
      title: "Group",
      getSortProperty: item => item.get("skillGroupName"),
      filter: {
        defaultText: "All groups",
      },
    },
    {
      filter: {
        defaultText: "All types",
      },
      xs: true,
      title: "Type",
      getSortProperty: item => item.get("skillTypeName"),
    },
    {
      xs: true,
      title: "Expires",
      getSortProperty: item =>
        formatDate(item.get("expirationDate"), Format.date),
    },
    {
      xs: true,
      title: "Criticality",
      getSortProperty: item => criticalityToString(item.get("criticality")),
    },
    {
      xs: true,
      title: "",
      component: Grid,
      container: true,
      justify: "flex-end",
      getSortProperty: item => item.get("competencyStatus"),
      getValue: item => (
        <CompetencyStatusChip status={item.get("competencyStatus")} />
      ),
      filter: {
        label: "Status",
        defaultText: "All",
        getFilterText: filter => statusToTooltipText(filter),
      },
    },
  ];

  getKey = course => course.get("skillId");

  render() {
    const { dataState, employeeRoleSkills } = this.props;

    return (
      <CrudBasePage
        title="Skills"
        items={employeeRoleSkills}
        dataState={dataState}
        SortableListProps={{
          dataState,
          Icon: ICONS.SKILL,
          getKey: this.getKey,
          columns: this.columns,
        }}
      />
    );
  }
}

export default compose(
  connect(
    (initialState, { employeeId, roleId }) => {
      const selector = getEmployeeRoleSkillsByRoleSelector(roleId);

      return state => ({
        employeeRoleSkills: selector(state, employeeId),
        dataState: getActionDataStateFromState(
          state,
          ACTIONS.GET_EMPLOYEE_ROLE_SKILLS_GROUPED_BY_ROLE,
        ),
      });
    },
    {
      dispatchOnLoad: getEmployeeRoleSkillList,
    },
  ),
)(EmployeeRoleSkillsContainer);
