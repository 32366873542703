import { compose } from "redux";
import React, { useMemo } from "react";
import { Grid } from "@material-ui/core";

import { Table } from "altus-ui-components";

import { EMPTY_MAP } from "app/app.constants";

const ExperienceRecordCategoryReport = ({
  category = EMPTY_MAP,
  recordItems = EMPTY_MAP,
}) => {
  const columns = useMemo(
    () => [
      {
        xs: true,
        id: categoryItem => categoryItem.get("experienceItemId"),
        Header: category.get("itemsTitle"),
        accessor: categoryItem => categoryItem.get("name"),
      },
      ...category
        .get("counters")
        .toJS()
        .map(counter => ({
          xs: 1,
          id: "CounterId_" + counter.experienceCategoryCounterId,
          Header: counter.name,
          Cell: ({ row }) => {
            const counterValue =
              recordItems
                .filter(
                  ri =>
                    ri.get("experienceItemId").toString() ===
                      row.original.get("experienceItemId").toString() &&
                    ri.get("experienceCategoryCounterId").toString() ===
                      counter.experienceCategoryCounterId.toString(),
                )
                .first()
                ?.get("count") ?? 0;

            return counterValue;
          },
        })),
    ],
    [category, recordItems],
  );

  return (
    <Grid container item>
      <Table
        stickyHeader
        columns={columns}
        useGlobalFilter={false}
        noItemsMessage="No records"
        items={category.get("items").toList()}
      />
    </Grid>
  );
};

export default compose(React.memo)(ExperienceRecordCategoryReport);
