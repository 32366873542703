import { compose } from "redux";
import { Link } from "react-router-dom";
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Tooltip } from "@material-ui/core";

import routePaths from "app/routePaths";
import { EMPTY_SET, ICONS } from "app/app.constants";
import GapReportHeaderCell from "features/components/GapReport/GapReportHeaderCell";
import GapReportRequirementHeaderCell from "features/components/GapReport/GapReportRequirementHeaderCell";

class ServiceGapReportHeader extends PureComponent {
  render() {
    const { classes, services, serviceCourses, serviceSkills } = this.props;

    return (
      <Grid item container wrap="nowrap" className={classes.root}>
        {services.map(service => {
          const serviceId = service.get("serviceId").toString();

          return (
            <React.Fragment key={serviceId}>
              <Tooltip title={service.get("name")}>
                <GapReportHeaderCell
                  target="_blank"
                  component={Link}
                  to={`${routePaths.hrPortal}/services/${service.get(
                    "serviceId",
                  )}`}
                >
                  <Typography
                    noWrap
                    variant="subtitle2"
                    className={classes.headerText}
                  >
                    {service.get("name")}
                  </Typography>
                </GapReportHeaderCell>
              </Tooltip>

              {serviceCourses.get(serviceId, EMPTY_SET).map(serviceCourse => (
                <GapReportRequirementHeaderCell
                  Icon={ICONS.COURSE}
                  name={serviceCourse.get("courseName")}
                  key={serviceCourse.get("serviceCourseId")}
                  title={`${serviceCourse.get(
                    "courseName",
                  )} (${serviceCourse.get("courseTypeName")})`}
                  to={`${routePaths.hrPortal}/courses/${serviceCourse.get(
                    "courseId",
                  )}`}
                />
              ))}

              {serviceSkills.get(serviceId, EMPTY_SET).map(serviceSkill => (
                <GapReportRequirementHeaderCell
                  Icon={ICONS.SKILL}
                  name={serviceSkill.get("skillName")}
                  key={serviceSkill.get("serviceNameId")}
                  title={`${serviceSkill.get("skillName")} (${serviceSkill.get(
                    "skillTypeName",
                  )})`}
                  to={`${routePaths.hrPortal}/skills/${serviceSkill.get(
                    "skillId",
                  )}`}
                />
              ))}
            </React.Fragment>
          );
        })}
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    top: 0,
    zIndex: 3,
    position: "sticky",
    height: theme.gapReport.header.height,
    borderTop: theme.gapReport.defaultBorder,
    borderRight: theme.gapReport.defaultBorder,
    backgroundColor: theme.palette.background.default,
  },
  headerText: {
    transform: "rotate(-90deg)",
    minWidth: theme.gapReport.header.height - theme.spacing(2),
    maxWidth: theme.gapReport.header.height - theme.spacing(2),
  },
});

export default compose(withStyles(styles))(ServiceGapReportHeader);
