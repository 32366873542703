import { Map } from "immutable";

import { ACTIONS, EMPTY_LIST } from "app/app.constants";

import { TrainingAndCompetencyEmployeeMapper } from "app/app.mappers";

const initialState = Map({
  employees: EMPTY_LIST,
});

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_TRAINING_AND_COMPETENCY_REPORT: {
      return initialState;
    }

    case ACTIONS.GET_TRAINING_AND_COMPETENCY_REPORT: {
      if (error) return state;
      return state.set(
        "employees",
        Map(
          payload.trainingAndCompetencyEmployees.map(employee => [
            employee.employeeId.toString(),
            TrainingAndCompetencyEmployeeMapper.from(employee),
          ]),
        ),
      );
    }

    default:
      return state;
  }
};
