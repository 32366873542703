import Agent from "infrastructure/agent";
import contentDisposition from "content-disposition";

import {
  EmployeeEducationMapper,
  WorkHistoryMapper,
  EmployeeSkillMapper,
} from "app/app.mappers";
import appService from "services/app.service";

/**
 * Employee
 */
const getEmployees = filter => Agent.get(`/employee`, filter);

const getMyTeamEmployeesByUser = () => Agent.get(`/employee/myteam`);

const getEmployee = employeeId => Agent.get(`/employee/${employeeId}`);

const getCurrentEmployee = () => Agent.get(`/employee/current`);

/**
 * Employee Skill
 */
const getEmployeeRoleSkillList = employeeId =>
  Agent.get(`/employee/${employeeId}/skill/byrole`);

const getEmployeeSkill = (employeeId, skillId) =>
  Agent.get(`/employee/${employeeId}/skill/${skillId}`);

const createEmployeeSkill = (employeeId, employeeSkill) =>
  Agent.post(
    `/employee/${employeeId}/skill`,
    EmployeeSkillMapper.to(employeeSkill),
  );

const getMyTeamFileEvidencesNeedingApproval = () =>
  Agent.get(`/employee/myteam/skill/evidence/requiresapproval`);

const getEmployeeSkillFileEvidences = (employeeId, skillId) =>
  Agent.get(`/employee/${employeeId}/skill/${skillId}/evidence/file`);

const getEmployeeSkillEvidences = (employeeId, skillId) =>
  Agent.get(`/employee/${employeeId}/skill/${skillId}/evidence`);

const deleteEmployeeSkill = (employeeId, skillId) =>
  Agent.delete(`/employee/${employeeId}/skill/${skillId}`);

/**
 * Employee Role
 */
const getEmployeeRoles = employeeId =>
  Agent.get(`/employee/${employeeId}/role`);

const getAvailableEmployeeRoles = employeeId =>
  Agent.get(`/employee/${employeeId}/available-roles`);

const createEmployeeRoles = (employeeId, roleIdList) =>
  Agent.post(`/employee/${employeeId}/assign-roles`, roleIdList);

const toggleRoleEmployeeCoursesOverride = (employeeId, roleId) =>
  Agent.post(`/employee/${employeeId}/role/${roleId}/toggle-override-courses`);

const toggleRoleEmployeeSkillsOverride = (employeeId, roleId) =>
  Agent.post(`/employee/${employeeId}/role/${roleId}/toggle-override-skills`);

const deleteEmployeeRole = (employeeId, roleId) =>
  Agent.delete(`/employee/${employeeId}/role/${roleId}`);

/**
 * Employee Experience
 */
const getEmployeeExperienceRecords = employeeId =>
  Agent.get(`/employee/${employeeId}/experience/record`);

const getEmployeeExperienceRecordsForReport = employeeId =>
  Agent.get(`/employee/${employeeId}/experience/record/report`);

/**
 * Employee Education
 */
const getEmployeeEducationItems = employeeId =>
  Agent.get(`/employee/${employeeId}/education`);

const getEmployeeEducationItem = (employeeId, educationId) =>
  Agent.get(`/employee/${employeeId}/education/${educationId}`);

const createEmployeeEducationItem = (employeeId, education) =>
  Agent.post(`/employee/${employeeId}/education`, education);

const createAndApproveEmployeeEducationItem = (employeeId, education) =>
  Agent.post(`/employee/${employeeId}/education/approve`, education);

const updateEmployeeEducationItem = (employeeId, educationId, education) =>
  Agent.put(`/employee/${employeeId}/education/${educationId}`, education);

const updateEmployeeEducationApproval = (employeeId, educationId, education) =>
  Agent.put(
    `/employee/${employeeId}/education/${educationId}/approval`,
    EmployeeEducationMapper.to(education),
  );

const removeEmployeeEducationApproval = (employeeId, educationId) =>
  Agent.delete(`/employee/${employeeId}/education/${educationId}/approval`);

const deleteEmployeeEducationItem = (employeeId, educationId) =>
  Agent.delete(`/employee/${employeeId}/education/${educationId}`);

/**
 * Employee Education File
 */

const uploadEducationFile = (employeeId, educationId, file) => {
  return Agent.file.post(
    `/employee/${employeeId}/education/${educationId}/files`,
    file,
    {},
  );
};

const previewEducationFile = (employeeId, educationId, fileId) =>
  appService.previewFile(
    `/employee/${employeeId}/education/${educationId}/files/${fileId}`,
  );

const deleteEducationFile = (employeeId, educationId, fileId) =>
  Agent.delete(
    `/employee/${employeeId}/education/${educationId}/files/${fileId}`,
  );

/**
 * Work History File
 */

const uploadWorkHistoryFile = (employeeId, workHistoryId, file) => {
  return Agent.file.post(
    `/employee/${employeeId}/workhistory/${workHistoryId}/files`,
    file,
    {},
  );
};

const previewWorkHistoryFile = (employeeId, workHistoryId, fileId) =>
  appService.previewFile(
    `/employee/${employeeId}/workhistory/${workHistoryId}/files/${fileId}`,
  );

const deleteWorkHistoryFile = (employeeId, workHistoryId, fileId) =>
  Agent.delete(
    `/employee/${employeeId}/workhistory/${workHistoryId}/files/${fileId}`,
  );

/*
 * Employee Work History
 */
const createWorkHistory = (employeeId, workHistory) =>
  Agent.post(`/employee/${employeeId}/workhistory`, workHistory);
const createAndApproveWorkHistory = (employeeId, workHistory) =>
  Agent.post(`/employee/${employeeId}/workhistory/approve `, workHistory);
const deleteWorkHistory = (employeeId, workHistoryId) =>
  Agent.delete(`/employee/${employeeId}/workhistory/${workHistoryId}`);
const getWorkHistory = employeeId =>
  Agent.get(`/employee/${employeeId}/workhistory`);
const updateWorkHistory = (employeeId, workHistoryId, workHistory) =>
  Agent.put(
    `/employee/${employeeId}/workhistory/${workHistoryId}`,
    workHistory,
  );
const updateWorkHistoryApproval = (employeeId, workHistoryId, workHistory) =>
  Agent.put(
    `/employee/${employeeId}/workhistory/${workHistoryId}/approval`,
    WorkHistoryMapper.to(workHistory),
  );
const removeWorkHistoryApproval = (employeeId, workHistoryId) =>
  Agent.delete(`/employee/${employeeId}/workhistory/${workHistoryId}/approval`);

/**
 * Employee CV
 */
const generateEmployeeCV = (employeeId, cvOptions) =>
  Agent.maybeRefreshToken().then(request =>
    request
      .post(`${Agent.config.apiRoot}/employee/${employeeId}/cv`)
      .send(cvOptions)
      .responseType("blob")
      .then(({ headers, body: file }) => {
        const { parameters } = contentDisposition.parse(
          headers["content-disposition"],
        );

        return [file, parameters.filename];
      }),
  );

const generateEmployeesCVs = cvOptions =>
  Agent.maybeRefreshToken().then(request =>
    request
      .post(`${Agent.config.apiRoot}/employee/cv`)
      .send(cvOptions)
      .responseType("blob")
      .then(({ headers, body: file }) => {
        const { parameters } = contentDisposition.parse(
          headers["content-disposition"],
        );

        return [file, parameters.filename];
      }),
  );

const getEmployeeCoursesAttendancesNeedingApproval = () =>
  Agent.get(`/employee/course/attendances/requiresapproval`);

const getEmployeeEducationRequiringApproval = () =>
  Agent.get(`/employee/education/requiresapproval`);

const getEmployeeWorkHistoryRequiringApproval = () =>
  Agent.get(`/employee/workhistory/requiresapproval`);

const getEmployeesByCourse = courseId =>
  Agent.get(`/employee/bycourse/${courseId}`);

const getEmployeesBySkill = skillId =>
  Agent.get(`/employee/byskill/${skillId}`);

/**
 * Employee Skill Evidence File
 */

const uploadEmployeeSkillFileEvidence = (
  employeeId,
  skillId,
  evidenceId,
  file,
) => {
  return Agent.file.post(
    `/employee/${employeeId}/skill/${skillId}/evidence/${evidenceId}/file`,
    file,
    {},
  );
};

const uploadAndApproveEmployeeSkillFileEvidence = (
  employeeId,
  skillId,
  evidenceId,
  file,
) => {
  return Agent.file.post(
    `/employee/${employeeId}/skill/${skillId}/evidence/${evidenceId}/file/approve`,
    file,
    {},
  );
};

const previewEmployeeSkillFileEvidence = (
  employeeId,
  skillId,
  fileEvidenceId,
) =>
  appService.previewFile(
    `/employee/${employeeId}/skill/${skillId}/evidence/file/${fileEvidenceId}`,
  );

const updateEmployeeSkillFileEvidenceApproval = (
  employeeId,
  skillId,
  employeeSkillFileEvidenceId,
  employeeSkillFileEvidence,
) =>
  Agent.put(
    `/employee/${employeeId}/skill/${skillId}/evidence/file/${employeeSkillFileEvidenceId}/approval`,
    employeeSkillFileEvidence,
  );

const removeEmployeeSkillFileEvidenceApproval = (
  employeeId,
  skillId,
  employeeSkillFileEvidenceId,
) =>
  Agent.delete(
    `/employee/${employeeId}/skill/${skillId}/evidence/file/${employeeSkillFileEvidenceId}/approval`,
  );

const deleteEmployeeSkillFileEvidence = (
  employeeId,
  skillId,
  employeeSkillFileEvidenceId,
) =>
  Agent.delete(
    `/employee/${employeeId}/skill/${skillId}/evidence/file/${employeeSkillFileEvidenceId}`,
  );

const deleteEmployeeSkillEvidence = (employeeId, skillId, skillEvidenceId) =>
  Agent.delete(
    `/employee/${employeeId}/skill/${skillId}/evidence/${skillEvidenceId}`,
  );

const getEmployeeServices = employeeId =>
  Agent.get(`/employee/${employeeId}/services`);

export default {
  generateEmployeeCV,
  getEmployee,
  getEmployees,
  getEmployeeRoles,
  toggleRoleEmployeeCoursesOverride,
  toggleRoleEmployeeSkillsOverride,
  getAvailableEmployeeRoles,
  getMyTeamEmployeesByUser,
  createEmployeeRoles,
  getCurrentEmployee,
  deleteEmployeeRole,
  getEmployeeExperienceRecords,
  getEmployeeEducationItems,
  getEmployeeEducationItem,
  createEmployeeEducationItem,
  createAndApproveEmployeeEducationItem,
  updateEmployeeEducationItem,
  updateEmployeeEducationApproval,
  removeEmployeeEducationApproval,
  deleteEmployeeEducationItem,
  createWorkHistory,
  createAndApproveWorkHistory,
  deleteWorkHistory,
  getWorkHistory,
  updateWorkHistory,
  updateWorkHistoryApproval,
  removeWorkHistoryApproval,
  getEmployeeRoleSkillList,
  generateEmployeesCVs,
  getEmployeeEducationRequiringApproval,
  getEmployeeWorkHistoryRequiringApproval,
  getEmployeesByCourse,
  uploadEducationFile,
  previewEducationFile,
  deleteEducationFile,
  uploadWorkHistoryFile,
  previewWorkHistoryFile,
  deleteWorkHistoryFile,
  createEmployeeSkill,
  uploadEmployeeSkillFileEvidence,
  uploadAndApproveEmployeeSkillFileEvidence,
  previewEmployeeSkillFileEvidence,
  getMyTeamFileEvidencesNeedingApproval,
  updateEmployeeSkillFileEvidenceApproval,
  removeEmployeeSkillFileEvidenceApproval,
  getEmployeeSkill,
  deleteEmployeeSkill,
  getEmployeesBySkill,
  getEmployeeSkillFileEvidences,
  getEmployeeSkillEvidences,
  deleteEmployeeSkillEvidence,
  deleteEmployeeSkillFileEvidence,
  getEmployeeCoursesAttendancesNeedingApproval,
  getEmployeeExperienceRecordsForReport,
  getEmployeeServices,
};
