import { compose } from "redux";
import { connect } from "react-redux";
import debounce from "lodash/debounce";
import React, { PureComponent } from "react";
import { reduxForm } from "redux-form/immutable";

import {
  Grid,
  Button,
  Tooltip,
  IconButton,
  Typography,
  withStyles,
} from "@material-ui/core";

import {
  COURSE_FORM,
  ADD_PREREQUISITE_COURSE_FORM,
  ADD_PREREQUISITE_COURSE_MODAL_ID,
} from "features/competence.constants";

import {
  getCourseFromState,
  getPrerequisiteCoursesFromState,
} from "features/competence.selectors";

import {
  updateCourse,
  courseDetailsOnLoad,
  addPrerequisiteCourseOnSubmit,
  getAvailablePrerequisiteCourses,
  removePrerequisiteCourseAskConfirmation,
  courseFormOnChange,
} from "features/competence.actions";

import SimpleFormModal from "app/components/SimpleFormModal";
import withToggleModal from "app/components/withToggleModal";
import CourseDetails from "features/components/CourseDetails";
import { ACTIONS, ICONS } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import CourseForm from "features/hr/courses/components/CourseForm";
import CustomCourseInfo from "features/components/CustomCourseInfo";
import AddPrerequisiteCourseForm from "features/components/AddPrerequisiteCourseForm";
import { CourseMapper } from "app/app.mappers";
import { BasePage } from "altus-ui-components";

class CourseDetailsContainer extends PureComponent {
  componentDidMount() {
    const { dispatchCourseDetailsOnLoad, courseId } = this.props;
    dispatchCourseDetailsOnLoad(courseId);
  }

  render() {
    const {
      classes,
      course,
      courseId,
      dataState,
      breadcrumb,
      dispatchToggleModal,
      prerequisiteCourses,
      dispatchRemovePrerequisiteCourse,
      dispatchAddPrerequisiteCourseOnSubmit,
      dispatchGetAvailablePrerequisiteCourses,
    } = this.props;

    return (
      <>
        <BasePage title={breadcrumb} dataState={dataState}>
          <Grid container item spacing={4}>
            <Grid container item xs={12} md={8}>
              <CourseForm course={course} validateCourseTypeChange />
            </Grid>
            {course.get("isCustom") && (
              <>
                <Grid container item xs={12} md={4}>
                  <CustomCourseInfo />
                </Grid>
                <Grid container justify="space-between" item xs={12} md={8}>
                  <Typography variant="h5">Prerequisites</Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      dispatchToggleModal({
                        modalId: ADD_PREREQUISITE_COURSE_MODAL_ID,
                      })
                    }
                  >
                    Add
                  </Button>
                </Grid>
                {prerequisiteCourses.map(prereq => (
                  <Grid item xs={12} md={8} key={prereq.get("courseId")}>
                    <div className={classes.prerequisiteCourse}>
                      <Tooltip title="Remove">
                        <IconButton
                          className={classes.removePrerequisiteButton}
                          onClick={() =>
                            dispatchRemovePrerequisiteCourse(course, prereq)
                          }
                        >
                          <ICONS.DELETE fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <CourseDetails course={prereq} />
                    </div>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </BasePage>
        <SimpleFormModal
          modalId={ADD_PREREQUISITE_COURSE_MODAL_ID}
          Icon={ICONS.COURSE}
          form={{
            form: ADD_PREREQUISITE_COURSE_FORM.ID,
          }}
          component={AddPrerequisiteCourseForm}
          onEnter={dispatchGetAvailablePrerequisiteCourses}
          onSubmit={dispatchAddPrerequisiteCourseOnSubmit(courseId)}
          title="Add Prerequisite Course"
          submitText="Add"
        />
      </>
    );
  }
}

CourseDetailsContainer.defaultProps = {
  course: CourseMapper.initial,
};

const styles = theme => ({
  prerequisiteCourse: {
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(4),
    position: "relative",
  },
  removePrerequisiteButton: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
});

export default compose(
  connect(
    (state, { courseId }) => ({
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_COURSE,
        ACTIONS.GET_PREREQUISITE_COURSES,
        ACTIONS.REMOVE_PREREQUISITE_COURSE,
        ACTIONS.ADD_PREREQUISITE_COURSE,
      ),
      courseId: courseId,
      course: getCourseFromState(state, courseId),
      prerequisiteCourses: getPrerequisiteCoursesFromState(state, courseId),
    }),
    {
      dispatchUpdateCourse: updateCourse,
      dispatchCourseDetailsOnLoad: courseDetailsOnLoad,
      dispatchGetAvailablePrerequisiteCourses: getAvailablePrerequisiteCourses,
      dispatchAddPrerequisiteCourseOnSubmit: addPrerequisiteCourseOnSubmit,
      dispatchRemovePrerequisiteCourse: removePrerequisiteCourseAskConfirmation,
    },
  ),
  reduxForm({
    form: COURSE_FORM.ID,
    onChange: debounce(courseFormOnChange, 1000),
  }),
  withToggleModal,
  withStyles(styles),
)(CourseDetailsContainer);
