import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import {
  getSpecificationFromState,
  getSpecificationCoursesFromState,
} from "features/competence.selectors";

import SpecificationItemList from "features/specification/components/SpecificationItemList";

class SpecificationCourseListContainer extends PureComponent {
  render() {
    const { specificationCourses } = this.props;

    if (!specificationCourses.size) {
      return null;
    }

    return (
      <SpecificationItemList items={specificationCourses} {...this.props} />
    );
  }
}

export default compose(
  connect(
    (state, { specificationId }) => ({
      specification: getSpecificationFromState(state, specificationId),
      specificationCourses: getSpecificationCoursesFromState(
        state,
        specificationId,
      ),
    }),
    null,
  ),
)(SpecificationCourseListContainer);
