import { OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { CourseGroupMapper } from "app/app.mappers";

const initialState = OrderedMap();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_COURSE_GROUPS: {
      if (error) return state;

      return OrderedMap(
        payload.map(courseGroup => [
          courseGroup.courseGroupId.toString(),
          CourseGroupMapper.from(courseGroup),
        ]),
      );
    }

    case ACTIONS.GET_COURSE_GROUP:
    case ACTIONS.UPDATE_COURSE_GROUP:
    case ACTIONS.CREATE_COURSE_GROUP: {
      if (error) return state;

      return state.set(
        payload.courseGroupId.toString(),
        CourseGroupMapper.from(payload),
      );
    }

    case ACTIONS.DELETE_COURSE_GROUP: {
      if (error) return state;

      return state.delete(action.courseGroupId.toString());
    }

    default:
      return state;
  }
};
