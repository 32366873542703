import { Map, List } from "immutable";

import { ACTIONS } from "app/app.constants";
import { EmployeeRoleMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { type, payload, error } = action;

  switch (type) {
    case ACTIONS.CLEAR_ROLE_EMPLOYEES: {
      return initialState;
    }
    case ACTIONS.CREATE_ROLE_EMPLOYEE: {
      if (error) return state;

      return state.mergeDeep(
        List(payload)
          .groupBy(roleEmployee => roleEmployee.roleId.toString())
          .map(roleEmployees =>
            Map(
              roleEmployees.map(roleEmployee => [
                roleEmployee.employeeId.toString(),
                EmployeeRoleMapper.from(roleEmployee),
              ]),
            ),
          ),
      );
    }

    case ACTIONS.DELETE_ROLE_EMPLOYEE: {
      if (error) return state;

      return state.deleteIn([
        action.roleId.toString(),
        action.employeeId.toString(),
      ]);
    }

    case ACTIONS.GET_MY_TEAM_ROLE_EMPLOYEES_BY_ROLE:
    case ACTIONS.GET_ROLE_EMPLOYEES: {
      if (error) return state;

      return Map(
        payload.map(roleEmployee => [
          roleEmployee.employeeId.toString(),
          EmployeeRoleMapper.from(roleEmployee),
        ]),
      ).groupBy(roleEmployee => roleEmployee.get("roleId").toString());
    }
    default:
      return state;
  }
};
