import React from "react";
import "moment/locale/en-gb";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ConnectedRouter } from "connected-react-router/immutable";

import store from "infrastructure/store";
import history from "infrastructure/history";
import { DEFAULT_MOMENT_LOCALE } from "app/app.constants";
import ApplicationRoot from "app/components/ApplicationRoot";

ReactDOM.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils} locale={DEFAULT_MOMENT_LOCALE}>
      <ConnectedRouter history={history}>
        <ApplicationRoot />
      </ConnectedRouter>
    </MuiPickersUtilsProvider>
  </Provider>,

  document.getElementById("root"),
);
