import {
  ICONS,
  APP_ROOT,
  APP_ROLES,
  FEATURE_FLAG,
  APP_ROLES_HR_AND_ADMIN,
} from "app/app.constants";

import { OverviewContainer } from "altus-ui-components";
import { OPEN_IN, RouteContainer } from "altus-ui-components";

import RolesCatalogue from "features/hr/roles/RolesContainer";
import SkillsContainer from "features/hr/skills/SkillsContainer";
import ExperienceRecordsContainer from "features/hr/experiences/ExperienceRecordsContainer";
import ExperienceRecordRouteContainer from "features/experience/ExperienceRecordRouteContainer";
import ExperienceRecordDetailsContainer from "features/experience/ExperienceRecordDetailsContainer";
import ExperienceRecordSummaryContainer from "features/experience/ExperienceRecordSummaryContainer";
import CourseCatalogueContainer from "features/hr/courses/CoursesContainer";
import HREmployeesContainer from "features/hr/employees/HREmployeesContainer";
import CourseContainer from "features/course/CourseContainer";
import CourseDetailsContainer from "features/course/details/CourseDetailsContainer";
import CourseRolesContainer from "features/course/roles/CourseRolesContainer";
import CourseEmployeesContainer from "features/course/employees/CourseEmployeesContainer";
import RoleContainer from "features/role/RoleContainer";
import RoleDetailsContainer from "features/role/RoleDetailsContainer";
import RoleMembersContainer from "features/role/RoleMembersContainer";
import RoleCoursesContainer from "features/role/RoleCoursesContainer";
import RoleSkillsContainer from "features/role/RoleSkillsContainer";
import EmployeeContainer from "features/employee/EmployeeContainer";
import EmployeeDetailsContainer from "features/employee/details/EmployeeDetailsContainer";
import EmployeeRolesContainer from "features/employee/roles/EmployeeRolesContainer";
import EmployeeCoursesContainer from "features/employee/courses/EmployeeCoursesContainer";
import EmployeeServicesContainer from "features/employee/services/EmployeeServicesContainer";
import EmployeeEducationContainer from "features/employee/education/EmployeeEducationContainer";
import EmployeeWorkHistoryContainer from "features/employee/workHistory/EmployeeWorkHistoryContainer";
import EmployeeRoleGapReportContainer from "features/employee/reports/EmployeeRoleGapReportContainer";
import EmployeeExperienceRecordContainer from "features/components/EmployeeExperienceRecordContainer";
import EmployeeExperienceRecordsContainer from "features/employee/experienceRecords/EmployeeExperienceRecordsContainer";
import EmployeeCourseStatusContainer from "features/employee/employeeCourse/components/EmployeeCourseStatusContainer";
import EmployeeCourseDetailsContainer from "features/employee/employeeCourse/components/EmployeeCourseDetailsContainer";
import EmployeeCourseAttendanceContainer from "features/employee/employeeCourse/components/EmployeeCourseAttendanceContainer";
import EmployeeRoleStatusContainer from "features/employeeRole/status/EmployeeRoleStatusContainer";
import EmployeeRoleDetailsContainer from "features/employeeRole/role/EmployeeRoleDetailsContainer";
import EmployeeRoleCoursesContainer from "features/employeeRole/courses/EmployeeRoleCoursesContainer";
import EmployeeEmployeeRoleContainer from "features/components/EmployeeEmployeeRoleContainer";
import HROverviewContainer from "features/hr/overview/HROverviewContainer";
import UserAccessContainer from "features/settings/UserAccessContainer";
import SettingsCourseTypesContainer from "features/settings/SettingsCourseTypesContainer";
import SettingsCourseGroupsContainer from "features/settings/SettingsCourseGroupsContainer";
import SettingsSkillTypesContainer from "features/settings/SettingsSkillTypesContainer";
import SettingsSkillGroupsContainer from "features/settings/SettingsSkillGroupsContainer";
import MyPageRolesContainer from "features/myPage/roles/MyPageRolesContainer";
import MyEducationContainer from "features/myPage/education/MyEducationContainer";
import MyPageWorkHistoryContainer from "features/myPage/workHistory/MyPageWorkHistoryContainer";
import MyPageSkillsContainer from "features/myPage/skills/MyPageSkillsContainer";
import MyPageExperienceRecordsContainer from "features/myPage/experiences/MyPageExperienceRecordsContainer";
import MyPageEmployeeExperienceRecordContainer from "features/myPage/experiences/components/MyPageEmployeeExperienceRecordContainer";
import MyDetailsContainer from "features/myPage/details/MyDetailsContainer";
import MyCoursesContainer from "features/myPage/courses/MyCoursesContainer";
import MyPageEmployeeCourseContainer from "features/myPage/courses/components/MyPageEmployeeCourseContainer";
import EmployeeEmployeeCourseContainer from "features/components/EmployeeEmployeeCourseContainer";
import EmployeeSkillsContainer from "features/employee/skills/EmployeeSkillsContainer";
import MyPageEmployeeRoleContainer from "features/myPage/roles/MyPageEmployeeRoleContainer";
import MyTeamEmployeesContainer from "features/myTeam/MyTeamEmployeesContainer";
import MyTeamRoleGapReportContainer from "features/myTeam/MyTeamRoleGapReportContainer";
import MyTeamOverviewContainer from "features/myTeam/MyTeamOverviewContainer";
import HRRoleGapReportContainer from "features/hr/HRRoleGapReportContainer";
import SkillContainer from "features/skill/SkillContainer";
import SkillDetailsContainer from "features/skill/SkillDetailsContainer";
import SkillRolesContainer from "features/skill/SkillRolesContainer";
import EmployeeSkillFileEvidencesContainer from "features/employee/employeeSkill/components/EmployeeSkillFileEvidencesContainer";
import EmployeeSkillStatusContainer from "features/employee/employeeSkill/components/EmployeeSkillStatusContainer";
import EmployeeSkillDetailsContainer from "features/employee/employeeSkill/components/EmployeeSkillDetailsContainer";
import EmployeeEmployeeSkillContainer from "features/components/EmployeeEmployeeSkillContainer";
import MyPageEmployeeSkillContainer from "features/myPage/skills/components/MyPageEmployeeSkillContainer";
import RoleBreadcrumb from "app/components/Breadcrumb/RoleBreadcrumb";
import CollectionBreadcrumb from "app/components/Breadcrumb/CollectionBreadcrumb";
import CourseBreadcrumb from "app/components/Breadcrumb/CourseBreadcrumb";
import EmployeeBreadcrumb from "app/components/Breadcrumb/EmployeeBreadcrumb";
import SkillBreadcrumb from "app/components/Breadcrumb/SkillBreadcrumb";
import MyTeamSkillGapReportContainer from "features/myTeam/MyTeamSkillGapReportContainer";
import HRSkillGapReportContainer from "features/hr/HRSkillGapReportContainer";
import MyPageOverviewContainer from "features/myPage/MyPageOverviewContainer";
import MyPageApprovals from "features/myPage/approvals/MyPageApprovals";
import SettingsOverviewContainer from "features/settings/SettingsOverviewContainer";
import SkillEmployeesContainer from "features/skill/SkillEmployeesContainer";
import SkillCollectionContainer from "features/skill/SkillCollectionContainer";
import EmployeeRoleSkillsContainer from "features/employeeRole/skills/EmployeeRoleSkillsContainer";
import MyTeamRolesContainer from "features/myTeam/MyTeamRolesContainer";
import MyTeamRoleDetailsContainer from "features/myTeam/MyTeamRoleDetailsContainer";
import MyTeamRoleMembersContainer from "features/myTeam/MyTeamRoleMembersContainer";
import MyTeamRoleCoursesContainer from "features/myTeam/MyTeamRoleCoursesContainer";
import MyTeamRoleSkillsContainer from "features/myTeam/MyTeamRoleSkillsContainer";
import MyTeamRoleContainer from "features/myTeam/MyTeamRoleContainer";
import SpecificationsContainer from "features/hr/specifications/SpecificationsContainer";
import SpecificationBreadcrumb from "app/components/Breadcrumb/SpecificationBreadcrumb";
import SpecificationContainer from "features/specification/SpecificationContainer";
import SpecificationDetailsContainer from "features/specification/SpecificationDetailsContainer";
import SpecificationCoursesContainer from "features/specification/SpecificationCoursesContainer";
import SpecificationSkillsContainer from "features/specification/SpecificationSkillsContainer";
import SpecificationGapReportContainer from "features/specification/SpecificationGapReportContainer";
import HRCourseGapReportContainer from "features/hr/HRCourseGapReportContainer";
import CollectionsContainer from "features/hr/collections/CollectionsContainer";
import CollectionContainer from "features/hr/collections/CollectionContainer";
import CollectionDetailsContainer from "features/hr/collections/CollectionDetailsContainer";
import CollectionCollectionContainer from "features/hr/collections/CollectionCollectionContainer";
import CollectionConditionContainer from "features/hr/collections/CollectionConditionContainer";
import CourseCollectionContainer from "features/course/CourseCollectionContainer";
import MyTeamCourseGapReportContainer from "features/myTeam/MyTeamCourseGapReportContainer";
import ApprovalContainer from "features/hr/approval/ApprovalContainer";
import ServicesContainer from "features/hr/services/ServicesContainer";
import ServiceBreadcumb from "app/components/Breadcrumb/ServiceBreadcumb";
import ServiceContainer from "features/service/ServiceContainer";
import ServiceDetailsContainer from "features/service/ServiceDetailsContainer";
import ServiceCoursesContainer from "features/service/ServiceCoursesContainer";
import ServiceSkillsContainer from "features/service/ServiceSkillsContainer";
import ServiceGapReportContainer from "features/service/ServiceGapReportContainer";
import ExperienceRecordBreadcrumb from "app/components/Breadcrumb/ExperienceRecordBreadcrumb";
import EmployeeExperienceRecordsReportContainer from "features/employee/reports/EmployeeExperienceRecordsReportContainer";
import TrainingHistoryReport from "features/reports/trainingHistory/TrainingHistoryReport";
import TrainingAndCompetencyReport from "features/reports/trainingAndCompetency/TrainingAndCompetencyReport";
import CourseExpiryReport from "features/reports/courseExpiryReport/CourseExpiryReport";
import CourseTrainingHistoryReport from "features/reports/courseTrainingHistory/CourseTrainingHistoryReport";
import OutstandingTrainingHistoryReport from "features/reports/outstandingTrainingHistory/OutstandingTrainingHistoryReport";

export const ROUTE_KEYS = {
  ROLES: "roles",
  COURSES: "courses",
  SKILL_GROUPS: "skillGroups",
  SKILL_TYPES: "skillTypes",
  COURSE_GROUPS: "courseGroups",
  COURSE_TYPES: "courseTypes",
  SKILLS: "skills",
  EXPERIENCES: "experiences",
  EMPLOYEES: "employees",
  APPROVALS: "pendingApproval",
  EDUCATION: "education",
  WORK_HISTORY: "workHistory",
  EMPLOYEE_EXPERIENCE_RECORDS: "experiences",
  ROLE_GAP_REPORT: "role-gap-report",
  REPORTS: "reports",
  HR_SETTINGS: "hrSettings",
  SERVICES: "services",
  SPECIFICATIONS: "specifications",
  COLLECTIONS: "collections",
  ROLE_EMPLOYEES: "roleEmployees",
  ROLE_COURSES: "roleCourses",
  ROLE_SKILLS: "roleSkills",
  COURSE_ROLES: "courseRoles",
  COURSE_MEMBERS: "courseEmployees",
  SKILL_ROLES: "skillRoles",
  SKILL_MEMBERS: "skillEmployees",
  SPECIFICATION_SKILL: "specificationSkill",
  SPECIFICATION_COURSE: "specificationCourse",
  SERVICE_SKILL: "serviceSkill",
  SERVICE_COURSE: "serviceCourse",
  EMPLOYEE_COURSE_ATTENDANCE: "employeeCourseAttendance",
  EMPLOYEE_EXPERIENCE_RECORDS_GAP_REPORT: "experience-report",
};

const createRoleSubRoutes = root => [
  {
    hidden: true,
    Icon: ICONS.ROLE,
    breadcrumb: RoleBreadcrumb,
    path: `${root}/roles/:roleId`,
    component: RoleContainer,
    routes: [
      {
        exact: true,
        breadcrumb: "Details",
        path: `${root}/roles/:roleId`,
        component: RoleDetailsContainer,
      },
      {
        breadcrumb: "Skills",
        key: ROUTE_KEYS.ROLE_SKILLS,
        featureFlag: FEATURE_FLAG.SKILLS,
        path: `${root}/roles/:roleId/skills`,
        component: RoleSkillsContainer,
      },
      {
        breadcrumb: "Courses",
        key: ROUTE_KEYS.ROLE_COURSES,
        path: `${root}/roles/:roleId/courses`,
        component: RoleCoursesContainer,
      },
      {
        breadcrumb: "Members",
        key: ROUTE_KEYS.ROLE_EMPLOYEES,
        path: `${root}/roles/:roleId/members`,
        component: RoleMembersContainer,
      },
    ],
  },
];

const createCourseSubRoutes = root => [
  {
    exact: true,
    breadcrumb: "Details",
    path: `${root}/courses/:courseId`,
    component: CourseDetailsContainer,
  },
  {
    breadcrumb: "Roles",
    key: ROUTE_KEYS.COURSE_ROLES,
    path: `${root}/courses/:courseId/roles`,
    component: CourseRolesContainer,
  },
  {
    breadcrumb: "Members",
    key: ROUTE_KEYS.COURSE_MEMBERS,
    path: `${root}/courses/:courseId/members`,
    component: CourseEmployeesContainer,
  },
  {
    breadcrumb: "Collection",
    path: `${root}/courses/:courseId/collection`,
    component: CourseCollectionContainer,
    featureFlag: FEATURE_FLAG.COLLECTIONS,
  },
];

const createSkillSubRoutes = root => [
  {
    exact: true,
    breadcrumb: "Details",
    path: `${root}/skills/:skillId`,
    component: SkillDetailsContainer,
  },
  {
    breadcrumb: "Roles",
    key: ROUTE_KEYS.SKILL_ROLES,
    path: `${root}/skills/:skillId/roles`,
    component: SkillRolesContainer,
  },
  {
    breadcrumb: "Members",
    key: ROUTE_KEYS.SKILL_MEMBERS,
    path: `${root}/skills/:skillId/members`,
    component: SkillEmployeesContainer,
  },
  {
    breadcrumb: "Collection",
    path: `${root}/skills/:skillId/collection`,
    component: SkillCollectionContainer,
    featureFlag: FEATURE_FLAG.COLLECTIONS,
  },
];

const createExperienceSubRoutes = root => [
  {
    exact: true,
    breadcrumb: "Summary",
    redirectPath: `${root}`,
    path: `${root}/experiences/:experienceRecordId/employee/:employeeId`,
    component: ExperienceRecordSummaryContainer,
  },
  {
    exact: true,
    breadcrumb: "Record",
    path: `${root}/experiences/:experienceRecordId/employee/:employeeId/record`,
    component: ExperienceRecordDetailsContainer,
  },
];

const createEmployeeExperienceSubRoutes = root => [
  {
    exact: true,
    breadcrumb: "Summary",
    redirectPath: `${root}/experiences`,
    path: `${root}/experiences/:experienceRecordId`,
    component: ExperienceRecordSummaryContainer,
  },
  {
    exact: true,
    breadcrumb: "Record",
    path: `${root}/experiences/:experienceRecordId/record`,
    component: ExperienceRecordDetailsContainer,
  },
];

const createApprovalExperienceSubRoutes = root => [
  {
    exact: true,
    breadcrumb: "Summary",
    path: `${root}/approvals/:experienceRecordId/employee/:employeeId`,
    component: ExperienceRecordSummaryContainer,
  },
  {
    exact: true,
    breadcrumb: "Record",
    path: `${root}/approvals/:experienceRecordId/employee/:employeeId/record`,
    source: ROUTE_KEYS.APPROVALS,
    component: ExperienceRecordDetailsContainer,
  },
];

const createEmployeeCourseRoutes = root => [
  {
    exact: true,
    breadcrumb: "Status",
    component: EmployeeCourseStatusContainer,
    path: `${root}/courses/:courseId`,
  },
  {
    breadcrumb: "Details",
    component: EmployeeCourseDetailsContainer,
    path: `${root}/courses/:courseId/details`,
  },
  {
    exact: true,
    breadcrumb: "Attendance",
    key: ROUTE_KEYS.EMPLOYEE_COURSE_ATTENDANCE,
    component: EmployeeCourseAttendanceContainer,
    path: `${root}/courses/:courseId/attendance`,
  },
  {
    breadcrumb: "Collection",
    key: ROUTE_KEYS.COLLECTIONS,
    path: `${root}/courses/:courseId/collection`,
    component: CourseCollectionContainer,
    accessLevel: APP_ROLES_HR_AND_ADMIN,
    featureFlag: FEATURE_FLAG.COLLECTIONS,
  },
];

const createEmployeeSkillRoutes = root => [
  {
    exact: true,
    breadcrumb: "Status",
    component: EmployeeSkillStatusContainer,
    path: `${root}/skills/:skillId`,
  },
  {
    breadcrumb: "Details",
    component: EmployeeSkillDetailsContainer,
    path: `${root}/skills/:skillId/details`,
  },
  {
    breadcrumb: "Evidences",
    component: EmployeeSkillFileEvidencesContainer,
    path: `${root}/skills/:skillId/file-evidences`,
  },
  {
    breadcrumb: "Collection",
    key: ROUTE_KEYS.COLLECTIONS,
    path: `${root}/skills/:skillId/collection`,
    component: SkillCollectionContainer,
    accessLevel: APP_ROLES_HR_AND_ADMIN,
    featureFlag: FEATURE_FLAG.COLLECTIONS,
  },
];

const createEmployeeRoleRoutes = root => [
  {
    exact: true,
    breadcrumb: "Status",
    component: EmployeeRoleStatusContainer,
    path: `${root}/roles/:roleId`,
  },
  {
    breadcrumb: "Details",
    component: EmployeeRoleDetailsContainer,
    path: `${root}/roles/:roleId/details`,
  },
  {
    breadcrumb: "Skills",
    key: ROUTE_KEYS.ROLE_SKILLS,
    component: EmployeeRoleSkillsContainer,
    path: `${root}/roles/:roleId/skills`,
    featureFlag: FEATURE_FLAG.SKILLS,
  },
  {
    breadcrumb: "Courses",
    key: ROUTE_KEYS.ROLE_COURSES,
    component: EmployeeRoleCoursesContainer,
    path: `${root}/roles/:roleId/courses`,
  },
];

const createEmployeeRoutes = root => [
  {
    exact: true,
    breadcrumb: "Details",
    component: EmployeeDetailsContainer,
    path: `${root}/employees/:employeeId`,
  },
  {
    component: RouteContainer,
    breadcrumb: "Roles",
    key: ROUTE_KEYS.ROLES,
    path: `${root}/employees/:employeeId/roles`,
    routes: [
      {
        exact: true,
        breadcrumb: "Roles",
        component: EmployeeRolesContainer,
        path: `${root}/employees/:employeeId/roles`,
      },
      {
        hidden: true,
        Icon: ICONS.ROLE,
        breadcrumb: RoleBreadcrumb,
        component: EmployeeEmployeeRoleContainer,
        path: `${root}/employees/:employeeId/roles/:roleId`,
        routes: createEmployeeRoleRoutes(`${root}/employees/:employeeId`),
      },
    ],
  },
  {
    key: ROUTE_KEYS.SKILLS,
    breadcrumb: "Skills",
    component: RouteContainer,
    path: `${root}/employees/:employeeId/skills`,
    featureFlag: FEATURE_FLAG.SKILLS,
    routes: [
      {
        exact: true,
        breadcrumb: "Skills",
        Icon: ICONS.SKILL,
        component: EmployeeSkillsContainer,
        path: `${root}/employees/:employeeId/skills`,
      },
      {
        hidden: true,
        breadcrumb: SkillBreadcrumb,
        component: EmployeeEmployeeSkillContainer,
        path: `${root}/employees/:employeeId/skills/:skillId`,
        routes: createEmployeeSkillRoutes(`${root}/employees/:employeeId`),
      },
    ],
  },
  {
    breadcrumb: "Courses",
    component: RouteContainer,
    key: ROUTE_KEYS.COURSES,
    path: `${root}/employees/:employeeId/courses`,
    routes: [
      {
        exact: true,
        Icon: ICONS.COURSE,
        breadcrumb: "Courses",
        component: EmployeeCoursesContainer,
        path: `${root}/employees/:employeeId/courses`,
      },
      {
        hidden: true,
        Icon: ICONS.COURSE,
        breadcrumb: CourseBreadcrumb,
        component: EmployeeEmployeeCourseContainer,
        path: `${root}/employees/:employeeId/courses/:courseId`,
        routes: createEmployeeCourseRoutes(`${root}/employees/:employeeId`),
      },
    ],
  },
  {
    key: ROUTE_KEYS.SERVICES,
    breadcrumb: "Services",
    component: EmployeeServicesContainer,
    path: `${root}/employees/:employeeId/services`,
    featureFlag: FEATURE_FLAG.SERVICES,
  },
  {
    key: ROUTE_KEYS.EDUCATION,
    component: EmployeeEducationContainer,
    breadcrumb: "Education",
    path: `${root}/employees/:employeeId/education`,
  },
  {
    key: ROUTE_KEYS.WORK_HISTORY,
    component: EmployeeWorkHistoryContainer,
    breadcrumb: "Work History",
    path: `${root}/employees/:employeeId/work-history`,
  },
  {
    key: ROUTE_KEYS.ROLE_GAP_REPORT,
    component: EmployeeRoleGapReportContainer,
    breadcrumb: "Role Gap",
    featureFlag: FEATURE_FLAG.EMPLOYEE_ROLE_GAP,
    accessLevel: APP_ROLES_HR_AND_ADMIN,
    path: `${root}/employees/:employeeId/role-gap-report`,
  },
  {
    key: ROUTE_KEYS.EMPLOYEE_EXPERIENCE_RECORDS,
    breadcrumb: "Experience Records",
    component: RouteContainer,
    path: `${root}/employees/:employeeId/experiences`,
    featureFlag: FEATURE_FLAG.EXPERIENCES,
    routes: [
      {
        exact: true,
        Icon: ICONS.EXPERIENCES,
        breadcrumb: "Experience Records",
        component: EmployeeExperienceRecordsContainer,
        path: `${root}/employees/:employeeId/experiences`,
      },
      {
        hidden: true,
        Icon: ICONS.EXPERIENCES,
        breadcrumb: "Experience",
        component: EmployeeExperienceRecordContainer,
        path: `${root}/employees/:employeeId/experiences/:experienceRecordId`,
        routes: createEmployeeExperienceSubRoutes(
          `${root}/employees/:employeeId`,
        ),
      },
    ],
  },
  {
    key: ROUTE_KEYS.EMPLOYEE_EXPERIENCE_RECORDS_GAP_REPORT,
    component: EmployeeExperienceRecordsReportContainer,
    breadcrumb: "Experience Report",
    accessLevel: APP_ROLES_HR_AND_ADMIN,
    path: `${root}/employees/:employeeId/experience-report`,
    featureFlag: FEATURE_FLAG.EXPERIENCES,
  },
];

const myTeamReportRoutes = [
  {
    Icon: ICONS.ROLE,
    key: ROUTE_KEYS.REPORTS,
    breadcrumb: "Role Gap Report",
    path: `${APP_ROOT.MY_TEAM}/reports/role-gap`,
    component: MyTeamRoleGapReportContainer,
    accessLevel: APP_ROLES.MANAGER,
    openIn: OPEN_IN.TAB,
  },
  {
    Icon: ICONS.SKILL,
    key: ROUTE_KEYS.REPORTS,
    breadcrumb: "Skill Gap Report",
    path: `${APP_ROOT.MY_TEAM}/reports/skill-gap`,
    component: MyTeamSkillGapReportContainer,
    featureFlag: FEATURE_FLAG.SKILLS,
    accessLevel: APP_ROLES.MANAGER,
    openIn: OPEN_IN.TAB,
  },
  {
    Icon: ICONS.COURSE,
    key: ROUTE_KEYS.REPORTS,
    breadcrumb: "Course Gap Report",
    path: `${APP_ROOT.MY_TEAM}/reports/course-gap`,
    component: MyTeamCourseGapReportContainer,
    accessLevel: APP_ROLES.MANAGER,
    openIn: OPEN_IN.TAB,
  },
];

export const HRReportRoutes = [
  {
    Icon: ICONS.ROLE,
    key: ROUTE_KEYS.REPORTS,
    breadcrumb: "Role Gap Report",
    component: HRRoleGapReportContainer,
    path: `${APP_ROOT.HR}/reports/role-gap`,
    accessLevel: APP_ROLES_HR_AND_ADMIN,
    openIn: OPEN_IN.TAB,
  },
  {
    Icon: ICONS.SKILL,
    key: ROUTE_KEYS.REPORTS,
    breadcrumb: "Skill Gap Report",
    component: HRSkillGapReportContainer,
    path: `${APP_ROOT.HR}/reports/skill-gap`,
    featureFlag: FEATURE_FLAG.SKILLS,
    accessLevel: APP_ROLES_HR_AND_ADMIN,
    openIn: OPEN_IN.TAB,
  },
  {
    Icon: ICONS.SERVICE,
    key: ROUTE_KEYS.REPORTS,
    breadcrumb: "Service Gap Report",
    component: ServiceGapReportContainer,
    path: `${APP_ROOT.HR}/reports/service-gap`,
    featureFlag: FEATURE_FLAG.SERVICES,
    accessLevel: APP_ROLES_HR_AND_ADMIN,
    openIn: OPEN_IN.TAB,
  },
  {
    Icon: ICONS.SPECIFICATION,
    key: ROUTE_KEYS.REPORTS,
    breadcrumb: "Specification Gap Report",
    component: SpecificationGapReportContainer,
    path: `${APP_ROOT.HR}/reports/specification-gap`,
    featureFlag: FEATURE_FLAG.SPECIFICATIONS,
    accessLevel: APP_ROLES_HR_AND_ADMIN,
    openIn: OPEN_IN.TAB,
  },
  {
    Icon: ICONS.COURSE,
    key: ROUTE_KEYS.REPORTS,
    breadcrumb: "Course Gap Report",
    component: HRCourseGapReportContainer,
    path: `${APP_ROOT.HR}/reports/course-gap`,
    accessLevel: APP_ROLES_HR_AND_ADMIN,
    openIn: OPEN_IN.TAB,
  },
  {
    Icon: ICONS.TRAINING_HISTORY,
    key: ROUTE_KEYS.REPORTS,
    breadcrumb: "Course Training History Report",
    component: CourseTrainingHistoryReport,
    path: `${APP_ROOT.HR}/reports/courseTrainingHistory`,
    featureFlag: FEATURE_FLAG.REPORTS,
    accessLevel: APP_ROLES_HR_AND_ADMIN,
    openIn: OPEN_IN.TAB,
  },
  {
    Icon: ICONS.TRAINING_HISTORY,
    key: ROUTE_KEYS.REPORTS,
    breadcrumb: "Employee Training History Report",
    component: TrainingHistoryReport,
    path: `${APP_ROOT.HR}/reports/trainingHistory`,
    featureFlag: FEATURE_FLAG.REPORTS,
    accessLevel: APP_ROLES_HR_AND_ADMIN,
    openIn: OPEN_IN.TAB,
  },
  {
    Icon: ICONS.TRAINING_HISTORY,
    key: ROUTE_KEYS.REPORTS,
    breadcrumb: "Training and Competency Report",
    component: TrainingAndCompetencyReport,
    path: `${APP_ROOT.HR}/reports/trainingCompetency`,
    featureFlag: FEATURE_FLAG.REPORTS,
    accessLevel: APP_ROLES_HR_AND_ADMIN,
    openIn: OPEN_IN.TAB,
  },
  {
    Icon: ICONS.TRAINING_HISTORY,
    key: ROUTE_KEYS.REPORTS,
    breadcrumb: "Course Expiry Report",
    component: CourseExpiryReport,
    path: `${APP_ROOT.HR}/reports/courseExpiry`,
    featureFlag: FEATURE_FLAG.REPORTS,
    accessLevel: APP_ROLES_HR_AND_ADMIN,
    openIn: OPEN_IN.TAB,
  },
  {
    Icon: ICONS.TRAINING_HISTORY,
    key: ROUTE_KEYS.REPORTS,
    breadcrumb: "Outstanding Training History Report",
    component: OutstandingTrainingHistoryReport,
    path: `${APP_ROOT.HR}/reports/outstandingTrainingHistory`,
    featureFlag: FEATURE_FLAG.REPORTS,
    accessLevel: APP_ROLES_HR_AND_ADMIN,
    openIn: OPEN_IN.TAB,
  },
];

const createMyTeamRoleSubRoutes = root => [
  {
    hidden: true,
    Icon: ICONS.ROLE,
    breadcrumb: RoleBreadcrumb,
    path: `${root}/roles/:roleId`,
    component: MyTeamRoleContainer,
    routes: [
      {
        exact: true,
        breadcrumb: "Details",
        path: `${root}/roles/:roleId`,
        component: MyTeamRoleDetailsContainer,
      },
      {
        breadcrumb: "Skills",
        key: ROUTE_KEYS.ROLE_SKILLS,
        featureFlag: FEATURE_FLAG.SKILLS,
        path: `${root}/roles/:roleId/skills`,
        component: MyTeamRoleSkillsContainer,
      },
      {
        breadcrumb: "Courses",
        key: ROUTE_KEYS.ROLE_COURSES,
        path: `${root}/roles/:roleId/courses`,
        component: MyTeamRoleCoursesContainer,
      },
      {
        breadcrumb: "Members",
        key: ROUTE_KEYS.ROLE_EMPLOYEES,
        path: `${root}/roles/:roleId/members`,
        component: MyTeamRoleMembersContainer,
      },
    ],
  },
];

const myPageRoutes = [
  {
    path: APP_ROOT.MY_PAGE,
    Icon: ICONS.EMPLOYEE,
    breadcrumb: "My Page",
    component: RouteContainer,
    accessLevel: [APP_ROLES.EMPLOYEE, APP_ROLES.HR_ASSESSOR_VERIFIER],
    routes: [
      {
        path: APP_ROOT.MY_PAGE,
        Icon: ICONS.EMPLOYEE,
        exact: true,
        hidden: true,
        component: MyPageOverviewContainer,
      },
      {
        key: ROUTE_KEYS.APPROVALS,
        Icon: ICONS.APPROVAL,
        breadcrumb: "Approvals",
        path: `${APP_ROOT.MY_PAGE}/approvals`,
        featureFlag: FEATURE_FLAG.SKILLS,
        component: RouteContainer,
        routes: [
          {
            exact: true,
            Icon: ICONS.APPROVAL,
            breadcrumb: "Approvals",
            path: `${APP_ROOT.MY_PAGE}/approvals`,
            component: MyPageApprovals,
          },
          {
            Icon: ICONS.APPROVAL,
            component: ExperienceRecordRouteContainer,
            breadcrumb: ExperienceRecordBreadcrumb,
            path: `${APP_ROOT.MY_PAGE}/approvals/:experienceRecordId/employee/:employeeId/`,
            featureFlag: FEATURE_FLAG.EXPERIENCES,
            routes: createApprovalExperienceSubRoutes(APP_ROOT.MY_PAGE),
          },
        ],
      },
      {
        Icon: ICONS.EMPLOYEE_DETAILS,
        breadcrumb: "Details",
        path: `${APP_ROOT.MY_PAGE}/details`,
        component: MyDetailsContainer,
        accessLevel: APP_ROLES.EMPLOYEE,
      },
      {
        key: ROUTE_KEYS.ROLES,
        Icon: ICONS.ROLE,
        breadcrumb: "Roles",
        path: `${APP_ROOT.MY_PAGE}/roles`,
        component: RouteContainer,
        accessLevel: APP_ROLES.EMPLOYEE,
        routes: [
          {
            exact: true,
            Icon: ICONS.ROLE,
            breadcrumb: "Roles",
            path: `${APP_ROOT.MY_PAGE}/roles`,
            component: MyPageRolesContainer,
          },
          {
            Icon: ICONS.ROLE,
            breadcrumb: RoleBreadcrumb,
            component: MyPageEmployeeRoleContainer,
            path: `${APP_ROOT.MY_PAGE}/roles/:roleId`,
            routes: createEmployeeRoleRoutes(APP_ROOT.MY_PAGE),
          },
        ],
      },
      {
        key: ROUTE_KEYS.SKILLS,
        Icon: ICONS.SKILL,
        breadcrumb: "Skills",
        path: `${APP_ROOT.MY_PAGE}/skills`,
        component: RouteContainer,
        featureFlag: FEATURE_FLAG.SKILLS,
        accessLevel: APP_ROLES.EMPLOYEE,
        routes: [
          {
            exact: true,
            hidden: true,
            breadcrumb: "Skills",
            Icon: ICONS.SKILL,
            path: `${APP_ROOT.MY_PAGE}/skills`,
            component: MyPageSkillsContainer,
          },
          {
            Icon: ICONS.SKILL,
            breadcrumb: SkillBreadcrumb,
            path: `${APP_ROOT.MY_PAGE}/skills/:skillId`,
            component: MyPageEmployeeSkillContainer,
            routes: createEmployeeSkillRoutes(APP_ROOT.MY_PAGE),
          },
        ],
      },
      {
        key: ROUTE_KEYS.EXPERIENCES,
        Icon: ICONS.EXPERIENCES,
        breadcrumb: "Experiences",
        path: `${APP_ROOT.MY_PAGE}/experiences`,
        component: RouteContainer,
        featureFlag: FEATURE_FLAG.EXPERIENCES,
        accessLevel: APP_ROLES.EMPLOYEE,
        routes: [
          {
            exact: true,
            hidden: true,
            breadcrumb: "Experiences",
            Icon: ICONS.EXPERIENCES,
            path: `${APP_ROOT.MY_PAGE}/experiences`,
            component: MyPageExperienceRecordsContainer,
          },
          {
            Icon: ICONS.EXPERIENCES,
            breadcrumb: "Experience",
            path: `${APP_ROOT.MY_PAGE}/experiences/:experienceRecordId`,
            component: MyPageEmployeeExperienceRecordContainer,
            routes: createEmployeeExperienceSubRoutes(APP_ROOT.MY_PAGE),
          },
        ],
      },
      {
        key: ROUTE_KEYS.COURSES,
        Icon: ICONS.COURSE,
        breadcrumb: "Courses",
        path: `${APP_ROOT.MY_PAGE}/courses`,
        component: RouteContainer,
        accessLevel: APP_ROLES.EMPLOYEE,
        routes: [
          {
            exact: true,
            hidden: true,
            Icon: ICONS.COURSE,
            breadcrumb: "Courses",
            path: `${APP_ROOT.MY_PAGE}/courses`,
            component: MyCoursesContainer,
          },
          {
            Icon: ICONS.COURSE,
            breadcrumb: CourseBreadcrumb,
            path: `${APP_ROOT.MY_PAGE}/courses/:courseId`,
            component: MyPageEmployeeCourseContainer,
            routes: createEmployeeCourseRoutes(APP_ROOT.MY_PAGE),
          },
        ],
      },
      {
        key: ROUTE_KEYS.EDUCATION,
        Icon: ICONS.EDUCATION,
        breadcrumb: "Education",
        path: `${APP_ROOT.MY_PAGE}/education`,
        component: MyEducationContainer,
        accessLevel: APP_ROLES.EMPLOYEE,
      },
      {
        key: ROUTE_KEYS.WORK_HISTORY,
        Icon: ICONS.HISTORY,
        breadcrumb: "Work History",
        path: `${APP_ROOT.MY_PAGE}/work-history`,
        component: MyPageWorkHistoryContainer,
        accessLevel: APP_ROLES.EMPLOYEE,
      },
    ],
  },
];

const myTeamRoutes = [
  {
    path: APP_ROOT.MY_TEAM,
    Icon: ICONS.TEAM,
    breadcrumb: "My Team",
    component: RouteContainer,
    accessLevel: APP_ROLES.MANAGER,
    routes: [
      {
        path: APP_ROOT.MY_TEAM,
        Icon: ICONS.TEAM,
        exact: true,
        hidden: true,
        component: MyTeamOverviewContainer,
      },
      {
        key: ROUTE_KEYS.EMPLOYEES,
        Icon: ICONS.TEAM,
        breadcrumb: "Team Members",
        path: `${APP_ROOT.MY_TEAM}/employees`,
        component: RouteContainer,
        routes: [
          {
            exact: true,
            Icon: ICONS.TEAM,
            breadcrumb: "Team Members",
            path: `${APP_ROOT.MY_TEAM}/employees`,
            component: MyTeamEmployeesContainer,
          },
          {
            Icon: ICONS.EMPLOYEE,
            component: EmployeeContainer,
            breadcrumb: EmployeeBreadcrumb,
            path: `${APP_ROOT.MY_TEAM}/employees/:employeeId`,
            routes: createEmployeeRoutes(APP_ROOT.MY_TEAM),
          },
        ],
      },
      {
        key: ROUTE_KEYS.ROLES,
        Icon: ICONS.ROLE,
        path: `${APP_ROOT.MY_TEAM}/roles`,
        component: RouteContainer,
        breadcrumb: "Team Roles",
        routes: [
          {
            exact: true,
            path: `${APP_ROOT.MY_TEAM}/roles`,
            component: MyTeamRolesContainer,
            breadcrumb: "Team Roles",
          },
          ...createMyTeamRoleSubRoutes(APP_ROOT.MY_TEAM),
        ],
      },
      {
        Icon: ICONS.REPORTS,
        breadcrumb: "Reports",
        path: `${APP_ROOT.MY_TEAM}/reports`,
        component: RouteContainer,
        routes: [
          {
            Icon: ICONS.REPORTS,
            exact: true,
            hidden: true,
            path: `${APP_ROOT.MY_TEAM}/reports`,
            component: OverviewContainer,
          },
          ...myTeamReportRoutes,
        ],
      },
    ],
  },
];

const createSettingsRoutes = root => [
  {
    key: ROUTE_KEYS.COURSE_TYPES,
    Icon: ICONS.COURSE,
    breadcrumb: "Course Types",
    path: `${root}/settings/course-types`,
    component: SettingsCourseTypesContainer,
  },
  {
    key: ROUTE_KEYS.COURSE_GROUPS,
    Icon: ICONS.COURSE,
    breadcrumb: "Course Groups",
    path: `${root}/settings/course-groups`,
    component: SettingsCourseGroupsContainer,
  },
  {
    key: ROUTE_KEYS.SKILL_TYPES,
    Icon: ICONS.SKILL,
    breadcrumb: "Skill Types",
    path: `${root}/settings/skill-types`,
    featureFlag: FEATURE_FLAG.SKILLS,
    component: SettingsSkillTypesContainer,
  },
  {
    key: ROUTE_KEYS.SKILL_GROUPS,
    Icon: ICONS.SKILL,
    breadcrumb: "Skill Groups",
    path: `${root}/settings/skill-groups`,
    featureFlag: FEATURE_FLAG.SKILLS,
    component: SettingsSkillGroupsContainer,
  },
  {
    Icon: ICONS.USER_ACCESS,
    breadcrumb: "User Access",
    component: UserAccessContainer,
    path: `${root}/settings/user-access`,
    accessLevel: APP_ROLES.ADMIN,
  },
];

const createCollectionSubRoutes = root => [
  {
    hidden: true,
    Icon: ICONS.COLLECTION,
    breadcrumb: CollectionBreadcrumb,
    path: `${root}/collections/:collectionId`,
    component: CollectionContainer,
    routes: [
      {
        exact: true,
        breadcrumb: "Details",
        path: `${root}/collections/:collectionId`,
        component: CollectionDetailsContainer,
      },
      {
        breadcrumb: "Collection",
        path: `${root}/collections/:collectionId/collection`,
        component: CollectionCollectionContainer,
      },
      {
        breadcrumb: "Conditions",
        path: `${root}/collections/:collectionId/conditions`,
        component: CollectionConditionContainer,
      },
    ],
  },
];

const createSpecificationSubRoutes = root => [
  {
    hidden: true,
    Icon: ICONS.SPECIFICATION,
    breadcrumb: SpecificationBreadcrumb,
    path: `${root}/specifications/:specificationId`,
    component: SpecificationContainer,
    routes: [
      {
        exact: true,
        breadcrumb: "Details",
        path: `${root}/specifications/:specificationId`,
        component: SpecificationDetailsContainer,
      },
      {
        key: ROUTE_KEYS.SPECIFICATION_SKILL,
        breadcrumb: "Skills",
        path: `${root}/specifications/:specificationId/skills`,
        featureFlag: FEATURE_FLAG.SKILLS,
        component: SpecificationSkillsContainer,
      },
      {
        key: ROUTE_KEYS.SPECIFICATION_COURSE,
        breadcrumb: "Courses",
        path: `${root}/specifications/:specificationId/courses`,
        component: SpecificationCoursesContainer,
      },
    ],
  },
];

const createServiceSubRoutes = root => [
  {
    hidden: true,
    Icon: ICONS.SERVICE,
    breadcrumb: ServiceBreadcumb,
    path: `${root}/services/:serviceId`,
    component: ServiceContainer,
    routes: [
      {
        exact: true,
        breadcrumb: "Details",
        path: `${root}/services/:serviceId`,
        component: ServiceDetailsContainer,
      },
      {
        key: ROUTE_KEYS.SERVICE_SKILL,
        breadcrumb: "Skills",
        path: `${root}/services/:serviceId/skills`,
        featureFlag: FEATURE_FLAG.SKILLS,
        component: ServiceSkillsContainer,
      },
      {
        key: ROUTE_KEYS.SERVICE_COURSE,
        breadcrumb: "Courses",
        path: `${root}/services/:serviceId/courses`,
        component: ServiceCoursesContainer,
      },
    ],
  },
];

const HRRoutes = [
  {
    accessLevel: APP_ROLES_HR_AND_ADMIN,
    Icon: ICONS.HR_PORTAL,
    path: APP_ROOT.HR,
    breadcrumb: "HR Portal",
    component: RouteContainer,
    routes: [
      {
        path: APP_ROOT.HR,
        Icon: ICONS.HR_PORTAL,
        exact: true,
        hidden: true,
        component: HROverviewContainer,
      },
      {
        key: ROUTE_KEYS.APPROVALS,
        breadcrumb: "Approvals",
        Icon: ICONS.APPROVAL,
        path: `${APP_ROOT.HR}/approval`,
        component: ApprovalContainer,
      },
      {
        key: ROUTE_KEYS.EMPLOYEES,
        Icon: ICONS.TEAM,
        breadcrumb: "Employees",
        component: RouteContainer,
        path: `${APP_ROOT.HR}/employees`,
        routes: [
          {
            exact: true,
            breadcrumb: "Employees",
            path: `${APP_ROOT.HR}/employees`,
            component: HREmployeesContainer,
          },
          {
            Icon: ICONS.EMPLOYEE,
            component: EmployeeContainer,
            breadcrumb: EmployeeBreadcrumb,
            path: `${APP_ROOT.HR}/employees/:employeeId`,
            routes: createEmployeeRoutes(APP_ROOT.HR),
          },
        ],
      },
      {
        key: ROUTE_KEYS.ROLES,
        Icon: ICONS.ROLE,
        path: `${APP_ROOT.HR}/roles`,
        component: RouteContainer,
        breadcrumb: "Roles Catalogue",
        routes: [
          {
            exact: true,
            path: `${APP_ROOT.HR}/roles`,
            component: RolesCatalogue,
            breadcrumb: "Roles Catalogue",
          },
          ...createRoleSubRoutes(APP_ROOT.HR),
        ],
      },
      {
        key: ROUTE_KEYS.COURSES,
        Icon: ICONS.COURSE,
        path: `${APP_ROOT.HR}/courses`,
        component: RouteContainer,
        breadcrumb: "Course Catalogue",
        routes: [
          {
            exact: true,
            path: `${APP_ROOT.HR}/courses`,
            breadcrumb: "Course Catalogue",
            component: CourseCatalogueContainer,
          },
          {
            Icon: ICONS.COURSE,
            component: CourseContainer,
            breadcrumb: CourseBreadcrumb,
            path: `${APP_ROOT.HR}/courses/:courseId`,
            routes: createCourseSubRoutes(APP_ROOT.HR),
          },
        ],
      },
      {
        key: ROUTE_KEYS.SKILLS,
        Icon: ICONS.SKILL,
        path: `${APP_ROOT.HR}/skills`,
        component: RouteContainer,
        featureFlag: FEATURE_FLAG.SKILLS,
        breadcrumb: "Skills Catalogue",
        routes: [
          {
            exact: true,
            path: `${APP_ROOT.HR}/skills`,
            component: SkillsContainer,
            breadcrumb: "Skills Catalogue",
          },
          {
            Icon: ICONS.SKILLS,
            component: SkillContainer,
            breadcrumb: SkillBreadcrumb,
            path: `${APP_ROOT.HR}/skills/:skillId`,
            routes: createSkillSubRoutes(APP_ROOT.HR),
          },
        ],
      },
      {
        key: ROUTE_KEYS.EXPERIENCES,
        Icon: ICONS.EXPERIENCES,
        path: `${APP_ROOT.HR}/experiences`,
        component: RouteContainer,
        featureFlag: FEATURE_FLAG.EXPERIENCES,
        breadcrumb: "Experiences Catalogue",
        routes: [
          {
            exact: true,
            path: `${APP_ROOT.HR}/experiences`,
            component: ExperienceRecordsContainer,
            breadcrumb: "Experiences Catalogue",
          },
          {
            Icon: ICONS.EXPERIENCES,
            component: ExperienceRecordRouteContainer,
            breadcrumb: ExperienceRecordBreadcrumb,
            path: `${APP_ROOT.HR}/experiences/:experienceRecordId/employee/:employeeId/`,
            routes: createExperienceSubRoutes(APP_ROOT.HR),
          },
        ],
      },
      {
        key: ROUTE_KEYS.SERVICES,
        Icon: ICONS.SERVICE,
        path: `${APP_ROOT.HR}/services`,
        component: RouteContainer,
        featureFlag: FEATURE_FLAG.SERVICES,
        breadcrumb: "Services",
        routes: [
          {
            Icon: ICONS.SKILLS,
            exact: true,
            path: `${APP_ROOT.HR}/services`,
            component: ServicesContainer,
            breadcrumb: "Services",
          },
          ...createServiceSubRoutes(APP_ROOT.HR),
        ],
      },
      {
        key: ROUTE_KEYS.SPECIFICATIONS,
        Icon: ICONS.SPECIFICATION,
        path: `${APP_ROOT.HR}/specifications`,
        component: RouteContainer,
        featureFlag: FEATURE_FLAG.SPECIFICATIONS,
        breadcrumb: "Specifications",
        routes: [
          {
            exact: true,
            path: `${APP_ROOT.HR}/specifications`,
            component: SpecificationsContainer,
            breadcrumb: "Specifications",
          },
          ...createSpecificationSubRoutes(APP_ROOT.HR),
        ],
      },
      {
        key: ROUTE_KEYS.COLLECTIONS,
        Icon: ICONS.COLLECTION,
        path: `${APP_ROOT.HR}/collections`,
        component: RouteContainer,
        breadcrumb: "Collections",
        featureFlag: FEATURE_FLAG.COLLECTIONS,
        routes: [
          {
            exact: true,
            path: `${APP_ROOT.HR}/collections`,
            component: CollectionsContainer,
            breadcrumb: "Collections",
          },
          ...createCollectionSubRoutes(APP_ROOT.HR),
        ],
      },
      {
        Icon: ICONS.REPORTS,
        breadcrumb: "Reports",
        path: `${APP_ROOT.HR}/reports`,
        component: RouteContainer,
        routes: [
          {
            Icon: ICONS.REPORTS,
            exact: true,
            hidden: true,
            path: `${APP_ROOT.HR}/reports`,
            component: OverviewContainer,
          },
          ...HRReportRoutes,
        ],
      },
      {
        Icon: ICONS.SETTINGS,
        breadcrumb: "Settings",
        path: `${APP_ROOT.HR}/settings`,
        component: RouteContainer,
        routes: [
          {
            Icon: ICONS.SETTINGS,
            exact: true,
            hidden: true,
            path: `${APP_ROOT.HR}/settings`,
            component: SettingsOverviewContainer,
          },
          ...createSettingsRoutes(APP_ROOT.HR),
        ],
      },
    ],
  },
];

export const routesWithoutSidebar = [...myTeamReportRoutes, ...HRReportRoutes];

export default [...myPageRoutes, ...myTeamRoutes, ...HRRoutes];
