import React from "react";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";

import routePaths from "app/routePaths";
import { ICONS } from "app/app.constants";
import GapReportHeadersContainer from "features/components/GapReport/GapReportHeadersContainer";
import GapReportRequirementHeaderCell from "features/components/GapReport/GapReportRequirementHeaderCell";

const EmployeeRoleGapReportHeader = ({ courses, classes, skills }) => (
  <Grid container>
    <Grid item xs={2} className={classes.headerPlaceholder} />
    <Grid item>
      <GapReportHeadersContainer>
        {courses.toList().map(course => {
          const courseId = course.get("courseId").toString();
          const courseName = course.get("courseName");
          const courseTypeName = course.get("courseTypeName");

          return (
            <GapReportRequirementHeaderCell
              name={courseName}
              Icon={ICONS.COURSE}
              key={course.get("courseId")}
              title={`${courseName} (${courseTypeName})`}
              to={`${routePaths.hrPortal}/courses/${courseId}`}
            />
          );
        })}
        {skills.toList().map(skill => {
          const skillId = skill.get("skillId").toString();
          const skillName = skill.get("skillName");

          return (
            <GapReportRequirementHeaderCell
              name={skillName}
              Icon={ICONS.SKILL}
              key={skill.get("skillId")}
              title={`${skillName}`}
              to={`${routePaths.hrPortal}/skills/${skillId}`}
            />
          );
        })}
      </GapReportHeadersContainer>
    </Grid>
  </Grid>
);

const styles = theme => ({
  headerPlaceholder: {
    borderBottom: theme.gapReport.defaultBorder,
  },
});

export default compose(
  React.memo,
  withStyles(styles),
)(EmployeeRoleGapReportHeader);
