import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { getCurrentEmployeeFromState } from "app/app.selectors";
import EmployeeSkillsBasePage from "features/components/EmployeeSkillsBasePage";

class MyPageSkillsContainer extends PureComponent {
  render() {
    const { currentEmployee, breadcrumb, path, Icon } = this.props;

    return (
      <EmployeeSkillsBasePage
        Icon={Icon}
        path={path}
        showAddButton={false}
        title={breadcrumb}
        employeeId={currentEmployee.get("employeeId")}
      />
    );
  }
}

export default compose(
  connect(state => ({
    currentEmployee: getCurrentEmployeeFromState(state),
  })),
)(MyPageSkillsContainer);
