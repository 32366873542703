import { compose } from "redux";
import { connect } from "react-redux";
import { PureComponent } from "react";

import { getCollectionFromState } from "features/competence.selectors";

class CollectionBreadcrumb extends PureComponent {
  render() {
    const { collection } = this.props;

    return collection ? collection.get("name") : "...";
  }
}

export default compose(
  connect((state, { match }) => ({
    collection: getCollectionFromState(state, match.params.collectionId),
  })),
)(CollectionBreadcrumb);
