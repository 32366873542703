import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Tooltip, IconButton } from "@material-ui/core";

import {
  getSkillTypes,
  editSkillTypeOnSubmit,
  createSkillTypeOnSubmit,
  initializeEditSkillType,
  deleteSkillTypeAskConfirmation,
} from "features/competence.actions";

import { ACTIONS, ICONS } from "app/app.constants";
import CrudBasePage from "app/components/CrudBasePage";
import { getActionDataStateFromState } from "app/app.selectors";
import { SKILL_TYPE_FORM } from "features/competence.constants";
import { getSkillTypesFromState } from "features/competence.selectors";
import SkillTypeForm from "features/settings/components/SkillTypeForm";

class SettingsSkillTypesContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad } = this.props;
    dispatchOnLoad();
  }

  columns = [
    {
      xs: true,
      title: "Name",
      getSortProperty: item => item.get("name"),
    },
  ];

  actions = [
    {
      getValue: item => (
        <Tooltip title="Delete">
          <IconButton onClick={() => this.props.dispatchDeleteSkillType(item)}>
            <ICONS.DELETE fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  render() {
    const {
      dataState,
      skillTypes,
      breadcrumb,
      dispatchEditSkillTypeOnSubmit,
      dispatchCreateSkillTypeOnSubmit,
      dispatchInitializeEditSkillType,
    } = this.props;

    return (
      <CrudBasePage
        displayAddButton
        displaySearchField
        Icon={ICONS.SKILL}
        items={skillTypes}
        title={breadcrumb}
        dataState={dataState}
        CreateEntityFormComponent={SkillTypeForm}
        EditEntityFormComponent={SkillTypeForm}
        createEntityForm={{
          form: SKILL_TYPE_FORM.ID,
        }}
        editEntityForm={{
          form: SKILL_TYPE_FORM.ID,
        }}
        CreateModalProps={{
          submitText: "Add",
          title: "Add Skill Type",
          onSubmit: dispatchCreateSkillTypeOnSubmit,
        }}
        EditModalProps={{
          submitText: "Save",
          title: "Edit Skill Type",
          formTitleField: SKILL_TYPE_FORM.NAME,
          onSubmit: dispatchEditSkillTypeOnSubmit,
        }}
        SortableListProps={{
          displayNumberOfItems: true,
          actions: this.actions,
          columns: this.columns,
          getKey: item => item.get("skillTypeId"),
          onRowClick: dispatchInitializeEditSkillType,
        }}
      />
    );
  }
}

export default compose(
  connect(
    state => ({
      skillTypes: getSkillTypesFromState(state),
      dataState: getActionDataStateFromState(state, ACTIONS.GET_SKILL_TYPES),
    }),
    {
      dispatchOnLoad: getSkillTypes,
      dispatchEditSkillTypeOnSubmit: editSkillTypeOnSubmit,
      dispatchDeleteSkillType: deleteSkillTypeAskConfirmation,
      dispatchCreateSkillTypeOnSubmit: createSkillTypeOnSubmit,
      dispatchInitializeEditSkillType: initializeEditSkillType,
    },
  ),
)(SettingsSkillTypesContainer);
