import { List, Map, Set, OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { WorkHistoryMapper } from "app/app.mappers";
import fileMappers from "app/mappers/file.mappers";
import { WORK_HISTORY_FORM } from "features/competence.constants";

const initialState = Map();

export default (state = initialState, action) => {
  const { type, payload, error } = action;
  switch (type) {
    case ACTIONS.CLEAR_WORK_HISTORY: {
      return initialState;
    }
    case ACTIONS.GET_WORK_HISTORY: {
      if (error) return state;

      return OrderedMap(
        List(payload).map(x => [
          x.workHistoryId.toString(),
          WorkHistoryMapper.from(x),
        ]),
      );
    }

    case ACTIONS.UPLOAD_WORK_HISTORY_FILE: {
      if (error) return state;

      return state.mergeIn(
        [action.workHistoryId.toString(), WORK_HISTORY_FORM.FILES],
        Set([payload]).map(fileMappers.File.from),
      );
    }

    case ACTIONS.CREATE_WORK_HISTORY:
    case ACTIONS.UPDATE_WORK_HISTORY:
    case ACTIONS.APPROVE_WORK_HISTORY:
    case ACTIONS.DISAPPROVE_WORK_HISTORY:
    case ACTIONS.CREATE_AND_APPROVE_WORK_HISTORY: {
      if (error) return state;

      return state.set(
        payload.workHistoryId.toString(),
        WorkHistoryMapper.from(payload),
      );
    }
    case ACTIONS.DELETE_WORK_HISTORY: {
      if (error) return state;

      return state.delete(action.workHistoryId.toString());
    }
    default:
      return state;
  }
};
