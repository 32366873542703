import React from "react";
import { compose } from "redux";
import { Map } from "immutable";
import isNil from "lodash/isNil";

import { Grid, Typography, withStyles } from "@material-ui/core";

import { PureComponent } from "react";
import { TreeView } from "@material-ui/lab";

import { EMPTY_LIST, EMPTY_MAP } from "app/app.constants";
import TreeListItem from "app/components/TreeList/TreeListItem";
import SortableListActionsHeader from "app/components/SortableList/SortableListActionsHeader";
import SortableListHeader from "app/components/SortableList/SortableListHeader";
import { buildItemTree } from "utils/app.util";
import CloseSquare from "app/components/icons/CloseSquare";
import PlusSquare from "app/components/icons/PlusSquare";
import MinusSquare from "app/components/icons/MinusSquare";
import SortableListRow from "app/components/SortableList/SortableListRow";

class TreeList extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      filter: this.createDefaultFilter(props.columns),
    };
  }

  createDefaultFilter = columns =>
    Map(
      columns.map(({ filter }, index) => [
        index.toString(),
        filter && filter.defaultValue,
      ]),
    ).filter(Boolean);

  setFilter = (columnNo, value) => {
    this.setState({
      filter: isNil(value)
        ? this.state.filter.delete(columnNo.toString())
        : this.state.filter.set(columnNo.toString(), value),
    });
  };

  clearFilter = () =>
    this.setState({
      filter: EMPTY_MAP,
    });

  setSearch = search => this.setState({ search });

  filterItemsKeepingParents = (items, getKey, matchPredicate) => {
    return items
      .sortBy(item => -item.get("treeMin"))
      .reduce((matchingItems, item) => {
        const isParentOfMatchingItem = matchingItems
          .map(matchingItem => matchingItem.get("parentId").toString())
          .includes(getKey(item).toString());

        return isParentOfMatchingItem || matchPredicate(item)
          ? matchingItems.push(item)
          : matchingItems;
      }, EMPTY_LIST);
  };

  itemMatchesSearch = (item, search) =>
    item
      .get("name")
      .toUpperCase()
      .includes(search.toUpperCase());

  itemMatchesFilters = (item, filter, columns) => {
    return filter.size
      ? filter.every((value, columnNo) => {
          const { getSortProperty } = columns[columnNo];
          const itemValue = getSortProperty ? getSortProperty(item) : undefined;

          return value === itemValue;
        })
      : true;
  };

  render() {
    const {
      Icon,
      items,
      columns,
      listActions,
      getKey,
      createSortableListRowProps,
      classes,
    } = this.props;

    const filteredItems = items
      .update(items =>
        this.filterItemsKeepingParents(
          items,
          getKey,
          item =>
            this.itemMatchesSearch(item, this.state.search) &&
            this.itemMatchesFilters(
              item,
              this.state.filter,
              this.props.columns,
            ),
        ),
      )
      .map((item, index) => item.set("index", index)); // Index is used later to set the striped rows.

    const itemTree = buildItemTree(filteredItems, getKey);

    return (
      <>
        <Grid className={classes.sticky} container>
          <SortableListActionsHeader
            items={items}
            columns={columns}
            listActions={listActions}
            classes={{ root: classes.placeholder }}
            striped={false}
            setSearch={this.setSearch}
            filter={this.state.filter}
            setFilter={this.setFilter}
            clearFilter={this.clearFilter}
          />
          <SortableListHeader hasIcon columns={[columns[0]]} />
        </Grid>
        <TreeView
          defaultCollapseIcon={<MinusSquare />}
          defaultExpandIcon={<PlusSquare />}
          defaultEndIcon={<CloseSquare />}
          defaultExpanded={items.map(item => getKey(item).toString()).toArray()}
        >
          {itemTree.map(item => (
            <TreeListItem
              Icon={Icon}
              key={getKey(item).toString()}
              item={item}
              itemChildren={item.get("children")}
              getKey={getKey}
              createSortableListRowProps={createSortableListRowProps}
            />
          ))}
        </TreeView>
        {!!items.size && !filteredItems.size && (
          <SortableListRow striped={false} noborder>
            <Grid container justify="center">
              <Typography variant="caption">No matches...</Typography>
            </Grid>
          </SortableListRow>
        )}
        {!!filteredItems.size && (
          <SortableListRow striped={false} noborder>
            <Grid container justify="flex-end">
              <Typography variant="caption">
                {`${filteredItems.size} item(s)`}
              </Typography>
            </Grid>
          </SortableListRow>
        )}
      </>
    );
  }
}

const styles = theme => ({
  placeholder: {
    backgroundColor: theme.palette.background.default,
    border: "none",
  },
  sticky: {
    top: 0,
    zIndex: 2,
    position: "sticky",
  },
});

export default compose(withStyles(styles))(TreeList);
