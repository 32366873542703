import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import {
  getServiceFromState,
  getServiceSkillsFromState,
} from "features/competence.selectors";

import ServiceItemList from "features/service/components/ServiceItemList";

const ServiceSkillListContainer = props => {
  const { serviceSkills } = props;

  return !serviceSkills.size ? null : (
    <ServiceItemList items={serviceSkills} {...props} />
  );
};

export default compose(
  connect(
    (state, { serviceId }) => ({
      service: getServiceFromState(state, serviceId),
      serviceSkills: getServiceSkillsFromState(state, serviceId),
    }),
    null,
  ),
)(ServiceSkillListContainer);
