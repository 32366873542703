import { Map, OrderedMap, List } from "immutable";
import { ACTIONS } from "app/app.constants";
import { EmployeeMapper, DepartmentMapper } from "app/app.mappers";
const initialState = Map({
  employee: Map(),
  department: Map(),
  appliedFilters: Map(),
  appliedOptions: Map(),
});
export default (state = initialState, action) => {
  const { error, payload, type } = action;
  switch (type) {
    case ACTIONS.CLEAR_SKILL_GAP_REPORT:
    case ACTIONS.CLEAR_ROLE_GAP_REPORT:
    case ACTIONS.CLEAR_COURSE_GAP_REPORT:
    case ACTIONS.CLEAR_SERVICE_GAP_REPORT:
    case ACTIONS.CLEAR_COURSE_EXPIRY_REPORT:
    case ACTIONS.CLEAR_TRAINING_AND_COMPETENCY_REPORT:
    case ACTIONS.CLEAR_COURSE_TRAINING_HISTORY_REPORT:
    case ACTIONS.CLEAR_TRAINING_HISTORY_REPORT:
    case ACTIONS.CLEAR_OUTSTANDING_HISTORY_REPORT:
    case ACTIONS.CLEAR_SPECIFICATION_GAP_REPORT: {
      return initialState;
    }
    case ACTIONS.GET_SKILL_GAP_REPORT:
    case ACTIONS.GET_ROLE_GAP_REPORT:
    case ACTIONS.GET_COURSE_GAP_REPORT:
    case ACTIONS.GET_SERVICE_GAP_REPORT:
    case ACTIONS.GET_TRAINING_AND_COMPETENCY_REPORT:
    case ACTIONS.GET_SPECIFICATION_GAP_REPORT: {
      if (error) return state;

      return state
        .set(
          "employee",
          List(payload.employees)
            .map(EmployeeMapper.from)
            .groupBy(employee => employee.get("departmentId").toString()),
        )
        .set(
          "department",
          OrderedMap(
            payload.departments.map(department => [
              department.departmentId.toString(),
              DepartmentMapper.from(department),
            ]),
          ),
        )
        .set("appliedFilters", action.filters);
    }
    case ACTIONS.GET_COURSE_EXPIRY_REPORT:
    case ACTIONS.GET_COURSE_TRAINING_HISTORY_REPORT:
    case ACTIONS.GET_OUTSTANDING_TRAINING_HISTORY_REPORT:
    case ACTIONS.GET_TRAINING_HISTORY_REPORT: {
      if (error) return state;
      return state.set("appliedFilters", action.filters);
    }
    case ACTIONS.UPDATE_GAP_REPORT_OPTIONS:
      if (error) return state;

      return state.setIn(
        ["appliedOptions", action.option.key],
        action.option.value,
      );
    default:
      return state;
  }
};
