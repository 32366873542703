import moment from "moment";
import { compose } from "redux";
import { fromJS } from "immutable";
import { connect } from "react-redux";
import React, { useMemo, useEffect, useCallback, useState } from "react";
import { withStyles, Grid, Button, Typography } from "@material-ui/core";

import { useToggle } from "altus-hooks";
import { BasePage, NoContentBasePage } from "altus-ui-components";

import {
  experienceRecordsOnUnload,
  employeeExperienceReportOnFilter,
  employeeExperienceReportOnLoad,
  generateExperienceRecordsReportForEmployee,
} from "features/competence.actions";

import {
  getExperienceRecordsFromState,
  getAvailableExperienceCategoriesFromState,
  getExperienceRecordsStatsFromState,
  getEmployeeAllExperienceRecordItemsFromState,
} from "features/competence.selectors";

import {
  ACTIONS,
  EMPTY_MAP,
  EMPTY_SET,
  Format,
  ICONS,
} from "app/app.constants";

import { formatDate } from "utils/format.util";
import DatePickerField from "app/components/form/DatePickerField";
import SortableList from "app/components/SortableList/SortableList";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import ExperienceRecordCategoryReport from "features/employee/reports/ExperienceRecordCategoryReport";
import EmployeeExperienceRecordReportExportModal from "features/employee/reports/EmployeeExperienceRecordReportExportModal";

const EmployeeExperienceRecordsReportContainer = ({
  dataState,
  classes,
  breadcrumb,
  employeeId,
  dispatchOnLoad,
  dispatchOnFilter,
  dispatchOnUnload,
  dispatchGenerateReport,
  experienceRecords = EMPTY_MAP,
  experienceRecordStats = EMPTY_MAP,
  experienceRecordItems = EMPTY_MAP,
  availableExperienceRecordCategories = EMPTY_SET,
}) => {
  const [open, toggleModal] = useToggle();

  useEffect(() => {
    dispatchOnLoad(employeeId);

    return () => dispatchOnUnload();
  }, [employeeId, dispatchOnLoad, dispatchOnUnload]);

  const [filteredItems, setFilteredItems] = useState(EMPTY_MAP);

  useEffect(() => {
    setFilteredItems(experienceRecords);
  }, [experienceRecords]);

  const onGenerateReport = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const onSubmitGenerateReport = useCallback(
    (selectedCategories, showDeployments) => {
      const options = {
        experienceCategories: selectedCategories
          .map(category => category.experienceCategoryId)
          .toArray(),
        showDeploymentHistory: showDeployments,
        experienceRecords: filteredItems,
      };

      dispatchGenerateReport(employeeId, options).then(() => toggleModal());
    },
    [toggleModal, dispatchGenerateReport, employeeId, filteredItems],
  );

  const onFilterItems = useCallback(
    items => {
      setFilteredItems(items);
      dispatchOnFilter(employeeId, items);
    },
    [employeeId, dispatchOnFilter],
  );

  const columns = useMemo(
    () => [
      {
        xs: 1,
        title: "Job",
        getSortProperty: item => item.get("jobId"),
        filter: {
          label: "Job",
          defaultText: "All",
        },
      },
      {
        xs: true,
        title: "Client",
        getSortProperty: item => item.get("experienceClientName"),
        filter: {
          label: "Client",
          defaultText: "All",
        },
      },
      {
        xs: true,
        filter: {
          label: "Location",
          defaultText: "All",
        },
        title: "Location",
        getSortProperty: item => item.get("locationName"),
      },
      {
        xs: true,
        filter: {
          label: "Discipline",
          defaultText: "All",
        },
        title: "Discipline",
        getSortProperty: item => item.get("experienceDisciplineName"),
      },
      {
        xs: true,
        filter: {
          label: "Performed as",
          defaultText: "All",
        },
        title: "Performed as",
        getSortProperty: item => item.get("performedAs"),
      },
      {
        filter: {
          render: ({ onChange, value, ...rest }) => (
            <DatePickerField
              input={{
                onChange,
                value,
              }}
              inputVariant="standard"
              DatePickerProps={{
                margin: "normal",
                label: "From Date",
              }}
              {...rest}
            />
          ),
          match: (filterValue, cellValue) =>
            moment(cellValue).isSameOrAfter(filterValue, "day"),
        },
        xs: true,
        title: "From Date",
        getSortProperty: item => formatDate(item.get("from"), Format.date),
      },
      {
        filter: {
          render: ({ onChange, value, ...rest }) => (
            <DatePickerField
              input={{
                onChange,
                value,
              }}
              inputVariant="standard"
              DatePickerProps={{
                margin: "normal",
                label: "To Date",
              }}
              {...rest}
            />
          ),
          match: (filterValue, cellValue) =>
            moment(cellValue).isSameOrBefore(filterValue, "day"),
        },
        xs: true,
        title: "To Date",
        getSortProperty: item => formatDate(item.get("to"), Format.date),
      },
      {
        xs: true,
        title: "XP",
        getSortProperty: item =>
          experienceRecordStats.getIn(
            [
              item.get("experienceRecordId").toString(),
              "experiencePointsCount",
            ],
            "-",
          ),
      },
    ],
    [experienceRecordStats],
  );

  if (!dataState.isLoading() && !experienceRecordItems.size) {
    return (
      <NoContentBasePage
        Icon={null}
        header="No experiences recorded yet"
        description="No experiences recorded yet"
      />
    );
  }
  return (
    <BasePage
      title={breadcrumb}
      Icon={ICONS.EXPERIENCES}
      dataState={dataState}
      displaySearchField={null}
    >
      <Grid container item justifyContent="flex-end">
        <Button
          size="large"
          color="primary"
          variant="contained"
          onClick={onGenerateReport}
        >
          Export
        </Button>
      </Grid>
      <Grid container item spacing={3} justifyContent="center" direction="row">
        <Grid container item>
          <Typography variant="h6" className={classes.title}>
            Experiences
          </Typography>
        </Grid>
        {availableExperienceRecordCategories &&
          availableExperienceRecordCategories
            .toMap()
            .toList()
            .map((category, index) => (
              <ExperienceRecordCategoryReport
                key={index}
                category={fromJS(category)}
                employeeId={employeeId}
                recordItems={experienceRecordItems}
              />
            ))}
      </Grid>
      <Grid container item>
        <Typography variant="h6" className={classes.tableTitle}>
          Deployment History
        </Typography>
        <SortableList
          Icon={ICONS.EXPERIENCES}
          items={experienceRecords}
          dataState={dataState}
          onFilter={onFilterItems}
          columns={columns}
          selectable={false}
          displayNumberOfItems={true}
          getKey={item => item.get("experienceRecordId")}
        />
      </Grid>
      <EmployeeExperienceRecordReportExportModal
        open={open}
        handleClose={onGenerateReport}
        handleGenerate={onSubmitGenerateReport}
        availableExperienceRecordCategories={
          availableExperienceRecordCategories
        }
      />
    </BasePage>
  );
};

const styles = theme => ({
  tableTitle: {
    display: "flex",
    align: "left",
    paddingTop: theme.spacing(4),
  },
  title: {
    display: "flex",
    align: "left",
  },
});
export default compose(
  connect(
    (state, { employeeId }) => ({
      experienceRecords: getExperienceRecordsFromState(state),
      experienceRecordStats: getExperienceRecordsStatsFromState(state),
      availableExperienceRecordCategories: getAvailableExperienceCategoriesFromState(
        state,
      ),
      experienceRecordItems: getEmployeeAllExperienceRecordItemsFromState(
        state,
        employeeId,
      ),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_EMPLOYEE_EXPERIENCE_RECORDS_FOR_REPORT,
        ACTIONS.GET_EMPLOYEE_ALL_EXPERIENCE_RECORD_ITEMS,
        ACTIONS.GET_STATS_FOR_EXPERIENCE_RECORDS_FOR_EMPLOYEE,
        ACTIONS.GET_EMPLOYEE_EXPERIENCE_RECORD_CATEGORIES_FOR_REPORT,
      ),
    }),
    {
      dispatchOnLoad: employeeExperienceReportOnLoad,
      dispatchOnUnload: experienceRecordsOnUnload,
      dispatchGenerateReport: generateExperienceRecordsReportForEmployee,
      dispatchOnFilter: employeeExperienceReportOnFilter,
    },
  ),
  withStyles(styles),
)(EmployeeExperienceRecordsReportContainer);
