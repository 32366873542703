import { OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";

import { SkillMapper, CourseMapper, ServiceMapper } from "app/app.mappers";

const initialState = OrderedMap();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_SERVICES: {
      return initialState;
    }

    case ACTIONS.GET_SERVICES: {
      if (error) return state;

      return OrderedMap(
        payload.map(service => [
          service.serviceId.toString(),
          ServiceMapper.from(service),
        ]),
      );
    }

    case ACTIONS.GET_SERVICE_WITH_PARENTS: {
      if (error) return state;

      return state.merge(
        OrderedMap(
          payload.map(service => [
            service.serviceId.toString(),
            ServiceMapper.from(service),
          ]),
        ),
      );
    }

    case ACTIONS.GET_AVAILABLE_COURSES_FOR_SERVICE: {
      if (error) return state;

      return state.setIn(
        [action.serviceId.toString(), "availableCourses"],
        OrderedMap(
          payload.map(course => [
            course.courseId.toString(),
            CourseMapper.from(course),
          ]),
        ),
      );
    }

    case ACTIONS.GET_AVAILABLE_SKILLS_FOR_SERVICE: {
      if (error) return state;

      return state.setIn(
        [action.serviceId.toString(), "availableSkills"],
        OrderedMap(
          payload.map(skill => [
            skill.skillId.toString(),
            SkillMapper.from(skill),
          ]),
        ),
      );
    }

    case ACTIONS.GET_SERVICE:
    case ACTIONS.UPDATE_SERVICE: {
      if (error) return state;

      return state.set(
        payload.serviceId.toString(),
        ServiceMapper.from(payload),
      );
    }
    default:
      return state;
  }
};
