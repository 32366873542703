import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { formValueSelector } from "redux-form/immutable";

import {
  EMPLOYEE_COURSE_FORM,
  ADD_EMPLOYEE_COURSE_ATTENDANCE_MODAL_ID,
} from "features/competence.constants";

import { EMPTY_SET, ICONS } from "app/app.constants";
import SimpleFormModal from "app/components/SimpleFormModal";
import AddEmployeeCourseCertificateForm from "features/components/AddEmployeeCourseCertificateForm";
import { validateEmployeeCourseForm } from "utils/validation.util";
import AddEmployeeCourseCertificateModalActions from "features/components/AddEmployeeCourseCertificateModalActions";

const AddEmployeeCourseCertificateModal = ({
  classes,
  employeeId,
  courseId,
  isOverridden,
}) => {
  return (
    <SimpleFormModal
      Icon={ICONS.COURSE}
      submitText="Upload"
      title="Upload certificate"
      displaySubmitBtn={false}
      displayCancelBtn={false}
      component={AddEmployeeCourseCertificateForm}
      modalId={ADD_EMPLOYEE_COURSE_ATTENDANCE_MODAL_ID}
      formTitleField={EMPLOYEE_COURSE_FORM.COURSE_NAME}
      ModalActions={AddEmployeeCourseCertificateModalActions}
      ModalActionsProps={{
        isOverridden,
        employeeId,
        courseId,
      }}
      FormProps={{
        employeeId,
        courseId,
      }}
      DialogProps={{
        maxWidth: "md",
        fullWidth: true,
        classes: {
          paper: classes.dialogPaper,
        },
      }}
      DialogContentProps={{
        classes: {
          root: classes.dialogContent,
        },
      }}
      form={{
        form: EMPLOYEE_COURSE_FORM.ID,
        initialValues: {
          [EMPLOYEE_COURSE_FORM.FILES]: EMPTY_SET,
        },
        validate: validateEmployeeCourseForm,
      }}
    />
  );
};

const formSelector = formValueSelector(EMPLOYEE_COURSE_FORM.ID);

const styles = {
  dialogPaper: {
    height: "100%",
    maxHeight: 700,
  },
  dialogContent: {
    overflowX: "hidden",
  },
};

export default compose(
  connect(state => ({
    isOverridden: formSelector(state, EMPLOYEE_COURSE_FORM.IS_OVERRIDDEN),
    courseId: formSelector(state, EMPLOYEE_COURSE_FORM.COURSE_ID),
  })),
  withStyles(styles),
)(AddEmployeeCourseCertificateModal);
