import { compose } from "redux";
import castArray from "lodash/castArray";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router";
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";

class Tabs extends PureComponent {
  render() {
    const { classes, value, children } = this.props;

    return (
      <Grid container className={classes.root}>
        {React.Children.map(children, child => {
          return React.cloneElement(child, {
            selected:
              castArray(child.props.value).some(val => val === value) &&
              !child.props.disabled,
          });
        })}
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    background: theme.palette.background.default,
    paddingLeft: theme.spacing(3),
  },
});

export default compose(withRouter, withStyles(styles, { name: "Tabs" }))(Tabs);
