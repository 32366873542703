import React, { PureComponent } from "react";

import EmployeeWorkHistoryBasePage from "features/components/EmployeeWorkHistoryBasePage";

class EmployeeWorkHistoryContainer extends PureComponent {
  render() {
    const { employeeId, breadcrumb } = this.props;

    return (
      <EmployeeWorkHistoryBasePage title={breadcrumb} employeeId={employeeId} />
    );
  }
}

export default EmployeeWorkHistoryContainer;
