import React from "react";
import { compose } from "redux";
import { Grid } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import { BasePage } from "altus-ui-components";
import { ReceivedDataState } from "altus-datastate";

import ErrorBoundary from "app/components/ErrorBoundary";
import GapReportMessagePage from "features/components/GapReport/GapReportMessagePage";
import GapReportBasePageHeader from "features/components/GapReport/GapReportBasePageHeader";

const GapReportBasePage = ({
  title,
  classes,
  children,
  dataState,
  renderFilter,
  employeesByDepartment,
}) => (
  <BasePage
    dataState={dataState}
    classes={{
      root: classes.basePageRoot,
      children: classes.basePageChildren,
    }}
  >
    <GapReportBasePageHeader renderFilter={renderFilter} />
    <ErrorBoundary>
      <Grid
        item
        container
        id="gap-report-scroll-container"
        className={classes.gapReportContainer}
      >
        <Route
          children={({ location }) => (
            <Switch>
              {!location.search && !employeesByDepartment.size && (
                <Route
                  render={() => (
                    <GapReportMessagePage title={title}>
                      Click
                      <b>
                        <em>{` Search `}</em>
                      </b>
                      to generate report...
                    </GapReportMessagePage>
                  )}
                />
              )}
              {location.search && !employeesByDepartment.size && (
                <Route
                  render={() => (
                    <GapReportMessagePage title={title}>
                      No results.. Try changing one or more filters
                    </GapReportMessagePage>
                  )}
                />
              )}
            </Switch>
          )}
        />
        {children}
      </Grid>
    </ErrorBoundary>
  </BasePage>
);

const styles = theme => ({
  paper: {
    padding: theme.spacing(5),
  },
  subRoot: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px 0px`,
  },
  gapReportContainer: {
    overflow: "auto",
    height: "100%",
  },
  basePageRoot: {
    overflow: "hidden",
  },
  basePageChildren: {
    height: "100%",
    padding: 0,
  },
});

GapReportBasePage.defaultProps = {
  dataState: ReceivedDataState,
};

export default compose(React.memo, withStyles(styles))(GapReportBasePage);
