import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Helmet } from "altus-ui-components";

import {
  getMyTeamCourseGapReport,
  myTeamCourseGapReportOnLoad,
  downloadAndSaveMyTeamCourseGapReport,
} from "features/competence.actions";

import { APP_ROOT } from "app/app.constants";
import CourseGapReportBasePage from "features/components/CourseGapReport/CourseGapReportBasePage";

class MyTeamCourseGapReportContainer extends PureComponent {
  componentDidMount() {
    const { dispatchOnLoad } = this.props;

    dispatchOnLoad();
  }

  render() {
    const {
      breadcrumb,
      dispatchGetMyTeamCourseGapReport,
      dispatchDownloadMyTeamCourseGapReport,
    } = this.props;

    return (
      <>
        <Helmet titleTemplate="%s">
          <title>{breadcrumb}</title>
        </Helmet>
        <CourseGapReportBasePage
          title={breadcrumb}
          root={APP_ROOT.MY_TEAM}
          getCourseGapReport={dispatchGetMyTeamCourseGapReport}
          downloadCourseGapReport={dispatchDownloadMyTeamCourseGapReport}
        />
      </>
    );
  }
}

export default compose(
  connect(null, {
    dispatchOnLoad: myTeamCourseGapReportOnLoad,
    dispatchGetMyTeamCourseGapReport: getMyTeamCourseGapReport,
    dispatchDownloadMyTeamCourseGapReport: downloadAndSaveMyTeamCourseGapReport,
  }),
)(MyTeamCourseGapReportContainer);
