import { Map, OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";

import employeeCourseMappers from "app/mappers/employeeCourse.mappers";

const initialState = Map({
  employeesAttendances: Map(),
});

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_TRAINING_HISTORY_REPORT: {
      return initialState;
    }

    case ACTIONS.GET_TRAINING_HISTORY_REPORT: {
      if (error) return state;

      return state.set(
        "employeesAttendances",
        OrderedMap(
          payload.map(employeeWithCourseAttendance => [
            employeeWithCourseAttendance.employeeId.toString(),
            employeeCourseMappers.EmployeeTrainingReport.from(
              employeeWithCourseAttendance,
            ),
          ]),
        ),
      );
    }

    default:
      return state;
  }
};
