import React, { useCallback, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Helmet } from "altus-ui-components";

import {
  getTrainingAndCompetencyReport,
  clearTrainingAndCompetencyReport,
  trainingAndCompetencyReportOnLoad,
  trainingAndCompetencyReportOnUnload,
  downloadAndSaveTrainingAndCompetencyReport,
} from "features/competence.actions";

import { getTrainingAndCompetencyReportEmployeesFromState } from "features/competence.selectors";

import { ACTIONS, APP_ROOT } from "app/app.constants";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import GapReportBasePage from "features/components/GapReport/GapReportBasePage";
import TrainingAndCompetencyReportFilter from "features/reports/trainingAndCompetency/TrainingAndCompetencyReportFilter"; // FILTERS,
import TrainingAndCompetencyreportTableContainter from "features/reports/trainingAndCompetency/TrainingAndCompetencyreportTableContainter";

const TrainingAndCompetencyReport = ({
  root = APP_ROOT.HR,
  employees,
  dataState,
  breadcrumb,
  dispatchOnLoad,
  dispatchOnUnload,
  dispatchGetReport,
  dispatchClearReport,
  dispatchDownloadGapReport,
}) => {
  useEffect(() => {
    dispatchOnLoad();
    return () => dispatchOnUnload();
  }, [dispatchOnLoad, dispatchOnUnload]);

  const renderFilter = useCallback(
    () => (
      <TrainingAndCompetencyReportFilter
        getGapReport={dispatchGetReport}
        clearGapReport={dispatchClearReport}
        downloadGapReport={dispatchDownloadGapReport}
      />
    ),
    [dispatchGetReport, dispatchClearReport, dispatchDownloadGapReport],
  );

  return (
    <>
      <Helmet titleTemplate="%s">
        <title>{breadcrumb}</title>
      </Helmet>
      <GapReportBasePage
        title={breadcrumb}
        dataState={dataState}
        renderFilter={renderFilter}
        employeesByDepartment={employees}
      >
        {!!employees.size && (
          <TrainingAndCompetencyreportTableContainter
            root={root}
            title={breadcrumb}
            employees={employees}
          />
        )}
      </GapReportBasePage>
    </>
  );
};

export default compose(
  connect(
    state => ({
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_TRAINING_AND_COMPETENCY_REPORT,
        ACTIONS.DOWNLOAD_TRAINING_AND_COMPETENCY_REPORT,
      ),
      employees: getTrainingAndCompetencyReportEmployeesFromState(state),
    }),
    {
      dispatchOnLoad: trainingAndCompetencyReportOnLoad,
      dispatchGetReport: getTrainingAndCompetencyReport,
      dispatchOnUnload: trainingAndCompetencyReportOnUnload,
      dispatchClearReport: clearTrainingAndCompetencyReport,
      dispatchDownloadGapReport: downloadAndSaveTrainingAndCompetencyReport,
    },
  ),
  React.memo,
)(TrainingAndCompetencyReport);
