import { replace } from "connected-react-router/immutable";

import { NOTIFICATION_VARIANTS } from "altus-redux-middlewares";

import routePaths from "app/routePaths";
import service from "services/app.service";
import appService from "services/app.service";
import { makeActionCreator } from "utils/app.util";
import { ACTIONS, HTTP_STATUS } from "app/app.constants";
import { getCurrentEmployee } from "features/competence.actions";

/**
 * Current User
 */
export const getCurrentUser = makeActionCreator({
  payload: service.getCurrentUser,
  type: ACTIONS.GET_CURRENT_USER,
});

export const appOnLoad = () => dispatch => {
  const payload = dispatch(getCurrentUser())
    .then(() =>
      Promise.all([
        dispatch(getCurrentEmployee()).catch(error => error), // ignore error
        dispatch(getAppFeatures()).catch(error => error), // ignore error
      ]),
    )
    .catch(error => {
      if (error.status !== HTTP_STATUS.UNAUTHORIZED) {
        dispatch(setApplicationFailure(error.message));
      }
    });

  dispatch({
    type: ACTIONS.APPLICATION_LOADED,
    payload: payload,
  });

  return payload;
};

export const onUnauthorized = () => dispatch =>
  dispatch(replace(routePaths.login));

/**
 * Users
 */
export const getUsers = makeActionCreator({
  type: ACTIONS.GET_USERS,
  payload: service.getUsers,
});

export const updateUser = makeActionCreator({
  type: ACTIONS.UPDATE_USER,
  payload: service.updateUser,
  notification: {
    [NOTIFICATION_VARIANTS.SUCCESS]: "User successfully updated",
  },
});

export const setApplicationFailure = errorMessage => dispatch =>
  dispatch({
    type: ACTIONS.SET_APPLICATION_FAILURE,
    payload: errorMessage,
  });

export const getAllAppRoles = makeActionCreator({
  type: ACTIONS.GET_ALL_APP_ROLES,
  payload: appService.getAllAppRoles,
});

export const createUserAppRoles = makeActionCreator(
  {
    type: ACTIONS.CREATE_USER_APP_ROLES,
    payload: appService.createUserAppRoles,
  },
  "userId",
);

export const getAppFeatures = makeActionCreator({
  type: ACTIONS.GET_APPLICATION_FEATURES,
  payload: appService.getAppFeatures,
});
