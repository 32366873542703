import React from "react";
import { compose } from "redux";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const GapReportHeadersContainer = ({ classes, children, ...rest }) => {
  return (
    <Grid item container wrap="nowrap" className={classes.root} {...rest}>
      {children}
    </Grid>
  );
};

const styles = theme => ({
  root: {
    top: 0,
    zIndex: 3,
    position: "sticky",
    height: theme.gapReport.header.height,
    borderTop: theme.gapReport.defaultBorder,
    borderRight: theme.gapReport.defaultBorder,
    backgroundColor: theme.palette.background.default,
  },
  headerText: {
    transform: "rotate(-90deg)",
    minWidth: theme.gapReport.header.height - theme.spacing(2),
    maxWidth: theme.gapReport.header.height - theme.spacing(2),
  },
});

export default compose(withStyles(styles))(GapReportHeadersContainer);
