import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Grid, Typography } from "@material-ui/core";

import { BasePage } from "altus-ui-components";

import {
  getCourseFromState,
  getEmployeeFromState,
  getCollectionsFromState,
  getEmployeeCourseFromState,
  getPrerequisiteCoursesFromState,
  getCollectionItemsByCourseFromState,
  getEmployeeCoursesForEmployeeSelector,
} from "features/competence.selectors";

import {
  getEmployeeCourse,
  getCollectionsByCourse,
  getPrerequisiteEmployeeCourses,
  getCollectionItemsOverriddenByCourse,
} from "features/competence.actions";

import {
  ICONS,
  ACTIONS,
  EMPTY_MAP,
  EMPTY_LIST,
  COMPETENCY_STATUS,
} from "app/app.constants";

import { getInitials } from "utils/app.util";
import { getSummarizedDataStatesFromState } from "app/app.selectors";
import { statusToTooltipText } from "app/components/CompetencyStatusIcon";
import EntityCompetencyStatus from "features/components/EntityRelationCompetencyStatus/EntityCompetencyStatus";
import EntityRelationCompetencyStatus from "features/components/EntityRelationCompetencyStatus/EntityRelationCompetencyStatus";

class EmployeeCourseStatusContainer extends PureComponent {
  componentDidMount() {
    const {
      courseId,
      employeeId,
      dispatchGetCollections,
      dispatchGetEmployeeCourse,
      dispatchGetPrerequisiteEmployeeCourses,
      dispatchGetCollectionItemsOverriddenByCourse,
    } = this.props;

    dispatchGetCollections(courseId);
    dispatchGetEmployeeCourse(employeeId, courseId);
    dispatchGetCollectionItemsOverriddenByCourse(courseId);
    dispatchGetPrerequisiteEmployeeCourses(employeeId, courseId);
  }

  getCompetencyStatusText = competencyStatus =>
    `${statusToTooltipText(competencyStatus)} ${" - Override"}`;

  renderEmployee = () => {
    const { employee } = this.props;

    return (
      <EntityCompetencyStatus
        topText={employee.get("displayName")}
        bottomText={employee.get("departmentName")}
        Icon={getInitials(employee.get("displayName"))}
      />
    );
  };

  renderCourse = () => {
    const { course } = this.props;

    return (
      <EntityCompetencyStatus
        Icon={<ICONS.COURSE />}
        topText={course.get("name")}
        bottomText={course.get("description")}
      />
    );
  };

  renderCustomCourse = () => {
    const { employeeCourses, prerequisiteCourses } = this.props;

    return (
      <Grid container item direction="column" spacing={1}>
        <Grid item>{this.renderCourse()}</Grid>
        {prerequisiteCourses.map(prerequisite => {
          const employeeCourse = employeeCourses.get(
            prerequisite.get("courseId").toString(),
            EMPTY_MAP,
          );

          return (
            <Grid item key={prerequisite.get("courseId")}>
              <EntityCompetencyStatus
                elevation={0}
                variant="dashed"
                Icon={<ICONS.COURSE />}
                topText={prerequisite.get("name")}
                bottomText={prerequisite.get("description")}
                competencyStatus={employeeCourse.get(
                  "competencyStatus",
                  COMPETENCY_STATUS.MISSING_REQUIREMENTS,
                )}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  renderCollectionCourse = () => {
    const { collectionItems } = this.props;

    return (
      <Grid container item direction="column" spacing={1}>
        <Grid item>{this.renderCourse()}</Grid>
        {collectionItems.map(collectionItem => {
          const Icon = collectionItem.get("courseId")
            ? ICONS.COURSE
            : ICONS.SKILL;

          return (
            <Grid item key={collectionItem.get("competencyCollectionItemId")}>
              <EntityCompetencyStatus
                elevation={0}
                Icon={<Icon />}
                variant="dashed"
                topText={collectionItem.get("name")}
                bottomText={collectionItem.get("description")}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  render() {
    const {
      course,
      dataState,
      breadcrumb,
      collections,
      employeeCourse,
    } = this.props;

    return (
      <BasePage title={breadcrumb} dataState={dataState}>
        <Grid container spacing={2}>
          <Grid item container xs={12} lg={10}>
            {employeeCourse.get("isOverridden") && (
              <Grid item>
                <Typography paragraph>
                  {employeeCourse.get("overrideReason")}
                </Typography>
                <Typography variant="subtitle2">Overridden by:</Typography>
                <Typography variant="body2">
                  {employeeCourse.get("overriddenBy")}
                </Typography>
              </Grid>
            )}
            {!!collections.size && (
              <Grid container justify="flex-end">
                <Grid item xs />
                <Grid item xs />
                <Grid item container xs alignItems="center" direction="column">
                  {collections.map(collection => (
                    <Typography key={collection.get("competencyCollectionId")}>
                      <b>{collection.get("name")}</b>
                    </Typography>
                  ))}
                  <Typography variant="caption" paragraph>
                    Collection override
                  </Typography>
                </Grid>
              </Grid>
            )}
            <EntityRelationCompetencyStatus
              renderLeftEntity={this.renderEmployee}
              competencyStatus={employeeCourse.get("competencyStatus")}
              getCompetencyStatusText={
                employeeCourse.get("isOverridden")
                  ? this.getCompetencyStatusText
                  : undefined
              }
              renderRightEntity={
                collections.size
                  ? this.renderCollectionCourse
                  : course.get("isCustom")
                  ? this.renderCustomCourse
                  : this.renderCourse
              }
            />
          </Grid>
        </Grid>
      </BasePage>
    );
  }
}

EmployeeCourseStatusContainer.defaultProps = {
  collections: EMPTY_MAP,
  employeeCourses: EMPTY_MAP,
  collectionItems: EMPTY_LIST,
  course: EMPTY_MAP,
  employee: EMPTY_MAP,
  employeeCourse: EMPTY_MAP,
};

export default compose(
  connect(
    (state, { courseId, employeeId }) => ({
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_COURSE,
        ACTIONS.GET_EMPLOYEE_COURSE,
        ACTIONS.GET_PREREQUISITE_COURSES,
        ACTIONS.GET_PREREQUISITE_EMPLOYEE_COURSES,
      ),
      course: getCourseFromState(state, courseId),
      collections: getCollectionsFromState(state),
      employee: getEmployeeFromState(state, employeeId),
      prerequisiteCourses: getPrerequisiteCoursesFromState(state, courseId),
      collectionItems: getCollectionItemsByCourseFromState(state, courseId),
      employeeCourse: getEmployeeCourseFromState(state, employeeId, courseId),
      employeeCourses: getEmployeeCoursesForEmployeeSelector(state, employeeId),
    }),
    {
      dispatchGetEmployeeCourse: getEmployeeCourse,
      dispatchGetCollections: getCollectionsByCourse,
      dispatchGetPrerequisiteEmployeeCourses: getPrerequisiteEmployeeCourses,
      dispatchGetCollectionItemsOverriddenByCourse: getCollectionItemsOverriddenByCourse,
    },
  ),
)(EmployeeCourseStatusContainer);
