import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { RouteContainer, NavTabsContainer } from "altus-ui-components";

import { ROUTE_KEYS } from "app/routes";
import { getSkillFromState } from "features/competence.selectors";
import { employeeSkillContainerOnLoad } from "features/competence.actions";
import EmployeeSkillDetailsHeader from "features/employee/employeeSkill/EmployeeSkillDetailsHeader";

class EmployeeSkillContainer extends PureComponent {
  componentDidMount() {
    const { employeeId, skillId, dispatchOnLoad } = this.props;

    dispatchOnLoad(employeeId, skillId);
  }

  filterRoutes = route => {
    const { skill } = this.props;

    if (!skill) return false;

    switch (route.key) {
      case ROUTE_KEYS.COLLECTIONS:
        return skill.get("isInCollection");
      default:
        return true;
    }
  };

  render() {
    const { routes, skillId, employeeId } = this.props;

    return (
      <>
        <EmployeeSkillDetailsHeader skillId={skillId} employeeId={employeeId} />
        <NavTabsContainer routes={routes.filter(this.filterRoutes)} />
        <RouteContainer
          routes={routes}
          RouteProps={{
            employeeId,
          }}
        />
      </>
    );
  }
}

export default compose(
  connect(
    (state, { skillId }) => ({
      skill: getSkillFromState(state, skillId),
    }),
    {
      dispatchOnLoad: employeeSkillContainerOnLoad,
    },
  ),
)(EmployeeSkillContainer);
