import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { COMPETENCY_STATUS, EMPTY_SET } from "app/app.constants";
import GapReportCell from "features/components/GapReport/GapReportCell";
import { getCourseGapReportEmployeeCoursesFromState } from "features/competence.selectors";

class CourseGapReportEmployeeRow extends PureComponent {
  render() {
    const {
      root,
      courses,
      employeeId,
      employeeCourses,
      index: employeeIndex,
    } = this.props;

    return (
      <>
        {courses.map((course, courseIndex) => {
          const courseId = course.get("courseId").toString();

          const employeeCourseCompetencyStatus = employeeCourses.getIn(
            [courseId, "competencyStatus"],
            COMPETENCY_STATUS.NONE,
          );

          return (
            <GapReportCell
              x={courseIndex}
              y={employeeIndex}
              key={courseIndex}
              status={employeeCourseCompetencyStatus}
              to={`${root}/employees/${employeeId}/courses/${courseId}`}
            />
          );
        })}
      </>
    );
  }
}

CourseGapReportEmployeeRow.defaultProps = {
  employeeCourses: EMPTY_SET,
};

export default compose(
  connect((state, { employeeId }) => ({
    employeeCourses: getCourseGapReportEmployeeCoursesFromState(
      state,
      employeeId,
    ),
  })),
)(CourseGapReportEmployeeRow);
