import { compose } from "redux";
import { connect } from "react-redux";
import React, { useCallback, useState, useMemo, useEffect } from "react";

import { Checkbox, FormControlLabel, withStyles } from "@material-ui/core";

import { getEmployeesAndStats } from "features/competence.actions";

import { ACTIONS } from "app/app.constants";
import { getActionDataStateFromState } from "app/app.selectors";
import { getEmployeesFromState } from "features/competence.selectors";
import EmployeesBasePage from "features/components/EmployeesBasePage";

const HREmployeesContainer = ({
  path,
  classes,
  employees,
  dataState,
  breadcrumb,
  dispatchOnLoad,
}) => {
  const [onlyExEmployees, setOnlyExEmployees] = useState(false);

  const onChange = useCallback(
    event => setOnlyExEmployees(event.target.checked),
    [],
  );
  const loadEmployees = useCallback(() => dispatchOnLoad({ onlyExEmployees }), [
    onlyExEmployees,
    dispatchOnLoad,
  ]);

  useEffect(() => {
    loadEmployees();
  }, [loadEmployees]);

  const listActions = useMemo(
    () => [
      {
        renderAction: () => (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={onlyExEmployees}
                onChange={onChange}
              />
            }
            label="Show former employees"
            classes={{
              root: classes.formControlLabelRoot,
            }}
          />
        ),
        getTitle: () => "Show former employees",
      },
    ],
    [classes, onlyExEmployees, onChange],
  );

  return (
    <EmployeesBasePage
      path={path}
      title={breadcrumb}
      employees={employees}
      dataState={dataState}
      onRefresh={loadEmployees}
      listActions={listActions}
    />
  );
};

const styles = () => ({
  formControlLabelRoot: {
    marginLeft: 0,
  },
});

export default compose(
  connect(
    state => ({
      employees: getEmployeesFromState(state),
      dataState: getActionDataStateFromState(state, ACTIONS.GET_EMPLOYEES),
    }),
    {
      dispatchOnLoad: getEmployeesAndStats,
    },
  ),
  React.memo,
  withStyles(styles),
)(HREmployeesContainer);
