import moment from "moment";
import { compose } from "redux";
import isNil from "lodash/isNil";
import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core/styles";
import { TextField as MuiTextField } from "@material-ui/core";

class ReadOnlyFieldReduxForm extends PureComponent {
  render() {
    const {
      input,
      margin,
      variant,
      classes,
      formatDate,
      defaultValue,
      disableUnderline,
      ...rest
    } = this.props;
    const { value } = input;

    return (
      <MuiTextField
        className={classes.root}
        disabled
        margin={margin}
        variant={variant}
        value={
          moment.isMoment(value)
            ? formatDate(value)
            : isNil(value)
            ? defaultValue
            : value
        }
        InputProps={{
          readOnly: true,
          disableUnderline: disableUnderline,
          classes: {
            root: classes.input,
            underline: classes.underline,
          },
        }}
        InputLabelProps={{
          classes: {
            root: classes.label,
          },
        }}
        {...rest}
      />
    );
  }
}

const styles = theme => ({
  input: {
    "&.Mui-disabled": {
      color: theme.palette.text.primary,
    },
  },
  label: {
    "&.Mui-disabled": {
      color: theme.palette.text.secondary,
    },
  },
  underline: {
    "&.Mui-disabled::before": {
      borderBottomStyle: "dotted",
    },
  },
});

ReadOnlyFieldReduxForm.defaultProps = {
  fullWidth: true,
  margin: "normal",
  variant: "standard",
};

export default compose(withStyles(styles))(ReadOnlyFieldReduxForm);
