import { compose } from "redux";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import React, { PureComponent } from "react";

import { ACTIONS } from "app/app.constants";
import { BasePage } from "altus-ui-components";
import RoleDetails from "features/components/RoleDetails";
import { getActionDataStateFromState } from "app/app.selectors";
import { getRoleFromState } from "features/competence.selectors";

class MyTeamRoleDetailsContainer extends PureComponent {
  render() {
    const { breadcrumb, role, dataState, ...rest } = this.props;

    return (
      <BasePage title={breadcrumb} dataState={dataState}>
        <Grid container item md={8} xs={12}>
          <RoleDetails role={role} {...rest} />
        </Grid>
      </BasePage>
    );
  }
}

export default compose(
  connect((state, { roleId }) => ({
    role: getRoleFromState(state, roleId),
    dataState: getActionDataStateFromState(
      state,
      ACTIONS.GET_ROLE_WITH_PARENTS,
    ),
  })),
)(MyTeamRoleDetailsContainer);
