import { OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { CourseTypeMapper } from "app/app.mappers";

const initialState = OrderedMap();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_COURSE_TYPES: {
      if (error) return state;

      return OrderedMap(
        payload.map(courseGroup => [
          courseGroup.courseTypeId.toString(),
          CourseTypeMapper.from(courseGroup),
        ]),
      );
    }

    case ACTIONS.GET_COURSE_TYPE:
    case ACTIONS.UPDATE_COURSE_TYPE:
    case ACTIONS.CREATE_COURSE_TYPE: {
      if (error) return state;

      return state.set(
        payload.courseTypeId.toString(),
        CourseTypeMapper.from(payload),
      );
    }

    case ACTIONS.DELETE_COURSE_TYPE: {
      if (error) return state;

      return state.delete(action.courseTypeId.toString());
    }

    default:
      return state;
  }
};
