import { compose } from "redux";
import React, { PureComponent } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { SORT_DIRECTION, EMPTY_LIST } from "app/app.constants";
import SortableList from "app/components/SortableList/SortableList";
import { RoleMapper } from "app/app.mappers";

class RoleSkillList extends PureComponent {
  render() {
    const {
      Icon,
      role,
      items,
      getKey,
      filter,
      columns,
      classes,
      actions,
      inherited,
      onRowClick,
      renderIcon,
      toggleEditModal,
      createSortableListRowProps,
    } = this.props;

    return (
      <Grid container item className={classes.root}>
        <SortableList
          Icon={Icon}
          items={items}
          getKey={getKey}
          filter={filter}
          columns={columns}
          actions={actions}
          renderIcon={renderIcon}
          defaultSortColumn={0}
          onRowClick={onRowClick}
          toggleEditModal={toggleEditModal}
          defaultSortDirection={SORT_DIRECTION.ASC}
          createSortableListRowProps={createSortableListRowProps}
          title={
            <Grid item>
              <Typography variant="h6" className={classes.title}>
                {inherited
                  ? `${role.get("name")} (Inherited)`
                  : role.get("name")}
              </Typography>
            </Grid>
          }
        />
      </Grid>
    );
  }
}

const styles = theme => ({
  root: {
    marginBottom: theme.spacing(8),
  },
  title: {
    display: "flex",
  },
});

RoleSkillList.defaultProps = {
  items: EMPTY_LIST,
  role: RoleMapper.initial,
};

export default compose(withStyles(styles))(RoleSkillList);
