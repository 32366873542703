import { OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { SkillGroupMapper } from "app/app.mappers";

const initialState = OrderedMap();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_SKILL_GROUPS: {
      if (error) return state;

      return OrderedMap(
        payload.map(skillGroup => [
          skillGroup.skillGroupId.toString(),
          SkillGroupMapper.from(skillGroup),
        ]),
      );
    }

    case ACTIONS.GET_SKILL_GROUP:
    case ACTIONS.UPDATE_SKILL_GROUP:
    case ACTIONS.CREATE_SKILL_GROUP: {
      if (error) return state;

      return state.set(
        payload.skillGroupId.toString(),
        SkillGroupMapper.from(payload),
      );
    }

    case ACTIONS.DELETE_SKILL_GROUP: {
      if (error) return state;

      return state.delete(action.skillGroupId.toString());
    }

    default:
      return state;
  }
};
