import { OrderedMap, List } from "immutable";

import { ACTIONS } from "app/app.constants";
import { RoleMapper, EmployeeMapper } from "app/app.mappers";

const initialState = OrderedMap();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_ROLES: {
      return initialState;
    }
    case ACTIONS.UPDATE_ROLE: {
      if (error) return state;

      return state.set(payload.roleId.toString(), RoleMapper.from(payload));
    }

    case ACTIONS.GET_ROLE: {
      if (error) return state;

      return state.set(payload.roleId.toString(), RoleMapper.from(payload));
    }

    case ACTIONS.GET_ROLES:
    case ACTIONS.GET_ROLES_BY_COURSE:
    case ACTIONS.GET_ROLES_BY_SKILL:
    case ACTIONS.GET_ROLES_BY_USER: {
      if (error) return state;

      return OrderedMap(
        payload
          .filter(Boolean)
          .map(role => [role.roleId.toString(), RoleMapper.from(role)]),
      );
    }

    case ACTIONS.GET_ROLE_WITH_PARENTS: {
      if (error) return state;

      return state.merge(
        OrderedMap(
          payload.map(role => [role.roleId.toString(), RoleMapper.from(role)]),
        ),
      );
    }

    case ACTIONS.GET_EMPLOYEE_ROLES: {
      if (error) return state;

      return state.merge(
        OrderedMap(
          payload.map(employeeRole => [
            employeeRole.roleId.toString(),
            RoleMapper.from(employeeRole.role),
          ]),
        ),
      );
    }

    case ACTIONS.GET_ROLE_COURSES_BY_ROLE: {
      if (error) return state;

      return OrderedMap(
        payload.roles.map(role => [
          role.roleId.toString(),
          RoleMapper.from(role),
        ]),
      );
    }

    case ACTIONS.GET_AVAILABLE_EMPLOYEES_FOR_ROLE: {
      if (error) return state;

      return state.setIn(
        [action.roleId.toString(), "availableEmployees"],
        OrderedMap(
          payload.map(employee => [
            employee.employeeId.toString(),
            EmployeeMapper.from(employee),
          ]),
        ),
      );
    }

    case ACTIONS.GET_AVAILABLE_COURSES_FOR_ROLE: {
      if (error) return state;

      return state.setIn(
        [action.roleId.toString(), "availableCoursesIds"],
        List(payload).map(course => course.courseId),
      );
    }

    case ACTIONS.GET_AVAILABLE_SKILLS_FOR_ROLE: {
      if (error) return state;

      return state.setIn(
        [action.roleId.toString(), "availableSkillsIds"],
        List(payload).map(skill => skill.skillId),
      );
    }

    default:
      return state;
  }
};
