import { compose } from "redux";
import { Route } from "react-router-dom";
import React, { PureComponent } from "react";
import { Grid, Fade } from "@material-ui/core";

import ApplicationSidebarMenuItem from "app/components/ApplicationSidebar/ApplicationSidebarMenuItem";

class ApplicationSidebarMenu extends PureComponent {
  render() {
    const { routes } = this.props;

    return routes.map(route => {
      const { path, routes: subRoutes } = route;

      return (
        <Route
          key={path}
          path={path}
          children={({ match }) => (
            <Grid>
              <ApplicationSidebarMenuItem route={route} />
              {!!match && (
                <Fade in={true}>
                  <Grid>
                    {subRoutes
                      .filter(route => !route.hidden)
                      .map(subRoute => (
                        <ApplicationSidebarMenuItem
                          depth={1}
                          maxDepth={2}
                          match={match}
                          route={subRoute}
                          key={subRoute.path}
                        />
                      ))}
                  </Grid>
                </Fade>
              )}
            </Grid>
          )}
        />
      );
    });
  }
}

export default compose()(ApplicationSidebarMenu);
