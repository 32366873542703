import { compose } from "redux";
import { connect } from "react-redux";
import Delete from "@material-ui/icons/Delete";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import React, { useEffect, useCallback, useMemo } from "react";

import { useModal } from "altus-modal";
import { MultiSelectModal } from "altus-ui-components";

import SwitchField from "app/components/SwitchField";
import CrudBasePage from "app/components/CrudBasePage";
import { ACTIONS, ICONS, CRITICALITY } from "app/app.constants";

import {
  roleCoursesOnUnload,
  getRoleCoursesByRole,
  createRoleCourseOnSubmit,
  getAvailableCoursesForRole,
  deleteRoleCourseAskConfirmation,
  updateRoleCourseAndGetRoleStats,
} from "features/competence.actions";

import {
  getActionDataStateFromState,
  getSummarizedDataStatesFromState,
} from "app/app.selectors";

import {
  getRolesFromState,
  getAvailableCoursesForRoleFromState,
} from "features/competence.selectors";

import { CREATE_ROLE_COURSE_FORM } from "features/competence.constants";
import RoleRoleCourseList from "features/role/components/RoleRoleCourseList";

const getKey = roleCourse => roleCourse.get("roleCourseId");
const getCourseName = course => course.get("name");

const RoleCoursesContainer = ({
  roles,
  roleId,
  dataState,
  breadcrumb,
  availableCourses,
  dispatchOnUnload,
  dispatchGetRoleCourses,
  dispatchDeleteRoleCourse,
  getAvailableCoursesDataState,
  dispatchCreateRoleCourseOnSubmit,
  dispatchGetAvailableCoursesForRole,
  dispatchUpdateRoleCourseAndGetRoleStats,
}) => {
  const [isOpen, toggleModal] = useModal(CREATE_ROLE_COURSE_FORM.ID);

  useEffect(() => {
    dispatchGetRoleCourses(roleId);

    return () => dispatchOnUnload();
  }, [roleId, dispatchGetRoleCourses, dispatchOnUnload]);

  useEffect(() => {
    if (isOpen) {
      dispatchGetAvailableCoursesForRole(roleId);
    }
  }, [roleId, isOpen, dispatchGetAvailableCoursesForRole]);

  const columns = useMemo(
    () => [
      {
        xs: 4,
        title: "Course Name",
        getSortProperty: item => item.getIn(["course", "name"]),
      },
      {
        xs: true,
        title: "Group",
        getSortProperty: item => item.getIn(["course", "courseGroupName"]),
      },
      {
        xs: true,
        title: "Type",
        getSortProperty: item => item.getIn(["course", "courseTypeName"]),
      },
      {
        xs: true,
        title: "Valid",
        getSortProperty: item => item.getIn(["course", "validityMonthsString"]),
      },
      {
        xs: true,
        title: "Status",
        getSortProperty: item => item.getIn(["course", "status"]),
      },
      {
        xs: true,
        justify: "center",
        container: true,
        title: "Criticality",
        getValue: item => (
          <SwitchField
            label="Critical"
            checked={item.get("criticality") === CRITICALITY.CRITICAL}
            onChange={() =>
              dispatchUpdateRoleCourseAndGetRoleStats(
                item.update("criticality", courseCriticality =>
                  courseCriticality === CRITICALITY.CRITICAL
                    ? CRITICALITY.NOT_CRITICAL
                    : CRITICALITY.CRITICAL,
                ),
              )
            }
          />
        ),
      },
    ],
    [dispatchUpdateRoleCourseAndGetRoleStats],
  );

  const actions = useMemo(
    () => [
      {
        getValue: item =>
          item.get("roleId").toString() === roleId.toString() && (
            <Tooltip title="Delete">
              <IconButton onClick={() => dispatchDeleteRoleCourse(item)}>
                <Delete />
              </IconButton>
            </Tooltip>
          ),
      },
    ],
    [roleId, dispatchDeleteRoleCourse],
  );

  const addCoursesSubmit = useCallback(
    selectedCourses => {
      dispatchCreateRoleCourseOnSubmit(
        roleId,
        selectedCourses.map(course => course.get("courseId")).toArray(),
      ).then(toggleModal);
    },
    [roleId, toggleModal, dispatchCreateRoleCourseOnSubmit],
  );

  return (
    <CrudBasePage
      dataState={dataState}
      title={breadcrumb}
      displayAddButton={true}
      displaySearchField={true}
      addButtonOnClick={toggleModal}
    >
      <Grid container spacing={5}>
        {roles.map(role => {
          const mapRoleId = role.get("roleId");

          return (
            <RoleRoleCourseList
              Icon={ICONS.COURSE}
              key={mapRoleId}
              roleId={mapRoleId}
              getKey={getKey}
              actions={actions}
              columns={columns}
              inherited={mapRoleId.toString() !== roleId.toString()}
            />
          );
        })}
      </Grid>
      <MultiSelectModal
        open={isOpen && !getAvailableCoursesDataState.isLoading()}
        displayChips
        onClose={toggleModal}
        getName={getCourseName}
        items={availableCourses}
        title="Add Courses"
        onSubmit={addCoursesSubmit}
      />
    </CrudBasePage>
  );
};

export default compose(
  connect(
    (state, { roleId }) => ({
      roles: getRolesFromState(state, roleId),
      availableCourses: getAvailableCoursesForRoleFromState(state, roleId),
      getAvailableCoursesDataState: getActionDataStateFromState(
        state,
        ACTIONS.GET_AVAILABLE_COURSES_FOR_ROLE,
      ),
      dataState: getSummarizedDataStatesFromState(
        state,
        ACTIONS.GET_ROLE_COURSES_BY_ROLE,
        ACTIONS.UPDATE_ROLE_COURSE,
        ACTIONS.GET_AVAILABLE_COURSES_FOR_ROLE,
      ),
    }),
    {
      dispatchGetAvailableCoursesForRole: getAvailableCoursesForRole,
      dispatchGetRoleCourses: getRoleCoursesByRole,
      dispatchOnUnload: roleCoursesOnUnload,
      dispatchDeleteRoleCourse: deleteRoleCourseAskConfirmation,
      dispatchCreateRoleCourseOnSubmit: createRoleCourseOnSubmit,
      dispatchUpdateRoleCourseAndGetRoleStats: updateRoleCourseAndGetRoleStats,
    },
  ),
)(RoleCoursesContainer);
