import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";

import { TILE_CHIP_TYPES, OverviewContainer } from "altus-ui-components";

import { ROUTE_KEYS } from "app/routes";
import { getMyTeamStats } from "features/competence.actions";
import { getMyTeamStatsFromState } from "features/competence.selectors";

class MyTeamOverviewContainer extends PureComponent {
  componentDidMount() {
    const { dispatchGetMyTeamStats } = this.props;
    dispatchGetMyTeamStats();
  }

  getChipType = route => {
    const { myTeamStats } = this.props;

    switch (route.key) {
      case ROUTE_KEYS.APPROVALS:
        return myTeamStats.get(route.key) > 0
          ? TILE_CHIP_TYPES.NOTIFICATION
          : TILE_CHIP_TYPES.VISIBLE;

      default:
        break;
    }

    return route.key ? TILE_CHIP_TYPES.VISIBLE : TILE_CHIP_TYPES.HIDDEN;
  };

  render() {
    const {
      match,
      parentRoutes,
      parentBreadcrumb,
      myTeamStats,
      Icon,
    } = this.props;

    const routesWithLabel = parentRoutes.map(route => ({
      ...route,
      chipType: this.getChipType(route),
      chipLabel: myTeamStats.get(route.key),
    }));

    return (
      <OverviewContainer
        Icon={Icon}
        match={match}
        parentRoutes={routesWithLabel}
        parentBreadcrumb={parentBreadcrumb}
      />
    );
  }
}

export default compose(
  connect(
    state => ({
      myTeamStats: getMyTeamStatsFromState(state),
    }),
    {
      dispatchGetMyTeamStats: getMyTeamStats,
    },
  ),
)(MyTeamOverviewContainer);
