import { compose } from "redux";
import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { grey } from "@material-ui/core/colors";

import {
  getSpecificationGapReportEmployeeSpecificationStatusesFromState,
  getSpecificationGapReportEmployeeCoursesFromState,
  getSpecificationGapReportEmployeeSkillsFromState,
} from "features/competence.selectors";

import { COMPETENCY_STATUS, EMPTY_SET } from "app/app.constants";
import GapReportCell from "features/components/GapReport/GapReportCell";

class SpecificationGapReportEmployeeRow extends PureComponent {
  render() {
    const {
      root,
      specifications,
      employeeId,
      specificationCourses,
      specificationSkills,
      employeeSpecificationStatuses,
      employeeCourses,
      employeeSkills,
      index: employeeIndex,
    } = this.props;

    let currentXPos = 0;

    return (
      <>
        {specifications.map((specification, specificationIndex) => {
          const specificationId = specification
            .get("specificationId")
            .toString();
          const employeeSpecificationStatus = employeeSpecificationStatuses.getIn(
            [specificationId, "competencyStatus"],
            COMPETENCY_STATUS.NONE,
          );

          const specificationXPos = currentXPos++;

          return (
            <React.Fragment key={specificationIndex}>
              <GapReportCell
                x={specificationXPos}
                fill={grey[200]}
                y={employeeIndex}
                status={employeeSpecificationStatus}
                to={`${root}/specifications/${specificationId}`}
              />
              {specificationCourses
                .get(specificationId, EMPTY_SET)
                .map((specificationCourse, specificationCourseIndex) => {
                  const courseId = specificationCourse
                    .get("courseId")
                    .toString();

                  const employeeCourseCompetencyStatus = employeeCourses.getIn(
                    [courseId, "competencyStatus"],
                    COMPETENCY_STATUS.NONE,
                  );

                  const specificationCourseXPos = currentXPos++;

                  return (
                    <GapReportCell
                      x={specificationCourseXPos}
                      key={specificationCourseIndex}
                      y={employeeIndex}
                      status={employeeCourseCompetencyStatus}
                      to={`${root}/employees/${employeeId}/courses/${courseId}`}
                    />
                  );
                })}
              {specificationSkills
                .get(specificationId, EMPTY_SET)
                .map((specificationSkill, specificationSkillIndex) => {
                  const skillId = specificationSkill.get("skillId").toString();

                  const employeeSkillCompetencyStatus = employeeSkills.getIn(
                    [skillId, "competencyStatus"],
                    COMPETENCY_STATUS.NONE,
                  );

                  const specificationSkillXPos = currentXPos++;

                  return (
                    <GapReportCell
                      x={specificationSkillXPos}
                      key={specificationSkillIndex}
                      y={employeeIndex}
                      status={employeeSkillCompetencyStatus}
                      to={`${root}/employees/${employeeId}/skills/${skillId}`}
                    />
                  );
                })}
            </React.Fragment>
          );
        })}
      </>
    );
  }
}

SpecificationGapReportEmployeeRow.defaultProps = {
  employeeSpecificationStatuses: EMPTY_SET,
  employeeCourses: EMPTY_SET,
  employeeSkills: EMPTY_SET,
};

export default compose(
  connect((state, { employeeId }) => ({
    employeeSpecificationStatuses: getSpecificationGapReportEmployeeSpecificationStatusesFromState(
      state,
      employeeId,
    ),
    employeeCourses: getSpecificationGapReportEmployeeCoursesFromState(
      state,
      employeeId,
    ),
    employeeSkills: getSpecificationGapReportEmployeeSkillsFromState(
      state,
      employeeId,
    ),
  })),
)(SpecificationGapReportEmployeeRow);
