import { Map, OrderedMap } from "immutable";

import { ACTIONS } from "app/app.constants";
import { CountryMapper } from "app/app.mappers";

const initialState = Map();

export default (state = initialState, action) => {
  const { error, payload, type } = action;

  switch (type) {
    case ACTIONS.GET_COUNTRIES: {
      if (error) return state;

      return OrderedMap(
        payload.map(country => [
          country.countryId.toString(),
          CountryMapper.from(country),
        ]),
      );
    }

    default:
      return state;
  }
};
