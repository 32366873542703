import React from "react";
import { Grid, MenuItem, TextField } from "@material-ui/core";

import { EMPTY_MAP } from "app/app.constants";

const ExperienceRecordValidatorSelector = ({
  readOnly,
  onUpdateValidator,
  experienceRecord = EMPTY_MAP,
  availableValidators = EMPTY_MAP,
}) => (
  <Grid item container justifyContent="center">
    <Grid item xs={3}>
      <TextField
        select
        label="Validator"
        variant="outlined"
        disabled={readOnly}
        onChange={({ target }) => onUpdateValidator(target.value)}
        value={experienceRecord.get("validatorId") ?? ""}
        SelectProps={{
          displayEmpty: true,
        }}
        InputLabelProps={{ shrink: true }}
      >
        <MenuItem disabled value="">
          <em>- Select -</em>
        </MenuItem>
        {availableValidators.valueSeq().map(validator => (
          <MenuItem
            key={validator.get("employeeId")}
            value={validator.get("employeeId")}
          >
            {validator.get("displayName")}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  </Grid>
);

export default ExperienceRecordValidatorSelector;
