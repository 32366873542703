import { compose } from "redux";
import { Field } from "redux-form/immutable";
import React, { useState, useCallback, useEffect } from "react";

import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Radio,
  RadioGroup,
  Typography,
  FormControlLabel,
} from "@material-ui/core";

import TextField from "app/components/form/TextField";
import { VALIDITY_PERIOD_OPTIONS } from "features/competence.constants";

const noValidityPeriod = null;

const ValidityPeriodField = ({ input, classes, validation }) => {
  const { value = noValidityPeriod, onChange, name } = input;
  const [validity, setValidity] = useState(VALIDITY_PERIOD_OPTIONS.ALWAYS);

  useEffect(() => {
    setValidity(
      value === noValidityPeriod
        ? VALIDITY_PERIOD_OPTIONS.ALWAYS
        : VALIDITY_PERIOD_OPTIONS.MONTHS,
    );
  }, [value]);

  const handleRadioChange = useCallback(
    validity => {
      if (validity === VALIDITY_PERIOD_OPTIONS.ALWAYS) {
        onChange(null);
      }
      setValidity(validity);
    },
    [onChange],
  );

  return (
    <Grid container direction="row">
      <RadioGroup value={validity}>
        <Grid item>
          <Typography
            classes={{
              root: classNames(classes.radioGroupLabel),
            }}
          >
            Valid
          </Typography>
          <FormControlLabel
            label="Always"
            control={<Radio color="primary" size="small" />}
            value={VALIDITY_PERIOD_OPTIONS.ALWAYS}
            onChange={() => {
              handleRadioChange(VALIDITY_PERIOD_OPTIONS.ALWAYS);
            }}
          />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={4}>
              <FormControlLabel
                label="Expires in"
                control={<Radio color="primary" size="small" />}
                value={VALIDITY_PERIOD_OPTIONS.MONTHS}
                onChange={() => {
                  handleRadioChange(VALIDITY_PERIOD_OPTIONS.MONTHS);
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                type="number"
                name={name}
                min={1}
                step={1}
                component={TextField}
                disabled={validity === VALIDITY_PERIOD_OPTIONS.ALWAYS}
                classes={{
                  root: classNames(classes.fieldMonths),
                }}
                validate={
                  validity === VALIDITY_PERIOD_OPTIONS.ALWAYS
                    ? undefined
                    : validation
                }
              />
            </Grid>
            <Grid item xs={3}>
              <Typography
                classes={{
                  root: classNames(classes.labelMonths),
                }}
              >
                months
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </RadioGroup>
    </Grid>
  );
};

const styles = theme => ({
  labelMonths: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  fieldMonths: {
    marginTop: theme.spacing(0),
  },
  radioGroupLabel: {
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
  },
});

export default compose(withStyles(styles))(ValidityPeriodField);
