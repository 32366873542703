import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { APP_ROLES } from "app/app.constants";
import AccessControl from "app/components/AccessControl";
import { getCurrentEmployeeFromState } from "app/app.selectors";
import MyTeamApprovalContainer from "features/myTeam/approval/MyTeamApprovalContainer";
import ExperienceRecordsValidatorContainer from "./components/ExperienceRecordsValidatorContainer";

const MyPageApprovals = ({
  currentEmployee,
  breadcrumb,
  path,
  Icon,
  ...rest
}) => {
  return (
    <>
      <AccessControl accessLevel={APP_ROLES.EMPLOYEE}>
        <ExperienceRecordsValidatorContainer
          Icon={Icon}
          path={path}
          title={breadcrumb}
          employeeId={currentEmployee.get("employeeId")}
        />
      </AccessControl>
      <AccessControl accessLevel={APP_ROLES.HR_ASSESSOR_VERIFIER}>
        <MyTeamApprovalContainer Icon={Icon} title={breadcrumb} {...rest} />
      </AccessControl>
    </>
  );
};

export default compose(
  connect(state => ({
    currentEmployee: getCurrentEmployeeFromState(state),
  })),
)(MyPageApprovals);
